import { observer } from 'mobx-react-lite'
import { ConversionsTable } from 'widgets/conversions/ConversionsTable/ConversionsTable'
import { ConversionsHeader } from 'src/pages/settings/conversions/conversions/ui/header/header'
import { useConversionsContext } from 'src/pages/settings/conversions/conversions/context/context'

export const ConversionsUi = observer(() => {
  const { conversionsTableStore } = useConversionsContext()

  return (
    <>
      <ConversionsHeader />
      <ConversionsTable store={conversionsTableStore} />
    </>
  )
})
