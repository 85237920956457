import styles from './styles.module.scss'
import { Currencies, numberString } from 'src/util/functions'
import { Switch } from 'components/Switch/Switch'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { BalanceFallsOptions } from 'store/settings/billing/hellpers'
import { observer } from 'mobx-react-lite'
import {
  AutoRechargeThreshold,
  SimplePrice,
  useGetPricesQuery,
  useSetupAutoRechargeMutation,
} from 'src/generated/graphql'
import classNames from 'classnames'
import billingStore from 'store/settings/billing/billingStore'
import { DisabledAutoRechargeModal } from 'src/pages/settings/billing/modals/DisabledAutoRechargeModal/DisabledAutoRechargeModal'
import { IOption } from 'src/types/IOption'
import upgradePlanStore from 'src/Modals/UpgradePlanModal/store/upgradePlanStore'
import { useEffect } from 'react'
import { useSetCreditPrices } from 'src/pages/settings/billing/hooks/useSetCreditPrices'
import migrationStore from 'store/migration/migrationStore'
import alertStore from 'store/alertStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { iCreditPriceToOption } from 'store/settings/billing/functions/iCreditPriceToOption'

type Props = {
  planName?: string
  onlyContent?: boolean
  fromUpgrade?: boolean
  mt?: number
}

export const AutoRechargeCard = observer(
  ({ onlyContent, fromUpgrade, mt, planName: planNameProps }: Props) => {
    const [setupAutoRecharge] = useSetupAutoRechargeMutation()
    const fromMigration = migrationStore.fromMigration

    const planName =
      planNameProps || billingStore.clSubscription?.price?.planName || ''

    const creditPrices = billingStore.creditPricesMap.get(planName) || []

    const { data } = useGetPricesQuery({
      skip: !!billingStore.prices.length || fromMigration,
    })
    useEffect(() => {
      if (data?.getPrices) {
        const filtered: SimplePrice[] = []
        data.getPrices?.forEach((price) => {
          if (price) {
            filtered.push(price)
          }
        })
        upgradePlanStore.setPrices(filtered)
      }
    }, [data])

    const isHide =
      billingStore.autoRecharge &&
      fromUpgrade &&
      billingStore.disableHideAutoRechargeFor !== 'fromUpgrade'

    useSetCreditPrices(planName, isHide)

    if (isHide) {
      return <></>
    }

    const creditPriceOptions: IOption[] = creditPrices.map((price) =>
      iCreditPriceToOption(price, fromMigration)
    )
    const activeCreditPrice = creditPrices.find(
      (price) => price.creditsAmount === billingStore.autoRechargeCreditsAmount
    )
    const selectedCreditPriceOption = activeCreditPrice
      ? iCreditPriceToOption(activeCreditPrice, fromMigration)
      : undefined

    const initialSetup = fromMigration || fromUpgrade ? true : undefined

    const handleSetActiveCreditPrice = async (option: IOption) => {
      try {
        const res = await setupAutoRecharge({
          variables: {
            options: {
              enable: billingStore.autoRecharge,
              creditsAmount: option.value,
              threshold: billingStore.autoRechargeThreshold,
              initialSetup,
            },
          },
        })
        if (res.data?.setupAutoRecharge) {
          billingStore.setOrganizationBillingInfo(res.data?.setupAutoRecharge)
        }
      } catch (e) {
        console.error(e)
      }
    }
    const setActiveAutoRecharge = async () => {
      if (fromUpgrade) {
        billingStore.setDisableHideAutoRechargeFor('fromUpgrade')
      }
      try {
        const res = await setupAutoRecharge({
          variables: {
            options: {
              enable: true,
              creditsAmount: 500,
              threshold: AutoRechargeThreshold.Credits100,
              initialSetup,
            },
          },
        })
        if (res.data?.setupAutoRecharge) {
          billingStore.setOrganizationBillingInfo(res.data?.setupAutoRecharge)
        }
        alertStore.setAlert({
          type: AlertTypeEnum.success,
          text: 'Auto-Recharge is enabled',
        })
      } catch (e) {
        console.error(e)
      }
    }

    const handleAutoRecharge = async () => {
      if (billingStore.autoRecharge) {
        billingStore.setOpenDisabledAutoRechargeModal(true)
      } else {
        await setActiveAutoRecharge()
      }
    }

    const setFallsOption = async (opt: IOption) => {
      try {
        const res = await setupAutoRecharge({
          variables: {
            options: {
              enable: billingStore.autoRecharge,
              creditsAmount: 500,
              threshold: opt.value,
              initialSetup,
            },
          },
        })
        if (res.data?.setupAutoRecharge) {
          billingStore.setOrganizationBillingInfo(res.data?.setupAutoRecharge)
        }
      } catch (e) {
        console.error(e)
      }
    }
    return (
      <div
        className={classNames(
          styles.autoRecharge,
          onlyContent && styles.onlyContent
        )}
        style={{ marginTop: mt }}
      >
        <DisabledAutoRechargeModal />
        <div className={styles.row}>
          <div>
            <span className={'bold'}>Auto-recharge</span>
            <div className={styles.wrapAutoText}>
              <span className={'s2 gray1'}>
                {billingStore.autoRecharge ? (
                  <>
                    Automatically add{' '}
                    {numberString({
                      val: Number(activeCreditPrice?.creditsAmount),
                    })}{' '}
                    credits (
                    {numberString({
                      val: Number(activeCreditPrice?.cost),
                      currency: Currencies.usd,
                    })}{' '}
                    + applicable taxes) to your account when your <br /> balance
                    goes below{' '}
                    {numberString({
                      val: parseInt(
                        String(billingStore.autoRechargeThresholdOption.title)
                      ),
                    })}{' '}
                    credits.
                  </>
                ) : (
                  'Enable auto-recharge to ensure that you always have enough credits on your account'
                )}
              </span>
            </div>
          </div>
          <Switch
            active={billingStore.autoRecharge}
            onChange={handleAutoRecharge}
          />
        </div>
        {billingStore.autoRecharge && (
          <div className={styles.rowGrid}>
            <Dropdown
              labelInput={'If balance falls bellow'}
              options={BalanceFallsOptions}
              selectedOption={billingStore.autoRechargeThresholdOption}
              onSelect={setFallsOption}
            />
            <Dropdown
              labelInput={'Recharge my account'}
              options={creditPriceOptions}
              selectedOption={selectedCreditPriceOption}
              onSelect={handleSetActiveCreditPrice}
            />
          </div>
        )}
      </div>
    )
  }
)
