import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { StyledEngineProvider } from '@mui/material/styles'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { CheckBox } from 'components/CheckBox/CheckBox'
import { SortLabel } from 'components/NewTable/components/SortLabel/SortLabel'
import { SettingBtn } from 'components/NewTable/components/Settings/SettingBtn'
import { RowComponent } from 'components/NewTable/components/RowComponent'
import { Direction } from 'src/generated/graphql'
import { observer } from 'mobx-react-lite'
import { AppPagination } from 'components/NewTable/components/AppPagination/AppPagination'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import uiStore from 'store/uiStore'
import { mobileActionColumn } from 'components/NewTable/store/mobileActionColumn'
import { StickyContent } from 'components/NewTable/components/StickyContent/StickyContent'
import { AdditionalContent } from 'components/NewTable/components/AdditionalContent/AdditionalContent'
import { RequiredFields, TableProps } from 'components/NewTable/types'
import { AllSelected } from 'components/NewTable/components/AllSelected/AllSelected'

function NewTable<T extends RequiredFields<T>>(props: TableProps<T>) {
  const {
    rows,
    columns,
    HoverRowComponent,
    onRowClick,
    withCheckbox,
    emptyContent,
    tableStore,
    noPagination,
    noSetting,
    noStickyHeader,
    noSorting,
    firstPaddingLeft16,
    fitContent,
    pageLoading,
    firstLoading,
    contentLoading,
    noSortsColumns,
    maxHeight,
    minHeight,
    withCalcMaxHeight,
    heightSticky91,
    showVisibleActionsCount,
    triggerHeight,
    actionsSize,
    withSelectAll,
  } = props

  const [newColumns, setNewColumns] = useState(columns)
  const [calcMaxHeight, setMaxHeight] = useState<undefined | string>(undefined)
  const [isLeftScroll, setIsLeftScroll] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const [refContainer, setRefContainer] = useState<HTMLDivElement | null>(null)
  const onRequestSort = (property: string) => {
    const isAsc =
      tableStore.orderBy === property && tableStore.order === Direction.Asc
    tableStore.setOrder(isAsc ? Direction.Desc : Direction.Asc)
    tableStore.setOrderBy(property)
  }

  const onCheckedAll = () => {
    if (!tableStore.checkedRowsIds.length) {
      tableStore.setCheckedRows(rows)
    } else {
      tableStore.setCheckedRows([])
    }
  }

  const createSortHandler = (property: string) => {
    onRequestSort(property)
  }

  useEffect(() => {
    tableStore.setRows(rows)
  }, [rows])

  useEffect(() => {
    if (uiStore.isMobile) {
      if (
        !columns.find((column) => column.id === mobileActionColumn.id) &&
        rows.length &&
        rows[0].actions?.length
      ) {
        setNewColumns([...columns, mobileActionColumn])
      }
    } else {
      setNewColumns(
        columns.filter((column) => column.id !== mobileActionColumn.id)
      )
    }
  }, [uiStore.isMobile, columns, rows])

  useEffect(() => {
    if (ref.current) {
      ref.current.onscroll = function (e) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        setIsLeftScroll(!!e.target?.scrollLeft)
      }
    }
  })

  useEffect(() => {
    if (refContainer?.offsetTop && withCalcMaxHeight) {
      setMaxHeight(`calc(100vh - ${refContainer.offsetTop + 72}px)`)
    }
  }, [refContainer, triggerHeight])

  if (firstLoading) {
    return <AbsoluteLoader />
  }

  return (
    <StyledEngineProvider injectFirst>
      <div
        className={classNames(
          styles.wrapTable,
          heightSticky91 && styles.heightSticky91,
          noStickyHeader && styles.noStickyHeader,
          firstPaddingLeft16 && styles.firstPaddingLeft16,
          fitContent && styles.fitContent
        )}
        ref={setRefContainer}
      >
        <TableContainer
          sx={{
            maxHeight: uiStore.isMobile
              ? undefined
              : maxHeight || calcMaxHeight,
            minHeight: uiStore.isMobile
              ? undefined
              : minHeight || maxHeight || calcMaxHeight,
          }}
          ref={ref}
        >
          <AdditionalContent {...props} />
          <StickyContent {...props} />
          {contentLoading && <AbsoluteLoader />}
          {!contentLoading &&
            (emptyContent || (
              <Table
                stickyHeader
                aria-label="sticky table variant1"
                className={classNames(styles.table, styles.variant1)}
              >
                <TableHead>
                  <TableRow>
                    {withCheckbox && (
                      <TableCell width={44}>
                        <div className={styles.wrapCheckbox}>
                          <CheckBox
                            checked={
                              rows.length === tableStore.checkedRowsIds.length
                            }
                            shape={
                              !!tableStore.checkedRowsIds.length &&
                              rows.length !== tableStore.checkedRowsIds.length
                                ? 'minus'
                                : 'default'
                            }
                            size={'medium'}
                            onChecked={onCheckedAll}
                          />
                        </div>
                      </TableCell>
                    )}
                    {newColumns.map((column) => (
                      <TableCell
                        key={column.id as string}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        className={classNames(
                          isLeftScroll && column.sticky && styles.stickyCell,
                          column.stickyRight && styles.stickyRight
                        )}
                        // sortDirection={orderBy === column.id ? order : false}
                      >
                        {column.labelContent && column.labelContent(tableStore)}
                        {noSorting ||
                        noSortsColumns?.includes(column.id as string) ? (
                          column.label
                        ) : (
                          <SortLabel
                            active={tableStore.orderBy === column.id}
                            direction={
                              tableStore.orderBy === column.id
                                ? tableStore.order.toLocaleLowerCase()
                                : 'asc'
                            }
                            onClick={() =>
                              createSortHandler(column.id as string)
                            }
                          >
                            {column.label}
                          </SortLabel>
                        )}
                      </TableCell>
                    ))}
                    {!(!rows.length && emptyContent) &&
                      !noSetting &&
                      !uiStore.isMobile && (
                        <TableCell className={classNames(styles.stickyRight)}>
                          <SettingBtn<T> tableStore={tableStore} />
                        </TableCell>
                      )}
                  </TableRow>
                  {withSelectAll && (
                    <AllSelected
                      colSpan={columns.length + 2}
                      tableStore={tableStore}
                    />
                  )}
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <RowComponent<T>
                      withCheckbox={withCheckbox}
                      isLeftScroll={isLeftScroll}
                      key={row.id}
                      row={row}
                      HoverRowComponent={
                        uiStore.isMobile ? undefined : HoverRowComponent
                      }
                      onRowClick={onRowClick}
                      columns={newColumns}
                      checkedRowsIds={tableStore.checkedRowsIds}
                      checkedRows={tableStore.checkedRows}
                      isSelectALl={tableStore.isSelectALl}
                      setCheckedRows={(rows) => tableStore.setCheckedRows(rows)}
                      noSetting={noSetting}
                      showVisibleActionsCount={showVisibleActionsCount}
                      actionsSize={actionsSize}
                    />
                  ))}
                </TableBody>
              </Table>
            ))}
        </TableContainer>
        {!noPagination &&
          !contentLoading &&
          (!!emptyContent || (
            <AppPagination
              rows={rows}
              tableStore={tableStore}
              key={tableStore.tableName}
              pageLoading={pageLoading}
            />
          ))}
      </div>
    </StyledEngineProvider>
  )
}

export default observer(NewTable)
