import { ColumnType } from 'components/NewTable/types'
import { numberString } from 'src/util/functions'
import { IConversionHitProjection } from 'widgets/conversions/ConversionDetail/store/types'
import dayjs, { DayjsFormats } from 'lib/dayjs'
import { capitalize } from 'lodash'
import { AppSymbols } from 'src/util/symbols'
import { Typography } from 'shared/Typography'

export const getColumns = (): ColumnType<IConversionHitProjection>[] => [
  {
    id: 'date',
    label: 'Converted at',
    format: (value) => dayjs(value as Date).format(DayjsFormats.fullWithAt),
  },
  {
    id: 'hitType',
    label: 'Type',
    format: (_, { hitType }) =>
      hitType ? (
        <Typography
          color={
            hitType === 'REVENUE'
              ? '--states-color-success-1'
              : '--primary-color-blue-1'
          }
        >
          {capitalize(hitType)}
        </Typography>
      ) : (
        AppSymbols.dash
      ),
  },
  { id: 'name', label: 'Name' },
  {
    id: 'phone',
    label: 'Phone',
  },
  {
    id: 'amount',
    label: 'Revenue',
    format: (_, { amount }) =>
      numberString({ val: amount || 0, currency: 'USD' }),
  },
]
