import { observer } from 'mobx-react-lite'
import { SelectList } from 'components/SelectList/SelectList'
import { Input } from 'shared/Input/Input'
import { Icon } from 'components/Icon/Icon'
import { DropdownField } from 'shared/DropdownField/DropdownField'
import * as React from 'react'
import { useConversionDetailContext } from 'widgets/conversions/ConversionDetail/context/context'

export const ConversionHitType = observer(() => {
  const {
    conversionHitTypeTitle,
    conversionHitTypeOptions,
    setConversionHitType,
  } = useConversionDetailContext()
  return (
    <DropdownField
      isSameWidth
      dropdownContent={() => (
        <SelectList
          options={conversionHitTypeOptions}
          onSelect={setConversionHitType}
        />
      )}
      triggerContent={(open) => (
        <Input
          width={150}
          isReadOnly
          value={conversionHitTypeTitle}
          placeholder={'Select'}
          rightContent={
            <Icon
              icon={open ? 'angleUp' : 'angleDown'}
              color={'--primary-color-gray-1'}
              fontSize={16}
            />
          }
        />
      )}
    />
  )
})
