// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KE72PIYcxHISdSlcVJwY{padding-inline:20px;display:flex;justify-content:space-between;align-items:center}.KE72PIYcxHISdSlcVJwY .fay4FgvUTrlW9wnqlUFm{display:grid;grid-template-columns:142px;column-gap:12px}`, "",{"version":3,"sources":["webpack://./src/pages/settings/integrations/header/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,4CACE,YAAA,CACA,2BAAA,CAEA,eAAA","sourcesContent":[".header{\n  padding-inline:  20px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  .right{\n    display: grid;\n    grid-template-columns: 142px;\n    //grid-template-columns: 142px 240px;\n    column-gap: 12px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `KE72PIYcxHISdSlcVJwY`,
	"right": `fay4FgvUTrlW9wnqlUFm`
};
export default ___CSS_LOADER_EXPORT___;
