import { observer } from 'mobx-react-lite'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'
import {
  TollFreeVerificationProfile,
  useAdminInitTfProfileContactInfoMutation,
  useInitTfProfileContactInfoMutation,
} from 'src/generated/graphql'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { PropsTollFreeStep } from 'src/pages/settings/compliance/TollFreeVerification/types'
import { TollFreeBackBtn } from 'src/pages/settings/compliance/TollFreeVerification/steps/TollFreeBackBtn'
import { InputText } from 'components/Input/InputText/InputText'
import { InputPhone } from 'components/Input/InputText/InputPhone'
import { ErrorsEnum } from 'src/static/errors'
import { useEffect } from 'react'
import businessProfileStore from 'store/settings/company/businessProfileStore'

export const ContactInfo = observer(({ onSuccess }: PropsTollFreeStep) => {
  const {
    isSuperAdminModal,
    contactInfo,
    setContactInfo,
    mapErrors,
    setError,
    contactInfoRequestInput,
    disabledContactInfo,
    continueText,
    adminOrgId,
  } = tollFreeVerificationStore

  const [initTfProfileBusinessInfo, { loading }] =
    useInitTfProfileContactInfoMutation()
  const [adminInitTfProfileBusinessInfo, { loading: loadingAdmin }] =
    useAdminInitTfProfileContactInfoMutation()

  useEffect(() => {
    setTimeout(() => {
      if (businessProfileStore.ownerFullBusinessProfile && !isSuperAdminModal) {
        if (
          !contactInfo &&
          businessProfileStore.ownerFullBusinessProfile.businessAuthorizedUsers
            ?.length
        ) {
          setContactInfo({
            firstName:
              businessProfileStore.ownerFullBusinessProfile
                .businessAuthorizedUsers[0]?.firstName,
            lastName:
              businessProfileStore.ownerFullBusinessProfile
                .businessAuthorizedUsers[0]?.lastName,
            email:
              businessProfileStore.ownerFullBusinessProfile
                .businessAuthorizedUsers[0]?.email,
            formattedPhoneNumber:
              businessProfileStore.ownerFullBusinessProfile
                .businessAuthorizedUsers[0]?.formattedPhoneNumber,
          })
        }
      }
    }, 50)
  }, [businessProfileStore.ownerFullBusinessProfile])

  const onContinue = async () => {
    try {
      let profile: TollFreeVerificationProfile | null | undefined
      if (adminOrgId) {
        const { data } = await adminInitTfProfileBusinessInfo({
          variables: {
            input: contactInfoRequestInput,
            organizationId: adminOrgId,
          },
        })
        profile = data?.adminInitTFProfileContactInfo
      } else {
        const { data } = await initTfProfileBusinessInfo({
          variables: {
            input: contactInfoRequestInput,
          },
        })
        profile = data?.initTFProfileContactInfo
      }

      profile && onSuccess(profile)
    } catch (e) {
      console.error(e)
    }
  }

  const clearError = (key: string) => {
    setError(key, false)
  }

  const onCheckEmail = () => {
    const email = contactInfo?.email || ''
    const isWrong = !(email.includes('@') && email.includes('.'))
    setError('contactEmail', isWrong)
  }

  return (
    <div className={'col40'}>
      <div className={'col24'}>
        <div className={'row12'}>
          <InputText
            label={'First Name'}
            placeholder={'e.g. Adam'}
            value={contactInfo?.firstName || ''}
            onChangeValue={(value) =>
              setContactInfo({
                ...contactInfo,
                firstName: value,
              })
            }
          />
          <InputText
            label={'Last Name'}
            placeholder={'e.g. Johnson'}
            value={contactInfo?.lastName || ''}
            onChangeValue={(value) =>
              setContactInfo({
                ...contactInfo,
                lastName: value,
              })
            }
          />
        </div>
        <InputText
          error={
            (mapErrors.get('contactEmail') && ErrorsEnum.verifyEmail) || ''
          }
          onFocus={() => clearError(`contactEmail`)}
          onBlur={onCheckEmail}
          label={'Business email'}
          placeholder={'e.g. adam.johnson@example.com'}
          value={contactInfo?.email || ''}
          onChangeValue={(value) =>
            setContactInfo({
              ...contactInfo,
              email: value,
            })
          }
        />
        <InputPhone
          error={
            (mapErrors.get('contactPhoneNumber') &&
              ErrorsEnum.validPhoneNumber) ||
            ''
          }
          onFocus={() => clearError(`contactPhoneNumber`)}
          label={'Phone number'}
          noIcon
          value={contactInfo?.formattedPhoneNumber || ''}
          onChangeValue={(value) =>
            setContactInfo({
              ...contactInfo,
              formattedPhoneNumber: value,
            })
          }
        />
      </div>

      <div className={'row12'}>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          onClick={onContinue}
          disabled={disabledContactInfo}
          loading={loading || loadingAdmin}
        >
          {continueText}
        </Button>
        <TollFreeBackBtn />
      </div>
    </div>
  )
})
