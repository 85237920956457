// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SNakFcf9DmrciLDpowXo{padding:8px 8px 20px 8px}.YUXu8QlJonc0MjK8Govk{display:grid;grid-template-columns:104px 1fr;column-gap:8px}.nBA6RoIlF18lkKsTXtgw{background:var(--primary-color-gray-4);margin-inline:-8px;border-bottom-left-radius:8px;border-bottom-right-radius:8px}.hdprVg0oVEiRkfKjg1Q1{padding:0 16px 16px 16px}`, "",{"version":3,"sources":["webpack://./src/widgets/CampaignSchedule/ui/ContactDateFieldCard/ContactDateFieldContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CAEF,sBACE,YAAA,CACA,+BAAA,CACA,cAAA,CAGF,sBACE,sCAAA,CAEA,kBAAA,CACA,6BAAA,CACA,8BAAA,CAEF,sBACE,wBAAA","sourcesContent":[".wrap{\n  padding: 8px 8px 20px 8px;\n}\n.counter{\n  display: grid;\n  grid-template-columns: 104px 1fr;\n  column-gap: 8px;\n}\n\n.time{\n  background: var(--primary-color-gray-4);\n\n  margin-inline: -8px;\n  border-bottom-left-radius: 8px;\n  border-bottom-right-radius: 8px;\n}\n.wrapTimePicker{\n  padding: 0 16px 16px 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `SNakFcf9DmrciLDpowXo`,
	"counter": `YUXu8QlJonc0MjK8Govk`,
	"time": `nBA6RoIlF18lkKsTXtgw`,
	"wrapTimePicker": `hdprVg0oVEiRkfKjg1Q1`
};
export default ___CSS_LOADER_EXPORT___;
