// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BwM3pt8Tet84YqL7yZJ1{margin-top:28px;padding-left:24px;margin-right:64px;display:flex;column-gap:8px;align-items:center}.vxunB45oe3Qx6gYdpuGQ{width:100%;display:grid;grid-template-columns:1fr min-content;align-items:center}`, "",{"version":3,"sources":["webpack://./src/pages/main/contacts/segments/DetailSegment/components/header/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,iBAAA,CACA,iBAAA,CACA,YAAA,CACA,cAAA,CACA,kBAAA,CAEF,sBACE,UAAA,CACA,YAAA,CACA,qCAAA,CACA,kBAAA","sourcesContent":[".content{\n  margin-top: 28px;\n  padding-left: 24px;\n  margin-right: 64px;\n  display: flex;\n  column-gap: 8px;\n  align-items: center;\n}\n.row{\n  width: 100%;\n  display: grid;\n  grid-template-columns: 1fr min-content;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `BwM3pt8Tet84YqL7yZJ1`,
	"row": `vxunB45oe3Qx6gYdpuGQ`
};
export default ___CSS_LOADER_EXPORT___;
