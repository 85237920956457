import { ActionBtnProps } from 'components/NewTable/types'
import * as React from 'react'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import { useCampaignFunctions } from 'src/pages/main/campaigns/hooks/useCampaignFunctions'
import { ICampaign } from 'src/types/ICampaign'

export const CampaignEditBtn = (props: ActionBtnProps<ICampaign>) => {
  const { row, rows } = props
  const { onEdit } = useCampaignFunctions(row, rows)
  return <TableBtn {...props} action={ActionsEnum.edit} onAction={onEdit} />
}
