import { ITypographyProps, Typography } from '../Typography'
import React, { CSSProperties } from 'react'
import {
  InfoTooltip,
  InfoTooltipProps,
} from 'components/InfoTooltip/InfoTooltip'
import classNames from 'classnames'
import styles from './styles.module.scss'

export type ILabelProps = {
  marginBottom?: CSSProperties['marginBottom']
  text: React.ReactNode
  isRequired?: boolean
  isOptional?: boolean
  typographyProps?: ITypographyProps
  infoTooltipProps?: InfoTooltipProps
}

export const Label = ({
  marginBottom,
  text,
  isRequired,
  isOptional,

  typographyProps,
  infoTooltipProps,
}: ILabelProps) => (
  <Typography
    variant={'s2-medium'}
    color={'--primary-color-gray-1'}
    {...typographyProps}
    style={{ marginBottom, ...typographyProps?.style }}
    className={classNames(styles.wrap, typographyProps?.className)}
  >
    {text}
    {isRequired && (
      <>
        <Typography
          variant={typographyProps?.variant || 's2-medium'}
          color={'--states-color-error-1'}
        >
          *
        </Typography>
      </>
    )}
    {isOptional && (
      <>
        <Typography
          variant={typographyProps?.variant || 's2-medium'}
          color={'--primary-color-gray-2'}
        >
          (optional)
        </Typography>
      </>
    )}
    {infoTooltipProps && <InfoTooltip marginLeft={0} {...infoTooltipProps} />}
  </Typography>
)
