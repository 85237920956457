import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import billingStore from 'store/settings/billing/billingStore'
import { ModalIcon } from 'components/ModalIcon/ModalIcon'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import upgradePlanStore from 'src/Modals/UpgradePlanModal/store/upgradePlanStore'
import { useSetBalance } from 'src/pages/settings/billing/hooks/useSetBalance'
import { SizedBox } from 'components/SizedBox'

export const BuySubscriptionModal = observer(() => {
  useSetBalance()
  const onClose = () => {
    billingStore.setOpenBuySubscriptionModal(false)
    upgradePlanStore.setIsDowngradeSubscription(false)
  }

  return (
    <Modal
      open={billingStore.openBuySubscriptionModal}
      onClose={onClose}
      withoutHeader
    >
      <div className={'pModalInfo'}>
        <ModalIcon />
        <h3>
          {upgradePlanStore.isDowngradeSubscription ? (
            <>
              You have downgraded <br />
              to <b>{billingStore.nextClSubscriptionTitle}</b> plan
            </>
          ) : (
            <>
              Congratulations! <br /> You have just upgraded to{' '}
              <b>{billingStore.clSubscriptionTitle}</b> plan
            </>
          )}
        </h3>
        {upgradePlanStore.isDowngradeSubscription && (
          <p className={'par2 gray1'}>
            <>
              The downgrade will take affect at the end of the billing cycle:{' '}
              <b>{billingStore.clSubscriptionRenews}</b>. All your monthly
              credits from previous tier will disappear. You’ll be able to
              purchase credits with{' '}
              {billingStore.downgradedPrice?.additionalCreditPrice}¢/credit
            </>
          </p>
        )}
        {!upgradePlanStore.isDowngradeSubscription && <SizedBox height={20} />}
        <Button
          typeBtn={BtnType.primary}
          size={BtnSize.medium}
          fullWidth
          onClick={onClose}
        >
          Done
        </Button>
      </div>
    </Modal>
  )
})
