import { RoutesEnum } from 'src/pages/routes'
import { FC, ReactNode } from 'react'
import { IIconType } from 'components/AppIcon/AppIcon'
import { IIcon } from 'components/Icon/Icon'

export enum NavType {
  dashboard = 'dashboard',
  settings = 'settings',
  create = 'create',
  navLink = 'navLink',
  logout = 'logout',
  help = 'help',
  productUpdates = 'productUpdates',
  switchOrg = 'switchOrg',
}

export interface INavItem {
  text: string
  route?: RoutesEnum
  rightIconRoute?: string
  leftIcon?: IIcon
  LeftIcon?: FC
  RightIcon?: FC
  type: NavType
  marginBottom?: number
  noMask?: boolean
  separator?: boolean
  separatorBottom?: boolean
  iconType?: IIconType
  rightContent?: ReactNode
  separatorMargin?: number
  isNew?: boolean
}

export const pages: { [key: string]: string } = {
  [RoutesEnum.dashboard]: 'Dashboard',
  [RoutesEnum.create]: 'Create Message',
  [RoutesEnum.broadcasts]: 'Broadcasts',
  [RoutesEnum.newBroadcast]: 'New broadcast',
  [RoutesEnum.triggers]: 'Triggers',
  [RoutesEnum.newTrigger]: 'New trigger',
  [RoutesEnum.keywords]: 'Keywords',
  [RoutesEnum.newKeyword]: 'New keyword',
  [RoutesEnum.webForms]: 'Web Forms',
  [RoutesEnum.newWebForm]: 'New web form',
  [RoutesEnum.adminOrganizations]: 'Organizations',
  [RoutesEnum.contacts]: 'Contacts',
}
