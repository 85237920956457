import { Card } from 'components/Card/Card'
import { ToggleBtn } from 'components/ToggleBtn/ToggleBrn'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { FC, useState } from 'react'
import styles from './styles.module.scss'
import { TTPositionEnum } from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { SizedBox } from 'components/SizedBox'
import { PerformanceChart } from 'src/pages/main/dashboard/Performance/PerformanceChart/PerformanceChart'
import { IOptionPerformanceButtons } from 'src/pages/main/dashboard/Performance/Perfomance'
import { IOption } from 'src/types/IOption'

type Props = {
  optionsButtons: IOptionPerformanceButtons[]
  optionsPeriods: IOption[]
}

export const PerformanceCard: FC<Props> = ({
  optionsButtons,
  optionsPeriods,
}) => {
  const [type, setType] = useState(optionsButtons[0].value)
  const [selectedPeriod, setSelectedPeriod] = useState(optionsPeriods[0])
  return (
    <Card padding={24}>
      <div className={styles.row}>
        <ToggleBtn
          options={optionsButtons}
          activeValue={type}
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          onOptionClick={setType}
        />
        <Dropdown
          className={styles.drop}
          selectedOption={selectedPeriod}
          onSelect={setSelectedPeriod}
          options={optionsPeriods}
          dropDownContentWidth={128}
          position={TTPositionEnum.bottomRight}
        />
      </div>
      <SizedBox height={32} />
      <PerformanceChart type={type} />
    </Card>
  )
}
