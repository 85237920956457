import React, { ButtonHTMLAttributes, ForwardedRef, forwardRef } from 'react'
import { Icon, IIcon } from 'components/Icon/Icon'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { Typography } from 'shared/Typography'
import { Loader } from 'components/Loader/Loader'

export type IButtonProps = {
  variant:
    | 'primary-medium'
    | 'secondary-medium'
    | 'quaternary-medium'
    | 'tertiary-small'
  text?: string
  leftIcon?: IIcon
  loading?: boolean
  onClick?: ButtonHTMLAttributes<HTMLButtonElement>['onClick']
  className?: ButtonHTMLAttributes<HTMLButtonElement>['className']
  disabled?: ButtonHTMLAttributes<HTMLButtonElement>['disabled']
  advanced?: boolean
  fullWidth?: boolean
}

// eslint-disable-next-line react/display-name
export const Button = forwardRef(
  (
    {
      variant,
      leftIcon,
      text,
      onClick,
      className,
      loading,
      advanced,
      fullWidth,
      ...props
    }: IButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const type = variant?.split('-')[0]
    const size = variant?.split('-')[1]

    const getIndeterminateColor = () => {
      if (type === 'primary') {
        return '--primary-color-white'
      }
      if (type === 'secondary') {
        return '--primary-color-gray-2'
      }
    }
    return (
      <button
        onClick={onClick}
        className={classNames(
          styles.wrap,
          {
            [styles[`type__${type}`]]: type,
            [styles[`size__${size}`]]: size,
            [styles.advanced]: advanced,
            [styles.fullWidth]: fullWidth,
          },
          className
        )}
        {...props}
        ref={ref}
      >
        {loading && (
          <div className={styles.loading}>
            <Loader size={16} indeterminateColor={getIndeterminateColor()} />
          </div>
        )}
        {leftIcon && <Icon icon={leftIcon} className={styles.icon} />}
        {text && (
          <Typography variant={'s1-bold'} isUnsetColor className={styles.text}>
            {text}
          </Typography>
        )}
      </button>
    )
  }
)
