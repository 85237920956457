import { useCardDropdownOptions } from 'src/pages/settings/billing/components/hooks/useCardDropdownOptions'
import { useEffect, useRef, useState } from 'react'
import { StripeError } from '@stripe/stripe-js'
import { nanoid } from 'nanoid'
import { Alert } from 'components/Alert/Alert'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { SizedBox } from 'components/SizedBox'
import billingStore from 'store/settings/billing/billingStore'

export function useCreditCard({
  setLoading,
}: {
  setLoading: (value: boolean) => void
}) {
  const [selectedValue, onSelectValue] = useCardDropdownOptions()
  const refBtn = useRef<HTMLButtonElement | null>(null)
  const [readyCard, setReadyCard] = useState(false)

  const [error, setCardError] = useState('')

  const onFailed = (error: StripeError) => {
    setCardError(error.message || nanoid())
    setLoading(false)
  }

  const errorAlert = error && (
    <>
      <Alert
        title={'We couldn’t verify this card'}
        text={
          'Please select a different payment method or check your card details.'
        }
        type={AlertTypeEnum.error}
      />
      <SizedBox height={20} />
    </>
  )

  useEffect(() => {
    if (selectedValue && selectedValue === billingStore.upgradeCardId) {
      setCardError(nanoid())
    } else {
      setCardError('')
    }
  }, [selectedValue])

  return {
    selectedValue,
    onSelectValue,
    refBtn,
    readyCard,
    setReadyCard,
    onFailed,
    error,
    errorAlert,
    setCardError,
  }
}
