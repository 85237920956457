import modalStore from 'src/widgets/ModalContainer/store/modalStore'
import { NewConversion } from 'widgets/conversions/NewConversion/NewConversion'
import { ConversionResponse } from 'src/pages/settings/conversions/conversions/store/type'

export const openNewConversionModal = (
  onSuccess: (response: ConversionResponse) => void
) => {
  const id = 'New conversion'
  const onClose = () => {
    modalStore.closeModal(id)
  }

  modalStore.addModal({
    id,
    title: 'New conversion',
    minWidth: 576,
    children: <NewConversion onClose={onClose} onSuccess={onSuccess} />,
  })
}
