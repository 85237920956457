// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c9MvMMBKZ3GUAgBMyJty{display:flex;column-gap:8px;align-items:center}.c9MvMMBKZ3GUAgBMyJty .box24{background-color:#fff}`, "",{"version":3,"sources":["webpack://./src/pages/main/triggers/TriggersTable/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,cAAA,CACA,kBAAA,CAEE,6BACE,qBAAA","sourcesContent":[".appWrap{\n  display: flex;\n  column-gap: 8px;\n  align-items: center;\n  :global{\n    .box24{\n      background-color: white;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appWrap": `c9MvMMBKZ3GUAgBMyJty`
};
export default ___CSS_LOADER_EXPORT___;
