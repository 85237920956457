import campaignDetailStore from 'store/campaigns/campaignDetailStore'
import styles from 'src/pages/main/campaigns/detail/styles.module.scss'
import classNames from 'classnames'
import { TypeContent } from 'components/TypeContent/TypeContent'
import { HeaderContent } from 'src/pages/main/campaigns/detail/header/HeaderContent'
import { Tabs } from 'components/Tabs/Tabs'
import { OverviewDetailTable } from 'src/pages/main/campaigns/detail/OverviewDetailTable/OverviewDetailTable'
import { CampaignDetailTable } from 'src/pages/main/campaigns/detail/CampaignDetailTable/CampaignDetailTable'
import { CampaignStepResponse, ContentType } from 'src/generated/graphql'
import { observer } from 'mobx-react-lite'

export const CampaignDetailContent = observer(() => {
  const { activeTabIndex, campaign, setActiveTabIndex } = campaignDetailStore
  const messageToTitle = (step: CampaignStepResponse | null, index: number) => {
    const active = activeTabIndex === index + 1
    return (
      <div className={styles.tabItem}>
        <div className={classNames(styles.counter, active && styles.active)}>
          <span className={classNames('medium', active ? 'white' : 'gray1')}>
            {index + 1}
          </span>
        </div>
        {step?.name}{' '}
        <TypeContent type={step?.type || ContentType.Sms} gray={!active} />
      </div>
    )
  }
  if (!campaign) {
    return null
  }
  return (
    <>
      <HeaderContent />
      <div className={styles.wrapTabs}>
        <Tabs
          tabs={[
            'Overview',
            ...campaign.campaignStepResponses?.map(messageToTitle),
          ]}
          activeTabIndex={activeTabIndex}
          onTabClick={setActiveTabIndex}
        />
      </div>
      {activeTabIndex === 0 ? <OverviewDetailTable /> : <CampaignDetailTable />}
    </>
  )
})
