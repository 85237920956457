import NewTable from 'components/NewTable/NewTable'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { LeftStickyContent } from 'src/pages/main/triggers/TriggersTable/Filters/LeftStickyContent'
import { ITrigger } from 'src/types/ITrigger'
import triggersStore from 'store/triggers/triggersStore'
import { Status, useGetTriggersQuery } from 'src/generated/graphql'
import { EmptyState } from 'components/EmptyState/EmptyState'
import * as React from 'react'
import { noSortsColumns } from 'components/NewTable/columns/triggersTableColumns'
import { useSetData } from 'src/hooks/useSetData'
import { getTriggerBtnActions } from 'src/pages/main/triggers/functions/getTriggerBtnActions'
import { TriggerDetailModal } from 'src/pages/main/triggers/modals/TriggerDetailModal'
import { CopyWebhook } from 'src/pages/main/triggers/components/CopyWebhook'
import { AppElements } from 'src/enums/appElements'

export const TriggersTable = observer(() => {
  const navigate = useNavigate()

  const queryResult = useGetTriggersQuery({
    variables: {
      page: triggersStore.tableStore.paginationInput,
    },
  })

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData: (data) => triggersStore.setData(data),
    refreshDeps: [triggersStore.tableStore.refreshTrigger],
  })

  const onRowClick = (id: string, row: ITrigger) => {
    if (row.status === Status.Draft) {
      navigate(id + '/edit')
    } else {
      navigate(id)
    }
  }

  const rows = triggersStore.triggers

  if (!firstLoading && !rows.length) {
    return (
      <EmptyState
        element={AppElements.Trigger}
        description={'Send instant messages via API or webhooks'}
      />
    )
  }

  return (
    <>
      <TriggerDetailModal />
      <NewTable<ITrigger>
        noSortsColumns={noSortsColumns}
        withCheckbox
        columns={triggersStore.tableStore.visibleColumns}
        rows={rows.map((row) => ({
          ...row,
          actions: getTriggerBtnActions(row),
          defaultVisibleMoreBtnContent:
            triggersStore.createTriggerId === row.id &&
            row.status !== Status.Draft ? (
              <CopyWebhook webhookLink={row.link} />
            ) : undefined,
          onHideDefaultVisible: () => triggersStore.setCreateTriggerId(''),
        }))}
        leftStickyContent={<LeftStickyContent />}
        onRowClick={onRowClick}
        withCalcMaxHeight
        tableStore={triggersStore.tableStore}
        pageLoading={loading || refetchLoading}
        firstLoading={firstLoading}
      />
    </>
  )
})
