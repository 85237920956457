// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UBf5D2Rra3Bf71ltErNJ{display:grid;grid-template-columns:512px 404px;column-gap:64px}.MKLC_nAVKR4O5wx_oC1f{padding-top:8px;display:grid;row-gap:24px}.asdkWxNacDrRKvzWgoZv{display:grid;grid-template-columns:1fr 1fr;column-gap:16px}.ZCgRzXA1BSoBH5CkF7g5{margin-top:40px;display:flex;column-gap:12px}`, "",{"version":3,"sources":["webpack://./src/pages/settings/account/routes/MyProfile/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,iCAAA,CACA,eAAA,CAEF,sBACE,eAAA,CACA,YAAA,CACA,YAAA,CAEF,sBACE,YAAA,CACA,6BAAA,CACA,eAAA,CAEF,sBACE,eAAA,CACA,YAAA,CACA,eAAA","sourcesContent":[".wrap{\n  display: grid;\n  grid-template-columns: 512px 404px;\n  column-gap: 64px;\n}\n.content{\n  padding-top: 8px;\n  display: grid;\n  row-gap: 24px;\n}\n.row{\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  column-gap: 16px;\n}\n.actions{\n  margin-top: 40px;\n  display: flex;\n  column-gap: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `UBf5D2Rra3Bf71ltErNJ`,
	"content": `MKLC_nAVKR4O5wx_oC1f`,
	"row": `asdkWxNacDrRKvzWgoZv`,
	"actions": `ZCgRzXA1BSoBH5CkF7g5`
};
export default ___CSS_LOADER_EXPORT___;
