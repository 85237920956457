import NewTable from 'components/NewTable/NewTable'
import * as React from 'react'
import { Status, useGetCampaignStepMessagesQuery } from 'src/generated/graphql'
import { AdditionalContent } from './AdditionalContent/AdditionalContent'
import { LeftStickyContent } from './LeftStickyContent/LeftStickyContent'
import campaignDetailStore from 'store/campaigns/campaignDetailStore'
import { observer } from 'mobx-react-lite'
import { useSetData } from 'src/hooks/useSetData'
import { EmptyStateStatistic } from 'components/EmptyState/EmptyStateStatistic'
import { IStatisticMessage } from 'src/types/IDetailMessage'
import { messageStatisticsNoSortsColumns } from 'components/NewTable/columns/messageStatisticsColumns'

export interface ICampaignDetailTable {
  id: string
  name: string
  status: Status
  phone: string
  sendTime: number
  answered?: string
}

export const CampaignDetailTable = observer(() => {
  const {
    activeStep,
    stepStatisticTableStore,
    setStepMessages,
    stepStatisticMessages,
  } = campaignDetailStore

  const queryResult = useGetCampaignStepMessagesQuery({
    variables: {
      page: stepStatisticTableStore.paginationInput,
      status: stepStatisticTableStore.statusBackEnd,
      dateRange: stepStatisticTableStore.rangeDatePickerStore.dateRange,
      uuid: activeStep?.uuid,
    },
    skip: stepStatisticTableStore.rangeDatePickerStore.isSkipByDateRange,
  })

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData: (data) => setStepMessages(data),
    refreshDeps: [stepStatisticTableStore.refreshTrigger],
    disabledRefetch:
      stepStatisticTableStore.rangeDatePickerStore.isSkipByDateRange,
  })

  return (
    <NewTable<IStatisticMessage>
      columns={stepStatisticTableStore.visibleColumns}
      rows={stepStatisticMessages || []}
      withCalcMaxHeight
      additionalContent={<AdditionalContent />}
      tableStore={stepStatisticTableStore}
      pageLoading={loading || refetchLoading}
      firstLoading={firstLoading}
      emptyContent={
        stepStatisticMessages.length ? undefined : <EmptyStateStatistic />
      }
      contentLoading={loading && !stepStatisticMessages.length}
      noSortsColumns={messageStatisticsNoSortsColumns}
      leftStickyContent={<LeftStickyContent />}
      // withSearch
    />
  )
})
