import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'
import { observer } from 'mobx-react-lite'
import businessProfileStore from 'store/settings/company/businessProfileStore'
import { CircularProgress } from 'components/CircularProgress/CircularProgress'
import classNames from 'classnames'
import { ActionsEnum } from 'components/Button/types'
import * as React from 'react'
import { SizedBox } from 'components/SizedBox'
import { useSetFullBusinessProfile } from 'src/pages/settings/compliance/BusinessProfile/hooks/useSetFullBusinessProfile'
import addNumberStore from 'store/settings/numbers/addNumberStore'
import { SmallBtn } from 'components/Button/SmallBtn'

export const BusinessProfileCard = observer(
  ({ fromModal, noAction }: { fromModal?: boolean; noAction?: boolean }) => {
    const { loading } = useSetFullBusinessProfile()
    const navigate = useNavigate()
    const onRegister = () => {
      if (fromModal) {
        addNumberStore.setOpenAddNumberModal(false)
        navigate(RoutesEnum.settingsCompliance + '/' + RoutesEnum.business)
      } else {
        navigate(RoutesEnum.settingsCompliance + '/' + RoutesEnum.business)
      }
    }
    const onEdit: React.MouseEventHandler<HTMLButtonElement> = (e) => {
      e.stopPropagation()
      if (fromModal) {
        addNumberStore.setOpenAddNumberModal(false)
        navigate(RoutesEnum.settingsCompliance + '/' + RoutesEnum.business)
      } else {
        navigate(RoutesEnum.settingsCompliance + '/' + RoutesEnum.business)
      }
    }
    const onOpenModal = () => {
      if (businessProfileStore.completed) {
        businessProfileStore.setOpenModal(true)
      }
    }
    return (
      <div>
        {businessProfileStore.completed && (
          <span className={'s2 gray1 medium mb8'}>Business profile</span>
        )}
        {/*eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions*/}
        <div
          className={classNames(
            styles.wrap,
            businessProfileStore.completed && styles.completed
          )}
          onClick={onOpenModal}
        >
          <div className={'col16'}>
            <div className={'col8'}>
              <p>
                <b>
                  {businessProfileStore.completed
                    ? businessProfileStore.businessNameString
                    : 'Business profile'}
                </b>
              </p>
              <span
                className={classNames(
                  's2 gray1',
                  fromModal && styles.fromModal
                )}
              >
                {businessProfileStore.completed
                  ? businessProfileStore.addressString
                  : 'Register your Business profile and get access to products that prevent your messages from being blocked by carriers.'}
              </span>
            </div>
            {!!businessProfileStore.completedStep &&
              !businessProfileStore.completed && (
                <div className={'row8'}>
                  <CircularProgress
                    progress={businessProfileStore.completedPercent}
                    small
                  />
                  <span className={'gray1'}>
                    {businessProfileStore.completedStep}/
                    {businessProfileStore.stepCount} steps
                  </span>
                </div>
              )}
          </div>

          {!noAction &&
            (businessProfileStore.completed ? (
              <div className={'row24'}>
                {/*{businessProfileStore.businessProfile?.hasEin &&*/}
                {/*  businessProfileStore.businessProfile?.status && (*/}
                {/*    <StatusContent*/}
                {/*      status={businessProfileStore.businessProfile?.status}*/}
                {/*      text={businessProfileStore.businessMapping.get(*/}
                {/*        businessProfileStore.businessProfile?.status*/}
                {/*      )}*/}
                {/*    />*/}
                {/*  )}*/}
                {/*{(businessProfileStore.businessProfile?.status ===*/}
                {/*  BusinessProfileStatus.TwilioRejected ||*/}
                {/*  !businessProfileStore.isHaveEIN) && (*/}
                {/*  <SmallBtn type={ActionsEnum.edit} gray4 onClick={onEdit} />*/}
                {/*)}*/}
                <SmallBtn type={ActionsEnum.edit} gray4 onClick={onEdit} />
              </div>
            ) : (
              <Button
                size={BtnSize.medium}
                typeBtn={fromModal ? BtnType.secondaryBlue : BtnType.primary}
                onClick={onRegister}
                loading={!fromModal && loading}
                disabled={fromModal && loading}
              >
                {!!businessProfileStore.completedStep &&
                !businessProfileStore.completed
                  ? 'Resume'
                  : 'Register'}
              </Button>
            ))}
        </div>
        {businessProfileStore.completed && <SizedBox height={24} />}
      </div>
    )
  }
)
