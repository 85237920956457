import { Alert } from 'components/Alert/Alert'
import { SizedBox } from 'components/SizedBox'
import { InputText } from 'components/Input/InputText/InputText'
import { ReactComponent as Phone } from 'icons/16px/Phone.svg'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import styles from './styles.module.scss'

export const ReceivePhoneCall = () => {
  return (
    <div>
      <Alert
        title={
          'Enter the phone number that you would like us to call. It can take up to 1 minute'
        }
      />
      <div className={styles.wrapReceive}>
        <InputText
          patternFormat
          format={'(###) ###-####'}
          maskFormat={'_'}
          placeholder={'(123) 123-1234'}
          leftIconContent={<Phone />}
        />
        <SizedBox height={16} />
        <Button typeBtn={BtnType.primary} size={BtnSize.small} p12>
          Confirm
        </Button>
      </div>
    </div>
  )
}
