import { createContext, useContext } from 'react'
import { CustomPlanStore } from 'src/pages/superAdmin/modals/CustomPlan/store/CustomPlanStore'

export const CustomPlanContext = createContext<CustomPlanStore | null>(null)

export function useCustomPlanContext() {
  const context = useContext(CustomPlanContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with CustomPlanModalContext'
    )

  return context
}
