import { makeAutoObservable } from 'mobx'
import { RangeOption, rangeOptions } from 'store/rangeDatePickerStore/types'
import { DateRangeCriteriaInput } from 'src/generated/graphql'

export class RangeDatePickerStore {
  constructor(withWeekRangePeriod?: boolean) {
    makeAutoObservable(this)
    if (withWeekRangePeriod) {
      this.startDate = new Date(rangeOptions[0].value[0])
      this.endDate = new Date(rangeOptions[0].value[1])
    }
  }
  startDate: Date | null = null
  startTime: Date | null = null
  endDate: Date | null = null
  endTime: Date | null = null
  allTime = true
  selectedPeriod: RangeOption | null = null

  get dateRange(): DateRangeCriteriaInput | null {
    if (this.allTime) {
      return null
    }
    return {
      from: this.startDate,
      to: this.endDate,
    }
  }

  get isSkipByDateRange() {
    if (this.dateRange) {
      if (!this.dateRange.from) {
        return true
      }
      if (!this.dateRange.to) {
        return true
      }
    }
    return false
  }

  setStartDate = (date: Date | null) => {
    this.startDate = date
  }

  setEndDate = (date: Date | null) => {
    this.endDate = date
  }

  setStartTime = (date: Date | null) => {
    this.allTime = false
    this.startTime = date
  }

  setEndTime = (date: Date | null) => {
    this.allTime = false
    this.endTime = date
  }

  setAllTime = (state: boolean) => {
    this.allTime = state
  }

  setSelectedPeriod = (period: RangeOption | null) => {
    this.selectedPeriod = period
  }
}
