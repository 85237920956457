import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useTriggersDetailContext } from 'src/pages/main/triggers/detail/triggerDetail'
import {
  ContentType,
  useGetTriggerMessageStatisticsQuery,
} from 'src/generated/graphql'
import { MessageStatisticCards } from 'components/MessageStatisticCards/MessageStatisticCards'

export const AdditionalContent = observer(() => {
  const context = useTriggersDetailContext()
  const { tableStore, trigger } = context
  const { data, refetch } = useGetTriggerMessageStatisticsQuery({
    variables: {
      uuid: trigger?.uuid,
      dateRange: tableStore.rangeDatePickerStore.dateRange,
    },
    skip: tableStore.rangeDatePickerStore.isSkipByDateRange,
  })

  useEffect(() => {
    context.setMessageStatistics(data?.getTriggerMessageStatistics || null)
  }, [data])

  useEffect(() => {
    refetch().catch(console.error)
  }, [tableStore.refreshTrigger])
  return (
    <MessageStatisticCards
      messageStatistic={data?.getTriggerMessageStatistics}
      type={trigger?.type || ContentType.Sms}
      tableStore={tableStore}
      withToday
    />
  )
})
