// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.T090koa54QYq_ONBNt7a{padding:0 16px 16px 16px;display:grid;gap:8px}.p0iEm03wLEdjPgf0bulR{background:var(--primary-color-white);padding:12px 12px 16px 12px;border-radius:8px;box-shadow:inset 0 0 0 1px var(--primary-color-gray-3)}`, "",{"version":3,"sources":["webpack://./src/widgets/AdvancedScheduling/ui/ActiveContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,YAAA,CACA,OAAA,CAEF,sBACE,qCAAA,CACA,2BAAA,CACA,iBAAA,CACA,sDAAA","sourcesContent":[".wrap{\n  padding: 0 16px 16px 16px;\n  display: grid;\n  gap: 8px;\n}\n.wrapDaySelector{\n  background: var(--primary-color-white);\n  padding: 12px 12px 16px 12px;\n  border-radius: 8px;\n  box-shadow: inset 0 0 0 1px var(--primary-color-gray-3);\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `T090koa54QYq_ONBNt7a`,
	"wrapDaySelector": `p0iEm03wLEdjPgf0bulR`
};
export default ___CSS_LOADER_EXPORT___;
