import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { InputText } from 'components/Input/InputText/InputText'
import classNames from 'classnames'
import { SizedBox } from 'components/SizedBox'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import {
  PlanPeriod,
  useAdminCreateCustomPlanMutation,
  useAdminUpdateCustomPlanMutation,
} from 'src/generated/graphql'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { IOption } from 'src/types/IOption'
import { useCustomPlanContext } from 'src/pages/superAdmin/modals/CustomPlan/context/context'
import { CustomPrices } from 'src/pages/superAdmin/modals/CustomPlan/ui/CustomPrices/CustomPrices'

export const CustomPlanUi = observer(() => {
  const {
    id,
    name,
    setName,
    description,
    setDescription,
    credits,
    setCredits,
    priceValue,
    setPriceValue,
    costCredit,
    setCostCredit,
    period,
    setPeriod,
    extraCreditPrice,
    setExtraCreditPrice,
    onClose,
    isEdit,
    addPrice,
    updatePrice,
    chargeObjectPrices,
  } = useCustomPlanContext()
  const [createCustomPlan, { loading: loadingCreate }] =
    useAdminCreateCustomPlanMutation()
  const [updateCustomPlan, { loading: loadingUpdate }] =
    useAdminUpdateCustomPlanMutation()

  const disabled = !name.trim() || !credits || !priceValue || !extraCreditPrice

  const onAction = async () => {
    try {
      if (isEdit) {
        const { data } = await updateCustomPlan({
          variables: {
            input: {
              extraCreditPrice,
              name,
              description,
              id,
              chargeObjectPrices,
            },
          },
        })
        if (data?.adminUpdateCustomPlan) {
          updatePrice(data.adminUpdateCustomPlan)
        }
      } else {
        const { data } = await createCustomPlan({
          variables: {
            input: {
              credits,
              period,
              price: priceValue,
              extraCreditPrice,
              name,
              description,
              chargeObjectPrices,
            },
          },
        })
        if (data?.adminCreateCustomPlan) {
          addPrice(data.adminCreateCustomPlan)
        }
      }
      onClose()
    } catch (e) {
      console.error(e)
    }
  }

  const options: IOption[] = [
    {
      value: PlanPeriod.Monthly,
      title: 'Monthly',
    },
    {
      value: PlanPeriod.Quarterly,
      title: 'Quarterly',
    },
    {
      value: PlanPeriod.Semiannual,
      title: 'Semi-Annual',
    },
    {
      value: PlanPeriod.Annual,
      title: 'Annual',
    },
  ]

  const onSetCostCredit = (cost: number) => {
    setCostCredit(cost)
    if (credits) {
      setPriceValue(Number((credits * cost).toFixed(4)))
    }
  }

  return (
    <div className={classNames('pModal', styles.content)}>
      <div className={styles.row}>
        <span>
          Plan Name <span className={'error1'}>*</span>
        </span>
        <InputText value={name} onChangeValue={setName} />
      </div>
      <div className={styles.row}>
        <span>Description</span>
        <InputText value={description} onChangeValue={setDescription} />
      </div>
      <div className={styles.row}>
        <span>Period</span>
        <Dropdown
          options={options}
          selectedValue={period}
          onSelectValue={setPeriod}
          disabled={isEdit}
        />
      </div>
      <div className={styles.row}>
        <span>
          Credits <span className={'error1'}>*</span>
        </span>
        <InputText
          disabled={isEdit}
          numericFormat
          value={credits || ''}
          onChangeValue={setCredits}
          required
        />
      </div>
      <div className={styles.row}>
        <span>
          Price, $ <span className={'error1'}>*</span>
        </span>
        <InputText
          decimalScale={2}
          numericFormat
          value={priceValue || ''}
          onChangeValue={(value: number) => setPriceValue(value)}
          disabled={isEdit}
          fixedDecimalScale
        />
      </div>
      <div className={styles.row}>
        <span>Credit cost, $</span>
        <InputText
          decimalScale={4}
          numericFormat
          value={costCredit || ''}
          onChangeValue={onSetCostCredit}
          disabled={isEdit}
        />
      </div>
      <div className={styles.row}>
        <span>
          Extra credit price, $ <span className={'error1'}>*</span>
        </span>
        <InputText
          decimalScale={4}
          numericFormat
          value={extraCreditPrice || ''}
          onChangeValue={setExtraCreditPrice}
        />
      </div>
      <CustomPrices />

      <SizedBox height={40} />
      <div className={'row12'}>
        <Button
          typeBtn={BtnType.primary}
          size={BtnSize.medium}
          onClick={onAction}
          loading={loadingUpdate || loadingCreate}
          disabled={disabled}
        >
          {(isEdit ? 'Update' : 'Create') + ' plan'}
        </Button>
        <Button
          typeBtn={BtnType.secondaryGray}
          size={BtnSize.medium}
          onClick={onClose}
        >
          Cancel
        </Button>
      </div>
    </div>
  )
})
