// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.u5rYhVpMzM9XZ6KpXRxu{padding:0 16px 16px 16px}.GtmzYloLmZ_oOguQDMID{display:flex;flex-wrap:nowrap;gap:6px;align-items:center}.DLEe1J2j3X3oeygYqvFY{display:grid;grid-template-columns:18px max-content;cursor:pointer;align-items:center;height:28px;padding:6px 8px;gap:8px;border-radius:6px;background:var(--primary-color-gray-4)}.DLEe1J2j3X3oeygYqvFY:hover,.DLEe1J2j3X3oeygYqvFY:focus{background:var(--primary-color-gray-2-light)}`, "",{"version":3,"sources":["webpack://./src/widgets/SendingSpeed/ui/ActiveContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CAGF,sBACE,YAAA,CACA,gBAAA,CACA,OAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,sCAAA,CACA,cAAA,CACA,kBAAA,CACA,WAAA,CACA,eAAA,CACA,OAAA,CACA,iBAAA,CACA,sCAAA,CACA,wDACE,4CAAA","sourcesContent":[".wrap{\n  padding: 0 16px 16px 16px;\n}\n\n.wrapMinutesContent{\n  display: flex;\n  flex-wrap: nowrap;\n  gap: 6px;\n  align-items: center;\n}\n\n.minutesBtn{\n  display: grid;\n  grid-template-columns: 18px max-content;\n  cursor: pointer;\n  align-items: center;\n  height: 28px;\n  padding: 6px 8px;\n  gap: 8px;\n  border-radius: 6px;\n  background: var(--primary-color-gray-4);\n  &:hover, &:focus{\n    background: var(--primary-color-gray-2-light);\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `u5rYhVpMzM9XZ6KpXRxu`,
	"wrapMinutesContent": `GtmzYloLmZ_oOguQDMID`,
	"minutesBtn": `DLEe1J2j3X3oeygYqvFY`
};
export default ___CSS_LOADER_EXPORT___;
