import { MuiTooltip, MuiTooltipProps } from 'components/Tooltip/MuiTooltip'
import { ReactComponent as Question } from 'icons/16pxNoMask/Question.svg'
import { ReactComponent as Info } from 'icons/16pxNoMask/Info.svg'
import { ReactComponent as Question2 } from 'icons/20px/NoMask/Question.svg'
import React, { CSSProperties, FC, ReactElement } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export interface InfoTooltipProps extends MuiTooltipProps {
  children?: ReactElement<never, never>
  large?: boolean
  error?: boolean
  info?: boolean
  marginLeft?: CSSProperties['marginLeft']
}

export const InfoTooltip: FC<InfoTooltipProps> = ({
  placement = 'top',
  arrow = true,
  large,
  error,
  info,
  marginLeft,

  ...props
}) => (
  <MuiTooltip placement={placement} arrow={arrow} {...props}>
    <span
      className={classNames(
        styles.wrap,
        large && styles.large,
        error && styles.error
      )}
      style={{ marginLeft }}
    >
      {info ? (
        large ? (
          <Question2 />
        ) : (
          <Info />
        )
      ) : large ? (
        <Question2 />
      ) : (
        <Question />
      )}
    </span>
  </MuiTooltip>
)
