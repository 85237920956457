import { makeAutoObservable, runInAction } from 'mobx'
import {
  IResponseVoice,
  IResponseVoiceType,
  responseVoiceToOption,
} from 'src/Modals/CreateTextToSpeechModal/store/type'
import { IOption } from 'src/types/IOption'
import axios, { AxiosResponse } from 'axios'
import audioStore from 'store/audioStore/audioStore'
import { TextareaStore } from 'store/textareaStore'
import { IAudioItem } from 'src/types/IAudioItem'

export type ICreateTextToSpeechModalStoreProps = {
  voices: IResponseVoice[]
  onClose: () => void
  options?: { editAudio?: IAudioItem; duplicateAudio?: IAudioItem }
}

export class CreateTextToSpeechModalStore {
  onClose
  constructor({
    voices,
    onClose,
    options,
  }: ICreateTextToSpeechModalStoreProps) {
    this.onClose = onClose
    makeAutoObservable(this)
    if (options?.editAudio) {
      this.setEditAudio(options?.editAudio)
    }
    if (options?.duplicateAudio) {
      this.setDuplicateAudio(options?.duplicateAudio)
    }
    if (voices.length) {
      this.setVoices(voices)
    }
  }

  textareaStore = new TextareaStore({})

  name = ''
  playing = false
  playingPreviewId = ''

  activeTabIndex = 0
  voiceMap: Map<IResponseVoiceType, IResponseVoice[]> = new Map()

  search = ''

  audioFilesMap: Map<string, { file: File; duration?: number }> = new Map()
  previewAudioFilesMap: Map<string, File> = new Map()

  selectedOption: IOption<IResponseVoice> | null = null

  loadingCreate = false
  loadingPreview = false

  editAudio: IAudioItem | null = null
  duplicateAudio: IAudioItem | null = null
  get audio() {
    return this.editAudio || this.duplicateAudio
  }

  private setInitInfo = (audio: IAudioItem) => {
    this.name = audio?.name || ''
    this.textareaStore.setText(audio.ttsMessage?.ttsText || '')
  }
  setEditAudio = (editAudio: IAudioItem) => {
    this.editAudio = editAudio
    this.setInitInfo(editAudio)
  }
  setDuplicateAudio = (duplicateAudio: IAudioItem) => {
    this.duplicateAudio = duplicateAudio
    this.setInitInfo(duplicateAudio)
  }

  setVoices = (voices: IResponseVoice[]) => {
    voices.forEach((voice) => {
      if (voice.types.includes('STANDARD')) {
        const old = this.voiceMap.get('STANDARD') || []
        this.voiceMap.set('STANDARD', [...old, { ...voice, type: 'STANDARD' }])
      }
      if (voice.types.includes('PREMIUM')) {
        const old = this.voiceMap.get('PREMIUM') || []
        this.voiceMap.set('PREMIUM', [...old, { ...voice, type: 'PREMIUM' }])
      }
    })
    const voice = this.audio?.ttsMessage?.voice
    const TTSVoiceType =
      voice?.type ||
      (window.localStorage.getItem('TTSVoiceType') as IResponseVoiceType)

    const TTSVoiceId =
      voice?.voiceId || window.localStorage.getItem('TTSVoiceId')
    if (TTSVoiceType && TTSVoiceId) {
      const savedVoice = this.voiceMap
        .get(TTSVoiceType as IResponseVoiceType)
        ?.find((voice) => voice.voiceId === TTSVoiceId)

      if (savedVoice) {
        this.activeTabIndex = TTSVoiceType === 'STANDARD' ? 0 : 1
        this.selectedOption = responseVoiceToOption(savedVoice)
        return
      }
    }
    this.selectedOption = this.voiceOptions[0] || null
  }

  get text() {
    return this.textareaStore.text
  }

  get audioFile() {
    return this.audioFilesMap.get(this.textKey)?.file
  }

  get audioFileDuration() {
    return this.audioFilesMap.get(this.textKey)?.duration
  }

  get audioSrc() {
    const file = this.audioFile
    return file && URL.createObjectURL(file)
  }

  get audioPreviewSrc() {
    const file = this.previewAudioFilesMap.get(this.playingPreviewId)
    return file && URL.createObjectURL(file)
  }

  get type(): IResponseVoiceType {
    if (this.activeTabIndex === 0) {
      return 'STANDARD'
    }
    return 'PREMIUM'
  }

  get standardLengthString() {
    return this.voiceMap.get('STANDARD')?.length || ''
  }
  get premiumLengthString() {
    return this.voiceMap.get('PREMIUM')?.length || ''
  }

  get voices() {
    return this.voiceMap.get(this.type) || []
  }

  get voiceOptions(): IOption[] {
    return this.voices
      .filter((voice) =>
        voice.voiceName.toLowerCase().includes(this.search.toString())
      )
      .map(responseVoiceToOption)
  }

  get disabledPlay() {
    return !this.text.trim()
  }
  get disabledCreate() {
    return !this.text.trim() || !this.name.trim() || this.isError
  }

  get duration() {
    return Math.ceil(this.text.trim().length / 12)
  }

  get textKey() {
    return `${this.selectedOption?.value}${
      this.selectedOption?.data?.type
    }${this.text.trim()}`
  }

  get isError() {
    return this.text.trim().length > 2500
  }

  setName = (value: string) => {
    this.name = value
  }

  setActiveTabIndex = (index: number) => {
    this.activeTabIndex = index
  }

  setSearch = (value: string) => {
    this.search = value
  }

  onSelect = (option: IOption) => {
    window.localStorage.setItem('TTSVoiceType', this.type)
    window.localStorage.setItem('TTSVoiceId', option.value)
    this.selectedOption = option
  }

  get dataPayload() {
    return {
      text: this.text.trim(),
      voiceId: this.selectedOption?.value,
      type: this.selectedOption?.data?.type,
      personalized: !!this.textareaStore.personalizes?.length,
    }
  }

  handlePlay = async () => {
    try {
      if (!this.audioSrc) {
        this.loadingPreview = true
        const { data }: AxiosResponse<File> = await axios.post(
          'tts/play',
          this.dataPayload,
          {
            responseType: 'blob',
          }
        )

        this.audioFilesMap.set(this.textKey, {
          file: data,
        })
      }

      this.setPlaying(!this.playing)
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loadingPreview = false
      })
    }
  }

  handlePlayPreview = async (voice: IResponseVoice) => {
    try {
      const id = `${voice.voiceId}${voice.type}`
      if (id === this.playingPreviewId) {
        this.playingPreviewId = ''
      } else {
        this.playingPreviewId = id
        if (!this.audioPreviewSrc) {
          const { data }: AxiosResponse<File> = await axios.post(
            'tts/play',
            {
              voiceId: voice.voiceId,
              type: voice.type,
              voicePreview: true,
            },
            {
              responseType: 'blob',
            }
          )

          this.previewAudioFilesMap.set(id, data)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  setPlaying = (value: boolean) => {
    this.playing = value
  }

  private _handleSaveOrCreate = async (data: string) => {
    try {
      this.loadingCreate = true
      const formData = new FormData()

      formData.append(
        'request',
        new Blob([data], {
          type: 'application/json',
        })
      )
      if (this.audioFile) {
        formData.append('audioData', this.audioFile)
      }
      await axios.post('tts/save', formData)
      audioStore.tableStore.onRefresh()
      this.onClose()
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loadingCreate = false
      })
    }
  }

  handleCreate = async () => {
    await this._handleSaveOrCreate(
      JSON.stringify({
        ...this.dataPayload,
        audioName: this.name.trim(),
      })
    )
  }

  handleUpdate = async () => {
    await this._handleSaveOrCreate(
      JSON.stringify({
        ...this.dataPayload,
        id: this.audio?.id,
        audioName: this.name.trim(),
      })
    )
  }

  setAudioDuration = (id: string, duration: number) => {
    const item = this.audioFilesMap.get(id)
    if (item) {
      this.audioFilesMap.set(id, { ...item, duration })
    }
  }
}
