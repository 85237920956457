import { MuiTooltip, MuiTooltipProps } from 'components/Tooltip/MuiTooltip'
import { DropdownContextProvider } from 'components/Dropdown/context/context'
import { ClickAwayListener } from '@mui/material'
import * as React from 'react'
import { PropsWithChildren, ReactNode, useState } from 'react'
import classNames from 'classnames'
import { Modifier } from 'react-popper'

type IDropdownFieldProps = PropsWithChildren<
  {
    dropdownContent: ReactNode
    withPopperOffset?: boolean
    isSameWidth?: boolean
    isFullWidth?: boolean
  } & MuiTooltipProps
>

const sameWidth: Partial<Modifier<any, any>>[] = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  effect: ({ state }) => {
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`
  },
}

export const DropdownField = ({
  dropdownContent,
  children,
  withPopperOffset,
  isFullWidth,
  isSameWidth,
  ...muiTooltipProps
}: Omit<IDropdownFieldProps, 'title'>) => {
  const [open, setOpen] = useState(false)
  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  const onOpen = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  let modifiers: Partial<Modifier<any, any>>[] = []
  if (withPopperOffset) {
    modifiers = [
      {
        name: 'offset',
        options: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          offset: ({ reference }) => {
            return [0, -reference.height - 16]
          },
        },
      },
    ]
  }
  if (isSameWidth) {
    modifiers = modifiers?.concat(sameWidth)
  }
  return (
    <DropdownContextProvider
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      parentElement={ref}
    >
      <ClickAwayListener onClickAway={onClose}>
        <div>
          <MuiTooltip
            padding={0}
            open={open}
            width={'100%'}
            PopperProps={{
              modifiers,
            }}
            title={<div ref={setRef}>{dropdownContent}</div>}
            white
            {...muiTooltipProps}
          >
            <button
              onClick={onOpen}
              className={classNames(isFullWidth && 'fullWidth')}
            >
              {children}
            </button>
          </MuiTooltip>
        </div>
      </ClickAwayListener>
    </DropdownContextProvider>
  )
}
