import { CreatePage } from 'components/Page/CreatePage'
import { useLocation, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useCreateWebFromContext } from 'src/pages/main/webForms/create/CreateWebformState'
import { NewWebForm } from 'src/pages/main/webForms/create/createForm/NewWebForm'
import { PreviewWebFrom } from 'src/pages/main/webForms/create/preview/PreviewWebFrom'
import { useGetWebFormLazyQuery } from 'src/generated/graphql'
import { transformWebForm } from 'store/webForms/functions'

export const EditWebFormContent = observer(() => {
  const { id } = useParams()
  const [getWebForm, { loading }] = useGetWebFormLazyQuery()
  const { pathname } = useLocation()
  const isDuplicate = pathname.includes('duplicate')

  useEffect(() => {
    if (id) {
      getWebForm({
        variables: {
          id: +id,
        },
      })
        .then(({ data }) => {
          if (data?.getWebForm) {
            const webForm = transformWebForm(data.getWebForm)
            createWebFormStore.setEdit(webForm, isDuplicate)
          }
        })
        .catch((e) => console.error(e))
    }
  }, [id])

  const createWebFormStore = useCreateWebFromContext()

  return (
    <CreatePage
      store={createWebFormStore}
      createElement={<NewWebForm />}
      previewElement={<PreviewWebFrom />}
      loading={loading}
    />
  )
})
