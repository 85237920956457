// type NormalValue<Name extends string> = `var(${Name})`
// type ColorValue<Key extends string> = NormalValue<Key>

const colors = [
  '--primary-color-blue-1',
  '--primary-color-blue-1-rgb',
  '--primary-color-blue-1-disabled',
  '--primary-color-blue-2',
  '--primary-color-blue-2-opacity6',
  '--primary-color-blue-3',
  '--primary-color-blue-3-disabled',
  '--primary-color-blue-4',
  '--primary-color-blue-5',
  '--primary-color-blue-6',
  '--primary-color-black',
  '--primary-color-black-rgb',
  '--primary-color-black-tooltip',
  '--primary-color-gray-1',
  '--primary-color-gray-1-rgb',
  '--primary-color-gray-2',
  '--primary-color-gray-2-disabled',
  '--primary-color-gray-2-light',
  '--primary-color-gray-3',
  '--primary-color-gray-4',
  '--primary-color-white',

  '--secondary-color-purple-1',
  '--secondary-color-purple-2',
  '--secondary-color-purple-3',
  '--secondary-color-orange-1',
  '--secondary-color-orange-2',
  '--secondary-color-orange-3',
  '--secondary-color-aqua-1',
  '--secondary-color-aqua-2',

  '--states-color-success-1',
  '--states-color-success-2',
  '--states-color-success-3',
  '--states-color-success-4',
  '--states-color-warning-1',
  '--states-color-warning-2',
  '--states-color-warning-3',
  '--states-color-warning-4',
  '--states-color-error-1',
  '--states-color-error-2',
  '--states-color-error-3',
  '--states-color-notify-1',
  '--states-color-notify-2',
  '--color-text',

  '--gradient-1',
  '--gradient-2',
  '--hover-img-gradient',
  '--hover-small-img-gradient',
] as const

export type IColor = (typeof colors)[number]

export const getCssColor = (color: IColor): string => `var(${color})`
