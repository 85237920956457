import { ConversionDetailHeader } from 'widgets/conversions/ConversionDetail/ui/ConversionDetailHeader/ConversionDetailHeader'
import { ConversionDetailTable } from 'widgets/conversions/ConversionDetail/ui/ConversionDetailTable/ConversionDetailTable'

export const ConversionDetailUi = () => {
  return (
    <>
      <ConversionDetailHeader />
      <ConversionDetailTable />
    </>
  )
}
