import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { ColorsValues } from 'styles/variables'
import { FC } from 'react'

export interface MuiTooltipProps extends TooltipProps {
  padding?: number
  width?: number | string
  white?: boolean
  zIndex?: number
}

export const MuiTooltip: FC<MuiTooltipProps> = styled(
  ({ className, children, ...props }: MuiTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}>
      {children}
    </Tooltip>
  )
)(({ padding = 8, white = false, width, zIndex }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: `rgba(${ColorsValues.blackRGB}, 0.9)`,
    marginBottom: '-8px!important',
  },
  [`&[data-popper-placement="left"] .${tooltipClasses.arrow}`]: {
    marginRight: '-8px!important',
  },
  [`&.MuiTooltip-popper`]: {
    zIndex,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: white
      ? ColorsValues.white
      : `rgba(${ColorsValues.blackRGB}, 0.9)`,
    color: white ? ColorsValues.black : ColorsValues.white,
    width: width ? width : undefined,
    maxWidth: width ? width : undefined,
    fontFamily: 'Inter',
    fontSize: 14,
    borderRadius: 8,
    margin: 0,
    padding: padding,
    lineHeight: '16px',
    boxShadow: white
      ? '0px 0px 2px rgba(83, 102, 137, 0.2), 0px 12px 16px rgba(83, 102, 137, 0.08), 0px 10px 40px rgba(83, 102, 137, 0.08)'
      : 'none',
  },
  [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
    marginTop: '8px!important',
  },
  [`& .${tooltipClasses.tooltipPlacementTop}`]: {
    marginBottom: '10px!important',
  },
}))
