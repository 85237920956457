import styles from 'components/Schedule/styles.module.scss'
import { ScheduleBtn } from 'components/Schedule/ScheduleBtn'
import { observer } from 'mobx-react-lite'
import { ScheduleProps } from 'components/Schedule/Schedule'

export const ImmediatelyCard = observer(
  ({ onActive, isActive }: ScheduleProps) => {
    return (
      <div className={styles.row}>
        <ScheduleBtn
          active={isActive}
          title={'Send Immediately'}
          onClick={onActive}
        />
      </div>
    )
  }
)
