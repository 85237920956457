// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o_s30ZRQXBX_tE9FFmPU{display:grid;grid-template-columns:1fr 1fr;gap:16px}`, "",{"version":3,"sources":["webpack://./src/pages/settings/compliance/registrations/A2P10DLCRegistration/steps/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,QAAA","sourcesContent":[".wrapOptInMethods{\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapOptInMethods": `o_s30ZRQXBX_tE9FFmPU`
};
export default ___CSS_LOADER_EXPORT___;
