import { makeAutoObservable } from 'mobx'
import { TableStore } from 'components/NewTable/store/TableStore'
import { getColumns } from 'widgets/conversions/ConversionsTable/store/columns'
import {
  ConversionResponseListFragment,
  GetConversionsQuery,
} from 'src/generated/graphql'
import {
  ConversionResponse,
  IConversionList,
} from 'src/pages/settings/conversions/conversions/store/type'

export class ConversionsTableStore {
  tableStore
  constructor() {
    makeAutoObservable(this)
    this.tableStore = new TableStore({
      withDataRange: true,
      tableName: 'ConversionsTable',
      columns: getColumns(),
      orderBy: 'id',
    })
  }

  itemsMap: Map<string, IConversionList> = new Map()
  get items() {
    return Array.from(this.itemsMap.values())
  }

  conversionListToConversionToConversionList = (
    response: ConversionResponseListFragment
  ): IConversionList => {
    return {
      id: response.id,
      ...response,
    }
  }

  updateItem = (response: ConversionResponse) => {
    const item = this.conversionListToConversionToConversionList(
      response as ConversionResponseListFragment
    )
    this.itemsMap.set(item.id, item)
  }

  isEmpty: null | boolean = null
  setIsEmpty = (value: boolean) => {
    this.isEmpty = value
  }

  setTotal = (total: number) => {
    this.tableStore.setTotal(total)
    if (total) {
      this.setIsEmpty(false)
      return
    }
    if (this.tableStore.search) {
      this.setIsEmpty(false)
      return
    }
    if (this.tableStore.rangeDatePickerStore.dateRange) {
      this.setIsEmpty(false)
      return
    }
    this.setIsEmpty(true)
  }

  setData = (data: GetConversionsQuery) => {
    this.setTotal(data.getConversions?.total || 0)
    this.itemsMap.clear()

    data.getConversions?.content?.forEach((response) => {
      if (response) {
        const item = this.conversionListToConversionToConversionList(response)
        this.itemsMap.set(item.id, item)
      }
    })
  }

  onSuccessDelete = () => {
    this.tableStore.onRefresh()
  }
}
