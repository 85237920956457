import styles from './styles.module.scss'
import { SmallBtn } from 'components/Button/SmallBtn'
import { ActionsEnum } from 'components/Button/types'
import { ReactComponent as Input } from 'icons/16pxNoMask/Input.svg'
import { ColorsNames } from 'styles/variables'
import { AppIcon } from 'components/AppIcon/AppIcon'
import { ReactComponent as Web } from 'icons/16pxNoMask/Web.svg'
import { ReactComponent as Arrows } from 'icons/16pxNoMask/Arrows.svg'
import { ReactComponent as Phone } from 'icons/16pxNoMask/Phone.svg'
import { ReactComponent as ContactsFill } from 'icons/16pxNoMask/ContactsFill.svg'
import { DetailRow } from 'components/DetailsModalContent/DetailRow/DetailRow'
import { useWebFormFunctions } from 'src/routes/main/webForms/hooks/useWebFormFunctions'
import { IWebForm } from 'src/types/IWebForm'
import { DetailTypeContent } from 'components/DetailsModalContent/DetailTypeContent'
import { ItemsRender } from 'components/NewTable/cell/ItemsRender/ItemsRender'

export const DetailWebFormContent = ({ webForm }: { webForm: IWebForm }) => {
  const { onTestWebForm } = useWebFormFunctions(webForm)
  return (
    <>
      <div className={styles.row}>
        <h3>{webForm.name}</h3>
        <SmallBtn
          type={ActionsEnum.testWebWorm}
          secondaryGray
          onClick={onTestWebForm}
        />
      </div>
      <div className={styles.grid}>
        <DetailRow
          leftContent={
            <>
              <Input />
              <span className={ColorsNames.gray1}>Fields collected</span>
            </>
          }
          rightContent={
            <div className={styles.fields}>
              {webForm.fieldsToCollect.map((field) => (
                <div key={field.field?.key || ''}>
                  <span>
                    {field.field?.name}{' '}
                    {field.required && <span className={'error1'}>*</span>}
                  </span>
                </div>
              ))}
            </div>
          }
        />
        <DetailRow
          leftContent={
            <>
              <AppIcon>
                <Web />
              </AppIcon>
              <span className={ColorsNames.gray1}>Thank you page</span>
            </>
          }
          rightContent={
            !webForm.thankYouPage ? (
              <span>none</span>
            ) : (
              <a href={webForm.thankYouPage} target={'_blank'} rel="noreferrer">
                {webForm.thankYouPage}
              </a>
            )
          }
        />
        <DetailRow
          leftContent={
            <>
              <Arrows />
              <span className={ColorsNames.gray1}>Double Opt-in</span>
            </>
          }
          rightContent={<span>{webForm.doubleOptIn ? 'On' : 'Off'}</span>}
        />
        <DetailRow
          leftContent={
            <>
              <Phone />
              <span className={ColorsNames.gray1}>Send from</span>
            </>
          }
          rightContent={
            <span>{webForm.sendFromPhoneNumber?.friendlyName}</span>
          }
        />
        <DetailRow
          leftContent={
            <>
              <ContactsFill />
              <span className={ColorsNames.gray1}>List</span>
            </>
          }
          rightContent={<ItemsRender items={webForm.items || []} />}
        />
      </div>
      <DetailTypeContent smsMessage={webForm.smsMessage} />
    </>
  )
}
