import { ActionsEnum } from 'components/Button/types'
import { AudioRenameBtn } from 'src/pages/settings/audio/components/AudioRenameBtn'
import { AudioDeleteBtn } from 'src/pages/settings/audio/components/AudioDeleteBtn'
import { AudioDownloadBtn } from 'src/pages/settings/audio/components/AudioDownloadBtn'
import { AudioCopyUrlBtn } from 'src/pages/settings/audio/components/AudioCopyUrlBtn'
import { AudioEditBtn } from 'src/pages/settings/audio/components/AudioEditBtn'
import { AudioDuplicateBtn } from 'src/pages/settings/audio/components/AudioDuplicateBtn'

export const audioBtnByAction = {
  [ActionsEnum.edit]: AudioEditBtn,
  [ActionsEnum.duplicate]: AudioDuplicateBtn,
  [ActionsEnum.rename]: AudioRenameBtn,
  [ActionsEnum.copy]: AudioCopyUrlBtn,
  [ActionsEnum.download]: AudioDownloadBtn,
  [ActionsEnum.delete]: AudioDeleteBtn,
}
