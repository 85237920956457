import { observer } from 'mobx-react-lite'
import { MediumBtn } from 'components/Button/MediumBtn'
import { ActionsEnum } from 'components/Button/types'
import { TableStore } from 'components/NewTable/store/TableStore'
import { IUnvalidatedContact } from 'src/pages/main/contacts/importHistory/FailedImportHistory/FailedImportHistoryTable/types'

export const Filters = observer(
  ({ tableStore }: { tableStore: TableStore<IUnvalidatedContact> }) => {
    return (
      <div className={'row12'}>
        <MediumBtn
          type={ActionsEnum.export}
          text={
            tableStore.checkedRowsIds.length ? 'Export selected' : undefined
          }
        />
      </div>
    )
  }
)
