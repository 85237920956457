import { Modal } from 'components/Modal/Modal'
import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { Alert } from 'components/Alert/Alert'
import { SizedBox } from 'components/SizedBox'
import { InputCheckboxGroup } from 'components/Input/InputCheckbox/InputCheckboxGroup'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import { useState } from 'react'
import { Li } from 'components/Ul/Li'
import { Ul } from 'components/Ul/Ul'
import user from 'store/user/user'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'

enum ReasonEnum {
  IssuesOrBugs = 'Issues or bugs in the software',
  QualityOfSupport = 'Quality of support',
  ItWasTooHardUse = 'It was too hard or difficult to use',
  NeededCallLoopTemporarily = 'I only needed Call Loop temporarily',
  AnotherSolution = 'I found another solution',
  Afford = 'I can no longer afford it / budget reasons',
  Other = 'Other',
}

export const DeleteAccountModal = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  const [reason, setReason] = useState<ReasonEnum | ''>('')
  const [message, setMessage] = useState('')
  const [step, setStep] = useState(0)
  const [agree, setAgree] = useState(false)
  const handleClose = () => {
    setStep(0)
    setAgree(false)
    onClose()
  }
  const onContinue = () => {
    if (step === 0) {
      setStep(1)
    } else {
      user.setIsDeleteAccount(true)
      handleClose()
    }
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={'Delete my account'}
      minWidth={528}
    >
      <div className={'mediumModal'}>
        <Alert
          title={'We’re sorry to see you go'}
          text={
            step === 0 ? (
              'Be advised, account deletion is final. There will be no way to restore your account.'
            ) : (
              <>
                Be advised, account deletion is final. There will be no way to
                restore your account. Deletion will take action at the end of
                the billing cycle: <span className={'error1'}>Jan 1, 2022</span>
                .
              </>
            )
          }
          type={AlertTypeEnum.delete}
        />
        <SizedBox height={24} />
        {step === 0 ? (
          <>
            <span className={'s2 medium gray1 mb8'}>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              We'd like to know why you're leaving:
            </span>
            <InputCheckboxGroup>
              {Object.values(ReasonEnum).map((val) => (
                <InputCheckbox
                  key={val}
                  label={val}
                  checked={reason === val}
                  onChecked={() => setReason(val)}
                  shape={'radio'}
                  size={'small'}
                />
              ))}
            </InputCheckboxGroup>
            {reason && (
              <div className={styles.message}>
                <div className={'s2 medium gray1 mb8'}>
                  Is there anything else we should to know? (optional)
                </div>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder={'Type here...'}
                />
              </div>
            )}
          </>
        ) : (
          <>
            <div className={styles.card}>
              <p className={'par2 bold'}>
                This action will permanently delete…
              </p>
              <SizedBox height={16} />
              <Ul>
                <Li>Your profile</Li>
                <Li>All of your contacts</Li>
                <Li>All of your credits</Li>
                <Li>All of your messages</Li>
              </Ul>
            </div>
            <InputCheckbox
              label={
                <>
                  I agree to the{' '}
                  <a href="/settings/account/routes" target={'_blank'}>
                    Terms and Conditions
                  </a>
                  , to delete my account
                </>
              }
              checked={agree}
              onChecked={() => setAgree(!agree)}
              noBordered
              rowClassName={styles.row}
              className={styles.agree}
            />
          </>
        )}

        <div className={styles.actions}>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            disabled={step === 0 ? !reason : !agree}
            onClick={onContinue}
          >
            {step === 0 ? 'Continue with deleting' : 'Delete my account'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
