import { observer } from 'mobx-react-lite'
import React from 'react'
import { Alert } from 'components/Alert/Alert'
import { Dropdown } from 'components/Dropdown/Dropdown'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'
import { SimpleTextarea } from 'components/SimpleTextarea/SimpleTextarea'
import { SizedBox } from 'components/SizedBox'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import {
  TollFreeVerificationProfile,
  useAdminInitTollFreeVerificationProfileMutation,
  useInitTollFreeVerificationProfileMutation,
} from 'src/generated/graphql'
import { PropsTollFreeStep } from 'src/pages/settings/compliance/TollFreeVerification/types'
import { onOpenIntercomWidget } from 'src/util/intercom'
import numbersStore from 'store/settings/numbers/numbersStore'
import { TollFreeBackBtn } from 'src/pages/settings/compliance/TollFreeVerification/steps/TollFreeBackBtn'
import { appLinks } from 'src/util/links'

export const Numbers = observer(({ onSuccess }: PropsTollFreeStep) => {
  const {
    tollFreeVerificationRequestInput,
    selectedNumbersOptions,
    setSelectedNumbersOptions,
    explanation,
    setExplanation,
    disabledSelectedNumbers,
    continueText,
    onDeleteNumber,
    adminOrgId,
  } = tollFreeVerificationStore
  const [initTollFreeVerificationProfile, { loading }] =
    useInitTollFreeVerificationProfileMutation()
  const [adminInitTollFreeVerificationProfile, { loading: loadingAdmin }] =
    useAdminInitTollFreeVerificationProfileMutation()
  const onContinue = async () => {
    try {
      let profile: TollFreeVerificationProfile | null | undefined
      if (adminOrgId) {
        const { data } = await adminInitTollFreeVerificationProfile({
          variables: {
            input: tollFreeVerificationRequestInput,
            organizationId: adminOrgId,
          },
        })
        profile = data?.adminInitTollFreeVerificationProfile
      } else {
        const { data } = await initTollFreeVerificationProfile({
          variables: {
            input: tollFreeVerificationRequestInput,
          },
        })
        profile = data?.initTollFreeVerificationProfile
      }

      profile && onSuccess(profile)
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <>
      <div className={'col40'}>
        <Alert
          compact
          text={
            <>
              Add all the Toll-Free numbers you want to verify. If you want to{' '}
              <br />
              increase throughput on your toll-free numbers to send more <br />
              messages,{' '}
              <button className={'s1 link bold'} onClick={onOpenIntercomWidget}>
                get in touch with us.
              </button>
            </>
          }
        />
        <div className={'col24'}>
          <Dropdown
            disabled={!!adminOrgId}
            selectedOptions={selectedNumbersOptions}
            onMultiSelect={(options) => setSelectedNumbersOptions(options)}
            options={numbersStore.toolFreeNumberOptionsForUseCase.map(
              (opt) => ({
                ...opt,
                leftMark: false,
              })
            )}
            withNoData
            noDataText={`You don't have any unverified numbers`}
            onDeleteTag={(id) => onDeleteNumber(id as string)}
            multi
            selectedTag
            labelInput={'Toll-Free numbers'}
            labelDropdown={'Select numbers'}
            labelTooltip={
              'Select the toll-free number that you’d like to include for this specific use case.'
            }
            withSelectALl
          />
          {selectedNumbersOptions.length > 1 && (
            <SimpleTextarea
              label={'Explanation'}
              labelTooltip={
                <>
                  Carriers need to know the reason you want to use multiple
                  toll-free numbers under the same use case.{' '}
                  <a
                    href={appLinks.callLoopCompliance}
                    target={'_blank'}
                    className={'s1'}
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                </>
              }
              placeholder={'Enter explanation'}
              height={104}
              value={explanation}
              onChangeValue={setExplanation}
            />
          )}
        </div>
      </div>
      <SizedBox height={40} />
      <div className={'row12'}>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          onClick={onContinue}
          disabled={disabledSelectedNumbers}
          loading={loading || loadingAdmin}
        >
          {continueText}
        </Button>
        <TollFreeBackBtn />
      </div>
    </>
  )
})
