import NewTable from 'components/NewTable/NewTable'
import * as React from 'react'
import { useGetKeywordContactsQuery } from 'src/generated/graphql'
import { useKeywordDetailContext } from 'src/pages/main/keywords/detail/keywordDetailPage'
import { AdditionalContent } from 'src/pages/main/keywords/detail/KeywordDetailTable/AdditionalContent/AdditionalContent'
import { LeftStickyContent } from 'src/pages/main/keywords/detail/KeywordDetailTable/LeftStickyContent/LeftStickyContent'
import { EmptyStateStatistic } from 'components/EmptyState/EmptyStateStatistic'
import { observer } from 'mobx-react-lite'
import { useSetData } from 'src/hooks/useSetData'
import { IKeywordContact } from 'src/types/IKeywordContact'

export const KeywordDetailTable = observer(() => {
  const context = useKeywordDetailContext()
  const { keyword, tableStore, contacts } = context

  const queryResult = useGetKeywordContactsQuery({
    variables: {
      page: tableStore.paginationInput,
      status: tableStore.statusBackEnd,
      dateRange: tableStore.rangeDatePickerStore.dateRange,
      keywordId: +(keyword?.id || 0),
    },
    skip: tableStore.rangeDatePickerStore.isSkipByDateRange,
  })

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData: (data) => context.setContactsData(data),
    refreshDeps: [tableStore.refreshTrigger],
    disabledRefetch: tableStore.rangeDatePickerStore.isSkipByDateRange,
  })

  return (
    <NewTable<IKeywordContact>
      // withCheckbox
      columns={tableStore.visibleColumns}
      rows={contacts}
      withSearch
      withCalcMaxHeight
      additionalContent={<AdditionalContent />}
      leftStickyContent={<LeftStickyContent />}
      tableStore={tableStore}
      pageLoading={loading || refetchLoading}
      firstLoading={firstLoading}
      emptyContent={contacts.length ? undefined : <EmptyStateStatistic />}
      contentLoading={loading && !contacts.length}
    />
  )
})
