// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c3cV1PTi5nvd2FXFYDM0{display:flex;align-items:center;justify-content:center;width:32px;height:32px;border:1px solid var(--primary-color-gray-3);border-radius:8px}.jGtkxCjmFDsFZUghjsMQ{margin-top:32px;display:grid;row-gap:24px;margin-bottom:40px}`, "",{"version":3,"sources":["webpack://./src/pages/settings/integrations/modal/CallLoopApiKeyModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,4CAAA,CACA,iBAAA,CAEF,sBACE,eAAA,CACA,YAAA,CACA,YAAA,CACA,kBAAA","sourcesContent":[".wrapTitleIcon{\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 32px;\n  height: 32px;\n  border: 1px solid var(--primary-color-gray-3);\n  border-radius: 8px;\n}\n.wrapFields{\n  margin-top: 32px;\n  display: grid;\n  row-gap: 24px;\n  margin-bottom: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapTitleIcon": `c3cV1PTi5nvd2FXFYDM0`,
	"wrapFields": `jGtkxCjmFDsFZUghjsMQ`
};
export default ___CSS_LOADER_EXPORT___;
