// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.E3tOlDBlUqL4Ev1VhbPG{display:flex;column-gap:4px;cursor:pointer;background-color:rgba(0,0,0,0);align-items:center;color:var(--primary-color-gray-1);font-size:12px;font-weight:500}.E3tOlDBlUqL4Ev1VhbPG.Q64fdi4A4uPY2gbF53Kp{color:var(--primary-color-black)}.BLwbFjeNZZ0HJpPQYN8j{transform:rotate(-180deg)}`, "",{"version":3,"sources":["webpack://./src/components/NewTable/components/SortLabel/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,cAAA,CACA,cAAA,CACA,8BAAA,CACA,kBAAA,CACA,iCAAA,CACA,cAAA,CACA,eAAA,CACA,2CACE,gCAAA,CAIJ,sBACE,yBAAA","sourcesContent":[".sortBtn{\n  display: flex;\n  column-gap: 4px;\n  cursor: pointer;\n  background-color: transparent;\n  align-items: center;\n  color: var(--primary-color-gray-1);\n  font-size: 12px;\n  font-weight: 500;\n  &.active{\n    color: var(--primary-color-black);\n  }\n}\n\n.rotate{\n  transform: rotate(-180deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sortBtn": `E3tOlDBlUqL4Ev1VhbPG`,
	"active": `Q64fdi4A4uPY2gbF53Kp`,
	"rotate": `BLwbFjeNZZ0HJpPQYN8j`
};
export default ___CSS_LOADER_EXPORT___;
