import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { NewInputCheckbox } from 'components/shared/NewInputCheckbox/NewInputCheckbox'
import styles from './styles.module.scss'
import { Typography } from 'shared/Typography'
import { numberString } from 'src/util/functions'
import billingStore from 'store/settings/billing/billingStore'
import { appLinks } from 'src/util/links'

type IEnableCallBackInputProps = {
  callbackEnabled: boolean
  setCallbackEnabled: (value: boolean) => void
}

export const EnableCallBackInput = observer(
  ({ callbackEnabled, setCallbackEnabled }: IEnableCallBackInputProps) => {
    const costInfo = useMemo(() => {
      if (!billingStore.clSubscription?.price?.additionalCreditPrice) {
        return ''
      }
      const costString = numberString({
        val: 0.15 / billingStore.clSubscription?.price?.additionalCreditPrice,
        maximumFractionDigits: 4,
      })

      return `Cost: ${costString} credits ($0.15) per callback`
    }, [billingStore.clSubscription?.price?.additionalCreditPrice])
    return (
      <NewInputCheckbox
        title={'Enable Call-Back tracking'}
        isNew
        className={styles.wrap}
        checked={callbackEnabled}
        onChecked={() => setCallbackEnabled(!callbackEnabled)}
        infoTooltipProps={{
          title: (
            <>
              Enable this feature to track the <br /> number of call-backs
              generated <br /> by your ringless voicemail <br /> messages.{' '}
              <a
                href={appLinks['call-back-tracking']}
                target={'_blank'}
                rel="noreferrer"
              >
                <Typography isLink>Learn more</Typography>
              </a>
              {costInfo && (
                <Typography
                  variant={'s2-regular'}
                  color={'--primary-color-gray-2'}
                  tag={'div'}
                  style={{ marginTop: 4 }}
                >
                  {costInfo}
                </Typography>
              )}
            </>
          ),
        }}
      />
    )
  }
)
