import { RangeDatePicker } from 'components/RangeDatePicker/RangeDatePicker'
import { EmptyStateSearch } from 'components/EmptyState/EmptyStateSearch'
import NewTable from 'components/NewTable/NewTable'
import * as React from 'react'
import { useGetConversionHitsQuery } from 'src/generated/graphql'
import { useSetData } from 'src/hooks/useSetData'
import { observer } from 'mobx-react-lite'
import { useConversionDetailContext } from 'widgets/conversions/ConversionDetail/context/context'
import { AdditionalContent } from 'widgets/conversions/ConversionDetail/ui/ConversionDetailTable/AdditionalContent/AdditionalContent'
import { EmptyStateStatistic } from 'components/EmptyState/EmptyStateStatistic'
import { ConversionHitType } from 'widgets/conversions/ConversionDetail/ui/ConversionDetailTable/ConversionHitType/ConversionHitType'
import styles from './styles.module.scss'

export const ConversionDetailTable = observer(() => {
  const { tableStore, setData, items, conversionId, hitTypes } =
    useConversionDetailContext()

  const queryResult = useGetConversionHitsQuery({
    variables: {
      conversionId,
      page: tableStore.paginationInput,
      dateRange: tableStore.rangeDatePickerStore.dateRange,
      hitTypes,
    },
    skip: tableStore.rangeDatePickerStore.isSkipByDateRange,
  })

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData,
    refreshDeps: [tableStore.refreshTrigger],
  })

  return (
    <NewTable
      tableStore={tableStore}
      rows={items}
      columns={tableStore.visibleColumns}
      withCalcMaxHeight
      pageLoading={loading || refetchLoading}
      firstLoading={firstLoading}
      additionalContent={<AdditionalContent />}
      leftStickyContent={
        <div className={styles.wrapFilters}>
          <RangeDatePicker
            rangeDatePickerStore={tableStore.rangeDatePickerStore}
          />
          <ConversionHitType />
        </div>
      }
      emptyContent={
        !items.length ? (
          tableStore.isFiltered ? (
            <EmptyStateSearch element={'conversion'} />
          ) : (
            <EmptyStateStatistic description={'There are no conversions yet'} />
          )
        ) : undefined
      }
    />
  )
})
