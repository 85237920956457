import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { DatePicker } from 'components/DatePicker/DatePicker'
import { TimePicker } from 'components/TimePicker/TimePicker'
import dayjs from 'lib/dayjs'
import { ScheduleContentWrapper } from 'components/Schedule/ScheduleContentWrapper/ScheduleContentWrapper'
import { useCampaignScheduleContext } from 'src/widgets/CampaignSchedule'

export const CalendarDateContent = observer(() => {
  const { activeCalendarDate, calendarDate, setCalendarDate } =
    useCampaignScheduleContext()
  useEffect(() => {
    if (!calendarDate) {
      setCalendarDate(dayjs().startOf('day').add(12, 'hours').toDate())
    }
  }, [activeCalendarDate])

  if (!activeCalendarDate) {
    return <></>
  }
  return (
    <ScheduleContentWrapper>
      <DatePicker startDate={calendarDate} onChange={setCalendarDate} />
      <TimePicker startTime={calendarDate} onChange={setCalendarDate} />
    </ScheduleContentWrapper>
  )
})
