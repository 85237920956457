import { AppRoute } from 'src/util/route'
import { RoutesEnum } from 'src/pages/routes'
import { PrivacyAccount } from 'src/pages/settings/account/routes/PrivacyAccount/PrivacyAccount'
import { Billing } from 'src/pages/settings/billing/billing'
import { Numbers } from 'src/pages/settings/numbers/numbers'
import { Integrations } from 'src/pages/settings/integrations/integrations'
import { Help } from 'src/pages/settings/help'
import { Dashboard } from 'src/pages/main/dashboard/dashboard'
import { Broadcasts } from 'src/pages/main/broadcasts/broadcasts'
import { BroadcastDetail } from 'src/pages/main/broadcasts/broadcastDetail/broadcastDetail'
import { CreateBroadcast } from 'src/pages/main/broadcasts/createBroadcast/CreateBroadcast'
// import { Campaigns } from 'src/pages/main/campaigns/campaigns'
import { Triggers } from 'src/pages/main/triggers/triggers'
import { TriggerDetail } from 'src/pages/main/triggers/detail/triggerDetail'
import { CreateTriggerPage } from 'src/pages/main/triggers/create/CreateTriggerPage'
import { Keywords } from 'src/pages/main/keywords/keywords'
import { KeywordDetailPage } from 'src/pages/main/keywords/detail/keywordDetailPage'
import { EditKeywordPage } from 'src/pages/main/keywords/create/EditKeywordPage'
import { WebForms } from 'src/pages/main/webForms/webForms'
import { WebFormDetailPage } from 'src/pages/main/webForms/detail/webFormDetailPage'
import { EditWebFormPage } from 'src/pages/main/webForms/create/EditWebFormPage'
import { DetailList } from 'src/pages/main/contacts/contctLists/DetailList/DetailList'
import { FailedImportHistory } from 'src/pages/main/contacts/importHistory/FailedImportHistory/FailedImportHistory'
import { ImportHistory } from 'src/pages/main/contacts/importHistory/ImportHistory'
// import { Automations } from 'src/pages/main/automations/automations'
import { CompanyTeam } from 'src/pages/settings/company/routes/CompanyTeam/CompanyTeam'
import { Compliance } from 'src/pages/settings/compliance/Compliance'
import { Organizations } from 'src/pages/superAdmin/routes/organizations/Organizations'
import { OrganizationPage } from 'src/pages/superAdmin/routes/organization/OrganizationPage'
import { Imports } from 'src/pages/superAdmin/routes/imports/Imports'
import { CustomPlans } from 'src/pages/superAdmin/routes/customPlans/CustomPlans'
import { ComplianceReviewSystem } from 'src/pages/superAdmin/routes/complianceRS/ComplianceReviewSystem'
import { Campaigns } from 'src/pages/main/campaigns/campaigns'
import { CampaignDetailPage } from 'src/pages/main/campaigns/detail/campaignDetailPage'
import { EditCampaignPage } from 'src/pages/main/campaigns/create/EditCampaignPage'
import { SegmentDetailRoutes } from 'src/pages/main/contacts/segments/DetailSegment/routes/SegmentDetailRoutes'
import { EditBroadcast } from 'src/pages/main/broadcasts/createBroadcast/EditBroadcast'

export const accountRoutes: AppRoute[] = [
  {
    path: RoutesEnum.privacy,
    Element: PrivacyAccount,
  },
]

export const adminRoutes: AppRoute[] = [
  {
    path: RoutesEnum.organizations,
    Element: Organizations,
  },
  {
    path: RoutesEnum.organizations + '/:id',
    Element: OrganizationPage,
  },
  {
    path: RoutesEnum.imports,
    Element: Imports,
  },
  {
    path: RoutesEnum.customPlans,
    Element: CustomPlans,
  },
  {
    path: RoutesEnum.compliance,
    Element: ComplianceReviewSystem,
  },
]

export const companyRoutes: AppRoute[] = [
  {
    path: RoutesEnum.team,
    Element: CompanyTeam,
  },
]

export const settingRoutes: AppRoute[] = [
  {
    path: RoutesEnum.compliance,
    Element: Compliance,
  },
  {
    path: RoutesEnum.billing,
    Element: Billing,
  },
  {
    path: RoutesEnum.numbers,
    Element: Numbers,
  },
  {
    path: RoutesEnum.integrations,
    Element: Integrations,
  },
  {
    path: RoutesEnum.help,
    Element: Help,
  },
]

export const mainRoutes: AppRoute[] = [
  {
    path: RoutesEnum.dashboard + '/*',
    Element: Dashboard,
  },

  //Broadcasts
  {
    path: RoutesEnum.broadcasts,
    Element: Broadcasts,
  },
  {
    path: RoutesEnum.broadcastDetail,
    Element: BroadcastDetail,
  },
  {
    path: RoutesEnum.newBroadcast,
    Element: CreateBroadcast,
  },
  {
    path: RoutesEnum.broadcastEdit,
    Element: EditBroadcast,
  },
  {
    path: RoutesEnum.broadcastDuplicate,
    Element: EditBroadcast,
  },

  //Campaigns
  {
    path: RoutesEnum.campaigns,
    Element: Campaigns,
  },
  {
    path: RoutesEnum.campaignDetail,
    Element: CampaignDetailPage,
  },
  {
    path: RoutesEnum.newCampaign,
    Element: EditCampaignPage,
  },
  {
    path: RoutesEnum.campaignEdit,
    Element: EditCampaignPage,
  },
  {
    path: RoutesEnum.campaignDuplicate,
    Element: EditCampaignPage,
  },

  //Triggers
  {
    path: RoutesEnum.triggers,
    Element: Triggers,
  },
  {
    path: RoutesEnum.triggerDetail,
    Element: TriggerDetail,
  },
  {
    path: RoutesEnum.newTrigger,
    Element: CreateTriggerPage,
  },
  {
    path: RoutesEnum.triggerEdit,
    Element: CreateTriggerPage,
  },
  {
    path: RoutesEnum.triggerDuplicate,
    Element: CreateTriggerPage,
  },
  //Keywords
  {
    path: RoutesEnum.keywords,
    Element: Keywords,
  },
  {
    path: RoutesEnum.keywordDetail,
    Element: KeywordDetailPage,
  },
  {
    path: RoutesEnum.newKeyword,
    Element: EditKeywordPage,
  },
  {
    path: RoutesEnum.keywordEdit,
    Element: EditKeywordPage,
  },
  {
    path: RoutesEnum.keywordDuplicate,
    Element: EditKeywordPage,
  },
  //WebForms
  {
    path: RoutesEnum.webForms,
    Element: WebForms,
  },
  {
    path: RoutesEnum.webFormDetail,
    Element: WebFormDetailPage,
  },
  {
    path: RoutesEnum.newWebForm,
    Element: EditWebFormPage,
  },
  {
    path: RoutesEnum.webFormEdit,
    Element: EditWebFormPage,
  },
  {
    path: RoutesEnum.webFormDuplicate,
    Element: EditWebFormPage,
  },
  //Contacts
  {
    path: RoutesEnum.listDetail,
    Element: DetailList,
  },
  {
    path: RoutesEnum.historyDetail,
    Element: FailedImportHistory,
  },
  {
    path: RoutesEnum.contactsHistory,
    Element: ImportHistory,
  },
  {
    path: RoutesEnum.segmentsDetailRoutes,
    Element: SegmentDetailRoutes,
  },
  //Automations
  // {
  //   path: RoutesEnum.automations,
  //   Element: Automations,
  // },
]
