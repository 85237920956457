import React, { useEffect, useState } from 'react'
import { BtnSize } from 'components/Button/Button'
import { RegisterOrganization } from 'src/widgets/RegisterOrganization/RegisterOrganization'
import { SizedBox } from 'components/SizedBox'
import { FooterRegister } from 'src/pages/auth/components/FooterRegister'
import { RegisterMember } from 'src/pages/main/newOrganization/RegisterMember'

export const NewOrganization = () => {
  const [step, setStep] = useState(0)
  const [companyName, setCompanyName] = useState('')
  const [employees, setEmployees] = useState<string | null>(null)
  useEffect(() => {
    return () => {
      setCompanyName('')
      setEmployees(null)
      setStep(0)
    }
  }, [])

  if (step === 0) {
    const onContinue = (companyName: string, employees: string | null) => {
      setCompanyName(companyName)
      setEmployees(employees)
      setStep(1)
    }
    return (
      <RegisterOrganization
        btnPrimarySize={BtnSize.large}
        btnPrimaryText={'Continue'}
        onContinue={onContinue}
        progress={50}
        bottomContent={
          <>
            <SizedBox height={16} />
            <FooterRegister
              text={
                'After your 14-day trial is over, you will be upgraded to the Pro Monthly 500 $25/month. Cancel anytime.'
              }
            />
          </>
        }
      />
    )
  }
  if (step === 1) {
    return <RegisterMember companyName={companyName} employees={employees} />
  }
  return null
}
