import React, {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import styles from './styles.module.scss'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import classNames from 'classnames'
import { Icon, IIcon } from 'components/Icon/Icon'

type Props<T> = {
  value: T
  setValue?: (val: T) => void
  leftIcon?: IIcon
  isNumber?: boolean
  rightContent?: ReactNode
  placeholder?: string
  isReadOnly?: boolean
  isFocusOnMount?: boolean
  toggleFocus?: (value: boolean) => void
  numericFormatProps?: NumericFormatProps
  withClear?: boolean
  width?: CSSProperties['width']
}

// eslint-disable-next-line react/display-name
export const Input = forwardRef(
  (
    {
      isNumber,
      leftIcon,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value,
      setValue,
      rightContent,
      placeholder = 'Enter value',
      isReadOnly,
      isFocusOnMount,
      toggleFocus,
      numericFormatProps,
      withClear,
      width,
    }: Props<typeof value>,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const [focus, setFocus] = useState(false)
    const refInput = useRef<HTMLInputElement | null>(null)

    const onFocus = () => {
      setFocus(true)
      toggleFocus && toggleFocus(true)
    }
    const onBlur = () => {
      setFocus(false)
      toggleFocus && toggleFocus(false)
    }

    useEffect(() => {
      if (refInput.current && isFocusOnMount) {
        refInput.current.focus()
      }
    }, [isFocusOnMount])

    const isShowClearBtn = withClear && value

    return (
      <div
        className={classNames(styles.wrap, {
          [styles.focus]: focus,
        })}
        style={{ width }}
        ref={ref}
      >
        {leftIcon && <Icon icon={leftIcon} color={'--primary-color-gray-1'} />}
        {isNumber ? (
          <NumericFormat
            getInputRef={refInput}
            value={value}
            thousandSeparator
            onValueChange={(values) => setValue && setValue(values.floatValue)}
            placeholder={placeholder}
            className={styles.input}
            onFocus={onFocus}
            onBlur={onBlur}
            readOnly={isReadOnly}
            {...numericFormatProps}
          />
        ) : (
          <input
            ref={refInput}
            value={value}
            onChange={(event) => setValue && setValue(event.target.value)}
            placeholder={placeholder}
            className={styles.input}
            onFocus={onFocus}
            onBlur={onBlur}
            readOnly={isReadOnly}
          />
        )}

        {rightContent && (
          <div className={styles.wrapRightContent}>{rightContent}</div>
        )}
        {isShowClearBtn && (
          <button
            className={styles.clear}
            onClick={(e) => {
              e.stopPropagation()
              setValue?.('')
              refInput.current?.focus()
            }}
          >
            <Icon icon={'xSmall'} fontSize={24} />
          </button>
        )}
      </div>
    )
  }
)
