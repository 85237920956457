import { observer } from 'mobx-react-lite'
import { Modal } from 'components/Modal/Modal'
import styles from './styles.module.scss'
import { InputText } from 'components/Input/InputText/InputText'
import classNames from 'classnames'
import { SizedBox } from 'components/SizedBox'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { useEffect, useState } from 'react'
import {
  PlanPeriod,
  useAdminCreateCustomPlanMutation,
  useAdminUpdateCustomPlanMutation,
} from 'src/generated/graphql'
import customPlansStore from 'store/superAdmin/customPlansStore'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { IOption } from 'src/types/IOption'

export const CustomPlanModal = observer(() => {
  const [createCustomPlan, { loading: loadingCreate }] =
    useAdminCreateCustomPlanMutation()
  const [updateCustomPlan, { loading: loadingUpdate }] =
    useAdminUpdateCustomPlanMutation()

  const onClose = () => {
    customPlansStore.onCloseModal()
  }

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [credits, setCredits] = useState(0)
  const [price, setPrice] = useState(0)
  const [costCredit, setCostCredit] = useState(0)
  const [period, setPeriod] = useState<PlanPeriod>(PlanPeriod.Monthly)
  const [extraCreditPrice, setExtraCreditPrice] = useState(0)

  useEffect(() => {
    if (customPlansStore.openModal) {
      setName(customPlansStore.modalPrice?.planName || '')
      setDescription(customPlansStore.modalPrice?.planDescription || '')
      setCredits(customPlansStore.modalPrice?.creditsIncluded || 0)
      onSetPrice(
        customPlansStore.modalPrice?.priceValue || 0,
        customPlansStore.modalPrice?.creditsIncluded || 0
      )
      setExtraCreditPrice(
        customPlansStore.modalPrice?.additionalCreditPrice || 0
      )
      setPeriod(
        (customPlansStore.modalPrice?.period?.toUpperCase() as PlanPeriod) ||
          PlanPeriod.Monthly
      )
    }
  }, [customPlansStore.openModal])

  const isEdit = !!customPlansStore.modalPrice

  const disabled = !name.trim() || !credits || !price || !extraCreditPrice

  const onAction = async () => {
    try {
      if (isEdit) {
        const { data } = await updateCustomPlan({
          variables: {
            input: {
              extraCreditPrice,
              name,
              description,
              id: customPlansStore.modalPrice?.id,
            },
          },
        })
        if (data?.adminUpdateCustomPlan) {
          customPlansStore.updatePrice(data.adminUpdateCustomPlan)
        }
      } else {
        const { data } = await createCustomPlan({
          variables: {
            input: {
              credits,
              period,
              price,
              extraCreditPrice,
              name,
              description,
            },
          },
        })
        if (data?.adminCreateCustomPlan) {
          customPlansStore.addPrice(data.adminCreateCustomPlan)
        }
      }
      onClose()
    } catch (e) {
      console.error(e)
    }
  }

  const options: IOption[] = [
    {
      value: PlanPeriod.Monthly,
      title: 'Monthly',
    },
    {
      value: PlanPeriod.Quarterly,
      title: 'Quarterly',
    },
    {
      value: PlanPeriod.Semiannual,
      title: 'Semi-Annual',
    },
    {
      value: PlanPeriod.Annual,
      title: 'Annual',
    },
  ]

  const onSetPrice = (price: number, propsCredits?: number) => {
    setPrice(price)
    if (propsCredits || credits) {
      setCostCredit(Number((price / (propsCredits || credits)).toFixed(4)))
    }
  }

  const onSetCostCredit = (cost: number) => {
    setCostCredit(cost)
    if (credits) {
      setPrice(Number((credits * cost).toFixed(4)))
    }
  }

  return (
    <Modal
      open={customPlansStore.openModal}
      title={(isEdit ? 'Edit' : 'New') + ' custom plan'}
      onClose={onClose}
    >
      <div className={classNames('pModal', styles.content)}>
        <div className={styles.row}>
          <span>
            Plan Name <span className={'error1'}>*</span>
          </span>
          <InputText value={name} onChangeValue={setName} />
        </div>
        <div className={styles.row}>
          <span>Description</span>
          <InputText value={description} onChangeValue={setDescription} />
        </div>
        <div className={styles.row}>
          <span>Period</span>
          <Dropdown
            options={options}
            selectedValue={period}
            onSelectValue={setPeriod}
            disabled={isEdit}
          />
        </div>
        <div className={styles.row}>
          <span>
            Credits <span className={'error1'}>*</span>
          </span>
          <InputText
            disabled={isEdit}
            numericFormat
            value={credits || ''}
            onChangeValue={setCredits}
            required
          />
        </div>
        <div className={styles.row}>
          <span>
            Price, $ <span className={'error1'}>*</span>
          </span>
          <InputText
            decimalScale={2}
            numericFormat
            value={price || ''}
            onChangeValue={(value: number) => onSetPrice(value)}
            disabled={isEdit}
            fixedDecimalScale
          />
        </div>
        <div className={styles.row}>
          <span>Credit cost, $</span>
          <InputText
            decimalScale={4}
            numericFormat
            value={costCredit || ''}
            onChangeValue={onSetCostCredit}
            disabled={isEdit}
          />
        </div>
        <div className={styles.row}>
          <span>
            Extra credit price, $ <span className={'error1'}>*</span>
          </span>
          <InputText
            decimalScale={4}
            numericFormat
            value={extraCreditPrice || ''}
            onChangeValue={setExtraCreditPrice}
          />
        </div>

        <SizedBox height={40} />
        <div className={'row12'}>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            onClick={onAction}
            loading={loadingUpdate || loadingCreate}
            disabled={disabled}
          >
            {(isEdit ? 'Update' : 'Create') + ' plan'}
          </Button>
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
})
