import { useState } from 'react'
import { ConversionsStore } from 'src/pages/settings/conversions/conversions/store/conversionsStore'
import { ConversionsUi } from 'src/pages/settings/conversions/conversions/ui/ConversionsUi'
import { ConversionsContext } from 'src/pages/settings/conversions/conversions/context/context'

export const Conversions = () => {
  const [store] = useState(() => new ConversionsStore())
  return (
    <ConversionsContext.Provider value={store}>
      <ConversionsUi />
    </ConversionsContext.Provider>
  )
}
