import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import { CreateTriggerStore } from 'store/triggers/createTriggerStore'
import { CreateBroadCastStore } from 'store/broadcasts/createBroadCastStore'
import { ModalIcon } from 'components/ModalIcon/ModalIcon'
import { SizedBox } from 'components/SizedBox'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'
import {
  Status,
  useCreateBroadcastMutation,
  useCreateCampaignMutation,
  useCreateKeywordMutation,
  useCreateTriggerMutation,
  useCreateWebFormMutation,
  useUpdateBroadcastMutation,
  useUpdateCampaignMutation,
  useUpdateKeywordMutation,
  useUpdateTriggerMutation,
  useUpdateWebFormMutation,
} from 'src/generated/graphql'
import { useMemo } from 'react'
import uiStore from 'store/uiStore'
import { CreateWebFormStore } from 'store/webForms/createWebFormStore'
import { CreateKeywordStore } from 'store/keywords/createKeywordStore'
import keywordsStore from 'store/keywords/keywordsStore'
import { CreateStoreTypes } from 'components/CreateElement/store/types'
import campaignsStore from 'store/campaigns/campaignsStore'
import webFormStore from 'store/webForms/webFormStore'
import { CreateCampaignStore } from 'store/campaigns/createCampaignStore'

export const CancelModal = observer(
  ({ store }: { store: CreateStoreTypes | CreateCampaignStore }) => {
    const [createBroadcast] = useCreateBroadcastMutation()
    const [updateBroadcast] = useUpdateBroadcastMutation()
    const [createTrigger] = useCreateTriggerMutation()
    const [updateTrigger] = useUpdateTriggerMutation()
    const [createWebForm] = useCreateWebFormMutation()
    const [updateWebForm] = useUpdateWebFormMutation()
    const [createKeyword] = useCreateKeywordMutation()
    const [updateKeyword] = useUpdateKeywordMutation()
    const [createCampaign] = useCreateCampaignMutation()
    const [updateCampaign] = useUpdateCampaignMutation()
    const onClose = () => {
      uiStore.setOpenCancelCreateModal(false)
    }
    const navigate = useNavigate()
    const onNavigate = () => {
      if (uiStore.nextRoute) {
        navigate(uiStore.nextRoute)
        uiStore.setNextRoute('')
        uiStore.setBeforeUnload(false)
        return
      }
      if (store instanceof CreateTriggerStore) {
        navigate(RoutesEnum.triggers)
      }
      if (store instanceof CreateBroadCastStore) {
        navigate(RoutesEnum.broadcasts)
      }
      if (store instanceof CreateWebFormStore) {
        navigate(RoutesEnum.webForms)
      }
      if (store instanceof CreateKeywordStore) {
        navigate(RoutesEnum.keywords)
      }
      if (store instanceof CreateCampaignStore) {
        navigate(RoutesEnum.campaigns)
      }
    }
    const dontSave = () => {
      onNavigate()
      onClose()
    }
    const saveAsDraft = async () => {
      try {
        onClose()
        if (store instanceof CreateBroadCastStore) {
          if (store.edit) {
            await updateBroadcast({
              variables: { broadcastInput: store.broadcastInput },
            })
          } else {
            await createBroadcast({
              variables: {
                broadcastInput: {
                  ...store.broadcastInput,
                  status: Status.Draft,
                },
              },
            })
          }
          onNavigate()
          return
        }
        if (store instanceof CreateTriggerStore) {
          if (store.edit) {
            await updateTrigger({
              variables: {
                triggerInput: store.updateTriggerInput,
              },
            })
          } else {
            await createTrigger({
              variables: {
                triggerInput: {
                  ...store.createTriggerInput,
                  status: Status.Draft,
                },
              },
            })
          }
          onNavigate()
          return
        }
        if (store instanceof CreateWebFormStore) {
          if (store.edit) {
            await updateWebForm({
              variables: {
                input: store.webFormInput,
              },
            })
          } else {
            await createWebForm({
              variables: {
                input: {
                  ...store.webFormInput,
                  status: Status.Draft,
                },
              },
            })
          }
          webFormStore.tableStore.onRefresh()
          onNavigate()
          return
        }
        if (store instanceof CreateKeywordStore) {
          await (store.edit ? updateKeyword : createKeyword)({
            variables: {
              keywordInput: {
                ...store.keywordInput,
                status: store.edit ? store.keywordInput.status : Status.Draft,
              },
            },
          })
          keywordsStore.tableStore.onRefresh()
          onNavigate()
          return
        }
        if (store instanceof CreateCampaignStore) {
          if (store.edit) {
            await updateCampaign({
              variables: {
                campaignInput: store.campaignUpdateInput,
              },
            })
          } else {
            await createCampaign({
              variables: {
                campaignInput: { ...store.campaignInput, status: Status.Draft },
              },
            })
          }

          campaignsStore.tableStore.onRefresh()
          onNavigate()
          return
        }
      } catch (e) {
        console.error(e)
      }
    }
    const type = useMemo(() => {
      if (store instanceof CreateTriggerStore) {
        return 'trigger'
      }
      if (store instanceof CreateBroadCastStore) {
        return 'broadcast'
      }
      if (store instanceof CreateWebFormStore) {
        return 'web form'
      }
      if (store instanceof CreateCampaignStore) {
        return 'campaign'
      }
      return ''
    }, [store])

    const disabledCreate = useMemo(() => {
      if (
        store instanceof CreateBroadCastStore ||
        store instanceof CreateTriggerStore
      ) {
        return false
      }
      return store.disabledCreate
    }, [store.disabledCreate])

    return (
      <Modal
        open={uiStore.openCancelCreateModal}
        onClose={onClose}
        withoutHeader
      >
        <div className={'pModalInfo'}>
          <ModalIcon type={'warning'} />
          <h3>
            Save {type} <b>“{store.name}”</b> before closing?
          </h3>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.primary}
            onClick={saveAsDraft}
            fullWidth
            disabled={disabledCreate}
          >
            {store.edit ? 'Save' : 'Save as draft'}
          </Button>
          <SizedBox height={8} />
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.secondaryGray}
            onClick={dontSave}
            fullWidth
          >
            Don’t save
          </Button>
          <SizedBox height={16} />
          <Button
            fullWidth
            size={BtnSize.medium}
            typeBtn={BtnType.secondaryGray}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    )
  }
)
