import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { TableStore } from 'components/NewTable/store/TableStore'
import uiStore from 'store/uiStore'
import { MediumBtn } from 'components/Button/MediumBtn'
import { ActionsEnum } from 'components/Button/types'
import { RequiredFields } from 'components/NewTable/types'
import { Input } from 'shared/Input/Input'

export const TableSearch = observer(
  <T extends RequiredFields<T>>({
    tableStore,
    onlySearch,
  }: {
    tableStore: TableStore<T>
    onlySearch?: boolean
  }) => {
    if (uiStore.isMobile && !tableStore.showMobileSearch && !onlySearch) {
      return (
        <MediumBtn
          type={ActionsEnum.search}
          noText
          onClick={() => tableStore.setShowMobileSearch(true)}
        />
      )
    }
    return (
      <Input
        leftIcon={'search'}
        placeholder={'Search...'}
        value={tableStore.search}
        setValue={tableStore.setSearch}
        withClear
        width={240}
      />
    )
  }
)
