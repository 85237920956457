// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErkCuq4kTi0Ky2iEdfko{height:100%;display:flex;flex-direction:column}.dQnPkloz8LKonN7XaHUO{height:100%;display:flex;flex-grow:1;flex-direction:column;align-items:center;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/pages/settings/conversions/conversions/ui/emptyState/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,qBAAA,CAEF,sBACE,WAAA,CACA,YAAA,CACA,WAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".wrap{\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.empty{\n  height: 100%;\n  display: flex;\n  flex-grow: 1;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `ErkCuq4kTi0Ky2iEdfko`,
	"empty": `dQnPkloz8LKonN7XaHUO`
};
export default ___CSS_LOADER_EXPORT___;
