import {
  MemberRegistrationType,
  useGetMainInfoQuery,
} from 'src/generated/graphql'
import { useEffect, useState } from 'react'
import billingStore from 'store/settings/billing/billingStore'
import user from 'store/user/user'
import auth from 'store/auth'
import { useNavigate } from 'react-router-dom'
import { UnauthorizedRoutes } from 'src/pages/routes'
import alertStore from 'store/alertStore'

export function useMainInfo() {
  const navigate = useNavigate()
  const [skip, setSkip] = useState(false)

  const { data, error, refetch } = useGetMainInfoQuery({
    skip,
    errorPolicy: 'all',
  })

  useEffect(() => {
    if (data) {
      if (data.getSubscription) {
        billingStore.setClSubscription(data.getSubscription)
      }
      if (data.getOrganizationBillingInfo) {
        billingStore.setOrganizationBillingInfo(data.getOrganizationBillingInfo)
      }
      if (data.getMember) {
        user.setMember(data.getMember)
      }

      if (
        data.getMember?.memberRegistration?.type ===
          MemberRegistrationType.Migration &&
        !data.getMember.registrationFinished
      ) {
        navigate(UnauthorizedRoutes.migration)
      }

      setSkip(true)
      auth.setLoadingMainInfo(false)
      if (
        data.getMember &&
        !data.getMember.organization &&
        error?.graphQLErrors[0].message
      ) {
        alertStore.disabledErrorAlert(error?.graphQLErrors[0].message)
      }
    }
  }, [data, error])

  useEffect(() => {
    if (auth.refetchMainInfo) {
      refetch().then(({ data }) => {
        if (data.getSubscription) {
          billingStore.setClSubscription(data.getSubscription)
        }
        if (data.getOrganizationBillingInfo) {
          billingStore.setOrganizationBillingInfo(
            data.getOrganizationBillingInfo
          )
        }
        if (data.getMember) {
          user.setMember(data.getMember)
        }
        setSkip(true)
        auth.setLoadingMainInfo(false)
      })
    }
  }, [auth.refetchMainInfo])
}
