import { observer } from 'mobx-react-lite'
import { Schedule } from 'components/Schedule/Schedule'
import { useCampaignScheduleContext } from 'src/widgets/CampaignSchedule'
import { ImmediatelyCard } from 'components/Schedule/ImmediatelyCard/ImmediatelyCard'
import { LaterCard } from 'components/Schedule/LaterCard/LaterCard'
import { CampaignDelayType } from 'src/generated/graphql'
import { LaterContentCampaign } from 'src/widgets/CampaignSchedule/ui/LaterContentCampaign/LaterContentCampaign'
import { CalendarDateCard } from 'src/widgets/CampaignSchedule/ui/CalendarDateCard/CalendarDateCard'
import { ContactDateFieldCard } from 'src/widgets/CampaignSchedule/ui/ContactDateFieldCard/ContactDateFieldCard'

export const CampaignScheduleContent = observer(() => {
  const {
    setSendTypeCampaign,
    activeSendImmediately,
    activeSendLater,
    sendTimeString,
    suffixString,
    scheduleError,
  } = useCampaignScheduleContext()

  const onActiveImmediately = () => {
    setSendTypeCampaign(CampaignDelayType.Immediate)
  }

  const onActiveLater = () => {
    setSendTypeCampaign(CampaignDelayType.Delay)
  }

  return (
    <Schedule
      placement={'bottom'}
      sendTimeString={sendTimeString}
      suffixString={suffixString}
      scheduleError={scheduleError}
      scheduleContent={
        <>
          <ImmediatelyCard
            onActive={onActiveImmediately}
            isActive={activeSendImmediately}
          />
          <LaterCard
            onActive={onActiveLater}
            isActive={activeSendLater}
            content={<LaterContentCampaign />}
          />
          <CalendarDateCard />
          <ContactDateFieldCard />
        </>
      }
    />
  )
})
