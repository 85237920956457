// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ucMXo4oaSgHAdhJKYZrZ{position:relative}.OIYUtNk0iD47y1mU_fA9{position:absolute;right:4px;top:28px;height:32px}.OIYUtNk0iD47y1mU_fA9 button{min-height:unset;height:32px;border:none;background-color:var(--primary-color-white);outline:none !important}.RuBjkbqn44PIARW8dm49{display:flex;column-gap:12px}.HT3sEo5aEeywsTS7Srwc{display:grid;grid-template-columns:343px 115px;column-gap:12px}.wHjDhsPLEVwbjBzmvhu1{padding:12px 16px 12px 12px}`, "",{"version":3,"sources":["webpack://./src/pages/settings/company/routes/CompanyTeam/modals/MemberModal/InviteContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAEF,sBACE,iBAAA,CACA,SAAA,CACA,QAAA,CACA,WAAA,CACA,6BACE,gBAAA,CACA,WAAA,CACA,WAAA,CAEA,2CAAA,CACA,uBAAA,CAIJ,sBACE,YAAA,CACA,eAAA,CAEF,sBACE,YAAA,CACA,iCAAA,CACA,eAAA,CAEF,sBACE,2BAAA","sourcesContent":[".email{\n  position: relative;\n}\n.drop{\n  position: absolute;\n  right: 4px;\n  top: 28px;\n  height: 32px;\n  button{\n    min-height: unset;\n    height: 32px;\n    border: none;\n\n    background-color: var(--primary-color-white);\n    outline: none!important;\n  }\n\n}\n.actions{\n  display: flex;\n  column-gap: 12px;\n}\n.inviteLinkContent{\n  display: grid;\n  grid-template-columns: 343px 115px;\n  column-gap: 12px;\n}\n.copyBtn{\n  padding: 12px 16px 12px 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"email": `ucMXo4oaSgHAdhJKYZrZ`,
	"drop": `OIYUtNk0iD47y1mU_fA9`,
	"actions": `RuBjkbqn44PIARW8dm49`,
	"inviteLinkContent": `HT3sEo5aEeywsTS7Srwc`,
	"copyBtn": `wHjDhsPLEVwbjBzmvhu1`
};
export default ___CSS_LOADER_EXPORT___;
