import NewTable from 'components/NewTable/NewTable'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { WrapPageSuperAdmin } from 'src/pages/superAdmin/components/WrapPageSuperAdmin/WrapPageSuperAdmin'
import { useAdminCustomPlansQuery } from 'src/generated/graphql'
import { Actions } from 'src/pages/superAdmin/routes/customPlans/Actions'
import { ISimplePrice } from 'src/types/ISimplePrice'
import customPlansStore from 'store/superAdmin/customPlansStore'

export const CustomPlans = observer(() => {
  const [fistLoading, setFirstLoading] = useState(true)
  const [refetchLoading, setRefetchLoading] = useState(false)

  const tableStore = customPlansStore.tablesStore
  const { data, refetch, loading } = useAdminCustomPlansQuery()

  useEffect(() => {
    if (data?.adminCustomPlans) {
      setFirstLoading(false)
      customPlansStore.setPrices(data)
    }
  }, [data])

  useEffect(() => {
    setRefetchLoading(true)
    refetch()
      .catch((e) => console.error(e))
      .finally(() => setRefetchLoading(false))
  }, [tableStore.refreshTrigger])

  const onRowClick = (id: string, row: ISimplePrice) => {
    customPlansStore.onOpenModal(row)
  }

  return (
    <>
      <WrapPageSuperAdmin title={'Custom Plans'}>
        <NewTable<ISimplePrice>
          columns={tableStore.visibleColumns}
          withCalcMaxHeight
          rows={customPlansStore.prices.map((prices) => ({
            ...prices,
            // actions: getImportsActions(importState),
          }))}
          onRowClick={onRowClick}
          noPagination
          leftStickyContent={<Actions />}
          tableStore={tableStore}
          firstLoading={fistLoading}
          pageLoading={loading || refetchLoading}
          noSorting
          emptyContent={
            !customPlansStore.prices.length ? <div></div> : undefined
          }
        />
      </WrapPageSuperAdmin>
    </>
  )
})
