import { makeAutoObservable } from 'mobx'
import { IListItem } from 'components/SelectList/ListItem/ListItem'
import {
  BroadcastSendingSpeedConfig,
  BroadcastSendingSpeedConfigInput,
  SendingSpeedUnit,
} from 'src/generated/graphql'

export class ActiveContentStore {
  constructor(config?: BroadcastSendingSpeedConfig) {
    makeAutoObservable(this)
    if (config) {
      this.init(config)
    }
    // this.getBroadcastSpeedOptions()
  }

  get broadcastSendingSpeedConfigInput(): BroadcastSendingSpeedConfigInput {
    return {
      unit: this.unit,
      contactsAmount: this.contactsCount || 10,
      unitAmount: this.unitAmount || 10,
    }
  }

  unit = SendingSpeedUnit.Minute

  contactsCount: number | null = null
  setContactsCount = (value: number) => {
    this.contactsCount = value
  }

  minutes = [10, 15, 30, 60]
  get minutesOptions(): IListItem[] {
    return this.minutes.map((minute) => ({
      id: minute,
      title: String(minute),
      isActive: this.unitAmount === minute,
    }))
  }
  unitAmount: number = this.minutes[0]
  setUnitAmount = (value: typeof this.unitAmount) => {
    this.unitAmount = value
  }

  init = (config: BroadcastSendingSpeedConfig) => {
    this.contactsCount = config.contactsAmount
    this.unitAmount = config.unitAmount
  }

  // broadcastSpeedOptions: BroadcastSpeedOption[] = []
  // getBroadcastSpeedOptions = async () => {
  //   try {
  //     const { data } = await BroadcastApi.getBroadcastSpeedOptions()
  //     if (data.getBroadcastSpeedOptions) {
  //       runInAction(() => {
  //         const broadcastSpeedOptions: BroadcastSpeedOption[] = []
  //         data.getBroadcastSpeedOptions?.forEach((option) => {
  //           if (option) {
  //             broadcastSpeedOptions.push(option)
  //           }
  //         })
  //         this.broadcastSpeedOptions = broadcastSpeedOptions
  //       })
  //     }
  //   } catch (e) {
  //     console.error(e)
  //   }
  // }
}
