import { observer } from 'mobx-react-lite'
import { AmericanAddressBlock } from 'src/pages/settings/account/compponents/AmericanAddressBlock/AmericanAddressBlock'
import businessProfileStore from 'store/settings/company/businessProfileStore'
import { SizedBox } from 'components/SizedBox'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { PropsBusinessStep } from 'src/pages/settings/compliance/BusinessProfile/types'
import {
  Asset,
  BusinessProfileResponse,
  useAdminInitBusinessGeneralInfoByManualEinMutation,
  useInitBusinessGeneralInfoByManualEinMutation,
  useRemoveAssetsMutation,
} from 'src/generated/graphql'
import mediaStore from 'store/mediaStore'
import { useState } from 'react'

export const Tab1 = observer(
  ({ onCancel, loadingPrevious, onSuccess }: PropsBusinessStep) => {
    const { adminOrgId } = businessProfileStore
    const [removedIds, setRemovedIds] = useState<string[]>([])
    const [initBusinessGeneralInfoByManualEin, { loading }] =
      useInitBusinessGeneralInfoByManualEinMutation()
    const [adminInitBusinessGeneralInfoByManualEin, { loading: loadingAdmin }] =
      useAdminInitBusinessGeneralInfoByManualEinMutation()
    const [removeAssets] = useRemoveAssetsMutation()
    const onContinue = async () => {
      try {
        let profile: BusinessProfileResponse | undefined | null
        if (adminOrgId) {
          const { data } = await adminInitBusinessGeneralInfoByManualEin({
            variables: {
              input: businessProfileStore.businessGeneralInfoEinRequestInput,
              organizationId: adminOrgId,
            },
          })
          profile = data?.adminInitBusinessGeneralInfoByManualEin
        } else {
          const { data } = await initBusinessGeneralInfoByManualEin({
            variables: {
              input: businessProfileStore.businessGeneralInfoEinRequestInput,
            },
          })
          profile = data?.initBusinessGeneralInfoByManualEin
        }

        if (profile) {
          onSuccess(profile)
          if (removedIds.length) {
            await removeAssets({
              variables: {
                assetIds: removedIds,
              },
            })
          }
        }
      } catch (e) {
        console.error(e)
      }
    }

    const onAddIrsFiles = (files: File[]) => {
      const onSuccess = (assets: Asset[]) => {
        businessProfileStore.addIrsAssets(assets)
      }
      if (files.length) {
        mediaStore.setUploadImages([files[0]], onSuccess)
      }
    }

    const onRemoveIrsFile = (id: string) => {
      setRemovedIds([...removedIds, id])
      businessProfileStore.onRemoveIrsFile()
    }
    return (
      <>
        <AmericanAddressBlock
          address={businessProfileStore.manualBusinessGeneralInfo?.address}
          setAddress={(address) =>
            businessProfileStore.setManualBusinessGeneralInfo({
              ...businessProfileStore.manualBusinessGeneralInfo,
              address,
            })
          }
          ein={businessProfileStore.manualEIN}
          setEin={(value) => businessProfileStore.setManualEIN(value)}
          name={businessProfileStore.manualBusinessGeneralInfo?.name || ''}
          setName={(name) =>
            businessProfileStore.setManualBusinessGeneralInfo({
              ...businessProfileStore.manualBusinessGeneralInfo,
              name,
            })
          }
          onBlurEin={() =>
            businessProfileStore.setError(
              'manualErrorEin',
              businessProfileStore.manualEIN.replace('-', '').trim().length !==
                9
            )
          }
          onFocusEin={() =>
            businessProfileStore.setError('manualErrorEin', false)
          }
          errorEin={businessProfileStore.errorManualEin}
          countryCode={'US'}
          irsAssets={
            businessProfileStore.irsAsset
              ? [businessProfileStore.irsAsset]
              : undefined
          }
          onAddIrsFiles={onAddIrsFiles}
          onRemoveFile={onRemoveIrsFile}
          irsLoading={mediaStore.assetLoading}
        />
        <SizedBox height={40} />
        <div className={'row12'}>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.primary}
            onClick={onContinue}
            disabled={businessProfileStore.disabledGeneralInfoWithEINManual}
            loading={loading || loadingAdmin}
          >
            {businessProfileStore.continueText}
          </Button>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.secondaryGray}
            onClick={onCancel}
            loading={loadingPrevious}
          >
            {businessProfileStore.step === 1 ? 'Cancel' : 'Back'}
          </Button>
        </div>
      </>
    )
  }
)
