// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QBgE3kHICAF4_DGXPsEI{display:grid;grid-template-columns:1fr 16px;justify-items:flex-start;gap:16px;min-height:32px;padding:8px;align-items:center;border-radius:8px}.QBgE3kHICAF4_DGXPsEI.WGHCED9i58pr9y9NNkJ6{grid-template-columns:1fr}.QBgE3kHICAF4_DGXPsEI:hover{cursor:pointer;background:var(--primary-color-gray-4)}.QBgE3kHICAF4_DGXPsEI .RohU_JzjHvEkTAS2kc8B{text-align:left}`, "",{"version":3,"sources":["webpack://./src/components/SelectList/ListItem/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,8BAAA,CACA,wBAAA,CACA,QAAA,CACA,eAAA,CACA,WAAA,CACA,kBAAA,CACA,iBAAA,CACA,2CACE,yBAAA,CAEF,4BACE,cAAA,CACA,sCAAA,CAEF,4CACE,eAAA","sourcesContent":[".wrap{\n  display: grid;\n  grid-template-columns: 1fr 16px;\n  justify-items: flex-start;\n  gap: 16px;\n  min-height: 32px;\n  padding: 8px;\n  align-items: center;\n  border-radius: 8px;\n  &.withoutMark{\n    grid-template-columns: 1fr;\n  }\n  &:hover{\n    cursor: pointer;\n    background: var(--primary-color-gray-4);\n  }\n  .title{\n    text-align: left;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `QBgE3kHICAF4_DGXPsEI`,
	"withoutMark": `WGHCED9i58pr9y9NNkJ6`,
	"title": `RohU_JzjHvEkTAS2kc8B`
};
export default ___CSS_LOADER_EXPORT___;
