import { createContext, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { KeywordDetailStore } from 'store/keywords/keywordDetailStore'
import { KeywordDetailTable } from 'src/pages/main/keywords/detail/KeywordDetailTable/KeywordDetailTable'
import { HeaderContent } from 'src/pages/main/keywords/detail/header/HeaderContent'
import { useGetKeywordByIdQuery } from 'src/generated/graphql'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'

const KeywordDetailContext = createContext<KeywordDetailStore>(
  new KeywordDetailStore()
)

export function useKeywordDetailContext() {
  return useContext(KeywordDetailContext)
}

export const KeywordDetailPage = observer(() => {
  const [store] = useState(new KeywordDetailStore())
  const { id } = useParams()
  const { data, loading } = useGetKeywordByIdQuery({
    variables: {
      keywordId: id,
    },
  })
  useEffect(() => {
    if (data?.getKeywordById) {
      store.setKeyword(data.getKeywordById)
    }
  }, [data])
  return (
    <KeywordDetailContext.Provider value={store}>
      {loading && <AbsoluteLoader size={40} />}
      {!loading && store.keyword && (
        <>
          <HeaderContent />
          <KeywordDetailTable />
        </>
      )}
    </KeywordDetailContext.Provider>
  )
})
