import { createContext, useContext } from 'react'
import { TextareaStore } from 'store/textareaStore'

export const TextareaContext = createContext<TextareaStore | null>(null)

export const useTextareaContext = () => {
  const context = useContext(TextareaContext)
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with TextareaContext'
    )
  }
  return context
}
