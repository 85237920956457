import React, { ReactNode, useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'
import { NumericFormat } from 'react-number-format'
import classNames from 'classnames'
import { Icon, IIcon } from 'components/Icon/Icon'

type Props<T> = {
  value: T
  setValue?: (val: T) => void
  leftIcon?: IIcon
  isNumber?: boolean
  rightContent?: ReactNode
  placeholder?: string
  isReadOnly?: boolean
  isFocusOnMount?: boolean
  toggleFocus?: (value: boolean) => void
}

export const Input = ({
  isNumber,
  leftIcon,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  value,
  setValue,
  rightContent,
  placeholder = 'Enter value',
  isReadOnly,
  isFocusOnMount,
  toggleFocus,
}: Props<typeof value>) => {
  const [focus, setFocus] = useState(false)
  const refInput = useRef<HTMLInputElement | null>(null)

  const onFocus = () => {
    setFocus(true)
    toggleFocus && toggleFocus(true)
  }
  const onBlur = () => {
    setFocus(false)
    toggleFocus && toggleFocus(false)
  }

  useEffect(() => {
    if (refInput.current && isFocusOnMount) {
      refInput.current.focus()
    }
  }, [isFocusOnMount])

  return (
    <div
      className={classNames(styles.wrap, {
        [styles.focus]: focus,
      })}
    >
      {leftIcon && <Icon icon={leftIcon} color={'--primary-color-gray-1'} />}
      {isNumber ? (
        <NumericFormat
          getInputRef={refInput}
          value={value}
          thousandSeparator
          onValueChange={(values) => setValue && setValue(values.floatValue)}
          placeholder={placeholder}
          className={styles.input}
          onFocus={onFocus}
          onBlur={onBlur}
          readOnly={isReadOnly}
        />
      ) : (
        <input
          ref={refInput}
          value={value}
          onChange={(event) => setValue && setValue(event.target.value)}
          placeholder={placeholder}
          className={styles.input}
          onFocus={onFocus}
          onBlur={onBlur}
          readOnly={isReadOnly}
        />
      )}

      {rightContent}
    </div>
  )
}
