import { makeAutoObservable } from 'mobx'
import { TableStore } from 'components/NewTable/store/TableStore'
import {
  AdminGetImportHistoryQuery,
  AllowImportStatus,
  Direction,
} from 'src/generated/graphql'
import { IContactImportState } from 'src/pages/main/contacts/importHistory/ImportHistoryTable/types'
import { superAdminImportColumns } from 'components/NewTable/columns/superAdmin/superAdminImportColumns'
import axios from 'axios'

class SuperAdminImportsStore {
  tableStore: TableStore<IContactImportState>
  constructor() {
    this.tableStore = new TableStore({
      columns: superAdminImportColumns,
      tableName: 'SuperAdminImportsTable',
      orderBy: 'createdAt',
      order: Direction.Desc,
    })
    makeAutoObservable(this)
  }

  importsMap: Map<string, IContactImportState> = new Map()
  importsStatuses: AllowImportStatus[] = [AllowImportStatus.NeedsReview]

  get imports(): IContactImportState[] {
    return Array.from(this.importsMap.values())
  }

  setAdminGetImportHistoryQuery(response: AdminGetImportHistoryQuery) {
    const filteredData: IContactImportState[] = []
    response.adminGetImportHistory?.content?.forEach((history) => {
      if (history) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        filteredData.push({ ...history, id: history.id || '' })
      }
    })
    this.importsMap = new Map<string, IContactImportState>(
      filteredData.map((state) => [state.id, state])
    )
    this.tableStore.setTotal(response?.adminGetImportHistory?.total || 0)
  }

  setImportStatuses(statuses: AllowImportStatus[]) {
    this.importsStatuses = statuses
  }

  async downloadFileImport(
    importId?: string,
    originalFileName?: string | null
  ) {
    axios({
      url: `auth/admin/import/download/${importId}`, //your url
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', originalFileName || 'file.pdf') //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
  }
}

export default new SuperAdminImportsStore()
