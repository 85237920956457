import { ICardDetail } from 'components/DetailCard/DetailCard/DetailCard'
import { observer } from 'mobx-react-lite'
import { useWebFormDetailContext } from 'src/pages/main/webForms/detail/webFormDetailPage'
import { TableStatuses } from 'components/NewTable/store/TableStore'
import { useGetWebFormContactsStatisticsQuery } from 'src/generated/graphql'
import { useEffect } from 'react'
import { DetailCardsWrapper } from 'components/DetailCard/DetailCardsWrapper'
import { detailsCards } from 'components/DetailCard/variants'

export const AdditionalContent = observer(() => {
  const context = useWebFormDetailContext()
  const { webForm, tableStore } = context
  if (!webForm) {
    return <></>
  }

  const { data, refetch } = useGetWebFormContactsStatisticsQuery({
    variables: {
      uuid: webForm.uuid,
      dateRange: tableStore.rangeDatePickerStore.dateRange,
    },
    skip: tableStore.rangeDatePickerStore.isSkipByDateRange,
  })

  const shortUrlClickStatistics =
    data?.getWebFormContactsStatistics?.shortUrlClickStatistics
  const totalStatistics = data?.getWebFormContactsStatistics?.totalStat
  const percentageStatistics =
    data?.getWebFormContactsStatistics?.percentageStatistics
  const todayStatistics = data?.getWebFormContactsStatistics?.todayStat

  const subscribedCount = totalStatistics?.subscribed || 0
  const activeCount = totalStatistics?.active || 0
  const confirmedCount = totalStatistics?.confirmed || 0
  const unsubscribedCount = totalStatistics?.unsubscribed || 0

  const subscribedPercentage = percentageStatistics?.subscribed || 0
  const activePercentage = percentageStatistics?.active || 0
  const confirmedPercentage = percentageStatistics?.confirmed || 0
  const unsubscribedPercentage = percentageStatistics?.unsubscribed || 0

  const todaySubscribedCount = todayStatistics?.subscribed || 0
  const todayActiveCount = todayStatistics?.active || 0
  const todayConfirmedCount = todayStatistics?.confirmed || 0
  const todayUnsubscribedCount = todayStatistics?.unsubscribed || 0

  const cards: ICardDetail[] = [
    {
      title: 'Visitors',
      value: TableStatuses.visitorsCount,
      status: TableStatuses.visitorsCount,
      count: 0,
      percent: 0,
      today: 0,
    },
    {
      title: 'Subscribers',
      value: TableStatuses.subscribedCount,
      status: TableStatuses.subscribedCount,
      hidePercent: true,
      count: subscribedCount,
      percent: subscribedPercentage,
      today: todaySubscribedCount,
    },

    {
      title: 'Confirmed',
      value: TableStatuses.confirmedCount,
      status: TableStatuses.confirmedCount,
      count: confirmedCount,
      percent: confirmedPercentage,
      today: todayConfirmedCount,
    },
    {
      title: 'Active',
      value: TableStatuses.activeCount,
      status: TableStatuses.activeCount,
      count: activeCount,
      percent: activePercentage,
      today: todayActiveCount,
    },
    shortUrlClickStatistics?.shortUrlVisits?.length &&
      detailsCards.shortUrlClickStatistics({
        shortUrlClickStatistics,
        card: {
          today: shortUrlClickStatistics?.totalVisitsToday,
        },
      }),
    detailsCards.unsubscribed({
      count: unsubscribedCount,
      percent: unsubscribedPercentage,
      today: todayUnsubscribedCount,
    }),
  ].filter((card) => Boolean(card)) as ICardDetail[]

  useEffect(() => {
    context.setContactStatistics(data?.getWebFormContactsStatistics || null)
  }, [data])

  useEffect(() => {
    refetch().catch(console.error)
  }, [tableStore.refreshTrigger])

  return <DetailCardsWrapper cards={cards} tableStore={tableStore} />
})
