import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

import audioStore from 'store/audioStore/audioStore'
import { OneSelectContent } from 'src/pages/settings/audio/AudioTable/LeftStickyContent/OneSelectContent'
import { NoSelectContent } from 'src/pages/settings/audio/AudioTable/LeftStickyContent/NoSelectContent'
import { ManySelectContent } from 'src/pages/settings/audio/AudioTable/LeftStickyContent/ManySelectContent'

export const LeftStickyContent = observer(() => {
  const tableStore = audioStore.tableStore
  const content = useMemo(() => {
    if (tableStore.checkedRowsIds.length) {
      if (
        tableStore.checkedRows.length &&
        tableStore.checkedRows.length === 1
      ) {
        return <OneSelectContent audioItem={tableStore.checkedRows[0]} />
      }
      return <ManySelectContent audioItems={tableStore.checkedRows} />
    }
    return <NoSelectContent />
  }, [tableStore.checkedRowsIds])
  return <div className={'row12'}>{content}</div>
})
