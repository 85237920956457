import { makeAutoObservable, runInAction } from 'mobx'
import contactStore from 'store/contacts/contactStore'
import customFieldsStore from 'store/contacts/customFields/customFieldsStore'
import axios, { AxiosError } from 'axios'
import { nanoid } from 'nanoid'
import billingStore from 'store/settings/billing/billingStore'
import { TableStore } from 'components/NewTable/store/TableStore'
import { IContactImportState } from 'src/pages/main/contacts/importHistory/ImportHistoryTable/types'
import { columns } from 'src/pages/main/contacts/importHistory/ImportHistoryTable/columns'
import { Direction, GetImportHistoryQuery } from 'src/generated/graphql'
import { IImportContactInfo } from 'src/types/IImportContactInfo'
import { IUploadContactData } from 'src/types/IUploadContactData'
import { IUploadContactDataColumns } from 'src/types/IUploadContactDataColumns'
import { IContactList } from 'src/types/IContactList'
import { IImportData } from 'src/types/IImportData'
import { IOption } from 'src/types/IOption'
import companyStore from 'store/settings/company/companyStore'

class ImportContactStore {
  contactStore: typeof contactStore
  billingStore: typeof billingStore
  tableStore: TableStore<IContactImportState>

  constructor() {
    this.contactStore = contactStore
    this.billingStore = billingStore
    this.tableStore = new TableStore({
      tableName: 'Import History',
      columns,
      orderBy: 'createdAt',
      order: Direction.Desc,
    })
    makeAutoObservable(this)
  }

  openImportModal = false
  fromList = false
  skipFirstRow = false
  uploadFiles: File[] = []
  uploadContactsData: Array<IUploadContactData> = []
  originalUploadContactsData: Array<IUploadContactData> = []
  uploadContactsDataColumnsMap: Map<string, IUploadContactDataColumns> =
    new Map<string, IUploadContactDataColumns>()
  originalContactsDataColumnsMap: Map<string, IUploadContactDataColumns> =
    new Map<string, IUploadContactDataColumns>()
  agreeTerm = false
  selectedOptions: IOption[] = []
  initialUploadContactCount = 0
  importContactInfo: IImportContactInfo | null = null
  importContactData: IImportData | null = null
  importLoadingFile = false
  importLoadingError = ''
  iContactImportStateMap: Map<string, IContactImportState> = new Map<
    string,
    IContactImportState
  >()
  loadingTriggerImportState = ''
  currentHistoryState: IContactImportState | null = null

  get iContactImportState(): IContactImportState[] {
    return Array.from(this.iContactImportStateMap.values())
  }

  get contactsCount() {
    return this.contactStore.totalContactCount
  }

  get noLimitContact() {
    return (
      !!this.billingStore.clSubscription?.limits?.contacts &&
      this.billingStore.clSubscription?.limits?.contacts > 1000
    )
  }

  get columns() {
    return Array.from(this.originalContactsDataColumnsMap.values()).map(
      (column) => {
        const newData = this.uploadContactsDataColumnsMap.get(
          column.defaultName
        )
        if (newData) {
          return newData.selectName
        } else {
          return column.selectName
        }
      }
    )
  }

  get defaultColumns() {
    return Array.from(this.uploadContactsDataColumnsMap.values()).map(
      (column) => column.defaultName
    )
  }

  get newContactCount(): number {
    return (
      this.contactStore.maxContactCount -
      this.contactsCount -
      (this.importContactData?.importPayload?.contactsCount || 0)
    )
  }

  get overContactsCount(): number {
    return (
      (this.importContactData?.importPayload?.contactsCount || 0) +
      this.newContactCount
    )
  }

  get disabledToStep3() {
    let disabled = true
    this.uploadContactsDataColumnsMap.forEach((column) => {
      if (column.selectName === 'phoneNumber') {
        disabled = false
      }
    })
    return disabled
  }

  get contactFieldsOptions(): IOption[] {
    // if (this.importContactData) {
    //   return this.importContactData.fields.map((field) => ({
    //     title: field.name,
    //     value: field.key,
    //   }))
    // }
    return [
      ...this.contactStore.contactFieldsOptions,
      {
        value: 'status',
        title: 'Status',
      },
      ...customFieldsStore.customFieldsOptions.map((f) => ({
        ...f,
        group: 'Custom fields',
      })),
    ]
  }

  get selectedContactLists() {
    const findContactLists: IContactList[] = []
    this.selectedOptions.forEach((opt) => {
      const list = this.contactStore.contactListsMap.get(String(opt.value))
      if (list) {
        findContactLists.push(list)
      }
    })
    return findContactLists
  }

  setIContactImportStateResponse(response: GetImportHistoryQuery) {
    const filteredData: IContactImportState[] = []
    response.getImportHistory?.content?.forEach((history) => {
      if (history) {
        filteredData.push({ ...history, id: history.id || '' })
      }
    })
    if (response.getMyOrganization) {
      companyStore.setAllowImport(response.getMyOrganization?.allowImport)
    }

    this.iContactImportStateMap = new Map<string, IContactImportState>(
      filteredData.map((state) => [state.id, state])
    )
    this.tableStore.setTotal(response?.getImportHistory?.total || 0)
  }

  setLoadingIContactImportState(states: IContactImportState[]) {
    states.forEach((state) => {
      this.iContactImportStateMap.set(state.id, state)
    })
  }

  setUploadContactsDataColumnsValue(opt: IOption, columnKey: string) {
    this.uploadContactsDataColumnsMap.forEach((column, key) => {
      if (column.selectName === opt.value && columnKey !== key) {
        const newId = 'ignore_' + nanoid()
        this.uploadContactsDataColumnsMap.set(key, {
          ...column,
          selectName: newId,
        })
      }
    })
    const prev = this.uploadContactsDataColumnsMap.get(columnKey)
    if (prev) {
      if (typeof opt.value === 'string') {
        prev.selectName = opt.value
      }
      this.uploadContactsDataColumnsMap.set(columnKey, prev)
    }
  }

  setAgreeTerms(state: boolean) {
    this.agreeTerm = state
  }

  onDeleteTag = (id: string | number) => {
    this.selectedOptions = this.selectedOptions.filter(
      (opt) => opt.value !== id
    )
    const list = this.contactStore.contactListsMap.get(id)
    if (list?.isNew) {
      this.contactStore.contactListsMap.delete(id)
    }
  }

  setOpenImportModal(state: boolean, id = '') {
    this.importLoadingError = ''
    this.openImportModal = state
    this.fromList = !!id
    if (id) {
      const currentList = this.contactStore.contactListsMap.get(id)
      if (currentList) {
        this.selectedOptions = [
          {
            title: currentList.name,
            value: currentList.id,
          },
        ]
      }
    }
  }

  onSelectOptions(options: IOption[]) {
    this.selectedOptions = options
  }

  setUploadFiles(files: File[]) {
    this.importLoadingFile = true
    this.importLoadingError = ''
    const file = files[0]
    const formData = new FormData()
    formData.append('file', file)
    axios
      .post('contact/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        runInAction(() => {
          this.importContactData = res.data
          if (this.importContactData) {
            const columns: string[] = []
            this.importContactData?.filePreview.firstRow.forEach(
              (el, index) => {
                const key =
                  index < 4
                    ? this.contactStore.contactFieldsOptions[index].value
                    : null
                if (key) {
                  columns.push(key)
                } else {
                  columns.push(nanoid())
                }
              }
            )

            const rows = [
              this.importContactData.filePreview.firstRow,
              ...this.importContactData.filePreview.previewRows,
            ]
            this.uploadContactsData = rows.map((row) =>
              row.reduce((acc, cur, index) => {
                const key: string = columns[index]
                acc[key] = row[index]
                return acc
              }, {} as { [key: string]: string })
            )
            this.originalUploadContactsData = JSON.parse(
              JSON.stringify(this.uploadContactsData)
            )
            this.uploadContactsDataColumnsMap = new Map(
              columns.map((key) => [
                key,
                {
                  defaultName: key,
                  selectName: 'ignore_' + key,
                },
              ])
            )
            this.originalContactsDataColumnsMap = new Map<
              string,
              IUploadContactDataColumns
            >(JSON.parse(JSON.stringify(this.uploadContactsDataColumnsMap)))
          }
          this.uploadFiles = files
        })
      })
      .catch((e) => {
        if (e instanceof AxiosError) {
          this.importLoadingError = e.response?.data?.message
        }
      })
      .finally(() => {
        runInAction(() => {
          this.importLoadingFile = false
        })
      })
  }
  removeFile() {
    this.importContactData = null
    this.uploadContactsData = []
    this.originalUploadContactsData = []
    this.uploadFiles = []
  }

  removeColumn(columnKey: string) {
    this.uploadContactsData = [
      ...this.uploadContactsData.map((data) => {
        delete data[columnKey]
        return data
      }),
    ]
    this.uploadContactsDataColumnsMap.delete(columnKey)
  }

  closeModal() {
    this.importContactData = null
    this.uploadContactsData = []
    this.originalUploadContactsData = []
    this.uploadFiles = []
    this.openImportModal = false
    this.agreeTerm = false
    this.selectedOptions = []
    this.skipFirstRow = false
    this.contactStore.contactLists.forEach((list) => {
      if (list.isNew) {
        this.contactStore.contactListsMap.delete(list.id)
      }
    })
  }

  resetMapFields() {
    this.uploadContactsDataColumnsMap = new Map(
      JSON.parse(JSON.stringify(this.originalContactsDataColumnsMap))
    )
    this.uploadContactsData = JSON.parse(
      JSON.stringify(this.originalUploadContactsData)
    )
  }
  setLoadingTriggerImportState() {
    this.loadingTriggerImportState = nanoid()
  }
  removeContactImportStates() {
    this.tableStore.setCheckedRows([])
    this.tableStore.onRefresh()
  }
  setCurrentHistoryState(state: IContactImportState) {
    this.currentHistoryState = state
  }
}

export default new ImportContactStore()
