import { getActionsByStatus } from 'src/pages/main/campaigns/functions/getActionsByStatus'
import { FC } from 'react'
import { ActionBtnProps } from 'components/NewTable/types'
import { ICampaign } from 'src/types/ICampaign'
import { campaignsBtnByAction } from 'src/pages/main/campaigns/functions/campaignsBtnByAction'

export const getCampaignsActions = (
  campaign: ICampaign,
  fromLeftStickyContent?: boolean
): Array<FC<ActionBtnProps<ICampaign>>> => {
  const actions = campaign.status && getActionsByStatus(campaign.status)

  const actionsFC: Array<FC<ActionBtnProps<ICampaign>>> = []

  actions?.forEach((action) => {
    const btn: FC<ActionBtnProps<ICampaign>> | undefined =
      campaignsBtnByAction[action as keyof typeof campaignsBtnByAction]
    if (btn) {
      actionsFC.push((props) =>
        btn({ ...props, row: campaign, fromLeftStickyContent })
      )
    }
  })
  return actionsFC
}
