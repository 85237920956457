import * as React from 'react'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import audioStore from 'store/audioStore/audioStore'
import { ReactComponent as Plus } from 'icons/16px/Plus.svg'
import { AudioPlayer } from 'components/AudioPlayer/AudioPlayer'
import { AudioType } from 'src/generated/graphql'
import { IAudioItem } from 'src/types/IAudioItem'
import {
  LinksRender,
  LinksRenderProps,
} from 'components/NewTable/cell/LinksRender/LinksRender'
import { RoutesEnum } from 'src/routes/routes'
import { ColumnType } from 'components/NewTable/types'
import { observer } from 'mobx-react-lite'
import { Icon } from 'components/Icon/Icon'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'

export const audioColumns: ColumnType<IAudioItem>[] = [
  {
    id: 'id',
    label: '',
    minWidth: 44,
    sticky: true,
    format: (value, row) => <AddAudioBtn row={row} />,
  },
  {
    id: 'name',
    label: 'Name',
    minWidth: 260,
    format: (value) => <div className={'edit'}>{value as string}</div>,
  },
  {
    id: 'url',
    label: 'Audio',
    minWidth: 180,
    format: (value, row) => (
      <AudioPlayer
        src={value as string}
        id={row.id + 'audioTable'}
        maxCanvasWidth={60}
      />
    ),
  },
  {
    id: 'broadcasts',
    label: 'Broadcasts',
    format: (value, { usageStatistics }) => (
      <LinksRender
        data={(usageStatistics?.broadcasts as LinksRenderProps['data']) || []}
        source={RoutesEnum.broadcasts}
        title={'Broadcasts'}
        variant2
      />
    ),
  },
  {
    id: 'campaigns',
    label: 'Campaigns',
    format: (value, { usageStatistics }) => (
      <LinksRender
        data={(usageStatistics?.campaigns as LinksRenderProps['data']) || []}
        source={RoutesEnum.campaigns}
        title={'Campaigns'}
        variant2
      />
    ),
  },
  {
    id: 'triggers',
    label: 'Triggers',
    format: (value, { usageStatistics }) => (
      <LinksRender
        data={(usageStatistics?.triggers as LinksRenderProps['data']) || []}
        source={RoutesEnum.triggers}
        title={'Triggers'}
        variant2
      />
    ),
  },
  {
    id: 'type',
    label: 'Audio Type',
    minWidth: 170,
    format: (value, { personalized, type }) => (
      <>
        {personalized && type === 'TTS'
          ? 'Dynamic text to speech'
          : AudioTYpeToString[value as AudioType]}
      </>
    ),
  },
]

const AddAudioBtn = observer(({ row }: { row: IAudioItem }) => {
  const duration =
    row.duration || audioStore.durationsMap.get(row.url as string) || 0

  if (audioStore.forVoice && row.type === 'TTS' && row.personalized) {
    return (
      <MuiTooltip
        title={[
          'Using dynamic text to speech is allowed for ringless messages only',
        ]}
        arrow
        placement={'top-start'}
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [-24, 0],
              },
            },
          ],
        }}
      >
        <div className={'flex-center pointer'}>
          <Icon
            icon={'question'}
            color={'--primary-color-gray-2'}
            hoverColor={'--primary-color-gray-1'}
          />
        </div>
      </MuiTooltip>
    )
  }
  if (audioStore.forVoice && duration < 7) {
    return (
      <MuiTooltip
        title={[
          'Audio less than 7 seconds can’t',
          <br key="br" />,
          'be used for Voice messages',
        ]}
        arrow
        placement={'top-start'}
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [-24, 0],
              },
            },
          ],
        }}
      >
        <div className={'flex-center pointer'}>
          <Icon
            icon={'question'}
            color={'--primary-color-gray-2'}
            hoverColor={'--primary-color-gray-1'}
          />
        </div>
      </MuiTooltip>
    )
  }

  return (
    <Button
      typeBtn={BtnType.secondaryBlue}
      size={BtnSize.mini}
      onClick={() => audioStore.onSelectAudio(row.id)}
    >
      <Plus />
    </Button>
  )
})

export const noSortsColumns = ['broadcasts', 'campaigns', 'triggers']

const AudioTYpeToString: { [key in AudioType]: string } = {
  [AudioType.RecordedByComputer]: 'Recorded from computer',
  [AudioType.Uploaded]: 'Uploaded',
  [AudioType.Tts]: 'Text to speech',
  [AudioType.RecordedByPhone]: 'Recorded from computer',
  [AudioType.RecordedByTwilio]: 'Recorded by twilio',
  [AudioType.Migrated]: 'Migrated',
}
