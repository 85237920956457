import { createContext, useContext } from 'react'
import { SendingSpeedStore } from 'src/widgets/SendingSpeed'

export const SendingSpeedContext = createContext<SendingSpeedStore | null>(null)

export const useSendingSpeedContext = () => {
  const context = useContext(SendingSpeedContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with SendingSpeedContext'
    )

  return context
}
