import { makeAutoObservable } from 'mobx'
import { TableStore } from 'components/NewTable/store/TableStore'
import {
  AdminCustomPlansQuery,
  Direction,
  SimplePrice,
} from 'src/generated/graphql'
import { superAdminCustomPriceColumns } from 'components/NewTable/columns/superAdmin/superAdminCustomPriceColumns'
import { ISimplePrice } from 'src/types/ISimplePrice'
import { transformSimplePriceToISimplePrice } from 'store/superAdmin/util'
import { CustomPlanStore } from 'src/pages/superAdmin/modals/CustomPlan/store/CustomPlanStore'

class CustomPlansStore {
  constructor() {
    makeAutoObservable(this)
  }
  tablesStore = new TableStore({
    tableName: 'Custom Price Table',
    columns: superAdminCustomPriceColumns,
    orderBy: 'createdAt',
    order: Direction.Desc,
  })

  pricesMap: Map<string, ISimplePrice> = new Map()

  get prices(): ISimplePrice[] {
    return Array.from(this.pricesMap.values())
  }

  onOpenModal = (price?: ISimplePrice) => {
    new CustomPlanStore(this.addPrice, this.updatePrice, price)
  }

  setPrices = (data: AdminCustomPlansQuery) => {
    this.tablesStore.setTotal(data.adminCustomPlans?.length || 0)
    if (data.adminCustomPlans) {
      const plans: ISimplePrice[] = []
      data.adminCustomPlans.forEach((plan) => {
        if (plan) {
          plans.push(transformSimplePriceToISimplePrice(plan))
        }
      })
      this.pricesMap = new Map(plans.map((plan) => [plan.id, plan]))
    }
  }

  addPrice = (price: SimplePrice) => {
    const iPrice = transformSimplePriceToISimplePrice(price)
    this.pricesMap = new Map(
      [iPrice, ...this.prices].map((plan) => [plan.id, plan])
    )
  }
  updatePrice = (price: SimplePrice) => {
    const iPrice = transformSimplePriceToISimplePrice(price)
    this.pricesMap.set(iPrice.id, iPrice)
  }
}

export default new CustomPlansStore()
