import modalStore from 'src/widgets/ModalContainer/store/modalStore'
import { ConversionCode } from 'widgets/conversions/ConversionCode/ConversionCode'

export const openGetCodeConversionModal = () => {
  const id = 'GetCodeConversion'

  modalStore.addModal({
    id,
    title: 'Conversion code',
    minWidth: 650,
    children: <ConversionCode />,
  })
}
