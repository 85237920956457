// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FmuaySEJWaokaZkwJWRp{display:grid;grid-template-columns:1fr 1fr 1fr;gap:12px}`, "",{"version":3,"sources":["webpack://./src/pages/auth/signUp/registerFlowB/RegisterCompanyInfoB/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,iCAAA,CACA,QAAA","sourcesContent":[".wrapInputs{\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  gap: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapInputs": `FmuaySEJWaokaZkwJWRp`
};
export default ___CSS_LOADER_EXPORT___;
