import { ModalIcon } from 'components/ModalIcon/ModalIcon'
import { FC, ReactNode, useState } from 'react'
import { Typography } from 'shared/Typography'
import { Button } from 'shared/Button/Button'
import styles from './styles.module.scss'

export type IDeleteModal = {
  title: ReactNode
  subTitle?: ReactNode
  onClose?: () => void
  onDelete: () => Promise<void>
}

export const DeleteModal: FC<IDeleteModal> = ({
  onClose,
  title,
  subTitle,
  onDelete,
}) => {
  const [loading, setLoading] = useState(false)

  const handleClick = async () => {
    setLoading(true)
    await onDelete()
    setLoading(false)
    onClose?.()
  }
  return (
    <div className={'pModalInfo'}>
      <ModalIcon type={'infoError1'} icon={'info1'} fontSize={20} />
      <Typography variant={'h3-regular'}>{title}</Typography>
      {subTitle && <p className={'par2 gray1'}>{subTitle}</p>}
      <div className={styles.actions}>
        <Button
          variant={'primary-medium'}
          onClick={handleClick}
          text={'Delete'}
          fullWidth
          loading={loading}
        />
        <Button
          variant={'secondary-medium'}
          onClick={onClose}
          text={'Close'}
          fullWidth
        />
      </div>
    </div>
  )
}
