import { Card } from 'components/Card/Card'
import { InputText } from 'components/Input/InputText/InputText'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { BackMigrationBtn } from 'src/pages/auth/migration/BackMigrationBtn'
import { Ul } from 'components/Ul/Ul'
import { Li } from 'components/Ul/Li'
import { ColorsNames, ColorsValues } from 'styles/variables'
import styles from './styles.module.scss'
import { useMigrationRegistrationStepMutation } from 'src/generated/graphql'
import user from 'store/user/user'
import migrationStore from 'store/migration/migrationStore'

export const MigrationStepContent = () => {
  const [migrationRegistrationStep, { loading }] =
    useMigrationRegistrationStepMutation()
  const onContinue = async () => {
    try {
      const { data } = await migrationRegistrationStep()
      if (data?.migrationRegistrationStep) {
        user.setMember(data.migrationRegistrationStep)
      }
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <Card width={480} margin={0}>
      <h3 className={'bold mb8'}>Let’s migrate your data</h3>
      <p className={'par2 gray1 mb32'}>
        Transfer your data from Call Loop 1.0 -{'>'} Call Loop 2.0
      </p>
      <InputText
        label={'Your current account'}
        value={migrationStore.memberRegistration?.migratedEmail || ''}
        disabled
      />
      <div className={'mt32'}>
        <div className={styles.wrapUl}>
          <span className={'s1 bold mb16'} color={ColorsValues.blue1}>
            We will migrate your:
          </span>
          <Ul>
            <Li color={ColorsNames.aqua1}>Contacts</Li>
            <Li color={ColorsNames.aqua1}>Triggers</Li>
            <Li color={ColorsNames.aqua1}>Keywords</Li>
            <Li color={ColorsNames.aqua1}>Web Forms</Li>
            <Li color={ColorsNames.aqua1}>Numbers</Li>
          </Ul>
        </div>

        <div className={'mt40 row12'}>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.primary}
            onClick={onContinue}
            loading={loading}
          >
            Continue
          </Button>
          <BackMigrationBtn />
        </div>
      </div>
    </Card>
  )
}
