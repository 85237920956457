import dayjs, { DayjsFormats } from 'lib/dayjs'
import { numberString } from 'src/util/functions'
import * as React from 'react'
import { LinksRender } from 'components/NewTable/cell/LinksRender/LinksRender'
import { IListResponse } from 'src/types/IContactList'
import { RoutesEnum } from 'src/pages/routes'
import { ColumnType } from 'components/NewTable/types'

export const contactListColumns: ColumnType<IListResponse>[] = [
  {
    id: 'name',
    label: 'List name',
    format: (value) => <div className={'edit'}>{value as string}</div>,
  },
  {
    id: 'listKeywordsNames',
    label: 'Keywords',
    format: (value, { keywords }) => (
      <LinksRender
        data={
          keywords?.map((list) => ({
            id: list?.id || '',
            name: list?.name || '',
          })) || []
        }
        source={RoutesEnum.keywords}
        title={'Keywords'}
      />
    ),
  },
  {
    id: 'listWebFormsNames',
    label: 'Web forms',
    format: (value, { webForms }) => (
      <LinksRender
        data={
          webForms?.map((list) => ({
            id: list?.id || '',
            name: list?.name || '',
          })) || []
        }
        source={RoutesEnum.webForms}
        title={'Web forms'}
      />
    ),
  },
  {
    id: 'createdAt',
    label: 'Created',
    format: (value) =>
      value ? dayjs(value as Date).format(DayjsFormats.fullWithAt) : undefined,
  },
  {
    id: 'totalContactsCount',
    label: 'Total',
    format: (value) => <>{numberString({ val: value as number })}</>,
  },
  {
    id: 'activeContactsCount',
    label: 'Active',
    format: (value) => <>{numberString({ val: value as number })}</>,
  },
  {
    id: 'invalidContactsCount',
    label: 'Invalid',
    format: (value) => <>{numberString({ val: value as number })}</>,
  },
  {
    id: 'unsubscribedContactsCount',
    label: 'Unsubscribed',
    format: (value) => <>{numberString({ val: value as number })}</>,
  },
]

export const noSortsColumns = [
  'listKeywordsNames',
  'listWebFormsNames',
  'totalContactsCount',
  'activeContactsCount',
  'invalidContactsCount',
  'unsubscribedContactsCount',
]
