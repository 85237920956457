import { useEffect } from 'react'
import { ICardDetail } from 'components/DetailCard/DetailCard/DetailCard'
import { observer } from 'mobx-react-lite'
import { useKeywordDetailContext } from 'src/pages/main/keywords/detail/keywordDetailPage'
import { useGetKeywordContactStatisticsQuery } from 'src/generated/graphql'
import { TableStatuses } from 'components/NewTable/store/TableStore'
import { DetailCardsWrapper } from 'components/DetailCard/DetailCardsWrapper'
import { detailsCards } from 'components/DetailCard/variants'

export const AdditionalContent = observer(() => {
  const context = useKeywordDetailContext()

  const { keyword, tableStore } = context
  if (!keyword) {
    return <></>
  }

  const { data, refetch } = useGetKeywordContactStatisticsQuery({
    variables: {
      keywordId: +keyword.id,
      dateRange: tableStore.rangeDatePickerStore.dateRange,
    },
    skip: tableStore.rangeDatePickerStore.isSkipByDateRange,
  })

  const shortUrlClickStatistics =
    data?.getKeywordContactStatistics?.shortUrlClickStatistics
  const totalStatistics = data?.getKeywordContactStatistics?.totalStatistics
  const percentageStatistics =
    data?.getKeywordContactStatistics?.percentageStatistics
  const todayStatistics = data?.getKeywordContactStatistics?.todayStatistics

  const subscribeCount = totalStatistics?.subscribersCount || 0
  const activeCount = totalStatistics?.activeCount || 0
  const unsubscribedCount = totalStatistics?.unsubscribedCount || 0

  const activePercentage = percentageStatistics?.activePercentage || 0
  const unsubscribedPercentage =
    percentageStatistics?.unsubscribedPercentage || 0

  const todaySubscribeCount = todayStatistics?.subscribersCount || 0
  const todayActiveCount = todayStatistics?.activeCount || 0
  const todayUnsubscribedCount = todayStatistics?.unsubscribedCount || 0

  const cards: ICardDetail[] = [
    {
      title: 'Subscribers',
      value: TableStatuses.totalCount,
      status: TableStatuses.totalCount,
      count: subscribeCount,
      hidePercent: true,
      percent: 0,
      today: todaySubscribeCount,
    },
    {
      title: 'Active',
      value: TableStatuses.activeCount,
      status: TableStatuses.activeCount,
      count: activeCount,
      percent: activePercentage,
      today: todayActiveCount,
    },
    shortUrlClickStatistics?.shortUrlVisits?.length &&
      detailsCards.shortUrlClickStatistics({
        shortUrlClickStatistics,
        card: {
          today: shortUrlClickStatistics?.totalVisitsToday,
        },
      }),
    detailsCards.unsubscribed({
      count: unsubscribedCount,
      percent: unsubscribedPercentage,
      today: todayUnsubscribedCount,
    }),
  ].filter((card) => Boolean(card)) as ICardDetail[]

  useEffect(() => {
    context.setMessageStatistics(data?.getKeywordContactStatistics || null)
  }, [data])

  useEffect(() => {
    refetch().catch(console.error)
  }, [tableStore.refreshTrigger])
  return <DetailCardsWrapper cards={cards} tableStore={tableStore} />
})
