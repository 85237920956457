import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx'
import { mockPhoneFields } from 'store/triggers/mockData'
import { ITrigger } from 'src/types/ITrigger'
import { CreateStore } from 'components/CreateElement/store/createStore'
import {
  AccountIntegrationType,
  ContentType,
  Status,
  TriggerInput,
  VerificationStatus,
} from 'src/generated/graphql'
import { IOption } from 'src/types/IOption'
import integrationsStore from 'store/settings/integrationsStore'
import triggersStore from 'store/triggers/triggersStore'
import { DropdownContactListsStore } from 'store/contacts/DropdownContactListsStore'
import billingStore from 'store/settings/billing/billingStore'
import companyStore from 'store/settings/company/companyStore'

export class CreateTriggerStore extends CreateStore {
  dropdownContactListsStore: DropdownContactListsStore
  constructor() {
    super({
      createBtnText: 'Create trigger',
      compliance: false,
    })
    this.dropdownContactListsStore = new DropdownContactListsStore()
    makeObservable(this, {
      initInput: observable,
      dropdownContactListsStore: observable,
      edit: observable,
      duplicate: observable,
      editTriggerId: observable,
      activeTrigger: observable,
      selectedApp: observable,
      appPhoneField: observable,
      isConnectedIntegration: computed,
      setEdit: action.bound,
      setActiveTrigger: action.bound,
      setSelectedApp: action.bound,
      setSelectedPhoneField: action.bound,
    })

    reaction(
      () => this.createTriggerInput,
      (input) => {
        if (!this.initInput) {
          this.initInput = input
        }
        const initInputString = JSON.stringify(this.initInput)
        const inputString = JSON.stringify(input)
        this.isHaveChanges = initInputString !== inputString
      }
    )
  }

  element = 'Trigger'
  edit = false
  duplicate = false
  editTriggerId = ''
  appPhoneField: string | undefined | null = ''
  editTrigger: ITrigger | null = null
  status = billingStore.autoRecharge ? Status.Active : Status.Disabled
  activeTrigger = billingStore.autoRecharge
  selectedApp: IOption | null = null
  initInput: TriggerInput | null = null

  get isConnectedIntegration() {
    if (this.selectedApp?.value === AccountIntegrationType.Custom) {
      return true
    }
    if (this.selectedApp?.value !== AccountIntegrationType.Custom) {
      return !!integrationsStore.connectedIntegrationsMap.get(
        this.selectedApp?.value as AccountIntegrationType
      )
    }

    return false
  }

  get showConnectBtn() {
    if (!this.selectedApp?.value) {
      return false
    }
    if (this.selectedApp?.value === AccountIntegrationType.Custom) {
      return false
    }
    return !this.isConnectedIntegration
  }

  get showPhoneDropdown() {
    if (!this.selectedApp?.value) {
      return false
    }
    if (this.selectedApp?.value === AccountIntegrationType.Custom) {
      return false
    }
    return this.isConnectedIntegration
  }

  get authUrl() {
    if (!this.isConnectedIntegration) {
      return (
        integrationsStore.oAuth2IntegrationsMap.get(this.selectedApp?.value)
          ?.authUrl || ''
      )
    }
  }

  get phoneFieldOptions() {
    if (this.selectedApp) {
      const options = triggersStore.integrationPhoneFieldsMap
        .get(this.selectedApp.value as AccountIntegrationType)
        ?.map((field) => ({
          title: field.name,
          value: field.key,
        }))
      return options?.length ? options : mockPhoneFields
    }
    return mockPhoneFields
  }

  get disabledCreate() {
    if (!this.isConnectedIntegration) {
      return true
    }
    if (!this.name) {
      return true
    }
    if (!this.selectedApp?.value) {
      return true
    }
    if (
      this.selectedApp?.value !== AccountIntegrationType.Custom &&
      !this.appPhoneField
    ) {
      return true
    }
    if (this.type === ContentType.Sms) {
      if (!this.textareaStore.smsMessageInput.text) {
        return true
      }
      if (this.textareaStore.isError) {
        return true
      }
      if (!this.smsPhone || this.smsPhone.includes('_')) {
        return true
      }
    }
    if (this.type === ContentType.Voice) {
      if (!this.voicePhone || this.voicePhone.includes('_')) {
        return true
      }
      if (this.voiceStore.disabledCreate) {
        return true
      }
    }
    if (this.type === ContentType.Ringless) {
      if (!this.voicePhone || this.voicePhone.includes('_')) {
        return true
      }
      if (this.ringlessStore.disabledCreate) {
        return true
      }
    }
    return false
  }

  get createTriggerInput(): TriggerInput {
    return {
      app: this.selectedApp?.value,
      appPhoneField: this.appPhoneField,
      name: this.name,
      sendFromCompany: this.company,
      smsMessage:
        this.activeTab === 0
          ? this.textareaStore.smsMessageRequestInput
          : undefined,
      status: this.status,
      sendFromPhoneNumber: this.phonePull,
      voiceMessage:
        this.activeTab === 1 ? this.voiceStore.voiceMessageInput : undefined,
      ringlessMessage:
        this.activeTab === 2
          ? this.ringlessStore.ringlessMessageInput
          : undefined,
      listIds: this.dropdownContactListsStore.selectedContactListsIds,
    }
  }
  get updateTriggerInput(): TriggerInput {
    return {
      ...this.createTriggerInput,
      id: this.editTriggerId,
    }
  }

  setEdit(trigger: ITrigger, isDuplicate: boolean) {
    this.type = trigger.type || ContentType.Sms
    this.editTrigger = trigger
    if (isDuplicate) {
      this.duplicate = true
      this.status = billingStore.autoRecharge ? Status.Active : Status.Disabled
      this.activeTrigger = billingStore.autoRecharge
    } else {
      this.edit = true
      this.status = trigger.status
      this.activeTrigger = trigger.status === Status.Active
    }

    this.editTriggerId = trigger.id
    this.textareaStore.setSmsMessage(trigger.smsMessage)
    this.textareaStore.setTriggerMessageUpdate()
    this.setSelectedApp(trigger.appOption)
    this.setSelectedPhoneField(trigger.appPhoneField)
    this.name = `${trigger.name}${isDuplicate ? ' copy' : ''}`

    if (trigger.type === ContentType.Sms) {
      if (
        !trigger.sendFromPhoneNumber?.verificationStatus ||
        trigger.sendFromPhoneNumber?.verificationStatus ===
          VerificationStatus.Unverified
      ) {
        this.smsPhone = ''
      } else {
        this.smsPhone = trigger.sendFromPhoneNumber?.friendlyName || ''
      }
    } else {
      if (trigger.type === ContentType.Voice) {
        this.voiceStore.setInit(trigger.voiceMessage)
      }
      if (trigger.type === ContentType.Ringless && trigger.ringlessMessage) {
        this.ringlessStore.setInit(trigger.ringlessMessage)
      }
      this.voicePhone = trigger.sendFromPhoneNumber?.friendlyName || ''
    }
    this.company = trigger.sendFromCompany || ''

    if (trigger.listIds) {
      this.dropdownContactListsStore.setInitListsIds(trigger.listIds)
    }

    this.initInput = this.createTriggerInput
  }

  setActiveTrigger(state: boolean) {
    const onTurnOn = () => {
      this.status = state ? Status.Active : Status.Disabled
      this.activeTrigger = state
    }
    if (state && !billingStore.autoRecharge) {
      companyStore.setTurnOnAutoRechargeModal('trigger', onTurnOn)
      return
    }
    onTurnOn()
  }
  setSelectedApp(option: IOption | undefined) {
    if (this.appPhoneField && option?.value !== this.selectedApp?.value) {
      runInAction(() => {
        this.appPhoneField = undefined
      })
    }
    if (option) {
      this.selectedApp = option
    }
  }
  setSelectedPhoneField(appPhoneField?: string | null) {
    this.appPhoneField = appPhoneField
  }
  setStatus(status: Status) {
    if (status !== Status.Active && this.activeTrigger) {
      this.activeTrigger = false
    }
    this.status = status
  }
}
