import configStore from 'store/configStore'
import { PostHog } from 'posthog-js'

export function initPostHogFeaturesFlags(postHog: PostHog) {
  configStore.setIsSegmentsByPostHog(!!postHog.isFeatureEnabled('Segments'))
  configStore.setIsContactAllByPostHog(!!postHog.isFeatureEnabled('Bulks'))
  configStore.setIsMultiOrg(!!postHog.isFeatureEnabled('MultiOrg'))
  configStore.setIsGoHighLevel(!!postHog.isFeatureEnabled('GoHighLevel'))
  configStore.setIsCallerIdHelp(!!postHog.isFeatureEnabled('callerIdHelp'))
  configStore.setIsDynamicTts(!!postHog.isFeatureEnabled('dynamic_tts'))
}
