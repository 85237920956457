import contactStore from 'store/contacts/contactStore'
import { useGetMyOrganizationContactsQuery } from 'src/generated/graphql'
import { ContactsTable } from 'src/pages/main/contacts/ContactsTable/ContactsTable'
import { observer } from 'mobx-react-lite'
import { useSetData } from 'src/hooks/useSetData'

export const ContactsTablePage = observer(() => {
  const queryResult = useGetMyOrganizationContactsQuery({
    variables: {
      page: contactStore.tableStoreContacts.paginationInput,
      contactSearch: {
        status: contactStore.tableStoreContacts.statusBackEnd,
        searchPattern: contactStore.tableStoreContacts.search,
      },
    },
  })

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData: (data) => contactStore.setDataContacts(data),
    refreshDeps: [contactStore.tableStoreContacts.refreshTrigger],
  })

  return (
    <ContactsTable
      pageLoading={loading || refetchLoading}
      firstLoading={firstLoading}
      tableStore={contactStore.tableStoreContacts}
      withSearch
    />
  )
})
