import { Alert } from 'components/Alert/Alert'
import { Link } from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'
import addNumberStore from 'store/settings/numbers/addNumberStore'
import { appLinks } from 'src/util/links'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'

export const LocalNumberAlert = () => (
  <Alert
    title={'You are required by the carriers to register for A2P 10DLC'}
    text={
      <>
        To avoid messages from being blocked and paying additional carrier
        penalty fees, after adding the number, please complete the{' '}
        <Link
          to={RoutesEnum.settingsCompliance}
          onClick={() => addNumberStore.setOpenAddNumberModal(false)}
        >
          10DLC registration.
        </Link>{' '}
        A2P 10DLC is required if you want to send messages to United States
        using local numbers.{' '}
        <a
          href={appLinks.callLoopCompliance}
          className={'s1'}
          target={'_blank'}
          rel="noreferrer"
        >
          Learn more
        </a>
      </>
    }
    type={AlertTypeEnum.notify2}
  />
)
