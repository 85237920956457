import { observer } from 'mobx-react-lite'
import { SizedBox } from 'components/SizedBox'
import styles from './styles.module.scss'
import { StatusContent } from 'components/StatusContent/StatusContent'
import {
  OrganizationStatus,
  useAdminUpdateOrganizationContractMutation,
} from 'src/generated/graphql'
import * as React from 'react'
import { useMemo } from 'react'
import { useOrganizationPageContext } from 'src/pages/superAdmin/routes/organization/OrganizationPage'
import { numberString } from 'src/util/functions'
import { Tag } from 'components/Tag/Tag'
import { Row } from 'src/pages/superAdmin/routes/organization/info/Row/Row'
import { CheckBox } from 'components/CheckBox/CheckBox'

export const OrganizationGeneralInfo = observer(() => {
  const context = useOrganizationPageContext()
  const { organization, membersMap } = context
  if (!organization) {
    return <></>
  }

  const planCredits = organization.balances?.plan || 0
  const purchasedCredits = organization.balances?.purchased || 0
  const rolloverCredits = organization.balances?.rollover || 0

  const [adminUpdateOrganizationContract, { loading: loadingContract }] =
    useAdminUpdateOrganizationContractMutation()

  const onUpdateOrganizationContract = async () => {
    try {
      if (!organization?.id) {
        return
      }
      const contract = !organization.contract
      const { data } = await adminUpdateOrganizationContract({
        variables: {
          organizationId: +organization?.id,
          contract,
        },
      })
      if (data?.adminUpdateOrganizationContract) {
        context.updateOrganization({
          contract,
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  // const [updateSharedNumberAccess, { loading: loadingShared }] =
  //   useUpdateSharedNumberAccessMutation()

  // const onUpdateSharedNumberAccess = async () => {
  //   try {
  //     const { data } = await updateSharedNumberAccess({
  //       variables: {
  //         id: organization?.id,
  //         allowed: !organization.canUseGlobalNumber,
  //       },
  //     })
  //     if (data?.updateSharedNumberAccess) {
  //       context.setOrganization(data.updateSharedNumberAccess)
  //     }
  //   } catch (e) {
  //     console.error(e)
  //   }
  // }

  const integrations = useMemo(
    () => (
      <div className={'row8'}>
        {organization.integrations?.activeCampaign?.connected && (
          <Tag text={'Active Campaign'} />
        )}
        {organization.integrations?.OAuth2Integrations?.map(
          (integration) =>
            integration?.connected && (
              <Tag key={integration.type} text={integration.type || ''} />
            )
        )}
      </div>
    ),
    []
  )

  const uniqueOwnerId = membersMap.get(organization?.ownerId)?.uniqueId

  return (
    <div className={styles.wrapBlock}>
      <p className={'bold'}>General Information</p>
      <SizedBox height={24} />
      <div className={styles.rows}>
        <Row title={'Company Name'} value={organization.companyName} />
        <Row
          title={'Migrated'}
          value={
            organization.migrated ? (
              <span className={'success1'}>true</span>
            ) : (
              <span className={'error1'}>false</span>
            )
          }
        />
        <Row title={'Country'} value={organization.ipCountryName} />
        <Row
          title={'Organization Status'}
          value={
            <StatusContent
              status={organization.organizationStatus as OrganizationStatus}
              noIcon
            />
          }
        />
        <Row
          title={'Contract'}
          value={
            <CheckBox
              checked={organization.contract}
              onChecked={onUpdateOrganizationContract}
              disabled={loadingContract}
            />
          }
        />
        <Row
          title={'Total Contacts'}
          value={numberString({ val: organization.totalContacts || 0 })}
        />
        <Row
          title={'Total Credits'}
          value={
            <>
              {`${numberString({
                val: planCredits + purchasedCredits + rolloverCredits,
              })} (plan: ${numberString({
                val: planCredits,
              })}, roll-over: ${numberString({
                val: rolloverCredits,
              })}, purchased: ${numberString({
                val: purchasedCredits,
              })})`}
            </>
          }
        />
        {/*<Row title={'Team Size'} value={organization.teamSize} />*/}

        <Row title={'Integrations'} value={integrations} />
        {/*<Row*/}
        {/*  title={'Can use Short Code'}*/}
        {/*  value={*/}
        {/*    <CheckBox*/}
        {/*      checked={!!organization.canUseGlobalNumber}*/}
        {/*      onChecked={onUpdateSharedNumberAccess}*/}
        {/*      disabled={loadingShared}*/}
        {/*    />*/}
        {/*  }*/}
        {/*/>*/}

        <Row
          title={'HubSpot'}
          value={
            <div className={'row8'}>
              <a
                href={`https://app.hubspot.com/contacts/${organization.hubSpotAppId}/company/${organization.hubSpotCompanyId}`}
                className={'s1 regular'}
                target={'_blank'}
                rel="noreferrer"
              >
                View Company
              </a>
              <div className={'point'} />
              <a
                href={`https://app.hubspot.com/contacts/${organization.hubSpotAppId}/contact/${organization.hubSpotOwnerId}`}
                className={'s1 regular'}
                target={'_blank'}
                rel="noreferrer"
              >
                View Contact
              </a>
            </div>
          }
        />
        <Row
          title={'Intercom'}
          value={
            <div className={'row8'}>
              {organization.intercomCompanyLink && (
                <>
                  <a
                    href={organization.intercomCompanyLink}
                    className={'s1 regular'}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    View Company
                  </a>
                  <div className={'point'} />
                </>
              )}
              {organization.intercomContactLink && (
                <a
                  href={organization.intercomContactLink}
                  className={'s1 regular'}
                  target={'_blank'}
                  rel="noreferrer"
                >
                  View Contact
                </a>
              )}
            </div>
          }
        />
        <Row
          title={'Twilio SID'}
          value={
            <a
              href={`https://www.twilio.com/console/usage/subaccounts/${organization.twilioSubAccountSid}`}
              className={'s1 regular'}
              target={'_blank'}
              rel="noreferrer"
            >
              {organization.twilioSubAccountSid}
            </a>
          }
        />
        <Row
          title={'Stripe'}
          value={
            <a
              href={`https://dashboard.stripe.com/customers/${organization.billingInfo?.stripeCustomerId}`}
              className={'s1 regular'}
              target={'_blank'}
              rel="noreferrer"
            >
              {organization.billingInfo?.stripeCustomerId}
            </a>
          }
        />
        <Row
          title={'Posthog'}
          value={
            uniqueOwnerId && (
              <a
                href={`https://app.posthog.com/person/${uniqueOwnerId}#activeTab=sessionRecordings`}
                className={'s1 regular'}
                target={'_blank'}
                rel="noreferrer"
              >
                View Recording
              </a>
            )
          }
        />
        <Row title={'IP Address'} value={organization.ipAddress} />
      </div>
    </div>
  )
})
