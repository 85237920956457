import { observer } from 'mobx-react-lite'
import { Modal } from 'components/Modal/Modal'
import businessProfileStore from 'store/settings/company/businessProfileStore'
import { Separator } from 'components/Separator/Separator'
import { SizedBox } from 'components/SizedBox'
import { PeopleToContact } from 'src/pages/settings/compliance/modals/BusinessProfileModal/PeopleToContact'
import { BusinessInfo } from 'src/pages/settings/compliance/modals/BusinessProfileModal/BusinessInfo'
import { GeneralInfo } from 'src/pages/settings/compliance/modals/BusinessProfileModal/GeneralInfo'
import * as React from 'react'
import { BusinessErrorAlert } from 'src/pages/settings/compliance/modals/components/BusinessErrorAlert'
import { SuperAdminBPActions } from 'src/pages/superAdmin/routes/complianceRS/components/SuperAdminBPActions'

export const BusinessProfileModal = observer(() => {
  const {
    setOpenModal,
    openModal,
    isSuperAdminModal,
    modalFullBusinessProfile,
    adminOrgId,
  } = businessProfileStore
  const onClose = () => {
    setOpenModal(false)
  }
  return (
    <Modal
      open={openModal}
      onClose={onClose}
      title={'Business profile'}
      topRight
    >
      <div className={'mediumModal'}>
        <Separator />
        <SizedBox height={32} />
        {isSuperAdminModal && modalFullBusinessProfile && adminOrgId && (
          <SuperAdminBPActions
            profile={modalFullBusinessProfile}
            organizationId={adminOrgId}
          />
        )}
        <h3>{businessProfileStore.businessNameString}</h3>
        {/*{businessProfileStore.businessProfile?.hasEin &&*/}
        {/*  businessProfileStore.businessProfile?.status && (*/}
        {/*    <div className={'mt12'}>*/}
        {/*      <StatusContent*/}
        {/*        status={businessProfileStore.businessProfile?.status}*/}
        {/*        text={businessProfileStore.businessMapping.get(*/}
        {/*          businessProfileStore.businessProfile?.status*/}
        {/*        )}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  )}*/}
        {businessProfileStore.isErrorAlert && <BusinessErrorAlert fromModal />}

        <SizedBox height={40} />
        <div className={'col36'}>
          <GeneralInfo />
          <BusinessInfo />
          <PeopleToContact />
        </div>
      </div>
    </Modal>
  )
})
