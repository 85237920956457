import { PropsWithChildren } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'

export const Page = ({
  children,
  gray,
  center,
}: PropsWithChildren<{ gray?: boolean; center?: boolean }>) => (
  <div
    className={classNames(styles.wrap, {
      [styles.gray]: gray,
      [styles.center]: center,
    })}
  >
    {children}
  </div>
)
