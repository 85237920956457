import { longShortDayMap } from 'components/Schedule/store/types'
import { DayOfWeek } from 'src/generated/graphql'

const shortenLabelForDays: { [key: string]: string } = {
  [`${DayOfWeek.Sunday},${DayOfWeek.Monday},${DayOfWeek.Tuesday},${DayOfWeek.Wednesday},${DayOfWeek.Thursday},${DayOfWeek.Friday},${DayOfWeek.Saturday}`]:
    'on all days',
  [`${DayOfWeek.Monday},${DayOfWeek.Tuesday},${DayOfWeek.Wednesday},${DayOfWeek.Thursday},${DayOfWeek.Friday}`]:
    'only on weekdays',
  [`${DayOfWeek.Sunday},${DayOfWeek.Saturday}`]: 'only on weekends',
}

export const getSendTextByDays = (days: Array<DayOfWeek | null>) => {
  const dayOfWeek = days.filter(Boolean) as DayOfWeek[]
  const shorten = shortenLabelForDays[dayOfWeek.toString()]
  if (shorten) return shorten
  return `only on ${dayOfWeek
    .map((day) => longShortDayMap.get(day))
    .join(', ')}`
}
