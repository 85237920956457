import { createContext, useContext } from 'react'
import { CreateTextToSpeechModalStore } from 'src/Modals/CreateTextToSpeechModal/store/createTextToSpeechModalStore'

export const CreateTextToSpeechModalContext =
  createContext<CreateTextToSpeechModalStore | null>(null)

export const useCreateTextToSpeechModalContext = () => {
  const context = useContext(CreateTextToSpeechModalContext)
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with CreateTextToSpeechModalContext'
    )
  }
  return context
}
