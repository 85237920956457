import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { NumericFormat, PatternFormat } from 'react-number-format'
import styles from 'components/Input/InputText/styles.module.scss'
import { BtnSize, Button } from 'components/Button/Button'
import { ReactComponent as Close } from 'icons/16px/CloseCustom.svg'
import classNames from 'classnames'
import { ReactComponent as EyeHide } from 'assets/icons/16px/Eye Hide.svg'
import { ReactComponent as Eye } from 'assets/icons/16px/Eye.svg'
import { ValidItem } from 'components/Input/InputText/ValidItem'
import { CardIcon } from 'components/Input/InputText/CardIcon'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'
import { SizedBox } from 'components/SizedBox'
import { AppIcon } from 'components/AppIcon/AppIcon'

export type InputTextProps<T> = JSX.IntrinsicElements['input'] & {
  label?: string | JSX.Element
  tooltip?: string | JSX.Element
  leftIconContent?: ReactNode
  rightLabelContent?: JSX.Element
  rightContent?: JSX.Element
  showRightContent?: boolean
  onClickRightIcon?: () => void
  setRefInput?: (element: HTMLInputElement | null) => void
  password?: boolean
  valid?: boolean
  showValidItems?: boolean
  card?: boolean
  patternFormat?: boolean
  numericFormat?: boolean
  withoutClear?: boolean
  format?: string
  withoutBorder?: boolean
  header?: boolean
  header2?: boolean
  header3?: boolean
  noShowErrorMessage?: boolean
  paddingLeft?: number
  maskFormat?: string | string[]
  error?: string
  withError?: boolean
  wrapClassName?: string
  removeReadOnly?: boolean
  setValidPassword?: Dispatch<SetStateAction<boolean>>
  blurOnEnter?: boolean
  customDisabled?: boolean
  hideRightContent?: boolean
  tooltipWidth?: number
  emptyWidth?: number
  decimalScale?: number
  fixedDecimalScale?: boolean
  inputClassName?: string
  errorRightContent?: ReactNode
  focusOnMount?: boolean
  onChangeValue?: (
    value: T,
    event?: React.ChangeEvent<HTMLInputElement>
  ) => void
}

export const InputText = ({
  id,
  onChangeValue,
  label,
  type = 'text',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  value,
  password,
  onClickRightIcon,
  valid,
  showValidItems,
  patternFormat,
  numericFormat,
  format,
  maskFormat,
  card,
  setValidPassword,
  withoutBorder,
  leftIconContent,
  withoutClear,
  header,
  header2,
  header3,
  setRefInput,
  required,
  paddingLeft,
  rightLabelContent,
  tooltip,
  rightContent,
  error,
  withError,
  onBlur,
  noShowErrorMessage,
  wrapClassName,
  showRightContent,
  removeReadOnly,
  blurOnEnter,
  customDisabled,
  hideRightContent,
  tooltipWidth,
  emptyWidth,
  inputClassName,
  focusOnMount,
  errorRightContent,
  decimalScale,
  fixedDecimalScale,
  ...props
}: InputTextProps<typeof value>) => {
  const [ref, setRef] = useState<HTMLInputElement | null>(null)
  const refHover = useRef<boolean>(false)
  const [isFocus, setFocus] = useState(false)

  const handleClickRightIcon = () => {
    onChangeValue && onChangeValue('')
    if (!onClickRightIcon) return
    onClickRightIcon()
  }

  useEffect(() => {
    ref?.setSelectionRange(String(value).length, String(value).length)
  }, [type])

  useEffect(() => {
    if (ref && removeReadOnly) {
      setTimeout(() => {
        ref.removeAttribute('readonly')
      }, 500)
    }
    if (focusOnMount && ref) {
      ref.focus()
    }
  }, [ref])
  const isValidLength = String(value).length > 7
  const isValidCharters = /(?=.*?[0-9])|(?=.*?[#?!@$%^&*-])/.test(String(value))

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    onBlur && onBlur(e)
    if (refHover.current && ref) {
      ref.focus()
    }
    !refHover.current && setFocus(false)
  }

  useEffect(() => {
    if (valid) {
      setValidPassword && setValidPassword(isValidCharters && isValidLength)
    }
  }, [isValidCharters, isValidLength, valid, setValidPassword])
  return (
    <div
      className={classNames('call-loop-input', styles.wrap, wrapClassName)}
      onMouseEnter={() => (refHover.current = true)}
      onMouseLeave={() => (refHover.current = false)}
    >
      {label && (
        <div className={styles.labelWrap}>
          <div className={styles.label}>
            {label} {required && <div className={styles.required}>&nbsp;*</div>}
            {tooltip && <InfoTooltip title={tooltip} width={tooltipWidth} />}
          </div>
          {rightLabelContent}
        </div>
      )}

      <div
        className={classNames(
          styles.inputWrap,
          card && styles.card,
          leftIconContent && styles.leftIcon,
          error && styles.error,
          withError && styles.error,
          withoutClear && styles.withoutClear,
          inputClassName
        )}
      >
        <div
          className={classNames(styles.closeWrap)}
          style={{
            display:
              (((isFocus && value) || password || showRightContent) &&
                !hideRightContent &&
                'block') ||
              'none',
          }}
        >
          {!password && !withoutClear && (
            <Button
              size={BtnSize.mini}
              round
              close
              onClick={handleClickRightIcon}
              tabIndex={-1}
            >
              <Close />
            </Button>
          )}
          {password && (
            <Button
              onClick={handleClickRightIcon}
              className={classNames(
                styles.eye,
                type === 'text' && styles.active
              )}
            >
              {type === 'text' ? <EyeHide /> : <Eye />}
            </Button>
          )}
          {rightContent}
        </div>
        {(leftIconContent || card) && (
          <div className={styles.leftIconWrap}>
            {card ? (
              <CardIcon value={value} />
            ) : (
              <AppIcon>{leftIconContent}</AppIcon>
            )}
          </div>
        )}
        <div className={styles.row}>
          {patternFormat && (
            <PatternFormat
              format={format || ''}
              placeholder={props.placeholder}
              mask={maskFormat}
              value={String(value)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                props.onChange && props.onChange(event)
                onChangeValue && onChangeValue(event.target.value, event)
              }}
              style={{ paddingLeft }}
              onFocus={props.onFocus}
              onBlur={handleBlur}
              tabIndex={props.tabIndex}
              type={type === 'password' ? 'password' : 'text'}
              disabled={props.disabled}
              getInputRef={(refLocal: HTMLInputElement) => {
                setRef(refLocal)
                if (setRefInput) setRefInput(refLocal)
              }}
            />
          )}
          {numericFormat && (
            <NumericFormat
              placeholder={props.placeholder}
              value={String(value)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                props.onChange && props.onChange(event)
                onChangeValue && onChangeValue(event.target.value, event)
              }}
              style={{ paddingLeft }}
              onFocus={(e) => {
                props.onFocus && props.onFocus(e)
                setFocus(true)
              }}
              onBlur={handleBlur}
              tabIndex={props.tabIndex}
              type={type === 'password' ? 'password' : 'text'}
              disabled={props.disabled}
              decimalScale={decimalScale}
              fixedDecimalScale={fixedDecimalScale}
            />
          )}
          {!patternFormat && !numericFormat && (
            <input
              {...props}
              onChange={(event) => {
                props.onChange && props.onChange(event)
                onChangeValue && onChangeValue(event.target.value, event)
              }}
              style={{
                paddingLeft,
                width: emptyWidth && !value ? emptyWidth : props.width,
              }}
              readOnly={props.readOnly}
              className={classNames(
                withoutBorder && styles.withoutBorder,
                header && styles.header,
                tooltip && styles.tooltip,
                header2 && styles.header2,
                header3 && styles.header3,
                customDisabled && styles.disabled,
                props.className
              )}
              id={id}
              ref={(refLocal) => {
                setRef(refLocal)
                setRefInput && setRefInput(refLocal)
              }}
              type={type}
              value={value}
              onKeyDown={(e) => {
                if (e.key === 'Tab') {
                  refHover.current = false
                }
                if (props.onKeyDown) {
                  props.onKeyDown(e)
                }
                if (e.key === 'Enter' && blurOnEnter) {
                  refHover.current = false
                  ref?.blur()
                }
              }}
              onFocus={(e) => {
                props.onFocus && props.onFocus(e)
                setFocus(true)
              }}
              onBlur={handleBlur}
            />
          )}
          {header && tooltip && !value && (
            <InfoTooltip title={tooltip} width={tooltipWidth} large />
          )}
        </div>
      </div>
      {error && !noShowErrorMessage && (
        <>
          <SizedBox height={8} />
          <ValidItem
            text={error}
            neutral={false}
            isValid={false}
            rightContent={errorRightContent}
          />
        </>
      )}
      {((valid && value) || showValidItems) && (
        <div className={styles.ul}>
          <ValidItem
            text={'contains at least 8 characters'}
            neutral={!value}
            isValid={!!value && isValidLength}
          />
          <ValidItem
            text={'contains at least one number (0-9) or a symbol'}
            neutral={!value}
            isValid={!!value && isValidCharters}
          />
        </div>
      )}
    </div>
  )
}
