import styles from '../styles.module.scss'
import { observer } from 'mobx-react-lite'
import { useCustomPlanContext } from 'src/pages/superAdmin/modals/CustomPlan/context/context'
import { Typography } from 'shared/Typography'
import { Switch } from 'components/Switch/Switch'
import { Input } from 'shared/Input/Input'

export const CustomPrices = observer(() => {
  const { isCustomPrices, setIsCustomPrices, customPriceFields } =
    useCustomPlanContext()
  return (
    <>
      <div className={styles.row}>
        <Typography>Use custom prices</Typography>
        <Switch active={isCustomPrices} onChange={setIsCustomPrices} />
      </div>
      {isCustomPrices && (
        <>
          {customPriceFields.map(
            ({ type, title, value, setValue, placeholder }) => (
              <div className={styles.row} key={type}>
                <span>{title}</span>
                <Input
                  value={value}
                  setValue={setValue}
                  isNumber
                  numericFormatProps={{ allowNegative: false }}
                  placeholder={placeholder ? String(placeholder) : undefined}
                />
              </div>
            )
          )}
        </>
      )}
    </>
  )
})
