import { TablePage } from 'components/Page/TablePage'
import { Tabs } from 'components/Tabs/Tabs'
import styles from './styles.module.scss'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/routes/routes'
import { useGetCustomFields } from 'src/routes/main/contacts/customFields/hooks/useGetCustomFields'
import { observer } from 'mobx-react-lite'
import { NewLabel } from 'src/shared/NewLabel/NewLabel'
import configStore from 'store/configStore'

export const Contacts = observer(() => {
  useGetCustomFields()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const isLists = pathname.includes(RoutesEnum.lists)
  const isSegments = pathname.includes(RoutesEnum.segments)
  const getActiveIndex = () => {
    if (isLists) {
      return 1
    }
    if (isSegments) {
      return 2
    }
    return 0
  }

  const onTabClick = (index: number) => {
    if (index === 0) {
      navigate(RoutesEnum.contacts)
    }
    if (index === 1) {
      navigate(RoutesEnum.contacts + '/' + RoutesEnum.lists)
    }
    if (index === 2) {
      navigate(RoutesEnum.contacts + '/' + RoutesEnum.segments)
    }
  }
  return (
    <TablePage>
      <div>
        <h1>Contacts</h1>
        <div className={styles.wrapTabs}>
          <Tabs
            tabs={[
              'Contacts',
              'Lists',
              <>
                Segments
                {configStore.isSegments && <NewLabel text={'Beta'} />}
              </>,
            ]}
            activeTabIndex={getActiveIndex()}
            onTabClick={onTabClick}
          />
        </div>
      </div>
      <Outlet />
    </TablePage>
  )
})
