import { createContext, useContext } from 'react'
import { ConversionsStore } from 'src/pages/settings/conversions/conversions/store/conversionsStore'

export const ConversionsContext = createContext<ConversionsStore | null>(null)

export const useConversionsContext = () => {
  const context = useContext(ConversionsContext)
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with ConversionsContext'
    )
  }
  return context
}
