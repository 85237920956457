import styles from './style.module.scss'
import { InputText } from 'components/Input/InputText/InputText'
import { Card } from 'components/Card/Card'
import React from 'react'
import { SizedBox } from 'components/SizedBox'
import { observer } from 'mobx-react-lite'
import { useCreateWebFromContext } from 'src/pages/main/webForms/create/CreateWebformState'
import { ActionsContent } from 'src/pages/main/webForms/create/createForm/ActionContent/ActionsContent'
import { AddContactsToListDropdown } from 'src/Modals/ImportContactModal/StepsContent/Step3/AddContactsToListDropdown'
import { FeedbackPageContent } from 'src/pages/main/webForms/create/createForm/FeedbackPageContent'
import { FieldsCollect } from 'src/pages/main/webForms/create/createForm/FieldsCollect/FieldsCollect'
import { SMSContent } from 'components/CreateElement/SMSContent/SMSContent'
import classNames from 'classnames'
import uiStore from 'store/uiStore'
import { useElementName } from 'src/hooks/useElementName'
import { MessageTemplatesModal } from 'src/widgets/MessageTemplate'
import { InputCollapsableCheckbox } from 'components/Input/InputCollapsableCheckbox/InputCollapsableCheckbox'
import { EditableFieldText } from 'components/CreateElement/SMSContent/EditableFieldText/EditableFieldText'
import { Separator } from 'components/Separator/Separator'
import { Advanced } from 'widgets/Advanced/Advanced'

export const NewWebForm = observer(({ fromModal }: { fromModal?: boolean }) => {
  const createWebFormStore = useCreateWebFromContext()
  const { error, onBlur, onFocus } = useElementName(createWebFormStore)
  return (
    <Card
      width={600}
      noMobileRadius
      className={classNames(uiStore.isMobile && 'minHeight100')}
    >
      <MessageTemplatesModal store={createWebFormStore} />
      {!fromModal ? (
        <span className={'gray1'}>
          {`${createWebFormStore.edit ? 'Edit' : 'New'} web form`}
        </span>
      ) : (
        <SizedBox height={8} />
      )}
      <div className={styles.inputWrap}>
        <InputText
          id={'name'}
          placeholder={'Web form name'}
          withoutBorder
          header
          value={createWebFormStore.name}
          onChange={(event) => {
            createWebFormStore.setName(event.target.value)
          }}
          error={error}
          onBlur={onBlur}
          onFocus={onFocus}
          withoutClear
          autoComplete={'off'}
        />
      </div>
      <SizedBox height={32} />
      <SMSContent
        store={createWebFormStore}
        noShortCode
        additionalContent={
          <>
            <InputCollapsableCheckbox
              label={'Double opt-in'}
              tooltipTitle={
                'We recommend double opt-in to confirm the contact wishes to receive texts from your company.'
              }
              checked={createWebFormStore.doubleOptIn}
              onChecked={() => {
                createWebFormStore.setDoubleOptIn(
                  !createWebFormStore.doubleOptIn
                )
              }}
              collapsableContent={
                <EditableFieldText
                  editableText={createWebFormStore.editableDoubleOptInText}
                  setEditableText={
                    createWebFormStore.setEditableDoubleOptInText
                  }
                  REQUIRED_TEXT={createWebFormStore.REQUIRED_DOUBLE_OPT_IN_TEXT}
                  NOT_REQUIRED_TEXT={
                    createWebFormStore.NOT_REQUIRED_DOUBLE_OPT_IN_TEXT
                  }
                  isRequiredTextAtEnd
                />
              }
              styleProps={{
                margin: '6px -12px',
              }}
            />
            <Separator />
          </>
        }
      />
      <SizedBox height={24} />
      <AddContactsToListDropdown
        store={createWebFormStore.dropdownContactListsStore}
      />
      <Advanced config={createWebFormStore} />
      <SizedBox height={32} />
      <FieldsCollect />
      <InputText
        placeholder={'Enter text'}
        label={'Subscribe button text'}
        value={createWebFormStore.subscribeBtnText}
        onChangeValue={createWebFormStore.setSubscribeText}
        onClickRightIcon={() => createWebFormStore.setCustomWebsite('')}
      />
      <SizedBox height={24} />
      <FeedbackPageContent />

      <ActionsContent fromModal={fromModal} />
    </Card>
  )
})
