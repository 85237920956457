import { CreatePage } from 'components/Page/CreatePage'
import { useLocation, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useCreateKeywordContext } from 'src/pages/main/keywords/create/CreateKeywordState'
import keywordsStore from 'store/keywords/keywordsStore'
import { NewKeyword } from 'src/pages/main/keywords/create/createForm/NewKeyword'
import { PreviewKeyword } from 'src/pages/main/keywords/create/preview/PreviewKeyword'
import { useGetKeywordByIdLazyQuery } from 'src/generated/graphql'
import { transformKeyword } from 'src/pages/main/keywords/functions/transformKeyword'

export const EditKeywordContent = observer(() => {
  const { pathname } = useLocation()
  const isDuplicate = pathname.includes('duplicate')
  const { id } = useParams()

  const [getKeywordById, { loading }] = useGetKeywordByIdLazyQuery()

  useEffect(() => {
    if (id) {
      getKeywordById({
        variables: {
          keywordId: +id,
        },
      })
        .then(({ data }) => {
          if (data?.getKeywordById) {
            const iKeyword = transformKeyword(data.getKeywordById)
            createKeywordStore.setEdit(iKeyword, isDuplicate)
            keywordsStore.setKeyword(iKeyword)
          }
        })
        .catch((e) => console.error(e))
    }
  }, [id])

  const createKeywordStore = useCreateKeywordContext()

  return (
    <CreatePage
      store={createKeywordStore}
      createElement={<NewKeyword />}
      previewElement={<PreviewKeyword />}
      loading={loading}
    />
  )
})
