import { Typography } from 'shared/Typography'
import styles from './styles.module.scss'
import { Icon } from 'components/Icon/Icon'
import { Actions } from 'src/pages/settings/conversions/conversions/ui/actions/Actions'

export const EmptyState = () => {
  return (
    <div className={styles.wrap}>
      <div className={styles.empty}>
        <Icon
          icon={'conversion'}
          fontSize={32}
          color={'--primary-color-gray-2'}
        />
        <Typography variant={'h3-semiBold'} style={{ marginTop: 20 }}>
          Create your first conversion
        </Typography>
        <Typography
          variant={'p2-regular'}
          style={{ marginTop: 8, marginBottom: 24 }}
          color={'--primary-color-gray-1'}
        >
          Measure the ROI of your SMS marketing campaigns.
        </Typography>
        <Actions />
      </div>
    </div>
  )
}
