import { ICardDetail } from 'components/DetailCard/DetailCard/DetailCard'
import { observer } from 'mobx-react-lite'
import { DetailCardsWrapper } from 'components/DetailCard/DetailCardsWrapper'
import campaignDetailStore from 'store/campaigns/campaignDetailStore'
import { useGetCampaignAnalyticsStatisticsQuery } from 'src/generated/graphql'
import { useEffect } from 'react'
import { detailsCards } from 'components/DetailCard/variants'

export const AdditionalContent = observer(() => {
  const {
    setCampaignOverviewMessageStatistics,
    overviewRangeDatePickerStore,
    campaign,
  } = campaignDetailStore

  const { data } = useGetCampaignAnalyticsStatisticsQuery({
    variables: {
      uuid: campaign?.uuid,
      dateRange: overviewRangeDatePickerStore.allTime
        ? null
        : {
            from: overviewRangeDatePickerStore.startDate,
            to: overviewRangeDatePickerStore.endDate,
          },
    },
    skip:
      !campaign?.uuid ||
      (!overviewRangeDatePickerStore.allTime &&
        (!overviewRangeDatePickerStore?.startDate ||
          !overviewRangeDatePickerStore?.endDate)),
  })

  const shortUrlClickStatistics =
    data?.getCampaignAnalyticsStatistics?.shortUrlClickStatistics
  const totalStatistics = data?.getCampaignAnalyticsStatistics?.totalStatistics
  const percentageStatistics =
    data?.getCampaignAnalyticsStatistics?.percentageStatistics

  const totalCount = totalStatistics?.totalCount || 0
  const successCount = totalStatistics?.successCount || 0
  const unsubscribedCount = totalStatistics?.unsubscribedCount || 0
  const pendingCount = totalStatistics?.pendingCount || 0
  const failedCount = totalStatistics?.failedCount || 0

  const successPercentage = percentageStatistics?.successPercentage || 0
  const unsubscribedPercentage =
    percentageStatistics?.unsubscribedPercentage || 0
  const failedPercentage = percentageStatistics?.failedPercentage || 0
  const pendingPercentage = percentageStatistics?.pendingPercentage || 0

  const cards: ICardDetail[] = [
    detailsCards.total({
      count: totalCount,
    }),

    detailsCards.pending({
      count: pendingCount,
      percent: pendingPercentage,
    }),

    detailsCards.success({
      count: successCount,
      percent: successPercentage,
    }),

    shortUrlClickStatistics?.shortUrlVisits?.length &&
      detailsCards.shortUrlClickStatistics({
        shortUrlClickStatistics,
      }),

    detailsCards.failed({
      count: failedCount,
      percent: failedPercentage,
    }),
    detailsCards.unsubscribed({
      count: unsubscribedCount,
      percent: unsubscribedPercentage,
    }),
  ].filter((card) => Boolean(card)) as ICardDetail[]

  useEffect(() => {
    setCampaignOverviewMessageStatistics(
      data?.getCampaignAnalyticsStatistics || null
    )
  }, [data])

  return <DetailCardsWrapper cards={cards} noSelect />
})
