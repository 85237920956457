import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { SizedBox } from 'components/SizedBox'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useRegisterHubspotMember } from 'src/hooks/useRegisterHubspotMember'
import { RangeBar } from 'components/RangeBar/RangeBar'
import { Typography } from 'shared/Typography'
import { Label } from 'shared/Label/Label'
import { Icon } from 'components/Icon/Icon'
import { DropdownField } from 'components/DropdownField/DropdownField'
import { SelectList } from 'components/SelectList/SelectList'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import styles from './styles.module.scss'
import { NoCompleteRoutes } from 'src/routes/routes'
import { useRegisterContext } from 'src/routes/auth/signUp/registerFlowB/context/context'
import { Input } from 'src/shared/Input/Input'

export const RegisterCompanyInfoB = observer(() => {
  const {
    loadHubspotForm,
    saveCompanyData,
    companyName,
    setCompanyName,
    disabledContinue,

    selected_contact_list_size_item,
    contact_list_size_options,
    set_selected_contact_list_size,

    selected_message_frequency_item,
    message_frequency_options,
    set_selected_message_frequency,

    type_of_messages_inputs_checkbox,
  } = useRegisterContext()

  const navigate = useNavigate()
  const onContinue = async () => {
    saveCompanyData()
    navigate(NoCompleteRoutes.register2)
  }

  useRegisterHubspotMember()

  useEffect(() => {
    loadHubspotForm()
  }, [])

  return (
    <>
      <>
        <Typography variant={'h2-semiBold'}>
          Tell us about your company
        </Typography>
        <SizedBox height={8} />
        <p className={'par2 gray1 medium mb20'}>
          This will help customize the app for you
        </p>
        <RangeBar progress={66} style2 />
      </>
      <SizedBox height={32} />
      <Label text={'Company name'} marginBottom={8} />
      <Input
        value={companyName}
        setValue={setCompanyName}
        placeholder={'e.g. Tesla, Inc'}
      />
      <SizedBox height={32} />

      <Label
        text={'How many contacts do you plan to message?'}
        marginBottom={8}
      />
      <DropdownField
        isSameWidth
        isFullWidth
        dropdownContent={
          <SelectList
            withoutMark
            options={contact_list_size_options}
            onSelect={set_selected_contact_list_size}
          />
        }
      >
        <Input
          isReadOnly
          value={selected_contact_list_size_item?.title}
          placeholder={'Select'}
          rightContent={
            <Icon
              icon={'angleDown'}
              color={'--primary-color-gray-1'}
              fontSize={16}
            />
          }
        />
      </DropdownField>

      <SizedBox height={32} />

      <Label text={'How many times will you message them?'} marginBottom={8} />
      <DropdownField
        isSameWidth
        isFullWidth
        dropdownContent={
          <SelectList
            withoutMark
            options={message_frequency_options}
            onSelect={set_selected_message_frequency}
          />
        }
      >
        <Input
          isReadOnly
          value={selected_message_frequency_item?.title}
          placeholder={'Select'}
          rightContent={
            <Icon
              icon={'angleDown'}
              color={'--primary-color-gray-1'}
              fontSize={16}
            />
          }
        />
      </DropdownField>

      <SizedBox height={32} />
      <Label text={'What types of messages will you send?'} marginBottom={8} />
      <div className={styles.wrapInputs}>
        {type_of_messages_inputs_checkbox.map((item) => (
          <InputCheckbox nowrap key={item.label} {...item} />
        ))}
      </div>

      <div className={'mt40'}>
        <Button
          size={BtnSize.large}
          typeBtn={BtnType.primary}
          onClick={onContinue}
          disabled={disabledContinue}
        >
          Continue
        </Button>
      </div>
    </>
  )
})
