import { makeAutoObservable, reaction } from 'mobx'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { maintenanceError } from 'src/providers/AppApolloProvider'
import { nanoid } from 'nanoid'
import alertStore from 'store/alertStore'
import cookie from 'js-cookie'
import { CookieEnum } from 'src/enums/CookieEnum'
import { IResponseAuth } from 'src/apiRest/authApi/types'
import { authApi } from 'src/apiRest/authApi/authApi'

export const authHeaders = {
  'Content-Type': 'application/json',
}

class Auth {
  withGoogle = false
  constructor() {
    makeAutoObservable(this)
    const token =
      sessionStorage.getItem('assumeToken') || localStorage.getItem('token')
    this.withGoogle = JSON.parse(localStorage.getItem('withGoogle') || 'false')
    axios.defaults.baseURL = process.env.API + '/'
    axios.defaults.headers.common['Authorization'] = token
      ? `Bearer ${token}`
      : ''
    reaction(
      () => this.token,
      (token) => {
        axios.defaults.headers.common['Authorization'] = token
          ? `Bearer ${token}`
          : ''
      }
    )
  }
  token = sessionStorage.getItem('assumeToken') || localStorage.getItem('token')
  isAssume = !!sessionStorage.getItem('assumeToken')
  refetchMainInfo = ''
  loadingMainInfo = true

  loginSearchParams: URLSearchParams | null = null

  migrationParams: {
    migration?: string
    customerId?: string
    email?: string
    twilioSubSID?: string
    userKey?: string
  } = {}

  isMigration = false
  initTagManager = false

  authError = ''

  onBackFn: (() => void) | null = null

  initFirstPromoter = false
  stripeCoupon = ''

  noOrganizationModal = false
  awaitToken = ''
  completeRegisterTrigger = ''

  get isAuth() {
    return !!this.token
  }

  get affiliatePromId() {
    return cookie.get('_fprom_tid')
  }

  setToken = (token: string) => {
    localStorage.setItem('token', token)
    this.token = token
  }
  setAssumeToken = (token: string) => {
    if (token) {
      sessionStorage.setItem('assumeToken', token)
      this.isAssume = true
      this.token = token
    }
  }

  openNoOrganizationModal = (token: string) => {
    this.noOrganizationModal = true
    this.awaitToken = token
  }
  closeNoOrganizationModal = () => {
    this.noOrganizationModal = false
    this.awaitToken = ''
  }

  onCreateOrganization = () => {
    this.noOrganizationModal = false
    this.setToken(this.awaitToken)
  }

  onSignInWithGoogle = async (
    googleTokenId: string,
    invitationUuid?: string,
    onBanned?: () => void
  ) => {
    if (invitationUuid) {
      await this.onSignIn(
        authApi.inviteSignInWithGoogle({
          affiliatePromId: this.affiliatePromId,
          googleTokenId,
          invitationUuid,
          userKey: this.migrationParams.userKey,
        }),
        onBanned
      )
    } else {
      await this.onSignIn(
        authApi.signInWithGoogle({
          affiliatePromId: this.affiliatePromId,
          googleTokenId,
          userKey: this.migrationParams.userKey,
          forceRegister: this.forceRegister || undefined,
        }),
        onBanned
      )
    }
  }

  onSignUp = async (email: string, password: string, timeZoneCode: string) => {
    await this.onSignIn(
      authApi.signup({
        affiliatePromId: this.affiliatePromId,
        email,
        password,
        timeZoneCode,
        userKey: this.migrationParams.userKey,
        forceRegister: this.forceRegister || undefined,
      })
    )
  }

  onSignUpInvite = async ({
    email,
    password,
    firstName,
    lastName,
    invitationUuid,
    timeZoneCode,
  }: {
    email: string
    password: string
    firstName: string
    lastName: string
    timeZoneCode: string
    invitationUuid: string
  }) => {
    await this.onSignIn(
      authApi.inviteSignUp({
        affiliatePromId: this.affiliatePromId,
        email,
        password,
        firstName,
        lastName,
        invitationUuid,
        timeZoneCode,
      })
    )
  }

  private onSignIn = async (
    promise: Promise<AxiosResponse<IResponseAuth>>,
    onBanned?: () => void
  ) => {
    try {
      const { data } = await promise
      const { token, message, hasOrganization } = data
      if (message === maintenanceError) {
        window.location.reload()
      }
      if (!hasOrganization) {
        this.openNoOrganizationModal(token)
      } else if (token) {
        this.setToken(token)
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.data.message === 'Organization was banned') {
          onBanned && onBanned()
        } else {
          alertStore.disabledErrorAlert(e.response?.data.message)
          this.setAuthError(e.response?.data.message || 'Something went wrong')
        }
      }

      console.error(e)
    }
  }

  onLogin = async (email: string, password: string, onBanned: () => void) => {
    await this.onSignIn(
      authApi.signIn({
        affiliatePromId: this.affiliatePromId,
        email,
        password,
      }),
      onBanned
    )
  }

  onLogout = async () => {
    try {
      await authApi.logout()
    } catch (e) {
      console.error(e)
    } finally {
      localStorage.clear()
      sessionStorage.clear()
      if (analytics.reset) {
        analytics.reset()
      }
      window.location.reload()
    }
  }

  setMigrationParams = (migrationParams: typeof this.migrationParams) => {
    this.migrationParams = migrationParams
  }

  setOnBackFn = (fn: typeof this.onBackFn) => {
    this.onBackFn = fn
  }

  setIsMigration = () => {
    this.isMigration = true
  }

  setRefetchMainInfo = (loadingMainInfo = true) => {
    this.loadingMainInfo = loadingMainInfo
    this.refetchMainInfo = nanoid()
  }

  setLoadingMainInfo = (value: boolean) => {
    this.loadingMainInfo = value
  }

  setInitTagManager = () => {
    this.initTagManager = true
  }

  setLoginSearchParams = (loginSearchParams: typeof this.loginSearchParams) => {
    if (
      this.loginSearchParams?.toString() &&
      this.loginSearchParams?.toString() === loginSearchParams?.toString()
    ) {
      return
    }
    this.loginSearchParams = loginSearchParams
  }

  setAuthError = (error = '') => {
    this.authError = error
  }

  setInitFirstPromoter = () => {
    this.initFirstPromoter = true
  }

  setStripeCoupon = (stripeCoupon: string) => {
    cookie.set(CookieEnum.StripeCoupon, stripeCoupon, { expires: 3 })
  }

  setCompleteRegisterTrigger = (value: string) => {
    this.completeRegisterTrigger = value
  }

  forceRegister = false
  setForceRegister = () => {
    this.forceRegister = true
  }
}

export default new Auth()
