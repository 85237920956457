import { NewBroadcast } from 'src/pages/main/broadcasts/createBroadcast/newBroadcast/newBroadcast'
import { CreateBroadcastsModal } from 'src/pages/main/broadcasts/createBroadcast/CreateBroadcastsModal/CreateBroadcastsModal'
import { PreviewBroadcast } from './PreviewBroadcast/PreviewBroadcast'
import { CreatePage } from 'components/Page/CreatePage'
import React, { useEffect, useState } from 'react'
import { CreateBroadCastStore } from 'store/broadcasts/createBroadCastStore'
import { useGetBroadcast } from 'src/pages/main/broadcasts/hooks/useGetBroadcast'
import { CreateBroadcastContext } from 'src/pages/main/broadcasts/createBroadcast/context/CreateBroadcastContext'
import { observer } from 'mobx-react-lite'

export const CreateBroadcast = observer(() => {
  useGetBroadcast()
  const [store] = useState(() => new CreateBroadCastStore())
  useEffect(() => store.dispose, [])
  return (
    <CreateBroadcastContext.Provider value={store}>
      <>
        <CreatePage
          store={store}
          createElement={<NewBroadcast />}
          previewElement={<PreviewBroadcast />}
        />
        <CreateBroadcastsModal />
      </>
    </CreateBroadcastContext.Provider>
  )
})
