import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { Counter } from 'components/Counter/Counter'
import {
  operatorsOptions,
  periodsOptions,
} from 'components/Schedule/store/mockData'
import classNames from 'classnames'
import { AddCustomField } from 'src/pages/main/contacts/customFields/modals/CustomFieldsModal/AddCustomField'
import customFieldsStore from 'store/contacts/customFields/customFieldsStore'
import { CampaignScheduleType, CustomFieldType } from 'src/generated/graphql'
import { TimePicker } from 'components/TimePicker/TimePicker'
import dayjs from 'lib/dayjs'
import { useGetCustomFields } from 'src/pages/main/contacts/customFields/hooks/useGetCustomFields'
import { useCampaignScheduleContext } from 'src/widgets/CampaignSchedule'
import { useDropdownContext } from 'components/Dropdown/context/context'
import { CheckBoxBtn } from 'components/CheckBoxBtn/CheckBoxBtn'

export const ContactDateFieldContent = observer(() => {
  const {
    activeContactDateField,
    contactDateFieldDate,
    contactDateField,
    setContactDateFieldDate,
    contactDateFieldOperator,
    contactDateFieldCount,
    setContactDateFieldCount,
    contactDateFieldPeriod,
    setContactDateFieldPeriod,
    setContactDateFieldOperator,
    setContactDateFieldId,
    activeContactDateCustomTime,
    toggleActiveContactDateCustomTime,
  } = useCampaignScheduleContext()
  const dropdownContext = useDropdownContext()
  const parentElement = dropdownContext?.parentElement
  useGetCustomFields(
    !!customFieldsStore.customFieldsOptions.length || !activeContactDateField
  )
  useEffect(() => {
    if (activeContactDateField && !contactDateFieldDate) {
      setContactDateFieldDate(dayjs().startOf('day').add(12, 'hours').toDate())
    }
  }, [activeContactDateField, contactDateField?.data?.type])
  if (!activeContactDateField) {
    return <></>
  }

  const timePicker = (
    <TimePicker
      startTime={contactDateFieldDate}
      onChange={(time) => setContactDateFieldDate(time)}
    />
  )

  return (
    <>
      <div className={classNames('col16', styles.wrap)}>
        {contactDateFieldOperator.value !== CampaignScheduleType.On && (
          <div className={styles.counter}>
            <Counter
              count={contactDateFieldCount}
              onMinus={() =>
                setContactDateFieldCount(+contactDateFieldCount - 1)
              }
              onPlus={() =>
                setContactDateFieldCount(+contactDateFieldCount + 1)
              }
              onChange={setContactDateFieldCount}
            />
            <Dropdown
              options={periodsOptions}
              selectedOption={contactDateFieldPeriod}
              onSelect={(opt) => setContactDateFieldPeriod(opt)}
              parentElement={parentElement}
            />
          </div>
        )}
        <Dropdown
          options={operatorsOptions}
          selectedOption={contactDateFieldOperator}
          onSelect={setContactDateFieldOperator}
          parentElement={parentElement}
        />
        <Dropdown
          options={customFieldsStore.customFieldsTimeOptions}
          placeholder={'Select filed'}
          selectedOption={contactDateField}
          onSelect={setContactDateFieldId}
          parentElement={parentElement}
          BottomDropdownButton={(props) => AddCustomField({ ...props })}
        />

        {contactDateField?.data?.type === CustomFieldType.Date && timePicker}
      </div>
      {contactDateField?.data?.type === CustomFieldType.DateTime && (
        <div className={styles.time}>
          <div className={'p8'}>
            <CheckBoxBtn
              active={activeContactDateCustomTime}
              toggleActive={toggleActiveContactDateCustomTime}
              title={'Set custom time'}
            />
          </div>

          {activeContactDateCustomTime && (
            <div className={styles.wrapTimePicker}>{timePicker}</div>
          )}
        </div>
      )}
    </>
  )
})
