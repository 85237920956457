import { makeAutoObservable } from 'mobx'
import {
  AdvancedSchedulingConfigFragment,
  AdvancedSchedulingConfigInput,
  BroadcastSendType,
  SchedulingConfig,
  SchedulingConfigInput,
} from 'src/generated/graphql'
import { BroadcastScheduleLaterStore } from 'src/widgets/BroadcastSchedule/ui/LaterContent/store/BroadcastScheduleLaterStore'
import { AdvancedSchedulingStore } from 'src/widgets/AdvancedScheduling'

export class BroadcastScheduleStore {
  constructor() {
    makeAutoObservable(this)
  }

  broadcastScheduleLaterStore: BroadcastScheduleLaterStore | null = null
  advancedSchedulingStore: AdvancedSchedulingStore =
    new AdvancedSchedulingStore()

  sendTypeBroadcast: BroadcastSendType = BroadcastSendType.Immediately

  get scheduleError() {
    if (this.activeSendLater && this.broadcastScheduleLaterStore) {
      return this.broadcastScheduleLaterStore.scheduleError
    }
    return ''
  }

  get advancedSchedulingConfigInput(): Omit<
    AdvancedSchedulingConfigInput,
    'durationDays' | 'durationMinutes' | 'durationHours'
  > | null {
    return this.advancedSchedulingStore.advancedSchedulingConfigInput
  }

  get schedulingConfigInput(): SchedulingConfigInput {
    return {
      sendLaterConfig:
        this.activeSendLater && this.broadcastScheduleLaterStore
          ? this.broadcastScheduleLaterStore?.sendLaterConfigInput
          : undefined,
      sendType: this.sendTypeBroadcast,
    }
  }

  get activeSendImmediately() {
    return this.sendTypeBroadcast === BroadcastSendType.Immediately
  }
  get activeSendLater() {
    return this.sendTypeBroadcast === BroadcastSendType.Later
  }

  get sendTimeString() {
    if (this.activeSendImmediately) {
      return 'Immediately'
    }
    if (this.activeSendLater && this.broadcastScheduleLaterStore) {
      return this.broadcastScheduleLaterStore?.sendTimeString
    }
    return ''
  }

  get suffixString() {
    if (this.activeSendImmediately) {
      return ''
    }
    if (this.activeSendLater && this.broadcastScheduleLaterStore) {
      return this.broadcastScheduleLaterStore.suffixString
    }
    return ''
  }
  setSchedulingConfig = (schedulingConfig: SchedulingConfig) => {
    if (schedulingConfig.sendType === BroadcastSendType.Immediately) {
      this.onActiveImmediately()
    }
    if (schedulingConfig.sendType === BroadcastSendType.Later) {
      this.sendTypeBroadcast = BroadcastSendType.Later
      this.broadcastScheduleLaterStore = new BroadcastScheduleLaterStore(
        schedulingConfig.sendLaterConfig
      )
    }
  }

  setAdvancedSchedulingConfig = (config: AdvancedSchedulingConfigFragment) => {
    this.advancedSchedulingStore.setAdvancedSchedulingConfig(config)
  }

  onActiveImmediately = () => {
    this.sendTypeBroadcast = BroadcastSendType.Immediately
    this.broadcastScheduleLaterStore = null
  }

  onActiveLater = () => {
    this.sendTypeBroadcast = BroadcastSendType.Later
    this.broadcastScheduleLaterStore = new BroadcastScheduleLaterStore()
  }

  get advancedInfo() {
    return this.advancedSchedulingStore.advancedInfo
  }
}
