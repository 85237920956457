import styles from './styels.module.scss'
import { FC } from 'react'
import { ReactComponent as Clock } from 'icons/16px/Clock.svg'
import { ReactComponent as Book } from 'icons/16px/Book.svg'
import { CircularProgress } from 'components/CircularProgress/CircularProgress'
import { IObjective } from 'store/dashboard/objectives/types'
import { useNavigate } from 'react-router-dom'
import { DashboardRoutesEnum } from 'src/pages/main/dashboard/dashboardRoutes'
import { RoutesEnum } from 'src/pages/routes'

export const ObjectiveCard: FC<IObjective> = ({
  step,
  title,
  currentLesson,
  lessons,
}) => {
  const navigate = useNavigate()
  const completeLessons = lessons.filter((lesson) => lesson.complete)
  return (
    <button
      className={styles.wrap}
      onClick={() =>
        navigate(
          RoutesEnum.dashboard +
            '/' +
            DashboardRoutesEnum.objective +
            '/' +
            step
        )
      }
    >
      <div className={styles.content}>
        <div className={styles.wrapIcon}>
          <div className={styles.step}>
            <span className={'medium blue1'}>{step}</span>
          </div>
          <h3>{title}</h3>
        </div>

        <div>
          <div className={styles.progressWrap}>
            <CircularProgress
              progress={(completeLessons.length / lessons.length) * 100}
            />
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.wrapIcon}>
          <Clock />
          <span className={'gray1'}>
            {lessons.reduce((acc, cur) => acc + cur.durationMin, 0)} min
          </span>
        </div>
        <div className={styles.wrapIcon}>
          <Book />
          <span className={'gray1'}>
            {currentLesson}/{lessons.length}
          </span>
        </div>
      </div>
    </button>
  )
}
