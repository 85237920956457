import { observer } from 'mobx-react-lite'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'
import {
  TollFreeVerificationProfile,
  useAdminInitTollFreeVerificationOptInMethodMutation,
  useInitTollFreeVerificationOptInMethodMutation,
} from 'src/generated/graphql'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { PropsTollFreeStep } from 'src/pages/settings/compliance/TollFreeVerification/types'
import { TollFreeBackBtn } from 'src/pages/settings/compliance/TollFreeVerification/steps/TollFreeBackBtn'
import { Dropdown } from 'components/Dropdown/Dropdown'
import React from 'react'
import { appLinks } from 'src/util/links'
import { OptInMethodContent } from 'src/pages/settings/compliance/TollFreeVerification/steps/OptInMethod/OptInMethodContent'

export const OptInMethod = observer(({ onSuccess }: PropsTollFreeStep) => {
  const {
    optInMethod,
    setOptInMethod,
    optInOptions,
    tollFreeVerificationOptInMethodRequestInput,
    disabledOptInMethod,
    continueText,
    adminOrgId,
  } = tollFreeVerificationStore

  const [initTfProfileBusinessInfo, { loading }] =
    useInitTollFreeVerificationOptInMethodMutation()
  const [adminInitTfProfileBusinessInfo, { loading: loadingAdmin }] =
    useAdminInitTollFreeVerificationOptInMethodMutation()
  const onContinue = async () => {
    try {
      let profile: TollFreeVerificationProfile | null | undefined
      if (adminOrgId) {
        const { data } = await adminInitTfProfileBusinessInfo({
          variables: {
            input: tollFreeVerificationOptInMethodRequestInput,
            organizationId: adminOrgId,
          },
        })
        profile = data?.adminInitTollFreeVerificationOptInMethod
      } else {
        const { data } = await initTfProfileBusinessInfo({
          variables: {
            input: tollFreeVerificationOptInMethodRequestInput,
          },
        })
        profile = data?.initTollFreeVerificationOptInMethod
      }

      profile && onSuccess(profile)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className={'col40'}>
      <div>
        <Dropdown
          fullWidth
          labelInput={'Opt-in method'}
          labelTooltip={
            <>
              How will your contacts opt-in to receive SMS messages from your
              company? Select the method that you are using to capture contacts.{' '}
              <a
                href={appLinks.tollFreeVerificationRegistrationOptIn}
                target={'_blank'}
                className={'s1'}
                rel="noreferrer"
              >
                Learn more.
              </a>
            </>
          }
          placeholder={'e.g. via Text'}
          options={optInOptions}
          selectedValue={optInMethod}
          onSelectValue={setOptInMethod}
        />

        {optInMethod && <OptInMethodContent />}
      </div>

      <div className={'row12'}>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          onClick={onContinue}
          disabled={disabledOptInMethod}
          loading={loading || loadingAdmin}
        >
          {continueText}
        </Button>
        <TollFreeBackBtn />
      </div>
    </div>
  )
})
