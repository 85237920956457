// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JHjwGM9dDxizBk32xvg8{display:flex;row-gap:20px;column-gap:16px;flex-flow:wrap}`, "",{"version":3,"sources":["webpack://./src/pages/settings/integrations/content/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,YAAA,CACA,eAAA,CACA,cAAA","sourcesContent":[".wrap{\n  display: flex;\n  row-gap: 20px;\n  column-gap: 16px;\n  flex-flow: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `JHjwGM9dDxizBk32xvg8`
};
export default ___CSS_LOADER_EXPORT___;
