import { observer } from 'mobx-react-lite'
import { ContentType } from 'src/generated/graphql'
import styles from './styles.module.scss'
import { DropdownButton } from 'shared/DropdownButton/DropdownButton'
import { TrackConversion } from 'widgets/TrackConversion/TrackConversion'
import { TrackConversionStore } from 'widgets/TrackConversion/store/trackConversionStore'
import { CSSProperties } from 'react'

export const Advanced = observer(
  ({
    config,
    style,
  }: {
    config: {
      type: ContentType
      isAdvanced: boolean
      toggleAdvanced: () => void
      trackConversionStore: TrackConversionStore
    }
    style?: CSSProperties
  }) => {
    const { type, isAdvanced, toggleAdvanced, trackConversionStore } = config
    if (type === ContentType.Sms) {
      return (
        <div className={styles.wrap} style={style}>
          <DropdownButton
            text={'Advanced'}
            rightIcon={isAdvanced ? 'angleUp' : 'angleDown'}
            onClick={toggleAdvanced}
            isNew
          />
          {isAdvanced && (
            <div className={styles.wrapConversion}>
              <TrackConversion store={trackConversionStore} />
            </div>
          )}
        </div>
      )
    }
    return null
  }
)
