import styles from './styles.module.scss'
import { ColorsNames } from 'styles/variables'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ModalIcon } from 'components/ModalIcon/ModalIcon'
import { useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'
import { observer } from 'mobx-react-lite'
import dayjs, { timestampToAbsoluteDay } from 'lib/dayjs'
import billingStore from 'store/settings/billing/billingStore'

export const CanceledPage = observer(() => {
  const canceledDay = billingStore.cancelAccountAt
    ? Math.floor(
        timestampToAbsoluteDay(dayjs(billingStore.cancelAccountAt).valueOf())
      )
    : Math.floor(
        timestampToAbsoluteDay(
          dayjs(billingStore.clSubscription?.canceledAt).valueOf()
        )
      ) + 30
  const todayAbsoluteDay = Math.floor(timestampToAbsoluteDay(dayjs().valueOf()))
  const navigate = useNavigate()
  const days = canceledDay - todayAbsoluteDay
  const onReopen = () => {
    navigate(RoutesEnum.reopen)
  }
  const type = days > 10 ? 'info' : days > 5 ? 'infoWarning1' : 'infoError1'
  const color =
    days > 10
      ? ColorsNames.notify1
      : days > 5
      ? ColorsNames.warning1
      : ColorsNames.error1
  return (
    <div className={styles.wrap}>
      <div className={styles.wrapInfo}>
        <ModalIcon type={type} />
      </div>

      <h2 className={'bold'}>
        You have <b className={color}>{days < 0 ? 0 : days} days left</b> to
        regain access to your data
      </h2>
      <p className={'par2 gray1'}>
        In 30 days, your contacts, lists, and all account data will be purged
        and deleted forever. To keep your data, pause your account or upgrade to
        a paid plan.
      </p>
      <Button typeBtn={BtnType.primary} size={BtnSize.large} onClick={onReopen}>
        Reopen my account
      </Button>
    </div>
  )
})
