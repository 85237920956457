import styles from './styles.module.scss'
import { Tabs } from 'components/Tabs/Tabs'
import { useState } from 'react'
import { TrackingSnippet } from 'widgets/conversions/ConversionCode/TrackingSnippet/TrackingSnippet'
import { RevenueTracking } from 'widgets/conversions/ConversionCode/RevenueTracking/RevenueTracking'

export const ConversionCode = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  return (
    <div className={styles.wrap}>
      <Tabs
        tabs={['Tracking Snippet', 'Revenue Tracking (optional)']}
        activeTabIndex={activeTabIndex}
        onTabClick={setActiveTabIndex}
      />
      {activeTabIndex === 0 && <TrackingSnippet />}
      {activeTabIndex === 1 && <RevenueTracking />}
    </div>
  )
}
