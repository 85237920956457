// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tMonPNkqmSTUxnzjTmEr{display:grid;grid-template-columns:104px 1fr;column-gap:8px;padding:8px 8px 20px 8px;row-gap:16px}.siVBUAH0DPT2qPK7qNf3{grid-column:-1/1}`, "",{"version":3,"sources":["webpack://./src/widgets/CampaignSchedule/ui/LaterContentCampaign/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,+BAAA,CACA,cAAA,CACA,wBAAA,CACA,YAAA,CAEF,sBACE,gBAAA","sourcesContent":[".wrap{\n  display: grid;\n  grid-template-columns: 104px 1fr;\n  column-gap: 8px;\n  padding: 8px 8px 20px 8px;\n  row-gap: 16px;\n}\n.time{\n  grid-column: -1/1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `tMonPNkqmSTUxnzjTmEr`,
	"time": `siVBUAH0DPT2qPK7qNf3`
};
export default ___CSS_LOADER_EXPORT___;
