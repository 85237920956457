import { observer } from 'mobx-react-lite'
import { Modal } from 'components/Modal/Modal'
import billingStore from 'store/settings/billing/billingStore'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { useEffect, useState } from 'react'
import { Alert } from 'components/Alert/Alert'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { AutoRechargeCardItem } from 'src/pages/settings/billing/modals/DisabledAutoRechargeModal/AutoRechargeCardItem'
import alertStore from 'store/alertStore'
import {
  AutoRechargeThreshold,
  useSetupAutoRechargeMutation,
} from 'src/generated/graphql'

export const DisabledAutoRechargeModal = observer(() => {
  const [setupAutoRecharge] = useSetupAutoRechargeMutation()
  const [enabledChecker, setEnabledChecker] = useState(true)
  const [enabledTriggers, setEnabledTriggers] = useState(true)
  const [enabledBroadcasts, setEnabledBroadcasts] = useState(true)

  useEffect(() => {
    setEnabledChecker(true)
    setEnabledTriggers(true)
    setEnabledBroadcasts(true)
  }, [billingStore.openDisabledAutoRechargeModal])

  const [loading, setLoading] = useState(false)
  const onClose = () => {
    billingStore.setOpenDisabledAutoRechargeModal(false)
  }
  const onDisable = async () => {
    setLoading(true)
    try {
      const res = await setupAutoRecharge({
        variables: {
          options: {
            enable: false,
            creditsAmount: 500,
            threshold: AutoRechargeThreshold.Credits100,
          },
        },
      })
      if (res.data?.setupAutoRecharge) {
        billingStore.setOrganizationBillingInfo(res.data?.setupAutoRecharge)
        setLoading(false)
        onClose()
        alertStore.setAlert({
          type: AlertTypeEnum.success,
          text: 'Auto-Recharge is disabled',
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  const disabledContinue =
    enabledChecker || enabledTriggers || enabledBroadcasts

  return (
    <Modal
      open={billingStore.openDisabledAutoRechargeModal}
      onClose={onClose}
      title={'Disable Auto-Recharge'}
    >
      <div className={'mediumModal'}>
        <Alert
          type={AlertTypeEnum.notify2}
          title={
            'Auto-Recharge is required to ensure that your messages get delivered to your contacts.'
          }
          text={'To disable Auto-Recharge, turn OFF the following features.'}
        />
        <div className={'col16 mt32'}>
          <AutoRechargeCardItem
            title={'PhoneCheckr'}
            text={
              'Turning OFF will increase the risk of sending messages to invalid numbers, thus losing credits and getting blocked by carriers.'
            }
            enabled={enabledChecker}
            onToggle={setEnabledChecker}
          />
          <AutoRechargeCardItem
            title={'Active Triggers'}
            text={
              <>
                Turning OFF will change the statuses of the Triggers: <br />
                Active -{'>'} Disabled
              </>
            }
            enabled={enabledTriggers}
            onToggle={setEnabledTriggers}
          />
          <AutoRechargeCardItem
            title={'Scheduled & Sending Broadcasts'}
            text={
              <>
                Turning OFF will change the statuses of the Broadcasts:
                <br />
                Scheduled -{'>'} Draft <br />
                Sending -{'>'} Paused
              </>
            }
            subTitle={'with Transfer Call'}
            enabled={enabledBroadcasts}
            onToggle={setEnabledBroadcasts}
          />
        </div>

        <div className={'row12 mt40'}>
          <Button
            size={BtnSize.medium}
            onClick={onDisable}
            typeBtn={BtnType.primary}
            disabled={disabledContinue}
            loading={loading}
          >
            Disable Auto-Recharge
          </Button>
          <Button
            size={BtnSize.medium}
            onClick={onClose}
            typeBtn={BtnType.secondaryGray}
          >
            Back
          </Button>
        </div>
      </div>
    </Modal>
  )
})
