import { observer } from 'mobx-react-lite'
import { PrimaryCardDropdown } from 'src/pages/settings/billing/components/PrimaryCardDropdown'
import { SizedBox } from 'components/SizedBox'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import { Dispatch, SetStateAction } from 'react'
import { Currencies, numberString } from 'src/util/functions'

export const mockBillings = [
  {
    title: '1 month',
    cost: 5,
  },
  {
    title: '2 months',
    cost: 10,
  },
  {
    title: '3 months',
    cost: 15,
  },
]

export const Step0 = observer(
  ({
    selectedBilling,
    setSelectedBilling,
    selectedValue,
    setSelectedValue,
    onFinish,
  }: {
    selectedBilling: (typeof mockBillings)[0]
    setSelectedBilling: Dispatch<SetStateAction<(typeof mockBillings)[0]>>
    selectedValue: string
    setSelectedValue: (card: string) => void
    onFinish: () => void
  }) => {
    return (
      <div>
        <span className={'s2 medium gray1 mb8'}>Billing</span>
        <div className={'col8'}>
          {mockBillings.map((billing) => (
            <InputCheckbox
              key={billing.title}
              label={billing.title}
              checked={billing.title === selectedBilling.title}
              onChecked={() => setSelectedBilling(billing)}
              shape={'radio'}
              size={'small'}
              rightIcon={
                <span className={'bold'}>
                  {numberString({
                    val: billing.cost,
                    currency: Currencies.usd,
                  })}
                </span>
              }
            />
          ))}
        </div>

        <SizedBox height={40} />
        <PrimaryCardDropdown
          selectedValue={selectedValue}
          onSelectValue={setSelectedValue}
          onFinish={onFinish}
        />
        <SizedBox height={100} />
      </div>
    )
  }
)
