import { Navigate, Route, Routes } from 'react-router-dom'
import { AuthPage } from 'components/AuhPage/AuthPage'
import { NoCompleteRoutes } from 'src/routes/routes'
import { observer } from 'mobx-react-lite'
import React from 'react'
import configStore from 'store/configStore'
import { RegisterCompanyInfoB } from 'src/routes/auth/signUp/registerFlowB/RegisterCompanyInfoB/RegisterCompanyInfoB'
import { RegisterMemberInfoB } from 'src/routes/auth/signUp/registerFlowB/RegisterMemberInfoB'
import { RegisterMemberInfoA } from 'src/routes/auth/signUp/registerFlowA/RegisterMemberInfoA'
import { RegisterCompanyInfoA } from 'src/routes/auth/signUp/registerFlowA/RegisterCompanyInfoA'
import { RegisterBLayout } from 'src/routes/auth/signUp/registerFlowB/layout/RegisterBLayout'

export const NoCompleteRegister = observer(() => {
  return (
    <Routes>
      <Route element={<AuthPage />}>
        {configStore.signUpFlow === 'B' ? (
          <Route element={<RegisterBLayout />}>
            <Route
              path={NoCompleteRoutes.register1}
              element={<RegisterCompanyInfoB />}
            />
            <Route
              path={NoCompleteRoutes.register2}
              element={<RegisterMemberInfoB />}
            />
          </Route>
        ) : (
          <Route>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <Route
              path={NoCompleteRoutes.register1}
              element={<RegisterMemberInfoA />}
            />
            <Route
              path={NoCompleteRoutes.register2}
              element={<RegisterCompanyInfoA />}
            />
          </Route>
        )}
        <Route
          path="*"
          element={<Navigate to={NoCompleteRoutes.register1} />}
        />
      </Route>
    </Routes>
  )
})
