import { Card } from 'components/Card/Card'
import { SignUp } from 'src/pages/auth/signUp/signUp'

export const CreateAccountStep = () => {
  return (
    <Card width={480} margin={0}>
      <SignUp />
    </Card>
  )
}
