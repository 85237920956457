import { makeAutoObservable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'

class UiStore {
  minRootHeight = 0
  triggerHeight = ''
  triggerUpdate = ''
  currentPage: HTMLDivElement | null = null
  constructor() {
    this.isMobile = window.innerWidth <= 800 || window.innerHeight <= 600
    if (this.isMobile) {
      document.body.style.setProperty('--page-height', 'calc(100vh - 82px)')
      this.hideMenu()
    } else {
      this.openMenu()
    }
    window.addEventListener('resize', () => {
      runInAction(() => {
        const width = document.documentElement.clientWidth
        const height = document.documentElement.clientHeight
        this.isMobile = width <= 800 || height <= 600
        if (this.isMobile) {
          this.hideMenu()
        } else {
          this.openMenu()
        }
      })
    })
    makeAutoObservable(this)
  }

  isMobile = window.innerWidth <= 800 || window.innerHeight <= 600
  visibleSidebar = true
  mobileTitle = ''

  loadingRoute = ''
  isBeforeUnload = false

  openCancelCreateModal = false

  nextRoute = ''
  loadingContentMap: Map<string, boolean> = new Map<string, boolean>()

  mobileRightBtn: JSX.Element | null = null
  closeAction: (() => void) | null = null
  mobileActions: JSX.Element | null = null
  isPreview = false

  setMinRootHeight(val = 0) {
    this.minRootHeight = val
  }
  setTriggerHeight() {
    this.triggerHeight = nanoid()
  }
  setTriggerUpdate() {
    this.triggerUpdate = nanoid()
  }
  setCurrentPage(page: HTMLDivElement) {
    this.currentPage = page
  }
  setOpenCancelCreateModal(state: boolean) {
    if (state) {
      if (this.isBeforeUnload) {
        this.openCancelCreateModal = state
      }
    } else {
      this.openCancelCreateModal = state
    }
  }
  setNextRoute(nextRoute = '') {
    this.nextRoute = nextRoute
  }
  setLoadingRoute(loadingRoute = '') {
    this.loadingRoute = loadingRoute
  }
  setLoadingContent([key, value]: [string, boolean]) {
    this.loadingContentMap.set(key, value)
  }
  setBeforeUnload(value: boolean) {
    this.isBeforeUnload = value
  }
  toggleMenu() {
    if (this.visibleSidebar) {
      this.hideMenu()
    } else {
      this.openMenu()
    }
  }
  openMenu = () => {
    this.visibleSidebar = true
  }

  hideMenu = () => {
    this.visibleSidebar = false
  }

  setMobilePage(title: string) {
    this.mobileTitle = title
  }
  setMobileRightBtn(btn: typeof this.mobileRightBtn) {
    this.mobileRightBtn = btn
  }
  setCloseAction(fn: typeof this.closeAction) {
    this.closeAction = fn
  }
  setMobileActions(actions: JSX.Element | null) {
    this.mobileActions = actions
  }
  setIsPreview(value: boolean) {
    this.isPreview = value
  }
}

export default new UiStore()
