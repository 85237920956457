import * as React from 'react'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SmallBtn } from 'components/Button/SmallBtn'
import { ActionsEnum } from 'components/Button/types'
import { onRenameTableCell } from 'components/NewTable/functions'
import { HoverRowComponentProps } from 'components/NewTable/components/RowComponent'
import { useListsFunctions } from 'src/pages/main/contacts/contctLists/hooks/useListsFunctions'
import { FixedTooltip } from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { ClassesEnum } from 'styles/classes'
import { IListResponse } from 'src/types/IContactList'
import { ListExportBtn } from 'src/pages/main/contacts/contctLists/components/ListExportBtn'

export const HoverRowComponent = observer(
  ({ row, tr }: HoverRowComponentProps<IListResponse>) => {
    const [createListError, setCreateListError] = useState('')
    const {
      onUpdate,
      // onExport,
      // onValidate,
      onDeleteLists,
    } = useListsFunctions()
    const onRename = () => {
      onRenameTableCell({
        tr,
        row,
        onSave: async (id, text) => {
          onUpdate(id, text, setCreateListError)
        },
        setCreateListError,
      })
    }
    // const handleValidate = () => {
    //   onValidate([row.id])
    // }
    useEffect(() => {
      if (createListError) {
        onRenameTableCell({
          tr,
          row,
          onSave: async (id, text) => {
            onUpdate(id, text, setCreateListError)
          },
          error: 'List with this name already exists',
          setCreateListError,
        })
      }
    }, [createListError])
    return (
      <>
        {createListError && (
          <FixedTooltip
            visible={!!createListError}
            trigger={tr?.children[1] as HTMLDivElement}
            text={'List with this name already exists'}
            globalClasses={[ClassesEnum.p4]}
          />
        )}

        <SmallBtn
          type={ActionsEnum.edit}
          onClick={onRename}
          tooltip={'Rename'}
        />
        <ListExportBtn row={row} />
        {/*<SmallBtn*/}
        {/*  type={ActionsEnum.validatePhone}*/}
        {/*  onClick={handleValidate}*/}
        {/*  // tooltip={'Rename'}*/}
        {/*/>*/}
        {/*<SmallBtn*/}
        {/*  type={ActionsEnum.export}*/}
        {/*  onClick={() => onExport([row.id])}*/}
        {/*/>*/}
        <SmallBtn
          type={ActionsEnum.delete}
          onClick={() => onDeleteLists([row.id])}
        />
      </>
    )
  }
)
