import styles from 'components/Schedule/styles.module.scss'
import { ScheduleBtn } from 'components/Schedule/ScheduleBtn'
import { CampaignDelayType } from 'src/generated/graphql'
import { observer } from 'mobx-react-lite'
import { useCampaignScheduleContext } from 'src/widgets/CampaignSchedule'
import { CalendarDateContent } from 'src/widgets/CampaignSchedule/ui/CalendarDateCard/CalendarDateContent/CalendarDateContent'

export const CalendarDateCard = observer(() => {
  const { activeCalendarDate, setSendTypeCampaign } =
    useCampaignScheduleContext()
  return (
    <div className={styles.row}>
      <ScheduleBtn
        active={activeCalendarDate}
        title={'Calendar Date'}
        onClick={() => {
          setSendTypeCampaign(CampaignDelayType.Date)
        }}
      />
      <CalendarDateContent />
    </div>
  )
})
