import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as Star } from 'icons/16pxNoMask/Star.svg'
import billingStore from 'store/settings/billing/billingStore'
import { observer } from 'mobx-react-lite'
import { numberString } from 'src/util/functions'
import { CreditsProgressBar } from 'src/pages/settings/billing/components/CreditsContent/CreditsProgressBar'
import { AutoRechargeCard } from 'components/AutoRecahrge/AutoRechargeCard'
import { openBuyCreditsModal } from 'store/settings/billing/actions'

export const CreditsContent = observer(() => {
  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <div className={styles.wrapLeft}>
          <div className={'row8'}>
            <Star />
            <span>
              <b>Credits</b> -{' '}
              {numberString({ val: billingStore.currentCredits })}
            </span>
          </div>
          <div className={styles.wrapBar}>
            <CreditsProgressBar />
          </div>
        </div>

        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          onClick={() => openBuyCreditsModal(true)}
        >
          Buy credits
        </Button>
      </div>
      <AutoRechargeCard onlyContent />
    </div>
  )
})
