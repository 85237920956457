// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.u6HjU6T1uTKyXtGJQSKu{display:grid;grid-template-columns:1fr 1fr;gap:16px}.u6HjU6T1uTKyXtGJQSKu>div:first-child{grid-column:-1/1}`, "",{"version":3,"sources":["webpack://./src/pages/settings/account/compponents/AdreesBlock/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,QAAA,CACA,sCACE,gBAAA","sourcesContent":[".wrap{\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 16px;\n  & > div:first-child{\n    grid-column: -1/1;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `u6HjU6T1uTKyXtGJQSKu`
};
export default ___CSS_LOADER_EXPORT___;
