import { AudioType } from 'src/generated/graphql'
import { ActionsEnum } from 'components/Button/types'
import { IAudioItem } from 'src/types/IAudioItem'

export function getActionsByType(item: IAudioItem): ActionsEnum[] {
  if (item.type === AudioType.Tts && item.ttsMessage) {
    const isUsage =
      item.usageStatistics?.broadcastCount ||
      item.usageStatistics?.campaignCount ||
      item.usageStatistics?.triggerCount
    if (isUsage) {
      return [
        // ActionsEnum.testAudio,
        ActionsEnum.rename,
        ActionsEnum.download,
        ActionsEnum.duplicate,
        ActionsEnum.delete,
      ]
    }
    return [
      // ActionsEnum.testAudio,
      ActionsEnum.edit,
      ActionsEnum.download,
      ActionsEnum.duplicate,
      ActionsEnum.delete,
    ]
  }
  return [
    // ActionsEnum.testAudio,
    ActionsEnum.rename,
    ActionsEnum.copy,
    ActionsEnum.download,
    ActionsEnum.delete,
  ]
}
