import { FC, ReactNode } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { CheckBox, ICheckBoxProps } from 'components/CheckBox/CheckBox'
import {
  InfoTooltip,
  InfoTooltipProps,
} from 'components/InfoTooltip/InfoTooltip'
import { ITypographyProps, Typography } from 'shared/Typography'
import { NewLabel } from 'src/shared/NewLabel/NewLabel'

type InputCheckboxProps = {
  infoTooltipProps?: InfoTooltipProps
  title: ReactNode
  typographyProps?: ITypographyProps
  isNew?: boolean
  className?: string
} & ICheckBoxProps

export const NewInputCheckbox: FC<InputCheckboxProps> = ({
  size,
  checked,
  disabled,
  shape,
  title,
  infoTooltipProps,
  typographyProps,
  isNew,
  className,
  onChecked,
}) => {
  return (
    <button className={classNames(styles.wrap, className)} onClick={onChecked}>
      <CheckBox
        size={size}
        checked={checked}
        disabled={disabled}
        shape={shape}
      />
      <Typography {...typographyProps}>{title}</Typography>
      {infoTooltipProps && (
        <div onClick={(event) => event.stopPropagation()} aria-hidden>
          <InfoTooltip {...infoTooltipProps} marginLeft={-4} />
        </div>
      )}
      {isNew && <NewLabel />}
    </button>
  )
}
