import { IListItem, ListItem } from 'components/SelectList/ListItem/ListItem'
import styles from './styles.module.scss'
import { ReactNode } from 'react'
import { InView } from 'react-intersection-observer'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'

export type SelectListProps<T = any, ID = any> = {
  options: Array<IListItem<T>>
  onSelect: (id: ID, item: IListItem<T>) => void
  withoutMark?: boolean
  isShowLoadingMore?: boolean
  handleLoadMore?: () => void
  bottomContent?: ReactNode
}

export const SelectList = ({
  options,
  onSelect,
  withoutMark,
  bottomContent,
  isShowLoadingMore,
  handleLoadMore,
}: SelectListProps) => {
  return (
    <div className={styles.wrap}>
      {!!options.length && (
        <div className={styles.list}>
          {options.map((item) => (
            <ListItem
              key={item.id}
              item={item}
              onSelect={onSelect}
              withoutMark={withoutMark}
            />
          ))}
          <InView onChange={(inView) => inView && handleLoadMore?.()}>
            {({ ref }) => (
              <div ref={ref}>
                {isShowLoadingMore && (
                  <div className={styles.wrapLoader}>
                    <AbsoluteLoader size={16} />
                  </div>
                )}
              </div>
            )}
          </InView>
        </div>
      )}

      {bottomContent && (
        <div className={styles.wrapBottom}>{bottomContent}</div>
      )}
    </div>
  )
}
