import { ITrigger } from 'src/types/ITrigger'
import { AppRender } from 'src/routes/main/triggers/TriggersTable/AppRender'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { ContentType, Status } from 'src/generated/graphql'
import { TypeContent } from 'components/TypeContent/TypeContent'
import { numberString } from 'src/util/functions'
import { ColumnType } from 'components/NewTable/types'
import dayjs, { DayjsFormats } from 'lib/dayjs'

export const triggersTableColumns: ColumnType<ITrigger>[] = [
  { id: 'name', label: 'Name' },
  {
    id: 'app',
    label: 'App',
    format: (value, { appOption: { iconClass, leftIcon, title } }) => (
      <AppRender title={title} leftIcon={leftIcon} iconClass={iconClass} />
    ),
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 143,
    format: (value) => <StatusContent status={value as Status} />,
  },
  {
    id: 'type',
    label: 'Type',
    minWidth: 106,
    format: (value) => <TypeContent type={value as ContentType} />,
  },
  {
    id: 'createdAt',
    label: 'Created',
    format: (value) => dayjs(value as Date).format(DayjsFormats.fullWithAt),
  },
  {
    id: 'messagesStat.total',
    label: 'Total',
    format: (value, { messagesStat }) =>
      numberString({ val: +messagesStat?.totalCount || 0 }),
  },
  {
    id: 'messagesStat.success',
    label: 'Success',
    format: (value, { messagesStat }) =>
      numberString({ val: +messagesStat?.successCount }),
  },
  {
    id: 'shortUrlClickStatistics',
    label: 'Clicks',
    format: (value, { shortUrlClickStatistics }) =>
      numberString({ val: shortUrlClickStatistics?.totalVisits || 0 }),
  },

  {
    id: 'messagesStat.pending',
    label: 'Pending',

    format: (value, { messagesStat }) =>
      numberString({ val: +messagesStat?.pendingCount }),
  },
  {
    id: 'messagesStat.failed',
    label: 'Failed',
    format: (value, { messagesStat }) =>
      numberString({ val: +messagesStat?.failedCount }),
  },
  {
    id: 'messagesStat.unsubscribed',
    label: 'Unsubscribed',
    minWidth: 100,

    format: (value, { messagesStat }) =>
      numberString({ val: +messagesStat?.unsubscribedCount }),
  },
]

export const noSortsColumns: string[] = [
  'type',
  'shortUrlClickStatistics',
  'messagesStat.total',
  'messagesStat.success',
  'messagesStat.unsubscribed',
  'messagesStat.pending',
  'messagesStat.failed',
]
