import { observer } from 'mobx-react-lite'
import NewTable from 'components/NewTable/NewTable'
import { IPhoneNumber } from 'src/types/IPhoneNumber'
import numbersStore from 'store/settings/numbers/numbersStore'
import { EmptyState } from 'components/EmptyState/EmptyState'
import * as React from 'react'
import addNumberStore from 'store/settings/numbers/addNumberStore'
import { LeftStickyContent } from 'src/pages/settings/numbers/LeftStickyContent/LeftStickyContent'
import { getNumbersActions } from 'src/pages/settings/numbers/actions/getNumbersActions'
import { useGetNumbersPageQuery } from 'src/generated/graphql'
import { useSetData } from 'src/hooks/useSetData'
import { EmptyStateSearch } from 'components/EmptyState/EmptyStateSearch'
import { noSortsColumnsNumbers } from 'components/NewTable/columns/numbersTableColumns'
import { AppElements } from 'src/enums/appElements'

export const NumbersTable = observer(() => {
  const tableStore = numbersStore.tableStore
  const sort =
    tableStore.paginationInput.sort?.orders &&
    tableStore.paginationInput.sort.orders[0]?.property === 'region'
      ? {
          ...tableStore.paginationInput.sort,
          orders: [
            { ...tableStore.paginationInput.sort.orders[0], property: 'state' },
            { ...tableStore.paginationInput.sort.orders[0], property: 'city' },
          ],
        }
      : tableStore.paginationInput.sort
  const queryResult = useGetNumbersPageQuery({
    variables: {
      page: {
        ...tableStore.paginationInput,
        sort,
      },
    },
  })

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData: (data) => numbersStore.setNumbersPage(data),
    refreshDeps: [tableStore.refreshTrigger],
  })
  if (!loading && !numbersStore.numbersPage.length && !tableStore.search) {
    return (
      <EmptyState
        createText={'Add'}
        element={AppElements.Numbers}
        description={'Use numbers to send SMS messages'}
        onCreate={() => addNumberStore.setOpenAddNumberModal(true)}
      />
    )
  }
  return (
    <NewTable<IPhoneNumber>
      withCheckbox
      rows={numbersStore.numbersPage.map((number) => ({
        ...number,
        actions: getNumbersActions(number),
      }))}
      emptyContent={
        !numbersStore.numbersPage.length ? (
          <EmptyStateSearch element={AppElements.Numbers} />
        ) : undefined
      }
      tableStore={numbersStore.tableStore}
      columns={numbersStore.tableStore.visibleColumns}
      withCalcMaxHeight
      withSearch
      leftStickyContent={<LeftStickyContent />}
      pageLoading={loading || refetchLoading}
      firstLoading={firstLoading}
      noSortsColumns={noSortsColumnsNumbers}
    />
  )
})
