import { observer } from 'mobx-react-lite'
import { useSetMember } from 'src/pages/settings/account/hooks/useSetMember'
import { useEffect, useMemo, useState } from 'react'
import { useCreditCard } from 'src/widgets/CreditCard/useCreditCard'
import {
  useStartTrialMutation,
  useUpdateOrganizationMutation,
} from 'src/generated/graphql'
import { useNavigate } from 'react-router-dom'
import { useUpdateMemberLead } from 'src/hooks/useUpdateMemberLead'
import user from 'store/user/user'
import billingStore from 'store/settings/billing/billingStore'
import { NoCompleteRoutes } from 'src/pages/routes'
import { Token } from '@stripe/stripe-js'
import mainStore from 'store/mainStore'
import { nanoid } from 'nanoid'
import { SizedBox } from 'components/SizedBox'
import { RangeBar } from 'components/RangeBar/RangeBar'
import { MemberInfo } from 'src/widgets/MemberInfo/MemberInfo'
import { CreditCard } from 'src/widgets/CreditCard/CreditCard'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { FooterRegister } from 'src/pages/auth/components/FooterRegister'
import auth from 'store/auth'
import companyStore from 'store/settings/company/companyStore'
import { useHandleMemberRegisterError } from 'src/pages/auth/signUp/helper'
import { useRegisterHubspotMember } from 'src/hooks/useRegisterHubspotMember'
import { ApolloError } from '@apollo/client'
import alertStore from 'store/alertStore'
import { useRegisterContext } from 'src/pages/auth/signUp/registerFlowB/context/context'

export const RegisterMemberInfoB = observer(() => {
  useSetMember()
  const registerStore = useRegisterContext()
  const [loading, setLoading] = useState(false)
  const [updateOrganization] = useUpdateOrganizationMutation()

  const creditCardProps = useCreditCard({ setLoading })

  const { errorAlert, readyCard, setCardError, refBtn } = creditCardProps

  const [startTrial] = useStartTrialMutation()

  const navigate = useNavigate()

  const [info, setInfo] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
  })
  const { firstName, lastName, phone } = info
  const { handleError, phoneError, clearPhoneError } =
    useHandleMemberRegisterError(setCardError)

  useUpdateMemberLead(phone, firstName, lastName)
  useRegisterHubspotMember()

  useEffect(() => {
    if (!user.member?.registrationFinished && billingStore.activeSubscription) {
      navigate(NoCompleteRoutes.register2)
    }
  }, [user.member?.registrationFinished, billingStore.activeSubscription])

  const onAction = async (token: Token) => {
    const tokenId = token.id

    try {
      const res = await startTrial({
        variables: {
          tokenId,
          member: {
            phone,
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            zipCode: token.card?.address_zip,
          },
        },
      })
      if (res.data?.startTrial?.subscription) {
        const { data } = await updateOrganization({
          variables: {
            organization: {
              companyName: registerStore.companyName.trim(),
            },
          },
        })
        if (data?.updateOrganization) {
          auth.setRefetchMainInfo()
          auth.setCompleteRegisterTrigger(nanoid())
          companyStore.setOrganization(data?.updateOrganization)
          mainStore.updateAnalyticTrigger()
        }
      }
    } catch (e) {
      if (e instanceof ApolloError) {
        const userMessage = e?.graphQLErrors[0].extensions.userMessage as string
        if (userMessage) {
          setCardError(userMessage)
          alertStore.disabledErrorAlert(e.message)
        } else {
          handleError(e.message)
        }
      }
    } finally {
      setLoading(false)
    }
  }

  const onContinue = async () => {
    setLoading(true)
    if (refBtn.current) {
      refBtn.current?.click()
    }
  }
  const isDisabled = useMemo(() => {
    if (!readyCard) {
      return true
    }

    return (
      !firstName.trim() || !lastName.trim() || !phone || phone.includes('_')
    )
  }, [
    billingStore.showNewCard,
    billingStore.readyNewCard,
    readyCard,
    firstName,
    lastName,
    phone,
  ])

  return (
    <>
      <h2>What is your name?</h2>
      <SizedBox height={8} />
      <p className={'par2 gray1 medium mb20'}>
        Just one more step from creating your account
      </p>
      <RangeBar progress={100} style2 />

      <SizedBox height={32} />
      {errorAlert}
      <MemberInfo
        info={info}
        setInfo={setInfo}
        disabledFields={['email']}
        phoneError={phoneError}
        clearPhoneError={clearPhoneError}
      />
      <SizedBox height={32} />
      <CreditCard
        {...creditCardProps}
        setLoading={setLoading}
        onAction={onAction}
        onlyCreate
      />

      <div className={'mt40 '}>
        <Button
          size={BtnSize.large}
          typeBtn={BtnType.primary}
          onClick={onContinue}
          disabled={isDisabled}
          loading={loading}
        >
          Start my free trial
        </Button>
        <SizedBox height={16} />
        <FooterRegister
          text={
            'Cancel anytime. You will not be charged until your trial ends.'
          }
        />
      </div>
    </>
  )
})
