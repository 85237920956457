import React from 'react'
import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { WeekDaysSelectorStore } from 'src/widgets/WeekDaysSelector/store/weekDaysSelectorStore'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { DayOfWeek } from 'src/generated/graphql'

type IDaysSelectorProps = {
  store: WeekDaysSelectorStore
}

export const WeekDaysSelector = observer(({ store }: IDaysSelectorProps) => {
  const { daysItems, onToggleDay } = store
  return (
    <div className={styles.wrap}>
      <span className={'s2 medium gray1'}>Send on these days only</span>
      <div className={styles.wrapDays}>
        {daysItems.map((item) => (
          <Button
            key={item.id}
            size={BtnSize.small}
            typeBtn={item.activeValue ? BtnType.primary : BtnType.secondaryGray}
            className={styles.dayBtn}
            onClick={() => onToggleDay(item.id as DayOfWeek)}
          >
            {item.label}
          </Button>
        ))}
      </div>
    </div>
  )
})
