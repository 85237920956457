import { ActionBtnProps } from 'components/NewTable/types'
import { IBroadcast } from 'src/types/IBroadcast'
import * as React from 'react'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import { useBroadcastFunctions } from 'src/pages/main/broadcasts/hooks/useBroadcastFunctions'

export const BroadcastEditBtn = (props: ActionBtnProps<IBroadcast>) => {
  const { row, rows } = props
  const { onEdit } = useBroadcastFunctions(row, rows)
  return <TableBtn {...props} action={ActionsEnum.edit} onAction={onEdit} />
}
