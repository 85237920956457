import { observer } from 'mobx-react-lite'
import NewTable from 'components/NewTable/NewTable'
import { useConversionsTableContext } from 'widgets/conversions/ConversionsTable/context/context'
import { useGetConversionsQuery } from 'src/generated/graphql'
import * as React from 'react'
import { useSetData } from 'src/hooks/useSetData'
import { EmptyState } from 'src/pages/settings/conversions/conversions/ui/emptyState/EmptyState'
import { RangeDatePicker } from 'components/RangeDatePicker/RangeDatePicker'
import { AdditionalContent } from 'widgets/conversions/ConversionsTable/ui/AdditionalContent/AdditionalContent'
import { DeleteBtn } from 'widgets/conversions/ConversionsTable/actions/DeleteBtn'
import { EmptyStateSearch } from 'components/EmptyState/EmptyStateSearch'
import { EditBtn } from 'widgets/conversions/ConversionsTable/actions/EditBtn'
import { useNavigate } from 'react-router-dom'

export const ConversionsTableUi = observer(() => {
  const navigate = useNavigate()
  const { tableStore, setData, isEmpty, items, onSuccessDelete, updateItem } =
    useConversionsTableContext()

  const queryResult = useGetConversionsQuery({
    variables: {
      page: tableStore.paginationInput,
      searchPattern: tableStore.search,
      dateRange: tableStore.rangeDatePickerStore.dateRange,
    },
  })

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData,
    refreshDeps: [tableStore.refreshTrigger],
  })

  if (isEmpty) {
    return <EmptyState />
  }

  const rows = items.map((item) => {
    return {
      ...item,
      actions: [
        () => EditBtn({ conversion: item, onSuccess: updateItem }),
        () => DeleteBtn({ ids: [item.id], onSuccess: onSuccessDelete }),
      ],
    }
  })

  const onRowClick = (id: string) => {
    navigate(String(id))
  }

  return (
    <NewTable
      tableStore={tableStore}
      withSearch
      rows={rows}
      columns={tableStore.visibleColumns}
      withCalcMaxHeight
      pageLoading={loading || refetchLoading}
      firstLoading={firstLoading}
      onRowClick={onRowClick}
      additionalContent={<AdditionalContent />}
      leftStickyContent={
        <RangeDatePicker
          rangeDatePickerStore={tableStore.rangeDatePickerStore}
        />
      }
      emptyContent={
        !rows.length ? <EmptyStateSearch element={'conversion'} /> : undefined
      }
    />
  )
})
