import styles from './styles.module.scss'
import { useNavigate } from 'react-router-dom'
import { BtnSize, Button } from 'components/Button/Button'
import { ReactComponent as Angle } from 'icons/16px/Angle-Left.svg'
import { ReactComponent as Star } from 'icons/16pxNoMask/Star.svg'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { TypeContent } from 'components/TypeContent/TypeContent'
import { observer } from 'mobx-react-lite'
import { ContentType, Status } from 'src/generated/graphql'
import { useTriggersDetailContext } from 'src/pages/main/triggers/detail/triggerDetail'
import { TriggerDetailModal } from 'src/pages/main/triggers/modals/TriggerDetailModal'
import { getTriggerBtnActions } from 'src/pages/main/triggers/functions/getTriggerBtnActions'
import { MoreBtn, MoreBtnItemProps } from 'components/Button/MoreBtn'
import * as React from 'react'
import { FC } from 'react'
import { BreadCrumbs } from 'components/BreadCrumbs/BreadCrumbs'

export const HeaderContent = observer(() => {
  const navigate = useNavigate()
  const { trigger, totalCharged } = useTriggersDetailContext()
  if (!trigger) {
    return <></>
  }
  const actions = getTriggerBtnActions(trigger)

  const btnCount = trigger.status === Status.Archived ? 2 : 3

  const rowActions = actions.slice(0, btnCount)
  const moreActions = actions.slice(btnCount)
  return (
    <div className={styles.header}>
      <TriggerDetailModal />
      <BreadCrumbs />
      <div className={styles.nav}>
        <div className={styles.left}>
          <div>
            <Button hoverGray size={BtnSize.small} onClick={() => navigate(-1)}>
              <Angle />
            </Button>
          </div>
          <h1>{trigger?.name}</h1>
        </div>
        <div className={styles.right}>
          {rowActions.map((Action, index) => (
            <Action key={index} medium />
          ))}
          {!!moreActions?.length && (
            <MoreBtn
              menuItems={moreActions as Array<FC<MoreBtnItemProps>>}
              tooltip={'More'}
              medium
            />
          )}
        </div>
      </div>
      <div className={styles.rowInfo}>
        <StatusContent status={trigger?.status} />
        <div className={'point'} />
        <TypeContent type={trigger?.type || ContentType.Sms} />
        <div className={'point'} />
        <div className={styles.creditCount}>
          <Star />
          <span>{totalCharged} credits</span>
        </div>
      </div>
    </div>
  )
})
