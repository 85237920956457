import styles from './styles.module.scss'
import NewTable from 'components/NewTable/NewTable'
import { observer } from 'mobx-react-lite'
import { Filters } from 'src/pages/main/contacts/ContactsTable/Filters/Filters'
import { AdditionalContent } from 'src/pages/main/contacts/ContactsTable/AdditionalContent/AdditionalContent'
import contactStore from 'store/contacts/contactStore'
import { useParams } from 'react-router-dom'
import { runInAction } from 'mobx'
import manualContactStore from 'store/contacts/manualContactStore'
import { TableStatuses, TableStore } from 'components/NewTable/store/TableStore'
import * as React from 'react'
import { CSSProperties, ReactNode, useEffect } from 'react'
import { EmptyStateContacts } from 'src/pages/main/contacts/ContactsTable/EmptyStateContacts'
import { noSortsColumns } from 'components/NewTable/columns/contacts/contactColums'
import { EmptyStateStatistic } from 'components/EmptyState/EmptyStateStatistic'
import { IContact } from 'src/types/IContact'
import { useGetMyContactLazyQuery } from 'src/generated/graphql'
import { transformContact } from 'store/contacts/functions'
import { TableProps } from 'components/NewTable/types'
import { BtnSize } from 'components/Button/Button'
import { useGetOrganizationLists } from 'src/pages/main/contacts/contctLists/hooks/useGetOrganizationLists'
import { getContactActions } from 'src/pages/main/contacts/functions/getContactActions'
import configStore from 'store/configStore'

export const ContactsTable = observer(
  ({
    pageLoading,
    firstLoading,
    tableStore,
    withSearch,
    rightStickyContent,
    noSelectContent,
    noAdditionalContent,
    extraBtnSize,
    overrideWrapStickyClass,
    minHeight,
    triggerHeight,
    withoutEmptyState,
    noSortColumnsProps = [],
  }: {
    pageLoading: boolean
    firstLoading: boolean
    withSearch?: boolean
    noSelectContent?: ReactNode
    noAdditionalContent?: boolean
    rightStickyContent?: TableProps<IContact>['rightStickyContent']
    triggerHeight?: TableProps<IContact>['triggerHeight']
    tableStore: TableStore<IContact>
    extraBtnSize?: BtnSize
    overrideWrapStickyClass?: string
    minHeight?: CSSProperties['minHeight']
    withoutEmptyState?: boolean
    noSortColumnsProps?: string[]
  }) => {
    const [getMyContact] = useGetMyContactLazyQuery()
    useGetOrganizationLists()
    const onRowClick = (id: string) => {
      getMyContact({
        variables: {
          contactId: id,
        },
        fetchPolicy: 'network-only',
      })
        .then(({ data }) => {
          if (data?.getMyContact) {
            manualContactStore.setEditContact(
              transformContact(data.getMyContact)
            )
          }
        })
        .catch(console.error)
    }
    const { id } = useParams()
    runInAction(() => {
      contactStore.detailContactId = id || ''
    })

    const rows = contactStore.contacts.map((item) => ({
      ...item,
      actions: getContactActions({
        row: item,
      }),
    }))

    useEffect(() => {
      return () => {
        contactStore.tableStoreContacts.reset()
      }
    }, [])

    if (
      !withoutEmptyState &&
      !firstLoading &&
      !rows.length &&
      !tableStore.search.trim() &&
      contactStore.tableStoreContacts.status === TableStatuses.totalCount
    ) {
      return <EmptyStateContacts />
    }

    return (
      <NewTable
        noSortsColumns={[...noSortsColumns, ...noSortColumnsProps]}
        withCheckbox
        withCalcMaxHeight
        showContent={
          contactStore.tableStoreContacts.status !== TableStatuses.totalCount
        }
        columns={contactStore.tableStoreContacts.visibleColumns}
        rows={rows}
        leftStickyContent={
          <Filters
            noSelectContent={noSelectContent}
            extraBtnSize={extraBtnSize}
          />
        }
        withSearch={withSearch}
        onRowClick={onRowClick}
        additionalContent={
          noAdditionalContent ? undefined : (
            <AdditionalContent
              tableStore={contactStore.tableStoreContacts}
              listId={id}
            />
          )
        }
        emptyContent={
          rows.length ? undefined : withoutEmptyState ? (
            <div />
          ) : (
            <EmptyStateStatistic description={'No contacts matching'} />
          )
        }
        minHeight={minHeight}
        tableStore={contactStore.tableStoreContacts}
        pageLoading={pageLoading}
        firstLoading={firstLoading}
        rightStickyContent={rightStickyContent}
        overrideWrapStickyClass={overrideWrapStickyClass}
        triggerHeight={triggerHeight}
        actionsSize={5}
        withSelectAll={configStore.isContactAllByPostHog}
      />
    )
  }
)
