import { observer } from 'mobx-react-lite'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { Link } from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'
import { Alert } from 'components/Alert/Alert'
import integrationsStore from 'store/settings/integrationsStore'

export const IntegrationDisconnectAlert = observer(() => {
  return (
    <>
      {integrationsStore.invalidatedIntegrations.map((integration) => {
        return (
          <Alert
            key={integration.type}
            title={`Uh oh. Your connection to ${integration.type} was disconnected. `}
            type={AlertTypeEnum.error}
            global
            rightContent={
              <Link to={RoutesEnum.settingsIntegrations}>
                <span className={'bold white'}>
                  Please re-connect {integration.type}.
                </span>
              </Link>
            }
          />
        )
      })}
    </>
  )
})
