// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.me15Ag91yHGFwP4IUIfA{height:100vh;background-color:#fff;display:grid;grid-template-rows:min-content 1fr}`, "",{"version":3,"sources":["webpack://./src/pages/main/contacts/segments/DetailSegment/Layout/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,kCAAA","sourcesContent":[".wrap{\n  height: 100vh;\n  background-color: white;\n  display: grid;\n  grid-template-rows: min-content 1fr;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `me15Ag91yHGFwP4IUIfA`
};
export default ___CSS_LOADER_EXPORT___;
