// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ho8XZwjvLrifuNgBqBv8{margin-top:12px;gap:16px;display:flex}.ho8XZwjvLrifuNgBqBv8 span{line-height:20px}.G0De2lyPA31geS_n0LnX{margin-top:4px;display:flex}.G0De2lyPA31geS_n0LnX span{line-height:20px}`, "",{"version":3,"sources":["webpack://./src/pages/auth/signUp/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,QAAA,CACA,YAAA,CACA,2BACE,gBAAA,CAGJ,sBACE,cAAA,CACA,YAAA,CACA,2BACE,gBAAA","sourcesContent":[".wrapUse{\n  margin-top: 12px;\n  gap: 16px;\n  display: flex;\n  span{\n    line-height: 20px;\n  }\n}\n.wrapLink{\n  margin-top: 4px;\n  display: flex;\n  span{\n    line-height: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapUse": `ho8XZwjvLrifuNgBqBv8`,
	"wrapLink": `G0De2lyPA31geS_n0LnX`
};
export default ___CSS_LOADER_EXPORT___;
