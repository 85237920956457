// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fceuAY77ggqEwa7L0ENe{margin-top:32px;position:relative;border:1px solid var(--primary-color-gray-3);border-radius:12px}.I8k7vPBsGfDLBlhB6g4Q{position:absolute;top:-10px;left:16px;padding:2px 8px;display:flex;background-color:var(--primary-color-blue-1);border-radius:8px}.RxrexFGbSO7gxjO4XyJy{padding:0 24px;display:flex;justify-content:space-between;align-items:center;height:86px}.RxrexFGbSO7gxjO4XyJy .y_v_o9KsXrbTUMmsNtHV{display:grid;row-gap:4px}.r9w6EBAvJOT_J2TugGpa{border-top:1px solid var(--primary-color-gray-3);padding:24px}`, "",{"version":3,"sources":["webpack://./src/pages/settings/billing/components/CurrentPlan/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,iBAAA,CACA,4CAAA,CACA,kBAAA,CAEF,sBACE,iBAAA,CACA,SAAA,CACA,SAAA,CACA,eAAA,CACA,YAAA,CACA,4CAAA,CACA,iBAAA,CAEF,sBACE,cAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,WAAA,CAEA,4CACE,YAAA,CACA,WAAA,CAGJ,sBACE,gDAAA,CACA,YAAA","sourcesContent":[".wrap{\n  margin-top: 32px;\n  position: relative;\n  border: 1px solid var(--primary-color-gray-3);\n  border-radius: 12px;\n}\n.label{\n  position: absolute;\n  top: -10px;\n  left: 16px;\n  padding: 2px 8px;\n  display: flex;\n  background-color: var(--primary-color-blue-1);\n  border-radius: 8px;\n}\n.header{\n  padding: 0 24px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 86px;\n\n  .left{\n    display: grid;\n    row-gap: 4px;\n  }\n}\n.content{\n  border-top: 1px solid var(--primary-color-gray-3);\n  padding: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `fceuAY77ggqEwa7L0ENe`,
	"label": `I8k7vPBsGfDLBlhB6g4Q`,
	"header": `RxrexFGbSO7gxjO4XyJy`,
	"left": `y_v_o9KsXrbTUMmsNtHV`,
	"content": `r9w6EBAvJOT_J2TugGpa`
};
export default ___CSS_LOADER_EXPORT___;
