import { observer } from 'mobx-react-lite'
import { CampaignsTable } from 'src/pages/main/campaigns/CampaignsTable/CampaignsTable'
import { Header } from 'shared/Header/Header'

export const Campaigns = observer(() => {
  return (
    <>
      <Header title={'Campaigns'} />
      <CampaignsTable />
    </>
  )
})
