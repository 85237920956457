import { BroadcastEstimatedDurationResponse } from 'src/generated/graphql'
import dayjs, { DayjsFormats } from 'lib/dayjs'

export const getEstimatedDurationResponse = (
  estimatedDurationResponse: BroadcastEstimatedDurationResponse
) => {
  const daysString = estimatedDurationResponse?.durationDays
    ? `${estimatedDurationResponse?.durationDays}d`
    : ''
  const hourString = estimatedDurationResponse?.durationHours
    ? `${estimatedDurationResponse?.durationHours}h`
    : ''
  const minutesString = estimatedDurationResponse?.durationMinutes
    ? `${estimatedDurationResponse?.durationMinutes}min`
    : ''
  const duration = [daysString, hourString, minutesString]
    .filter(Boolean)
    .join(' ')

  const today = dayjs().format('DD MMM, YYYY')
  const finishDate = dayjs(estimatedDurationResponse.expectedFinishDate)
  const estimatedCompletionDay = finishDate.format('DD MMM, YYYY')
  return {
    duration: `~ ${duration}`,
    by: `${finishDate.format(DayjsFormats.time)} ${
      today === estimatedCompletionDay
        ? 'today'
        : `on ${finishDate.format(DayjsFormats.full3)}`
    }`,
  }
}
