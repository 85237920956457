import { InputText } from 'components/Input/InputText/InputText'
import styles from './styles.module.scss'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { SizedBox } from 'components/SizedBox'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { HorizontalDivider } from 'components/HorizontalDevider/HorizontalDivider'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { TTPositionEnum } from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { SentToDropDown } from 'components/SendToDropDown/SentToDropDown'
import { ReactComponent as Delete } from 'icons/16px/Delete.svg'
import { InputCheckboxGroup } from 'components/Input/InputCheckbox/InputCheckboxGroup'
import { CopyField } from 'components/CopyField/CopyField'
import {
  InvitationAccessType,
  InvitationRole,
  useCreateInvitationMutation,
  useRemoveInvitationMutation,
  useUpdateMemberRestrictionsMutation,
} from 'src/generated/graphql'
import { CopyBtn } from 'components/Button/CopyBtn'
import teamStore from 'store/settings/teamStore'
import { observer } from 'mobx-react-lite'
import alertStore from 'store/alertStore'
import { IOption } from 'src/types/IOption'
import { ErrorsEnum } from 'src/static/errors'

const dontValidEmailErrorText = 'Please enter a valid email address'
const alreadyUsedEmailErrorText =
  'User with this email has been already invited or registered'

const options: IOption<InvitationRole>[] = [
  {
    value: InvitationRole.Member,
    title: 'Member',
    tooltip: (
      <div style={{ width: 205 }}>
        The Member can import and export contacts, but cannot view or edit
        Billing.
      </div>
    ),
  },
  {
    value: InvitationRole.Admin,
    title: 'Admin',
    tooltip: (
      <div style={{ width: 205 }}>
        Has all the same capabilities as Account owner, but cannot edit Billing.
      </div>
    ),
  },
]

export const InviteContent = observer(() => {
  const [createInvitation] = useCreateInvitationMutation()
  const [updateMemberRestrictions, { loading }] =
    useUpdateMemberRestrictionsMutation()
  const [inviteEmail, setInviteEmail] = useState('')
  const [dontValidEmailError, setDontValidEmailError] = useState('')
  const [alreadyUsedEmailError, setAlreadyUsedEmailError] = useState('')

  const [loadingLink, setLoadingLink] = useState(false)
  const [loadingEmail, setLoadingEmail] = useState(false)
  const [invitationId, setInvitationId] = useState(0)
  const [removeInvitation] = useRemoveInvitationMutation()
  const [accessType, setAccessType] = useState<InvitationAccessType>(
    InvitationAccessType.Full
  )
  const [inviteLink, setInviteLink] = useState('')
  const [selectedRoleOption, setSelectedRoleOption] = useState(
    (teamStore.editMember?.role &&
      options.find((opt) => opt.value === teamStore.editMember?.role)) ||
      options[0]
  )

  const handleCreateInviteLink = async () => {
    try {
      setLoadingLink(true)
      const { data } = await createInvitation({
        variables: {
          input: {
            role: selectedRoleOption.value as InvitationRole,
            accessType,
            listIds:
              teamStore.dropdownContactListsStore.selectedContactListsIds,
          },
        },
      })
      if (data?.createInvitation?.link) {
        setInviteLink(data.createInvitation.link)
      }
      if (data?.createInvitation?.id) {
        setInvitationId(data?.createInvitation?.id)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingLink(false)
    }
  }

  const handleDeleteInvitation = async () => {
    try {
      await removeInvitation({
        variables: {
          invitationId,
        },
      })
      setInviteLink('')
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    setInviteLink('')
  }, [selectedRoleOption, accessType, inviteEmail])

  useEffect(() => {
    if (teamStore.editMember) {
      setInviteEmail(teamStore.editMember.email || '')
      if (teamStore.editMember.accessType) {
        setAccessType(teamStore.editMember.accessType)
      }
      setSelectedRoleOption(
        (teamStore.editMember?.role &&
          options.find((opt) => opt.value === teamStore.editMember?.role)) ||
          options[0]
      )
    }
  }, [teamStore.editMember])

  const onSend = async () => {
    if (inviteEmail.includes('@') && inviteEmail.includes('.')) {
      try {
        setLoadingEmail(true)
        const { data } = await createInvitation({
          variables: {
            input: {
              role: selectedRoleOption.value as InvitationRole,
              accessType,
              listIds:
                teamStore.dropdownContactListsStore.selectedContactListsIds,
              email: inviteEmail,
            },
          },
        })
        if (data?.createInvitation) {
          teamStore.setOpenMemberModal(false)
          teamStore.setSentInvite({
            email: data.createInvitation.email || '',
            id: data.createInvitation.id,
          })
        }
      } catch (e) {
        if (
          e instanceof Error &&
          e.message.includes(ErrorsEnum.alreadyUseEmail)
        ) {
          alertStore.disabledErrorAlert(e.message)
          setDontValidEmailError(alreadyUsedEmailErrorText)
        }
        if (e instanceof Error && e.message.includes('valid')) {
          alertStore.disabledErrorAlert(e.message)
          setDontValidEmailError(dontValidEmailErrorText)
        }
        console.error(e)
      } finally {
        setLoadingEmail(false)
      }
    } else {
      setDontValidEmailError(dontValidEmailErrorText)
    }
  }

  const onSave = async () => {
    try {
      const { data } = await updateMemberRestrictions({
        variables: {
          memberId: teamStore.editMemberId,
          input: {
            role: selectedRoleOption.value as InvitationRole,
            accessType,
            listIds:
              teamStore.dropdownContactListsStore.selectedContactListsIds,
          },
        },
      })
      if (data?.updateMemberRestrictions) {
        teamStore.setOpenMemberModal(false)
        teamStore.setMembers([data?.updateMemberRestrictions])
      }
    } catch (e) {
      console.error(e)
    }
  }

  const onFocus = () => {
    setAlreadyUsedEmailError('')
    setDontValidEmailError('')
  }

  return (
    <div className={'mediumModal'}>
      <div className={styles.email}>
        <InputText
          label={'Email address'}
          placeholder={'email@address.com'}
          value={inviteEmail}
          onChange={(e) => setInviteEmail(e.target.value)}
          onFocus={onFocus}
          withoutClear
          disabled={!!teamStore.editMember}
          error={dontValidEmailError || alreadyUsedEmailError}
        />
        <Dropdown
          className={styles.drop}
          options={options}
          dropDownContentWidth={170}
          position={TTPositionEnum.bottomRight}
          addLeftPosition={4}
          selectedOption={selectedRoleOption}
          onSelect={setSelectedRoleOption}
        />
      </div>
      {selectedRoleOption.value === InvitationRole.Member && (
        <>
          <SizedBox height={32} />
          <span className={'s2 medium gray1 mb8'}>Access to Contact Lists</span>
          <InputCheckboxGroup>
            <InputCheckbox
              label={'Full access'}
              checked={accessType === InvitationAccessType.Full}
              onChecked={() => setAccessType(InvitationAccessType.Full)}
              shape={'radio'}
              size={'small'}
            />
            <InputCheckbox
              label={'Restricted access'}
              checked={accessType === InvitationAccessType.Restricted}
              onChecked={() => setAccessType(InvitationAccessType.Restricted)}
              shape={'radio'}
              size={'small'}
            />
          </InputCheckboxGroup>
          {accessType === InvitationAccessType.Restricted && (
            <>
              <SizedBox height={16} />
              <SentToDropDown
                noRightBth
                noWebForm
                noKeyword
                maxWidth={464}
                required
                onMultiSelect={(options) =>
                  teamStore.dropdownContactListsStore.onSelectOptions(options)
                }
                label={'Connect to list'}
                selectedOptions={
                  teamStore.dropdownContactListsStore.selectedOptions
                }
                // tabIndex={1}
                onDeleteTag={(id) => {
                  teamStore.dropdownContactListsStore.onDeleteTag(id)
                }}
              />
            </>
          )}
        </>
      )}

      <SizedBox height={40} />
      {teamStore.editMember ? (
        <div className={styles.actions}>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            onClick={onSave}
            loading={loading}
          >
            Save changes
          </Button>
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={() => teamStore.setOpenMemberModal(false)}
          >
            Cancel
          </Button>
        </div>
      ) : (
        <>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            disabled={
              !inviteEmail ||
              (accessType === InvitationAccessType.Restricted &&
                !teamStore.dropdownContactListsStore.selectedContactListsOptions
                  .length) ||
              !!dontValidEmailError ||
              !!alreadyUsedEmailError
            }
            onClick={onSend}
            fullWidth
            loading={loadingEmail}
          >
            Send email invite
          </Button>
          <SizedBox height={16} />
          <HorizontalDivider />
          <SizedBox height={16} />
          {inviteLink ? (
            <div className={styles.inviteLinkContent}>
              <CopyField link={inviteLink} />
              <CopyBtn
                content={inviteLink}
                textBtn={'Copy link'}
                linkIcon
                size={BtnSize.medium}
              />
              <Button
                typeBtn={BtnType.secondaryDelete}
                size={BtnSize.medium}
                onClick={handleDeleteInvitation}
                p0
              >
                <Delete />
              </Button>
            </div>
          ) : (
            <Button
              typeBtn={BtnType.secondaryGray}
              size={BtnSize.medium}
              onClick={handleCreateInviteLink}
              disabled={
                accessType === InvitationAccessType.Restricted &&
                !teamStore.dropdownContactListsStore.selectedContactListsOptions
                  .length
              }
              fullWidth
              loading={loadingLink}
            >
              Create invite link
            </Button>
          )}
        </>
      )}
    </div>
  )
})
