import {
  Listing,
  Status,
  useContactBulkArchiveMutation,
  useContactBulkReSubscribeMutation,
  useContactBulkSoftUnsubscribeMutation,
  useContactBulkUnArchiveMutation,
  useGetMyContactLazyQuery,
  useRemoveContactsMutation,
  useRequestDoubleOptInConfirmationMutation,
  useUpdateContactMutation,
} from 'src/generated/graphql'
import {
  transformContact,
  transformContactToBack,
} from 'store/contacts/functions'
import contactStore from 'store/contacts/contactStore'
import manualContactStore from 'store/contacts/manualContactStore'
import { IContact } from 'src/types/IContact'
import alertStore from 'store/alertStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import companyStore from 'store/settings/company/companyStore'
import editContactListsStore from 'src/pages/main/contacts/modals/EditListsModal/editContactListsStore'
import { IContactActions } from 'src/pages/main/contacts/functions/getContactActions'

export function useContactFunctions(props: IContactActions) {
  const [getMyContact] = useGetMyContactLazyQuery()
  const [removeContacts] = useRemoveContactsMutation()
  const [updateContact] = useUpdateContactMutation()
  const [requestDoubleOptIn] = useRequestDoubleOptInConfirmationMutation()
  const [contactBulkArchiveMutation] = useContactBulkArchiveMutation()
  const [contactBulkUnArchiveMutation] = useContactBulkUnArchiveMutation()
  const [contactBulkReSubscribeMutation] = useContactBulkReSubscribeMutation()
  const [contactBulkSoftUnsubscribeMutation] =
    useContactBulkSoftUnsubscribeMutation()

  const contact = props.row
  const bulk = contact ? { ids: [+contact.id] } : props.bulk

  const handleUpdateContact = async (newContact: IContact) => {
    try {
      const { data } = await updateContact({
        variables: {
          updateContactInput: transformContactToBack(newContact),
        },
      })

      if (data?.updateContact?.contact) {
        contactStore.updateContact(
          transformContact(data?.updateContact?.contact)
        )
        if (data.updateContact.updatedListings) {
          const listings: Listing[] = []
          data.updateContact.updatedListings.forEach((list) => {
            if (list) {
              listings.push(list)
            }
          })
          contactStore.addListing(listings)
        }
      }
      contactStore.updateContact(newContact)
      contactStore.setStatisticTrigger()
    } catch (e) {
      console.error(e)
    }
  }

  const onEdit = async () => {
    if (!contact) {
      return
    }
    try {
      const { data } = await getMyContact({
        variables: {
          contactId: contact.id,
        },
        fetchPolicy: 'network-only',
      })
      if (data?.getMyContact) {
        manualContactStore.setEditContact(transformContact(data.getMyContact))
      }
    } catch (e) {
      console.error(e)
    }
  }

  const onEditLists = () => {
    if (!bulk) return
    editContactListsStore.setEditListsModalContactIds(bulk || null)
  }

  const onUnsubscribe = async () => {
    if (!bulk) return
    try {
      await contactBulkSoftUnsubscribeMutation({
        variables: {
          bulk,
        },
      })
      contactStore.onUpdateContacts(props.bulk)
    } catch (e) {
      console.error(e)
    }
  }
  const onSubscribe = async () => {
    if (!bulk) return
    try {
      await contactBulkReSubscribeMutation({
        variables: {
          bulk,
        },
      })
      contactStore.onUpdateContacts(props.bulk)
    } catch (e) {
      console.error(e)
    }
  }
  const onArchive = async () => {
    if (!bulk) return
    try {
      await contactBulkArchiveMutation({
        variables: {
          bulk,
        },
      })
      contactStore.onUpdateContacts(props.bulk)
    } catch (e) {
      console.error(e)
    }
  }
  const onUnarchived = async () => {
    if (!bulk) return
    try {
      await contactBulkUnArchiveMutation({
        variables: {
          bulk,
        },
      })
      contactStore.onUpdateContacts(props.bulk)
    } catch (e) {
      console.error(e)
    }
  }
  const onValid = () => {
    if (!contact) {
      return
    }
    const newContact: IContact = { ...contact, status: Status.Active }
    handleUpdateContact(newContact)
  }
  const onResendDoubleOptIn = async () => {
    if (!contact) {
      return
    }
    try {
      const { data } = await requestDoubleOptIn({
        variables: {
          contactIds: [contact.id],
        },
      })
      if (data?.requestDoubleOptInConfirmation) {
        alertStore.setAlert({
          type: AlertTypeEnum.success,
          title: 'Double opt-in message has been sent',
        })
      }
    } catch (e) {
      console.error(e)
    }
  }
  const onDelete = async () => {
    if (!bulk) return
    try {
      await removeContacts({
        variables: {
          bulk,
        },
      })
      contactStore.onUpdateContacts(bulk)
    } catch (e) {
      console.error(e)
    }
  }

  const onValidatePhone = async () => {
    if (bulk) {
      companyStore.setPhoneCheckerModal({
        state: true,
        bulk,
      })
    }
  }

  return {
    onEdit,
    onEditLists,
    handleUpdateContact,
    onUnsubscribe,
    onSubscribe,
    onArchive,
    onUnarchived,
    onValid,
    onValidatePhone,
    onDelete,
    onResendDoubleOptIn,
  }
}
