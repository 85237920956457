import { createContext, useContext } from 'react'
import { RegisterStore } from 'store/registerStore'

export const RegisterContext = createContext<RegisterStore | null>(null)

export const useRegisterContext = () => {
  const context = useContext(RegisterContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with RegisterStoreContext'
    )

  return context
}
