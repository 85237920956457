import { makeAutoObservable } from 'mobx'
import { ActiveContentStore } from 'src/widgets/SendingSpeed/ui/ActiveContent/store/ActiveContentStore'
import { BroadcastSendingSpeedConfig } from 'src/generated/graphql'

export class SendingSpeedStore {
  constructor(config?: BroadcastSendingSpeedConfig | null) {
    makeAutoObservable(this)
    if (config) {
      this.init(config)
    }
  }

  activeContentStore: ActiveContentStore | null = null

  active = false
  setActive = (active: typeof this.active) => {
    if (active) {
      this.activeContentStore = new ActiveContentStore()
    } else {
      this.activeContentStore = null
    }
    this.active = active
  }
  toggleActive = () => {
    this.setActive(!this.active)
  }

  init = (config: BroadcastSendingSpeedConfig) => {
    this.active = true
    this.activeContentStore = new ActiveContentStore(config)
  }
}
