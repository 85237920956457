import { CurrentPlan } from 'src/pages/settings/billing/components/CurrentPlan/CurrentPlan'
import { CreditsContent } from 'src/pages/settings/billing/components/CreditsContent/CreditsContent'
import { PaymentMethods } from 'src/pages/settings/billing/components/PaymentMethods/PaymentMethods'
import { CancelSubscription } from 'src/pages/settings/billing/components/CancelSubscription/CancelSubscription'
import { Invoices } from 'src/pages/settings/billing/Invoices/Invoices'
import { useEffect } from 'react'
import alertStore from 'store/alertStore'
import { observer } from 'mobx-react-lite'
import billingStore from 'store/settings/billing/billingStore'
import { useSetBillingInfo } from 'src/pages/settings/billing/hooks/useSetBillingInfo'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { PhoneCheckrCard } from 'src/pages/settings/billing/components/PhoneCheckr/PhoneCheсkrCard'
import { Header } from 'shared/Header/Header'
import { Container } from 'shared/Container/Container'

export const Billing = observer(() => {
  useSetBillingInfo()
  useEffect(() => {
    if (billingStore.isPausedSubscription) {
      alertStore.setAlert(
        {
          type: AlertTypeEnum.blue3,
          title:
            'Your subscription will be paused at the end of the billing cycle: Jan 1, 2022. Pause period is up to 3 months.',
          rightContent: <span className={'white bold'}>Cancel pause</span>,
          onClose: () => billingStore.setIsPausedSubscription(false),
        },
        true
      )
    }
  }, [billingStore.isPausedSubscription])
  return (
    <>
      <Container small>
        <Header title={'Billing & Usage'} style={{ paddingInline: 0 }} />
        <CurrentPlan />
        {!billingStore.isTrial && <CreditsContent />}
        <PhoneCheckrCard />
        <PaymentMethods />
        <Invoices />
      </Container>
      <CancelSubscription />
    </>
  )
})
