import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { Typography } from 'shared/Typography'
import { Switch } from 'components/Switch/Switch'
import { useTrackConversionContext } from 'widgets/TrackConversion/context/context'

export const SwitchCard = observer(() => {
  const { isEnabled, setEnabled } = useTrackConversionContext()
  return (
    <>
      <div className={styles.left}>
        <Typography variant={'s1-semiBold'}>Track conversions</Typography>
        <Typography variant={'s2-regular'} color={'--primary-color-gray-1'}>
          Record and track the main milestones people accomplish, such as making
          a purchase
        </Typography>
      </div>
      <div>
        <Switch active={isEnabled} onChange={setEnabled} />
      </div>
    </>
  )
})
