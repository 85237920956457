import { Alert } from 'components/Alert/Alert'
import { observer } from 'mobx-react-lite'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import a2p10DLCStore from 'store/settings/company/a2p10DLCStore'
import {
  TenDlcUseCaseType,
  useInitUseCaseTenDlcMutation,
} from 'src/generated/graphql'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { SimpleTextarea } from 'components/SimpleTextarea/SimpleTextarea'
import React from 'react'
import { IOption } from 'src/types/IOption'
import { BackBtn } from 'src/pages/settings/compliance/registrations/A2P10DLCRegistration/steps/BackBtn'
import typesStore from 'store/typesStore'

export const Step2 = observer(() => {
  const [initUseCaseTenDlc, { loading }] = useInitUseCaseTenDlcMutation()
  const options: IOption[] = Object.values(TenDlcUseCaseType).map(
    (useCase) => ({
      title: typesStore.a2pUseCaseTypeMapping.get(useCase) || useCase,
      value: useCase,
    })
  )
  const onContinue = async () => {
    try {
      const { data } = await initUseCaseTenDlc({
        variables: {
          input: a2p10DLCStore.tenDlcUseCaseRequestInput,
        },
      })
      if (data?.initUseCaseTenDlc?.profile) {
        a2p10DLCStore.setTenDlcProfile(data.initUseCaseTenDlc.profile)
      }
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <div className={'col40'}>
      <Alert
        compact
        text={
          'Your campaign use case describes the type of messaging you plan to send. Please select the use case that best describes how you will use messaging.'
        }
      />
      <div className={'col24'}>
        <Dropdown<TenDlcUseCaseType>
          selectedValue={a2p10DLCStore.useCaseType || undefined}
          onSelectValue={(value) => a2p10DLCStore.setUseCaseType(value)}
          placeholder={'e.g. Marketing'}
          labelInput={'Use case'}
          options={options}
        />
        <SimpleTextarea
          value={a2p10DLCStore.useCaseDescription}
          onChangeValue={(value) => a2p10DLCStore.setUseCaseDescription(value)}
          label={'Use case description'}
          placeholder={'Enter use case description'}
          height={104}
          error={a2p10DLCStore.useCaseDescriptionError}
        />
      </div>
      <div className={'row12'}>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          onClick={onContinue}
          loading={loading}
          disabled={a2p10DLCStore.disabledUseCase}
        >
          {a2p10DLCStore.continueText}
        </Button>
        <BackBtn />
      </div>
    </div>
  )
})
