import { SizedBox } from 'components/SizedBox'
import { TabWrap } from 'src/pages/settings/account/TabWrap'
import { DeleteAccount } from 'src/pages/settings/account/compponents/DeleteAccount/DeleteAccount'
import { ChangePasswordContent } from 'src/pages/settings/account/routes/PrivacyAccount/password/ChangePasswordContent'
import { GoogleAuthContent } from 'src/pages/settings/account/routes/PrivacyAccount/GoogleAuthContent'
import { EmailAuthContent } from 'src/pages/settings/account/routes/PrivacyAccount/EmailAuthContent'
import styles from './styles.module.scss'

export const PrivacyAccount = () => {
  return (
    <>
      <TabWrap className={styles.wrapPrivacyTab}>
        <div className={styles.wrapPrivacyContent}>
          <GoogleAuthContent />
          <SizedBox height={48} />
          <EmailAuthContent />
          <ChangePasswordContent />
        </div>

        <DeleteAccount />
      </TabWrap>
    </>
  )
}
