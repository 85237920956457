import { TrackConversionContext } from 'widgets/TrackConversion/context/context'
import { TrackConversionUi } from 'widgets/TrackConversion/ui/TrackConversionUi'
import { TrackConversionStore } from 'widgets/TrackConversion/store/trackConversionStore'

type ITrackConversionProps = {
  store: TrackConversionStore
}
export const TrackConversion = ({ store }: ITrackConversionProps) => {
  return (
    <TrackConversionContext.Provider value={store}>
      <TrackConversionUi />
    </TrackConversionContext.Provider>
  )
}
