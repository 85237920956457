import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { ActiveContentStore } from 'src/widgets/SendingSpeed/ui/ActiveContent/store/ActiveContentStore'
import * as React from 'react'
import classNames from 'classnames'
import { Icon } from 'components/Icon/Icon'
import { SelectList } from 'components/SelectList/SelectList'
import { DropdownField } from 'shared/DropdownField/DropdownField'
import { Input } from 'shared/Input/Input'

export const ActiveContent = observer(
  ({ store }: { store: ActiveContentStore }) => {
    const {
      contactsCount,
      setContactsCount,
      minutesOptions,
      unitAmount,
      setUnitAmount,
    } = store

    return (
      <div className={styles.wrap}>
        <Input
          leftIcon={'contactsFill'}
          isNumber
          value={contactsCount}
          setValue={setContactsCount}
          rightContent={
            <div
              className={classNames(
                's2 medium gray1 nowrap',
                styles.wrapMinutesContent
              )}
            >
              contacts every
              <DropdownField
                withPopperOffset
                dropdownContent={() => (
                  <SelectList
                    options={minutesOptions}
                    onSelect={setUnitAmount}
                  />
                )}
              >
                <div className={styles.minutesBtn}>
                  <span>{unitAmount}</span>
                  <Icon icon={'angleDown'} fontSize={12} />
                </div>
              </DropdownField>
              minutes
            </div>
          }
        />
      </div>
    )
  }
)
