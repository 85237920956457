import { HeaderContent } from './header/HeaderContent'
import styles from './styles.module.scss'
import { useParams } from 'react-router-dom'
import { WrapContactsTable } from './WrapContactsTable'
import { useGetCustomFields } from 'src/pages/main/contacts/customFields/hooks/useGetCustomFields'
import { observer } from 'mobx-react-lite'
import uiStore from 'store/uiStore'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'

export const DetailList = observer(() => {
  useGetCustomFields()
  const { id } = useParams()
  const isNew = id === 'new'

  return (
    <div className={styles.wrap}>
      {uiStore.loadingContentMap.get('lists') && <AbsoluteLoader size={40} />}
      <HeaderContent />
      {!isNew && <WrapContactsTable />}
    </div>
  )
})
