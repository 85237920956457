import { observer } from 'mobx-react-lite'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'
import styles from 'src/pages/settings/compliance/TollFreeVerification/steps/OptInMethod/styles.module.scss'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import { OptInImageLoader } from 'src/pages/settings/compliance/TollFreeVerification/steps/OptInMethod/OptInImageLoader'
import React from 'react'
import tollFreeVerificationStore, {
  OptInAgreementsEnum,
  optInAgreementsMapByMethod,
} from 'store/settings/company/tollFreeVerificationStore'
import typesStore from 'store/typesStore'

export const UploadOptInImageContent = observer(() => {
  const { optInMethod, optInAgreementsMap, setOptInAgreement } =
    tollFreeVerificationStore
  const { tfOptInMethodMapping } = typesStore

  const inputsOpj = {
    [OptInAgreementsEnum.isHaveComplianceOptIn]: (
      <InputComponent
        label={'I have added the Compliance Language'}
        link={
          (optInMethod && tfOptInMethodMapping.get(optInMethod)?.infoUrl) || ''
        }
        onChecked={(value) =>
          setOptInAgreement(OptInAgreementsEnum.isHaveComplianceOptIn, value)
        }
        checked={
          !!optInAgreementsMap.get(OptInAgreementsEnum.isHaveComplianceOptIn)
        }
      />
    ),
    [OptInAgreementsEnum.isHaveTermsOptIn]: (
      <InputComponent
        label={'I have included a link to my Terms of Service'}
        onChecked={(value) =>
          setOptInAgreement(OptInAgreementsEnum.isHaveTermsOptIn, value)
        }
        checked={!!optInAgreementsMap.get(OptInAgreementsEnum.isHaveTermsOptIn)}
      />
    ),
    [OptInAgreementsEnum.isHavePrivacyOptIn]: (
      <InputComponent
        label={'I have included a link to my Privacy Policy'}
        onChecked={(value) =>
          setOptInAgreement(OptInAgreementsEnum.isHavePrivacyOptIn, value)
        }
        checked={
          !!optInAgreementsMap.get(OptInAgreementsEnum.isHavePrivacyOptIn)
        }
      />
    ),
    [OptInAgreementsEnum.isHaveFooterLinksOptIn]: (
      <InputComponent
        label={
          'My website footer contains a link to my Privacy Policy and Terms of Service'
        }
        onChecked={(value) =>
          setOptInAgreement(OptInAgreementsEnum.isHaveFooterLinksOptIn, value)
        }
        checked={
          !!optInAgreementsMap.get(OptInAgreementsEnum.isHaveFooterLinksOptIn)
        }
      />
    ),
  }
  return (
    <div>
      <div className={'col4'}>
        <div className={'flex'}>
          <span className={'s2'}>
            <b>Upload opt-in image</b>
          </span>
          <InfoTooltip
            title={
              <>
                Please provide image of the opt-in process and what a subscriber
                is agreeing to. This should be where the customer’s phone number
                was entered by the customer agreeing to receive the SMS.
                <br />
                <br />
                Supported files: png, jpg - up to 5MB
              </>
            }
          />
        </div>
      </div>
      <span className={'s2'}>
        Before uploading the image, please make sure it meets the below criteria
      </span>
      {optInMethod && (
        <div className={styles.wrapInputs}>
          {optInAgreementsMapByMethod[optInMethod].map(
            (agreementsItem) => inputsOpj[agreementsItem]
          )}
        </div>
      )}
      <OptInImageLoader />
    </div>
  )
})

const InputComponent = ({
  link,
  checked,
  onChecked,
  label,
}: {
  label: string
  link?: string
  checked: boolean
  onChecked: (value: boolean) => void
}) => {
  const onLink: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.stopPropagation()
  }
  return (
    <InputCheckbox
      label={<div className={'fullWidth flex-space-between'}>{label}</div>}
      rightIcon={
        (link && (
          <a
            href={link}
            target={'_blank'}
            className={'s2 bold nowrap'}
            rel="noreferrer"
            onClick={onLink}
          >
            See examples
          </a>
        )) ||
        undefined
      }
      checked={checked}
      onChecked={() => onChecked(!checked)}
      noBordered3
    />
  )
}
