import { FC, Fragment, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { useSetTotalContactsCount } from 'src/pages/main/contacts/hooks/useSetTotalContactsCount'
import manualContactStore from 'store/contacts/manualContactStore'

export const CheckLimitContactsWrap: FC<{ children?: ReactNode }> = observer(
  (props) => {
    useSetTotalContactsCount(manualContactStore.openContactModal)
    return <Fragment {...props} />
  }
)
