import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import { DatePicker } from 'components/DatePicker/DatePicker'
import { TimePicker } from 'components/TimePicker/TimePicker'
import { SizedBox } from 'components/SizedBox'
import { BroadcastScheduleLaterStore } from 'src/widgets/BroadcastSchedule/ui/LaterContent/store/BroadcastScheduleLaterStore'
import React from 'react'

export const LaterContent = observer(
  ({ store }: { store: BroadcastScheduleLaterStore }) => {
    const { date, setDate } = store
    return (
      <>
        <div className={styles.wrap}>
          <DatePicker startDate={date} onChange={setDate} />
          <SizedBox height={8} />
          <TimePicker startTime={date} onChange={setDate} />
        </div>
        {/*<RepeatContent store={repeatStore} />*/}
      </>
    )
  }
)
