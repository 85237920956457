import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { Direction, NullHandling, PaginationInput } from 'src/generated/graphql'
import { nanoid } from 'nanoid'
import { RangeDatePickerStore } from 'store/rangeDatePickerStore/rangeDatePickerStore'
import { IOption } from 'src/types/IOption'
import { ColumnType, RequiredFields } from 'components/NewTable/types'

import { AppElements } from 'src/enums/appElements'
import uniqueBy from '@popperjs/core/lib/utils/uniqueBy'
import { type IListItem } from 'components/SelectList/ListItem/ListItem'

export enum TableStatuses {
  totalCount = 'totalCount',
  subscribedCount = 'subscribedCount',
  confirmedCount = 'confirmedCount',
  visitorsCount = 'visitorsCount',
  successCount = 'successCount',
  activeCount = 'activeCount',
  invalidCount = 'invalidCount',
  archivedCount = 'archivedCount',
  pendingCount = 'pendingCount',
  unsubscribedCount = 'unsubscribedCount',
  failedCount = 'failedCount',
  inactiveCount = 'inactiveCount',
  clicksCount = 'clicksCount',
  callBacks = 'callBacks',
  voiceTransfers = 'voiceTransfers',
}

export class TableStore<T extends RequiredFields<T>> {
  rangeDatePickerStore: RangeDatePickerStore
  constructor({
    columns = [],
    orderBy = 'sendTime',
    order = Direction.Desc,
    tableName = '',
    defaultHiddenColumn = [],
    status,
    selectedRowPerPage,
    withDataRange,
    noSort,
    fromBroadcastStatistic,
    element,
  }: {
    columns: ColumnType<T>[]
    orderBy?: string
    order?: Direction
    tableName: string
    status?: TableStatuses
    defaultHiddenColumn?: string[]
    selectedRowPerPage?: number
    withDataRange?: boolean
    noSort?: boolean
    fromBroadcastStatistic?: boolean
    element?: AppElements
  }) {
    this.withDataRange = withDataRange
    this.fromBroadcastStatistic = fromBroadcastStatistic
    this.noSort = noSort
    this.rangeDatePickerStore = new RangeDatePickerStore(withDataRange)
    this.tableName = tableName
    if (status) {
      this.status = status
    }
    this.orderBy = orderBy
    this.order = order

    this.columnsMap = new Map<string, ColumnType<T>>(
      columns.map((col) => [col.id as string, col])
    )
    if (selectedRowPerPage) {
      this.selectedRowPerPage = selectedRowPerPage
    }

    const columnOrder = JSON.parse(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      localStorage.getItem(this.tableName + 'ColumnOrder')
    )
    const columnVisible = JSON.parse(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      localStorage.getItem(this.tableName + 'Visible')
    )

    this.columnsVisibleState = new Map(
      columnVisible ||
        columns
          .filter((col) => !defaultHiddenColumn.includes(col.id as string))
          .map((col) => [col.id, true])
    )
    this.columnOrderIds = columnOrder || columns.map((col) => col.id)
    this.element = element || null

    makeAutoObservable(this)
    reaction(
      () => this.status,
      () => {
        runInAction(() => {
          this.page = 0
        })
      }
    )
    reaction(
      () => this.search,
      () => {
        runInAction(() => {
          this.page = 0
        })
      }
    )
  }
  rows: Array<T> = []
  element: AppElements | null = null
  isSelectALl = false
  withDataRange?: boolean
  fromBroadcastStatistic?: boolean
  noSort?: boolean
  total = 0
  tableName = ''
  order: Direction = Direction.Asc
  orderBy = 'name'
  page = 0
  checkedRows: Array<T> = []
  currentStatuses: IOption[] = []
  currentTypes: IOption[] = []
  search = ''
  refreshTrigger = ''
  columnsMap: Map<string, ColumnType<T>> = new Map()
  columnsVisibleState: Map<string, boolean> = new Map<string, boolean>()
  columnOrderIds: string[] = []
  status = TableStatuses.totalCount
  showMobileSearch = false

  get paginationInput(): PaginationInput {
    return {
      pageNumber: this.page,
      pageSize: this.selectedRowPerPage,
      sort: this.noSort
        ? undefined
        : {
            orders: [
              {
                direction: this.order,
                property: this.orderBy,
                nullHandlingHint: NullHandling.Native,
                ignoreCase: this.orderBy !== 'id',
              },
            ],
          },
    }
  }

  get checkedRowsIds() {
    return this.checkedRows.map((row) => row.id)
  }

  get columns(): ColumnType<T>[] {
    return Array.from(this.columnsMap.values())
  }

  get visibleColumns(): ColumnType<T>[] {
    const newColumns: ColumnType<T>[] = []
    this.columnOrderIds.forEach((id) => {
      const col = this.columnsMap.get(id)
      if (col && this.columnsVisibleState.get(id)) {
        newColumns.push(col)
      }
    })
    return newColumns
  }

  get allPages() {
    return Math.ceil(this.total / +this.selectedRowPerPage) || 0
  }

  get allPagesOptions(): IOption[] {
    return Array(this.allPages)
      .fill(0)
      .map((_, index) => index + 1)
      .map((p) => ({ title: String(p), value: p - 1 }))
  }

  reset = () => {
    this.search = ''
    this.isSelectALl = false
    this.rows = []
    this.checkedRows = []
    this.refreshTrigger = ''
  }

  setRows = (rows: typeof this.rows) => {
    this.rows = rows
    if (this.isSelectALl) {
      this.checkedRows = uniqueBy(
        [...this.checkedRows, ...rows],
        (row) => row.id
      )
    }
  }

  setTotal(total: number) {
    this.total = total
  }

  changeVisibleColumn(id: string, visible: boolean) {
    this.columnsVisibleState.set(id, visible)
    localStorage.setItem(
      this.tableName + 'Visible',
      JSON.stringify(this.columnsVisibleState)
    )
  }

  setColumnOrder(order: string[]) {
    this.columnOrderIds = order
    localStorage.setItem(this.tableName + 'ColumnOrder', JSON.stringify(order))
  }

  onRefresh() {
    this.refreshTrigger = nanoid()
  }

  setOrder(order: Direction) {
    this.order = order
  }
  setOrderBy(orderBy: string) {
    this.orderBy = orderBy
  }
  setPage(page: number) {
    this.page = page
  }
  setCheckedRows(rows: Array<T>) {
    this.isSelectALl = false
    this.checkedRows = rows
    if (this.checkedRows.length && this.checkedRows.length === this.total) {
      this.isSelectALl = true
    }
  }
  setCurrentStatus(statuses: IOption[]) {
    this.currentStatuses = statuses
  }
  setSearch(val: string) {
    this.checkedRows = []
    this.search = val
  }
  setStatus(status: TableStatuses) {
    if (status) {
      this.checkedRows = []
      this.status = status
    }
  }
  setColumns(columns: ColumnType<T>[], defaultHiddenColumn?: string[]) {
    this.columnsMap = new Map<string, ColumnType<T>>(
      columns.map((col) => [col.id as string, col])
    )

    const columnOrder = JSON.parse(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      localStorage.getItem(this.tableName + 'ColumnOrder')
    )
    const columnVisible = JSON.parse(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      localStorage.getItem(this.tableName + 'Visible')
    )

    this.columnsVisibleState = new Map(
      columnVisible ||
        columns
          .filter((col) => !defaultHiddenColumn?.includes(col.id as string))
          .map((col) => [col.id, true])
    )
    this.columnOrderIds = columnOrder || columns.map((col) => col.id)
  }

  setShowMobileSearch(value: boolean) {
    this.showMobileSearch = value
  }

  setSelectALl = (value: typeof this.isSelectALl) => {
    if (!value) {
      this.checkedRows = []
    } else {
      this.checkedRows = uniqueBy(
        [...this.checkedRows, ...this.rows],
        (row) => row.id
      )
    }
    this.isSelectALl = value
  }

  //Pagination
  rowPerPage = [25, 50, 100, 250, 500]
  get rowPerPageOptions(): IListItem[] {
    return this.rowPerPage.map((item) => ({
      id: item,
      title: String(item),
      isActive: this.selectedRowPerPage === item,
    }))
  }
  selectedRowPerPage = this.rowPerPage[0]
  setSelectedRowPerPage = (
    value: typeof this.selectedRowPerPage,
    isShowMore = false
  ) => {
    this.selectedRowPerPage = value
    if (!isShowMore) {
      this.setPage(0)
    }
  }
}
