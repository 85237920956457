// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uTCoVxvvtirohdPM3dLg{margin-top:64px;padding-left:64px;padding-bottom:48px}@media(max-width: 800px){.uTCoVxvvtirohdPM3dLg{padding-left:24px;padding-bottom:24px}}@media(max-height: 600px){.uTCoVxvvtirohdPM3dLg{padding-left:24px;padding-bottom:24px}}.BeBKttgx563EQDR6BEVf{cursor:pointer}.BeBKttgx563EQDR6BEVf:hover{color:var(--states-color-error-1)}`, "",{"version":3,"sources":["webpack://./src/pages/settings/billing/components/CancelSubscription/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,iBAAA,CACA,mBAAA,CACA,yBAJF,sBAKI,iBAAA,CACA,mBAAA,CAAA,CAEF,0BARF,sBASI,iBAAA,CACA,mBAAA,CAAA,CAIJ,sBACE,cAAA,CACA,4BACE,iCAAA","sourcesContent":[".wrap{\n  margin-top: 64px;\n  padding-left: 64px;\n  padding-bottom: 48px;\n  @media (max-width: 800px) {\n    padding-left: 24px;\n    padding-bottom: 24px;\n  }\n  @media (max-height: 600px) {\n    padding-left: 24px;\n    padding-bottom: 24px;\n  }\n}\n\n.button{\n  cursor: pointer;\n  &:hover{\n    color: var(--states-color-error-1);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `uTCoVxvvtirohdPM3dLg`,
	"button": `BeBKttgx563EQDR6BEVf`
};
export default ___CSS_LOADER_EXPORT___;
