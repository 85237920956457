import { createContext, useContext } from 'react'
import { CampaignScheduleStore } from 'src/widgets/CampaignSchedule'

export const CampaignScheduleContext =
  createContext<CampaignScheduleStore | null>(null)

export const useCampaignScheduleContext = () => {
  const context = useContext(CampaignScheduleContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with CampaignScheduleContext'
    )

  return context
}
