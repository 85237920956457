import { FC, useEffect } from 'react'
import styles from './styles.module.scss'
import { CancelModal } from 'components/Page/modal/CancelModal'
import uiStore from 'store/uiStore'
import { CreateStoreTypes } from 'components/CreateElement/store/types'
import { useSetNumbers } from 'src/pages/settings/numbers/hooks/useSetNumbers'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import { CloseCreateBtn } from 'components/Page/components/CloseCreateBtn'
import { observer } from 'mobx-react-lite'
import { CreateCampaignStore } from 'store/campaigns/createCampaignStore'

type Props = {
  store: CreateStoreTypes | CreateCampaignStore
  createElement: JSX.Element
  previewElement: JSX.Element
  loading?: boolean
  lazy?: boolean
}

export const CreatePage: FC<Props> = observer(
  ({ store, createElement, previewElement, loading, lazy }) => {
    useSetNumbers()

    const closeBtn = <CloseCreateBtn />
    useEffect(() => {
      const onKeydown = (e: KeyboardEvent) => {
        if (e.key === 'Tab') {
          if (document.activeElement?.id === '/dashboard') {
            const el = document.getElementById('name')
            if (el) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              el.focus()
            }
          }
        }
      }
      window.addEventListener('keyup', onKeydown)
      uiStore.setMobileRightBtn(closeBtn)
      return () => {
        window.removeEventListener('keyup', onKeydown)
        uiStore.setMobileRightBtn(null)
      }
    }, [])
    if (loading && !lazy) {
      return <AbsoluteLoader />
    }
    if (uiStore.isMobile) {
      return (
        <div className={styles.wrapCreatePage}>
          <CancelModal store={store} />
          {uiStore.isPreview ? (
            previewElement
          ) : (
            <div className={styles.wrapCreateElement}>{createElement}</div>
          )}
        </div>
      )
    }
    return (
      <>
        {lazy && loading && <AbsoluteLoader />}
        <div
          className={styles.wrapCreatePage}
          style={{ visibility: lazy && loading ? 'hidden' : 'visible' }}
        >
          <CancelModal store={store} />
          <div className={styles.wrapCreateElement}>{createElement}</div>
          {previewElement}
        </div>
      </>
    )
  }
)
