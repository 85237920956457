import { observer } from 'mobx-react-lite'
import styles from 'src/pages/main/contacts/modals/AddContactModal/styles.module.scss'
import { InputText } from 'components/Input/InputText/InputText'
import manualContactStore from 'store/contacts/manualContactStore'
import { SizedBox } from 'components/SizedBox'
import { InputPhone } from 'components/Input/InputText/InputPhone'
import { AddContactsToListDropdown } from 'src/Modals/ImportContactModal/StepsContent/Step3/AddContactsToListDropdown'
import { CustomFieldsContent } from 'src/pages/main/contacts/modals/AddContactModal/CustomFieldsContent/CustomFieldsContent'
import customFieldsStore from 'store/contacts/customFields/customFieldsStore'
import { useEffect, useMemo } from 'react'
import alertStore from 'store/alertStore'
import { ErrorsEnum } from 'src/static/errors'
import { useGetMyContactLazyQuery } from 'src/generated/graphql'
import { transformContact } from 'store/contacts/functions'

export const CommonContent = observer(() => {
  const [getMyContact] = useGetMyContactLazyQuery()
  const error = useMemo(() => {
    if (
      manualContactStore.error.includes('contact_phone_member_unq') ||
      manualContactStore.error.includes('contact_phone_organization_unq')
    ) {
      return ErrorsEnum.alreadyUsePhoneNumber
    }
    if (manualContactStore.error.includes('Number is invalid')) {
      return ErrorsEnum.validPhoneNumber
    }
    if (manualContactStore.error.includes('Contact already exists')) {
      return ErrorsEnum.existContact
    }
  }, [manualContactStore.error])

  useEffect(() => {
    if (error) {
      alertStore.disabledErrorAlert(manualContactStore.error)
    }
  }, [error, manualContactStore.timeError])

  const onViewContact = async () => {
    try {
      const { data } = await getMyContact({
        variables: {
          contactId: manualContactStore.errorContactId,
        },
      })

      if (data?.getMyContact) {
        manualContactStore.closeAddContactModal()
        manualContactStore.setEditContact(transformContact(data.getMyContact))
      }
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <div className={styles.wrapBody}>
      <div className={styles.row}>
        <InputText
          label={'First Name'}
          placeholder={'e.g. Adam'}
          value={manualContactStore.firstName}
          onChange={(event) =>
            manualContactStore.setFirstName(event.target.value)
          }
          onClickRightIcon={() => manualContactStore.setFirstName('')}
        />
        <InputText
          label={'Last Name'}
          placeholder={'e.g. Robertson'}
          value={manualContactStore.lastName}
          onChange={(event) =>
            manualContactStore.setLastName(event.target.value)
          }
          onClickRightIcon={() => manualContactStore.setLastName('')}
        />
      </div>
      <SizedBox height={16} />
      <InputPhone
        required
        label={'Phone'}
        value={manualContactStore.phoneNumber}
        onChange={(event) => {
          if (error) {
            manualContactStore.setError('')
          }
          manualContactStore.setPhone(event.target.value)
        }}
        onClickRightIcon={() => manualContactStore.setPhone('')}
        error={error}
        errorRightContent={
          error === ErrorsEnum.existContact &&
          manualContactStore.errorContactId ? (
            <button className={'s2 link'} onClick={onViewContact}>
              (view contact)
            </button>
          ) : undefined
        }
        focusOnMount={!manualContactStore.editContactId}
      />
      <SizedBox height={16} />
      <InputText
        label={'Email'}
        placeholder={'email@address.com'}
        value={manualContactStore.email}
        onChange={(event) => manualContactStore.setEmail(event.target.value)}
        onClickRightIcon={() => manualContactStore.setEmail('')}
      />
      <SizedBox height={32} />
      <AddContactsToListDropdown store={manualContactStore} />
      {!!customFieldsStore.customFieldsFiltered.length && (
        <>
          <p className={styles.customFieldTitle}>
            <b>Custom fields</b>
          </p>
          <CustomFieldsContent
            fields={customFieldsStore.customFieldsFiltered}
            mapValues={manualContactStore.customFieldsMap}
            setValues={(map) => manualContactStore.setCustomFieldsMap(map)}
            error={manualContactStore.error}
            clearError={() => manualContactStore.setError('')}
          />
        </>
      )}
    </div>
  )
})
