import { NewConversionContext } from 'widgets/conversions/NewConversion/context/context'
import { NewConversionUi } from 'widgets/conversions/NewConversion/ui/NewConversionUi'
import { useState } from 'react'
import {
  INewConversionStoreProps,
  NewConversionStore,
} from 'widgets/conversions/NewConversion/store/newConversionStore'

type INewConversionProps = INewConversionStoreProps

export const NewConversion = ({
  onClose,
  onSuccess,
  conversion,
}: INewConversionProps) => {
  const [store] = useState(
    () => new NewConversionStore({ onClose, onSuccess, conversion })
  )
  return (
    <NewConversionContext.Provider value={store}>
      <NewConversionUi />
    </NewConversionContext.Provider>
  )
}
