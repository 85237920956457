import { useParams } from 'react-router-dom'
import { useGetConversionQuery } from 'src/generated/graphql'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import { ConversionDetail } from 'widgets/conversions/ConversionDetail/ConversionDetail'

export const ConversionDetailPage = () => {
  const { id } = useParams()
  const { data, loading } = useGetConversionQuery({
    variables: {
      conversionId: id,
    },
  })
  if (loading) {
    return <AbsoluteLoader />
  }
  if (data?.getConversion) {
    return <ConversionDetail response={data.getConversion} />
  }
  return null
}
