import * as React from 'react'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import { useContactFunctions } from 'src/pages/main/contacts/hooks/useContactFunctions'
import { IContactActions } from 'src/pages/main/contacts/functions/getContactActions'

export const ContactsEditLists = (props: IContactActions) => {
  const { onEditLists } = useContactFunctions(props)

  return (
    <TableBtn
      {...props}
      action={ActionsEnum.editLists}
      onAction={onEditLists}
    />
  )
}
