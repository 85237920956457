import { StatusContent } from 'components/StatusContent/StatusContent'
import { Status } from 'src/generated/graphql'
import { numberString } from 'src/util/functions'
import { IWebForm } from 'src/types/IWebForm'
import { ColumnType } from 'components/NewTable/types'
import dayjs, { DayjsFormats } from 'lib/dayjs'
import { ItemsRender } from 'components/NewTable/cell/ItemsRender/ItemsRender'
import {
  conversionColumns,
  conversionColumnsIds,
} from 'src/pages/settings/conversions/config/columns'

export const webFormsColumns: ColumnType<IWebForm>[] = [
  { id: 'name', label: 'Name' },
  {
    id: 'status',
    label: 'Status',
    format: (value) => <StatusContent status={value as Status} />,
  },
  {
    id: 'contactsLists',
    label: 'List',
    minWidth: 143,
    format: (value, { items }) =>
      items ? <ItemsRender items={items || []} /> : <></>,
  },
  {
    id: 'createdAt',
    label: 'Created',
    format: (value) => dayjs(value as Date).format(DayjsFormats.fullWithAt),
  },
  {
    id: 'visitors',
    label: 'Visitors',
    format: (value) => numberString({ val: +value || 0 }),
  },
  {
    id: 'contactStatusStatistics.subscribed',
    label: 'Subscribers',
    format: (value, { contactStatusStatistics }) =>
      numberString({ val: +contactStatusStatistics?.subscribed || 0 }),
  },
  {
    id: 'contactStatusStatistics.confirmed',
    label: 'Confirmed',
    format: (value, { contactStatusStatistics }) =>
      numberString({ val: +contactStatusStatistics?.confirmed || 0 }),
  },
  {
    id: 'contactStatusStatistics.active',
    label: 'Active',
    format: (value, { contactStatusStatistics }) =>
      numberString({ val: +contactStatusStatistics?.active || 0 }),
  },
  {
    id: 'shortUrlClickStatistics',
    label: 'Clicks',
    format: (value, { shortUrlClickStatistics }) =>
      numberString({ val: shortUrlClickStatistics?.totalVisits || 0 }),
  },
  {
    id: 'contactStatusStatistics.unsubscribed',
    label: 'Unsubscribed',
    format: (value, { contactStatusStatistics }) =>
      numberString({ val: +contactStatusStatistics?.unsubscribed || 0 }),
  },
  ...conversionColumns,
]

export const noSortsColumns: string[] = [
  'contactsLists',
  'visitors',
  'contactStatusStatistics.subscribed',
  'contactStatusStatistics.confirmed',
  'contactStatusStatistics.active',
  'shortUrlClickStatistics',
  'contactStatusStatistics.unsubscribed',
  ...conversionColumnsIds,
]
