import { SizedBox } from 'components/SizedBox'
import { RangeBar } from 'components/RangeBar/RangeBar'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { Numbers } from 'src/pages/settings/compliance/TollFreeVerification/steps/Numbers'
import {
  TollFreeVerificationProfile,
  TollFreeVerificationStep,
} from 'src/generated/graphql'
import { useSetNumbers } from 'src/pages/settings/numbers/hooks/useSetNumbers'
import { FC, useEffect, useMemo } from 'react'
import { RoutesEnum } from 'src/pages/routes'
import { PropsTollFreeStep } from 'src/pages/settings/compliance/TollFreeVerification/types'
import numbersStore from 'store/settings/numbers/numbersStore'
import { BusinessInfo } from 'src/pages/settings/compliance/TollFreeVerification/steps/BusinessInfo'
import { UseCase } from 'src/pages/settings/compliance/TollFreeVerification/steps/UseCase'
import { ContactInfo } from 'src/pages/settings/compliance/TollFreeVerification/steps/ContactInfo'
import { OptInMethod } from 'src/pages/settings/compliance/TollFreeVerification/steps/OptInMethod/OptInMethod'
import { Terms } from 'src/pages/settings/compliance/TollFreeVerification/steps/Terms'

export const TollFreeVerificationContent = observer(
  ({ isModal }: { isModal?: boolean }) => {
    const navigate = useNavigate()
    const { loading } = useSetNumbers()

    const {
      stepCount,
      step,
      stepTitle,
      progressPercent,
      currentStep,
      setCompleted,
      setProfile,
      isSuperAdminModal,
    } = tollFreeVerificationStore

    useEffect(() => {
      if (
        !numbersStore.toolFreeNumbers.length &&
        !loading &&
        !isSuperAdminModal
      ) {
        navigate(RoutesEnum.settingsCompliance)
      }
    }, [numbersStore.toolFreeNumbers, loading])

    const onSuccess = (
      profile: TollFreeVerificationProfile,
      finish?: boolean
    ) => {
      if (finish) {
        setCompleted()
        if (isSuperAdminModal) {
          tollFreeVerificationStore.setOpenEditModal(false)
        } else {
          navigate(-1)
        }
      }

      if (profile) {
        setProfile(profile)
      }
    }
    const Content: FC<PropsTollFreeStep> | undefined = useMemo(() => {
      switch (currentStep) {
        case TollFreeVerificationStep.Numbers:
          return Numbers
        case TollFreeVerificationStep.BusinessInfo:
          return BusinessInfo
        case TollFreeVerificationStep.ContactInfo:
          return ContactInfo
        case TollFreeVerificationStep.UseCase:
          return UseCase
        case TollFreeVerificationStep.OptInMethod:
          return OptInMethod
        case TollFreeVerificationStep.Terms:
          return Terms
      }
    }, [step])
    return (
      <>
        {!isModal && (
          <>
            <span className={'gray1 letter1'}>Toll-Free verification</span>
            <SizedBox height={24} />
          </>
        )}
        <div className={'row-between'}>
          <h3>
            <b>{stepTitle}</b>
          </h3>
          <span className={'s2 medium gray1'}>
            Step {step} / {stepCount}
          </span>
        </div>
        <SizedBox height={16} />
        <RangeBar progress={progressPercent} style2 />
        <SizedBox height={40} />
        {Content && <Content onSuccess={onSuccess} />}
      </>
    )
  }
)
