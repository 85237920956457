import businessProfileStore from 'store/settings/company/businessProfileStore'
import { onOpenIntercomWidget } from 'src/util/intercom'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { NavLink } from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'
import { Alert } from 'components/Alert/Alert'
import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { AppIcon, IIconType } from 'components/AppIcon/AppIcon'
import { ColorsNames } from 'styles/variables'
import { ReactComponent as Close } from 'icons/16pxNoMask/Close.svg'
import { SizedBox } from 'components/SizedBox'

export const BusinessErrorAlert = observer(
  ({ fromModal }: { fromModal?: boolean }) => {
    const onHide = () => {
      businessProfileStore.setHideErrorAlert(true)
    }

    return (
      <>
        <SizedBox height={32} />
        <Alert
          title={
            <>
              {businessProfileStore.errors?.map((error) => (
                <>
                  {error.failureReason}
                  <br />
                </>
              ))}
            </>
          }
          text={
            <>
              Please update your Business Profile. <br /> <br /> Alternatively,
              Please{' '}
              <button className={'link s1 bold'} onClick={onOpenIntercomWidget}>
                contact us.
              </button>
            </>
          }
          type={AlertTypeEnum.error}
          rightContent={
            fromModal ? (
              <>
                {!businessProfileStore.isSuperAdminModal && (
                  <NavLink to={RoutesEnum.business} className={'linkError s1'}>
                    Update
                  </NavLink>
                )}
              </>
            ) : (
              <button className={'mt4'} onClick={onHide}>
                <AppIcon color={ColorsNames.black} type={IIconType.stroke}>
                  <Close />
                </AppIcon>
              </button>
            )
          }
        />
      </>
    )
  }
)
