import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { CheckBoxBtn } from 'components/CheckBoxBtn/CheckBoxBtn'
import { useAdvancedSchedulingContext } from 'src/widgets/AdvancedScheduling'
import { ActiveContent } from 'src/widgets/AdvancedScheduling/ui/ActiveContent/ActiveContent'

export const AdvancedSchedulingContent = observer(() => {
  const { active, toggleActive, activeAdvancedScheduleStore } =
    useAdvancedSchedulingContext()
  return (
    <div className={styles.wrap}>
      <div className={'p8'}>
        <CheckBoxBtn
          active={active}
          toggleActive={toggleActive}
          title={'Advanced scheduling'}
          isNew
        />
      </div>
      {activeAdvancedScheduleStore && (
        <ActiveContent store={activeAdvancedScheduleStore} />
      )}
    </div>
  )
})
