// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Q1EY07dj5BjGkNYZNJ1C{width:100%;display:grid;grid-template-columns:repeat(auto-fit, minmax(200px, 1fr));gap:10px;margin-bottom:24px;padding-top:8px}@media(max-width: 800px){.Q1EY07dj5BjGkNYZNJ1C{padding-inline:24px}}@media(max-height: 600px){.Q1EY07dj5BjGkNYZNJ1C{padding-inline:24px}}`, "",{"version":3,"sources":["webpack://./src/components/DetailCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,0DAAA,CACA,QAAA,CACA,kBAAA,CACA,eAAA,CACA,yBAPF,sBAQI,mBAAA,CAAA,CAEF,0BAVF,sBAWI,mBAAA,CAAA","sourcesContent":[".wrap{\n  width: 100%;\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));\n  gap: 10px;\n  margin-bottom: 24px;\n  padding-top: 8px;\n  @media (max-width: 800px) {\n    padding-inline: 24px;\n  }\n  @media (max-height: 600px) {\n    padding-inline: 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Q1EY07dj5BjGkNYZNJ1C`
};
export default ___CSS_LOADER_EXPORT___;
