import { ReactComponent as Broadcasts } from 'assets/icons/20px/Broadcasts.svg'
import { ReactComponent as Triggers } from 'assets/icons/20px/Triggers.svg'
import { ReactComponent as Keywords } from 'assets/icons/20px/Keywords.svg'
import { ReactComponent as Webforms } from 'assets/icons/20px/Webforms.svg'
import { ReactComponent as Campaigns } from 'assets/icons/20px/Campaigns.svg'
import { ReactComponent as Contacts } from 'assets/icons/20px/Contacts.svg'
import { ReactComponent as CompanyIcon } from 'assets/icons/20px/Company.svg'
import { ReactComponent as Help } from 'assets/icons/20px/Help & Support.svg'
import { ReactComponent as Add } from 'assets/icons/16px/Duotone/Add.svg'
import { ReactComponent as CRM } from 'assets/icons/20px/NoMask/CRM.svg'
import { ReactComponent as Import } from 'assets/icons/20px/NoMask/Import.svg'
import { ReactComponent as Megaphone } from 'assets/icons/20px/NoMask/Megaphone line.svg'
import { ReactComponent as Shield } from 'assets/icons/20px/NoMask/Shield.svg'
import { ReactComponent as Dollar } from 'assets/icons/Dollar.svg'
import { RoutesEnum } from 'src/pages/routes'
import { IIconType } from 'components/AppIcon/AppIcon'
import { INavItem, NavType } from 'components/NavBar/types'
import { AdminNotification } from 'components/NavBar/components/AdminNotification/AdminNotification'

export const navItems: INavItem[] = [
  {
    text: 'Dashboard',
    route: RoutesEnum.dashboard,
    type: NavType.dashboard,
  },
  {
    separator: true,
    separatorMargin: 16,
    text: 'Broadcasts',
    route: RoutesEnum.broadcasts,
    LeftIcon: Broadcasts,
    RightIcon: Add,
    rightIconRoute: RoutesEnum.newBroadcast,
    type: NavType.navLink,
    marginBottom: 4,
  },
  {
    text: 'Campaigns',
    route: RoutesEnum.campaigns,
    rightIconRoute: RoutesEnum.newCampaign,
    LeftIcon: Campaigns,
    RightIcon: Add,
    type: NavType.navLink,
    marginBottom: 4,
    isNew: true,
  },
  {
    text: 'Triggers',
    route: RoutesEnum.triggers,
    LeftIcon: Triggers,
    RightIcon: Add,
    type: NavType.navLink,
    marginBottom: 4,
    rightIconRoute: RoutesEnum.newTrigger,
  },
  {
    text: 'Keywords',
    route: RoutesEnum.keywords,
    LeftIcon: Keywords,
    RightIcon: Add,
    type: NavType.navLink,
    marginBottom: 4,
    rightIconRoute: RoutesEnum.newKeyword,
  },
  {
    text: 'Web Forms',
    route: RoutesEnum.webForms,
    LeftIcon: Webforms,
    RightIcon: Add,
    type: NavType.navLink,
    marginBottom: 4,
    rightIconRoute: RoutesEnum.newWebForm,
  },
  // {
  //   text: 'Automations',
  //   route: RoutesEnum.automations,
  //   LeftIcon: Automations,
  //   RightIcon: Add,
  //   type: NavType.navLink,
  //   marginBottom: 4,
  // },
  {
    text: 'Contacts',
    route: RoutesEnum.contacts,
    LeftIcon: Contacts,
    RightIcon: Add,
    type: NavType.navLink,
    marginBottom: 4,
  },
  {
    text: 'Admin Panel',
    route: RoutesEnum.adminOrganizations,
    LeftIcon: CRM,
    noMask: true,
    iconType: IIconType.stroke,
    // RightIcon: Add,
    type: NavType.navLink,
    separator: true,
    rightContent: <AdminNotification />,
    // marginBottom: 20,
  },
]

export const HelpNavItem: INavItem = {
  text: 'Help & Support',
  type: NavType.help,
  LeftIcon: Help,
}

export const ProductUpdatesNavItem: INavItem = {
  text: 'Product Updates',
  type: NavType.productUpdates,
  LeftIcon: Megaphone,
  marginBottom: 4,
}

export const SwitchOrganizationNavItem: INavItem = {
  text: 'Switch Organization',
  type: NavType.switchOrg,
  marginBottom: 4,
  separator: true,
}

export const navItemsSettings: INavItem[] = [
  {
    text: 'Settings',
    type: NavType.settings,
  },
  {
    separator: true,
    separatorMargin: 16,
    text: 'Account',
    route: RoutesEnum.account,
    leftIcon: 'account',
    type: NavType.navLink,
    marginBottom: 4,
  },
  {
    text: 'Company',
    route: RoutesEnum.company,
    leftIcon: 'company',
    type: NavType.navLink,
    marginBottom: 4,
  },
  {
    text: 'Compliance',
    route: RoutesEnum.compliance,
    leftIcon: 'shield',
    type: NavType.navLink,
    marginBottom: 4,
  },
  {
    text: 'Billing & Usage',
    route: RoutesEnum.billing,
    leftIcon: 'billing',
    type: NavType.navLink,
    marginBottom: 4,
  },
  {
    text: 'Numbers',
    route: RoutesEnum.numbers,
    leftIcon: 'tollFree',
    type: NavType.navLink,
    marginBottom: 4,
  },
  {
    text: 'Integrations',
    isNew: true,
    route: RoutesEnum.integrations,
    leftIcon: 'integrations',
    type: NavType.navLink,
    marginBottom: 4,
  },
  {
    text: 'Conversions',
    isNew: true,
    route: RoutesEnum.conversions,
    leftIcon: 'conversion',
    type: NavType.navLink,
    marginBottom: 4,
  },
  {
    text: 'Audio',
    route: RoutesEnum.audio,
    leftIcon: 'audio',
    type: NavType.navLink,
    separatorBottom: true,
  },
  {
    text: 'Log Out',
    type: NavType.logout,
    marginBottom: 0,
  },
]

export const navItemsAdmin: INavItem[] = [
  {
    text: 'Admin Panel',
    type: NavType.settings,
    marginBottom: 20,
  },
  {
    text: 'Organizations',
    route: RoutesEnum.organizations,
    LeftIcon: CompanyIcon,
    type: NavType.navLink,
    marginBottom: 4,
  },
  {
    text: 'Imports',
    route: RoutesEnum.imports,
    LeftIcon: Import,
    noMask: true,
    type: NavType.navLink,
    marginBottom: 4,
    rightContent: <AdminNotification isImports />,
  },
  {
    text: 'Custom Plans',
    route: RoutesEnum.customPlans,
    LeftIcon: Dollar,
    noMask: true,
    iconType: IIconType.stroke,
    type: NavType.navLink,
    marginBottom: 4,
  },
  {
    text: 'Compliance',
    route: RoutesEnum.compliance,
    LeftIcon: Shield,
    noMask: true,
    iconType: IIconType.stroke,
    type: NavType.navLink,
    marginBottom: 4,
    rightContent: <AdminNotification isCompliance />,
  },
]
