// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VK3Pf9N9Guavpv76ShDY{margin-bottom:32px}@media(max-width: 800px){.VK3Pf9N9Guavpv76ShDY{padding-inline:24px}}@media(max-height: 600px){.VK3Pf9N9Guavpv76ShDY{padding-inline:24px}}`, "",{"version":3,"sources":["webpack://./src/pages/settings/company/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,yBAFF,sBAGI,mBAAA,CAAA,CAGF,0BANF,sBAOI,mBAAA,CAAA","sourcesContent":[".wrapTabs{\n  margin-bottom: 32px;\n  @media (max-width: 800px) {\n    padding-inline: 24px;\n\n  }\n  @media (max-height: 600px) {\n    padding-inline: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapTabs": `VK3Pf9N9Guavpv76ShDY`
};
export default ___CSS_LOADER_EXPORT___;
