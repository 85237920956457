// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sfQ9Wyhav2a1ACd3Jdni{display:flex;height:100%;flex-grow:1;overflow:auto}.F8nQOrlgQ2nyHQeS9s8V{display:grid;grid-template-rows:76px var(--page-height)}`, "",{"version":3,"sources":["webpack://./src/layout/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CACA,WAAA,CACA,aAAA,CAGF,sBACE,YAAA,CACA,0CAAA","sourcesContent":[".wrap{\n  display: flex;\n  height: 100%;\n  flex-grow: 1;\n  overflow: auto;\n}\n\n.pageWrap{\n  display: grid;\n  grid-template-rows: 76px var(--page-height);\n}\n.page{\n  //@media (max-width: 800px) {\n  //  max-height: calc(100vh - 76px);\n  //  //overflow-y: scroll;\n  //}\n  //@media (max-height: 600px) {\n  //  max-height: calc(100vh - 76px);\n  //  //overflow-y: scroll;\n  //}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `sfQ9Wyhav2a1ACd3Jdni`,
	"pageWrap": `F8nQOrlgQ2nyHQeS9s8V`
};
export default ___CSS_LOADER_EXPORT___;
