// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DPey48TmfVO5t5wnRPmv{margin-bottom:32px;padding-inline:64px}@media(max-width: 800px){.DPey48TmfVO5t5wnRPmv{padding-inline:24px}}@media(max-height: 600px){.DPey48TmfVO5t5wnRPmv{padding-inline:24px}}`, "",{"version":3,"sources":["webpack://./src/pages/main/contacts/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,mBAAA,CACA,yBAHF,sBAII,mBAAA,CAAA,CAGF,0BAPF,sBAQI,mBAAA,CAAA","sourcesContent":[".wrapTabs{\n  margin-bottom: 32px;\n  padding-inline: 64px;\n  @media (max-width: 800px) {\n    padding-inline: 24px;\n\n  }\n  @media (max-height: 600px) {\n    padding-inline: 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapTabs": `DPey48TmfVO5t5wnRPmv`
};
export default ___CSS_LOADER_EXPORT___;
