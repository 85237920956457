import { getActionsByStatus } from 'src/pages/main/campaigns/functions/getActionsByStatus'
import { ActionsEnum } from 'components/Button/types'
import { FC } from 'react'
import { ActionBtnProps } from 'components/NewTable/types'
import { ICampaign } from 'src/types/ICampaign'
import { campaignsBtnByAction } from 'src/pages/main/campaigns/functions/campaignsBtnByAction'

const manyActionsStatics = [
  ActionsEnum.switch,
  ActionsEnum.archive,
  ActionsEnum.restore,
  ActionsEnum.resume,
  ActionsEnum.delete,
]

export const getManyCampaignsActions = (
  campaigns: ICampaign[],
  fromLeftStickyContent?: boolean
): Array<FC<ActionBtnProps<ICampaign>>> => {
  const campaignsActions: ActionsEnum[][] = []
  const campaignsStatuses = campaigns.map((item) => item.status)

  campaignsStatuses.forEach((status) => {
    if (status) {
      campaignsActions.push(getActionsByStatus(status))
    }
  })

  const manyActions = manyActionsStatics.filter((action) =>
    campaignsActions.every((bActions) => bActions.includes(action))
  )

  const actionsFC: Array<FC<ActionBtnProps<ICampaign>>> = []

  manyActions.forEach((action) => {
    const btn: FC<ActionBtnProps<ICampaign>> | undefined =
      campaignsBtnByAction[action as keyof typeof campaignsBtnByAction]
    if (btn) {
      actionsFC.push((props) =>
        btn({ ...props, rows: campaigns, fromLeftStickyContent })
      )
    }
  })
  return actionsFC
}
