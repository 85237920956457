import { IStatusProps } from 'components/Status'
import { InvoiceStatus } from 'src/generated/graphql'

const statuses: { [key in InvoiceStatus]: IStatusProps } = {
  Draft: {
    title: 'Draft',
    icon: 'draft',
    color: '--primary-color-gray-1',
  },
  Open: {
    title: 'Open',
    icon: 'clock',
    color: '--states-color-notify-1',
  },
  Paid: {
    title: 'Paid',
    icon: 'checkCircle',
    color: '--states-color-success-1',
  },
  Refunded: {
    title: 'Refunded',
    icon: 'backCircle',
    color: '--primary-color-gray-1',
  },
  UnCollectible: {
    title: 'Uncollectible',
    icon: 'minusCircle',
    color: '--secondary-color-orange-1',
  },
  Undefined: {
    title: 'Undefined',
    icon: 'minusCircle',
    color: '--primary-color-gray-1',
  },
  Void: {
    title: 'Void',
    icon: 'closeCircle',
    color: '--states-color-error-1',
  },
}

export type IStatusesKeys = keyof typeof statuses

export default statuses
