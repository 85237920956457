import dayjs, { PluginFunc } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import user from 'store/user/user'

export default function (
  date?: string | number | Date | dayjs.Dayjs | null | undefined,
  plugin?: PluginFunc<any>
) {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(advancedFormat)
  plugin && dayjs.extend(plugin)
  return dayjs(date).tz(user.timezone?.zoneId || 'America/New_York')
}

export enum DayjsFormats {
  full = 'MMM D, YYYY h:mm A',
  full2 = 'dddd, MMMM D, YYYY [@] h:mm A',
  full3 = 'ddd, MMM D, YYYY',
  fullWithAt = 'MMM D, YYYY [at] h:mm A',
  fullWithAt2 = 'MMM D, YYYY [at] h:mm a',
  fullWithAtDash = `MMM D, YYYY [•] h:mma`,
  date = 'MMM D, YYYY',
  dateSort = 'MM/DD/YYYY',
  fullSlash = 'MM/DD/YYYY h:mm A',
  fullWithTZ = 'MMM D, YYYY h:mm A Z',
  time = 'h:mm a',
}

export const timestampToAbsoluteDay = (timestamp: number) =>
  timestamp / 1000 / 60 / 60 / 24

export function useTimeZoneDate({
  startDate,
  onChange,
}: {
  startDate: Date | null | undefined
  onChange: (val: Date | null) => void
}) {
  const split = user.timezone?.offset?.split(':')

  const offsetHoursUser = split?.length ? parseInt(split[0]) || 0 : 0
  const localOffset = new Date().getTimezoneOffset() / 60
  const offsetHours = offsetHoursUser + localOffset

  const startDateTz = startDate
    ? dayjs(startDate).add(offsetHours, 'hour').toDate()
    : null

  const handleChange = (date: Date | null) => {
    onChange(date ? dayjs(date).add(-offsetHours, 'hour').toDate() : null)
  }

  return {
    startDateTz,
    handleChange,
  }
}
