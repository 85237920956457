import { getCssColor, IColor } from 'styles/colors'

export const Separator = ({
  color = '--primary-color-gray-3' as IColor,
  width = '100%',
  marginLeft = 0,
  marginBlock = 0,
}) => (
  <div
    style={{
      height: 1,
      width,
      background: getCssColor(color),
      marginLeft,
      marginBlock,
    }}
  />
)
