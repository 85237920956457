import { observer } from 'mobx-react-lite'
import { Row } from 'src/pages/settings/compliance/modals/components/Row/Row'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'

export const BusinessInfo = observer(() => {
  const { businessInfo, addressString } = tollFreeVerificationStore
  return (
    <div className={'col16'}>
      <p className={'par2'}>
        <b>Business info</b>
      </p>
      <div className={'col12'}>
        <Row title={'Business name'} value={businessInfo?.name} />
        <Row title={'Website URL'} value={businessInfo?.website} />
        <Row title={'Address'} value={addressString} />
      </div>
    </div>
  )
})
