// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Nwh2hUGYQxcF0dfbNMWC{height:76px;display:flex;justify-content:space-between;align-items:center;border-bottom:1px solid var(--primary-color-gray-3);padding:0 32px 0 24px}.YEaMov5bKy6KZt7Af70N{display:flex;align-items:center;column-gap:12px}`, "",{"version":3,"sources":["webpack://./src/pages/main/dashboard/Objectives/ActiveObjective/Header/styles.module.scss"],"names":[],"mappings":"AACA,sBACE,WAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,mDAAA,CACA,qBAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,eAAA","sourcesContent":["@import \"src/styles/mixin\";\n.header{\n  height: 76px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 1px solid var(--primary-color-gray-3);\n  padding: 0 32px 0 24px;\n}\n.title{\n  display: flex;\n  align-items: center;\n  column-gap: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Nwh2hUGYQxcF0dfbNMWC`,
	"title": `YEaMov5bKy6KZt7Af70N`
};
export default ___CSS_LOADER_EXPORT___;
