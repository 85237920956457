import { observer } from 'mobx-react-lite'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { Icon } from 'components/Icon/Icon'
import { useEffect, useRef } from 'react'
import audioStore from 'store/audioStore/audioStore'
import { useCreateTextToSpeechModalContext } from 'src/Modals/CreateTextToSpeechModal/context/context'

export const PreviewSpeech = observer(() => {
  const {
    disabledPlay,
    playing,
    handlePlay,
    audioSrc,
    setPlaying,
    loadingPreview,
    textKey,
    setAudioDuration,
  } = useCreateTextToSpeechModalContext()
  const refAudio = useRef<HTMLAudioElement>(null)

  const handelEnded = () => {
    setPlaying(false)
  }

  useEffect(() => {
    if (audioSrc && playing) {
      audioStore.setPlayAudioId(textKey)
      refAudio.current?.play()
    } else {
      if (audioSrc && !playing) {
        refAudio.current?.pause()
      }
    }
    if (audioStore.playAudioId !== textKey && playing) {
      refAudio.current?.pause()
    }
  }, [audioSrc, playing, audioStore.playAudioId])
  return (
    <>
      <Button
        onClick={handlePlay}
        typeBtn={playing ? BtnType.secondaryBlue : BtnType.secondaryGray}
        size={BtnSize.medium}
        disabled={disabledPlay}
        loading={loadingPreview}
      >
        {playing ? (
          <>
            <Icon icon={'pause'} color={'--primary-color-blue-1'} />
            Pause
          </>
        ) : (
          <>
            <Icon
              icon={'play'}
              color={
                disabledPlay
                  ? '--primary-color-gray-2'
                  : '--primary-color-gray-1'
              }
            />
            Play
          </>
        )}
      </Button>
      {audioSrc && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio
          ref={refAudio}
          src={audioSrc}
          style={{ display: 'none' }}
          onEnded={handelEnded}
          onLoadedData={(e) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            setAudioDuration(textKey, Math.ceil(e.target.duration))
          }}
        ></audio>
      )}
    </>
  )
})
