import { useEffect, useRef } from 'react'

export const useClickAway = <E extends HTMLElement>(
  callback: () => void,
  requiredParent?: HTMLElement | null
) => {
  const ref = useRef<E | null>(null)

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (!ref.current) return

      if (requiredParent) {
        const isInside = event.composedPath().includes(requiredParent)
        if (!isInside) return
      }

      const isInside = event.composedPath().includes(ref.current)
      if (isInside) return

      callback()
    }

    document.addEventListener('click', handleClick, true)

    return () => document.removeEventListener('click', handleClick, true)
  }, [callback])

  return ref
}
