import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import campaignsStore from 'store/campaigns/campaignsStore'
import { OneSelectContent } from 'src/pages/main/campaigns/CampaignsTable/Filters/OneSelectContent'
import { ManySelectContent } from 'src/pages/main/campaigns/CampaignsTable/Filters/ManySelectContent'
import { NoSelectContent } from 'src/pages/main/campaigns/CampaignsTable/Filters/NoSelectContent'

export const Filters = observer(() => {
  const content = useMemo(() => {
    if (campaignsStore.tableStore.checkedRowsIds.length) {
      if (
        campaignsStore.tableStore.checkedRows.length &&
        campaignsStore.tableStore.checkedRows.length === 1
      ) {
        return (
          <OneSelectContent
            campaign={campaignsStore.tableStore.checkedRows[0]}
          />
        )
      }
      return <ManySelectContent />
    }
    return <NoSelectContent />
  }, [campaignsStore.tableStore.checkedRowsIds])
  return <div className={styles.wrapActions}>{content}</div>
})
