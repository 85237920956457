// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ra2eAP9NVOuD6cvvuI3C{margin:8px 0 0 0}.XvWvT_78KRhmEkPXWE5Q{display:flex;align-items:center;justify-content:right;column-gap:8px}.aacWhLwlYnUJMNZXjc5x{display:grid;grid-template-columns:200px 1fr;column-gap:12px;align-items:end;margin-bottom:24px}.hgIjc_E7t6RYoc67cFdZ{border:1px solid var(--primary-color-gray-3);border-radius:8px}.Ul02Nf7Ebm5t937nnLP0{border-bottom:none}.gjZgZKmlmJdaK8jeXLl_{padding:0 12px;display:flex;height:38px;justify-content:space-between;align-items:center}.ypZYGpgszjJeBj7VNf1g{padding:12px 12px 0 12px;border-top:1px solid var(--primary-color-gray-3)}`, "",{"version":3,"sources":["webpack://./src/pages/main/webForms/create/createForm/style.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,qBAAA,CACA,cAAA,CAEF,sBACE,YAAA,CACA,+BAAA,CACA,eAAA,CACA,eAAA,CACA,kBAAA,CAGF,sBACE,4CAAA,CACA,iBAAA,CAEF,sBACE,kBAAA,CAEF,sBACE,cAAA,CACA,YAAA,CACA,WAAA,CACA,6BAAA,CACA,kBAAA,CAEF,sBACE,wBAAA,CACA,gDAAA","sourcesContent":[".inputWrap{\n  margin: 8px 0 0 0;\n}\n.switch{\n  display: flex;\n  align-items: center;\n  justify-content: right;\n  column-gap: 8px;\n}\n.sendWrap{\n  display: grid;\n  grid-template-columns: 200px 1fr;\n  column-gap: 12px;\n  align-items: end;\n  margin-bottom: 24px;\n}\n\n.fieldWrap{\n  border: 1px solid var(--primary-color-gray-3);\n  border-radius: 8px;\n}\n.textArea{\n  border-bottom: none;\n}\n.fieldHeader{\n  padding: 0 12px;\n  display: flex;\n  height: 38px;\n  justify-content: space-between;\n  align-items: center;\n}\n.textAreaWrap{\n  padding: 12px 12px 0 12px;\n  border-top: 1px solid var(--primary-color-gray-3);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrap": `Ra2eAP9NVOuD6cvvuI3C`,
	"switch": `XvWvT_78KRhmEkPXWE5Q`,
	"sendWrap": `aacWhLwlYnUJMNZXjc5x`,
	"fieldWrap": `hgIjc_E7t6RYoc67cFdZ`,
	"textArea": `Ul02Nf7Ebm5t937nnLP0`,
	"fieldHeader": `gjZgZKmlmJdaK8jeXLl_`,
	"textAreaWrap": `ypZYGpgszjJeBj7VNf1g`
};
export default ___CSS_LOADER_EXPORT___;
