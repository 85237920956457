import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { Alert } from 'components/Alert/Alert'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { SizedBox } from 'components/SizedBox'
import { TollFreeVerificationCard } from 'src/pages/settings/compliance/TollFreeVerificationCard/TollFreeVerificationCard'

export const ResumeTollFreeContent = ({
  onAction,
  onClose,
}: {
  onClose: () => void
  onAction: () => void
}) => {
  return (
    <div className={'medium2Modal'}>
      <Alert
        type={AlertTypeEnum.notify2}
        title={'Resume Toll-Free verification'}
        text={
          'After you complete the verification, you’ll be able to use your toll-free number to send messages without carrier filtering to US and Canada.'
        }
      />
      <SizedBox height={32} />
      <TollFreeVerificationCard noAction />
      <div className={'row12 mt40'}>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          onClick={onAction}
          className={'mb8'}
        >
          Resume
        </Button>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.secondaryGray}
          onClick={onClose}
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}
