// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IZrEL0ya6oh1G8PNtFsY{border:1px solid var(--primary-color-gray-3);border-radius:12px;padding:16px;display:grid;grid-template-columns:1fr max-content;justify-content:space-between;align-items:center}`, "",{"version":3,"sources":["webpack://./src/pages/settings/billing/modals/DisabledAutoRechargeModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,4CAAA,CACA,kBAAA,CACA,YAAA,CACA,YAAA,CACA,qCAAA,CACA,6BAAA,CACA,kBAAA","sourcesContent":[".card{\n  border: 1px solid var(--primary-color-gray-3);\n  border-radius: 12px;\n  padding: 16px;\n  display: grid;\n  grid-template-columns: 1fr max-content;\n  justify-content: space-between;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `IZrEL0ya6oh1G8PNtFsY`
};
export default ___CSS_LOADER_EXPORT___;
