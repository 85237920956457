// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iRLPmwYD9_F1BOkylK4g{padding:0 8px 32px 32px}.qlVStJ5mYxzwQTmdCJhi{margin-right:24px;display:grid;grid-template-columns:250px 214px;background-color:var(--primary-color-gray-4);border-radius:8px 8px 0 0}.qlVStJ5mYxzwQTmdCJhi div{padding:12px}.tobSk_K1e44RtkiaX12h{padding:8px 18px 16px 0}.fr8Jf7GXNTi1JyEpzEmi{display:grid;grid-template-columns:216px 1fr max-content;column-gap:12px;align-items:center;padding:8px 12px 8px 8px}.pW4N1OFbNkco4HjvFQr2{display:flex;align-items:center;column-gap:8px}.vrClF0yuThgiwGRtqUYD{max-height:349px;overflow:scroll;margin-bottom:32px}.wIJtqBy4xkpTPDyLAEmv{padding-left:32px}`, "",{"version":3,"sources":["webpack://./src/pages/main/contacts/customFields/modals/CustomFieldsModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,uBAAA,CAEF,sBACE,iBAAA,CACA,YAAA,CACA,iCAAA,CACA,4CAAA,CACA,yBAAA,CACA,0BACE,YAAA,CAIJ,sBACE,uBAAA,CAEF,sBACE,YAAA,CACA,2CAAA,CACA,eAAA,CACA,kBAAA,CACA,wBAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,cAAA,CAEF,sBACE,gBAAA,CACA,eAAA,CACA,kBAAA,CAEF,sBACE,iBAAA","sourcesContent":[".wrap{\n  padding: 0 8px 32px 32px;\n}\n.header{\n  margin-right: 24px;\n  display: grid;\n  grid-template-columns: 250px 214px;\n  background-color: var(--primary-color-gray-4);\n  border-radius: 8px 8px 0 0;\n  div{\n    padding: 12px;\n  }\n\n}\n.content{\n  padding: 8px 18px 16px 0;\n}\n.item{\n  display: grid;\n  grid-template-columns: 216px 1fr max-content;\n  column-gap: 12px;\n  align-items: center;\n  padding: 8px 12px 8px 8px;\n}\n.wrapInput{\n  display: flex;\n  align-items: center;\n  column-gap: 8px;\n}\n.wrapContent{\n  max-height: 349px;\n  overflow: scroll;\n  margin-bottom: 32px;\n}\n.wrapError{\n  padding-left: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `iRLPmwYD9_F1BOkylK4g`,
	"header": `qlVStJ5mYxzwQTmdCJhi`,
	"content": `tobSk_K1e44RtkiaX12h`,
	"item": `fr8Jf7GXNTi1JyEpzEmi`,
	"wrapInput": `pW4N1OFbNkco4HjvFQr2`,
	"wrapContent": `vrClF0yuThgiwGRtqUYD`,
	"wrapError": `wIJtqBy4xkpTPDyLAEmv`
};
export default ___CSS_LOADER_EXPORT___;
