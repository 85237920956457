import styles from 'src/routes/main/dashboard/Performance/PerformanceChart/styles.module.scss'
import { numberString } from 'src/util/functions'
import { SizedBox } from 'components/SizedBox'
import { FC } from 'react'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'shared/Typography'

type Props = {
  info: {
    count: number
    profit: number
    label: string
  }
}

export const ChartInfo: FC<Props> = ({ info }) => {
  return (
    <>
      <div className={styles.success}>
        <h2 className={'regular'}>{numberString({ val: info.count })}</h2>
        {!!info.profit && (
          <div className={styles.improve}>
            <Icon
              icon={'upArrow'}
              fontSize={9}
              color={'--states-color-success-1'}
            />
            <Typography color={'--states-color-success-1'}>
              {info.profit}%
            </Typography>
          </div>
        )}
      </div>
      <SizedBox height={8} />
      <span>{info.label}</span>
      <SizedBox height={40} />
    </>
  )
}
