import {
  GetBroadcastEstimatedDurationDocument,
  GetBroadcastEstimatedDurationQuery,
  GetBroadcastEstimatedDurationQueryVariables,
  GetBroadcastSpeedOptionsDocument,
  GetBroadcastSpeedOptionsQuery,
} from 'src/generated/graphql'
import apiSore from 'store/apiSore'

export const BroadcastApi = {
  getBroadcastSpeedOptions: () =>
    apiSore.client.query<GetBroadcastSpeedOptionsQuery>({
      query: GetBroadcastSpeedOptionsDocument,
    }),

  getBroadcastEstimatedDuration: (
    variables: GetBroadcastEstimatedDurationQueryVariables
  ) =>
    apiSore.client.query<
      GetBroadcastEstimatedDurationQuery,
      GetBroadcastEstimatedDurationQueryVariables
    >({
      query: GetBroadcastEstimatedDurationDocument,
      variables,
    }),
}
