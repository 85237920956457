import classNames from 'classnames'
import styles from 'components/NavBar/styles.module.scss'
import { LinkWrapper } from 'components/Wrappers/LinkWrapper'
import { useLocation } from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as Close } from 'assets/icons/16px/Close.svg'

export const SettingsNav = ({ text }: { text: string }) => {
  const location = useLocation()
  const isActive = location.pathname.includes(RoutesEnum.settings)

  return (
    <div className={classNames(styles.settings, isActive && styles.active)}>
      <span>{text}</span>
      <LinkWrapper to={RoutesEnum.broadcasts}>
        <Button
          typeBtn={BtnType.primary}
          size={BtnSize.small}
          className={styles.settingsIcon}
        >
          <Close />
        </Button>
      </LinkWrapper>
    </div>
  )
}
