import styles from './styles.module.scss'
import contactStore from 'store/contacts/contactStore'
import { observer } from 'mobx-react-lite'
import { ReactNode, useMemo } from 'react'
import { OneSelectContent } from 'src/pages/main/contacts/ContactsTable/Filters/OneSelectContent'
import { ManySelectContent } from 'src/pages/main/contacts/ContactsTable/Filters/ManySelectContent'
import { NoSelectContent } from 'src/pages/main/contacts/ContactsTable/Filters/NoSelectContent'
import { BtnSize } from 'components/Button/Button'

export const Filters = observer(
  ({
    noSelectContent,
    extraBtnSize,
  }: {
    noSelectContent?: ReactNode
    extraBtnSize?: BtnSize
  }) => {
    const content = useMemo(() => {
      if (
        contactStore.tableStoreContacts.checkedRowsIds.length === 1 &&
        contactStore.tableStoreContacts.checkedRows.length
      ) {
        return <OneSelectContent extraBtnSize={extraBtnSize} />
      }
      if (contactStore.tableStoreContacts.checkedRowsIds.length > 1) {
        return <ManySelectContent extraBtnSize={extraBtnSize} />
      }
      return noSelectContent || <NoSelectContent />
    }, [contactStore.tableStoreContacts.checkedRowsIds, contactStore.contacts])
    return <div className={styles.wrapActions}>{content}</div>
  }
)
