import { createContext, useContext } from 'react'
import { NewConversionStore } from 'widgets/conversions/NewConversion/store/newConversionStore'

export const NewConversionContext = createContext<NewConversionStore | null>(
  null
)

export const useNewConversionContext = () => {
  const context = useContext(NewConversionContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with NewConversionContext'
    )

  return context
}
