import styles from './styles.module.scss'
import { ColorsNames } from 'styles/variables'
import { ReactComponent as Phone } from 'icons/16pxNoMask/Phone.svg'
import { TestItBtn } from 'components/TestItBtn/TestItBtn'
import { TypeContent } from 'components/TypeContent/TypeContent'
import { ReactComponent as StatusIcon } from 'icons/16pxNoMask/Status/Status.svg'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { ReactComponent as Calendar } from 'icons/16pxNoMask/Calendar.svg'
import { ReactComponent as ContactsFill } from 'icons/16pxNoMask/ContactsFill.svg'
import {
  CampaignDelayType,
  CampaignScheduleType,
  CampaignStepResponse,
  ContentType,
} from 'src/generated/graphql'
import { ICampaign } from 'src/types/ICampaign'
import classNames from 'classnames'
import { DetailTypeContent } from 'components/DetailsModalContent/DetailTypeContent'
import { ReactComponent as Deliver } from 'icons/16pxNoMask/Deliver.svg'
import {
  operatorsOptions,
  periodsOptions,
} from 'components/Schedule/store/mockData'
import dayjs, { DayjsFormats } from 'lib/dayjs'
import { AppSymbols } from 'src/util/symbols'
import { ItemsRender } from 'components/NewTable/cell/ItemsRender/ItemsRender'
import {
  transformRinglessMessageToInput,
  transformSMSToInput,
  transformVoiceMessageToInput,
} from 'src/util/transform'

export const DetailCampaignContent = ({
  campaign,
}: {
  campaign: ICampaign
}) => {
  const messageRender = (step: CampaignStepResponse, index: number) => {
    // const getStatus = () => {
    //   if (campaign.status === Status.Archived) {
    //     return Status.Archived
    //   }
    //   if (campaign.status === Status.Disabled) {
    //     return Status.Disabled
    //   }
    //   return message.active ? Status.Active : Status.Disabled
    // }

    const getTimeContent = () => {
      if (step.delayType === CampaignDelayType.Immediate) {
        return 'Immediately'
      }
      const periodTitle =
        periodsOptions.find((opt) => opt.value === step.delay?.period)?.title ||
        step.delay?.period

      const operatorTitle =
        operatorsOptions.find((opt) => opt.value === step.delay?.scheduleType)
          ?.title || step.delay?.scheduleType

      const suffix = step.delay?.hour
        ? `${AppSymbols.point} ${dayjs()
            .startOf('day')
            .add(step.delay.hour, 'hour')
            .add(step.delay.minute || 0, 'minute')
            .format('h:mm A')}`
        : ''

      if (step.delayType === CampaignDelayType.Delay) {
        return `${step.delay?.value} ${periodTitle}${
          Number(step.delay?.value) > 1 ? 's' : ''
        } after ${suffix}`
      }
      if (step.delayType === CampaignDelayType.Date) {
        return `${dayjs(step.delay?.sendDate).format(DayjsFormats.fullWithAt)}`
      }
      if (step.delayType === CampaignDelayType.DelayCustomField) {
        if (step.delay?.scheduleType === CampaignScheduleType.On) {
          return `On the ${step.delay?.customField?.name || ''}`
        }
        return `${step.delay?.value} ${periodTitle}${
          Number(step.delay?.value) > 1 ? 's' : ''
        } ${operatorTitle} ${
          step.delay?.customField ? `the ${step.delay?.customField.name}` : ''
        } ${suffix}`
      }
      return ''
    }

    return (
      <div key={step.id} className={styles.content}>
        <div className={styles.row}>
          <div className={'row8'}>
            <div className={styles.counter}>
              <span className={'medium blue1'}>{index + 1}</span>
            </div>
            <h3>{step.name}</h3>
          </div>
          <TestItBtn
            type={step.type || ContentType.Sms}
            testMessageInput={
              step.type === ContentType.Sms && step.smsMessage
                ? {
                    fromNumberId: step.phoneNumber?.id,
                    smsMessage: transformSMSToInput(step.smsMessage),
                    toNumber: '',
                  }
                : undefined
            }
            testCallMessageRequestInput={
              step.type === ContentType.Voice && step.voiceMessage
                ? {
                    voiceMessage: transformVoiceMessageToInput(
                      step.voiceMessage
                    ),
                    toNumber: '',
                    fromNumberId: step.phoneNumber?.id,
                  }
                : undefined
            }
            testRinglessMessageRequestInput={
              step.type === ContentType.Ringless && step.ringlessMessage
                ? {
                    ...transformRinglessMessageToInput(step.ringlessMessage),
                    fromNumberId: step.phoneNumber?.id,
                    toNumber: '',
                  }
                : undefined
            }
          />
        </div>
        <div className={styles.grid}>
          <Row
            leftContent={
              <>
                <StatusIcon />
                <span className={ColorsNames.gray1}>Status</span>
              </>
            }
            rightContent={
              campaign.status ? (
                <StatusContent status={campaign.status} />
              ) : (
                <></>
              )
            }
          />
          <Row
            leftContent={
              <>
                <Calendar />
                <span className={ColorsNames.gray1}>Send time</span>
              </>
            }
            rightContent={<span>{getTimeContent()}</span>}
          />
          <Row
            leftContent={
              <>
                <Phone />
                <span className={ColorsNames.gray1}>Send from</span>
              </>
            }
            rightContent={<span>{step.phoneNumber?.friendlyName}</span>}
          />
          <Row
            leftContent={
              <>
                <Deliver />
                <span className={ColorsNames.gray1}>Message type</span>
              </>
            }
            rightContent={<TypeContent type={step.type || ContentType.Sms} />}
          />
        </div>

        <DetailTypeContent
          smsMessage={step.smsMessage}
          ringlessMessage={step.ringlessMessage}
          voiceMessage={step.voiceMessage}
        />
      </div>
    )
  }
  return (
    <>
      <div className={styles.header}>
        <Row
          leftContent={
            <>
              <ContactsFill />
              <span className={ColorsNames.gray1}>Enroll</span>
            </>
          }
          rightContent={<ItemsRender items={campaign.items || []} />}
        />
      </div>
      <div className={'col32'}>
        {campaign.campaignStepResponses?.map(messageRender)}
      </div>
    </>
  )
}

const Row = ({
  leftContent,
  rightContent,
  startLabel,
}: {
  leftContent: JSX.Element
  rightContent: JSX.Element
  startLabel?: boolean
}) => (
  <div className={styles.rowTable}>
    <div className={classNames(styles.left, startLabel && styles.start)}>
      {leftContent}
    </div>
    {rightContent}
  </div>
)
