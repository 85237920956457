// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v89kcGuT6pCA8nVOfVLP{border:1px solid var(--primary-color-gray-3);border-radius:16px;padding:20px}`, "",{"version":3,"sources":["webpack://./src/pages/auth/migration/stpes/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,4CAAA,CACA,kBAAA,CACA,YAAA","sourcesContent":[".wrapUl{\n  border: 1px solid var(--primary-color-gray-3);\n  border-radius: 16px;\n  padding: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapUl": `v89kcGuT6pCA8nVOfVLP`
};
export default ___CSS_LOADER_EXPORT___;
