// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KxYpWcYKRfQEdXb9usLr{border-radius:8px;padding:16px;border:1px solid var(--primary-color-gray-3);background:var(--primary-color-white);display:grid;grid-template-columns:1fr max-content;column-gap:48px;row-gap:16px;align-items:center}.KxYpWcYKRfQEdXb9usLr.EIWV6rm_4Dh13eUvan0y{background:var(--primary-color-gray-4)}`, "",{"version":3,"sources":["webpack://./src/widgets/TrackConversion/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,4CAAA,CACA,qCAAA,CACA,YAAA,CACA,qCAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,2CACE,sCAAA","sourcesContent":[".wrap{\n  border-radius: 8px;\n  padding: 16px;\n  border: 1px solid var(--primary-color-gray-3);\n  background: var(--primary-color-white);\n  display: grid;\n  grid-template-columns: 1fr max-content;\n  column-gap: 48px;\n  row-gap: 16px;\n  align-items: center;\n  &.active{\n    background: var(--primary-color-gray-4);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `KxYpWcYKRfQEdXb9usLr`,
	"active": `EIWV6rm_4Dh13eUvan0y`
};
export default ___CSS_LOADER_EXPORT___;
