import { useEffect } from 'react'
import { ICardDetail } from 'components/DetailCard/DetailCard/DetailCard'
import { observer } from 'mobx-react-lite'
import contactStore from 'store/contacts/contactStore'
import {
  useGetListContactsStatisticsQuery,
  useGetMyOrganizationContactsStatisticsQuery,
} from 'src/generated/graphql'
import { TableStatuses, TableStore } from 'components/NewTable/store/TableStore'
import { IContact } from 'src/types/IContact'
import { DetailCardsWrapper } from 'components/DetailCard/DetailCardsWrapper'

export const AdditionalContent = observer(
  ({
    tableStore,
    listId,
  }: {
    tableStore: TableStore<IContact>
    listId?: string
  }) => {
    const { data, refetch } = listId
      ? useGetListContactsStatisticsQuery({ variables: { listId } })
      : useGetMyOrganizationContactsStatisticsQuery()

    const statistics = listId
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        data?.getListContactsStatistics
      : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        data?.getMyOrganizationContactsStatistics
    const totalCount = statistics?.totalCount || 0
    const activeCount = statistics?.activeCount || 0
    const invalidCount = statistics?.invalidCount || 0
    const archivedCount = statistics?.archivedCount || 0
    const unsubscribedCount = statistics?.unsubscribedCount || 0
    const inactiveCount = statistics?.inactiveCount || 0
    const getPercent = (count: number) =>
      Math.round((count / totalCount) * 100) || 0
    const cards: ICardDetail[] = [
      {
        title: 'All contacts',
        status: TableStatuses.totalCount,
        value: TableStatuses.totalCount,
        count: statistics?.totalCount || 0,
        percent: totalCount ? 100 : 0,
      },
      {
        title: 'Active',
        value: TableStatuses.activeCount,
        status: TableStatuses.activeCount,
        count: activeCount,
        percent: getPercent(activeCount),
      },
      {
        title: 'Inactive',
        value: TableStatuses.inactiveCount,
        status: TableStatuses.inactiveCount,
        count: inactiveCount,
        percent: getPercent(inactiveCount),
      },
      {
        title: 'Archived',
        value: TableStatuses.archivedCount,
        status: TableStatuses.archivedCount,
        count: archivedCount,
        percent: getPercent(archivedCount),
      },
      {
        title: 'Unsubscribed',
        value: TableStatuses.unsubscribedCount,
        status: TableStatuses.unsubscribedCount,
        count: unsubscribedCount,
        percent: getPercent(unsubscribedCount),
      },
      {
        title: 'Invalid',
        value: TableStatuses.invalidCount,
        status: TableStatuses.invalidCount,
        count: invalidCount,
        percent: getPercent(invalidCount),
      },
    ]

    useEffect(() => {
      refetch()
    }, [
      contactStore.tableStoreContacts.refreshTrigger,
      contactStore.statisticTrigger,
    ])

    if (
      !contactStore.contacts.length &&
      tableStore.status === TableStatuses.totalCount &&
      !tableStore.search.trim()
    ) {
      return <></>
    }
    return <DetailCardsWrapper cards={cards} tableStore={tableStore} />
  }
)
