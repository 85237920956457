import { PropsWithChildren } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'

export const Container = ({
  children,
  small,
}: PropsWithChildren<{ small?: boolean }>) => (
  <div className={classNames(styles.wrap, { [styles.small]: small })}>
    {children}
  </div>
)
