import styles from './styles.module.scss'
import { ReactComponent as Logo } from 'assets/images/Logo Horizontal.svg'
import { Steps } from 'components/Modal/Steps/Steps'
import { observer } from 'mobx-react-lite'
import auth from 'store/auth'
import { CreateAccountStep } from 'src/pages/auth/migration/stpes/CreateAccountStep'
import { ContactDetailsStep } from 'src/pages/auth/migration/stpes/ContactDetailsStep'
import { CompanyDetailsStep } from 'src/pages/auth/migration/stpes/CompanyDetailsStep'
import { MigrationStepContent } from 'src/pages/auth/migration/stpes/MigrationStepContent'
import { ChoosePlanStep } from 'src/pages/auth/migration/stpes/ChoosePlanStep/ChoosePlanStep'
import { PaymentStep } from 'src/pages/auth/migration/stpes/PaymentStep/PaymentStep'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as Close } from 'icons/16pxNoMask/Close.svg'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RoutesEnum, UnauthorizedRoutes } from 'src/pages/routes'
import { useEffect } from 'react'
import user from 'store/user/user'
import { useLogout } from 'components/NavBar/components/Logout'
import migrationStore from 'store/migration/migrationStore'

export enum MigrationStep {
  CreateAccount = 'Create account',
  ContactDetails = 'Contact details',
  CompanyDetails = 'Company details',
  Migration = 'Migration',
  ChoosePlan = 'Choose plan',
  Payment = 'Payment',
}

const migrationsStep = [
  <CreateAccountStep key={0} />,
  <ContactDetailsStep key={1} />,
  <CompanyDetailsStep key={2} />,
  <MigrationStepContent key={3} />,
  <ChoosePlanStep key={4} />,
  <PaymentStep key={5} />,
]

export const Migration = observer(() => {
  const { onLogout } = useLogout()
  const [params] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    const migrationParams: typeof auth.migrationParams = {}
    params.forEach((value, key) => {
      migrationParams[key as keyof typeof auth.migrationParams] = value
    })
    auth.setMigrationParams(migrationParams)
  }, [params])

  useEffect(() => {
    auth.setIsMigration()
  }, [])

  useEffect(() => {
    if (user.member?.registrationFinished) {
      navigate(RoutesEnum.create)
    }
  }, [user.member?.registrationFinished])

  const onClose = async () => {
    onLogout(true)
    navigate(UnauthorizedRoutes.login)
  }
  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <Logo />
        <div className={styles.steps}>
          <Steps
            steps={Object.values(MigrationStep)}
            activeStep={migrationStore.migrationStep || 0}
          />
        </div>
        <div className={styles.right}>
          <Button
            size={BtnSize.small}
            typeBtn={BtnType.secondaryGray}
            onClick={onClose}
          >
            <Close />
          </Button>
        </div>
      </div>
      <div className={styles.content}>
        {migrationsStep[migrationStore.migrationStep || 0]}
      </div>
    </div>
  )
})
