import NewTable from 'components/NewTable/NewTable'
import { ICampaign } from 'src/types/ICampaign'
import { observer } from 'mobx-react-lite'
import { AdditionalContent } from 'src/pages/main/campaigns/detail/OverviewDetailTable/AdditionalContent/AdditionalContent'
import { RangeDatePicker } from 'components/RangeDatePicker/RangeDatePicker'
import styles from './styles.module.scss'
import campaignDetailStore from 'store/campaigns/campaignDetailStore'
import * as React from 'react'

export const OverviewDetailTable = observer(() => {
  return (
    <div className={styles.wrap}>
      <div className={styles.wrapDate}>
        <RangeDatePicker
          rangeDatePickerStore={
            campaignDetailStore.overviewRangeDatePickerStore
          }
        />
        {/*<Dropdown*/}
        {/*  options={repeatsOptions}*/}
        {/*  selectedOption={campaignDetailStore.overviewPeriod}*/}
        {/*  onSelect={(opt) => campaignDetailStore.setOverviewPeriod(opt)}*/}
        {/*  dropDownContentWidth={120}*/}
        {/*/>*/}
      </div>
      {campaignDetailStore.campaign && (
        <NewTable<ICampaign>
          columns={campaignDetailStore.overViewTableStore.visibleColumns}
          rows={[campaignDetailStore.campaign]}
          noStickyHeader
          tableStore={campaignDetailStore.overViewTableStore}
          noPagination
          noSetting
          noSorting
          additionalContent={<AdditionalContent />}
          withCalcMaxHeight
        />
      )}
    </div>
  )
})
