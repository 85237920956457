// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s_3iOwg1LQ7XZL7vJfOO{background-color:var(--primary-color-gray-4);border-radius:12px;padding:16px 0 16px 16px;font-size:14px;line-height:20px;color:var(--primary-color-gray-1)}.JidjF4WV__09_NxZ0NKB{padding:8px;max-height:423px;overflow-y:auto;padding-right:16px}.vvbpfCzN9c2anDJ8kuU8{margin-top:40px;width:88px}`, "",{"version":3,"sources":["webpack://./src/pages/main/webForms/modals/GetWebFromCodeModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBAGE,4CAAA,CACA,kBAAA,CACA,wBAAA,CACA,cAAA,CACA,gBAAA,CACA,iCAAA,CAEF,sBACE,WAAA,CACA,gBAAA,CACA,eAAA,CACA,kBAAA,CAGF,sBACE,eAAA,CACA,UAAA","sourcesContent":[".content{\n  //margin-top: 32px;\n\n  background-color: var(--primary-color-gray-4);\n  border-radius: 12px;\n  padding: 16px 0 16px 16px;\n  font-size: 14px;\n  line-height: 20px;\n  color: var(--primary-color-gray-1);\n}\n.innerContent{\n  padding: 8px;\n  max-height: 423px;\n  overflow-y: auto;\n  padding-right: 16px;\n\n}\n.copyBtn{\n  margin-top: 40px;\n  width: 88px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `s_3iOwg1LQ7XZL7vJfOO`,
	"innerContent": `JidjF4WV__09_NxZ0NKB`,
	"copyBtn": `vvbpfCzN9c2anDJ8kuU8`
};
export default ___CSS_LOADER_EXPORT___;
