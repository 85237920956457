import { makeAutoObservable, runInAction } from 'mobx'
import type { IListItem } from 'components/SelectList/ListItem/ListItem'
import { Currency, UpdateConversionRequestInput } from 'src/generated/graphql'
import { ConversionApi } from 'src/api/settings/conversions/api'

import alertStore from 'store/alertStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import {
  ConversionResponse,
  IConversion,
} from 'src/pages/settings/conversions/conversions/store/type'

export type INewConversionStoreProps = {
  onClose: () => void
  onSuccess: (response: ConversionResponse) => void
  conversion?: IConversion
}

export class NewConversionStore {
  onClose
  onSuccess
  constructor({ onClose, onSuccess, conversion }: INewConversionStoreProps) {
    this.onClose = onClose
    this.onSuccess = onSuccess
    if (conversion) {
      this.init(conversion)
    }

    makeAutoObservable(this)
  }

  id = null
  editConversion: IConversion | null = null

  get isEdit() {
    return !!this.id
  }

  init = (conversion: IConversion) => {
    this.editConversion = conversion
    this.id = conversion.id
    this.name = conversion.name || ''
    this.url = conversion.urlPattern || ''
    this.currency = conversion.currency || null
    this.defaultAmount = conversion.defaultAmount || null
    this.conversionAttribute = conversion.uniqueHit ? 'one' : 'every'
  }

  get conversion(): UpdateConversionRequestInput {
    return {
      id: this.id || undefined,
      name: this.name,
      urlPattern: this.url,
      currency: this.currency!,
      defaultAmount: this.defaultAmount,
      uniqueHit: this.conversionAttribute === 'one',
    }
  }

  get disabledCreate() {
    if (!this.name.trim().length) return true
    if (!this.url.trim().length) return true
    if (!this.currency) return true
    if (!this.conversionAttribute) return true
    return false
  }

  loading = false
  onCreate = async () => {
    try {
      this.loading = true
      const { data } = await ConversionApi.create({
        conversion: this.conversion,
      })
      if (data?.createConversion) {
        this.onSuccess(data.createConversion)
      }
      this.onClose()
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }
  onSaveEdit = async () => {
    try {
      this.loading = true
      const { data } = await ConversionApi.update({
        conversion: this.conversion,
      })
      if (data?.updateConversion) {
        this.onSuccess(data.updateConversion)
      }
      alertStore.setAlert({
        type: AlertTypeEnum.success,
        title: 'Conversion updated',
      })
      this.onClose()
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  name = ''
  setName = (name: string) => {
    this.name = name
  }

  url = ''
  setUrl = (url: string) => {
    this.url = url
  }

  mapCurrencyOptions: Map<Currency, Omit<IListItem, 'isActive'>> = new Map([
    [
      Currency.Usd,
      {
        id: Currency.Usd,
        text: 'USD - United States Dollar',
      },
    ],
    [
      Currency.Ca,
      {
        id: Currency.Ca,
        text: 'CAD - Canadian Dollar',
      },
    ],
  ])
  currency: Currency | null = Currency.Usd
  get currencyTitle() {
    if (this.currency) {
      return this.mapCurrencyOptions.get(this.currency)?.text
    }
  }
  setCurrency = (value: typeof this.currency) => {
    this.currency = value
  }

  get currencyOptions(): IListItem[] {
    return Array.from(this.mapCurrencyOptions.values()).map((item) => ({
      ...item,
      isActive: item.id === this.currency,
    }))
  }

  defaultAmount: number | null = null
  setDefaultAmount = (value: typeof this.defaultAmount) => {
    this.defaultAmount = value
  }

  mapConversionAttributesOptions: Map<
    'one' | 'every',
    Omit<IListItem, 'isActive'>
  > = new Map([
    [
      'one',
      {
        id: 'one',
        text: 'Count one conversion per contact',
      },
    ],
    [
      'every',
      {
        id: 'every',
        text: 'Count every conversion',
      },
    ],
  ])
  conversionAttribute: 'one' | 'every' | null = null
  get conversionAttributeTitle() {
    if (this.conversionAttribute) {
      return this.mapConversionAttributesOptions.get(this.conversionAttribute)
        ?.text
    }
  }
  setConversionAttribute = (value: typeof this.conversionAttribute) => {
    this.conversionAttribute = value
  }

  get conversionAttributeOptions(): IListItem[] {
    return Array.from(this.mapConversionAttributesOptions.values()).map(
      (item) => ({
        ...item,
        isActive: item.id === this.conversionAttribute,
      })
    )
  }
}
