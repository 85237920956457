import NewTable from 'components/NewTable/NewTable'
import * as React from 'react'
import { AdditionalContent } from 'src/pages/main/triggers/detail/TriggersDetailTable/AdditionalContent/AdditionalContent'
import { LeftStickyContent } from 'src/pages/main/triggers/detail/TriggersDetailTable/LeftStickyContent/LeftStickyContent'
import {
  GetTriggerMessagesQuery,
  useGetTriggerMessagesQuery,
} from 'src/generated/graphql'
import { observer } from 'mobx-react-lite'
import { useTriggersDetailContext } from 'src/pages/main/triggers/detail/triggerDetail'
import { IStatisticMessage } from 'src/types/IDetailMessage'
import { EmptyStateStatistic } from 'components/EmptyState/EmptyStateStatistic'
import { useSetData } from 'src/hooks/useSetData'
import { messageStatisticsNoSortsColumns } from 'components/NewTable/columns/messageStatisticsColumns'

export const TriggersDetailTable = observer(() => {
  const context = useTriggersDetailContext()
  const { trigger, tableStore, statisticMessages } = context

  const queryResult = useGetTriggerMessagesQuery({
    variables: {
      page: tableStore.paginationInput,
      status: tableStore.statusBackEnd,
      dateRange: tableStore.rangeDatePickerStore.dateRange,
      uuid: trigger?.uuid || '',
    },
    skip: tableStore.rangeDatePickerStore.isSkipByDateRange,
  })

  const setData = (data: GetTriggerMessagesQuery) => {
    tableStore.setTotal(data.getTriggerMessages?.total || 0)
    context.setMessages(data)
  }

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData,
    refreshDeps: [tableStore.refreshTrigger],
    disabledRefetch: tableStore.rangeDatePickerStore.isSkipByDateRange,
  })

  return (
    <NewTable<IStatisticMessage>
      columns={tableStore.visibleColumns}
      noSortsColumns={messageStatisticsNoSortsColumns}
      rows={statisticMessages}
      withCalcMaxHeight
      additionalContent={<AdditionalContent />}
      leftStickyContent={<LeftStickyContent />}
      emptyContent={
        statisticMessages.length ? undefined : (
          <EmptyStateStatistic
            description={
              'There are not any contacts that received the message yet'
            }
          />
        )
      }
      tableStore={tableStore}
      pageLoading={loading || refetchLoading}
      firstLoading={firstLoading}
      contentLoading={loading && !statisticMessages.length}
    />
  )
})
