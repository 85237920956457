import { ConversionsTableContext } from 'widgets/conversions/ConversionsTable/context/context'
import { ConversionsTableStore } from 'widgets/conversions/ConversionsTable/store/conversionsTableStore'
import { ConversionsTableUi } from 'widgets/conversions/ConversionsTable/ui/ConversionsTableUi'

export const ConversionsTable = ({
  store,
}: {
  store: ConversionsTableStore
}) => (
  <ConversionsTableContext.Provider value={store}>
    <ConversionsTableUi />
  </ConversionsTableContext.Provider>
)
