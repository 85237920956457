// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gGNQhG8hiUns0rrzidGQ{display:flex;flex-grow:1;position:relative;background:var(--primary-color-gray-4)}.v7lMebd7McKd5vpdmTLf{padding-top:64px;padding-left:64px;pointer-events:none;height:calc(100vh - 82px);overflow:hidden}.v7lMebd7McKd5vpdmTLf h1{margin-bottom:28px}.vBaUw7Jv2NsEwrwsPYzX{position:absolute;top:0;bottom:0;left:0;right:0;display:flex;justify-content:center;align-items:center;overflow:hidden;height:100vh;background:hsla(0,0%,100%,.3)}@media(max-width: 800px){.vBaUw7Jv2NsEwrwsPYzX{height:calc(100vh - 76px)}}@media(max-height: 600px){.vBaUw7Jv2NsEwrwsPYzX{height:calc(100vh - 76px)}}`, "",{"version":3,"sources":["webpack://./src/pages/main/dashboard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CACA,iBAAA,CACA,sCAAA,CAGF,sBACE,gBAAA,CACA,iBAAA,CAEA,mBAAA,CACA,yBAAA,CACA,eAAA,CACA,yBACE,kBAAA,CAGJ,sBACE,iBAAA,CACA,KAAA,CACA,QAAA,CACA,MAAA,CACA,OAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,eAAA,CACA,YAAA,CAEA,6BAAA,CACA,yBAbF,sBAcI,yBAAA,CAAA,CAEF,0BAhBF,sBAiBI,yBAAA,CAAA","sourcesContent":[".wrapPage{\n  display: flex;\n  flex-grow: 1;\n  position: relative;\n  background: var(--primary-color-gray-4);\n}\n\n.wrap{\n  padding-top: 64px;\n  padding-left: 64px;\n  //TODO: comingSoon\n  pointer-events: none;\n  height: calc(100vh - 82px);\n  overflow: hidden;\n  h1{\n    margin-bottom: 28px;\n  }\n}\n.comingSoon{\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  overflow: hidden;\n  height: 100vh;\n\n  background: rgba(255,255,255,0.3);\n  @media (max-width: 800px) {\n    height: calc(100vh - 76px);\n  }\n  @media (max-height: 600px) {\n    height: calc(100vh - 76px);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapPage": `gGNQhG8hiUns0rrzidGQ`,
	"wrap": `v7lMebd7McKd5vpdmTLf`,
	"comingSoon": `vBaUw7Jv2NsEwrwsPYzX`
};
export default ___CSS_LOADER_EXPORT___;
