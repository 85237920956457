// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.W5ZT1_BLGEEgq77e_CzG{padding-top:32px;position:relative;margin-bottom:40px}.W5ZT1_BLGEEgq77e_CzG:before{content:"";position:absolute;height:1px;background-color:var(--primary-color-gray-3);width:calc(100% + 64px);left:-32px;top:0}.vs2ZGPDFTHalgTjeBDCM{margin-bottom:16px;margin-top:20px}.hRuwn1wBwxmzfALpXLSs{padding-left:8px;padding-right:12px;height:56px;display:grid;grid-template-columns:max-content 1fr;align-items:center;column-gap:8px}.chkN8jwACdUFZRWovFMd{display:grid;grid-template-columns:1fr min-content;align-items:center}.EDiNnu42gLbLifFoxVPk{margin-left:12px}.SZdpWZv_bdhLV63rUOPc{display:flex;column-gap:8px;align-items:center;padding-right:8px}`, "",{"version":3,"sources":["webpack://./src/pages/main/webForms/create/createForm/FieldsCollect/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,iBAAA,CACA,kBAAA,CACA,6BACE,UAAA,CACA,iBAAA,CACA,UAAA,CACA,4CAAA,CACA,uBAAA,CACA,UAAA,CACA,KAAA,CAGJ,sBACE,kBAAA,CACA,eAAA,CAEF,sBACE,gBAAA,CACA,kBAAA,CACA,WAAA,CACA,YAAA,CACA,qCAAA,CACA,kBAAA,CACA,cAAA,CAEF,sBACE,YAAA,CACA,qCAAA,CACA,kBAAA,CAEF,sBACE,gBAAA,CAEF,sBACE,YAAA,CACA,cAAA,CACA,kBAAA,CACA,iBAAA","sourcesContent":[".wrap{\n  padding-top: 32px;\n  position: relative;\n  margin-bottom: 40px;\n  &:before{\n    content: '';\n    position: absolute;\n    height: 1px;\n    background-color: var(--primary-color-gray-3);\n    width: calc(100% + 64px);\n    left: -32px;\n    top: 0;\n  }\n}\n.fieldsWrap{\n  margin-bottom: 16px;\n  margin-top: 20px;\n}\n.field{\n  padding-left: 8px;\n  padding-right: 12px;\n  height: 56px;\n  display: grid;\n  grid-template-columns: max-content 1fr;\n  align-items: center;\n  column-gap: 8px;\n}\n.fieldRight{\n  display: grid;\n  grid-template-columns: 1fr min-content;\n  align-items: center;\n}\n.deleteWrap{\n  margin-left: 12px;\n}\n.switch{\n  display: flex;\n  column-gap: 8px;\n  align-items: center;\n  padding-right: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `W5ZT1_BLGEEgq77e_CzG`,
	"fieldsWrap": `vs2ZGPDFTHalgTjeBDCM`,
	"field": `hRuwn1wBwxmzfALpXLSs`,
	"fieldRight": `chkN8jwACdUFZRWovFMd`,
	"deleteWrap": `EDiNnu42gLbLifFoxVPk`,
	"switch": `SZdpWZv_bdhLV63rUOPc`
};
export default ___CSS_LOADER_EXPORT___;
