import { BtnSize, Button } from 'components/Button/Button'
import { ReactComponent as Google } from 'icons/Google.svg'
import { useGoogleLogin } from '@react-oauth/google'
import auth from 'store/auth'
import { UnauthorizedRoutes } from 'src/pages/routes'
import { useNavigate } from 'react-router-dom'

export const AppGoogleLogin = ({
  fromSignUp,
  select_account,
  invitationUuid,
  // onSuccess,
  disabled,
}: {
  fromSignUp?: boolean
  disabled?: boolean
  invitationUuid?: string
  select_account?: boolean
  autoLoad?: boolean
  onSuccess?: () => void
  onFailed?: (message: string) => void
}) => {
  const navigate = useNavigate()
  const onBanned = () => {
    navigate(UnauthorizedRoutes.ban)
  }
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      await auth.onSignInWithGoogle(
        tokenResponse.access_token,
        invitationUuid,
        onBanned
        // onSuccess
      )
    },
    prompt: select_account ? 'select_account' : '',
  })

  const onClick = () => {
    login()
  }
  return (
    <>
      <Button
        size={BtnSize.medium}
        google
        mb={20}
        onClick={onClick}
        fullWidth
        disabled={disabled}
      >
        <Google />
        Sign {fromSignUp ? 'up' : 'in'} with Google
      </Button>
    </>
  )
}
