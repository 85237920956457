// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gvQv2zcE3NKyCRSVNbaD{display:grid}.S8VEkyJQY9R0ug9tBvwf{padding-top:30px;padding-left:64px;margin-bottom:18px}.MeegxOA3W1uG1DiIcYmU{display:flex;justify-content:space-between;align-items:center;padding-left:24px;padding-right:64px;padding-bottom:40px}.eNXL1ydx47i5juomMKz5{display:flex;gap:8px;align-items:center}.AHCR6X0oq857R1zsTfJT{display:flex;gap:12px}`, "",{"version":3,"sources":["webpack://./src/shared/Header/HeaderDetail/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAEF,sBACE,gBAAA,CACA,iBAAA,CACA,kBAAA,CAEF,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,iBAAA,CACA,kBAAA,CACA,mBAAA,CAEF,sBACE,YAAA,CACA,OAAA,CACA,kBAAA,CAEF,sBACE,YAAA,CACA,QAAA","sourcesContent":[".wrap{\n  display: grid;\n}\n.wrapBreadcrumbs{\n  padding-top: 30px;\n  padding-left: 64px;\n  margin-bottom: 18px;\n}\n.content{\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding-left: 24px;\n  padding-right: 64px;\n  padding-bottom: 40px;\n}\n.left{\n  display: flex;\n  gap: 8px;\n  align-items: center;\n}\n.right{\n  display: flex;\n  gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `gvQv2zcE3NKyCRSVNbaD`,
	"wrapBreadcrumbs": `S8VEkyJQY9R0ug9tBvwf`,
	"content": `MeegxOA3W1uG1DiIcYmU`,
	"left": `eNXL1ydx47i5juomMKz5`,
	"right": `AHCR6X0oq857R1zsTfJT`
};
export default ___CSS_LOADER_EXPORT___;
