import NewTable from 'components/NewTable/NewTable'
import React from 'react'
import { AdditionalContent } from 'src/pages/main/broadcasts/broadcastDetail/BroadcastDetailTable/AdditionalContent/AdditionalContent'
import { useGetBroadcastMessagesQuery } from 'src/generated/graphql'
import { observer } from 'mobx-react-lite'
import { useBroadcastDetailContext } from 'src/pages/main/broadcasts/broadcastDetail/broadcastDetail'
import { EmptyStateStatistic } from 'components/EmptyState/EmptyStateStatistic'
import { IStatisticMessage } from 'src/types/IDetailMessage'
import { useSetData } from 'src/hooks/useSetData'
import { messageStatisticsNoSortsColumns } from 'components/NewTable/columns/messageStatisticsColumns'

export const BroadcastsDetailTable = observer(() => {
  const context = useBroadcastDetailContext()
  const { broadcast, tableStore, statisticMessages } = context

  const queryResult = useGetBroadcastMessagesQuery({
    variables: {
      uuid: broadcast?.uuid,
      page: tableStore.paginationInput,
      status: tableStore.statusBackEnd,
    },
  })

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData: (data) => context.setMessages(data),
    refreshDeps: [tableStore.refreshTrigger],
  })

  return (
    <NewTable<IStatisticMessage>
      columns={tableStore.visibleColumns}
      rows={statisticMessages || []}
      withCalcMaxHeight
      additionalContent={<AdditionalContent />}
      tableStore={tableStore}
      pageLoading={loading || refetchLoading}
      firstLoading={firstLoading}
      emptyContent={
        statisticMessages.length ? undefined : <EmptyStateStatistic />
      }
      contentLoading={loading && !statisticMessages.length}
      noSortsColumns={messageStatisticsNoSortsColumns}
    />
  )
})
