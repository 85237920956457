import { StatusContent } from 'components/StatusContent/StatusContent'
import { Status } from 'src/generated/graphql'
import { numberString } from 'src/util/functions'
import { ICampaign } from 'src/types/ICampaign'
import { ColumnType } from 'components/NewTable/types'
import dayjs, { DayjsFormats } from 'lib/dayjs'
import { ItemsRender } from 'components/NewTable/cell/ItemsRender/ItemsRender'
import {
  conversionColumns,
  conversionColumnsIds,
} from 'src/pages/settings/conversions/config/columns'

export const campaignColumns: ColumnType<ICampaign>[] = [
  { id: 'name', label: 'Name' },
  {
    id: 'status',
    label: 'Status',
    format: (value) => <StatusContent status={value as Status} />,
  },
  {
    id: 'contactsListsIds',
    label: 'List',
    format: (value, { items }) =>
      items ? <ItemsRender items={items || []} /> : <></>,
  },
  {
    id: 'createdAt',
    label: 'Created',
    format: (value) => dayjs(value as Date).format(DayjsFormats.fullWithAt),
  },
  {
    id: 'total',
    label: 'Total',
    format: (value, { messagesStat }) =>
      numberString({ val: +messagesStat?.totalCount }),
  },
  {
    id: 'success',
    label: 'Success',
    format: (value, { messagesStat }) =>
      numberString({ val: +messagesStat?.successCount }),
  },
  {
    id: 'shortUrlClickStatistics',
    label: 'Clicks',
    format: (value, { shortUrlClickStatistics }) =>
      numberString({ val: shortUrlClickStatistics?.totalVisits || 0 }),
  },
  {
    id: 'pending',
    label: 'Pending',
    format: (value, { messagesStat }) =>
      numberString({ val: +messagesStat?.pendingCount }),
  },
  {
    id: 'failed',
    label: 'Failed',
    format: (value, { messagesStat }) =>
      numberString({ val: +messagesStat?.failedCount }),
  },
  {
    id: 'unsubscribed',
    label: 'Unsubscribed',
    format: (value, { messagesStat }) =>
      numberString({ val: +messagesStat?.unsubscribedCount }),
  },
  ...conversionColumns,
]

export const noSortsColumnsCampaigns = [
  'contactsListsIds',
  'shortUrlClickStatistics',
  'total',
  'success',
  'pending',
  'scheduled',
  'unsubscribed',
  'failed',
  ...conversionColumnsIds,
]
