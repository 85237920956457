// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LqFUx4qGkSCZ5UNAlUCc{display:flex;column-gap:12px}`, "",{"version":3,"sources":["webpack://./src/pages/main/keywords/KeywordsTable/Filters/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,eAAA","sourcesContent":[".wrapActions{\n  display: flex;\n  column-gap: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapActions": `LqFUx4qGkSCZ5UNAlUCc`
};
export default ___CSS_LOADER_EXPORT___;
