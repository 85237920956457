// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WOxArWnbPdG23bRFebyh{margin:8px 0 0 0;display:grid;grid-template-columns:1fr 100px}.gw36kxnvqzirV3M5wFCF{display:flex;align-items:center;justify-content:right;column-gap:8px}.OFOkndDy693Y8Yxra2WA{display:grid;grid-template-columns:200px 1fr;column-gap:12px;align-items:end;margin-bottom:24px}.xg2Zr06sHW_sTGlkLUqt{display:grid;row-gap:8px;margin-bottom:40px}.VQ4NWR9LqmE34bQaoYBQ{display:flex;column-gap:12px}.tz40LxIE8NCExJTo2oJ1{width:100%}.xziwpyE0APfpKmDCazQj{min-width:294px}`, "",{"version":3,"sources":["webpack://./src/pages/main/triggers/create/components/style.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,YAAA,CACA,+BAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,qBAAA,CACA,cAAA,CAEF,sBACE,YAAA,CACA,+BAAA,CACA,eAAA,CACA,eAAA,CACA,kBAAA,CAKF,sBACE,YAAA,CACA,WAAA,CACA,kBAAA,CAEF,sBACE,YAAA,CACA,eAAA,CAEF,sBACE,UAAA,CAEF,sBACE,eAAA","sourcesContent":[".inputWrap{\n  margin: 8px 0 0 0;\n  display: grid;\n  grid-template-columns: 1fr 100px;\n}\n.switch{\n  display: flex;\n  align-items: center;\n  justify-content: right;\n  column-gap: 8px;\n}\n.sendWrap{\n  display: grid;\n  grid-template-columns: 200px 1fr;\n  column-gap: 12px;\n  align-items: end;\n  margin-bottom: 24px;\n}\n\n\n\n.appDropdownContent{\n  display: grid;\n  row-gap: 8px;\n  margin-bottom: 40px;\n}\n.appDropdownWrap{\n  display: flex;\n  column-gap: 12px;\n}\n.appDrop{\n  width: 100%;\n}\n.phoneDrop{\n  min-width: 294px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrap": `WOxArWnbPdG23bRFebyh`,
	"switch": `gw36kxnvqzirV3M5wFCF`,
	"sendWrap": `OFOkndDy693Y8Yxra2WA`,
	"appDropdownContent": `xg2Zr06sHW_sTGlkLUqt`,
	"appDropdownWrap": `VQ4NWR9LqmE34bQaoYBQ`,
	"appDrop": `tz40LxIE8NCExJTo2oJ1`,
	"phoneDrop": `xziwpyE0APfpKmDCazQj`
};
export default ___CSS_LOADER_EXPORT___;
