import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import objectivesStore from 'store/dashboard/objectives/objectivesStore'
import { IObjective } from 'store/dashboard/objectives/types'
import { RoutesEnum } from 'src/pages/routes'
import styles from './styles.module.scss'
import { HeaderActiveObject } from 'src/pages/main/dashboard/Objectives/ActiveObjective/Header/HeaderActiveObject'
import { CourseContent } from 'src/pages/main/dashboard/Objectives/ActiveObjective/CourseContent/CourseContent'
import { ActiveObjectiveContent } from 'src/pages/main/dashboard/Objectives/ActiveObjective/ActiveObjectiveContent/ActiveObjectiveContent'

export const ActiveObjective = observer(() => {
  const { step } = useParams()
  const navigate = useNavigate()
  const activeObjective: IObjective | undefined =
    objectivesStore.objectivesMap.get(Number(step))
  if (!activeObjective) {
    navigate(RoutesEnum.dashboard)
    return <></>
  }
  return (
    <div className={styles.wrap}>
      <HeaderActiveObject />
      <div className={styles.content}>
        <ActiveObjectiveContent />
        <CourseContent />
      </div>
    </div>
  )
})
