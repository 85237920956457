import NewTable from 'components/NewTable/NewTable'
import * as React from 'react'
import { useGetWebFormContactsQuery } from 'src/generated/graphql'
import { useWebFormDetailContext } from 'src/pages/main/webForms/detail/webFormDetailPage'
import { AdditionalContent } from 'src/pages/main/webForms/detail/WebFromDetailTable/AdditionalContent/AdditionalContent'
import { LeftStickyContent } from 'src/pages/main/webForms/detail/WebFromDetailTable/LeftStickyContent/LeftStickyContent'
import { observer } from 'mobx-react-lite'
import { EmptyStateStatistic } from 'components/EmptyState/EmptyStateStatistic'
import { useSetData } from 'src/hooks/useSetData'
import { IContact } from 'src/types/IContact'

export const WebFromDetailTable = observer(() => {
  const webFormDetailStore = useWebFormDetailContext()
  const { webForm, tableStore, contacts } = webFormDetailStore

  const queryResult = useGetWebFormContactsQuery({
    variables: {
      page: tableStore.paginationInput,
      status: tableStore.statusBackEnd,
      dateRange: tableStore.rangeDatePickerStore.dateRange,
      uuid: webForm?.uuid || '',
    },
    skip: tableStore.rangeDatePickerStore.isSkipByDateRange,
  })

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData: (data) => webFormDetailStore.setWebFormContactsData(data),
    refreshDeps: [tableStore.refreshTrigger],
    disabledRefetch: tableStore.rangeDatePickerStore.isSkipByDateRange,
  })

  return (
    <NewTable<IContact>
      columns={tableStore.visibleColumns}
      rows={contacts}
      withCalcMaxHeight
      additionalContent={<AdditionalContent />}
      leftStickyContent={<LeftStickyContent />}
      emptyContent={
        contacts.length ? undefined : (
          <EmptyStateStatistic
            description={
              'There are not any contacts that received the message yet'
            }
          />
        )
      }
      tableStore={tableStore}
      pageLoading={loading || refetchLoading}
      firstLoading={firstLoading}
      contentLoading={loading && !contacts.length}
    />
  )
})
