import styles from 'src/pages/settings/integrations/header/styles.module.scss'
import integrationsStore from 'store/settings/integrationsStore'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as Charger } from 'icons/16pxNoMask/Charger.svg'
import { AppIcon } from 'components/AppIcon/AppIcon'
import { observer } from 'mobx-react-lite'
import { Header } from 'shared/Header/Header'

export const IntegrationsHeader = observer(() => {
  // const [search, setSearch] = useState('')
  const onOpen = () => {
    integrationsStore.setOpenCallLoopApiKeyModal(true)
  }
  return (
    <Header
      style={{ paddingInline: 0 }}
      title={'Integrations'}
      rightContent={
        <div className={styles.right}>
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            withIcon
            onClick={onOpen}
          >
            <AppIcon>
              <Charger />
            </AppIcon>
            Call Loop API
          </Button>
          {/*<SearchInput value={search} onSearch={setSearch} />*/}
        </div>
      }
    />
  )
})
