// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rheHqievhNQjgPMb0MNE{display:flex;gap:12px}`, "",{"version":3,"sources":["webpack://./src/pages/settings/conversions/conversions/ui/actions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA","sourcesContent":[".actions{\n  display: flex;\n  gap: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `rheHqievhNQjgPMb0MNE`
};
export default ___CSS_LOADER_EXPORT___;
