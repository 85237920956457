import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { FC, useMemo } from 'react'
import { IAudioItem } from 'src/types/IAudioItem'
import { MoreBtn, MoreBtnItemProps } from 'components/Button/MoreBtn'
import { getAudioActions } from 'src/pages/settings/audio/functions/getAudioActions'

export const OneSelectContent = observer(
  ({ audioItem }: { audioItem: IAudioItem }) => {
    const actions = getAudioActions(audioItem)

    return useMemo(() => {
      const rowActions = actions.slice(0, 4)
      const moreActions = actions.slice(4)
      return (
        <>
          {rowActions.map((Action, index) => (
            <Action key={index} medium />
          ))}
          {!!moreActions?.length && (
            <MoreBtn
              menuItems={moreActions as Array<FC<MoreBtnItemProps>>}
              tooltip={'More'}
              medium
            />
          )}
        </>
      )
    }, [actions])
  }
)
