import { observer } from 'mobx-react-lite'
import { Tabs } from 'components/Tabs/Tabs'
import { useEffect, useState } from 'react'
import { SizedBox } from 'components/SizedBox'
import { useSetMember } from 'src/pages/settings/account/hooks/useSetMember'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'
import { Header } from 'shared/Header/Header'

export const Account = observer(() => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  useSetMember(true, pathname.includes('profile'))
  const onTabClick = (index: number) => {
    switch (index) {
      case 0: {
        return navigate(RoutesEnum.settings + '/' + RoutesEnum.account)
      }
      case 1: {
        return navigate(RoutesEnum.privacy)
      }
      default: {
        navigate(RoutesEnum.settings + '/' + RoutesEnum.account)
      }
    }
  }
  useEffect(() => {
    if (pathname.includes('privacy')) {
      setActiveTabIndex(1)
      return
    }
    setActiveTabIndex(0)
  }, [pathname])
  return (
    <>
      <Header title={'Account'} inContainer />
      <Tabs
        tabs={['My Profile', 'Privacy & Security']}
        activeTabIndex={activeTabIndex}
        onTabClick={onTabClick}
      />
      <SizedBox height={32} />
      <Outlet />
    </>
  )
})
