import { Alert } from 'components/Alert/Alert'
import { observer } from 'mobx-react-lite'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import a2p10DLCStore from 'store/settings/company/a2p10DLCStore'
import { useInitOptInDescriptionTenDlcMutation } from 'src/generated/graphql'
import React from 'react'
import { SimpleTextarea } from 'components/SimpleTextarea/SimpleTextarea'
import { BackBtn } from 'src/pages/settings/compliance/registrations/A2P10DLCRegistration/steps/BackBtn'
import typesStore from 'store/typesStore'

export const Step4 = observer(() => {
  const [initOptInDescriptionTenDlc, { loading }] =
    useInitOptInDescriptionTenDlcMutation()
  const onContinue = async () => {
    try {
      const { data } = await initOptInDescriptionTenDlc({
        variables: {
          input: a2p10DLCStore.tenDlcOptInDescriptionRequestInput,
        },
      })
      if (data?.initOptInDescriptionTenDlc?.profile) {
        a2p10DLCStore.setTenDlcProfile(data.initOptInDescriptionTenDlc.profile)
      }
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <div className={'col40'}>
      <Alert
        text={
          <>
            Please provide, in as much detail, how your contacts opt-in to
            receive text messages. See examples of{' '}
            <a href="/settings/company/routes#">opt-in descriptions.</a>
          </>
        }
      />
      <div className={'col24'}>
        {a2p10DLCStore.optInMethods.map((method) => (
          <SimpleTextarea
            key={method}
            pLabel={typesStore.a2pOptInMethodsMapping.get(method) || method}
            placeholder={'Enter opt-in description'}
            value={a2p10DLCStore.optInDescriptionsMap.get(method)}
            onChangeValue={(value) =>
              a2p10DLCStore.setOptInDescription(method, value)
            }
            height={104}
          />
        ))}
      </div>
      <div className={'row12'}>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          onClick={onContinue}
          loading={loading}
          disabled={a2p10DLCStore.disabledOptInMethodsDesc}
        >
          {a2p10DLCStore.continueText}
        </Button>
        <BackBtn />
      </div>
    </div>
  )
})
