import React, { FC, useMemo } from 'react'
import styles from './styles.module.scss'
import { ReactComponent as Checkbox } from 'icons/defaults/Checkbox.svg'
import { ReactComponent as CheckboxRound } from 'icons/defaults/Checkbox-Round.svg'
import { ReactComponent as CheckboxMinus } from 'icons/defaults/CheckboxMinus.svg'
import classNames from 'classnames'

export type ICheckBoxProps = {
  checked: boolean
  disabled?: boolean
  onChecked?: React.MouseEventHandler<HTMLButtonElement>
  shape?: 'radio' | 'circle' | 'minus' | 'default'
  size?: 'small' | 'medium' | 'large'
  boxSize?: '32'
}

export const CheckBox: FC<ICheckBoxProps> = ({
  checked,
  onChecked,
  disabled,
  size = 'medium',
  shape = 'default',
  boxSize,
}) => {
  const content = useMemo(() => {
    if (shape === 'minus') {
      return <CheckboxMinus />
    }
    if (checked && shape !== 'radio') {
      return shape === 'circle' ? <CheckboxRound /> : <Checkbox />
    } else {
      return <div className={styles.content} />
    }
  }, [shape, checked, size])

  const innerContent = (
    <div
      className={classNames(
        styles.wrap,
        checked && styles.checked,
        styles[`size__${size}`],
        styles[`shape__${shape}`]
      )}
    >
      {content}
    </div>
  )

  return (
    <button onClick={onChecked} disabled={disabled}>
      {boxSize ? (
        <div className={styles[`boxSize__${boxSize}`]}>{innerContent}</div>
      ) : (
        innerContent
      )}
    </button>
  )
}
