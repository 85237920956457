import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import mediaStore from 'store/mediaStore'
import { SIZE_LIMIT_5_MB } from 'src/static/constants'
import { DragAndDropInput } from 'components/DragnDropInput/DragAndDropInput/DragAndDropInput'
import { FilesRejection } from 'src/pages/settings/compliance/registrations/components/OptInItem/FilesRejection'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'

export const OptInImageLoader = observer(() => {
  const { disabledUploadOptInImage, optInAsset, setOptInAsset } =
    tollFreeVerificationStore
  const [filesRejections, setFilesRejections] = useState<File[]>([])

  const [loading, setLoading] = useState(false)

  const onAddFiles = async (files: File[]) => {
    setLoading(true)
    mediaStore
      .setUploadImages(files.slice(0, 3), (assets) => {
        if (assets.length) {
          setOptInAsset(assets[0])
        }
      })
      .finally(() => setLoading(false))
  }
  const onFilesRejections = (files: File[]) => {
    setFilesRejections(files)
  }
  const onRemoveFile = () => {
    setOptInAsset(null)
  }
  return (
    <>
      <DragAndDropInput
        assets={optInAsset ? [optInAsset] : undefined}
        onRemoveFile={onRemoveFile}
        onAddFiles={(files) => onAddFiles(files)}
        accept={'image/jpeg, image/png'}
        variant2
        fullTouch
        fileLimitSize={SIZE_LIMIT_5_MB}
        onFilesRejections={onFilesRejections}
        inputLoading={loading}
        fullWidth
        disabled={disabledUploadOptInImage && 'variant2'}
      />
      {!!filesRejections.length && (
        <div className={'col8 mt8'}>
          {filesRejections.map((file) => (
            <FilesRejection
              key={file.lastModified}
              file={file}
              accepted={['jpg', 'png']}
            />
          ))}
        </div>
      )}
    </>
  )
})
