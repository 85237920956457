import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as Plus } from 'icons/16px/Plus.svg'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import segmentStore from 'store/contacts/segment/segmentStore'
import { getSegmentActions } from 'src/pages/main/contacts/segments/functions/getSegmentActions'

export const Filters = observer(() => {
  const { tableStore } = segmentStore
  const navigate = useNavigate()

  const content = useMemo(() => {
    if (tableStore.checkedRowsIds.length === 1) {
      const actions = getSegmentActions(tableStore.checkedRows[0])

      return (
        <>
          {actions.map((Action, index) => (
            <Action key={index} medium />
          ))}
        </>
      )
    }
    if (tableStore.checkedRowsIds.length > 1) {
      const actions = getSegmentActions(undefined, tableStore.checkedRows)
      return (
        <>
          {actions.map((Action, index) => (
            <Action key={index} medium />
          ))}
        </>
      )
    }
    return (
      <>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          withIcon
          onClick={() => navigate('new')}
        >
          <Plus />
          New segment
        </Button>
      </>
    )
  }, [tableStore.checkedRowsIds])
  return <div className={styles.wrapActions}>{content}</div>
})
