import { observer } from 'mobx-react-lite'
import { Modal } from 'components/Modal/Modal'
import companyStore from 'store/settings/company/companyStore'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { InputCheckboxGroup } from 'components/Input/InputCheckbox/InputCheckboxGroup'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import { Alert } from 'components/Alert/Alert'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { SizedBox } from 'components/SizedBox'
import { AutoRechargeCard } from 'components/AutoRecahrge/AutoRechargeCard'
import billingStore from 'store/settings/billing/billingStore'
import { useMemo, useState } from 'react'

export const TurnOnAutoRechargeModal = observer(() => {
  const [loading, setLoading] = useState(false)
  const onClose = () => {
    companyStore.setTurnOnAutoRechargeModal(null)
  }
  const onTurnOn = async () => {
    try {
      if (companyStore.onTurnOnAutoRechargeModal) {
        setLoading(true)
        await companyStore.onTurnOnAutoRechargeModal()
        onClose()
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }
  const onBack = () => {
    companyStore.setTurnOnAutoRechargeModal(null)
    companyStore.setPhoneCheckerModal({ state: true })
  }

  const info = useMemo(() => {
    if (companyStore.turnOnAutoRechargeModal === 'phoneChecker') {
      return {
        modalTitle: 'Turn on PhoneCheckr',
        alertTitle: 'Ensure a better deliverability rate',
        alertText:
          'Turn on PhoneCheckr to prevent sending messages to invalid numbers, thus losing credits and getting blocked by carriers.',
        btnText: 'Turn on',
      }
    }
    if (companyStore.turnOnAutoRechargeModal === 'trigger') {
      return {
        modalTitle: 'Turn on Trigger',
        alertTitle:
          'To send messages using Triggers, turn ON the Auto-Recharge',
        alertText:
          'Auto-Recharge ensures that you don’t run out of credits and your messages continue to be sent.',
        btnText: 'Turn on trigger',
      }
    }
    if (companyStore.turnOnAutoRechargeModal === 'callTransfer') {
      return {
        modalTitle: 'Turn on transfer call',
        alertTitle: 'To transfer calls, turn ON the Auto-Recharge',
        alertText:
          'Auto-Recharge ensures that you don’t run out of credits and all your calls are being transferred.',
        btnText: 'Turn on transfer call',
      }
    }
    if (companyStore.turnOnAutoRechargeModal === 'callForwarding') {
      return {
        modalTitle: 'Turn on call forwarding',
        alertTitle: 'To forward calls, turn ON the Auto-Recharge',
        alertText:
          'Auto-Recharge ensures that you don’t run out of credits and all your calls are being forwarded.',
        btnText: 'Turn on call forwarding',
      }
    }
    if (companyStore.turnOnAutoRechargeModal === 'broadcastResume') {
      return {
        modalTitle: 'Resume broadcast',
        alertTitle:
          'To resume broadcast with transfer call, turn ON the Auto-Recharge',
        alertText:
          'Auto-Recharge ensures that you don’t run out of credits and all your calls are being transferred.',
        btnText: 'Resume broadcast',
      }
    }
    if (companyStore.turnOnAutoRechargeModal === 'broadcastCreate') {
      return {
        modalTitle: 'Create broadcast',
        alertTitle:
          'To create broadcast with transfer call, turn ON the Auto-Recharge',
        alertText:
          'Auto-Recharge ensures that you don’t run out of credits and all your calls are being transferred.',
        btnText: 'Create broadcast',
      }
    }
    return null
  }, [companyStore.turnOnAutoRechargeModal])

  return (
    <Modal
      open={!!companyStore.turnOnAutoRechargeModal}
      onClose={onClose}
      title={info?.modalTitle}
    >
      <div className={'medium2Modal'}>
        {info && (
          <Alert
            type={AlertTypeEnum.notify2}
            title={info.alertTitle}
            text={info.alertText}
          />
        )}
        {companyStore.turnOnAutoRechargeModal === 'phoneChecker' && (
          <>
            <SizedBox height={32} />
            <span className={'s2 medium gray1 mb8'}>
              What contacts you want to validate?
            </span>
            <InputCheckboxGroup>
              <InputCheckbox
                label={'Look up all existing contacts numbers'}
                checked={companyStore.verifyExisting}
                onChecked={() => companyStore.setVerifyExisting(true)}
                shape={'radio'}
                size={'small'}
              />
              <InputCheckbox
                label={'Look up only the new contacts going forward'}
                checked={!companyStore.verifyExisting}
                onChecked={() => companyStore.setVerifyExisting(false)}
                shape={'radio'}
                size={'small'}
              />
            </InputCheckboxGroup>
          </>
        )}
        <AutoRechargeCard mt={32} />
        <div className={'mt40 row12'}>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            onClick={onTurnOn}
            loading={loading}
            disabled={!billingStore.autoRecharge}
          >
            {info?.btnText}
          </Button>
          {companyStore.turnOnAutoRechargeModal === 'phoneChecker' ? (
            <Button
              typeBtn={BtnType.secondaryGray}
              size={BtnSize.medium}
              onClick={onBack}
            >
              Back
            </Button>
          ) : (
            <Button
              typeBtn={BtnType.secondaryGray}
              size={BtnSize.medium}
              onClick={onClose}
            >
              Cancel
            </Button>
          )}
        </div>
      </div>
    </Modal>
  )
})
