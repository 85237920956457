import { KeepCurrentMigrationPlanModal } from 'src/Modals/KeepCurrentMigrationPlanModal/KeepCurrentMigrationPlanModal'
import { Card } from 'components/Card/Card'
import { SizedBox } from 'components/SizedBox'
import React, { useEffect } from 'react'
import { SimplePrice, useGetPriceMigrationQuery } from 'src/generated/graphql'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import { UpgradeSubscriptionContent } from 'src/pages/auth/migration/stpes/ChoosePlanStep/UpgradeSubscriptionContent/UpgradeSubscriptionContent'
import migrationStore from 'store/migration/migrationStore'
import { MigrationBonusAlert } from 'src/pages/auth/migration/components/MigrationBonusAlert'
import dayjs from 'dayjs'
import Countdown, { CountdownRendererFn } from 'react-countdown'

const ExpireMigrationDate = dayjs('31 Aug 2024 0:00:00 EST').valueOf()

export const ChoosePlanStep = () => {
  const { data, loading } = useGetPriceMigrationQuery()
  useEffect(() => {
    if (data?.getPriceMigration) {
      const filtered: SimplePrice[] = []
      data?.getPriceMigration.annualPrices?.forEach((price) => {
        if (price) {
          filtered.push(price)
        }
      })
      data?.getPriceMigration.monthlyPrices?.forEach((price) => {
        if (price) {
          filtered.push(price)
        }
      })
      migrationStore.setPrices(filtered)
    }
  }, [data])
  if (loading) {
    return <AbsoluteLoader />
  }

  const renderer: CountdownRendererFn = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    if (completed) {
      // Render a completed state
      return ''
    } else {
      if (days) {
        return (
          <>
            {days}d {hours}h {minutes}m {seconds}s
          </>
        )
      }
      if (hours) {
        return (
          <>
            {hours}h {minutes}m {seconds}s
          </>
        )
      }
      if (minutes) {
        return (
          <>
            {minutes}m {seconds}s
          </>
        )
      }
      return <>{seconds}s</>
    }
  }
  return (
    <Card width={800}>
      <KeepCurrentMigrationPlanModal />
      <h3 className={'bold mb32'}>
        {migrationStore.withExpiredCredits ? (
          <>
            🎁 Special One-Time Offer! Expires in{' '}
            <Countdown date={ExpireMigrationDate} renderer={renderer} />
          </>
        ) : migrationStore.memberRegistration?.oldSubscription ? (
          '🎁 Special One-Time Offer! Get FREE Credits'
        ) : (
          '🎁 One-Time Offer! Keep Your Call Loop 1.0 Credits'
        )}
      </h3>
      <MigrationBonusAlert />
      <SizedBox height={12} />
      <UpgradeSubscriptionContent />
    </Card>
  )
}
