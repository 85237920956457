import styles from 'src/pages/settings/integrations/modal/CustomIntegrationModal/CustomFieldsContent/styles.module.scss'
import { Dropdown } from 'components/Dropdown/Dropdown'
import customFieldsStore from 'store/contacts/customFields/customFieldsStore'
import { SmallBtn } from 'components/Button/SmallBtn'
import { ActionsEnum } from 'components/Button/types'
import React from 'react'
import { IOption } from 'src/types/IOption'
import customIntegrationModalStore from 'src/pages/settings/integrations/modal/CustomIntegrationModal/store/customIntegrationModalStore'
import { IdFieldButton } from 'components/IdFieldButton/IdFieldButton'
import { AddCustomFieldSticky } from 'src/pages/main/contacts/customFields/modals/CustomFieldsModal/AddCustomField'

export const CustomFieldItem = ({
  id,
  item,
}: {
  id: string
  item: IOption | null
}) => {
  const { removeField, updateField } = customIntegrationModalStore
  const handleRemove = () => {
    removeField(id)
  }
  const handleSelect = (option: IOption) => {
    updateField(id, option)
  }

  return (
    <div className={styles.item}>
      <Dropdown
        rightBtn={
          item ? (
            <div className={'mr4'}>
              <IdFieldButton id={item.value} />
            </div>
          ) : (
            <></>
          )
        }
        selectedOption={item}
        onSelect={handleSelect}
        options={customFieldsStore.customFieldsOptionsWithIcons}
        BottomDropdownButton={AddCustomFieldSticky}
        labelDropdown={'Custom fields'}
      />
      <SmallBtn type={ActionsEnum.delete2} onClick={handleRemove} />
    </div>
  )
}
