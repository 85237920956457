import { observer } from 'mobx-react-lite'
import { Input } from 'shared/Input/Input'
import { useNewConversionContext } from 'widgets/conversions/NewConversion/context/context'
import { Label } from 'shared/Label/Label'

export const ConversionUrl = observer(() => {
  const { url, setUrl } = useNewConversionContext()
  return (
    <div>
      <Label
        text={'Conversion url'}
        marginBottom={8}
        infoTooltipProps={{
          width: 302,
          title:
            'The site where conversions will be tracked. An asterisk * may be used as a wildcard. Example: /thank-you?id=*',
        }}
      />
      <Input
        value={url}
        setValue={setUrl}
        placeholder={'Enter URL'}
        withClear
      />
    </div>
  )
})
