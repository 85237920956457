import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import audioStore from 'store/audioStore/audioStore'
import styles from './style.module.scss'
import * as React from 'react'
import classNames from 'classnames'
import { AudioTable } from 'src/pages/settings/audio/AudioTable/AudioTable'

export const AudioModal = observer(() => {
  return (
    <Modal
      open={audioStore.openModal}
      onClose={() => audioStore.onCloseModal()}
      title={'Add audio'}
      minHeight={audioStore.audioFilesMap.size ? 625 : 472}
    >
      <div className={styles.wrap}>
        <div
          className={classNames(
            styles.tableWrap,
            audioStore.audioFilesMap.size && styles.withTable
          )}
        >
          <AudioTable fromModal />
        </div>
      </div>
    </Modal>
  )
})
