// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RzmR1p8ajM9UcJUO9UkL{display:grid;grid-template-columns:180px minmax(300px, auto);align-items:center;border-bottom:1px solid var(--primary-color-gray-3)}.RzmR1p8ajM9UcJUO9UkL .SjhWWBttXT4eb7xC7v3c{padding:8px}.RzmR1p8ajM9UcJUO9UkL .Pxxm6uu1LKGtdAO80jn4{padding-block:8px}`, "",{"version":3,"sources":["webpack://./src/pages/superAdmin/routes/organization/info/Row/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,+CAAA,CACA,kBAAA,CAEA,mDAAA,CACA,4CACE,WAAA,CAEF,4CACE,iBAAA","sourcesContent":[".row{\n  display: grid;\n  grid-template-columns: 180px minmax(300px, auto);\n  align-items: center;\n  //width: 600px;\n  border-bottom: 1px solid var(--primary-color-gray-3);\n  .right{\n    padding: 8px;\n  }\n  .left{\n    padding-block: 8px;\n    //border-right: 1px solid var(--primary-color-gray-3);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `RzmR1p8ajM9UcJUO9UkL`,
	"right": `SjhWWBttXT4eb7xC7v3c`,
	"left": `Pxxm6uu1LKGtdAO80jn4`
};
export default ___CSS_LOADER_EXPORT___;
