import { IOrganization } from 'src/types/IOrganization'
import { OrganizationStatus, Status } from 'src/generated/graphql'
import { FC } from 'react'
import { IOrganizationDetails } from 'src/types/IOrganizationDetails'
import { AddCreditBtn } from 'src/pages/superAdmin/components/AddCreditBtn'
import { BanOrganizationBtn } from 'src/pages/superAdmin/components/BanOrganization'
import { AssumeOrganizationBtn } from 'src/pages/superAdmin/components/AssumeOrganizationBtn'
import { ActionBtnProps } from 'components/NewTable/types'
import { AddCallerIdBtn } from 'src/pages/superAdmin/components/AddCallerIdBtn'

export function getOrganizationActions(
  row?: IOrganization | IOrganizationDetails | null
): Array<FC<ActionBtnProps<IOrganization>>> {
  const actions: Array<FC<ActionBtnProps<IOrganization>>> = []
  if (!row) return actions

  actions.push((props) => BanOrganizationBtn({ ...props, row }))
  actions.push((props) => AssumeOrganizationBtn({ ...props, row }))

  if (
    (row.status === Status.Active || row.status === Status.Trial) &&
    row.organizationStatus !== OrganizationStatus.Banned
  ) {
    actions.push((props) => AddCreditBtn({ ...props, row }))
  }
  actions.push((props) => AddCallerIdBtn({ ...props, row }))

  return actions
}
