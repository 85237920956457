import React from 'react'
import { observer } from 'mobx-react-lite'
import { RinglessCard } from 'components/CreateElement/RinglessCard/RinglessCard'
import { SendFromComponentVR } from 'components/CreateElement/SendFromComponentVR'
import { CreateStoreTypes } from 'components/CreateElement/store/types'
import { EnableCallBackInput } from 'src/widgets/EnableCallBackInput/EnableCallBackInput'

export const RinglessContent = observer(
  ({ store }: { store: CreateStoreTypes }) => {
    return (
      <>
        <RinglessCard ringlessStore={store.ringlessStore} />
        <EnableCallBackInput
          callbackEnabled={store.ringlessStore.callbackEnabled}
          setCallbackEnabled={store.ringlessStore.setCallbackEnabled}
        />
        <SendFromComponentVR store={store} />
      </>
    )
  }
)
