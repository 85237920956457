// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jibSAYWCN5s2lLarbout{display:flex;justify-content:space-between}.GFrzO3y7Y3RAu1mCv40N button{min-height:32px}`, "",{"version":3,"sources":["webpack://./src/pages/main/dashboard/Performance/PerformanceCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CAGA,6BACE,eAAA","sourcesContent":[".row{\n  display: flex;\n  justify-content: space-between;\n}\n.drop{\n  button{\n    min-height: 32px;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `jibSAYWCN5s2lLarbout`,
	"drop": `GFrzO3y7Y3RAu1mCv40N`
};
export default ___CSS_LOADER_EXPORT___;
