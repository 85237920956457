import { FC } from 'react'
import { ActionBtnProps } from 'components/NewTable/types'
import { ISegment } from 'src/types/ISegment'
import { SegmentDeleteBtn } from 'src/pages/main/contacts/segments/components/SegmentDeleteBtn/SegmentDeleteBtn'
import { SegmentRenameBtn } from 'src/pages/main/contacts/segments/components/SegmentDeleteBtn/SegmentRenameBtn'
import { SegmentDuplicateBtn } from 'src/pages/main/contacts/segments/components/SegmentDeleteBtn/SegmentDuplicateBtn'
import { SegmentExportBtn } from 'src/pages/main/contacts/segments/components/SegmentDeleteBtn/SegmentExportBtn'

export const getSegmentActions = (
  row?: ISegment,
  rows?: ISegment[]
): Array<FC<ActionBtnProps<ISegment>>> => {
  if (row) {
    return [
      (props) => SegmentRenameBtn({ ...props, row }),
      (props) => SegmentExportBtn({ ...props, row }),
      (props) => SegmentDuplicateBtn({ ...props, row }),
      (props) => SegmentDeleteBtn({ ...props, row, rows }),
    ]
  }
  if (rows) {
    return [(props) => SegmentDeleteBtn({ ...props, rows })]
  }
  return []
}
