// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WxHePQ67pZ7SbKLRQeyY{margin:8px -12px 0 -12px;width:calc(100% + 24px)}`, "",{"version":3,"sources":["webpack://./src/widgets/EnableCallBackInput/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,uBAAA","sourcesContent":[".wrap{\n  margin: 8px -12px 0 -12px;\n  width: calc(100% + 24px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `WxHePQ67pZ7SbKLRQeyY`
};
export default ___CSS_LOADER_EXPORT___;
