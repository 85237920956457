// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zlKwljgzKMHm4TYksgY8{margin:4px;display:flex;gap:8px;align-items:center;padding:4px 8px;border-radius:4px;cursor:pointer}.zlKwljgzKMHm4TYksgY8:hover{background-color:hsla(0,0%,100%,.2)}.zlKwljgzKMHm4TYksgY8.BHoICBKh5Jfr4Gx3Uv9D{pointer-events:none}`, "",{"version":3,"sources":["webpack://./src/components/Textarea/ShortenLinkTooltip/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,OAAA,CACA,kBAAA,CAQA,eAAA,CACA,iBAAA,CACA,cAAA,CATA,4BACE,mCAAA,CAEF,2CACE,mBAAA","sourcesContent":[".linkBtn{\n  margin: 4px;\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  &:hover{\n    background-color: rgba(255, 255, 255, 0.2);\n  }\n  &.loading{\n    pointer-events: none;\n  }\n\n  padding: 4px 8px;\n  border-radius: 4px;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkBtn": `zlKwljgzKMHm4TYksgY8`,
	"loading": `BHoICBKh5Jfr4Gx3Uv9D`
};
export default ___CSS_LOADER_EXPORT___;
