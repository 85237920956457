// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.R3p0uTSmzIdy54BStM6O{margin-top:40px;display:grid;gap:32px}.R3p0uTSmzIdy54BStM6O.MC4jjGqGS1FyjLrV68i7{margin-top:86px}.em439kndZHJ7_Vlgkf6x{display:flex;justify-content:space-between}.em439kndZHJ7_Vlgkf6x .ScKpVYshPfda3MugZCg2{display:flex;column-gap:12px}.Y6EkH5_yV1dRPUbSMWgg{padding:8px}`, "",{"version":3,"sources":["webpack://./src/pages/main/keywords/create/createForm/ActionContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAIA,YAAA,CACA,QAAA,CAJA,2CACE,eAAA,CAMJ,sBAEE,YAAA,CACA,6BAAA,CAEA,4CACE,YAAA,CACA,eAAA,CAGJ,sBACE,WAAA","sourcesContent":[".wrap{\n  margin-top: 40px;\n  &.fromModal{\n    margin-top: 86px;\n  }\n  display: grid;\n  gap: 32px;\n}\n\n.actionsWrap{\n\n  display: flex;\n  justify-content: space-between;\n\n  .left{\n    display: flex;\n    column-gap: 12px;\n  }\n}\n.menuWrap{\n  padding: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `R3p0uTSmzIdy54BStM6O`,
	"fromModal": `MC4jjGqGS1FyjLrV68i7`,
	"actionsWrap": `em439kndZHJ7_Vlgkf6x`,
	"left": `ScKpVYshPfda3MugZCg2`,
	"menuWrap": `Y6EkH5_yV1dRPUbSMWgg`
};
export default ___CSS_LOADER_EXPORT___;
