import { SegmentContent } from 'src/pages/main/contacts/segments/DetailSegment/components/SegmentContent/SegmentContent'
import { useSegmentDetailContext } from 'src/pages/main/contacts/segments/DetailSegment/Layout/SegmentDetailLayout'
import { useParams } from 'react-router-dom'
import { useGetSegmentQuery } from 'src/generated/graphql'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import { HeaderWrapper } from 'src/pages/main/contacts/segments/DetailSegment/components/header/HeaderWrapper'
import { HeaderContentSegment } from 'src/pages/main/contacts/segments/DetailSegment/components/header/HeaderContentSegment'

export const DetailSegmentPage = observer(() => {
  const { setSegment } = useSegmentDetailContext()
  const { id } = useParams()

  const { data, loading } = useGetSegmentQuery({
    variables: {
      segmentId: id,
    },
  })

  useEffect(() => {
    if (data?.getSegment) {
      setSegment(data?.getSegment)
    }
  }, [data])
  if (loading) {
    return <AbsoluteLoader />
  }
  return (
    <>
      <HeaderWrapper>
        <HeaderContentSegment />
      </HeaderWrapper>
      <SegmentContent />
    </>
  )
})
