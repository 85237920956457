import { SizedBox } from 'components/SizedBox'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as Plus } from 'icons/16px/Plus.svg'
import styles from 'src/pages/settings/account/routes/MyProfile/VirtualContactCard/styles.module.scss'
import createVCardStore from 'store/settings/createVCardStore'

export const EmptyContent = () => {
  const createVCard = () => {
    createVCardStore.openModal()
  }
  return (
    <div className={styles.emptyCard}>
      <p className={'par2 bold'}>Virtual Contact Card</p>
      <SizedBox height={8} />
      <span className={'s2 gray1'}>
        Setup how people see your company.
        <br />
        You can share your vCard via SMS.
      </span>
      <SizedBox height={40} />
      <Button
        size={BtnSize.small}
        typeBtn={BtnType.secondaryBlue}
        p12
        onClick={createVCard}
      >
        <Plus />
        Create vCard
      </Button>
    </div>
  )
}
