import React, {
  ButtonHTMLAttributes,
  ForwardedRef,
  forwardRef,
  ReactNode,
} from 'react'
import { Icon, IIcon, IIconProps } from 'components/Icon/Icon'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { Typography } from 'shared/Typography'
import { NewLabel } from 'shared/NewLabel/NewLabel'

export type IDropdownButtonProps = {
  text?: string
  secondaryText?: string
  leftIcon?: IIcon
  rightIcon?: IIcon
  rightIconProps?: Partial<IIconProps>
  rightContent?: ReactNode
  loading?: boolean
  onClick?: ButtonHTMLAttributes<HTMLButtonElement>['onClick']
  className?: ButtonHTMLAttributes<HTMLButtonElement>['className']
  disabled?: ButtonHTMLAttributes<HTMLButtonElement>['disabled']
  fullWidth?: boolean
  primary?: boolean
  isNew?: boolean
}

// eslint-disable-next-line react/display-name
export const DropdownButton = forwardRef(
  (
    {
      leftIcon,
      text,
      secondaryText,
      rightIcon,
      rightIconProps,
      rightContent,
      onClick,
      className,
      fullWidth,
      primary,
      isNew,
      ...props
    }: IDropdownButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <button
        onClick={onClick}
        className={classNames(
          styles.wrap,
          {
            [styles.fullWidth]: fullWidth,
            [styles.primary]: primary,
          },
          className
        )}
        {...props}
        ref={ref}
      >
        <div className={styles.left}>
          {leftIcon && <Icon icon={leftIcon} className={styles.icon} />}
          <div className={styles.info}>
            {text && (
              <Typography
                variant={primary ? 's1-semiBold' : 's1-regular'}
                isUnsetColor
              >
                {text}
              </Typography>
            )}
            {secondaryText && (
              <Typography
                variant={'s1-regular'}
                color={'--primary-color-gray-1'}
                className={styles.secondaryText}
              >
                {secondaryText}
              </Typography>
            )}
          </div>
        </div>
        {isNew && <NewLabel />}
        {(rightIcon || rightContent) && (
          <div className={styles.right}>
            {rightContent}
            {rightIcon && (
              <Icon
                icon={rightIcon}
                className={styles.icon}
                {...rightIconProps}
              />
            )}
          </div>
        )}
      </button>
    )
  }
)
