// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KFgCXWBOM002nMPBR3Nt{margin-top:24px;display:grid;grid-template-columns:1fr 1fr;column-gap:24px}`, "",{"version":3,"sources":["webpack://./src/pages/auth/migration/stpes/ChoosePlanStep/UpgradeSubscriptionContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,YAAA,CACA,6BAAA,CACA,eAAA","sourcesContent":[".cards{\n  margin-top: 24px;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  column-gap: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cards": `KFgCXWBOM002nMPBR3Nt`
};
export default ___CSS_LOADER_EXPORT___;
