import { observer } from 'mobx-react-lite'
import billingStore from 'store/settings/billing/billingStore'
import { PrimaryCardDropdown } from 'src/pages/settings/billing/components/PrimaryCardDropdown'
import { StripeCardElement } from 'components/StripeCardElement/StripeCardElement'
import { StripeError, Token } from '@stripe/stripe-js'
import React from 'react'

type CreditCardProps = {
  selectedValue: string
  onSelectValue: (value: string) => void
  setLoading: (value: boolean) => void
  setReadyCard: (value: boolean) => void
  refBtn: React.MutableRefObject<HTMLButtonElement | null>
  onAction: (token: Token) => void
  onFailed: (error: StripeError) => void
  error: string
  skip?: boolean
  onlyCreate?: boolean
}

export const CreditCard = observer(
  ({
    selectedValue,
    onSelectValue,
    setLoading,
    setReadyCard,
    refBtn,
    onAction,
    onFailed,
    error,
    skip,
    onlyCreate,
  }: CreditCardProps) => {
    return !!billingStore.cardsOptions.length && !onlyCreate ? (
      <PrimaryCardDropdown
        selectedValue={selectedValue}
        onSelectValue={onSelectValue}
        onFinish={() => setLoading(false)}
        withNewCard
        noAddCard
        skip={skip}
      />
    ) : (
      <StripeCardElement
        label={'Credit card'}
        noAction
        setReadyForm={setReadyCard}
        refBtn={refBtn}
        onAction={onAction}
        onFailed={onFailed}
        error={error}
      />
    )
  }
)
