// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BH1BXv29sCKEM5JRK5ds{display:flex;align-items:center;gap:4px}`, "",{"version":3,"sources":["webpack://./src/shared/Label/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,OAAA","sourcesContent":[".wrap{\n  display: flex;\n  align-items: center;\n  gap: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `BH1BXv29sCKEM5JRK5ds`
};
export default ___CSS_LOADER_EXPORT___;
