import { makeAutoObservable } from 'mobx'
import { ConversionsTableStore } from 'widgets/conversions/ConversionsTable/store/conversionsTableStore'

export class ConversionsStore {
  conversionsTableStore
  constructor() {
    makeAutoObservable(this)
    this.conversionsTableStore = new ConversionsTableStore()
  }

  onSuccessCreateConversion = () => {
    this.conversionsTableStore.tableStore.onRefresh()
  }
}
