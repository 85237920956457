// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SP0SwLgdiQR4Binj3Tis{border-radius:12px;background-color:var(--primary-color-gray-4);display:grid;padding:0 20px;min-height:72px;grid-template-columns:max-content 1fr max-content;align-items:center;column-gap:12px}.JWgTlxawcPSfRpgiSK99{width:40px;height:40px;border-radius:20px;background-color:#fff;display:flex;justify-content:center;align-items:center}.Ls6K_IoPWPAJp57eJH0o{display:grid;row-gap:2px}`, "",{"version":3,"sources":["webpack://./src/pages/settings/account/routes/PrivacyAccount/GoogleCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,4CAAA,CACA,YAAA,CACA,cAAA,CACA,eAAA,CACA,iDAAA,CACA,kBAAA,CACA,eAAA,CAEF,sBACE,UAAA,CACA,WAAA,CACA,kBAAA,CACA,qBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAEF,sBACE,YAAA,CACA,WAAA","sourcesContent":[".wrap{\n  border-radius: 12px;\n  background-color: var(--primary-color-gray-4);\n  display: grid;\n  padding: 0 20px;\n  min-height: 72px;\n  grid-template-columns: max-content 1fr max-content;\n  align-items: center;\n  column-gap: 12px;\n}\n.icon{\n  width: 40px;\n  height: 40px;\n  border-radius: 20px;\n  background-color: white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.info{\n  display: grid;\n  row-gap: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `SP0SwLgdiQR4Binj3Tis`,
	"icon": `JWgTlxawcPSfRpgiSK99`,
	"info": `Ls6K_IoPWPAJp57eJH0o`
};
export default ___CSS_LOADER_EXPORT___;
