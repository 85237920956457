import { AdvancedSchedulingContext } from './context/context'
import { AdvancedSchedulingContent } from 'src/widgets/AdvancedScheduling/ui/AdvancedSchedulingContent'
import { AdvancedSchedulingStore } from 'src/widgets/AdvancedScheduling/store/advancedSchedulingStore'

type IAdvancedSchedulingProps = {
  store: AdvancedSchedulingStore
}

export const AdvancedScheduling = ({ store }: IAdvancedSchedulingProps) => {
  return (
    <AdvancedSchedulingContext.Provider value={store}>
      <AdvancedSchedulingContent />
    </AdvancedSchedulingContext.Provider>
  )
}
