// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.se0bfUMPFp1ez9gvQXRQ{display:flex;flex-direction:column;gap:8px;width:100%}`, "",{"version":3,"sources":["webpack://./src/components/Modal/ui/DeleteModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,UAAA","sourcesContent":[".actions{\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `se0bfUMPFp1ez9gvQXRQ`
};
export default ___CSS_LOADER_EXPORT___;
