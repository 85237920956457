import { ConversionDetailContext } from 'widgets/conversions/ConversionDetail/context/context'
import { ConversionDetailUi } from 'widgets/conversions/ConversionDetail/ui/ConversionDetailUi'
import { useState } from 'react'
import { ConversionDetailStore } from 'widgets/conversions/ConversionDetail/store/conversionDetailStore'
import { ConversionResponse } from 'src/pages/settings/conversions/conversions/store/type'

export const ConversionDetail = ({
  response,
}: {
  response: ConversionResponse
}) => {
  const [store] = useState(() => new ConversionDetailStore(response))
  return (
    <ConversionDetailContext.Provider value={store}>
      <ConversionDetailUi />
    </ConversionDetailContext.Provider>
  )
}
