// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.b6vZydNeipQwYlJowFeu{padding:0 32px 32px 32px}.FgjCToJThUODDu9OX_91{display:flex;column-gap:12px;padding-top:40px}.ds5tlW1Y4eMJmnSJSpNv{display:grid;grid-template-columns:512px 200px;column-gap:24px}.syJ7Vs7uT1Qwr7sBaio5{height:fit-content;padding:20px 16px;background-color:var(--primary-color-gray-4);border-radius:16px}`, "",{"version":3,"sources":["webpack://./src/pages/settings/billing/modals/PauseSubscriptionModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CAEF,sBACE,YAAA,CACA,eAAA,CACA,gBAAA,CAGF,sBACE,YAAA,CACA,iCAAA,CACA,eAAA,CAEF,sBACE,kBAAA,CACA,iBAAA,CACA,4CAAA,CACA,kBAAA","sourcesContent":[".wrap{\n  padding: 0 32px 32px 32px;\n}\n.actions{\n  display: flex;\n  column-gap: 12px;\n  padding-top: 40px;\n}\n\n.step0Wrap{\n  display: grid;\n  grid-template-columns: 512px 200px;\n  column-gap: 24px;\n}\n.card{\n  height: fit-content;\n  padding: 20px 16px;\n  background-color: var(--primary-color-gray-4);\n  border-radius: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `b6vZydNeipQwYlJowFeu`,
	"actions": `FgjCToJThUODDu9OX_91`,
	"step0Wrap": `ds5tlW1Y4eMJmnSJSpNv`,
	"card": `syJ7Vs7uT1Qwr7sBaio5`
};
export default ___CSS_LOADER_EXPORT___;
