// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zJwgG7qLNOiRiHz0lcxE{padding:0 64px 0 64px;display:flex;flex-grow:1;flex-direction:column}.zJwgG7qLNOiRiHz0lcxE.Cbku7Cxcgyb9ZYeqzWpe{margin:0 auto;width:100%;max-width:1168px}@media(max-width: 800px){.zJwgG7qLNOiRiHz0lcxE{padding:0 24px 24px 24px}}@media(max-height: 600px){.zJwgG7qLNOiRiHz0lcxE{padding:0 24px 24px 24px}}`, "",{"version":3,"sources":["webpack://./src/shared/Container/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,YAAA,CACA,WAAA,CACA,qBAAA,CACA,2CACE,aAAA,CACA,UAAA,CACA,gBAAA,CAEF,yBAVF,sBAWI,wBAAA,CAAA,CAEF,0BAbF,sBAcI,wBAAA,CAAA","sourcesContent":[".wrap{\n  padding: 0 64px 0 64px;\n  display: flex;\n  flex-grow: 1;\n  flex-direction: column;\n  &.small{\n    margin: 0 auto;\n    width: 100%;\n    max-width: 1040 + 64px + 64px;\n  }\n  @media (max-width: 800px) {\n    padding: 0 24px 24px 24px;\n  }\n  @media (max-height: 600px) {\n    padding: 0 24px 24px 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `zJwgG7qLNOiRiHz0lcxE`,
	"small": `Cbku7Cxcgyb9ZYeqzWpe`
};
export default ___CSS_LOADER_EXPORT___;
