import { Modal } from 'components/Modal/Modal'
import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import billingStore from 'store/settings/billing/billingStore'
import { useEffect, useState } from 'react'
import { Alert } from 'components/Alert/Alert'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { SizedBox } from 'components/SizedBox'
import {
  mockBillings,
  Step0,
} from 'src/routes/settings/billing/modals/PauseSubscriptionModal/Step0'
import { Currencies, numberString } from 'src/util/functions'
import { Separator } from 'components/Separator/Separator'
import { useCardDropdownOptions } from 'src/routes/settings/billing/components/hooks/useCardDropdownOptions'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { CancellationFeedbackContent } from 'components/Feedbackontent/CancellationFeedbackContent'
import { CancellationReasonType } from 'src/generated/graphql'

export const PauseSubscriptionModal = observer(() => {
  const [selectedValue, setSelectedValue] = useCardDropdownOptions()
  const [selectedBilling, setSelectedBilling] = useState(mockBillings[0])
  const [selectedAnswer, setSelectedAnswer] =
    useState<CancellationReasonType | null>(null)
  const [comment, setComment] = useState('')
  const [uiLoading, setUiLoading] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const onClose = () => {
    billingStore.setOpenPauseSubscriptionModal(false)
  }
  const onPause = () => {
    onClose()
    billingStore.setOpenSuccessPauseSubscriptionModal(true)
    billingStore.setIsPausedSubscription(true)
  }
  const toStep1 = () => {
    if (billingStore.showNewCard) {
      setUiLoading(true)
      billingStore.setAddActionNewCardCb(() => {
        setActiveStep(1)
        setUiLoading(false)
      })
      billingStore.newCardElement?.click()
    } else {
      setActiveStep(1)
    }
  }
  useEffect(() => {
    setActiveStep(0)
    setSelectedAnswer(null)
  }, [billingStore.openPauseSubscriptionModal])
  return (
    <Modal
      open={billingStore.openPauseSubscriptionModal}
      onClose={onClose}
      title={'Pause subscription'}
      steps={['Payment', 'Confirm']}
      activeStep={activeStep}
    >
      <div className={styles.wrap}>
        <Alert
          type={AlertTypeEnum.notify2}
          titleContent={
            <div>
              <span className={'par2 bold blue4'}>
                The pause will start at the end of the billing cycle on{' '}
                <span className={'par2 bold error1'}>Jan 1, 2021</span>
              </span>
            </div>
          }
          text={'Your account will be moved to view only mode'}
        />
        <SizedBox height={32} />
        <div className={styles.step0Wrap}>
          {activeStep === 0 && (
            <Step0
              selectedBilling={selectedBilling}
              setSelectedBilling={setSelectedBilling}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              onFinish={() => setUiLoading(false)}
            />
          )}
          {activeStep === 1 && (
            <CancellationFeedbackContent
              selectedAnswer={selectedAnswer}
              setSelectedAnswer={setSelectedAnswer}
              withoutBudgetReason={billingStore.isTrial}
              comment={comment}
              setComment={setComment}
            />
          )}
          <div className={styles.card}>
            <span className={'medium mb16'}>Details</span>
            <div className={'row-between mb8'}>
              <p className={'par2 gray1'}>Plan</p>
              <p className={'par2'}>Pause</p>
            </div>
            <div className={'row-between mb16'}>
              <p className={'par2 gray1'}>Period</p>
              <p className={'par2'}>{selectedBilling.title}</p>
            </div>
            <Separator color={'--primary-color-gray-2-disabled'} />
            <div className={'row-between mt16'}>
              <span>Total</span>
              <span>
                {numberString({
                  val: selectedBilling.cost,
                  currency: Currencies.usd,
                })}
              </span>
            </div>
          </div>
        </div>

        <div className={styles.actions}>
          {activeStep === 0 ? (
            <Button
              typeBtn={BtnType.primary}
              size={BtnSize.medium}
              onClick={toStep1}
              loading={uiLoading}
              disabled={
                billingStore.showNewCard
                  ? !billingStore.readyNewCard
                  : !selectedValue
              }
            >
              Continue
            </Button>
          ) : (
            <Button
              typeBtn={BtnType.primary}
              size={BtnSize.medium}
              disabled={!selectedAnswer}
              onClick={onPause}
            >
              Pause subscription
            </Button>
          )}

          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
})
