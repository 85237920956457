import { Icon } from 'components/Icon/Icon'
import { Typography } from 'shared/Typography'
import { DetailRow } from 'components/DetailsModalContent/DetailRow/DetailRow'
import styles from './styles.module.scss'
import { ConversionResponse } from 'src/pages/settings/conversions/conversions/store/type'

export const ConversionContent = ({
  conversion,
}: {
  conversion: ConversionResponse
}) => {
  return (
    <DetailRow
      left={{
        icon: 'conversion',
        text: 'Track conv.',
      }}
      rightContent={
        <div className={styles.wrap}>
          <div className={styles.row}>
            <Icon icon={'link'} color={'--primary-color-gray-1'} />
            <Typography>{conversion?.name}</Typography>
          </div>
          <Typography className={styles.url} color={'--primary-color-gray-1'}>
            {conversion?.urlPattern}
          </Typography>
        </div>
      }
    />
  )
}
