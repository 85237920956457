// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jlMCuH7ZdamB8dip8mJW{padding:8px 12px;border-radius:8px;cursor:pointer;display:flex;gap:8px;align-items:center;align-content:center}.jlMCuH7ZdamB8dip8mJW:hover{background-color:var(--primary-color-gray-4)}`, "",{"version":3,"sources":["webpack://./src/components/shared/NewInputCheckbox/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,iBAAA,CACA,cAAA,CAIA,YAAA,CACA,OAAA,CACA,kBAAA,CACA,oBAAA,CANA,4BACE,4CAAA","sourcesContent":[".wrap{\n  padding: 8px 12px;\n  border-radius: 8px;\n  cursor: pointer;\n  &:hover{\n    background-color: var(--primary-color-gray-4);\n  }\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  align-content: center;\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `jlMCuH7ZdamB8dip8mJW`
};
export default ___CSS_LOADER_EXPORT___;
