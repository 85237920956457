import NewTable from 'components/NewTable/NewTable'
import { observer } from 'mobx-react-lite'
import contactStore from 'store/contacts/contactStore'
import { Filters } from './Filters/Filters'
import { HoverRowComponent } from './HoverRowComponent/HoverRowComponent'
import * as React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  GetMyOrganizationListsQuery,
  useGetMyOrganizationListsQuery,
} from 'src/generated/graphql'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import { EmptyState } from 'components/EmptyState/EmptyState'
import { noSortsColumns } from 'components/NewTable/columns/contacts/config'
import { useSetData } from 'src/hooks/useSetData'
import { useSetTotalContactsCount } from 'src/pages/main/contacts/hooks/useSetTotalContactsCount'
import { EmptyStateStatistic } from 'components/EmptyState/EmptyStateStatistic'
import { IListResponse } from 'src/types/IContactList'
import { AppElements } from 'src/enums/appElements'

export const ListsTable = observer(() => {
  const loadingContacts = useSetTotalContactsCount(
    !!contactStore.totalContactCount
  )
  const navigate = useNavigate()
  const [lists, setLists] = useState<IListResponse[]>([])

  const queryResult = useGetMyOrganizationListsQuery({
    variables: {
      page: contactStore.tableStoreLists.paginationInput,
      listingSearch: {
        searchPattern: contactStore.tableStoreLists.search,
      },
    },
  })

  const setData = (data: GetMyOrganizationListsQuery) => {
    if (data.getMyOrganizationLists) {
      const listings: IListResponse[] = []
      data?.getMyOrganizationLists?.content?.forEach((field) => {
        if (field) {
          listings.push({ ...field, id: field.id })
        }
      })
      setLists(listings)
      contactStore.tableStoreLists.setTotal(data.getMyOrganizationLists.total)
    }
  }

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData,
    refreshDeps: [contactStore.tableStoreLists.refreshTrigger],
  })

  const onRowClick = (id: string) => {
    navigate(String(id))
  }

  if (firstLoading || loadingContacts) {
    return <AbsoluteLoader />
  }

  if (
    !firstLoading &&
    !lists.length &&
    !contactStore.tableStoreLists.search.trim()
  ) {
    return (
      <EmptyState
        element={AppElements.List}
        description={'Group contacts for better management'}
        heightV2
      />
    )
  }

  return (
    <NewTable<IListResponse>
      noSortsColumns={noSortsColumns}
      withCheckbox
      columns={contactStore.tableStoreLists.visibleColumns}
      rows={lists}
      leftStickyContent={<Filters />}
      withSearch
      onRowClick={onRowClick}
      HoverRowComponent={HoverRowComponent}
      withCalcMaxHeight
      tableStore={contactStore.tableStoreLists}
      pageLoading={loading || refetchLoading}
      emptyContent={
        lists.length ? undefined : (
          <EmptyStateStatistic description={'No lists matching'} />
        )
      }
    />
  )
})
