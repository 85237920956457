import styles from './styles.module.scss'
import { useNavigate } from 'react-router-dom'
import { BreadCrumbs } from 'components/BreadCrumbs/BreadCrumbs'
import { BtnSize, Button } from 'components/Button/Button'
import { ReactComponent as Angle } from 'icons/16px/Angle-Left.svg'
import { FailedImportHistoryTable } from 'src/pages/main/contacts/importHistory/FailedImportHistory/FailedImportHistoryTable/FailedImportHistoryTable'
import { observer } from 'mobx-react-lite'
import importContactStore from 'store/contacts/importContactStore'

export const FailedImportHistory = observer(() => {
  const navigate = useNavigate()
  return (
    <div className={styles.wrap}>
      <div>
        <BreadCrumbs />
        <div className={styles.content}>
          <Button hoverGray size={BtnSize.small} onClick={() => navigate(-1)}>
            <Angle />
          </Button>

          <h1>{importContactStore.currentHistoryState?.originalFileName}</h1>
        </div>
      </div>
      <div className={styles.content}>
        <FailedImportHistoryTable />
      </div>
    </div>
  )
})
