// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import auth from 'store/auth'

export function firstPromoter() {
  try {
    const script = document.createElement('script')
    script.src = 'https://cdn.firstpromoter.com/fpr.js'
    script.onload = () => {
      ;(function (w) {
        w.fpr =
          w.fpr ||
          function () {
            w.fpr.q = w.fpr.q || []
            // eslint-disable-next-line prefer-rest-params
            w.fpr.q[arguments[0] == 'set' ? 'unshift' : 'push'](arguments)
          }
      })(window)
      fpr('init', { cid: 'kcyzqi56' })
      fpr('click')
    }
    document.head.appendChild(script)
    auth.setInitFirstPromoter()
    return
  } catch (e) {
    console.error(e)
  }
}
