import React, { ForwardedRef } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { ColorsNames } from 'styles/variables'
import { Loader } from 'components/Loader/Loader'
import { ReactComponent as CheckIcon } from 'icons/16pxNoMask/Check.svg'
import { AppIcon, IIconType } from 'components/AppIcon/AppIcon'
import { Icon, IIcon } from 'components/Icon/Icon'

export enum BtnSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
  mini = 'mini',
}
export enum BtnType {
  primary = 'primary',
  secondaryGray = 'secondaryGray',
  secondaryBlue = 'secondaryBlue',
  secondaryDelete = 'secondaryDelete',
  secondaryGrayDelete = 'secondaryGrayDelete',
  blueAction = 'blueAction',
  tertiaryIcon = 'tertiaryIcon',
}

type BtnProps = JSX.IntrinsicElements['button'] & {
  size?: BtnSize
  color?: ColorsNames.white | ColorsNames.blue1 | ColorsNames.gray4

  icon?: boolean
  modalNav?: boolean
  google?: boolean
  round?: boolean
  close?: boolean
  hoverGray?: boolean
  hoverGray2?: boolean
  mb?: number
  p12?: boolean
  p0?: boolean
  fullWidth?: boolean
  active?: boolean
  p8?: boolean
  left?: boolean
  menuBtn?: boolean
  spaceBetween?: boolean
  blackHoverText?: boolean
  typeBtn?: BtnType
  textColor?: string
  tabIndex?: number
  withIcon?: boolean
  loading?: boolean
  success?: boolean
  regular?: boolean
  btnSmall?: boolean
  width?: number
  victorIcon?: IIcon
}

// eslint-disable-next-line react/display-name
export const Button = React.forwardRef(
  (
    {
      size,
      color,
      // border,
      mb,
      google,
      className,
      round,
      close,
      icon,
      hoverGray,
      hoverGray2,
      p12,
      active,
      modalNav,
      fullWidth,
      p8,
      left,
      menuBtn,
      spaceBetween,
      blackHoverText,
      typeBtn,
      tabIndex,
      withIcon,
      loading,
      p0,
      success,
      regular,
      btnSmall,
      width,
      victorIcon,
      ...props
    }: BtnProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    if (typeBtn === 'tertiaryIcon' && victorIcon) {
      return (
        <button
          ref={ref}
          className={classNames(
            'call-loop-button',
            styles.btn,
            styles.tertiaryIcon,
            size && styles[size]
          )}
          {...props}
        >
          <Icon icon={victorIcon} color={'--primary-color-gray-1'} />
        </button>
      )
    }
    return (
      <button
        style={{ marginBottom: mb || undefined, width }}
        tabIndex={tabIndex}
        className={classNames(
          'call-loop-button',
          styles.btn,
          btnSmall && styles.btnSmall,
          typeBtn && styles[typeBtn],
          google && styles.google,
          icon && styles.icon,
          modalNav && styles.modalNav,
          size && styles[size],
          color && styles[color],
          round && styles.round,
          close && styles.close,
          p0 && styles.p0,
          p8 && styles.p8,
          p12 && styles.p12,
          hoverGray && styles.hoverGray,
          hoverGray2 && styles.hoverGray2,
          active && styles.active,
          fullWidth && styles.fullWidth,
          left && styles.left,
          menuBtn && styles.menuBtn,
          spaceBetween && styles.spaceBetween,
          blackHoverText && styles.blackHoverText,
          withIcon && styles.withIcon,
          loading && styles.loading,
          success && styles.loading,
          regular && styles.regular,
          className
        )}
        {...props}
        ref={ref}
      >
        {loading && (
          <div className={styles.loading}>
            <Loader
              size={16}
              indeterminateColor={
                typeBtn === BtnType.secondaryGray
                  ? '--primary-color-gray-2'
                  : typeBtn === BtnType.primary
                  ? '--primary-color-white'
                  : undefined
              }
            />
          </div>
        )}
        {success && (
          <div className={styles.loading}>
            <AppIcon type={IIconType.stroke} color={ColorsNames.white}>
              <CheckIcon />
            </AppIcon>
          </div>
        )}
        {props.children}
      </button>
    )
  }
)
