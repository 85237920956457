import { useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'
import {
  CampaignResponse,
  useActionCampaignArchiveMutation,
  useActionCampaignRestoreMutation,
  useActionCampaignSwitchEnabledMutation,
  useDeleteCampaignsMutation,
  useGetCampaignByIdLazyQuery,
} from 'src/generated/graphql'
import { ICampaign } from 'src/types/ICampaign'
import campaignsStore from 'store/campaigns/campaignsStore'
import { transformCampaign } from 'store/campaigns/transformCampaign'
import campaignDetailStore from 'store/campaigns/campaignDetailStore'

export function useCampaignFunctions(row?: ICampaign, rows?: ICampaign[]) {
  const navigate = useNavigate()

  const [getById] = useGetCampaignByIdLazyQuery()

  const [actionCampaignSwitch] = useActionCampaignSwitchEnabledMutation()
  const [archiveCampaign] = useActionCampaignArchiveMutation()
  const [restoreCampaign] = useActionCampaignRestoreMutation()
  const [deleteCampaign] = useDeleteCampaignsMutation()

  const longIds = rows && rows.map((item) => +item.id)

  const onOpenDetail = async () => {
    try {
      if (row?.campaignStepResponses?.length) {
        campaignsStore.setCampaignDetailModal(transformCampaign(row))
      } else {
        const { data } = await getById({
          variables: {
            campaignId: +row?.id,
          },
        })
        if (data?.getCampaignById) {
          campaignsStore.setCampaignDetailModal(
            transformCampaign(data.getCampaignById as CampaignResponse)
          )
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const onEdit = () => {
    if (row) {
      navigate(`${RoutesEnum.campaigns}/${row.id}/edit`)
    }
  }

  const onDuplicate = () => {
    if (row) {
      navigate(`${RoutesEnum.campaigns}/${row.id}/duplicate`)
    }
  }
  const onDelete = async (propsIds?: number[]) => {
    if (row || longIds || propsIds) {
      try {
        const ids: number[] = (row ? [+row.id] : longIds) || propsIds || []
        await deleteCampaign({ variables: { campaignIds: ids } })
        navigate(RoutesEnum.campaigns)
        campaignsStore.delete(ids.map((id) => String(id)))
      } catch (e) {
        console.error(e)
      }
    }
  }

  const onArchive = async (propsIds?: number[], fromEditPage?: boolean) => {
    if (row || longIds || propsIds) {
      try {
        const ids: number[] = (row ? [+row.id] : longIds) || propsIds || []
        await archiveCampaign({ variables: { campaignIds: ids } })
        if (fromEditPage) {
          navigate(RoutesEnum.campaigns)
        }

        campaignDetailStore.onRefresh()
        campaignsStore.tableStore.onRefresh()
      } catch (e) {
        console.error(e)
      }
    }
  }
  const onRestore = async (propsIds?: number[]) => {
    if (row || longIds || propsIds) {
      try {
        const ids: number[] = (row ? [+row.id] : longIds) || propsIds || []
        await restoreCampaign({ variables: { campaignIds: ids } })
        campaignDetailStore.onRefresh()
        campaignsStore.tableStore.onRefresh()
      } catch (e) {
        console.error(e)
      }
    }
  }

  const onSwitch = async (enabled: boolean, propsIds?: number[]) => {
    if (row || longIds || propsIds) {
      try {
        const ids: number[] = (row ? [+row.id] : longIds) || propsIds || []
        await actionCampaignSwitch({ variables: { campaignIds: ids, enabled } })
        campaignDetailStore.onRefresh()
        campaignsStore.tableStore.onRefresh()
      } catch (e) {
        console.error(e)
      }
    }
  }

  const onExport = () => {
    if (row) {
      console.log('Export')
    }
  }

  return {
    onOpenDetail,
    onRestore,
    onArchive,
    onEdit,
    onDuplicate,
    onDelete,
    onSwitch,
    onExport,
  }
}
