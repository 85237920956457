import { BroadcastScheduleContext } from 'src/widgets/BroadcastSchedule/context/context'
import { BroadcastScheduleStore } from 'src/widgets/BroadcastSchedule'
import { FC } from 'react'
import { BroadcastScheduleContent } from 'src/widgets/BroadcastSchedule/ui/BroadcastScheduleContent'

type IBroadcastScheduleProps = {
  store: BroadcastScheduleStore
}

export const BroadcastSchedule: FC<IBroadcastScheduleProps> = ({ store }) => (
  <BroadcastScheduleContext.Provider value={store}>
    <BroadcastScheduleContent />
  </BroadcastScheduleContext.Provider>
)
