import { NewRequirements } from 'src/pages/settings/compliance/components/NewRequirements/NewRequirements'
import { SizedBox } from 'components/SizedBox'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import * as React from 'react'
import uiStore from 'store/uiStore'
import { RoutesEnum } from 'src/pages/routes'
import { useNavigate } from 'react-router-dom'

export const Compliance = ({
  onCancel,
  onContinue,
}: {
  onCancel: () => void
  onContinue: () => void
}) => {
  const navigate = useNavigate()

  const onRegister = () => {
    const to = RoutesEnum.settingsCompliance
    onCancel()
    if (uiStore.isBeforeUnload) {
      uiStore.setOpenCancelCreateModal(true)
      uiStore.setNextRoute(to)
    } else {
      uiStore.setNextRoute('')
      navigate(to)
    }
  }

  return (
    <>
      <NewRequirements />
      <SizedBox height={32} />
      <p className={'par0'}>
        Before buying or using your number, the carriers are now requiring
        everyone who intends to send messages to United States and Canada to
        register for A2P 10DLC for local numbers and Toll-free verification for
        toll-free numbers. And we have good news, Call Loop made this process
        simple for you.
      </p>
      <div className={'mt40 row12'}>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          onClick={onContinue}
        >
          Choose number
        </Button>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.secondaryGray}
          onClick={onRegister}
        >
          Register now
        </Button>
      </div>
    </>
  )
}
