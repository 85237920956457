import { useUpdateOrganizationMutation } from 'src/generated/graphql'
import auth from 'store/auth'
import companyStore from 'store/settings/company/companyStore'
import mainStore from 'store/mainStore'
import { useHubspotRegisterCompany } from 'src/hooks/useHubspotRegisterCompany'
import { RegisterOrganization } from 'src/widgets/RegisterOrganization/RegisterOrganization'
import { BtnSize } from 'components/Button/Button'
import { SizedBox } from 'components/SizedBox'
import { FooterRegister } from 'src/pages/auth/components/FooterRegister'
import React from 'react'

export const RegisterCompanyInfoA = () => {
  const [updateOrganization, { loading }] = useUpdateOrganizationMutation()
  const onContinue = async (companyName: string, employees: string | null) => {
    try {
      const { data } = await updateOrganization({
        variables: {
          organization: {
            employees: employees,
            companyName: companyName.trim(),
          },
        },
      })
      if (data?.updateOrganization) {
        auth.setRefetchMainInfo()
        companyStore.setOrganization(data?.updateOrganization)
        mainStore.updateAnalyticTrigger()
      }
    } catch (e) {
      console.error(e)
    }
  }

  useHubspotRegisterCompany()

  return (
    <RegisterOrganization
      loading={loading}
      btnPrimarySize={BtnSize.large}
      btnPrimaryText={"Let's go!"}
      onContinue={onContinue}
      initCompanyName={companyStore.companyName}
      bottomContent={
        <>
          <SizedBox height={16} />
          <FooterRegister
            text={
              'Cancel anytime. You will not be charged until your trial ends.'
            }
          />
        </>
      }
    />
  )
}
