// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doc3gxQwfUZjNPwdz5YP{padding:0 32px 32px 32px}.o7mUMuo3HY7zQ2AYmcJH{margin-top:40px;display:flex;column-gap:12px}.KeE9afv5DnnBmLHlXcjP{display:flex;justify-content:space-between;margin-bottom:8px}.jxwEhmQ7uJSV8wPdiAkL{cursor:pointer}.jxwEhmQ7uJSV8wPdiAkL span:hover{color:var(--states-color-error-2)}.aUknrtMzQieB_KvPJR3P{cursor:pointer}.aUknrtMzQieB_KvPJR3P span:hover{color:var(--primary-color-blue-4)}.dK9B4TkTkk_vObqxFI5W{display:grid;row-gap:24px}`, "",{"version":3,"sources":["webpack://./src/pages/main/contacts/UnsubscribeContactModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CAEF,sBACE,eAAA,CACA,YAAA,CACA,eAAA,CAEF,sBACE,YAAA,CACA,6BAAA,CACA,iBAAA,CAEF,sBACE,cAAA,CAEE,iCACE,iCAAA,CAIN,sBACE,cAAA,CAEE,iCACE,iCAAA,CAIN,sBACE,YAAA,CACA,YAAA","sourcesContent":[".wrap{\n  padding: 0 32px 32px 32px;\n}\n.actions{\n  margin-top: 40px;\n  display: flex;\n  column-gap: 12px;\n}\n.row{\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 8px;\n}\n.unsubscribe{\n  cursor: pointer;\n  span{\n    &:hover{\n      color: var(--states-color-error-2);\n    }\n  }\n}\n.resubscribe{\n  cursor: pointer;\n  span{\n    &:hover{\n      color: var(--primary-color-blue-4);\n    }\n  }\n}\n.wrapContent{\n  display: grid;\n  row-gap: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `doc3gxQwfUZjNPwdz5YP`,
	"actions": `o7mUMuo3HY7zQ2AYmcJH`,
	"row": `KeE9afv5DnnBmLHlXcjP`,
	"unsubscribe": `jxwEhmQ7uJSV8wPdiAkL`,
	"resubscribe": `aUknrtMzQieB_KvPJR3P`,
	"wrapContent": `dK9B4TkTkk_vObqxFI5W`
};
export default ___CSS_LOADER_EXPORT___;
