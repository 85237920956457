import { observer } from 'mobx-react-lite'
import { Input } from 'shared/Input/Input'
import { useNewConversionContext } from 'widgets/conversions/NewConversion/context/context'
import { Label } from 'shared/Label/Label'
import { SelectList } from 'components/SelectList/SelectList'
import { Icon } from 'components/Icon/Icon'
import { DropdownField } from 'shared/DropdownField/DropdownField'
import * as React from 'react'

export const ConversionAttribution = observer(() => {
  const {
    setConversionAttribute,
    conversionAttributeOptions,
    conversionAttributeTitle,
  } = useNewConversionContext()
  return (
    <div>
      <Label text={'Conversion attribution'} marginBottom={8} />
      <DropdownField
        isFullWidth
        isSameWidth
        dropdownContent={() => (
          <SelectList
            options={conversionAttributeOptions}
            onSelect={setConversionAttribute}
          />
        )}
        triggerContent={(open) => (
          <Input
            isReadOnly
            value={conversionAttributeTitle}
            placeholder={'Select'}
            rightContent={
              <Icon
                icon={open ? 'angleUp' : 'angleDown'}
                color={'--primary-color-gray-1'}
                fontSize={16}
              />
            }
          />
        )}
      />
    </div>
  )
})
