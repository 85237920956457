import { FooterText } from 'src/pages/auth/components/FooterText/FooterText'

export const FooterRegister = ({ text }: { text?: string }) => (
  <FooterText>
    {text || (
      <>
        After your 15-day free trial is over, you will be upgraded to the
        <br />
        Pro 500 plan ($25/mo).
      </>
    )}
  </FooterText>
)
