import { IInvoice } from 'src/pages/settings/billing/Invoices/types'
import dayjs from 'lib/dayjs'
import { Currencies, numberString } from 'src/util/functions'
import {
  InvoiceStatus,
  useFetchInvoiceLinkLazyQuery,
} from 'src/generated/graphql'
import { ColumnType } from 'components/NewTable/types'
import { InvoiceStatusWidget } from 'src/widgets/status/InvoiceStatusWidget'

export const invoicesColumns: ColumnType<IInvoice>[] = [
  {
    id: 'status',
    label: 'Status',
    format: (value) => <InvoiceStatusWidget status={value as InvoiceStatus} />,
  },
  {
    id: 'date',
    label: 'Date',
    format: (value) => dayjs(value as Date).format('YYYY-MM-DD'),
  },
  {
    id: 'amount',
    label: 'Amount',
    format: (value, { currency }) =>
      numberString({
        val: +value,
        currency: currency || Currencies.usd,
        maximumFractionDigits: 2,
      }),
  },
  {
    id: 'paymentMethodDetails',
    label: 'Method',
  },
  {
    id: 'link',
    label: 'Invoice',
    format: (value, row) => <ViewDetails id={row.id} />,
  },
]

const ViewDetails = ({ id }: { id: string }) => {
  const [fetchInvoiceLink, { loading }] = useFetchInvoiceLinkLazyQuery()
  const onClick = async () => {
    if (loading) return
    try {
      const { data } = await fetchInvoiceLink({
        variables: {
          id,
        },
      })
      if (data?.fetchInvoice) {
        data.fetchInvoice.link && window.open(data.fetchInvoice.link, '_blank')
      }
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <button className={'s1 link bold'} onClick={onClick}>
      View details
    </button>
  )
}
