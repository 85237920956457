// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cmGLUWduMZGjiODv4xXd{display:grid;padding:8px}`, "",{"version":3,"sources":["webpack://./src/components/SelectList/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA","sourcesContent":[".wrap{\n  display: grid;\n  padding: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `cmGLUWduMZGjiODv4xXd`
};
export default ___CSS_LOADER_EXPORT___;
