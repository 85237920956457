import { observer } from 'mobx-react-lite'
import contactStore from 'store/contacts/contactStore'
import * as React from 'react'
import { BtnSize } from 'components/Button/Button'
import { getContactActions } from 'src/pages/main/contacts/functions/getContactActions'
import { Actions } from 'components/Actions/Actions'

export const ManySelectContent = observer(
  ({ extraBtnSize }: { extraBtnSize?: BtnSize }) => {
    const actions = getContactActions({
      extraBtnSize,
      bulk: contactStore.contactsBulk,
    })

    return <Actions actions={actions} extraBtnSize={extraBtnSize} />
  }
)
