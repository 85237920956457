// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Gv7QJovNAg3nEgwVvWZo{display:flex;flex-direction:column;gap:8px}.Jy8idvh3LuZyARl_0ohn{display:flex;gap:8px}.FToqVS94kMTUKSMdHduJ{word-break:break-word;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/components/DetailsModalContent/ConversionContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAEF,sBACE,YAAA,CACA,OAAA,CAEF,sBACE,qBAAA,CACA,mBAAA,CACA,2BAAA,CACA,oBAAA,CACA,eAAA","sourcesContent":[".wrap{\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n.row{\n  display: flex;\n  gap: 8px;\n}\n.url{\n  word-break: break-word;\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: 2;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Gv7QJovNAg3nEgwVvWZo`,
	"row": `Jy8idvh3LuZyARl_0ohn`,
	"url": `FToqVS94kMTUKSMdHduJ`
};
export default ___CSS_LOADER_EXPORT___;
