import { useGetCustomFieldsQuery } from 'src/generated/graphql'
import { useSetCustomFields } from 'src/pages/main/contacts/customFields/hooks/useSetCustomFields'
import { useEffect } from 'react'
import customFieldsStore from 'store/contacts/customFields/customFieldsStore'

export function useGetCustomFields(skip?: boolean) {
  const { data, refetch } = useGetCustomFieldsQuery({
    skip: skip,
  })
  useSetCustomFields(data)
  useEffect(() => {
    if (customFieldsStore.openCustomFieldsModal) {
      refetch()
    }
  }, [customFieldsStore.openCustomFieldsModal])
}
