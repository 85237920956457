// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sKLCu0s8VKaYtGiyzQPw{display:grid;row-gap:12px}.pQ7E9FEWz40W55peuzu7{display:grid;grid-template-columns:200px 500px;align-items:center}@media(max-width: 800px){.pQ7E9FEWz40W55peuzu7{grid-template-columns:1fr 1fr}}@media(max-height: 600px){.pQ7E9FEWz40W55peuzu7{grid-template-columns:1fr 1fr}}.Kqb4ULpCAZ8BjGvIA9wA{max-width:400px}`, "",{"version":3,"sources":["webpack://./src/pages/superAdmin/modals/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,YAAA,CAEF,sBACE,YAAA,CACA,iCAAA,CACA,kBAAA,CACA,yBAJF,sBAKI,6BAAA,CAAA,CAEF,0BAPF,sBAQI,6BAAA,CAAA,CAIJ,sBACE,eAAA","sourcesContent":[".content{\n  display: grid;\n  row-gap: 12px;\n}\n.row{\n  display: grid;\n  grid-template-columns: 200px 500px;\n  align-items: center;\n  @media (max-width: 800px) {\n    grid-template-columns: 1fr 1fr;\n  }\n  @media (max-height: 600px) {\n    grid-template-columns: 1fr 1fr;\n  }\n}\n\n.contentBan{\n  max-width: 400px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `sKLCu0s8VKaYtGiyzQPw`,
	"row": `pQ7E9FEWz40W55peuzu7`,
	"contentBan": `Kqb4ULpCAZ8BjGvIA9wA`
};
export default ___CSS_LOADER_EXPORT___;
