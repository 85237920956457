import { makeAutoObservable } from 'mobx'
import { AccountIntegrationType } from 'src/generated/graphql'
import { nanoid } from 'nanoid'
import { RoutesEnum } from 'src/pages/routes'

export class MainStore {
  constructor() {
    makeAutoObservable(this)
  }

  analyticTriggerUpdate = ''
  _redirectPathAfterLogin = ''

  get redirectPathAfterLogin() {
    return this._redirectPathAfterLogin || RoutesEnum.broadcasts
  }

  selectTriggerApp: AccountIntegrationType | null = null

  get isSelectTriggerApp() {
    return !!this.selectTriggerApp
  }
  setSelectedTriggerApp(state?: AccountIntegrationType) {
    this.selectTriggerApp = state || null
  }
  updateAnalyticTrigger() {
    this.analyticTriggerUpdate = nanoid()
  }
  setRedirectPathAfterLogin = (path: string) => {
    this._redirectPathAfterLogin = path
  }
}

export default new MainStore()
