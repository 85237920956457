import { observer } from 'mobx-react-lite'
import { NumbersTable } from 'src/pages/settings/numbers/NumbersTable'
import { CallForwardingModal } from 'src/pages/settings/numbers/modals/CallForwardingModal'
import { ReleaseNumbersModal } from 'src/pages/settings/numbers/modals/ReleaseNumbersModal'
import { Header } from 'shared/Header/Header'

export const Numbers = observer(() => {
  return (
    <>
      <CallForwardingModal />
      <ReleaseNumbersModal />
      <Header title={'Numbers'} />
      <NumbersTable />
    </>
  )
})
