import styles from './styles.module.scss'
import { useNavigate } from 'react-router-dom'
import { BtnSize, Button } from 'components/Button/Button'
import { RoutesEnum } from 'src/pages/routes'
import { ReactComponent as Angle } from 'icons/16px/Angle-Left.svg'
import { ReactComponent as Star } from 'icons/16pxNoMask/Star.svg'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { TypeContent } from 'components/TypeContent/TypeContent'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { FC } from 'react'
import { ContentType, Status } from 'src/generated/graphql'
import { useBroadcastDetailContext } from 'src/pages/main/broadcasts/broadcastDetail/broadcastDetail'
import { BroadcastDetailModal } from 'src/pages/main/broadcasts/modals/BroadcastDetailModal'
import { getBroadcastActions } from 'src/pages/main/broadcasts/functions/getBroadcastActions'
import { MoreBtn, MoreBtnItemProps } from 'components/Button/MoreBtn'
import { BreadCrumbs } from 'components/BreadCrumbs/BreadCrumbs'

export const HeaderContent = observer(() => {
  const navigate = useNavigate()

  const { broadcast, totalCharged } = useBroadcastDetailContext()
  if (!broadcast) {
    return <></>
  }

  const actions = getBroadcastActions(broadcast)

  const rowActions = actions.slice(0, 2)
  const moreActions = actions.slice(2)
  return (
    <div className={styles.header}>
      <BroadcastDetailModal />
      <BreadCrumbs />
      <div className={styles.nav}>
        <div className={styles.left}>
          <div>
            <Button
              hoverGray
              size={BtnSize.small}
              onClick={() => navigate(RoutesEnum.broadcasts)}
            >
              <Angle />
            </Button>
          </div>
          <h1>{broadcast?.name}</h1>
        </div>
        <div className={styles.right}>
          {rowActions.map((Action, index) => (
            <Action key={index} medium />
          ))}
          {!!moreActions?.length && (
            <MoreBtn
              menuItems={moreActions as Array<FC<MoreBtnItemProps>>}
              tooltip={'More'}
              medium
            />
          )}
        </div>
      </div>
      <div className={styles.rowInfo}>
        <StatusContent status={broadcast?.simplifiedStatus as Status} />
        <div className={'point'} />
        <TypeContent type={broadcast?.type || ContentType.Sms} />
        <div className={'point'} />
        <div className={styles.creditCount}>
          <Star />
          <span>
            {totalCharged}{' '}
            {broadcast.simplifiedStatus === Status.Scheduled ? 'estimated' : ''}{' '}
            credits
          </span>
        </div>
      </div>
    </div>
  )
})
