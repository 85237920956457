// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zyFAOLx8eRsooCqSdjNw{padding-top:26px;height:162px}`, "",{"version":3,"sources":["webpack://./src/pages/main/contacts/modals/EditListsModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,YAAA","sourcesContent":[".content{\n  padding-top: 26px;\n  height: 162px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `zyFAOLx8eRsooCqSdjNw`
};
export default ___CSS_LOADER_EXPORT___;
