// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RQBhm9NnsXL_8gutWPhE{margin-top:40px}.cGacQ_K6W8yZZgrT9Lnz{margin-top:12px}`, "",{"version":3,"sources":["webpack://./src/widgets/Advanced/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAEF,sBACE,eAAA","sourcesContent":[".wrap{\n  margin-top: 40px;\n}\n.wrapConversion{\n  margin-top: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `RQBhm9NnsXL_8gutWPhE`,
	"wrapConversion": `cGacQ_K6W8yZZgrT9Lnz`
};
export default ___CSS_LOADER_EXPORT___;
