import { AccountIntegrationType } from 'src/generated/graphql'
import { IIcon } from 'components/Icon/Icon'

export const getAccountIntegrationTypeIIcon = (
  type: AccountIntegrationType
): IIcon => {
  const obj: { [key in AccountIntegrationType]: IIcon } = {
    [AccountIntegrationType.Custom]: 'plus',
    [AccountIntegrationType.ActiveCampaign]: 'campaignLogo',
    [AccountIntegrationType.HubSpot]: 'hubSpotLogo',
    [AccountIntegrationType.Keap]: 'keapLogo',
    [AccountIntegrationType.Drip]: 'drip',
    [AccountIntegrationType.Zapier]: 'zapier',
    [AccountIntegrationType.LeadConnector]: 'plus',
  }
  return obj[type]
}
