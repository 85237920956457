import styles from './styles.module.scss'
import billingStore from 'store/settings/billing/billingStore'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import companyStore from 'store/settings/company/companyStore'

export const CancelSubscription = observer(() => {
  const onCancel = () => {
    if (companyStore.isContract) {
      return
    }
    billingStore.setOpenContinueCancelSubscriptionModal(true)
  }
  return (
    <>
      {/*<SizedBox height={128} />*/}
      <div className={styles.wrap}>
        <button
          className={classNames(styles.button, 'gray1 bold')}
          onClick={onCancel}
          id={'cancel_subscription'}
        >
          Cancel subscription
        </button>
      </div>
    </>
  )
})
