import { Dropdown } from 'components/Dropdown/Dropdown'
import { ReactComponent as Web } from 'icons/16pxNoMask/Web.svg'
import { AppIcon } from 'components/AppIcon/AppIcon'
import React from 'react'
import { useCreateWebFromContext } from 'src/pages/main/webForms/create/CreateWebformState'
import { observer } from 'mobx-react-lite'
import { mockPages } from 'store/webForms/mockData'
import { InputText } from 'components/Input/InputText/InputText'
import { SizedBox } from 'components/SizedBox'

export const FeedbackPageContent = observer(() => {
  const createWebFormStore = useCreateWebFromContext()
  return (
    <>
      <Dropdown
        options={mockPages}
        selectedValue={createWebFormStore.thankYouPageValue}
        onSelectValue={(value) => createWebFormStore.setThankYouPage(value)}
        labelInput={'Thank you page'}
        leftIcon={
          <AppIcon>
            <Web />
          </AppIcon>
        }
      />
      {createWebFormStore.thankYouPageValue === 'custom' && (
        <>
          <SizedBox height={16} />
          <InputText
            placeholder={'website.com'}
            label={'Add link'}
            value={createWebFormStore.customWebsite}
            onChange={(event) =>
              createWebFormStore.setCustomWebsite(event.target.value)
            }
            onClickRightIcon={() => createWebFormStore.setCustomWebsite('')}
          />
        </>
      )}
    </>
  )
})
