import { AccountIntegrationType } from 'src/generated/graphql'
import { getAccountIntegrationTypeIIcon } from 'src/pages/main/triggers/create/components/getAccountIntegrationTypeIcon'
import { IOption } from 'src/types/IOption'

export const mockApps: IOption<AccountIntegrationType>[] = [
  {
    value: AccountIntegrationType.ActiveCampaign,
    title: 'Active Campaign',
    leftIcon: {
      icon: getAccountIntegrationTypeIIcon(
        AccountIntegrationType.ActiveCampaign
      ),
    },
    iconClass: 'box24',
  },
  // {
  //   value: AccountIntegrationType.Drip,
  //   title: 'Drip',
  //   leftIcon: {
  //     icon: getAccountIntegrationTypeIIcon(AccountIntegrationType.Drip),
  //   },
  //   iconClass: 'box24',
  // },
  // {
  //   value: AccountIntegrationType.Zapier,
  //   title: 'Zapier',
  //   leftIcon: {
  //     icon: getAccountIntegrationTypeIIcon(AccountIntegrationType.Zapier),
  //   },
  //   iconClass: 'box24',
  // },
  {
    value: AccountIntegrationType.HubSpot,
    title: 'HubSpot',
    leftIcon: {
      icon: getAccountIntegrationTypeIIcon(AccountIntegrationType.HubSpot),
    },
    iconClass: 'box24',
  },
  {
    value: AccountIntegrationType.Keap,
    title: 'Keap',
    leftIcon: {
      fontSize: 14,
      icon: getAccountIntegrationTypeIIcon(AccountIntegrationType.Keap),
    },
    iconClass: 'box24',
  },
  {
    value: AccountIntegrationType.Custom,
    title: 'Custom',
    leftIcon: {
      color: '--primary-color-gray-1',
      fontSize: 12,
      icon: getAccountIntegrationTypeIIcon(AccountIntegrationType.Custom),
    },
    iconClass: 'box24',
  },
]

export const mockPhoneFields: IOption[] = [
  {
    value: 'Phone Number',
    title: 'Phone Number',
  },
  {
    value: 'MPHONE',
    title: 'MPHONE',
  },
]
