import { IListItem, ListItem } from 'components/SelectList/ListItem/ListItem'
import styles from './styles.module.scss'

export type SelectListProps<T> = {
  options: Array<IListItem>
  onSelect: (id: T, item: IListItem) => void
  withoutMark?: boolean
}

export const SelectList = <T,>({
  options,
  onSelect,
  withoutMark,
}: SelectListProps<T>) => {
  return (
    <div className={styles.wrap}>
      {options.map((item) => (
        <ListItem
          key={item.id}
          item={item}
          onSelect={onSelect}
          withoutMark={withoutMark}
        />
      ))}
    </div>
  )
}
