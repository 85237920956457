import { createContext, PropsWithChildren, useContext } from 'react'

type IDropdownContextProps = {
  parentElement?: HTMLDivElement | null
  onClose: () => void
  onOpen: () => void
  open: boolean
}

const DropdownContext = createContext<IDropdownContextProps | null>(null)

export const useDropdownContext = () => useContext(DropdownContext)

export const DropdownContextProvider = ({
  children,
  ...props
}: PropsWithChildren<IDropdownContextProps>) => (
  <DropdownContext.Provider value={props}>{children}</DropdownContext.Provider>
)
