import { createContext, useContext } from 'react'
import { ConversionDetailStore } from 'widgets/conversions/ConversionDetail/store/conversionDetailStore'

export const ConversionDetailContext =
  createContext<ConversionDetailStore | null>(null)

export const useConversionDetailContext = () => {
  const context = useContext(ConversionDetailContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with ConversionDetailContext'
    )

  return context
}
