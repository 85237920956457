import { useState } from 'react'
import { RegisterStore } from 'store/registerStore'
import { Outlet } from 'react-router-dom'
import { RegisterContext } from 'src/pages/auth/signUp/registerFlowB/context/context'

export const RegisterBLayout = () => {
  const [store] = useState(() => new RegisterStore())
  return (
    <RegisterContext.Provider value={store}>
      <Outlet />
    </RegisterContext.Provider>
  )
}
