// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._5252R04FLFiFiUD3NfhQ{background:var(--gradient-1);min-height:100vh;width:100%}.FUKZTOl8YyJ4yvo4zPjB{height:96px;width:100%;display:grid;grid-template-columns:130px 1fr 130px;padding:32px;margin-bottom:80px}.Egb_qLMBuehykpgklhmP{display:flex;justify-content:center}.UDyLSnpIkT2HVnQ4a1tg{margin-top:80px;display:flex;justify-content:center}.CA4Oa88RkDXneJJeL3VG{display:flex;justify-content:right}`, "",{"version":3,"sources":["webpack://./src/pages/auth/migration/styles.module.scss"],"names":[],"mappings":"AAAA,uBACE,4BAAA,CACA,gBAAA,CACA,UAAA,CAKF,sBACE,WAAA,CACA,UAAA,CACA,YAAA,CACA,qCAAA,CACA,YAAA,CACA,kBAAA,CAEF,sBACE,YAAA,CACA,sBAAA,CAGF,sBACE,eAAA,CACA,YAAA,CACA,sBAAA,CAEF,sBACE,YAAA,CACA,qBAAA","sourcesContent":[".wrap{\n  background: var(--gradient-1);\n  min-height: 100vh;\n  width: 100%;\n  //display: flex;\n\n}\n\n.header{\n  height: 96px;\n  width: 100%;\n  display: grid;\n  grid-template-columns: 130px 1fr 130px;\n  padding: 32px;\n  margin-bottom: 80px;\n}\n.steps{\n  display: flex;\n  justify-content: center;\n}\n\n.content{\n  margin-top: 80px;\n  display: flex;\n  justify-content: center;\n}\n.right{\n  display: flex;\n  justify-content: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `_5252R04FLFiFiUD3NfhQ`,
	"header": `FUKZTOl8YyJ4yvo4zPjB`,
	"steps": `Egb_qLMBuehykpgklhmP`,
	"content": `UDyLSnpIkT2HVnQ4a1tg`,
	"right": `CA4Oa88RkDXneJJeL3VG`
};
export default ___CSS_LOADER_EXPORT___;
