import React, { PropsWithChildren } from 'react'
import { TextareaStore } from 'store/textareaStore'
import { TextareaContext } from 'components/Textarea/context/context'
import { ITextareaProps, TextareaUi } from 'components/Textarea/TextareUi'

type Props = PropsWithChildren<{
  textareaStore: TextareaStore
}> &
  ITextareaProps

export const Textarea = ({ textareaStore, ...props }: Props) => (
  <TextareaContext.Provider value={textareaStore}>
    <TextareaUi {...props} />
  </TextareaContext.Provider>
)
