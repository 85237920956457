import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { CSSProperties, FC, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { MuiTooltipProps } from 'components/Tooltip/MuiTooltip'
import { ValidItem } from 'components/Input/InputText/ValidItem'
import { DropdownField } from 'components/DropdownField/DropdownField'
import { Icon } from 'components/Icon/Icon'
import { NewLabel } from 'src/shared/NewLabel/NewLabel'

type Props = {
  placement: MuiTooltipProps['placement']
  scheduleContent: ReactNode
  tabIndex?: number
  width?: CSSProperties['width']
  sendTimeString?: string
  suffixString?: string
  scheduleError?: string
  isNew?: boolean
}

export type ScheduleProps = {
  onActive: () => void
  isActive: boolean
  content?: ReactNode
}

export const Schedule: FC<Props> = observer(
  ({
    placement,
    tabIndex,
    sendTimeString,
    suffixString,
    scheduleError,
    scheduleContent,
    width = 350,
    isNew,
  }) => {
    return (
      <div className={'fit-content'}>
        <DropdownField
          dropdownContent={scheduleContent}
          placement={placement}
          width={width}
          zIndex={100}
        >
          <div>
            <Button
              typeBtn={BtnType.secondaryBlue}
              size={BtnSize.small}
              p12
              tabIndex={tabIndex}
            >
              <Icon icon={'schedule'} />
              <span className={'blue2 bold'}>{sendTimeString}</span>
              {suffixString && (
                <span className={'blue2Opacity6'}>{suffixString}</span>
              )}
              {isNew && <NewLabel />}
            </Button>
            {scheduleError && <ValidItem text={scheduleError} mt={8} />}
          </div>
        </DropdownField>
      </div>
    )
  }
)
