import { observer } from 'mobx-react-lite'
import { ReactComponent as Search } from 'icons/16px/Search.svg'
import { InputText } from 'components/Input/InputText/InputText'
import * as React from 'react'
import { TableStore } from 'components/NewTable/store/TableStore'
import uiStore from 'store/uiStore'
import { MediumBtn } from 'components/Button/MediumBtn'
import { ActionsEnum } from 'components/Button/types'
import { RequiredFields } from 'components/NewTable/types'

export const TableSearch = observer(
  <T extends RequiredFields<T>>({
    tableStore,
    onlySearch,
  }: {
    tableStore: TableStore<T>
    onlySearch?: boolean
  }) => {
    if (uiStore.isMobile && !tableStore.showMobileSearch && !onlySearch) {
      return (
        <MediumBtn
          type={ActionsEnum.search}
          noText
          onClick={() => tableStore.setShowMobileSearch(true)}
        />
      )
    }
    return (
      <InputText
        leftIconContent={<Search />}
        placeholder={'Search...'}
        value={tableStore.search}
        onChange={(event) => tableStore.setSearch(event.target.value)}
        onClickRightIcon={() => tableStore.setSearch('')}
        width={240}
      />
    )
  }
)
