import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { Counter } from 'components/Counter/Counter'
import { periodsOptions } from 'components/Schedule/store/mockData'
import { CampaignDelayPeriod } from 'src/generated/graphql'
import dayjs from 'lib/dayjs'
import { TimePicker } from 'components/TimePicker/TimePicker'
import { useCampaignScheduleContext } from 'src/widgets/CampaignSchedule'
import { useDropdownContext } from 'components/Dropdown/context/context'

export const LaterContentCampaign = observer(() => {
  const scheduleStore = useCampaignScheduleContext()
  useEffect(() => {
    if (
      scheduleStore.laterPeriod?.value !== CampaignDelayPeriod.Hour &&
      scheduleStore.laterPeriod?.value !== CampaignDelayPeriod.Minute &&
      !scheduleStore.laterDate
    ) {
      scheduleStore.setLaterDate(
        dayjs().startOf('day').add(12, 'hours').toDate()
      )
    }
  }, [scheduleStore.activeSendLater, scheduleStore.laterPeriod])

  const dropdownContext = useDropdownContext()
  const parentElement = dropdownContext?.parentElement

  if (!scheduleStore.activeSendLater) {
    return <></>
  }
  return (
    <>
      <div className={styles.wrap}>
        <Counter
          count={scheduleStore.laterCount}
          onMinus={() =>
            scheduleStore.setLaterCount(+scheduleStore.laterCount - 1)
          }
          onPlus={() =>
            scheduleStore.setLaterCount(+scheduleStore.laterCount + 1)
          }
          onChange={scheduleStore.setLaterCount}
        />
        <Dropdown
          options={periodsOptions}
          selectedOption={scheduleStore.laterPeriod}
          onSelect={(opt) => scheduleStore.setLaterPeriod(opt)}
          parentElement={parentElement}
        />
        {scheduleStore.laterPeriod?.value !== CampaignDelayPeriod.Hour &&
          scheduleStore.laterPeriod?.value !== CampaignDelayPeriod.Minute && (
            <div className={styles.time}>
              <TimePicker
                startTime={scheduleStore.laterDate}
                onChange={(time) => scheduleStore.setLaterDate(time)}
              />
            </div>
          )}
      </div>
    </>
  )
})
