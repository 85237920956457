import { createContext, useContext } from 'react'
import { BroadcastScheduleStore } from 'src/widgets/BroadcastSchedule'

export const BroadcastScheduleContext =
  createContext<BroadcastScheduleStore | null>(null)

export const useBroadcastScheduleContext = () => {
  const context = useContext(BroadcastScheduleContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with BroadcastScheduleContext'
    )

  return context
}
