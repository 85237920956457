import { observer } from 'mobx-react-lite'
import mainStore from 'store/mainStore'
import { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { UnauthorizedRoutes } from 'src/pages/routes'

export const RedirectAfterLogin = observer(() => {
  const { pathname, search } = useLocation()
  useEffect(() => {
    mainStore.setRedirectPathAfterLogin(`${pathname}${search}`)
  }, [pathname, search])
  return <Navigate to={UnauthorizedRoutes.login} />
})
