import styles from './style.module.scss'
import { InputText } from 'components/Input/InputText/InputText'
import { Card } from 'components/Card/Card'
import { Tabs } from 'components/Tabs/Tabs'
import React from 'react'
import { SizedBox } from 'components/SizedBox'
import { observer } from 'mobx-react-lite'
import { SMSContent } from 'components/CreateElement/SMSContent/SMSContent'
import { MainVoiceContent } from 'components/CreateElement/MainVoiceContent'
import { RinglessContent } from 'components/CreateElement/RinglessContent'
import { MessageTemplatesModal } from 'src/widgets/MessageTemplate'
import { Alert } from 'components/Alert/Alert'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { AppIcon, IIconType } from 'components/AppIcon/AppIcon'
import { ColorsNames } from 'styles/variables'
import { ReactComponent as Close } from 'icons/16pxNoMask/Close.svg'
import { Button } from 'components/Button/Button'
import { RoutesEnum } from 'src/routes/routes'
import { LinkWrapper } from 'components/Wrappers/LinkWrapper'
import { BroadcastLimitAlert } from 'src/routes/main/broadcasts/createBroadcast/newBroadcast/BroadcastLimitAlert'
import { appLinks } from 'src/util/links'
import { ActionsContent } from 'src/routes/main/broadcasts/createBroadcast/newBroadcast/ActionContent/ActionsContent'
import classNames from 'classnames'
import uiStore from 'store/uiStore'
import { useElementName } from 'src/hooks/useElementName'
import { SendToDropDownWithContacts } from 'components/SendToDropDownWithContacts/SendToDropDownWithContacts'
import { useCreateBroadcastContext } from 'src/routes/main/broadcasts/createBroadcast/context/CreateBroadcastContext'
import { BroadcastSchedule } from 'src/widgets/BroadcastSchedule'

export const NewBroadcast = observer(() => {
  const newBroadCastStore = useCreateBroadcastContext()
  const { error, onBlur, onFocus } = useElementName(newBroadCastStore)
  return (
    <Card
      width={600}
      noMobileRadius
      className={classNames(uiStore.isMobile && 'minHeight100')}
    >
      <MessageTemplatesModal store={newBroadCastStore} />
      <span className={'gray1'}>{`${
        newBroadCastStore.edit ? 'Edit' : 'New'
      } broadcast`}</span>
      <div className={styles.inputWrap}>
        <InputText
          placeholder={'Broadcast name'}
          withoutClear
          withoutBorder
          header
          value={newBroadCastStore.name}
          onChange={(e) => newBroadCastStore.setName(e.target.value)}
          onFocus={onFocus}
          onBlur={onBlur}
          error={error}
        />
      </div>
      {newBroadCastStore.isVerifyInfoTollFree && (
        <>
          <Alert
            title={
              'You are required by the carriers to verify your toll-free numbers'
            }
            text={
              <span>
                To avoid carrier filtering and ensure better deliverability,
                please complete the{' '}
                <LinkWrapper to={RoutesEnum.settingsCompliance}>
                  toll-free verification.
                </LinkWrapper>{' '}
                <a
                  href={appLinks.tollFreeVerification}
                  target={'_blank'}
                  rel="noreferrer"
                  className={'s1'}
                >
                  Learn more
                </a>
              </span>
            }
            rightContent={
              <Button
                onClick={() => {
                  newBroadCastStore.setHiddenVerifyInfoTollFree()
                }}
                className={'mt6'}
              >
                <AppIcon color={ColorsNames.black} type={IIconType.stroke}>
                  <Close />
                </AppIcon>
              </Button>
            }
            type={AlertTypeEnum.notify2}
          />
          <SizedBox height={40} />
        </>
      )}
      {newBroadCastStore.isVerifyInfoLocal && (
        <>
          <Alert
            title={'You are required by the carriers to register for A2P 10DLC'}
            text={
              <span>
                To avoid messages from being blocked and paying additional
                carrier penalty fees, please complete the{' '}
                <LinkWrapper to={RoutesEnum.settingsCompliance}>
                  10DLC registration.
                </LinkWrapper>
                A2P 10DLC is required if you want to send messages to United
                States using local numbers.{' '}
                <a
                  href={appLinks.a2p10dlcCompliance}
                  target={'_blank'}
                  rel="noreferrer"
                  className={'s1'}
                >
                  Learn more
                </a>
              </span>
            }
            rightContent={
              <Button
                onClick={() => {
                  newBroadCastStore.setHiddenVerifyInfoLocal()
                }}
                className={'mt6'}
              >
                <AppIcon color={ColorsNames.black} type={IIconType.stroke}>
                  <Close />
                </AppIcon>
              </Button>
            }
            type={AlertTypeEnum.notify2}
          />
          <SizedBox height={40} />
        </>
      )}
      <Tabs
        tabs={['SMS', 'Voice', 'Ringless']}
        activeTabIndex={newBroadCastStore.activeTab}
        onTabClick={(index) => newBroadCastStore.setActiveTab(index)}
      />
      <SizedBox height={16} />
      {newBroadCastStore.activeTab === 0 && (
        <SMSContent store={newBroadCastStore} />
      )}
      {newBroadCastStore.activeTab === 1 && (
        <MainVoiceContent store={newBroadCastStore} />
      )}
      {newBroadCastStore.activeTab === 2 && (
        <RinglessContent store={newBroadCastStore} />
      )}
      <SizedBox height={24} />
      <SendToDropDownWithContacts
        noKeyword
        noWebForm
        dropdownContactListsStore={newBroadCastStore.dropdownContactListsStore}
      />

      {newBroadCastStore.isLimitInfo && (
        <>
          <SizedBox height={16} />
          <BroadcastLimitAlert />
          <SizedBox height={8} />
        </>
      )}
      <SizedBox height={24} />
      <BroadcastSchedule store={newBroadCastStore.scheduleStore} />
      <ActionsContent />
    </Card>
  )
})
