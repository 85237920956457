// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MU8chS6KtdWb1GSifUtn{margin-top:40px;display:grid;gap:32px}.zOy6L38D1qNuMLixDdFR{display:flex;justify-content:space-between}.zOy6L38D1qNuMLixDdFR .IR4fgviCRAYz5ZqR1sHg{display:flex;column-gap:12px}.yIpjxvTuwqNGn5Y8sjz4{padding:8px}`, "",{"version":3,"sources":["webpack://./src/pages/main/webForms/create/createForm/ActionContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,YAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,4CACE,YAAA,CACA,eAAA,CAGJ,sBACE,WAAA","sourcesContent":[".wrap{\n  margin-top: 40px;\n  display: grid;\n  gap: 32px;\n}\n\n.actionsWrap{\n  display: flex;\n  justify-content: space-between;\n  .left{\n    display: flex;\n    column-gap: 12px;\n  }\n}\n.menuWrap{\n  padding: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `MU8chS6KtdWb1GSifUtn`,
	"actionsWrap": `zOy6L38D1qNuMLixDdFR`,
	"left": `IR4fgviCRAYz5ZqR1sHg`,
	"menuWrap": `yIpjxvTuwqNGn5Y8sjz4`
};
export default ___CSS_LOADER_EXPORT___;
