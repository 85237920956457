import styles from './styles.module.scss'
import { FieldCollect } from 'src/pages/main/webForms/create/createForm/FieldsCollect/FieldCollect'
import { DragList } from 'components/DragList/DragList'
import { ReactComponent as Plus } from 'icons/16pxNoMask/Plus.svg'
import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useCreateWebFromContext } from 'src/pages/main/webForms/create/CreateWebformState'
import { BtnSize, BtnType, Button } from 'components/Button/Button'

export const FieldsCollect = observer(() => {
  const createWebFormStore = useCreateWebFromContext()
  const elements = useMemo(() => {
    return createWebFormStore.fieldsToCollect.map((filed, _, self) => ({
      id: filed.id,
      component: <FieldCollect field={filed} length={self.length} />,
    }))
  }, [createWebFormStore.fieldsToCollect])
  return (
    <div className={styles.wrap}>
      <p>
        <b>Fields to collect</b>
      </p>
      <div className={styles.fieldsWrap}>
        <DragList
          elements={elements}
          order={createWebFormStore.orderFieldsCollect}
          setOrder={(order) => createWebFormStore.setOrderFieldsCollect(order)}
        />
      </div>
      <Button
        typeBtn={BtnType.secondaryBlue}
        size={BtnSize.small}
        fullWidth
        onClick={() => createWebFormStore.addCollectField()}
      >
        {/*<AppIcon/>*/}
        <Plus />
        Add field
      </Button>
    </div>
  )
})
