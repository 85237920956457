import { ImportBtn } from 'src/pages/main/contacts/components/ImportBtn/ImportBtn'
import { CheckLimitContactsWrap } from 'src/pages/main/contacts/components/CheckLimitContactsWrap'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import manualContactStore from 'store/contacts/manualContactStore'
import { ReactComponent as Contacts } from 'icons/16px/Contacts Add.svg'
import customFieldsStore from 'store/contacts/customFields/customFieldsStore'
import { ReactComponent as Custom } from 'icons/16px/Custom.svg'
import { useParams } from 'react-router-dom'

export const NoSelectContent = () => {
  const { id } = useParams()
  return (
    <>
      <ImportBtn />
      <CheckLimitContactsWrap>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.secondaryGray}
          withIcon
          onClick={() => manualContactStore.setOpenContactModal(true, id)}
        >
          <Contacts />
          Add manually
        </Button>
      </CheckLimitContactsWrap>

      {id ? (
        <>{/*<MediumBtn type={ActionsEnum.export} />*/}</>
      ) : (
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.secondaryGray}
          withIcon
          onClick={() => customFieldsStore.setOpenCustomFieldsModalModal(true)}
        >
          <Custom />
          Custom fields
        </Button>
      )}
    </>
  )
}
