import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import webFormStore from 'store/webForms/webFormStore'
import { CreateWebFormState } from 'src/pages/main/webForms/create/CreateWebformState'
import { PreviewWebFrom } from 'src/pages/main/webForms/create/preview/PreviewWebFrom'
import { NewWebForm } from 'src/pages/main/webForms/create/createForm/NewWebForm'

export const NewWebFormModal = observer(() => {
  const onClose = () => {
    webFormStore.setOpenWebFormModal(false)
  }
  return (
    <Modal
      open={webFormStore.openNewWebFormModal}
      onClose={onClose}
      title={'New web form'}
      minWidth={1000}
    >
      <CreateWebFormState>
        <div className={styles.content}>
          <NewWebForm fromModal />
          <div className={styles.preview}>
            <PreviewWebFrom />
          </div>
        </div>
      </CreateWebFormState>
    </Modal>
  )
})
