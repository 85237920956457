// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TuHruMwsbcw7TQmmZrOd{display:flex;width:fit-content}.mkiAdfzgr4v4sPwOpTvg{display:grid;grid-template-columns:508px 508px;column-gap:24px}`, "",{"version":3,"sources":["webpack://./src/pages/main/dashboard/Performance/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,iBAAA,CAEF,sBACE,YAAA,CACA,iCAAA,CACA,eAAA","sourcesContent":[".wrapPicker{\n  display: flex;\n  width: fit-content;\n}\n.cardWrap{\n  display: grid;\n  grid-template-columns: 508px 508px;\n  column-gap: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapPicker": `TuHruMwsbcw7TQmmZrOd`,
	"cardWrap": `mkiAdfzgr4v4sPwOpTvg`
};
export default ___CSS_LOADER_EXPORT___;
