import { ActionBtnProps } from 'components/NewTable/types'
import * as React from 'react'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import { IAudioItem } from 'src/types/IAudioItem'
import { useAudioFunctions } from 'src/pages/settings/audio/hooks/useAudioFunctions'

export const AudioDownloadBtn = (props: ActionBtnProps<IAudioItem>) => {
  const { row, rows } = props
  const { onDownload } = useAudioFunctions({
    audioItem: row,
    audioItems: rows,
  })
  return (
    <TableBtn
      {...props}
      action={ActionsEnum.download}
      onAction={() => onDownload()}
    />
  )
}
