// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XkFECduBqW79IPjYz_uT{display:grid;grid-template-columns:150px 1fr;align-content:center;align-items:flex-start}.XkFECduBqW79IPjYz_uT .lf8MR_dIpnBkr11l4vi5{min-height:24px;display:flex;column-gap:8px;align-items:center}.XkFECduBqW79IPjYz_uT .lf8MR_dIpnBkr11l4vi5.zYVUOI6Mu2SKiy04xC9q{align-items:flex-start}.XkFECduBqW79IPjYz_uT .lf8MR_dIpnBkr11l4vi5 .bo8OAljvIeJmTRhhCmbA{align-items:center;min-height:24px;display:flex;column-gap:8px}.XkFECduBqW79IPjYz_uT .V2NMm5KlMVp3Cj_Sz7fD{align-items:center;display:grid;grid-auto-rows:minmax(24px, 1fr)}.XkFECduBqW79IPjYz_uT a{text-decoration:underline;font-size:14px;line-height:16px}`, "",{"version":3,"sources":["webpack://./src/components/DetailsModalContent/DetailRow/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,+BAAA,CACA,oBAAA,CACA,sBAAA,CACA,4CACE,eAAA,CACA,YAAA,CACA,cAAA,CACA,kBAAA,CACA,iEACE,sBAAA,CAEF,kEACE,kBAAA,CACA,eAAA,CACA,YAAA,CACA,cAAA,CAIJ,4CACE,kBAAA,CACA,YAAA,CACA,gCAAA,CAGF,wBACE,yBAAA,CACA,cAAA,CACA,gBAAA","sourcesContent":[".rowTable{\n  display: grid;\n  grid-template-columns: 150px 1fr;\n  align-content: center;\n  align-items: flex-start;\n  .left{\n    min-height: 24px;\n    display: flex;\n    column-gap: 8px;\n    align-items: center;\n    &.start{\n      align-items: flex-start;\n    }\n    .leftContent{\n      align-items: center;\n      min-height: 24px;\n      display: flex;\n      column-gap: 8px;\n    }\n  }\n\n  .right{\n    align-items: center;\n    display: grid;\n    grid-auto-rows: minmax(24px, 1fr);\n  }\n\n  a{\n    text-decoration: underline;\n    font-size: 14px;\n    line-height: 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rowTable": `XkFECduBqW79IPjYz_uT`,
	"left": `lf8MR_dIpnBkr11l4vi5`,
	"start": `zYVUOI6Mu2SKiy04xC9q`,
	"leftContent": `bo8OAljvIeJmTRhhCmbA`,
	"right": `V2NMm5KlMVp3Cj_Sz7fD`
};
export default ___CSS_LOADER_EXPORT___;
