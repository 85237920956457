import NewTable from 'components/NewTable/NewTable'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { WrapPageSuperAdmin } from 'src/pages/superAdmin/components/WrapPageSuperAdmin/WrapPageSuperAdmin'
import { useAdminGetImportHistoryQuery } from 'src/generated/graphql'
import { EmptyStateSearch } from 'components/EmptyState/EmptyStateSearch'
import { IContactImportState } from 'src/pages/main/contacts/importHistory/ImportHistoryTable/types'
import { Filters } from 'src/pages/superAdmin/routes/imports/Filters'
import { getImportsActions } from 'src/pages/superAdmin/hooks/getImportsActions'
import { superAdminImportColumnsNoSortsColumns } from 'components/NewTable/columns/superAdmin/superAdminImportColumns'
import superAdminImportsStore from 'store/superAdmin/superAdminImportsStore'
import { AppElements } from 'src/enums/appElements'

export const Imports = observer(() => {
  const [fistLoading, setFirstLoading] = useState(true)
  const [refetchLoading, setRefetchLoading] = useState(false)

  const tableStore = superAdminImportsStore.tableStore
  const { data, refetch, loading } = useAdminGetImportHistoryQuery({
    variables: {
      page: tableStore.paginationInput,
      searchPattern: tableStore.search,
      statuses: superAdminImportsStore.importsStatuses,
    },
  })

  useEffect(() => {
    if (data?.adminGetImportHistory) {
      setFirstLoading(false)
      superAdminImportsStore.setAdminGetImportHistoryQuery(data)
    }
  }, [data])

  useEffect(() => {
    setRefetchLoading(true)
    refetch()
      .catch((e) => console.error(e))
      .finally(() => setRefetchLoading(false))
  }, [tableStore.refreshTrigger])

  return (
    <>
      <WrapPageSuperAdmin title={'Imports'}>
        <NewTable<IContactImportState>
          columns={tableStore.visibleColumns}
          withCalcMaxHeight
          rows={superAdminImportsStore.imports.map((importState) => ({
            ...importState,
            actions: getImportsActions(importState),
          }))}
          leftStickyContent={<Filters />}
          withSearch
          tableStore={tableStore}
          firstLoading={fistLoading}
          pageLoading={loading || refetchLoading}
          noSortsColumns={superAdminImportColumnsNoSortsColumns}
          emptyContent={
            !superAdminImportsStore.imports.length ? (
              <EmptyStateSearch element={AppElements.Imports} />
            ) : undefined
          }
        />
      </WrapPageSuperAdmin>
    </>
  )
})
