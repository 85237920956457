import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { FC } from 'react'
import { MoreBtn, MoreBtnItemProps } from 'components/Button/MoreBtn'
import { getTriggerBtnActions } from 'src/pages/main/triggers/functions/getTriggerBtnActions'
import triggersStore from 'store/triggers/triggersStore'

export const OneSelectContent = observer(() => {
  const row =
    triggersStore.tableStore.checkedRows &&
    triggersStore.tableStore.checkedRows[0]
  const actions = getTriggerBtnActions(row, true)

  const rowActions = actions.slice(0, 4)
  const moreActions = actions.slice(4)
  return (
    <>
      {rowActions.map((Action, index) => (
        <Action key={index} medium />
      ))}
      {!!moreActions?.length && (
        <MoreBtn
          menuItems={moreActions as Array<FC<MoreBtnItemProps>>}
          tooltip={'More'}
          medium
        />
      )}
    </>
  )
})
