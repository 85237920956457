import { BtnSize, Button } from 'components/Button/Button'
import { ReactComponent as Message } from 'icons/16px/Message-Templates.svg'
import messageTemplateStore from 'src/widgets/MessageTemplate/store/MessageTemplateStore'
import { NewLabel } from 'src/shared/NewLabel/NewLabel'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'

export const MessageTemplateAction = () => (
  <MuiTooltip
    title={
      <div className={'row8'}>
        Message templates
        <NewLabel />
      </div>
    }
    arrow
    placement={'top'}
  >
    <Button
      size={BtnSize.small}
      hoverGray
      onClick={messageTemplateStore.onOpen}
    >
      <Message />
      <NewLabel isPoint />
    </Button>
  </MuiTooltip>
)
