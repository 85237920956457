import { Modal } from 'components/Modal/Modal'
import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import { DragList } from 'components/DragList/DragList'
import { useMemo } from 'react'
import { FieldItem } from 'src/pages/main/contacts/customFields/modals/CustomFieldsModal/FieldItem'
import customFieldsStore from 'store/contacts/customFields/customFieldsStore'
import {
  CustomField,
  useUpdateCustomFieldsMutation,
} from 'src/generated/graphql'
import {
  transformCustomFieldsFromBack,
  transformCustomFieldsToBack,
} from 'store/contacts/customFields/functions'
import { PlusBtn } from 'components/Button/PlusBtn/PlusBtn'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { runInAction } from 'mobx'
import { useGetCustomFields } from 'src/pages/main/contacts/customFields/hooks/useGetCustomFields'

export const CustomFieldsModal = observer(() => {
  useGetCustomFields(!customFieldsStore.openCustomFieldsModal)
  const [updateCustomFields] = useUpdateCustomFieldsMutation()
  const onClose = () => {
    customFieldsStore.setOpenCustomFieldsModalModal(false)
  }

  const onSave = () => {
    customFieldsStore.onSave()
    updateCustomFields({
      variables: {
        fields: {
          customFields: transformCustomFieldsToBack(
            customFieldsStore.customFieldsFiltered
          ),
        },
      },
    })
      .then(({ data }) => {
        if (data?.updateCustomFields) {
          const customFields: CustomField[] = []
          data?.updateCustomFields?.forEach((field) => {
            if (field) {
              customFields.push(field)
            }
          })
          runInAction(() => {
            customFieldsStore.customFieldsMap = new Map(
              transformCustomFieldsFromBack(customFields).map((field) => [
                field.id,
                field,
              ])
            )
          })
        }
      })
      .catch(console.error)
  }
  const elements = useMemo(() => {
    return customFieldsStore.customFieldsModal.map((filed) => ({
      id: filed.id,
      component: <FieldItem {...filed} />,
    }))
  }, [customFieldsStore.customFieldsModal])

  const onAddCustomFields = () => {
    customFieldsStore.addCustomField()
  }

  return (
    <Modal
      open={customFieldsStore.openCustomFieldsModal}
      onClose={onClose}
      title={'Custom fields'}
      titleTooltip={'Info'}
      minWidth={528}
    >
      <div className={styles.wrap}>
        <div className={styles.header}>
          <div className={'s2 medium gray1'}>Name</div>
          <div className={'s2 medium gray1'}>Type</div>
        </div>
        <div className={styles.wrapContent}>
          <div className={styles.content}>
            <DragList
              elements={elements}
              order={customFieldsStore.orderCustomFields}
              setOrder={(order) =>
                customFieldsStore.setOrderCustomFields(order)
              }
            />
          </div>
          <PlusBtn title={'Add custom field'} onClick={onAddCustomFields} />
        </div>
        <div className={'row12'}>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            onClick={onSave}
            disabled={customFieldsStore.disabledSave}
          >
            Save
          </Button>
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
})
