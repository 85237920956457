// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZlNpaKaUlnUFGSDCY0a7{text-align:center;font-size:12px;line-height:16px;color:var(--primary-color-gray-1)}`, "",{"version":3,"sources":["webpack://./src/pages/auth/components/FooterText/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,cAAA,CACA,gBAAA,CACA,iCAAA","sourcesContent":[".wrap{\n  text-align: center;\n  font-size: 12px;\n  line-height: 16px;\n  color: var(--primary-color-gray-1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `ZlNpaKaUlnUFGSDCY0a7`
};
export default ___CSS_LOADER_EXPORT___;
