import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import keywordsStore from 'store/keywords/keywordsStore'
import { NewKeyword } from 'src/pages/main/keywords/create/createForm/NewKeyword'
import { PreviewKeyword } from 'src/pages/main/keywords/create/preview/PreviewKeyword'
import { CreateKeywordState } from 'src/pages/main/keywords/create/CreateKeywordState'
import styles from './styles.module.scss'

export const NewKeywordModal = observer(() => {
  const onClose = () => {
    keywordsStore.setOpenKeywordModal(false)
  }
  return (
    <Modal
      open={keywordsStore.openNewKeywordModal}
      onClose={onClose}
      title={'New keyword'}
      minWidth={1000}
    >
      <CreateKeywordState>
        <div className={styles.content}>
          <NewKeyword fromModal />
          <div className={styles.preview}>
            <PreviewKeyword />
          </div>
        </div>
      </CreateKeywordState>
    </Modal>
  )
})
