// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gm3zgHeYQsvfkzMb_mtB{width:100%;height:100%;overflow:auto}.oxPLbXcDztTNQ5x8cXly{margin:0 auto;padding:32px;width:664px;display:flex;flex-direction:column;align-items:center}.oxPLbXcDztTNQ5x8cXly iframe{border-radius:8px}.r_rAI6clRVk_hYUoGcah{width:600px}.r_rAI6clRVk_hYUoGcah h3{text-align:left}.b0tqsI1WgYO1yjFOyMUw{display:flex;column-gap:12px}`, "",{"version":3,"sources":["webpack://./src/pages/main/dashboard/Objectives/ActiveObjective/ActiveObjectiveContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,aAAA,CAEF,sBACE,aAAA,CACA,YAAA,CACA,WAAA,CAIA,YAAA,CACA,qBAAA,CACA,kBAAA,CALA,6BACE,iBAAA,CAOJ,sBACE,WAAA,CACA,yBACE,eAAA,CAGJ,sBACE,YAAA,CACA,eAAA","sourcesContent":[".layout{\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n}\n.wrap{\n  margin: 0 auto;\n  padding: 32px;\n  width: 664px;\n  iframe{\n    border-radius: 8px;\n  }\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n}\n.content{\n  width: 600px;\n  h3{\n    text-align: left;\n  }\n}\n.wrapActions{\n  display: flex;\n  column-gap: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `gm3zgHeYQsvfkzMb_mtB`,
	"wrap": `oxPLbXcDztTNQ5x8cXly`,
	"content": `r_rAI6clRVk_hYUoGcah`,
	"wrapActions": `b0tqsI1WgYO1yjFOyMUw`
};
export default ___CSS_LOADER_EXPORT___;
