export const typographyDefaultTag: { [key: string]: string } = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  p0: 'p',
  p1: 'p',
  p2: 'p',
  s1: 'span',
  s2: 'span',
  s3: 'span',
  s4: 'span',
}
