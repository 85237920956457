import { makeAutoObservable } from 'mobx'
import dayjs from 'lib/dayjs'
import { SendLaterConfig, SendLaterConfigInput } from 'src/generated/graphql'
import { RepeatStore } from 'src/widgets/BroadcastSchedule/ui/LaterContent/RepeatContent/store/repeatStore'

export class BroadcastScheduleLaterStore {
  private _date: Date | null = dayjs().add(5, 'minute').toDate()
  repeatStore: RepeatStore
  constructor(sendLaterConfig?: SendLaterConfig | null) {
    if (sendLaterConfig) {
      if (sendLaterConfig?.sendDate) {
        this._date = dayjs(sendLaterConfig?.sendDate).toDate()
      }
      this.repeatStore = new RepeatStore(sendLaterConfig)
    } else {
      this._date = dayjs().add(5, 'minute').toDate()
      this.repeatStore = new RepeatStore()
    }

    makeAutoObservable(this)
  }

  get suffixString() {
    return this.repeatStore.suffixString
  }

  get sendLaterConfigInput(): SendLaterConfigInput {
    return {
      sendDate: this.date,
      ...this.repeatStore.schedulingConfigInput,
    }
  }

  get date() {
    return this._date
  }

  setDate = (value: typeof this._date) => {
    this._date = value
  }

  get scheduleError() {
    if (this.date) {
      const sendLaterSendDateTimestamp = +this.date
      const nowTimestamp = +new Date()
      if (sendLaterSendDateTimestamp < nowTimestamp) {
        return 'Send date invalid'
      }
    }
    return ''
  }
  get sendTimeString() {
    return `${dayjs(this.date).format('ddd, MMM DD')} - ${dayjs(
      this.date
    ).format('h:mm a')}`
  }
}
