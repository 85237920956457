import { observer } from 'mobx-react-lite'
import broadcastsStore from 'store/broadcasts/broadcastsStore'
import { getManyBroadcastsActions } from 'src/pages/main/broadcasts/functions/getManyBroadcastsActions'

export const ManySelectContent = observer(() => {
  const actions = getManyBroadcastsActions(
    broadcastsStore.tableStore.checkedRows
  )

  return (
    <>
      {actions.map((Action, index) => (
        <Action key={index} medium />
      ))}
    </>
  )
})
