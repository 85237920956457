import { ActionsEnum } from 'components/Button/types'
import { FC } from 'react'
import { ActionBtnProps } from 'components/NewTable/types'
import { ITrigger } from 'src/types/ITrigger'
import { getTriggerActionsByStatus } from 'src/pages/main/triggers/functions/getTriggerActionsByStatus'
import { triggerBtnByAction } from 'src/pages/main/triggers/functions/triggerBtnByAction'

const manyActionsStatics = [
  // ActionsEnum.stop,
  // ActionsEnum.pause,
  // ActionsEnum.resume,
  ActionsEnum.delete,
]

export const getManyTriggersActions = (
  rows: ITrigger[]
): Array<FC<ActionBtnProps<ITrigger>>> => {
  const triggersActions: ActionsEnum[][] = []
  const triggersStatuses = rows.map((row) => row.status)

  triggersStatuses.forEach((status) => {
    if (status) {
      triggersActions.push(getTriggerActionsByStatus(status))
    }
  })

  const manyActions = manyActionsStatics.filter((action) =>
    triggersActions.every((bActions) => bActions.includes(action))
  )

  const actionsFC: Array<FC<ActionBtnProps<ITrigger>>> = []

  manyActions.forEach((action) => {
    const btn: FC<ActionBtnProps<ITrigger>> | undefined =
      triggerBtnByAction[action as keyof typeof triggerBtnByAction]
    if (btn) {
      actionsFC.push((props) => btn({ ...props, rows }))
    }
  })
  return actionsFC
}
