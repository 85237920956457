import { observer } from 'mobx-react-lite'
import audioStore from 'store/audioStore/audioStore'
import styles from 'src/Modals/AudioModal/style.module.scss'
import NewTable from 'components/NewTable/NewTable'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useGetAllAudioItemsQuery } from 'src/generated/graphql'
import { IAudioItem } from 'src/types/IAudioItem'
import { LeftStickyContent } from 'src/pages/settings/audio/AudioTable/LeftStickyContent/LeftStickyContent'
import { EmptyState } from 'components/EmptyState/EmptyState'
import { NoSelectContent } from 'src/pages/settings/audio/AudioTable/LeftStickyContent/NoSelectContent'
import { noSortsColumns } from 'store/audioStore/audioColumns'
import { AppElements } from 'src/enums/appElements'
import { getAudioActions } from 'src/pages/settings/audio/functions/getAudioActions'

export const AudioTable = observer(({ fromModal }: { fromModal?: boolean }) => {
  const [fistLoading, setFirstLoading] = useState(true)
  const [refetchLoading, setRefetchLoading] = useState(false)
  const { data, loading, refetch, error } = useGetAllAudioItemsQuery({
    variables: {
      page: audioStore.tableStore.paginationInput,
      pattern: audioStore.tableStore.search,
    },
    skip: fromModal && !audioStore.openModal,
  })

  useEffect(() => {
    if (error) {
      setFirstLoading(false)
      setRefetchLoading(false)
    }
  }, [error])

  useEffect(() => {
    if (data) {
      setFirstLoading(false)
      audioStore.setAudioItemsData(data)
    }
  }, [data])

  useEffect(() => {
    setRefetchLoading(true)
    refetch().finally(() => setRefetchLoading(false))
  }, [audioStore.tableStore.refreshTrigger, audioStore.openModal])

  useEffect(() => {
    return () => {
      audioStore.tableStore.reset()
    }
  }, [])

  const rows = useMemo(() => {
    const tableData: IAudioItem[] = []
    data?.getAllAudioItems?.content?.forEach((audioFile) => {
      if (audioFile) {
        tableData.push({
          id: audioFile.id,
          ...audioFile,
        })
      }
    })
    return tableData.map((audioItem) => ({
      ...audioItem,
      actions: getAudioActions(audioItem),
    }))
  }, [data])

  if (!loading && !audioStore.audioFilesMap.size && !fromModal) {
    return (
      <EmptyState
        element={AppElements.Audio}
        description={'Use audio to send voice and ringless messages'}
        actions={<NoSelectContent />}
        heightV2
      />
    )
  }
  return (
    <>
      <NewTable<IAudioItem>
        tableStore={audioStore.tableStore}
        columns={audioStore.tableStore.visibleColumns.filter((column) =>
          fromModal ? true : column.id !== 'id'
        )}
        rows={rows}
        firstLoading={fistLoading}
        pageLoading={loading || refetchLoading}
        withSearch
        noSetting
        withCheckbox={!fromModal}
        maxHeight={fromModal ? 472 : undefined}
        withCalcMaxHeight={!fromModal}
        emptyContent={
          !rows.length ? (
            <div className={styles.empty}>
              <p className={'par2'}>You don’t have any added audios yet</p>
            </div>
          ) : undefined
        }
        leftStickyContent={<LeftStickyContent />}
        noSortsColumns={noSortsColumns}
      />
    </>
  )
})
