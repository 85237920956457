import { useState } from 'react'
import user from 'store/user/user'
import { SizedBox } from 'components/SizedBox'
import { Button } from 'components/Button/Button'
import { InputText } from 'components/Input/InputText/InputText'
import accountStore from 'store/settings/accountStore'
import { CreateNewPasswordModal } from 'src/pages/settings/account/routes/PrivacyAccount/password/CreateNewPasswordModal/CreateNewPasswordModal'
import { NewPasswordContent } from 'src/pages/settings/account/routes/PrivacyAccount/password/NewPasswordContent'
import { observer } from 'mobx-react-lite'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import {
  useRestorePasswordMutation,
  useVerifyRestorePasswordCodeMutation,
} from 'src/generated/graphql'

export const ExpandContent = observer(
  ({ onCollapse }: { onCollapse: () => void }) => {
    const [restorePassword, { loading }] = useRestorePasswordMutation()
    const [verifyPassword] = useVerifyRestorePasswordCodeMutation()
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [typePassword, setTypePassword] = useState('password')

    const onVerify = async (code: string) => {
      await verifyPassword({
        variables: {
          input: {
            verificationCode: +code,
          },
        },
      })
      accountStore.setOpenCreatePasswordModal(true)
      accountStore.setVerificationCode(+code)
      return
    }

    const onResend = async () => {
      return restorePassword()
    }

    const onForgot = async () => {
      try {
        const { data } = await restorePassword()
        if (data) {
          accountStore.setOpenVerifyNumberModal(true, {
            title: 'Verify your identity',
            info1: `We’ve sent it to ${user.email}`,
            info2: 'Didn’t receive the code?',
            size: 6,
            onVerify,
            resendRemainingSeconds:
              data.restorePassword?.resendRemainingSeconds,
            onResend,
          })
        }
      } catch (e) {
        console.error(e)
      }
    }

    return (
      <>
        {loading && <AbsoluteLoader size={40} />}
        <CreateNewPasswordModal />
        {user.isEmailAuth && (
          <>
            <SizedBox height={40} />
            <InputText
              password
              rightLabelContent={
                <Button onClick={onForgot}>
                  <span className={'s2 link'}>
                    <b>Forgot password?</b>
                  </span>
                </Button>
              }
              id={'password'}
              autoComplete={'new-password'}
              label={'Current password'}
              error={error}
              placeholder={'Enter your password'}
              value={password}
              type={typePassword}
              onChange={(e) => setPassword(e.target.value)}
              onClickRightIcon={() =>
                setTypePassword(
                  typePassword === 'password' ? 'text' : 'password'
                )
              }
              focusOnMount
            />
          </>
        )}
        <SizedBox height={user.isEmailAuth ? 24 : 40} />
        <NewPasswordContent
          onCancel={onCollapse}
          currentPassword={password}
          setCurrentPasswordError={setError}
        />
      </>
    )
  }
)
