import { WebFormsTable } from 'src/pages/main/webForms/WebformsTable/WebFormsTable'
import { Header } from 'shared/Header/Header'

export const WebForms = () => {
  return (
    <>
      <Header title={'Web Forms'} />
      <WebFormsTable />
    </>
  )
}
