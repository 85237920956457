import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  getContactActions,
  IContactActions,
} from 'src/pages/main/contacts/functions/getContactActions'
import { Actions } from 'components/Actions/Actions'
import contactStore from 'store/contacts/contactStore'

export const OneSelectContent = observer((props: IContactActions) => {
  const id = contactStore.tableStoreContacts.checkedRowsIds[0]
  const actions = getContactActions({
    row: contactStore.contactsMap.get(id),
    bulk: { ids: [id] },
    ...props,
  })

  return <Actions actions={actions} extraBtnSize={props.extraBtnSize} />
})
