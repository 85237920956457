import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import { InputText } from 'components/Input/InputText/InputText'
import { useSegmentDetailContext } from 'src/pages/main/contacts/segments/DetailSegment/Layout/SegmentDetailLayout'
import { SegmentDeleteBtn } from 'src/pages/main/contacts/segments/components/SegmentDeleteBtn/SegmentDeleteBtn'
import { useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'

export const HeaderContentSegment = observer(() => {
  const { name, setName, segment } = useSegmentDetailContext()

  const navigate = useNavigate()

  const onSuccessAction = () => {
    navigate(`${RoutesEnum.contacts}/${RoutesEnum.segments}`)
  }

  return (
    <div className={styles.row}>
      <InputText
        header2
        withoutBorder
        withoutClear
        value={name}
        onChangeValue={setName}
      />

      {segment && (
        <SegmentDeleteBtn
          medium={true}
          row={segment}
          onSuccessAction={onSuccessAction}
        />
      )}
    </div>
  )
})
