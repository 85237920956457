import { UnauthorizedRoutes } from 'src/pages/routes'
import { SizedBox } from 'components/SizedBox'
import { useNavigate } from 'react-router-dom'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { useState } from 'react'
import { InputText } from 'components/Input/InputText/InputText'
import { appLinks } from 'src/util/links'
import axios from 'axios'
import { FooterText } from 'src/pages/auth/components/FooterText/FooterText'

export const ForgotNewPassword = ({
  email,
  verificationCode,
}: {
  email: string
  verificationCode: number | string
}) => {
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [typePassword, setTypePassword] = useState('password')
  const [typeConfirmPassword, setTypeConfirmPassword] = useState('password')
  const [isValidPassword, setValidPassword] = useState(false)
  const navigate = useNavigate()
  const onUpdate = async () => {
    try {
      setLoading(true)
      await axios.post('auth/password/restore/confirm', {
        verificationCode,
        email,
        newPassword: password,
      })
      navigate(UnauthorizedRoutes.login)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }
  const isDisabled = !(
    password.trim() &&
    isValidPassword &&
    password.trim() === confirmPassword.trim()
  )
  return (
    <>
      <h2>Create new password</h2>
      <SizedBox height={8} />
      <p className={'par2 gray1 medium'}>
        Enter new password for {email} account.
      </p>
      <SizedBox height={32} />
      <InputText
        setValidPassword={setValidPassword}
        valid
        showValidItems
        password
        id={'password'}
        label={'New password'}
        placeholder={'Enter your password'}
        value={password}
        type={typePassword}
        onChange={(e) => setPassword(e.target.value)}
        onClickRightIcon={() =>
          setTypePassword(typePassword === 'password' ? 'text' : 'password')
        }
      />
      <SizedBox height={32} />
      <InputText
        password
        id={'password'}
        label={'Confirm password'}
        placeholder={'Confirm new password'}
        value={confirmPassword}
        type={typeConfirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        onClickRightIcon={() =>
          setTypeConfirmPassword(
            typeConfirmPassword === 'password' ? 'text' : 'password'
          )
        }
      />
      <SizedBox height={40} />
      <Button
        size={BtnSize.large}
        typeBtn={BtnType.primary}
        onClick={onUpdate}
        disabled={isDisabled}
        loading={loading}
      >
        Update password
      </Button>
      <SizedBox height={16} />
      <FooterText>
        Need help? You can always reach out to{' '}
        <a href={appLinks.callLoopSupport} target={'_blank'} rel="noreferrer">
          Call Loop Support Team.
        </a>
      </FooterText>
    </>
  )
}
