// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hesVh7FWgaW3tHL5ixHz{display:flex;justify-content:space-between;align-items:center;padding:64px 64px 32px 64px}.hesVh7FWgaW3tHL5ixHz.E2Dcoen8rMwtTaBqf7va{padding-top:30px}.hesVh7FWgaW3tHL5ixHz.bpsZZBuqnJgpSM8NV6qJ{padding-inline:0}@media(max-width: 800px){.hesVh7FWgaW3tHL5ixHz{padding:24px}}@media(max-height: 600px){.hesVh7FWgaW3tHL5ixHz{padding:24px}}._T4VvxfO4SxEXq5CV5Xu{display:flex;gap:12px}`, "",{"version":3,"sources":["webpack://./src/shared/Header/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,2BAAA,CACA,2CACE,gBAAA,CAEF,2CACE,gBAAA,CAEF,yBAXF,sBAYI,YAAA,CAAA,CAGF,0BAfF,sBAgBI,YAAA,CAAA,CAOJ,sBACE,YAAA,CACA,QAAA","sourcesContent":[".wrap{\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 64px 64px 32px 64px;\n  &.withBreadcrumbs{\n    padding-top: 30px;\n  }\n  &.inContainer{\n    padding-inline: 0;\n  }\n  @media (max-width: 800px) {\n    padding: 24px;\n\n  }\n  @media (max-height: 600px) {\n    padding: 24px;\n  }\n}\n\n.left{\n\n}\n.right{\n  display: flex;\n  gap: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `hesVh7FWgaW3tHL5ixHz`,
	"withBreadcrumbs": `E2Dcoen8rMwtTaBqf7va`,
	"inContainer": `bpsZZBuqnJgpSM8NV6qJ`,
	"right": `_T4VvxfO4SxEXq5CV5Xu`
};
export default ___CSS_LOADER_EXPORT___;
