import styles from '../styles.module.scss'
import { Alert } from 'components/Alert/Alert'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { CopyBtn } from 'components/Button/CopyBtn'
import { BtnSize } from 'components/Button/Button'
import { Typography } from 'shared/Typography'
import { conversionCodeScript } from 'widgets/conversions/ConversionCode/conversionCodeScript'

export const TrackingSnippet = () => {
  const code = `<!-- Start of Call Loop Embed Code -->
${conversionCodeScript}
<!-- End of Call Loop Embed Code -->`
  return (
    <>
      <div className={styles.content}>
        <Alert
          type={AlertTypeEnum.notify2}
          text={
            <>
              {`Copy and paste this tracking code into every page of your site, just before the </body> tag.`}{' '}
              <a
                href={'/#'}
                className={'s1'}
                target={'_blank'}
                rel="noreferrer"
              >
                Learn more
              </a>
            </>
          }
        />
        <div className={styles.code}>
          <Typography
            fontFamily={'Roboto_Mono'}
            variant={'p2-regular'}
            color={'--primary-color-gray-1'}
            tag={'pre'}
          >
            {code}
          </Typography>
        </div>
      </div>
      <div className={styles.actions}>
        <CopyBtn content={code} size={BtnSize.medium} textBtn={'Copy code'} />
      </div>
    </>
  )
}
