import { observer } from 'mobx-react-lite'
import { Asset } from 'src/generated/graphql'
import React, { useState } from 'react'
import mediaStore from 'store/mediaStore'
import { DragAndDropInput } from 'components/DragnDropInput/DragAndDropInput/DragAndDropInput'
import { SIZE_LIMIT_5_MB } from 'src/static/constants'
import { AttachmentGallery } from 'components/AttachmentGallery/AttachmentGallery'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'
import { FilesRejection } from 'src/pages/settings/compliance/registrations/components/OptInItem/FilesRejection'

export const OptInItem = observer(
  ({
    successCb,
    onRemove,
    label,
    attachImages,
    link,
  }: {
    successCb: (assets: Asset[]) => void
    onRemove: (ids: string[]) => void
    label: string
    attachImages: Asset[] | undefined
    link?: string
  }) => {
    const [filesRejections, setFilesRejections] = useState<File[]>([])

    const [loading, setLoading] = useState(false)

    const onAddFiles = async (files: File[]) => {
      setLoading(true)
      mediaStore
        .setUploadImages(files.slice(0, 3), (assets) => successCb(assets))
        .finally(() => setLoading(false))
    }
    const onFilesRejections = (files: File[]) => {
      setFilesRejections(files)
    }
    return (
      <div>
        <p className={'mb16 bold row4'}>
          {label}
          {link && (
            <InfoTooltip
              title={
                <a
                  href={link}
                  target={'_blank'}
                  className={'s1'}
                  rel="noreferrer"
                >
                  See examples
                </a>
              }
            />
          )}
        </p>
        {!!filesRejections.length && (
          <div className={'col8 mb16'}>
            {filesRejections.map((file) => (
              <FilesRejection
                key={file.lastModified}
                file={file}
                accepted={['jpg', 'png']}
              />
            ))}
          </div>
        )}

        <div className={'row8'}>
          <DragAndDropInput
            onAddFiles={(files) => onAddFiles(files)}
            accept={'image/jpeg, image/png'}
            mini
            fileLimitSize={SIZE_LIMIT_5_MB}
            onFilesRejections={onFilesRejections}
            inputLoading={loading}
          />
          <AttachmentGallery
            attachImages={attachImages}
            removeAttachImages={onRemove}
            medium
            // fromTemplate={fromTemplate}
            // fromDataCollectionItem={fromDataCollectionItem}
          />
        </div>
      </div>
    )
  }
)
