// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HbCyNgRoVFFc2mDQRDFL{padding:0 32px 32px 32px;width:650px}.otjCpGwFG0GAnbKMSif0{padding-top:24px;display:flex;flex-direction:column;gap:24px}.ZHA0hHdtwPaQqwSmCh_B{height:18px;padding-inline:4px;display:inline-flex;align-items:center;border-radius:4px;background:var(--primary-color-gray-2-light)}.K9oYDa4rM5LL91PPNNMZ{display:flex;padding:16px;align-items:flex-start;gap:10px;align-self:stretch;border-radius:12px;background:var(--primary-color-gray-4)}.K9oYDa4rM5LL91PPNNMZ pre{white-space:pre-wrap}.gShSIBm2f98jP3vpa4eW{margin-top:40px}`, "",{"version":3,"sources":["webpack://./src/widgets/conversions/ConversionCode/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,WAAA,CAEF,sBACE,gBAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,sBACE,WAAA,CACA,kBAAA,CACA,mBAAA,CACA,kBAAA,CACA,iBAAA,CACA,4CAAA,CAEF,sBACE,YAAA,CACA,YAAA,CACA,sBAAA,CACA,QAAA,CACA,kBAAA,CACA,kBAAA,CACA,sCAAA,CACA,0BACE,oBAAA,CAIJ,sBACE,eAAA","sourcesContent":[".wrap{\n  padding: 0 32px 32px 32px;\n  width: 650px;\n}\n.content{\n  padding-top: 24px;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.tag{\n  height: 18px;\n  padding-inline: 4px;\n  display: inline-flex;\n  align-items: center;\n  border-radius: 4px;\n  background: var(--primary-color-gray-2-light);\n}\n.code{\n  display: flex;\n  padding: 16px;\n  align-items: flex-start;\n  gap: 10px;\n  align-self: stretch;\n  border-radius: 12px;\n  background: var(--primary-color-gray-4);\n  pre{\n    white-space: pre-wrap;\n  }\n}\n\n.actions{\n  margin-top: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `HbCyNgRoVFFc2mDQRDFL`,
	"content": `otjCpGwFG0GAnbKMSif0`,
	"tag": `ZHA0hHdtwPaQqwSmCh_B`,
	"code": `K9oYDa4rM5LL91PPNNMZ`,
	"actions": `gShSIBm2f98jP3vpa4eW`
};
export default ___CSS_LOADER_EXPORT___;
