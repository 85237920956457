import { useMemo } from 'react'
import { CreateWebFormState } from 'src/pages/main/webForms/create/CreateWebformState'
import { EditWebFormContent } from 'src/pages/main/webForms/create/EditWebFormContent'

export const EditWebFormPage = () => {
  return useMemo(
    () => (
      <CreateWebFormState>
        <EditWebFormContent />
      </CreateWebFormState>
    ),
    []
  )
}
