// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kP7ZxND3FjGGXAwXTWDL{background:var(--primary-color-gray-4);border-bottom-left-radius:8px;border-bottom-right-radius:8px}`, "",{"version":3,"sources":["webpack://./src/widgets/AdvancedScheduling/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,sCAAA,CACA,6BAAA,CACA,8BAAA","sourcesContent":[".wrap{\n  background: var(--primary-color-gray-4);\n  border-bottom-left-radius: 8px;\n  border-bottom-right-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `kP7ZxND3FjGGXAwXTWDL`
};
export default ___CSS_LOADER_EXPORT___;
