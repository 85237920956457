import { ReactNode } from 'react'
import { Icon } from 'components/Icon/Icon'
import styles from './styles.module.scss'
import { useDropdownContext } from 'components/Dropdown/context/context'
import { Typography } from 'shared/Typography'
import classNames from 'classnames'

export type IItemId = number | string

export type IListItem<T = IItemId> = {
  id: T
  title: ReactNode
  isActive: boolean
  option?: HTMLOptionElement
}

type ListItemProps<T = IItemId> = {
  item: T
  onSelect: (id: T, item: IListItem<T>) => void
  withoutMark?: boolean
}

export const ListItem = ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  item,
  onSelect,
  withoutMark,
}: ListItemProps<typeof item.id>) => {
  const { title, isActive } = item
  const dropdownContext = useDropdownContext()
  const handleClick = () => {
    if (dropdownContext?.onClose) {
      dropdownContext.onClose()
    }
    onSelect(item.id, item)
  }
  return (
    <button
      onClick={handleClick}
      className={classNames(styles.wrap, { [styles.withoutMark]: withoutMark })}
    >
      <Typography className={styles.title}>{title}</Typography>
      {!withoutMark && isActive && (
        <Icon icon={'check'} color={'--primary-color-blue-1'} />
      )}
    </button>
  )
}
