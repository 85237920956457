// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cP8NKNuyGB39HG4s8VZJ{padding:0 32px 32px 32px;width:576px}._E6pEawC8KnlNDiVCKnx{display:flex;margin-top:40px;column-gap:12px}.mx8yT96N3sWZqOqKlPwm{display:flex;justify-content:space-between}.MxMY4dGbvP2PFH1JAjGU{width:100%}`, "",{"version":3,"sources":["webpack://./src/pages/settings/billing/modals/BuyCreditsModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,WAAA,CAEF,sBACE,YAAA,CACA,eAAA,CACA,eAAA,CAEF,sBACE,YAAA,CACA,6BAAA,CAEF,sBACE,UAAA","sourcesContent":[".wrap{\n  padding: 0 32px 32px 32px;\n  width: 576px;\n}\n.actions{\n  display: flex;\n  margin-top: 40px;\n  column-gap: 12px;\n}\n.row{\n  display: flex;\n  justify-content: space-between;\n}\n.dropdown{\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `cP8NKNuyGB39HG4s8VZJ`,
	"actions": `_E6pEawC8KnlNDiVCKnx`,
	"row": `mx8yT96N3sWZqOqKlPwm`,
	"dropdown": `MxMY4dGbvP2PFH1JAjGU`
};
export default ___CSS_LOADER_EXPORT___;
