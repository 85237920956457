import { createContext, useContext } from 'react'
import { AdvancedSchedulingStore } from 'src/widgets/AdvancedScheduling'

export const AdvancedSchedulingContext =
  createContext<AdvancedSchedulingStore | null>(null)

export const useAdvancedSchedulingContext = () => {
  const context = useContext(AdvancedSchedulingContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with AdvancedSchedulingContext'
    )

  return context
}
