import { capitalize } from 'lodash'
import { PaymentMethod } from 'src/generated/graphql'
import { CardIcon } from 'components/Input/InputText/CardIcon'
import { IOption } from 'src/types/IOption'
import { CardType } from 'src/enums/cardType'
import { Typography } from 'shared/Typography'

export const cardToOption = ({ id, card }: PaymentMethod): IOption => {
  return {
    title: (
      <div className={'row8'}>
        <CardIcon cardTypeProps={card?.brand as CardType} />
        {capitalize(card?.brand || '')} ... {card?.last4}
      </div>
    ),
    value: id,
    rightContent: (
      <Typography
        color={'--primary-color-gray-1'}
        variant={'s2-regular'}
        className={'nowrap'}
      >
        Exp {card?.expMonth}/{(card?.expYear || '').toString().substring(2, 4)}
      </Typography>
    ),
  }
}
