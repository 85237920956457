import dayjs from 'lib/dayjs'
import { DayOfWeek } from 'src/generated/graphql'

export enum EveryPeriodEnum {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
}

export const days: DayOfWeek[] = [
  DayOfWeek.Sunday,
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
]

// monday -> Mon
export const longShortDayMap: Map<DayOfWeek, string> = new Map(
  days.map((day, index) => [
    day,
    dayjs().startOf('week').add(index, 'day').format('ddd'),
  ])
)

// monday -> Mo
export const longShort2DayMap = new Map(
  days.map((day, index) => [
    day,
    dayjs().startOf('week').add(index, 'day').format('dd'),
  ])
)

export function getNumberWithOrdinal(n: number) {
  const s = ['th', 'st', 'nd', 'rd'],
    v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}
