// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dr5_25koxczDEQ9wfGM_{display:grid;grid-template-columns:max-content 1fr;border-top:1px solid var(--primary-color-gray-3)}.gEivQlXAol3vXiWsuld7{padding-top:40px;display:flex;justify-content:center;background:var(--gradient-1);border-bottom-right-radius:20px}`, "",{"version":3,"sources":["webpack://./src/pages/main/webForms/modals/NewWebFormModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qCAAA,CACA,gDAAA,CAEF,sBACE,gBAAA,CACA,YAAA,CACA,sBAAA,CACA,4BAAA,CACA,+BAAA","sourcesContent":[".content{\n  display: grid;\n  grid-template-columns: max-content 1fr;\n  border-top: 1px solid var(--primary-color-gray-3);\n}\n.preview{\n  padding-top: 40px;\n  display: flex;\n  justify-content: center;\n  background: var(--gradient-1);\n  border-bottom-right-radius: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `Dr5_25koxczDEQ9wfGM_`,
	"preview": `gEivQlXAol3vXiWsuld7`
};
export default ___CSS_LOADER_EXPORT___;
