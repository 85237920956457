import { makeAutoObservable } from 'mobx'
import { ActiveAdvancedScheduleStore } from 'src/widgets/AdvancedScheduling/ui/ActiveContent/store/ActiveAdvancedScheduleStore'
import {
  AdvancedSchedulingConfigFragment,
  AdvancedSchedulingConfigInput,
} from 'src/generated/graphql'

export class AdvancedSchedulingStore {
  constructor() {
    makeAutoObservable(this)
  }

  get advancedSchedulingConfigInput(): Omit<
    AdvancedSchedulingConfigInput,
    'durationDays' | 'durationMinutes' | 'durationHours'
  > | null {
    return (
      this.activeAdvancedScheduleStore?.advancedSchedulingConfigInput || null
    )
  }

  activeAdvancedScheduleStore: ActiveAdvancedScheduleStore | null = null

  active = false
  setActive = (active: typeof this.active) => {
    if (active) {
      this.activeAdvancedScheduleStore = new ActiveAdvancedScheduleStore()
    } else {
      this.activeAdvancedScheduleStore = null
    }
    this.active = active
  }
  toggleActive = () => {
    this.setActive(!this.active)
  }

  get advancedInfo() {
    if (!this.active && !this.activeAdvancedScheduleStore) {
      return null
    }
    return {
      sendOnlyStr: this.activeAdvancedScheduleStore?.sendOnlyStr,
      sendAtStr: this.activeAdvancedScheduleStore?.sendAtStr,
      sendToContactsStr: this.activeAdvancedScheduleStore?.sendToContactsStr,
    }
  }

  setAdvancedSchedulingConfig = (config: AdvancedSchedulingConfigFragment) => {
    this.active = true
    this.activeAdvancedScheduleStore = new ActiveAdvancedScheduleStore(config)
  }
}
