import { Filters } from './Filters/Filters'
import NewTable from 'components/NewTable/NewTable'
import styles from './styles.module.scss'
import { useEffect, useState } from 'react'
import { Direction, useGetSkippedContactsQuery } from 'src/generated/graphql'
import { observer } from 'mobx-react-lite'
import { TableStore } from 'components/NewTable/store/TableStore'
import { columns } from './columns'
import { IUnvalidatedContact } from 'src/pages/main/contacts/importHistory/FailedImportHistory/FailedImportHistoryTable/types'
import { useParams } from 'react-router-dom'
import importContactStore from 'store/contacts/importContactStore'

export const FailedImportHistoryTable = observer(() => {
  const { id } = useParams()
  const [tableStore] = useState(
    new TableStore<IUnvalidatedContact>({
      tableName: 'Skipped contacts',
      columns,
      orderBy: 'name',
      order: Direction.Asc,
    })
  )
  const [rows, setRows] = useState<IUnvalidatedContact[]>([])
  const [fistLoading, setFirstLoading] = useState(true)
  const { data, refetch, loading, error } = useGetSkippedContactsQuery({
    variables: { page: tableStore.paginationInput, importStateId: id },
  })

  useEffect(() => {
    if (error) {
      setFirstLoading(false)
    }
  }, [error])

  useEffect(() => {
    if (data?.getSkippedContacts) {
      const iUnvalidatedContacts: IUnvalidatedContact[] = []
      data?.getSkippedContacts.content?.forEach((contact) => {
        if (contact) {
          iUnvalidatedContacts.push({ ...contact, id: contact.id })
        }
      })
      setRows(iUnvalidatedContacts)
      setFirstLoading(false)
      if (data.getSkippedContacts.importState) {
        importContactStore.setCurrentHistoryState({
          ...data.getSkippedContacts.importState,
          id: data.getSkippedContacts.importState.id,
        })
      }

      tableStore.setTotal(data.getSkippedContacts.total)
    }
  }, [data])

  useEffect(() => {
    refetch().catch((e) => {
      console.error(e)
    })
  }, [tableStore.refreshTrigger])

  return (
    <div className={styles.wrap}>
      <NewTable<IUnvalidatedContact>
        withCheckbox
        columns={tableStore.visibleColumns}
        rows={rows}
        leftStickyContent={<Filters tableStore={tableStore} />}
        withSearch
        withCalcMaxHeight
        emptyContent={!rows.length ? <></> : undefined}
        tableStore={tableStore}
        firstLoading={fistLoading}
        pageLoading={loading}
      />
    </div>
  )
})
