import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import numbersStore from 'store/settings/numbers/numbersStore'
import { useSetFullBusinessProfile } from 'src/pages/settings/compliance/BusinessProfile/hooks/useSetFullBusinessProfile'
import { UnVerificationTollFreeContent } from 'src/pages/settings/compliance/modals/UnverifiedNumberModal/content/UnVerificationTollFreeContent'
import { RoutesEnum } from 'src/pages/routes'
import uiStore from 'store/uiStore'
import { useNavigate } from 'react-router-dom'
import { useMemo } from 'react'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'
import { ResumeTollFreeContent } from 'src/pages/settings/compliance/modals/UnverifiedNumberModal/content/ResumeTollFreeContent'
import { useSetFullTollFreeProfile } from 'src/pages/settings/compliance/TollFreeVerification/hooks/useSetFullTollFreeProfile'

export const UnverifiedNumberModal = observer(() => {
  const { profile } = tollFreeVerificationStore
  const { loading: loadingBusinessProfile } = useSetFullBusinessProfile(
    !numbersStore.openUnverifiedNumberModal
  )
  const { loading: loadingTollFreeProfile } = useSetFullTollFreeProfile(
    !numbersStore.openUnverifiedNumberModal
  )
  const navigate = useNavigate()
  const onClose = () => {
    numbersStore.setOpenUnverifiedNumberModal(false)
  }

  const onAction = (to: string) => {
    onClose()
    if (uiStore.isBeforeUnload) {
      uiStore.setOpenCancelCreateModal(true)
      uiStore.setNextRoute(to)
    } else {
      uiStore.setNextRoute('')
      navigate(to)
    }
  }
  const onVerify = () => {
    const to =
      RoutesEnum.settingsCompliance + '/' + RoutesEnum.tollFreeVerification
    onAction(to)
  }

  const content = useMemo(() => {
    if (profile) {
      return <ResumeTollFreeContent onClose={onClose} onAction={onVerify} />
    }
    return (
      <UnVerificationTollFreeContent onClose={onClose} onAction={onVerify} />
    )
  }, [profile])
  return (
    <Modal
      open={numbersStore.openUnverifiedNumberModal}
      onClose={onClose}
      loading={loadingBusinessProfile || loadingTollFreeProfile}
      withoutHeader={!profile}
      minWidth={600}
      title={'Compliance'}
    >
      {content}
    </Modal>
  )
})
