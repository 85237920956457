import { createContext, useContext } from 'react'
import { TrackConversionStore } from 'widgets/TrackConversion/store/trackConversionStore'

export const TrackConversionContext =
  createContext<TrackConversionStore | null>(null)

export const useTrackConversionContext = () => {
  const context = useContext(TrackConversionContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with TrackConversionContext'
    )

  return context
}
