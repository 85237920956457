import { ReactNode } from 'react'
import { useDropdownContext } from 'components/Dropdown/context/context'
import {
  DropdownButton,
  IDropdownButtonProps,
} from 'shared/DropdownButton/DropdownButton'

export type IListItem<T = any, ID = any> = {
  id: ID
  text: ReactNode
  isActive: boolean
  option?: HTMLOptionElement
  item?: T
  dropdownButtonProps?: Omit<IDropdownButtonProps, 'onClick' | 'text'>
}

type ListItemProps<T = any, ID = any> = {
  item: IListItem<T>
  onSelect: (id: ID, item: IListItem<T>) => void
  withoutMark?: boolean
}

export const ListItem = ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  item,
  onSelect,
  withoutMark,
}: ListItemProps<any, typeof item.id>) => {
  const { text, isActive, dropdownButtonProps } = item
  const dropdownContext = useDropdownContext()
  const handleClick = () => {
    if (dropdownContext?.onClose) {
      dropdownContext.onClose()
    }
    onSelect(item.id, item)
  }
  return (
    <DropdownButton
      text={text}
      rightIcon={!withoutMark && isActive && 'check'}
      rightIconProps={
        !withoutMark &&
        isActive && {
          color: '--primary-color-blue-1',
        }
      }
      onClick={handleClick}
      {...dropdownButtonProps}
    />
  )
}
