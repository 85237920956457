import styles from './styles.module.scss'
import {
  DetailCard,
  ICardDetail,
} from 'components/DetailCard/DetailCard/DetailCard'
import { TableStore } from 'components/NewTable/store/TableStore'
import { RequiredFields } from 'components/NewTable/types'
import { observer } from 'mobx-react-lite'

export const DetailCardsWrapper = observer(
  <T extends RequiredFields<T>>({
    cards,
    tableStore,
    noSelect,
  }: {
    cards: ICardDetail[]
    tableStore?: TableStore<T>
    noSelect?: boolean
    noActive?: boolean
  }) => {
    const handleSelectCard = (card: ICardDetail) => {
      !noSelect &&
        card.status &&
        tableStore &&
        tableStore.setStatus(card.status)
    }

    return (
      <div className={styles.wrap}>
        {cards.map((card) => (
          <DetailCard
            key={card.value}
            card={card}
            checked={!!tableStore && card.status === tableStore?.status}
            noSelect={noSelect}
            handleSelectCard={() => handleSelectCard(card)}
          />
        ))}
      </div>
    )
  }
)
