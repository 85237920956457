import { FC } from 'react'
import { ActionBtnProps } from 'components/NewTable/types'
import { IAudioItem } from 'src/types/IAudioItem'
import { getActionsByType } from 'src/pages/settings/audio/functions/getActionsByType'
import { audioBtnByAction } from 'src/pages/settings/audio/functions/audioBtnByAction'

export const getAudioActions = (
  audioItem: IAudioItem
): Array<FC<ActionBtnProps<IAudioItem>>> => {
  const actions = getActionsByType(audioItem)

  const actionsFC: Array<FC<ActionBtnProps<IAudioItem>>> = []

  actions.forEach((action) => {
    const btn: FC<ActionBtnProps<IAudioItem>> | undefined =
      audioBtnByAction[action as keyof typeof audioBtnByAction]
    if (btn) {
      actionsFC.push((props) => btn({ ...props, row: audioItem }))
    }
  })
  return actionsFC
}
