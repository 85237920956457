import { ActionBtnProps } from 'components/NewTable/types'
import * as React from 'react'
import { useState } from 'react'
import { ITrigger } from 'src/types/ITrigger'
import { useTriggerFunctions } from 'src/pages/main/triggers/hooks/useTriggerFunctions'
import { Status } from 'src/generated/graphql'
import { Switch } from 'components/Switch/Switch'
import { ActionsEnum } from 'components/Button/types'
import { TableBtn } from 'components/Button/TableBtn'
import { IIconType } from 'components/AppIcon/AppIcon'
import uiStore from 'store/uiStore'

export const TriggerSwitchBtn = (props: ActionBtnProps<ITrigger>) => {
  const [loading, setLoading] = useState(false)

  const { row, rows, medium, fromLeftStickyContent } = props
  const { onSwitch } = useTriggerFunctions(row, rows)

  const handleSwitch = async () => {
    try {
      setLoading(true)
      await onSwitch()
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }
  const active = row?.status === Status.Active
  const switchComponent = (
    <Switch
      active={active}
      onChange={handleSwitch}
      marginInline={8}
      disabled={loading}
    />
  )
  if (fromLeftStickyContent || uiStore.isMobile) {
    return (
      <TableBtn
        {...props}
        action={active ? ActionsEnum.turnOff : ActionsEnum.turnOn}
        onAction={handleSwitch}
        iconType={active ? IIconType.circle : IIconType.fill}
        disabled={loading}
      />
    )
  }
  if (medium) {
    return (
      <div className={'flex'}>
        <span>{active ? 'Active' : 'Disabled'}</span>
        {switchComponent}
      </div>
    )
  }
  return switchComponent
}
