import { BtnSize, BtnType, Button } from 'components/Button/Button'
import React, { FC, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import contactStore from 'store/contacts/contactStore'
import { SearchInput } from 'components/Input/InputText/SearchInput'
import customFieldsStore from 'store/contacts/customFields/customFieldsStore'
import { AddCustomField } from 'src/routes/main/contacts/customFields/modals/CustomFieldsModal/AddCustomField'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { RoutesEnum } from 'src/routes/routes'
import mainStore from 'store/mainStore'
import { useLocation } from 'react-router-dom'
import { AccountIntegrationType } from 'src/generated/graphql'
import triggersStore from 'store/triggers/triggersStore'
import { getAccountIntegrationTypeIIcon } from 'src/routes/main/triggers/create/components/getAccountIntegrationTypeIcon'
import { AddMergeToken } from 'src/routes/main/contacts/customFields/modals/MergeTokenModal/AddMergeToken'
import { Icon } from 'components/Icon/Icon'

type Props = {
  onAddPersonalize: (name: string, key: string) => void
  fromMore?: boolean
}

// const appIcon

export const PersonalizeContent: FC<Props> = observer(
  ({ onAddPersonalize, fromMore }) => {
    const { pathname } = useLocation()
    const [search, setSearch] = useState('')

    const selectTriggerApp = useMemo(
      () =>
        (pathname === RoutesEnum.newTrigger && mainStore.selectTriggerApp) ||
        (pathname.includes('triggers') && mainStore.selectTriggerApp),
      [mainStore.selectTriggerApp, pathname]
    )

    const content = useMemo(() => {
      if (
        !selectTriggerApp ||
        selectTriggerApp === AccountIntegrationType.Custom
      ) {
        return (
          <>
            <div>
              <div className={styles.block}>
                <span className={'p8 s2 medium gray1'}>Default fields</span>
                {contactStore.contactFieldsOptions
                  .filter(({ title }) =>
                    String(title)
                      .toLocaleLowerCase()
                      .includes(search.toLocaleLowerCase())
                  )
                  .map(({ title, value }) => (
                    <Button
                      key={value}
                      size={BtnSize.small}
                      typeBtn={BtnType.secondaryGray}
                      menuBtn
                      spaceBetween
                      onClick={() => onAddPersonalize(String(title), value)}
                    >
                      {title}
                      <Icon icon={'plus'} color={'--primary-color-gray-1'} />
                    </Button>
                  ))}
              </div>

              <div className={styles.block2}>
                <span className={'p8 s2 medium gray1'}>Custom fields</span>
                {customFieldsStore.customFields
                  .filter(
                    (field) =>
                      field.name &&
                      field.name
                        .toLocaleLowerCase()
                        .includes(search.toLocaleLowerCase())
                  )
                  .map(({ key, name }) => (
                    <Button
                      key={key}
                      size={BtnSize.small}
                      typeBtn={BtnType.secondaryGray}
                      menuBtn
                      spaceBetween
                      onClick={() => onAddPersonalize(String(name), key || '')}
                    >
                      {name}
                      <Icon icon={'plus'} color={'--primary-color-gray-1'} />
                    </Button>
                  ))}
              </div>
            </div>
          </>
        )
      } else {
        const icon = getAccountIntegrationTypeIIcon(selectTriggerApp)
        return (
          <>
            {triggersStore.integrationCustomFieldsMap
              .get(selectTriggerApp)
              ?.filter((field) =>
                field.name
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase())
              )
              .map(({ key, name }) => (
                <Button
                  key={key}
                  size={BtnSize.small}
                  typeBtn={BtnType.secondaryGray}
                  menuBtn
                  spaceBetween
                  onClick={() => onAddPersonalize(String(name), key || '')}
                >
                  <div className={'row8 text-left'}>
                    <div className={'box24'}>
                      <Icon icon={icon} fontSize={'unset'} />
                    </div>
                    {name}
                  </div>
                  <Icon icon={'plus'} color={'--primary-color-gray-1'} />
                </Button>
              ))}
          </>
        )
      }
    }, [
      customFieldsStore.customFields,
      selectTriggerApp,
      triggersStore.integrationCustomFieldsMap,
      search,
    ])

    return (
      <div className={classNames(fromMore && styles.fromMore)}>
        <div className={styles.search}>
          <SearchInput value={search} onSearch={setSearch} small />
        </div>

        <div className={styles.wrapScrollableContent}>{content}</div>
        {(!selectTriggerApp ||
          selectTriggerApp === AccountIntegrationType.Custom) && (
          <div className={styles.wrapActions}>
            <AddCustomField />
            {selectTriggerApp === AccountIntegrationType.Custom && (
              <AddMergeToken onAddPersonalize={onAddPersonalize} />
            )}
          </div>
        )}
      </div>
    )
  }
)
