import { useEffect } from 'react'
import { useGetConversionStatisticsQuery } from 'src/generated/graphql'
import { observer } from 'mobx-react-lite'
import { DetailCardsWrapper } from 'components/DetailCard/DetailCardsWrapper'
import { detailsCards } from 'components/DetailCard/variants'
import { Currencies, numberString } from 'src/util/functions'
import { useConversionDetailContext } from 'widgets/conversions/ConversionDetail/context/context'

export const AdditionalContent = observer(() => {
  const { tableStore, conversionId } = useConversionDetailContext()

  const { data, refetch } = useGetConversionStatisticsQuery({
    variables: {
      conversionId,
      dateRange: tableStore.rangeDatePickerStore.dateRange,
    },
    skip: tableStore.rangeDatePickerStore.isSkipByDateRange,
  })

  useEffect(() => {
    if (tableStore.refreshTrigger) {
      refetch().catch(console.error)
    }
  }, [tableStore.refreshTrigger])

  return (
    <DetailCardsWrapper
      cards={[
        detailsCards.custom({
          title: 'Converted',
          count: `${data?.getConversionStatistics?.total || 0}`,
          hidePercent: true,
        }),
        detailsCards.custom({
          title: 'Revenue',
          count: numberString({
            val: data?.getConversionStatistics?.revenue || 0,
            currency: Currencies.usd,
          }),
          hidePercent: true,
        }),
        detailsCards.custom({
          title: 'Cost',
          count: numberString({
            val: data?.getConversionStatistics?.totalCosts || 0,
            currency: Currencies.usd,
          }),
          hidePercent: true,
        }),
        detailsCards.custom({
          title: 'ROI',
          count: `${numberString({
            val: data?.getConversionStatistics?.roi || 0,
          })}%`,
          hidePercent: true,
        }),
      ]}
      noSelect
    />
  )
})
