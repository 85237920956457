import { BtnSize, Button } from 'components/Button/Button'
import { ReactComponent as Plus } from 'icons/16pxNoMask/Plus.svg'
import { FC } from 'react'
import { IBottomDropdownButtonProps } from 'components/Dropdown/types'
import mergeTokenStore from 'src/routes/main/contacts/customFields/modals/MergeTokenModal/mergeTokenStore'
import { NewLabel } from 'src/shared/NewLabel/NewLabel'

type AddMergeTokenProps = {
  onAddPersonalize: (name: string, key: string) => void
}

export const AddMergeToken: FC<
  IBottomDropdownButtonProps & AddMergeTokenProps
> = ({ onClose, onAddPersonalize }) => (
  <Button
    size={BtnSize.small}
    menuBtn
    hoverGray
    onClick={() => {
      onClose && onClose()
      mergeTokenStore.setOpenModal(true, onAddPersonalize)
    }}
  >
    <Plus />
    <span className={'bold blue1'}>Add merge token</span>
    <NewLabel />
  </Button>
)
