// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xdwFBtxMujmCUB5IYOzG{background:var(--primary-color-gray-4);border-radius:16px;padding:16px 20px;display:grid;row-gap:8px}`, "",{"version":3,"sources":["webpack://./src/pages/settings/compliance/registrations/A2P10DLCRegistration/steps/Step7/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,sCAAA,CACA,kBAAA,CACA,iBAAA,CACA,YAAA,CACA,WAAA","sourcesContent":[".summary{\n  background: var(--primary-color-gray-4);\n  border-radius: 16px;\n  padding: 16px 20px;\n  display: grid;\n  row-gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"summary": `xdwFBtxMujmCUB5IYOzG`
};
export default ___CSS_LOADER_EXPORT___;
