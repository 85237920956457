import styles from './styles.module.scss'
import { useNavigate } from 'react-router-dom'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as Angle } from 'icons/16px/Angle-Left.svg'
import { ReactComponent as Star } from 'icons/16pxNoMask/Star.svg'
import { observer } from 'mobx-react-lite'
import { DetailsBtn } from 'src/pages/main/webForms/detail/header/DetailsBtn'
import { ReactComponent as Code } from 'icons/16pxNoMask/Code.svg'
import { ClassesEnum } from 'styles/classes'
import { useWebFormFunctions } from 'src/pages/main/webForms/hooks/useWebFormFunctions'
import { MoreBtnWebForm } from 'src/pages/main/webForms/detail/header/MoreBtnWebForm'
import { useWebFormDetailContext } from 'src/pages/main/webForms/detail/webFormDetailPage'
import { BreadCrumbs } from 'components/BreadCrumbs/BreadCrumbs'
import * as React from 'react'

export const HeaderContent = observer(() => {
  const navigate = useNavigate()
  const { webForm, totalCharged } = useWebFormDetailContext()
  const { onGetCodeWebForm } = useWebFormFunctions(webForm)
  return (
    <div className={styles.header}>
      <BreadCrumbs />
      <div className={styles.nav}>
        <div className={styles.left}>
          <div>
            <Button hoverGray size={BtnSize.small} onClick={() => navigate(-1)}>
              <Angle />
            </Button>
          </div>
          <h1>{webForm?.name}</h1>
        </div>
        <div className={styles.right}>
          <DetailsBtn />
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.secondaryGray}
            className={styles.btn}
            onClick={onGetCodeWebForm}
          >
            <Code />
            <span className={ClassesEnum.bold}>Get Code</span>
          </Button>
          <MoreBtnWebForm />
        </div>
      </div>
      <div className={styles.rowInfo}>
        <div className={styles.creditCount}>
          <Star />
          <span>{totalCharged} credits</span>
        </div>
      </div>
    </div>
  )
})
