import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { ClassesEnum } from 'styles/classes'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import styles from './styles.module.scss'
import { ReactComponent as More } from 'icons/16px/More.svg'
import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { MenuBtn, MenuEnum } from 'components/Button/MenuBtn'
import { useKeywordFunctions } from 'src/pages/main/keywords/hooks/useKeywordFunctions'
import { useKeywordDetailContext } from 'src/pages/main/keywords/detail/keywordDetailPage'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'

export const MoreBtnKeyword = observer(() => {
  const { keyword } = useKeywordDetailContext()
  const { showTT, setShowTT, trigger, setTrigger, onTriggerClick } =
    useFixedTooltip()
  const { onDuplicate, onDelete } = useKeywordFunctions({ keyword })
  const onBtnClick = () => {
    setShowTT(false)
  }

  const actions = useMemo(() => {
    if (!keyword) return <></>

    return (
      <>
        <MenuBtn
          type={MenuEnum.duplicate}
          onClick={() => {
            onBtnClick()
            onDuplicate()
          }}
        />
        {/*<MenuBtn*/}
        {/*  type={MenuEnum.analytics}*/}
        {/*  onClick={() => {*/}
        {/*    onBtnClick()*/}
        {/*  }}*/}
        {/*/>*/}
        <MenuBtn
          type={MenuEnum.delete}
          onClick={() => {
            onBtnClick()
            onDelete([keyword.id])
          }}
        />
      </>
    )
  }, [keyword])
  return (
    <>
      <FixedTooltip
        visible={showTT}
        trigger={trigger}
        width={148}
        white
        noArrow
        position={TTPositionEnum.bottomRight}
        globalClasses={[ClassesEnum.p8]}
        addLeftPosition={8}
      >
        {actions}
      </FixedTooltip>
      <div ref={setTrigger}>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.secondaryGray}
          className={styles.btn}
          onClick={onTriggerClick}
        >
          <More />
        </Button>
      </div>
    </>
  )
})
