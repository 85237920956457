import { SizedBox } from 'components/SizedBox'
import { Alert } from 'components/Alert/Alert'
import { ReactComponent as UnitedStatesOfAmerica } from 'icons/20px/NoMask/United-states-of-america.svg'
import { ReactComponent as Canada } from 'icons/20px/NoMask/Canada.svg'
import { observer } from 'mobx-react-lite'
import businessProfileStore from 'store/settings/company/businessProfileStore'
import { CardButton } from 'components/Button/CardButton/CardButton'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import {
  BusinessProfileResponse,
  CountryCode,
  useAdminRegisterBusinessProfileMutation,
  useRegisterBusinessProfileMutation,
} from 'src/generated/graphql'
import { PropsBusinessStep } from 'src/pages/settings/compliance/BusinessProfile/types'

export const CountryOfOperationsContent = observer(
  ({ onCancel, loadingPrevious, onSuccess }: PropsBusinessStep) => {
    const { adminOrgId } = businessProfileStore
    const [registerBusinessProfile, { loading }] =
      useRegisterBusinessProfileMutation()
    const [adminRegisterBusinessProfile, { loading: loadingAdmin }] =
      useAdminRegisterBusinessProfileMutation()
    const onContinue = async () => {
      try {
        let profile: BusinessProfileResponse | undefined | null
        if (adminOrgId) {
          const { data } = await adminRegisterBusinessProfile({
            variables: {
              input: {
                operationCountries: businessProfileStore.operationCountries,
              },
              organizationId: adminOrgId,
            },
          })
          profile = data?.adminRegisterBusinessProfile
        } else {
          const { data } = await registerBusinessProfile({
            variables: {
              input: {
                operationCountries: businessProfileStore.operationCountries,
              },
            },
          })
          profile = data?.registerBusinessProfile
        }
        if (profile) {
          onSuccess(profile)
        }
      } catch (e) {
        console.error(e)
      }
    }
    return (
      <>
        <Alert text={'Which country do you want to send messages to?'} />
        <SizedBox height={40} />
        <div className={'row16'}>
          <CardButton
            onClick={() =>
              businessProfileStore.setOperationCountries(CountryCode.Us)
            }
            description={'United States'}
            icon={<UnitedStatesOfAmerica />}
            active={businessProfileStore.isUSOperation}
            border
          />
          <CardButton
            onClick={() =>
              businessProfileStore.setOperationCountries(CountryCode.Ca)
            }
            description={'Canada'}
            icon={<Canada />}
            active={businessProfileStore.isCanadaOperation}
            border
          />
        </div>
        <SizedBox height={40} />
        <div className={'row12'}>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.primary}
            onClick={onContinue}
            disabled={businessProfileStore.disabledOperations}
            loading={loading || loadingAdmin}
          >
            {businessProfileStore.continueText}
          </Button>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.secondaryGray}
            onClick={onCancel}
            loading={loadingPrevious}
          >
            {businessProfileStore.step === 1 ? 'Cancel' : 'Back'}
          </Button>
        </div>
      </>
    )
  }
)
