// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kKx_iMvNSyK2TIztsgrd{display:grid;grid-template-columns:1fr 1fr;column-gap:12px}.wea9tRdy7ELlTnSU6NLf{margin-top:6px;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/pages/auth/invite/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,eAAA,CAEF,sBACE,cAAA,CACA,cAAA","sourcesContent":[".inputWrap{\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  column-gap: 12px;\n}\n.errorLink{\n  margin-top: 6px;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrap": `kKx_iMvNSyK2TIztsgrd`,
	"errorLink": `wea9tRdy7ELlTnSU6NLf`
};
export default ___CSS_LOADER_EXPORT___;
