import { makeAutoObservable } from 'mobx'
import { days, longShort2DayMap } from 'components/Schedule/store/types'
import { getSendTextByDays } from 'components/Schedule/utils/getSendTextByDays'
import { DayOfWeek } from 'src/generated/graphql'

export type IWeekDaysSelectorStoreProps = {
  days?: Array<DayOfWeek | null> | null
}

export class WeekDaysSelectorStore {
  constructor(props?: IWeekDaysSelectorStoreProps) {
    if (props) {
      this.init(props)
    }
    makeAutoObservable(this)
  }

  daysMap: Map<DayOfWeek, boolean> = new Map(
    days.map((day, index) => [day, index !== 0 && index !== 6])
  )
  get props(): IWeekDaysSelectorStoreProps {
    return {
      days: this.selectedDays,
    }
  }
  get daysItems(): { id: DayOfWeek; label: string; activeValue: boolean }[] {
    return Array.from(this.daysMap.entries()).map(([key, value]) => ({
      id: key,
      label: longShort2DayMap.get(key) as string,
      activeValue: value,
    }))
  }

  get selectedDaysItems() {
    return this.daysItems.filter((item) => item.activeValue)
  }

  get selectedDays() {
    return this.selectedDaysItems.map((item) => item.id as DayOfWeek)
  }

  get text() {
    return getSendTextByDays(this.selectedDays)
  }

  onToggleDay = (day: DayOfWeek) => {
    const prevValue = this.daysMap.get(day)
    if (prevValue && this.selectedDays.length === 1) return
    this.daysMap.set(day, !prevValue)
  }

  init = ({ days }: IWeekDaysSelectorStoreProps) => {
    if (days?.length) {
      const dayOfWeeks: DayOfWeek[] = []
      days?.forEach((day) => {
        if (day) {
          dayOfWeeks.push(day)
        }
      })
      this.daysMap.forEach((_, key) => {
        this.daysMap.set(key, dayOfWeeks.includes(key))
      })
    }
  }
}
