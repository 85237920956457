import { observer } from 'mobx-react-lite'
import styles from 'components/Schedule/styles.module.scss'
import { ScheduleBtn } from 'components/Schedule/ScheduleBtn'
import { ScheduleProps } from 'components/Schedule/Schedule'
import { FC } from 'react'

export const LaterCard: FC<ScheduleProps> = observer(
  ({ onActive, isActive, content }) => {
    return (
      <div className={styles.row}>
        <ScheduleBtn
          active={isActive}
          title={'Send Later'}
          onClick={onActive}
        />
        {content}
      </div>
    )
  }
)
