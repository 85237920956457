import { makeAutoObservable } from 'mobx'
import {
  RinglessMessageDetailsFragment,
  RinglessMessageInput,
} from 'src/generated/graphql'
import { IAudioItem } from 'src/types/IAudioItem'

export class RinglessStore {
  constructor() {
    makeAutoObservable(this)
  }

  callbackEnabled = false
  setCallbackEnabled = (value: boolean) => {
    this.callbackEnabled = value
  }

  ringlessVoicemailAudio: IAudioItem | null = null

  get disabledCreate() {
    return !this.ringlessVoicemailAudio
  }

  get ringlessMessageInput(): RinglessMessageInput {
    return {
      callbackEnabled: this.callbackEnabled,
      audioId: this.ringlessVoicemailAudio?.id || null,
    }
  }

  get ringlessMessageInit(): RinglessMessageDetailsFragment {
    return {
      callbackEnabled: this.callbackEnabled,
      voiceMail: this.ringlessVoicemailAudio,
    }
  }

  setInit = (ringlessMessage: RinglessMessageDetailsFragment) => {
    this.ringlessVoicemailAudio = ringlessMessage.voiceMail as IAudioItem
    this.callbackEnabled = ringlessMessage.callbackEnabled || false
  }

  setRinglessVoicemailAudio = (audio: IAudioItem | null) => {
    this.ringlessVoicemailAudio = audio
  }
}
