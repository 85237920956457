import { SizedBox } from 'components/SizedBox'
import { AppGoogleLogin } from 'components/GoogleLogin/AppGoogleLogin'
import { GoogleCard } from 'src/pages/settings/account/routes/PrivacyAccount/GoogleCard/GoogleCard'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import auth from 'store/auth'

export const GoogleAuthContent = observer(() => {
  const [autoLoad, setAutoLoad] = useState(false)
  return (
    <>
      <p className={'mb8'}>
        <b>Google authentication</b>
      </p>
      <span className={'s2 gray1'}>
        Connect your Google account to log into Call Loop
      </span>
      <SizedBox height={32} />
      {auth.withGoogle ? (
        <GoogleCard setAutoLoad={setAutoLoad} />
      ) : (
        <MuiTooltip title={'Coming soon'} placement={'top'} arrow>
          <div>
            <AppGoogleLogin select_account autoLoad={autoLoad} disabled />
          </div>
        </MuiTooltip>
      )}
    </>
  )
})
