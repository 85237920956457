import { observer } from 'mobx-react-lite'
import { CheckBoxBtn } from 'components/CheckBoxBtn/CheckBoxBtn'
import { useSendingSpeedContext } from 'src/widgets/SendingSpeed'
import { ActiveContent } from 'src/widgets/SendingSpeed/ui/ActiveContent/ActiveContent'

export const SendingSpeedContent = observer(() => {
  const { active, toggleActive, activeContentStore } = useSendingSpeedContext()
  return (
    <>
      <div className={'p8'}>
        <CheckBoxBtn
          active={active}
          toggleActive={toggleActive}
          title={'Sending speed'}
          isNew
        />
      </div>
      {activeContentStore && <ActiveContent store={activeContentStore} />}
    </>
  )
})
