import styles from './styles.module.scss'
import { FC } from 'react'
import { ILesson } from 'store/dashboard/objectives/types'
import classNames from 'classnames'
import { StepInfo } from 'src/pages/main/dashboard/Objectives/ActiveObjective/StepInfo/StepInfo'
import { SizedBox } from 'components/SizedBox'
import { ReactComponent as Check } from 'icons/20px/Check.svg'

interface ILessonProps extends ILesson {
  active: boolean
  index: number
  countLessons: number
  onClickLesson: () => void
}

export const LessonCard: FC<ILessonProps> = ({
  title,
  index,
  active,
  durationMin,
  countLessons,
  complete,
  onClickLesson,
}) => {
  return (
    <button
      className={classNames(styles.wrap, active && styles.active)}
      onClick={onClickLesson}
    >
      <div className={styles.wrapContent}>
        <div className={styles.content}>
          <div
            className={classNames(styles.number, active && styles.activeNumber)}
          >
            0{index + 1}
          </div>
          <div className={styles.row}>
            <div>
              <span>
                <span className={'bold'}>Lesson {index + 1}:</span> {title}
              </span>
              <SizedBox height={8} />
              <StepInfo
                durationMin={durationMin}
                current={index + 1}
                count={countLessons}
                withoutLessons
              />
            </div>
            {complete && (
              <div className={styles.check}>
                <Check />
              </div>
            )}
          </div>
        </div>
      </div>
    </button>
  )
}
