import {
  GetActiveContactsCountDocument,
  GetActiveContactsCountQuery,
  GetActiveContactsCountQueryVariables,
} from 'src/generated/graphql'
import apiSore from 'store/apiSore'

export const CommonApi = {
  getActiveContactsCount: (variables: GetActiveContactsCountQueryVariables) =>
    apiSore.client.query<
      GetActiveContactsCountQuery,
      GetActiveContactsCountQueryVariables
    >({
      query: GetActiveContactsCountDocument,
      variables,
    }),
}
