import { ITypographyProps, Typography } from '../Typography'
import React, { CSSProperties } from 'react'

export type ILabelProps = {
  marginBottom?: CSSProperties['marginBottom']
  text: React.ReactNode
  isRequired?: boolean
  typographyProps?: ITypographyProps
}

export const Label = ({
  marginBottom,
  text,
  isRequired,
  typographyProps,
}: ILabelProps) => (
  <Typography
    variant={'s2-medium'}
    color={'--primary-color-gray-1'}
    {...typographyProps}
    style={{ marginBottom, ...typographyProps?.style }}
  >
    {text}
    {isRequired && (
      <>
        {' '}
        <Typography
          variant={typographyProps?.variant || 's2-medium'}
          color={'--states-color-error-1'}
        >
          *
        </Typography>
      </>
    )}
  </Typography>
)
