import { observer } from 'mobx-react-lite'
import { CreateElementBtn } from 'components/Button/CreateElementBtn'
import addNumberStore from 'store/settings/numbers/addNumberStore'
import numbersStore from 'store/settings/numbers/numbersStore'
import { getNumbersActions } from 'src/pages/settings/numbers/actions/getNumbersActions'
import { ManySelectContent } from 'src/pages/settings/numbers/LeftStickyContent/ManySelectContent'
import { AppElements } from 'src/enums/appElements'

export const LeftStickyContent = observer(() => {
  if (numbersStore.tableStore.checkedRows.length === 1) {
    return (
      <div className={'row12'}>
        {getNumbersActions(numbersStore.tableStore.checkedRows[0]).map(
          (Action, index) => (
            <Action key={index} medium />
          )
        )}
      </div>
    )
  }
  if (numbersStore.tableStore.checkedRows.length > 1) {
    return <ManySelectContent />
  }
  if (numbersStore.numbersPage.length || numbersStore.tableStore.search) {
    return <div />
  }
  return (
    <div>
      <CreateElementBtn
        appElement={AppElements.Numbers}
        createText={'Buy'}
        onCreate={() => addNumberStore.setOpenAddNumberModal(true)}
      />
    </div>
  )
})
