import React, { FC, ReactNode } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { Icon } from 'components/Icon/Icon'

type Props = {
  active: boolean
  direction: string
  onClick: () => void
  children?: ReactNode
}

export const SortLabel: FC<Props> = ({
  children,
  onClick,
  active,
  direction,
}) => {
  return (
    <button
      onClick={onClick}
      className={classNames(styles.sortBtn, active && styles.active)}
    >
      {children}
      {active && (
        <Icon
          icon={'arrowDown'}
          fontSize={12}
          className={direction === 'asc' ? styles.rotate : undefined}
        />
      )}
    </button>
  )
}
