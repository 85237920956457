import * as React from 'react'
import { useDeleteConversionsMutation } from 'src/generated/graphql'
import { Button } from 'shared/Button/Button'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import modalStore from 'widgets/ModalContainer/store/modalStore'
import alertStore from 'store/alertStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'

export const DeleteBtn = ({
  ids,
  onSuccess,
  size,
}: {
  ids: string[]
  onSuccess: () => void
  size?: 'small' | 'medium'
}) => {
  const [deleteConversions] = useDeleteConversionsMutation()

  const onDelete = async () => {
    modalStore.addModal({
      deleteModalProps: {
        title: 'Delete conversion?',
        subTitle: `This action cannot be undone`,
        onDelete: async () => {
          try {
            await deleteConversions({
              variables: {
                conversionIds: ids,
              },
            })
            alertStore.setAlert({
              type: AlertTypeEnum.success,
              title: 'Conversion deleted',
            })
            onSuccess()
          } catch (e) {
            console.error(e)
          }
        },
      },
    })
  }

  if (size === 'medium') {
    return (
      <Button
        variant={'quaternary-medium'}
        onClick={onDelete}
        leftIcon={'delete'}
        text={'Delete'}
      />
    )
  }

  return (
    <MuiTooltip title={'Delete'} arrow placement={'top'}>
      <Button
        variant={'tertiary-small'}
        onClick={onDelete}
        leftIcon={'delete'}
        advanced
      />
    </MuiTooltip>
  )
}
