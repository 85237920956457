import { StatusContent } from 'components/StatusContent/StatusContent'
import { Status } from 'src/generated/graphql'
import { numberString } from 'src/util/functions'
import { IContactImportState } from 'src/pages/main/contacts/importHistory/ImportHistoryTable/types'
import dayjs, { DayjsFormats } from 'lib/dayjs'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'
import { ColumnType } from 'components/NewTable/types'
import { observer } from 'mobx-react-lite'
import companyStore from 'store/settings/company/companyStore'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'shared/Typography'

export const columns: ColumnType<IContactImportState>[] = [
  {
    id: 'originalFileName',
    label: 'File name',
    format: (value) => (
      <div className={styles.file}>
        <Icon icon={'file'} fontSize={12} color={'--primary-color-gray-1'} />
        <Typography>{value as string}</Typography>
      </div>
    ),
  },
  {
    id: 'successCount',
    label: 'Contact Added',
    format: (value, { simplifiedStatus, successPercentage }) =>
      simplifiedStatus === Status.Processing ? (
        <span className={'gray1'}>
          {successPercentage ? `${successPercentage}%` : 'Please wait'}
        </span>
      ) : (
        numberString({ val: +value })
      ),
  },
  {
    id: 'invalidCount',
    label: 'Skipped',
    format: (value, { simplifiedStatus, id }) =>
      simplifiedStatus === Status.Processing ? (
        <span className={'gray1'}>Please wait</span>
      ) : value ? (
        <Link to={String(id)} className={'s1'}>
          {numberString({ val: +value })}
        </Link>
      ) : (
        <>{value}</>
      ),
  },
  {
    id: 'member.name',
    label: 'Uploaded By',
    format: (value, { member }) =>
      member?.profile?.name ||
      `${member?.profile?.firstName || ''} ${
        member?.profile?.lastName || ''
      }`.trim(),
  },
  {
    id: 'createdAt',
    label: 'Upload time',
    format: (value) => dayjs(value as Date).format(DayjsFormats.fullWithAtDash),
  },
  {
    id: 'simplifiedStatus',
    label: 'Status',
    format: (value) => <StatusColumn status={value as Status} />,
  },
]

const StatusColumn = observer(({ status }: { status: Status }) => {
  if (status === Status.Processing && !companyStore.allowImport) {
    return (
      <StatusContent
        status={Status.Pending}
        text={'Pending Review'}
        tooltipWidth={'fit-content'}
        tooltipIconText={
          <>
            Your import is currently pending a manual review. This is <br />
            done to ensure the quality of your contact list. Imports are <br />
            typically reviewing within 1 business day.{' '}
            <a
              href="https://help.callloop.com/en/articles/7039886-how-to-import-contacts"
              target={'_blank'}
              rel="noreferrer"
              className={'s1'}
            >
              Learn more
            </a>
          </>
        }
      />
    )
  }
  return <StatusContent status={status as Status} />
})
