import styles from './styles.module.scss'
import { useNavigate } from 'react-router-dom'
import { BreadCrumbs } from 'components/BreadCrumbs/BreadCrumbs'
import { BtnSize, Button } from 'components/Button/Button'
import { ReactComponent as Angle } from 'icons/16px/Angle-Left.svg'
import { ImportHistoryTable } from 'src/pages/main/contacts/importHistory/ImportHistoryTable/ImportHistoryTable'

export const ImportHistory = () => {
  const navigate = useNavigate()
  return (
    <div className={styles.wrap}>
      <div>
        <BreadCrumbs />
        <div className={styles.content}>
          <Button hoverGray size={BtnSize.small} onClick={() => navigate(-1)}>
            <Angle />
          </Button>

          <h1>Import History</h1>
        </div>
      </div>
      <ImportHistoryTable />
    </div>
  )
}
