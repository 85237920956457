import { AudioTable } from 'src/pages/settings/audio/AudioTable/AudioTable'
import { Header } from 'shared/Header/Header'

export const Audio = () => {
  return (
    <>
      <Header title={'Audio'} />
      <AudioTable />
    </>
  )
}
