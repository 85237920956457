import { SizedBox } from 'components/SizedBox'
import { Alert } from 'components/Alert/Alert'
import { observer } from 'mobx-react-lite'
import { Tab1 } from 'src/pages/settings/compliance/BusinessProfile/steps/GeneralInfoWithEIN/Tab1'
import { PropsBusinessStep } from 'src/pages/settings/compliance/BusinessProfile/types'
import { appLinks } from 'src/util/links'

export const GeneralInfoWithEIN = observer((props: PropsBusinessStep) => {
  return (
    <>
      <Alert
        text={
          <p className={'par2'}>
            If your business is US-based and has an EIN, please provide your EIN
            to get access to higher messaging limits on Local and Toll-Free
            numbers.{' '}
            <a
              href={appLinks.callLoopCompliance}
              target={'_blank'}
              rel="noreferrer"
            >
              Learn more.
            </a>
          </p>
        }
      />
      <SizedBox height={40} />
      <Tab1 {...props} />
      {/*<Tabs*/}
      {/*  tabs={[*/}
      {/*    'Find',*/}
      {/*    <>*/}
      {/*      Enter manually{' '}*/}
      {/*      {businessProfileStore.einLookupError && (*/}
      {/*        <InfoTooltip*/}
      {/*          title={*/}
      {/*            'There are insufficient data received from lookup. Please add all required data manually'*/}
      {/*          }*/}
      {/*          error*/}
      {/*          info*/}
      {/*        />*/}
      {/*      )}*/}
      {/*    </>,*/}
      {/*  ]}*/}
      {/*  activeTabIndex={businessProfileStore.activeTabGeneralInfo}*/}
      {/*  onTabClick={(index) =>*/}
      {/*    businessProfileStore.setActiveTabGeneralInfo(index)*/}
      {/*  }*/}
      {/*/>*/}
      {/*<SizedBox height={32} />*/}
      {/*{businessProfileStore.activeTabGeneralInfo === 0 && <Tab0 {...props} />}*/}
      {/*{businessProfileStore.activeTabGeneralInfo === 1 && <Tab1 {...props} />}*/}
    </>
  )
})
