import styles from './styles.module.scss'
import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { Step1 } from 'src/Modals/ImportContactModal/StepsContent/Step1/Step1'
import { Step2 } from 'src/Modals/ImportContactModal/StepsContent/Step2/Step2'
import { Step3 } from 'src/Modals/ImportContactModal/StepsContent/Step3/Step3'
import importContactStore from 'store/contacts/importContactStore'
import { useUpdateContactImportStateMutation } from 'src/generated/graphql'
import upgradePlanStore from 'src/Modals/UpgradePlanModal/store/upgradePlanStore'
import { useSetTotalContactsCount } from 'src/pages/main/contacts/hooks/useSetTotalContactsCount'
import { listToListId } from 'store/contacts/functions'
import { useLocation, useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'

export const ImportContactModal = observer(() => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  useSetTotalContactsCount(
    !importContactStore.openImportModal || importContactStore.noLimitContact
  )
  const [updateContactImport] = useUpdateContactImportStateMutation()
  const steps = importContactStore.fromList
    ? ['Upload list', 'Map Fields']
    : ['Upload list', 'Map Fields', 'Select List']
  const [step, setStep] = useState(0)

  useEffect(() => {
    return () => setStep(0)
  }, [importContactStore.openImportModal])

  const onImport = async () => {
    if (!importContactStore.importContactData) {
      return
    }
    try {
      await updateContactImport({
        variables: {
          importStateInput: {
            id: importContactStore.importContactData.importPayload.id,
            columns: importContactStore.columns,
            listIds: importContactStore.selectedContactLists.map(listToListId),
            skipFirstRow: importContactStore.skipFirstRow,
          },
        },
      })
      importContactStore.tableStore.onRefresh()
      importContactStore.setLoadingTriggerImportState()
      importContactStore.closeModal()
      if (pathname.includes(RoutesEnum.contacts)) {
        navigate(RoutesEnum.contactsHistory)
      }
    } catch (e) {
      console.error()
    }
  }

  return (
    <Modal
      open={importContactStore.openImportModal}
      onClose={() => importContactStore.closeModal()}
      title={'Import contacts'}
      steps={steps}
      activeStep={step}
    >
      <div className={'pModal'}>
        {step === 0 && (
          <div className={styles.w936}>
            <Step1 />
          </div>
        )}
        {step === 1 && (
          <div className={styles.w936}>
            <Step2 />
          </div>
        )}
        {step === 2 && <Step3 />}
        <div className={styles.actions}>
          {step === 0 && (
            <>
              {importContactStore.overContactsCount > 0 ? (
                <Button
                  typeBtn={BtnType.primary}
                  size={BtnSize.medium}
                  disabled={
                    !importContactStore.uploadFiles.length ||
                    (!importContactStore.overContactsCount &&
                      !importContactStore.noLimitContact)
                  }
                  onClick={() => {
                    setStep(step + 1)
                  }}
                >
                  Continue
                </Button>
              ) : (
                <Button
                  typeBtn={BtnType.primary}
                  size={BtnSize.medium}
                  onClick={() => upgradePlanStore.setOpenUpgradePlanModal(true)}
                >
                  Upgrade plan
                </Button>
              )}
              <Button
                typeBtn={BtnType.secondaryGray}
                size={BtnSize.medium}
                onClick={() => importContactStore.closeModal()}
              >
                Cancel
              </Button>
            </>
          )}
          {step === 1 && !importContactStore.fromList && (
            <>
              <Button
                typeBtn={BtnType.primary}
                size={BtnSize.medium}
                disabled={importContactStore.disabledToStep3}
                onClick={() => {
                  setStep(step + 1)
                }}
              >
                Continue
              </Button>
              <Button
                typeBtn={BtnType.secondaryGray}
                size={BtnSize.medium}
                onClick={() => {
                  importContactStore.resetMapFields()
                  setStep(step - 1)
                }}
              >
                Back
              </Button>
            </>
          )}
          {(step === 2 || (step === 1 && importContactStore.fromList)) && (
            <>
              <Button
                typeBtn={BtnType.primary}
                size={BtnSize.medium}
                disabled={
                  !importContactStore.agreeTerm ||
                  !importContactStore.selectedOptions.length
                }
                onClick={onImport}
              >
                Import
              </Button>
              <Button
                typeBtn={BtnType.secondaryGray}
                size={BtnSize.medium}
                onClick={() => setStep(step - 1)}
              >
                Back
              </Button>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
})
