// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p0NLqv5WMc7tVsSTx8lb aside{height:395px;padding-top:8px;border-radius:12px;border:none;box-shadow:none}.p0NLqv5WMc7tVsSTx8lb aside input{border-radius:8px;border:1px solid var(--primary-color-gray-3)}.p0NLqv5WMc7tVsSTx8lb aside .active-category-indicator-wrapper{margin-bottom:4px}.p0NLqv5WMc7tVsSTx8lb aside .emoji-group:before{font-size:11px}`, "",{"version":3,"sources":["webpack://./src/components/Textarea/actions/EmojiPicker/styles.module.scss"],"names":[],"mappings":"AACE,4BACE,YAAA,CACA,eAAA,CACA,kBAAA,CACA,WAAA,CACA,eAAA,CAGA,kCAEE,iBAAA,CACA,4CAAA,CAGA,+DACE,iBAAA,CAEF,gDAEE,cAAA","sourcesContent":[".emoji{\n  aside{\n    height: 395px;\n    padding-top: 8px;\n    border-radius: 12px;\n    border: none;\n    box-shadow: none;\n    //filter: drop-shadow(0px 0px 2px rgba(83, 102, 137, 0.2)) drop-shadow(0px 12px 16px rgba(83, 102, 137, 0.08)) drop-shadow(0px 10px 40px rgba(83, 102, 137, 0.08));\n\n    input{\n      //margin-top: 4px;\n      border-radius: 8px;\n      border: 1px solid var(--primary-color-gray-3);\n    }\n    :global{\n      .active-category-indicator-wrapper{\n        margin-bottom: 4px;\n      }\n      .emoji-group:before{\n        //font-weight: normal;\n        font-size: 11px;\n      }\n    }\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emoji": `p0NLqv5WMc7tVsSTx8lb`
};
export default ___CSS_LOADER_EXPORT___;
