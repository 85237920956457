import { observer } from 'mobx-react-lite'
import styles from 'components/Schedule/styles.module.scss'
import { ScheduleBtn } from 'components/Schedule/ScheduleBtn'
import { CampaignDelayType } from 'src/generated/graphql'
import { useCampaignScheduleContext } from 'src/widgets/CampaignSchedule'
import { ContactDateFieldContent } from 'src/widgets/CampaignSchedule/ui/ContactDateFieldCard/ContactDateFieldContent/ContactDateFieldContent'

export const ContactDateFieldCard = observer(() => {
  const { setSendTypeCampaign, activeContactDateField } =
    useCampaignScheduleContext()
  const onClick = () => {
    setSendTypeCampaign(CampaignDelayType.DelayCustomField)
  }
  return (
    <div className={styles.row}>
      <ScheduleBtn
        active={activeContactDateField}
        title={'Contact Date Field'}
        onClick={onClick}
      />
      <ContactDateFieldContent />
    </div>
  )
})
