// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XJ4ZPXLEItf8dWerwc4D{align-items:center;margin-top:0;padding-top:0;top:0;display:flex;justify-content:space-between;padding-bottom:20px}.J0rfSRsQMPBOVIWVI1kR{position:relative}.J0rfSRsQMPBOVIWVI1kR table thead tr:first-child th{top:52px}`, "",{"version":3,"sources":["webpack://./src/pages/main/contacts/segments/DetailSegment/components/SegmentContent/SegmentTableContact/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,YAAA,CACA,aAAA,CACA,KAAA,CACA,YAAA,CACA,6BAAA,CACA,mBAAA,CAGF,sBACE,iBAAA,CAIM,oDACE,QAAA","sourcesContent":[".overrideWrapStickyClass{\n  align-items: center;\n  margin-top: 0;\n  padding-top: 0;\n  top: 0;\n  display: flex;\n  justify-content: space-between;\n  padding-bottom: 20px;\n\n}\n.table{\n  position: relative;\n  table{\n    thead {\n      tr:first-child {\n        th {\n          top: 52px;\n        }\n      }\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overrideWrapStickyClass": `XJ4ZPXLEItf8dWerwc4D`,
	"table": `J0rfSRsQMPBOVIWVI1kR`
};
export default ___CSS_LOADER_EXPORT___;
