import { FC } from 'react'
import {
  CampaignScheduleContext,
  CampaignScheduleStore,
} from 'src/widgets/CampaignSchedule'
import { CampaignScheduleContent } from 'src/widgets/CampaignSchedule/ui/CampaignScheduleContent'

type IBroadcastScheduleProps = {
  store: CampaignScheduleStore
}

export const CampaignSchedule: FC<IBroadcastScheduleProps> = ({ store }) => (
  <CampaignScheduleContext.Provider value={store}>
    <CampaignScheduleContent />
  </CampaignScheduleContext.Provider>
)
