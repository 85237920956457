import styles from './styles.module.scss'
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { BtnSize, Button } from 'components/Button/Button'
import classNames from 'classnames'
import { Portal } from '../Portal'
import { observer } from 'mobx-react-lite'
import uiStore from 'store/uiStore'
import { Steps } from 'components/Modal/Steps/Steps'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import { IInfoModal, InfoModal } from 'components/Modal/ui/InfoModal/InfoModal'
import { Icon } from 'components/Icon/Icon'
import {
  DeleteModal,
  IDeleteModal,
} from 'components/Modal/ui/DeleteModal/DeleteModal'

export type IModalProps = {
  open?: boolean
  onClose?: () => void
  title?: ReactNode
  subTitle?: string | JSX.Element
  titleTooltip?: string
  minWidth?: number
  minHeight?: number
  withoutHeader?: boolean
  headerContent?: JSX.Element
  centered?: boolean
  centered2?: boolean
  topRight?: boolean
  steps?: string[]
  activeStep?: number
  staticContent?: boolean
  loading?: boolean
  triggerHeight?: string
  infoModalProps?: Omit<IInfoModal, 'onClose'>
  deleteModalProps?: Omit<IDeleteModal, 'onClose'>
  children?: ReactNode
  size?: 'small' | 'medium'
  leftNavBar?: boolean
}

export const Modal: FC<IModalProps> = observer(
  ({
    open,
    onClose,
    title,
    subTitle,
    minHeight,
    minWidth,
    withoutHeader,
    centered,
    headerContent,
    steps,
    activeStep = 0,
    topRight,
    titleTooltip,
    staticContent,
    centered2,
    loading,
    triggerHeight,
    children,
    infoModalProps,
    deleteModalProps,
    size,
    leftNavBar,
  }) => {
    const refContent = useRef<HTMLDivElement>(null)
    const onOverlayClick = (e: React.MouseEvent) => {
      e.stopPropagation()
      onClose && onClose()
    }
    const [isCentered, setIsCentered] = useState(false)
    useEffect(() => {
      if (open && refContent.current && centered2) {
        setIsCentered(
          window.document.body.offsetHeight -
            (refContent.current?.offsetHeight || 0) -
            240 <
            0
        )
      }
    }, [open, refContent, triggerHeight])

    const content = (
      <div
        className={classNames(
          styles.wrap,
          uiStore.isMobile && styles.mobile,
          centered && styles.centered,
          centered2 && isCentered && styles.centered,
          topRight && styles.topRight,
          staticContent && styles.staticContent,
          leftNavBar && styles.leftNavBar
        )}
        onClick={onOverlayClick}
        aria-hidden="true"
        style={{ minHeight: uiStore.minRootHeight }}
      >
        <div
          className={styles.content}
          onClick={(e) => {
            e.stopPropagation()
          }}
          style={{ minHeight }}
          aria-hidden="true"
          ref={refContent}
          id={'modal_content'}
        >
          {!infoModalProps &&
            !deleteModalProps &&
            !withoutHeader &&
            !leftNavBar && (
              <div className={styles.header}>
                {title && (
                  <div className={'fullWidth'}>
                    {subTitle && (
                      <span className={'gray1 mb28'}>{subTitle}</span>
                    )}
                    <h3 className={'bold'}>
                      {title}
                      {titleTooltip && (
                        <InfoTooltip title={titleTooltip} large />
                      )}
                    </h3>
                  </div>
                )}
                <div className={styles.headerSteps}>
                  {steps && <Steps steps={steps} activeStep={activeStep} />}
                  {headerContent}
                </div>

                {!staticContent && (
                  <Button
                    size={BtnSize.mini}
                    onClick={onClose}
                    className={styles.close}
                  >
                    <Icon
                      icon={'close'}
                      fontSize={18}
                      color={'--primary-color-gray-1'}
                      hoverColor={'--primary-color-black'}
                    />
                  </Button>
                )}
              </div>
            )}
          {loading ? (
            <div
              style={{
                height: 528,
                width: minWidth || 800,
                position: 'relative',
              }}
            >
              {/*<SizedBox height={528} />*/}
              <AbsoluteLoader />
            </div>
          ) : (
            <div className={styles.wrapContent}>
              {infoModalProps && (
                <InfoModal {...infoModalProps} onClose={onClose} />
              )}
              {deleteModalProps && (
                <DeleteModal {...deleteModalProps} onClose={onClose} />
              )}
              <div
                className={classNames(
                  size === 'small' && 'smallModal',
                  size === 'medium' && 'mediumModal'
                )}
              >
                {children}
              </div>
            </div>
          )}
        </div>
      </div>
    )
    return (
      <>
        {open && !staticContent && (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          <Portal>{content}</Portal>
        )}
        {open && staticContent && content}
      </>
    )
  }
)
