// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AZsMjJE5aKnmo5wCr0WY{margin-top:40px;border:1px solid var(--primary-color-gray-3);border-radius:12px;padding:25px 24px;display:flex;justify-content:space-between;align-items:center}.AZsMjJE5aKnmo5wCr0WY.gPWmEFulXVSWz48FVK31{padding:16px;margin-top:32px}`, "",{"version":3,"sources":["webpack://./src/pages/settings/billing/components/PhoneCheckr/styles.module.scss"],"names":[],"mappings":"AAAA,sBAKE,eAAA,CACA,4CAAA,CACA,kBAAA,CACA,iBAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CAVA,2CACE,YAAA,CACA,eAAA","sourcesContent":[".wrap{\n  &.fromModal{\n    padding: 16px;\n    margin-top: 32px;\n  }\n  margin-top: 40px;\n  border: 1px solid var(--primary-color-gray-3);\n  border-radius: 12px;\n  padding: 25px 24px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `AZsMjJE5aKnmo5wCr0WY`,
	"fromModal": `gPWmEFulXVSWz48FVK31`
};
export default ___CSS_LOADER_EXPORT___;
