import { TableStatuses } from 'components/NewTable/store/TableStore'
import { Clicks } from 'components/Clicks/Clicks'
import { ICardDetail } from 'components/DetailCard/DetailCard/DetailCard'
import { ShortUrlClickStatistics } from 'src/generated/graphql'

export const detailsCards = {
  total: (props: Partial<ICardDetail>) =>
    ({
      title: 'Total',
      status: TableStatuses.totalCount,
      value: TableStatuses.totalCount,
      count: props.count,
      percent: 0,
      ...props,
    } as ICardDetail),
  success: (props: Partial<ICardDetail>) =>
    ({
      title: 'Success',
      value: TableStatuses.successCount,
      status: TableStatuses.successCount,
      count: props.count,
      percent: props.percent,
      ...props,
    } as ICardDetail),
  custom: (props: Partial<ICardDetail>) =>
    ({
      ...props,
    } as ICardDetail),
  pending: (props: Partial<ICardDetail>) =>
    ({
      title: 'Pending',
      value: TableStatuses.pendingCount,
      status: TableStatuses.pendingCount,
      count: props.count,
      percent: props.percent,
      ...props,
    } as ICardDetail),
  failed: (props: Partial<ICardDetail>) =>
    ({
      title: 'Failed',
      value: TableStatuses.failedCount,
      status: TableStatuses.failedCount,
      count: props.count,
      percent: props.percent,
      ...props,
    } as ICardDetail),
  unsubscribed: (props: Partial<ICardDetail>) =>
    ({
      title: 'Unsubscribed',
      value: TableStatuses.unsubscribedCount,
      status: TableStatuses.unsubscribedCount,
      count: props.count,
      percent: props.percent,
      ...props,
    } as ICardDetail),
  callBacks: (props: Partial<ICardDetail>) =>
    ({
      title: 'Call backs',
      value: TableStatuses.callBacks,
      status: TableStatuses.callBacks,
      count: props.count,
      percent: props.percent,
      ...props,
    } as ICardDetail),
  voiceTransfers: (props: Partial<ICardDetail>) =>
    ({
      title: 'Transfers',
      value: TableStatuses.voiceTransfers,
      status: TableStatuses.voiceTransfers,
      count: props.count,
      percent: props.percent,
      ...props,
    } as ICardDetail),
  shortUrlClickStatistics: ({
    shortUrlClickStatistics,
    card,
  }: {
    shortUrlClickStatistics: ShortUrlClickStatistics
    card?: Partial<ICardDetail>
  }) =>
    ({
      value: TableStatuses.clicksCount,
      status: TableStatuses.clicksCount,
      titleComponent: (
        <Clicks shortUrlClickStatistics={shortUrlClickStatistics} fromCard />
      ),
      count: shortUrlClickStatistics.totalVisits,
      percent: shortUrlClickStatistics.totalVisitsPercentage,
      ...card,
    } as ICardDetail),
}
