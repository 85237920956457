import styles from './styles.module.scss'
import { ReactNode } from 'react'
import { CheckBox } from 'components/CheckBox/CheckBox'
import classNames from 'classnames'
import { ClassesEnum } from 'styles/classes'
import { TableStatuses } from 'components/NewTable/store/TableStore'
import { observer } from 'mobx-react-lite'
import { RequiredFields } from 'components/NewTable/types'
import { numberString } from 'src/util/functions'

export interface ICardDetail {
  title?: string
  titleComponent?: ReactNode
  value: string
  status?: TableStatuses
  count: ReactNode
  percent: number
  today?: number | string
  noSelectCard?: boolean
  hidePercent?: boolean
}

type Props<T extends RequiredFields<T>> = {
  card: ICardDetail
  noSelect?: boolean
  handleSelectCard: () => void
  checked: boolean
}

export const DetailCard = observer(
  <T extends RequiredFields<T>>({
    card,
    noSelect,
    handleSelectCard,
    checked,
  }: Props<T>) => {
    const {
      title,
      percent,
      count,
      today,
      titleComponent,
      value,
      hidePercent,
      noSelectCard,
    } = card

    const isNoSelect = noSelect || noSelectCard

    return (
      <button
        className={classNames(
          styles.wrapCard,
          checked && styles.checked,
          isNoSelect && styles.noSelect
        )}
        onClick={isNoSelect ? undefined : handleSelectCard}
      >
        <div className={styles.content}>
          <div className={classNames(styles.wrapTitleRow, styles.row)}>
            {title ? (
              <span className={ClassesEnum.nowrap}>{title}</span>
            ) : (
              titleComponent
            )}
            <div className={styles.row}>
              {value !== TableStatuses.totalCount && !hidePercent && (
                <span className={'gray1'}>
                  {numberString({ val: percent, maximumFractionDigits: 1 })}%
                </span>
              )}
            </div>
          </div>
          <div className={styles.rowBottom}>
            <div>
              <div className={styles.count}>{count}</div>
              {today !== undefined && (
                <div className={styles.today}>
                  <span className={'s2 medium gray1'}>Today</span>
                  <span className={'s2 medium ml4'}>{today}</span>
                </div>
              )}
            </div>
            {!isNoSelect && (
              <div className={'mb4'}>
                <CheckBox checked={checked} shape={'radio'} size={'small'} />
              </div>
            )}
          </div>
        </div>
      </button>
    )
  }
)
