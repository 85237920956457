import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { BusinessProfileCard } from 'src/pages/settings/compliance/BusinessProfileCard/BusinessProfileCard'
import { TollFreeVerificationCard } from 'src/pages/settings/compliance/TollFreeVerificationCard/TollFreeVerificationCard'
import { NewRequirements } from 'src/pages/settings/compliance/components/NewRequirements/NewRequirements'
import companyStore from 'store/settings/company/companyStore'
import { Header } from 'shared/Header/Header'
import { Container } from 'shared/Container/Container'

export const Compliance = observer(() => {
  useEffect(() => {
    companyStore.setShowNewReq(true)
  }, [])
  return (
    <>
      <Header title={'Compliance'} />
      <Container small>
        {companyStore.showNewReq && <NewRequirements fromCompany />}
        <div className={'col16'}>
          <BusinessProfileCard />
          {/*<A2P10DLCCard />*/}
          <TollFreeVerificationCard />
        </div>
      </Container>
    </>
  )
})
