// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._FlhUle5SXXCjf5BtaUf{padding:0 32px 32px 32px;width:800px;overflow-x:auto}._m7j_1zXUs3VPkmEVZ9B{margin-top:6px}.AC4R_aPs1hEyzqmbg1oL{display:grid;grid-template-columns:max-content max-content 1fr max-content;column-gap:12px;margin-top:186px}`, "",{"version":3,"sources":["webpack://./src/pages/main/broadcasts/createBroadcast/CreateBroadcastsModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,wBAAA,CACA,WAAA,CACA,eAAA,CAEJ,sBACI,cAAA,CAEJ,sBACI,YAAA,CACA,6DAAA,CACA,eAAA,CACA,gBAAA","sourcesContent":[".wrap{\n    padding: 0 32px 32px 32px;\n    width: 800px;\n    overflow-x: auto;\n}\n.upgrade{\n    margin-top: 6px;\n}\n.actions{\n    display: grid;\n    grid-template-columns: max-content max-content 1fr max-content;\n    column-gap: 12px;\n    margin-top: 186px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `_FlhUle5SXXCjf5BtaUf`,
	"upgrade": `_m7j_1zXUs3VPkmEVZ9B`,
	"actions": `AC4R_aPs1hEyzqmbg1oL`
};
export default ___CSS_LOADER_EXPORT___;
