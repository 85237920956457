import React, { useState } from 'react'
import { useOverlayClick } from 'src/hooks/useOverlayClick'

export function useFixedTooltip(
  refHover?: React.MutableRefObject<boolean>,
  onOverlayClick?: () => void,
  parentElement?: HTMLDivElement | null
) {
  const [trigger, setTrigger] = useState<
    HTMLDivElement | HTMLButtonElement | null
  >(null)
  const [showTT, setShowTT] = useState(false)
  const overlayClick = () => {
    if (!refHover?.current) {
      onOverlayClick && onOverlayClick()
      setShowTT(false)
    }
  }
  useOverlayClick(overlayClick, showTT, parentElement)
  const onTriggerClick = () => {
    // event && event.stopPropagation()
    if (!showTT) {
      setTimeout(() => setShowTT(true))
    }
  }
  return { trigger, setTrigger, onTriggerClick, showTT, setShowTT }
}
