// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wPlCTV71SQ9tbNpl7ezI{display:flex;width:100%;position:sticky;overflow:auto;left:0}`, "",{"version":3,"sources":["webpack://./src/components/NewTable/components/AdditionalContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CACA,eAAA,CACA,aAAA,CACA,MAAA","sourcesContent":[".additionalContent{\n  display: flex;\n  width: 100%;\n  position: sticky;\n  overflow: auto;\n  left: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"additionalContent": `wPlCTV71SQ9tbNpl7ezI`
};
export default ___CSS_LOADER_EXPORT___;
