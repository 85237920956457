import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { TextareaStore } from 'store/textareaStore'
import { VoiceStore } from 'store/voiceStore/voiceStore'
import { RinglessStore } from 'store/ringlessStore/ringlessStore'
import mediaStore from 'store/mediaStore'
import contactStore from 'store/contacts/contactStore'
import { PreviewEnum } from 'components/Preview/Preview'
import { IPreviewMessage } from 'store/broadcasts/createBroadCastStore'
import { ContentType } from 'src/generated/graphql'
import {
  IPreviewContent,
  IPreviewVoiceMessage,
  IRinglessMessage,
  ISMSMessages,
} from 'components/Preview/types'
import uiStore from 'store/uiStore'
import numbersStore from 'store/settings/numbers/numbersStore'
import { TrackConversionStore } from 'widgets/TrackConversion/store/trackConversionStore'

export class CreateStore {
  textareaStore: TextareaStore
  mediaStore: typeof mediaStore
  contactStore: typeof contactStore
  voiceStore: VoiceStore
  ringlessStore: RinglessStore
  trackConversionStore: TrackConversionStore
  constructor({
    createBtnText,
    placeholder,
    compliance,
  }: {
    createBtnText: string
    placeholder?: string
    compliance?: boolean
  }) {
    this.textareaStore = new TextareaStore({
      compliance,
    })
    this.mediaStore = mediaStore
    this.contactStore = contactStore
    this.voiceStore = new VoiceStore()
    this.ringlessStore = new RinglessStore()
    this.trackConversionStore = new TrackConversionStore()
    this.createBtnText = createBtnText
    this.placeholder = placeholder || ''
    makeObservable(this, {
      type: observable,
      isHaveChanges: observable,
      activeTab: computed,
      previewContent: computed,
      name: observable,
      disabled: observable,
      previewMessagePhone: observable,
      smsPhone: observable,
      voicePhone: observable,
      uuidCode: observable,
      company: observable,
      activeModalTab: observable,
      createBtnText: observable,
      openTest: observable,
      sendTimeString: observable,
      trackConversionStore: observable,
      isAdvanced: observable,

      typePreview: computed,
      previewSmsContent: computed,
      voiceMessagePreview: computed,
      ringlessMessage: computed,
      previewMessages: computed,
      smsPhoneFull: computed,

      setActiveTab: action.bound,
      setName: action.bound,
      setActiveModalTab: action.bound,
      setSMSPhone: action.bound,
      setVoicePhone: action.bound,
      setCompany: action.bound,
      setOpenTest: action.bound,
      setSendTimeString: action.bound,
    })

    reaction(
      () => this.isHaveChanges,
      (isHaveChanges) => {
        uiStore.setBeforeUnload(isHaveChanges)
      }
    )
  }
  placeholder = ''
  type: ContentType = ContentType.Sms
  name = ''
  previewMessagePhone = ''
  smsPhone = ''
  voicePhone = ''
  uuidCode = ''
  company = ''
  activeModalTab = 0
  createBtnText = ''
  openTest = false
  disabled = false
  isHaveChanges = false

  sendTimeString = 'Immediately'

  get smsPhoneFull() {
    return numbersStore.numbersMapByFriendly.get(this.smsPhone)
  }
  get voicePhoneFull() {
    return numbersStore.numbersMapByFriendly.get(this.voicePhone)
  }
  get phonePull() {
    if (this.type === ContentType.Sms) {
      return this.smsPhoneFull
    }
    return this.voicePhoneFull
  }

  get activeTab() {
    if (this.type === ContentType.Voice) {
      return 1
    }
    if (this.type === ContentType.Ringless) {
      return 2
    }
    return 0
  }

  get typePreview(): PreviewEnum {
    if (this.activeTab === 1) {
      return PreviewEnum.voice
    }
    if (this.activeTab === 2) {
      return PreviewEnum.ringless
    }

    return PreviewEnum.sms
  }

  get previewContent(): IPreviewContent {
    return {
      sendTimeString: this.sendTimeString,
      smsMessages: this.previewSmsContent,
      voiceMessage: this.voiceMessagePreview,
      type: this.type,
      ringlessMessage: this.ringlessMessage,
      disabled: this.disabled,
    }
  }

  get previewSmsContent(): ISMSMessages {
    return {
      smsMessages: this.previewMessages,
      phone: this.smsPhone,
      company: this.company,
    }
  }

  get voiceMessagePreview(): IPreviewVoiceMessage {
    return {
      liveAnswerEnabled: this.voiceStore.liveAnswerEnabled,
      liveAnswerEnabledAudio: this.voiceStore.liveAnswerEnabledAudio,
      voiceMailEnabled: this.voiceStore.voiceMailEnabled,
      voiceMailEnabledAudio: this.voiceStore.voiceMailEnabledAudio,
      phone: this.voicePhone,
    }
  }

  get ringlessMessage(): IRinglessMessage {
    return {
      ringlessVoicemailAudio: this.ringlessStore.ringlessVoicemailAudio,
      phone: this.voicePhone,
    }
  }

  get previewMessages() {
    const message: IPreviewMessage = {
      placeholder: this.placeholder,
      messageHtml: this.textareaStore.text,
      attachImages: this.textareaStore.attachImages,
      vCards: this.textareaStore.vCards,
      messageId: this.textareaStore.messageId,
      compliance: this.textareaStore.compliance,
      complianceText: this.textareaStore.complianceText,
    }
    const messagesFromDataCollection: IPreviewMessage[] =
      this.textareaStore.dataCollection
        .filter((item) => item.active)
        .map((item) => ({
          ...item,
          attachImages: mediaStore.getImagesByIds(item.attachImagesIds),
          compliance: false,
        }))
    return [message, ...messagesFromDataCollection]
  }

  setName(name: string) {
    this.name = name
  }

  setActiveTab(index: number) {
    if (index === 0) {
      this.type = ContentType.Sms
    }
    if (index === 1) {
      this.type = ContentType.Voice
    }
    if (index === 2) {
      this.type = ContentType.Ringless
    }
  }

  setActiveModalTab(val: number) {
    this.activeModalTab = val
  }

  setSMSPhone(phone: string) {
    if (
      numbersStore.toolFreeNumbers.find(
        (number) => number.friendlyName === phone
      )
    ) {
      this.smsPhone = phone
      this.voicePhone = phone
    } else {
      if (this.activeTab === 0) {
        this.smsPhone = phone
      } else {
        this.voicePhone = phone
      }
    }
  }
  setVoicePhone(phone: string) {
    this.voicePhone = phone
  }

  setUuidCode(uuidCode: string) {
    this.uuidCode = uuidCode
  }

  setCompany(company: string) {
    this.company = company
    this.textareaStore.setAdditionalText(company)
  }
  setOpenTest(state: boolean) {
    this.openTest = state
  }

  setSendTimeString = (time: string) => {
    this.sendTimeString = time
  }

  isAdvanced = false
  setAdvanced = (value: boolean) => {
    this.isAdvanced = value
  }
  toggleAdvanced = () => {
    this.isAdvanced = !this.isAdvanced
  }
}
