import styles from './styles.module.scss'
import { Button } from 'shared/Button/Button'
import { useConversionsContext } from 'src/pages/settings/conversions/conversions/context/context'
import { openNewConversionModal } from 'src/features/conversions/openNewConversionModal'
import { openGetCodeConversionModal } from 'src/features/conversions/openGetCodeConversionModal'

export const Actions = () => {
  const { onSuccessCreateConversion } = useConversionsContext()
  return (
    <div className={styles.actions}>
      <Button
        variant={'primary-medium'}
        text={'New conversion'}
        leftIcon={'plus'}
        onClick={() => openNewConversionModal(onSuccessCreateConversion)}
      />
      <Button
        variant={'secondary-medium'}
        text={'Conversion code'}
        leftIcon={'code'}
        onClick={openGetCodeConversionModal}
      />
    </div>
  )
}
