import styles from './styles.module.scss'
import { FC, useState } from 'react'
import { ReactComponent as CheckMark } from 'icons/20px/Check2.svg'
import classNames from 'classnames'
import { bytesToSize } from 'src/util/functions'
import { Asset } from 'src/generated/graphql'
import presentationStore from 'store/presentationStore'
import mediaStore from 'store/mediaStore'
import { Icon } from 'components/Icon/Icon'

type Props = {
  img: Asset
  onSelectUploadImg: (id: string) => void
  isSelect: boolean
  index: number
}

export const ImgCard: FC<Props> = ({
  img,
  onSelectUploadImg,
  isSelect,
  index,
}) => {
  const [hover, setHover] = useState(false)
  const onFullScreen = () => {
    presentationStore.setCurrentIndex(index)
    presentationStore.setPresentationImages(mediaStore.uploadImages)
  }
  return (
    <div
      className={classNames(styles.imgWrap, isSelect && styles.selected)}
      onClick={() => onSelectUploadImg(img.id)}
      aria-hidden={true}
    >
      <div
        className={styles.addContent}
        onMouseLeave={() => setHover(false)}
        onMouseEnter={() => setHover(true)}
      >
        <div className={styles.row}>
          {isSelect && (
            <div
              className={classNames(styles.check, isSelect && styles.checked)}
            >
              {isSelect && <CheckMark />}
            </div>
          )}
        </div>
        <div className={styles.rowHover}>
          <span className={'s2 medium white ellipsisNowrap'}>
            {hover ? (
              <>{img.originalFileName?.split('.' + img.extension)[0]}</>
            ) : (
              bytesToSize(img.sourceSize)
            )}
          </span>
          {hover && (
            <button className={styles.fullScreenBtn} onClick={onFullScreen}>
              <Icon
                icon={'arrowExpand'}
                fontSize={12}
                color={'--primary-color-white'}
              />
            </button>
          )}
        </div>
      </div>
      <div
        className={styles.img}
        style={{ backgroundImage: `url(${img.compressedUrl})` }}
      />
    </div>
  )
}
