// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.X9cA8aZvbiZxGKWfqw6K{display:grid;grid-template-columns:1fr 100px;column-gap:12px}.eo0J6DTLVoWbivkHY8Sa{border:1px solid var(--primary-color-gray-3);padding:15px;border-radius:16px;margin-bottom:24px}`, "",{"version":3,"sources":["webpack://./src/pages/settings/numbers/modals/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,+BAAA,CACA,eAAA,CAEF,sBACE,4CAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA","sourcesContent":[".wrapNumber{\n  display: grid;\n  grid-template-columns: 1fr 100px;\n  column-gap: 12px;\n}\n.wrapAffects{\n  border: 1px solid var(--primary-color-gray-3);\n  padding: 15px;\n  border-radius: 16px;\n  margin-bottom: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapNumber": `X9cA8aZvbiZxGKWfqw6K`,
	"wrapAffects": `eo0J6DTLVoWbivkHY8Sa`
};
export default ___CSS_LOADER_EXPORT___;
