import {
  action,
  computed,
  makeObservable,
  observable,
  override,
  reaction,
} from 'mobx'
import { CreateStore } from 'components/CreateElement/store/createStore'
import { DropdownContactListsStore } from 'store/contacts/DropdownContactListsStore'
import contactStore from 'store/contacts/contactStore'
import CustomFieldsStore from 'store/contacts/customFields/customFieldsStore'
import { nanoid } from 'nanoid'
import {
  ContentType,
  GetExistingKeywordPhoneNumbersQuery,
  KeywordInput,
  PhoneNumber,
  Status,
} from 'src/generated/graphql'
import keywordsStore from 'store/keywords/keywordsStore'
import { disabledTooltipText } from 'src/pages/main/keywords/create/DisabledTooltipText'
import { IPreviewContent, ISMSMessages } from 'components/Preview/types'
import { IKeyword } from 'src/types/IKeyword'
import { IOption } from 'src/types/IOption'
import { ReserveKeywordWords } from 'src/static/reserveKeywordWords'
import numbersStore from 'store/settings/numbers/numbersStore'

export class CreateKeywordStore extends CreateStore {
  dropdownContactListsStore: DropdownContactListsStore
  customFieldsStore: typeof CustomFieldsStore
  contactStore: typeof contactStore
  keywordsStore: typeof keywordsStore
  constructor() {
    super({
      createBtnText: 'Create keyword',
      placeholder: 'Auto-confirmation message...',
      compliance: false,
    })
    this.dropdownContactListsStore = new DropdownContactListsStore()
    this.contactStore = contactStore
    this.customFieldsStore = CustomFieldsStore
    this.keywordsStore = keywordsStore
    makeObservable(this, {
      initInput: observable,
      dropdownContactListsStore: observable,
      activeKeyword: observable,
      keywordsStore: observable,
      edit: observable,
      duplicate: observable,
      editId: observable,
      smsPhones: observable,
      keywordsPairsMap: observable,

      keywordInput: computed,
      disabledCreate: computed,
      previewContent: override,
      editKeyword: computed,
      isAlreadyUse: computed,

      userAnswer: computed,
      phoneOptions: computed,

      setEdit: action.bound,
      setActiveKeyword: action.bound,
      setSMSPhones: action.bound,
      setKeywordsPairsMapData: action.bound,
    })

    reaction(
      () => this.keywordInput,
      (input) => {
        if (!this.initInput) {
          this.initInput = input
        }
        const initInputString = JSON.stringify(this.initInput)
        const inputString = JSON.stringify(input)
        this.isHaveChanges = initInputString !== inputString
      }
    )
  }

  noGenerateName = true

  element = 'Keyword'

  createId = nanoid()
  activeKeyword = true
  status: Status = Status.Active

  keywordsPairsMap: Map<string, string[]> = new Map<string, string[]>()

  edit = false
  duplicate = false
  editId = ''
  smsPhones: string[] = []
  initInput: KeywordInput | null = null

  get keywordInput(): KeywordInput {
    return {
      id: this.edit ? this.editId : undefined,
      name: this.name,
      listIds: this.dropdownContactListsStore.selectedContactListsIds,
      smsMessage:
        this.type === ContentType.Sms
          ? this.textareaStore.smsMessageInput
          : undefined,
      status: this.status,
      phoneNumberIds: this.smsPhones.map(
        (phone) => numbersStore.numbersMapByFriendly.get(phone)?.id
      ),
      conversion:
        this.type === ContentType.Sms
          ? this.trackConversionStore.conversionInput
          : null,
    }
  }

  get editKeyword(): IKeyword | undefined {
    return this.keywordsStore.keywordsMap.get(this.editId)
  }

  get isAlreadyUse() {
    let isUse = false
    const phonesForKeyword =
      this.keywordsPairsMap.get(this.name.toLowerCase()) || []
    if (this.edit) {
      if (this.editKeyword?.name === this.name) {
        return false
      }
    }
    phonesForKeyword.forEach((p) => {
      if (p && this.smsPhones.includes(p)) {
        isUse = true
      }
    })

    return isUse
  }

  get isReserve() {
    return ReserveKeywordWords.includes(this.name.toUpperCase())
  }

  get phoneOptions(): IOption[] {
    const phonesForKeyword =
      this.keywordsPairsMap.get(this.name.toLowerCase()) || []
    return numbersStore.smsPhoneNumbersOptions.map((opt) => ({
      ...opt,
      disabled: phonesForKeyword.includes(opt.value),
      disabledTooltip: disabledTooltipText,
    }))
  }

  get phones(): PhoneNumber[] {
    const phones: PhoneNumber[] = []
    this.smsPhones.forEach((phone) => {
      const p = numbersStore.numbersMapByFriendly.get(phone)
      if (p) {
        phones.push(p)
      }
    })

    return phones
  }

  get userAnswer() {
    return this.name || 'Keyword name'
  }

  get previewContent(): IPreviewContent {
    return {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...super.previewContent,
      smsMessages: this.smsMessagesKeyword,
      userAnswer: this.userAnswer,
    }
  }

  get smsMessagesKeyword(): ISMSMessages {
    return {
      ...this.previewSmsContent,
      phone: (this.smsPhones.length && this.smsPhones[0]) || '',
    }
  }

  get disabledCreate() {
    return !(
      this.name &&
      this.smsPhones.length &&
      this.textareaStore.smsMessageInput.text &&
      !this.textareaStore.isError &&
      this.dropdownContactListsStore.selectedOptions.length &&
      !this.isAlreadyUse &&
      !this.isReserve
    )
  }

  setActiveKeyword(state: boolean) {
    this.status = state ? Status.Active : Status.Disabled
    this.activeKeyword = state
  }

  setEdit(keyword: IKeyword, isDuplicate: boolean) {
    const phones: string[] = []
    keyword.keywordPhoneNumbers?.forEach((p) => {
      if (p?.phoneNumber?.friendlyName) {
        phones.push(p?.phoneNumber?.friendlyName)
      }
    })
    if (isDuplicate) {
      this.duplicate = true
      this.status = Status.Active
      this.activeKeyword = true
    } else {
      this.edit = true
      this.status = keyword.status || Status.Active
      this.activeKeyword = keyword.status === Status.Active
    }

    this.editId = keyword.id
    this.textareaStore.setSmsMessage(keyword.smsMessage)
    this.textareaStore.setTriggerMessageUpdate()
    this.name = keyword.name
    // this.name = `${keyword.name}${isDuplicate ? ' copy' : ''}`
    this.smsPhones = phones
    if (keyword.contactsListsIds) {
      this.dropdownContactListsStore.setInitListsIds(keyword.contactsListsIds)
    }
    if (keyword.conversion) {
      this.setAdvanced(true)
      this.trackConversionStore.init(keyword.conversion)
    }
    this.initInput = this.keywordInput
  }

  setSMSPhones(phones: string[]) {
    this.smsPhones = phones
  }

  setKeywordsPairsMapData({
    getExistingKeywordPhoneNumbers,
  }: GetExistingKeywordPhoneNumbersQuery) {
    this.keywordsPairsMap = new Map(
      Object.entries(getExistingKeywordPhoneNumbers)
    )
  }
}
