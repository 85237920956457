import { makeAutoObservable, reaction } from 'mobx'
import { DropdownContactListsStore } from 'store/contacts/DropdownContactListsStore'
import contactStore from 'store/contacts/contactStore'
import CustomFieldsStore from 'store/contacts/customFields/customFieldsStore'
import { nanoid } from 'nanoid'
import {
  CampaignCreateRequestInput,
  CampaignUpdateRequestInput,
  Status,
} from 'src/generated/graphql'
import { ICampaign } from 'src/types/ICampaign'
import { CampaignMessageStore } from 'store/campaigns/campaignMessageStore'
import campaignsStore from 'store/campaigns/campaignsStore'
import uiStore from 'store/uiStore'
import { DayjsFormats } from 'lib/dayjs'
import { TrackConversionStore } from 'widgets/TrackConversion/store/trackConversionStore'

export class CreateCampaignStore {
  dropdownContactListsStore: DropdownContactListsStore
  customFieldsStore: typeof CustomFieldsStore
  contactStore: typeof contactStore
  campaignsStore: typeof campaignsStore
  trackConversionStore: TrackConversionStore
  constructor() {
    makeAutoObservable(this)
    this.dropdownContactListsStore = new DropdownContactListsStore()
    this.trackConversionStore = new TrackConversionStore()
    this.contactStore = contactStore
    this.customFieldsStore = CustomFieldsStore
    this.campaignsStore = campaignsStore

    reaction(
      () => this.campaignInput,
      (input) => {
        if (!this.initInput) {
          this.initInput = input
        }
        const initInputString = JSON.stringify(this.initInput)
        const inputString = JSON.stringify(input)
        uiStore.setBeforeUnload(initInputString !== inputString)
      }
    )
  }

  formatName = DayjsFormats.date

  element = 'Campaign'

  campaign: ICampaign | null = null

  name = ''
  createBtnText = 'Create campaign'
  messages: CampaignMessageStore[] = [new CampaignMessageStore()]

  createId = nanoid()
  activeCampaign = true
  status: Status | undefined = Status.Active

  edit = false
  duplicate = false
  editId = ''

  initInput: CampaignCreateRequestInput | null = null

  reEnter = false
  reEnrol = false

  get commonInput() {
    return {
      name: this.name,
      status: this.status,
      enabled: this.activeCampaign,
      listIds: this.dropdownContactListsStore.selectedContactListsIds,
      reEnrollEnabled: this.reEnrol,
      conversion: this.trackConversionStore.conversionInput,
    }
  }

  get campaignInput(): CampaignCreateRequestInput {
    return {
      ...this.commonInput,
      enrollExisting: false,
      stepCreateRequests: this.messages.map((message) => message.step),
    }
  }

  get campaignUpdateInput(): CampaignUpdateRequestInput {
    return {
      ...this.commonInput,
      id: this.campaign?.id,
      stepUpdateRequests: this.messages.map((message) => message.updateStep),
    }
  }

  get disabledCreate() {
    return (
      !(this.name && this.messages.length) ||
      !this.dropdownContactListsStore.selectedContactListsIds.length ||
      !!this.messages.find((message) => message.stepError)
    )
  }

  setName = (name: string) => {
    this.name = name
  }

  reset = () => {
    this.dropdownContactListsStore.onSelectOptions([])

    this.campaign = null

    this.name = ''
    this.createBtnText = 'Create campaign'
    this.messages = [new CampaignMessageStore()]

    this.createId = nanoid()
    this.activeCampaign = true
    this.status = Status.Active

    this.edit = false
    this.duplicate = false
    this.editId = ''
    this.reEnrol = false
  }

  setActiveCampaign = (state: boolean) => {
    this.status = state ? Status.Active : Status.Disabled
    this.activeCampaign = state
    this.messages.forEach((message) => {
      message.setActive(state)
    })
  }

  setEdit = (campaign: ICampaign, isDuplicate: boolean) => {
    this.messages = []
    this.campaign = campaign

    if (isDuplicate) {
      this.duplicate = true
      this.status = Status.Active
      this.activeCampaign = true
    } else {
      this.edit = true
      this.status = campaign.status
      this.activeCampaign = campaign.status !== Status.Disabled
    }

    this.editId = campaign.id

    this.name = `${campaign.name}${isDuplicate ? ' copy' : ''}`

    this.reEnrol = campaign.reEnrollEnabled

    if (campaign.campaignListResponses) {
      contactStore.addCampaignListResponse(campaign.campaignListResponses)
    }

    this.dropdownContactListsStore.setInitListsIds(campaign.contactsListsIds)

    campaign.campaignStepResponses?.forEach((step) => {
      if (step) {
        const newStore = new CampaignMessageStore()
        newStore.setStep(step)
        this.messages.push(newStore)
      }
    })
    if (campaign.conversion) {
      this.setAdvanced(true)
      this.trackConversionStore.init(campaign.conversion)
    }
    this.initInput = this.campaignInput
  }

  addMessage() {
    this.messages.push(new CampaignMessageStore())
  }
  duplicateMessage(messageStore: CampaignMessageStore) {
    const newMessage = new CampaignMessageStore()
    newMessage.setDuplicate(messageStore)
    this.messages.push(newMessage)
  }
  deleteMessage(id: string | number) {
    this.messages = this.messages.filter((message) => message.id !== id)
  }

  toggleReEnrol = () => {
    this.reEnrol = !this.reEnrol
  }

  isAdvanced = false
  setAdvanced = (value: boolean) => {
    this.isAdvanced = value
  }
  toggleAdvanced = () => {
    this.isAdvanced = !this.isAdvanced
  }
}

export default new CreateCampaignStore()
