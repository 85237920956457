import { SizedBox } from 'components/SizedBox'
import { Alert } from 'components/Alert/Alert'
import { observer } from 'mobx-react-lite'
import businessProfileStore from 'store/settings/company/businessProfileStore'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import a2p10DLCStore from 'store/settings/company/a2p10DLCStore'
import { Link } from 'react-router-dom'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { RoutesEnum } from 'src/pages/routes'
import { InputCheckboxCard } from 'components/Input/InputCheckboxCard/InputCheckboxCard'
import { Currencies, numberString } from 'src/util/functions'
import {
  StockExchange,
  TenDlcBusinessType,
  TenDlcPackageType,
  useInitPackageTenDlcMutation,
} from 'src/generated/graphql'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { IOption } from 'src/types/IOption'
import { InputText } from 'components/Input/InputText/InputText'
import { BackBtn } from 'src/pages/settings/compliance/registrations/A2P10DLCRegistration/steps/BackBtn'
import { useMemo } from 'react'
import {
  businessTypeMap,
  packageTypeMap,
} from 'store/settings/company/mockData'
import typesStore from 'store/typesStore'

const options: IOption[] = [
  {
    title: businessTypeMap[TenDlcBusinessType.Private],
    value: TenDlcBusinessType.Private,
  },
  {
    title: businessTypeMap[TenDlcBusinessType.Public],
    value: TenDlcBusinessType.Public,
  },
]

export const Step1 = observer(() => {
  const [initPackageTenDlc, { loading }] = useInitPackageTenDlcMutation()
  const onContinue = async () => {
    try {
      const { data } = await initPackageTenDlc({
        variables: {
          input: a2p10DLCStore.tenDlcSelectPackageRequestInput,
        },
      })
      if (data?.initPackageTenDlc?.profile) {
        a2p10DLCStore.setTenDlcProfile(data.initPackageTenDlc.profile)
      }
    } catch (e) {
      console.error(e)
    }
  }
  const onBusiness = () => {
    // businessProfileStore.setStep(2)
  }
  const isHaveEIN = businessProfileStore.isHaveEIN

  const optionsStocks: IOption[] = useMemo(() => {
    return Object.values(StockExchange).map((value) => ({
      title: typesStore.a2pStockExchangesMapping.get(value)
        ? `${value}: ${typesStore.a2pStockExchangesMapping.get(value)}`
        : value,
      value,
      p8: true,
      renderTitle: value,
    }))
  }, [typesStore.a2pStockExchangesMapping])
  return (
    <>
      <Alert
        text={
          'Please select the 10DLC registration package that fits your business. Call Loop passes through these fees at cost with no markup.'
        }
      />
      {!isHaveEIN && (
        <>
          <SizedBox height={12} />
          <Alert
            text={
              <>
                To get access to higher packages you need to have a United
                States EIN number. Add the EIN in{' '}
                <Link
                  to={`${RoutesEnum.settingsCompliance}/${RoutesEnum.business}`}
                  className={'par2'}
                  onClick={onBusiness}
                >
                  Business profile
                </Link>{' '}
                and resubmit your application. Once approved, you’ll be able to
                select “Low volume standard” or “Standard” brand package.
              </>
            }
            type={AlertTypeEnum.notify2}
          />
        </>
      )}
      <SizedBox height={40} />
      <div className={'col12'}>
        {!isHaveEIN && (
          <InputCheckboxCard
            label={packageTypeMap[TenDlcPackageType.SoleProprietor]}
            checked={
              a2p10DLCStore.selectedPackage === TenDlcPackageType.SoleProprietor
            }
            onChecked={() =>
              a2p10DLCStore.setSelectedPackage(TenDlcPackageType.SoleProprietor)
            }
            info1={'up to 1,000 SMS / MMS segments per day'}
            info2={'up to 1 MPS (Messages per second)'}
            tooltip={
              <>
                SMS segment - 160 characters <br />
                MMS segment - 1600 characters
              </>
            }
            rightContent={
              <h3>
                {numberString({
                  val: 19,
                  currency: Currencies.usd,
                  maximumFractionDigits: 0,
                })}
              </h3>
            }
          />
        )}
        <InputCheckboxCard
          label={packageTypeMap[TenDlcPackageType.LowVolumeStandard]}
          checked={
            a2p10DLCStore.selectedPackage ===
            TenDlcPackageType.LowVolumeStandard
          }
          onChecked={() =>
            a2p10DLCStore.setSelectedPackage(
              TenDlcPackageType.LowVolumeStandard
            )
          }
          disabled={!businessProfileStore.isHaveEIN}
          info1={'up to 2,000 SMS / MMS segments per day'}
          info2={'up to 3.75 MPS (Messages per second)'}
          tooltip={
            <>
              SMS segment - 160 characters <br />
              MMS segment - 1600 characters
            </>
          }
          rightContent={
            <h3>
              {numberString({
                val: 19,
                currency: Currencies.usd,
                maximumFractionDigits: 0,
              })}
            </h3>
          }
        />
        <InputCheckboxCard
          label={packageTypeMap[TenDlcPackageType.Standard]}
          checked={a2p10DLCStore.selectedPackage === TenDlcPackageType.Standard}
          onChecked={() =>
            a2p10DLCStore.setSelectedPackage(TenDlcPackageType.Standard)
          }
          disabled={!businessProfileStore.isHaveEIN}
          recommended
          info1={'> 2,000 SMS / MMS segments per day'}
          info2={'up to 225 MPS (Messages per second)'}
          tooltip={
            <>
              SMS segment - 160 characters <br />
              MMS segment - 1600 characters
            </>
          }
          rightContent={
            <h3>
              {numberString({
                val: 59,
                currency: Currencies.usd,
                maximumFractionDigits: 0,
              })}
            </h3>
          }
        />
      </div>
      <SizedBox height={40} />
      <Dropdown<TenDlcBusinessType>
        placeholder={'e.g. Private'}
        labelInput={'Business type'}
        options={options}
        selectedValue={a2p10DLCStore.businessType || undefined}
        onSelectValue={(val) => a2p10DLCStore.setSelectedBusinessType(val)}
      />
      {a2p10DLCStore.businessType === TenDlcBusinessType.Public && (
        <div className={'row12 mt24'}>
          <InputText
            label={'Company Stock Ticker Symbol'}
            placeholder={'e.g. XYZD'}
            value={a2p10DLCStore.companyStockTicker}
            onChangeValue={(val) => a2p10DLCStore.setCompanyStockTicker(val)}
          />
          <Dropdown<StockExchange>
            labelInput={'Stock exchange'}
            placeholder={'e.x. NASDAQ'}
            fullWidth
            options={optionsStocks}
            selectedValue={a2p10DLCStore.stockExchange || undefined}
            onSelectValue={(val) => a2p10DLCStore.setStockExchange(val)}
          />
        </div>
      )}
      <div className={'row12 mt40'}>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          onClick={onContinue}
          loading={loading}
          disabled={a2p10DLCStore.disabledSelectedPackage}
        >
          {a2p10DLCStore.continueText}
        </Button>
        <BackBtn />
      </div>
    </>
  )
})
