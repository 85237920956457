import contactStore from 'store/contacts/contactStore'
import { RoutesEnum } from 'src/pages/routes'
import {
  useCreateListMutation,
  useRemoveListsMutation,
  useUpdateListMutation,
} from 'src/generated/graphql'
import { useNavigate } from 'react-router-dom'
import { transformListingToList } from 'store/contacts/functions'
import { Dispatch, SetStateAction } from 'react'
import { nanoid } from 'nanoid'
import companyStore from 'store/settings/company/companyStore'

export function useListsFunctions() {
  const [createList] = useCreateListMutation()
  const [updateList] = useUpdateListMutation()
  const [removeList] = useRemoveListsMutation()
  const navigate = useNavigate()
  const onCreate = (
    name: string,
    setCreateListError?: Dispatch<SetStateAction<string>>
  ) => {
    createList({
      variables: {
        listInput: {
          name: name.trim(),
        },
      },
    })
      .then((res) => {
        if (res.data?.createList) {
          contactStore.addListing([res.data?.createList])
          navigate(
            RoutesEnum.contacts +
              '/' +
              RoutesEnum.lists +
              '/' +
              res.data?.createList.id
          )
        }
      })
      .catch((e) => {
        setCreateListError && setCreateListError(nanoid())
        console.error(e)
      })
  }

  const onUpdate = (
    id: string,
    name: string,
    setCreateListError?: Dispatch<SetStateAction<string>>
  ) => {
    updateList({
      variables: {
        listInput: {
          id: Number(id),
          name: name.trim(),
        },
      },
    })
      .then((res) => {
        res.data?.updateList &&
          contactStore.updateContactList(
            transformListingToList(res.data?.updateList)
          )
      })
      .catch((e) => {
        setCreateListError && setCreateListError(nanoid())
        console.error(e)
      })
  }
  const onExport = (ids: string[]) => {
    console.log('export', ids)
  }
  const onValidate = (ids: string[]) => {
    companyStore.setPhoneCheckerModal({
      state: true,
      listsValidateIds: ids.map((id) => +id),
    })
  }
  const onDeleteLists = async (listIds: string[]) => {
    try {
      await removeList({
        variables: {
          listIds,
        },
      })
      contactStore.tableStoreLists.onRefresh()
      listIds.forEach((id) => {
        contactStore.deleteContactList(id)
      })
    } catch (e) {
      console.error(e)
    }
  }

  return { onCreate, onUpdate, onExport, onDeleteLists, onValidate }
}
