import '@draft-js-plugins/inline-toolbar/lib/plugin.css'
import { AuthorizedAppContent } from 'src/app/AuthorizedApp/AuthorizedAppContent'
import { GlobalAlert } from 'components/Alert/GlobalAlert'
import { OAuth } from 'src/app/AuthorizedApp/OAuth'
import { CommonComponents } from 'src/app/AuthorizedApp/CommonComponents'

export const AuthorizedApp = () => (
  <>
    <GlobalAlert />
    <CommonComponents />
    <OAuth />
    <AuthorizedAppContent />
  </>
)
