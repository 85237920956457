import { SizedBox } from 'components/SizedBox'
import { Alert } from 'components/Alert/Alert'
import { AppIcon, IIconType } from 'components/AppIcon/AppIcon'
import { ColorsNames } from 'styles/variables'
import { ReactComponent as Check } from 'icons/20px/NoMask/Check.svg'
import { ReactComponent as Close } from 'icons/20px/NoMask/Close.svg'
import { observer } from 'mobx-react-lite'
import businessProfileStore from 'store/settings/company/businessProfileStore'
import { CardButton } from 'components/Button/CardButton/CardButton'
import { appLinks } from 'src/util/links'
import { PropsBusinessStep } from 'src/pages/settings/compliance/BusinessProfile/types'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import {
  BusinessProfileResponse,
  useAdminInitCompanyTypeMutation,
  useInitCompanyTypeMutation,
} from 'src/generated/graphql'

export const CompanyTypeContent = observer(
  ({ onCancel, loadingPrevious, onSuccess }: PropsBusinessStep) => {
    const { adminOrgId } = businessProfileStore
    const [initCompanyType, { loading }] = useInitCompanyTypeMutation()
    const [adminInitCompanyType, { loading: loadingAdmin }] =
      useAdminInitCompanyTypeMutation()
    const onContinue = async () => {
      try {
        let profile: BusinessProfileResponse | undefined | null
        if (adminOrgId) {
          const { data } = await adminInitCompanyType({
            variables: {
              hasEin: !!businessProfileStore.isHaveEIN,
              organizationId: adminOrgId,
            },
          })
          profile = data?.adminInitCompanyType
        } else {
          const { data } = await initCompanyType({
            variables: {
              hasEin: !!businessProfileStore.isHaveEIN,
            },
          })
          profile = data?.initCompanyType
        }

        if (profile) {
          onSuccess(profile)
        }
      } catch (e) {
        console.error(e)
      }
    }
    return (
      <>
        <Alert
          text={
            <p className={'par2'}>
              In order to send text messages in the United States, the carriers
              now require you to verify your account.{' '}
              <a
                href={appLinks.callLoopCompliance}
                target={'_blank'}
                rel="noreferrer"
              >
                Learn more.
              </a>
            </p>
          }
        />
        <SizedBox height={40} />
        <div className={'row16'}>
          <CardButton
            onClick={() => businessProfileStore.setIsHaveEIN(true)}
            description={
              <>
                I have a United States <br />
                EIN number
              </>
            }
            icon={
              <AppIcon type={IIconType.stroke} color={ColorsNames.success1}>
                <Check />
              </AppIcon>
            }
            active={!!businessProfileStore.isHaveEIN}
            positive
          />
          <CardButton
            onClick={() => businessProfileStore.setIsHaveEIN(false)}
            description={
              <>
                I don’t have a United States <br />
                EIN number
              </>
            }
            icon={
              <AppIcon type={IIconType.stroke} color={ColorsNames.error1}>
                <Close />
              </AppIcon>
            }
            active={businessProfileStore.isHaveEIN === false}
            negative
          />
        </div>
        <SizedBox height={40} />
        <div className={'row12'}>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.primary}
            onClick={onContinue}
            disabled={businessProfileStore.disabledCompanyType}
            loading={loading || loadingAdmin}
          >
            {businessProfileStore.continueText}
          </Button>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.secondaryGray}
            onClick={onCancel}
            loading={loadingPrevious}
          >
            {businessProfileStore.step === 1 ? 'Cancel' : 'Back'}
          </Button>
        </div>
      </>
    )
  }
)
