import { ReactComponent as DropIcon } from 'icons/16pxNoMask/Drag Drop.svg'
import styles from './styles.module.scss'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { useCreateWebFromContext } from 'src/pages/main/webForms/create/CreateWebformState'
import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'
import { Switch } from 'components/Switch/Switch'
import { SmallBtn } from 'components/Button/SmallBtn'
import { ActionsEnum } from 'components/Button/types'
import { SizedBox } from 'components/SizedBox'
import { AddCustomField } from 'src/pages/main/contacts/customFields/modals/CustomFieldsModal/AddCustomField'
import { ICustomField } from 'src/types/ICustomField'
import { IOption } from 'src/types/IOption'

interface Props {
  length: number
  field: ICustomField
}

export const FieldCollect = observer(({ field, length }: Props) => {
  const refDropdown = useRef<HTMLDivElement>(null)
  const { id, required, name, key } = field
  const createWebFormStore = useCreateWebFromContext()
  const onSelectOption = (opt: IOption) => {
    createWebFormStore.setFieldsCollect({
      ...field,
      name: String(opt.title) || '',
      key: opt.value,
      ...opt,
    })
  }
  const onRequired = (val: boolean, e: React.ChangeEvent) => {
    e.stopPropagation()
    createWebFormStore.setFieldsCollect({ ...field, required: val })
  }
  const onRemove = () => {
    createWebFormStore.removeCollectField(id)
  }
  return (
    <div className={styles.field}>
      <DropIcon />
      <div className={styles.fieldRight}>
        <div ref={refDropdown}>
          <Dropdown
            openTrigger={createWebFormStore.activeFilterId === field.id}
            options={createWebFormStore.fieldsOptions}
            maxHeightDropdown={176}
            TopDropdownButton={(props) => AddCustomField({ ...props })}
            rightBtn={
              key ? (
                <div className={styles.switch}>
                  <span className={'s2 gray1'}>Required</span>
                  <button onClick={(event) => event.stopPropagation()}>
                    <Switch
                      active={!!required}
                      disabled={key === 'phoneNumber'}
                      onChange={onRequired}
                    />
                  </button>
                </div>
              ) : (
                <></>
              )
            }
            selectedOption={
              key
                ? {
                    value: key,
                    title: (
                      <>
                        <span className={key === 'phoneNumber' ? 'gray1' : ''}>
                          {name}
                        </span>
                        {required && <span className={'error1'}>*</span>}
                      </>
                    ),
                  }
                : undefined
            }
            disabled={key === 'phoneNumber'}
            onSelect={onSelectOption}
          />
        </div>

        {key !== 'phoneNumber' && (
          <div className={styles.deleteWrap}>
            <SmallBtn type={ActionsEnum.delete2} onClick={onRemove} />
          </div>
        )}
        {key === 'phoneNumber' && length > 1 && <SizedBox width={44} />}
      </div>
    </div>
  )
})
