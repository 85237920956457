import { createContext, useContext } from 'react'
import { CreateBroadCastStore } from 'store/broadcasts/createBroadCastStore'

export const CreateBroadcastContext =
  createContext<CreateBroadCastStore | null>(null)

export function useCreateBroadcastContext() {
  const context = useContext(CreateBroadcastContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with CreateBroadcastContext'
    )

  return context
}
