import {
  CreateConversionDocument,
  CreateConversionMutation,
  CreateConversionMutationVariables,
  UpdateConversionDocument,
  UpdateConversionMutation,
  UpdateConversionMutationVariables,
} from 'src/generated/graphql'
import apiSore from 'store/apiSore'

export const ConversionApi = {
  create: (variables: CreateConversionMutationVariables) =>
    apiSore.client.mutate<
      CreateConversionMutation,
      CreateConversionMutationVariables
    >({
      mutation: CreateConversionDocument,
      variables,
    }),
  update: (variables: UpdateConversionMutationVariables) =>
    apiSore.client.mutate<
      UpdateConversionMutation,
      UpdateConversionMutationVariables
    >({
      mutation: UpdateConversionDocument,
      variables,
    }),
}
