import { SizedBox } from 'components/SizedBox'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import businessProfileStore from 'store/settings/company/businessProfileStore'
import { CompanyTypeContent } from 'src/pages/settings/compliance/BusinessProfile/steps/CompanyTypeContent'
import { RangeBar } from 'components/RangeBar/RangeBar'
import { GeneralInfoWithEIN } from 'src/pages/settings/compliance/BusinessProfile/steps/GeneralInfoWithEIN/GeneralInfoWithEIN'
import { BusinessInfo } from 'src/pages/settings/compliance/BusinessProfile/steps/BusinessInfo'
import { PeopleToContact } from 'src/pages/settings/compliance/BusinessProfile/steps/PeopleToContact/PeopleToContact'
import { BusinessTerms } from 'src/pages/settings/compliance/BusinessProfile/steps/BusinessTerms'
import { GeneralInfoWithoutEIN } from 'src/pages/settings/compliance/BusinessProfile/steps/GeneralInfoWithoutEIN'
import {
  BusinessProfileResponse,
  useAdminBusinessProfilePreviousStepMutation,
  useBusinessProfilePreviousStepMutation,
} from 'src/generated/graphql'
import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { CountryOfOperationsContent } from 'src/pages/settings/compliance/BusinessProfile/steps/CountryOfOperationsContent'
import { BusinessErrorAlert } from 'src/pages/settings/compliance/modals/components/BusinessErrorAlert'

export const BusinessProfileContent = observer(
  ({ isModal }: { isModal?: boolean }) => {
    const {
      adminOrgId,
      step,
      setFullBusinessProfile,
      setOpenEditModal,
      setHideErrorAlert,
      isUSOperation,
      isHaveEIN,
      isCanadaOperation,
      stepTitle,
      stepCount,
      progressPercent,
      isErrorAlert,
      hideErrorAlert,
    } = businessProfileStore
    const [businessProfilePrevious, { loading: loadingPrevious }] =
      useBusinessProfilePreviousStepMutation()
    const [adminBusinessProfilePrevious, { loading: loadingPreviousAdmin }] =
      useAdminBusinessProfilePreviousStepMutation()
    const navigate = useNavigate()
    const onSuccess = (
      businessProfileResponse: BusinessProfileResponse,
      finish?: boolean
    ) => {
      if (finish) {
        if (adminOrgId) {
          setOpenEditModal(false)
        } else {
          navigate(-1)
        }
      }
      setFullBusinessProfile(businessProfileResponse)
    }

    const onCancel = async () => {
      try {
        if (step === 1) {
          if (adminOrgId) {
            setOpenEditModal(false)
          } else {
            navigate(-1)
          }
        } else {
          let profile: BusinessProfileResponse | undefined | null
          if (adminOrgId) {
            const { data } = await adminBusinessProfilePrevious({
              variables: { organizationId: adminOrgId },
            })
            profile = data?.adminBusinessProfilePreviousStep
          } else {
            const { data } = await businessProfilePrevious()
            profile = data?.businessProfilePreviousStep
          }
          profile && setFullBusinessProfile(profile)
        }
      } catch (e) {
        console.error(e)
      }
    }
    const Content = useMemo(() => {
      if (step === 1) {
        return CountryOfOperationsContent
      }
      if (isUSOperation) {
        if (step === 2) {
          return CompanyTypeContent
        }
        if (isHaveEIN && step === 3) {
          return GeneralInfoWithEIN
        }
        if (isHaveEIN === false && step === 3) {
          return GeneralInfoWithoutEIN
        }
        if (step === 4) {
          return BusinessInfo
        }
        if (step === 5) {
          return PeopleToContact
        }
        if (step === 6) {
          return BusinessTerms
        }
      } else {
        if (step === 2) {
          return GeneralInfoWithoutEIN
        }
        if (step === 3) {
          return BusinessInfo
        }
        if (step === 4) {
          return PeopleToContact
        }
      }
    }, [
      step,
      isHaveEIN,
      isUSOperation,
      isCanadaOperation,
      loadingPrevious,
      loadingPreviousAdmin,
    ])
    useEffect(() => {
      setHideErrorAlert(false)
    }, [])
    return (
      <>
        {!isModal && (
          <>
            <span className={'gray1 letter1'}>Business profile</span>
            <SizedBox height={24} />
          </>
        )}

        <div className={'row-between'}>
          <h3>
            <b>{stepTitle}</b>
          </h3>
          <span className={'s2 medium gray1'}>
            Step {step} / {stepCount}
          </span>
        </div>
        <SizedBox height={16} />
        <RangeBar progress={progressPercent} style2 />
        {isErrorAlert && !hideErrorAlert && <BusinessErrorAlert />}
        <SizedBox height={step > 2 ? 40 : 32} />
        {Content && (
          <Content
            onCancel={onCancel}
            loadingPrevious={loadingPrevious || loadingPreviousAdmin}
            onSuccess={onSuccess}
          />
        )}
      </>
    )
  }
)
