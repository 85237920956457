import {
  BroadcastFullResponseFragment,
  BroadcastResponseFragment,
  RinglessMessageDetailsFragment,
  RinglessMessageInput,
  SmsMessage,
  SmsMessageRequestInput,
  TriggerFragmentFragment,
  VoiceMessageDetailsFragment,
  VoiceMessageInput,
} from 'src/generated/graphql'
import { Maybe } from 'graphql/jsutils/Maybe'

export const transformSMSToInput = (
  item: SmsMessage
): SmsMessageRequestInput => ({
  text: item.text || '',
  assetIds: item.assetIds,
  compliance: item.compliance,
  complianceText: item.complianceText,
  cardIds: item.cardIds,
})
export const transformVoiceMessageToInput = (
  item: VoiceMessageDetailsFragment
): VoiceMessageInput => ({
  liveAnswer: item.liveAnswer
    ? {
        ...item.liveAnswer,
        liveAnswer: undefined,
        audioId: item.liveAnswer?.liveAnswer?.id,
      }
    : undefined,
  voiceMail: item.voiceMail
    ? {
        ...item.voiceMail,
        voiceMail: undefined,
        audioId: item.voiceMail?.voiceMail?.id,
      }
    : undefined,
})
export const transformRinglessMessageToInput = (
  item: RinglessMessageDetailsFragment
): RinglessMessageInput => ({
  audioId: item.voiceMail?.id,
  callbackEnabled: item.callbackEnabled,
})

export const transformToInputs = (
  item:
    | TriggerFragmentFragment
    | BroadcastResponseFragment
    | BroadcastFullResponseFragment
): {
  smsMessageInput: Maybe<SmsMessageRequestInput>
  voiceMessageInput: Maybe<VoiceMessageInput>
  ringlessMessageInput: Maybe<RinglessMessageInput>
} => ({
  smsMessageInput: item.smsMessage
    ? {
        text: item.smsMessage.text || '',
        assetIds: item.smsMessage.assetIds,
        compliance: item.smsMessage.compliance,
        complianceText: item.smsMessage.complianceText,
        cardIds: item.smsMessage.cardIds,
      }
    : undefined,
  voiceMessageInput: item.voiceMessage
    ? {
        liveAnswer: item.voiceMessage.liveAnswer
          ? {
              ...item.voiceMessage.liveAnswer,
              liveAnswer: undefined,
              audioId: item.voiceMessage.liveAnswer?.liveAnswer?.id,
            }
          : undefined,
        voiceMail: item.voiceMessage.voiceMail
          ? {
              ...item.voiceMessage.voiceMail,
              voiceMail: undefined,
              audioId: item.voiceMessage.voiceMail?.voiceMail?.id,
            }
          : undefined,
      }
    : undefined,
  ringlessMessageInput: item.ringlessMessage
    ? {
        audioId: item.ringlessMessage.voiceMail?.id,
        callbackEnabled: item.ringlessMessage.callbackEnabled,
      }
    : undefined,
})
