import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import {
  CampaignResponse,
  useGetCampaignByIdLazyQuery,
} from 'src/generated/graphql'
import { transformCampaign } from 'store/campaigns/transformCampaign'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import { CreatePage } from 'components/Page/CreatePage'
import { NewCampaign } from 'src/pages/main/campaigns/create/createForm/NewCampaign'
import { PreviewCampaign } from 'src/pages/main/campaigns/create/preview/PreviewCampaign'
import createCampaignStore from 'store/campaigns/createCampaignStore'

export const EditCampaignPage = () => {
  const { pathname } = useLocation()
  const isDuplicate = pathname.includes('duplicate')
  const { id } = useParams()

  const [getCampaignById, { loading }] = useGetCampaignByIdLazyQuery()

  useEffect(() => {
    if (id) {
      getCampaignById({
        variables: {
          campaignId: +id,
        },
      })
        .then(({ data }) => {
          if (data?.getCampaignById) {
            const campaign = transformCampaign(
              data.getCampaignById as CampaignResponse
            )
            createCampaignStore.setEdit(campaign, isDuplicate)
          }
        })
        .catch((e) => console.error(e))
    }
  }, [id])

  if (loading) {
    return <AbsoluteLoader />
  }

  return (
    <CreatePage
      store={createCampaignStore}
      createElement={<NewCampaign />}
      previewElement={<PreviewCampaign />}
    />
  )
}
