import { makeAutoObservable, runInAction } from 'mobx'
import { IResponseVoice } from 'src/Modals/CreateTextToSpeechModal/store/type'
import axios, { AxiosResponse } from 'axios'
import { IAudioItem } from 'src/types/IAudioItem'
import {
  CreateTextToSpeechModalStore,
  ICreateTextToSpeechModalStoreProps,
} from 'src/Modals/CreateTextToSpeechModal/store/createTextToSpeechModalStore'

class CreateTextToSpeechStore {
  constructor() {
    makeAutoObservable(this)
  }

  createTextToSpeechModalStore: CreateTextToSpeechModalStore | null = null
  voices: IResponseVoice[] = []

  get openModal() {
    return Boolean(this.createTextToSpeechModalStore)
  }

  setOpenModal = (
    value: boolean,
    options?: ICreateTextToSpeechModalStoreProps['options']
  ) => {
    if (value) {
      this.createTextToSpeechModalStore = new CreateTextToSpeechModalStore({
        voices: this.voices,
        onClose: this.onClose,
        options,
      })
      if (value && !this.voices.length) {
        this.getVoices().then(() => {
          this.createTextToSpeechModalStore?.setVoices(this.voices)
        })
      }
    } else {
      this.createTextToSpeechModalStore = null
    }
  }
  onClose = () => {
    this.setOpenModal(false)
  }

  getVoices = async () => {
    try {
      const { data }: AxiosResponse<IResponseVoice[]> = await axios.get(
        'tts/voice'
      )
      runInAction(() => {
        if (data) {
          this.voices = data
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  onEditTextToSpeech = (audioItem: IAudioItem) => {
    this.setOpenModal(true, {
      editAudio: audioItem,
    })
  }
  onDuplicateTextToSpeech = (audioItem: IAudioItem) => {
    this.setOpenModal(true, {
      duplicateAudio: audioItem,
    })
  }
}

export default new CreateTextToSpeechStore()
