import styles from './styles.module.scss'
import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { SourceLink } from 'components/NewTable/cell/OptInRender/OptInRender'
import { RoutesEnum } from 'src/pages/routes'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { AppSymbols } from 'src/util/symbols'
import { AccountIntegrationType } from 'src/generated/graphql'

export type LinksRenderProps = {
  data: Array<{ id: string; name: string; app?: AccountIntegrationType }>
  source: RoutesEnum
  title: string
  variant2?: boolean
}

export const LinksRender = observer((props: LinksRenderProps) => {
  const { data, source, variant2 } = props
  if (variant2 && !data.length) {
    return <>{AppSymbols.dash}</>
  }
  return (
    <div className={styles.wrapLists}>
      {!!data.length && data[0] && !variant2 && (
        <SourceLink
          name={data[0].name || data[0].name || ''}
          id={String(data[0].id || data[0].id || '')}
          source={source}
        />
      )}
      {(variant2 ? data.length : data?.length > 1 && data[1]) && (
        <WithTooltip {...props} />
      )}
    </div>
  )
})

const WithTooltip = ({ data, title, source, variant2 }: LinksRenderProps) => {
  const { trigger, setTrigger, onTriggerClick, showTT } = useFixedTooltip()
  return (
    <>
      <FixedTooltip
        visible={showTT}
        trigger={trigger}
        white
        noArrow
        position={TTPositionEnum.bottomRight}
      >
        <div className={styles.wrapListsTooltip}>
          <span className={'s2 medium gray1 mb8 ml8'}>{title}</span>
          <div className={classNames(styles.wrapTags)}>
            {!!data.length &&
              (variant2 ? data : data.slice(1)).map(({ id, name, app }) => (
                <SourceLink
                  key={id}
                  name={name || ''}
                  id={id}
                  source={source}
                  variant2={variant2}
                  fromDropdown
                  integrationType={app}
                />
              ))}
          </div>
        </div>
      </FixedTooltip>
      {variant2 ? (
        <Button
          ref={setTrigger}
          typeBtn={BtnType.secondaryGray}
          size={BtnSize.mini}
          onClick={onTriggerClick}
          className={styles.countBtn}
          regular
        >
          {data.length}
        </Button>
      ) : (
        <button
          ref={setTrigger}
          onClick={onTriggerClick}
          className={styles.wrapPlus}
        >
          {`+${data.length - 1}`}
        </button>
      )}
    </>
  )
}
