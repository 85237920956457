import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { WrapPageSuperAdmin } from 'src/pages/superAdmin/components/WrapPageSuperAdmin/WrapPageSuperAdmin'
import { AddCreditsModal } from 'src/pages/superAdmin/modals/AddCreditsModal'
import { BanOrganizationModal } from 'src/pages/superAdmin/modals/BanOrganizationModal'
import { OrganizationsTable } from 'src/pages/superAdmin/routes/organizations/OrganizationTable/OrganizationsTable'
import { AddCallerIdModal } from 'src/pages/superAdmin/modals/AddCallerIdModal'

export const Organizations = observer(() => {
  return (
    <>
      <AddCreditsModal />
      <AddCallerIdModal />
      <BanOrganizationModal />
      <WrapPageSuperAdmin title={'Organizations'}>
        <OrganizationsTable />
      </WrapPageSuperAdmin>
    </>
  )
})
