import { createContext, useContext } from 'react'
import { ConversionsTableStore } from 'widgets/conversions/ConversionsTable/store/conversionsTableStore'

export const ConversionsTableContext =
  createContext<ConversionsTableStore | null>(null)

export const useConversionsTableContext = () => {
  const context = useContext(ConversionsTableContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with ConversionsTableContext'
    )

  return context
}
