// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Mo_1Va3r6ttm_JVGOH9f{display:grid;grid-template-columns:1fr 240px}.gG1Ahvmc1QTNX9DsIkT7{display:flex;column-gap:12px}.tQ8YB1WfDsiEGyhMzI2g{width:fit-content}`, "",{"version":3,"sources":["webpack://./src/pages/main/keywords/detail/KeywordDetailTable/LeftStickyContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,+BAAA,CAEF,sBACE,YAAA,CACA,eAAA,CAEF,sBACE,iBAAA","sourcesContent":[".row{\n  display: grid;\n  grid-template-columns: 1fr 240px;\n}\n.wrapActions{\n  display: flex;\n  column-gap: 12px;\n}\n.wrapRangePicker{\n  width: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `Mo_1Va3r6ttm_JVGOH9f`,
	"wrapActions": `gG1Ahvmc1QTNX9DsIkT7`,
	"wrapRangePicker": `tQ8YB1WfDsiEGyhMzI2g`
};
export default ___CSS_LOADER_EXPORT___;
