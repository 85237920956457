import { FC } from 'react'
import { ActionBtnProps } from 'components/NewTable/types'
import { ITrigger } from 'src/types/ITrigger'
import { getTriggerActionsByStatus } from 'src/pages/main/triggers/functions/getTriggerActionsByStatus'
import { triggerBtnByAction } from 'src/pages/main/triggers/functions/triggerBtnByAction'

export const getTriggerBtnActions = (
  row: ITrigger,
  fromLeftStickyContent?: boolean
): Array<FC<ActionBtnProps<ITrigger>>> => {
  const actions = getTriggerActionsByStatus(row.status)

  const actionsFC: Array<FC<ActionBtnProps<ITrigger>>> = []

  actions.forEach((action) => {
    const Btn: FC<ActionBtnProps<ITrigger>> | undefined =
      triggerBtnByAction[action as keyof typeof triggerBtnByAction]
    if (Btn) {
      actionsFC.push((props) => Btn({ ...props, row, fromLeftStickyContent }))
    }
  })
  return actionsFC
}
