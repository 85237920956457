// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eLIHwS_ViKzvwwfNOVK5{width:100%;padding-right:8px;display:flex;height:32px;align-items:center}`, "",{"version":3,"sources":["webpack://./src/components/CheckBoxBtn/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,iBAAA,CACA,YAAA,CACA,WAAA,CACA,kBAAA","sourcesContent":[".btn{\n  width: 100%;\n  padding-right: 8px;\n  display: flex;\n  height: 32px;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `eLIHwS_ViKzvwwfNOVK5`
};
export default ___CSS_LOADER_EXPORT___;
