import { makeAutoObservable } from 'mobx'
import {
  ChargeObject,
  ChargeObjectPricesInput,
  PlanPeriod,
  SimplePrice,
} from 'src/generated/graphql'
import { ISimplePrice } from 'src/types/ISimplePrice'
import modalStore from 'widgets/ModalContainer/store/modalStore'
import { CustomPlan } from 'src/pages/superAdmin/modals/CustomPlan/CustomPlan'
import configStore from 'store/configStore'

export class CustomPlanStore {
  constructor(
    public addPrice: (price: SimplePrice) => void,
    public updatePrice: (price: SimplePrice) => void,
    private price?: ISimplePrice
  ) {
    makeAutoObservable(this)
    if (price) {
      this.init(price)
    }
    this.openModal()
  }

  modalId = 'CustomPlanModal'

  get id() {
    return this.price?.id
  }

  get isEdit() {
    return !!this.price
  }

  get chargeObjectPrices(): ChargeObjectPricesInput | null {
    if (this.isCustomPrices) {
      const prices: ChargeObjectPricesInput['prices'] = []
      this.customPricesMap.forEach((value, key) => {
        if (value) {
          prices.push({
            chargeObject: key,
            creditPrice: value,
          })
        }
      })
      return { prices }
    }
    return null
  }

  init = (price: ISimplePrice) => {
    this.setName(price.planName || '')
    this.setDescription(price.planDescription || '')
    this.setCredits(price.creditsIncluded || 0)
    this.setPriceValue(price.priceValue || 0, price.creditsIncluded || 0)

    this.setExtraCreditPrice(price.additionalCreditPrice || 0)
    this.setPeriod(
      (price.period?.toUpperCase() as PlanPeriod) || PlanPeriod.Monthly
    )
    if (price.customObjectPrices?.length) {
      this.isCustomPrices = true
      price.customObjectPrices.forEach((price) => {
        if (price?.chargeObject) {
          this.customPricesMap.set(price.chargeObject, price.creditPrice)
        }
      })
    }
  }

  openModal = () => {
    modalStore.addModal({
      id: this.modalId,
      onClose: this.onClose,
      title: (this.isEdit ? 'Edit' : 'New') + ' custom plan',
      children: <CustomPlan store={this} />,
    })
  }

  onClose = () => {
    modalStore.removeModal(this.modalId)
  }

  name = ''
  setName = (value: string): void => {
    this.name = value
  }

  description = ''
  setDescription = (value: string): void => {
    this.description = value
  }

  credits = 0
  setCredits = (value: number): void => {
    this.credits = value
  }

  priceValue = 0
  setPriceValue = (value: number, propsCredits?: number): void => {
    this.priceValue = value
    const credits = propsCredits || this.credits
    if (credits) {
      this.costCredit = Number((this.priceValue / credits).toFixed(4))
    }
  }

  costCredit = 0
  setCostCredit = (value: number): void => {
    this.costCredit = value
    const credits = this.credits
    if (credits) {
      this.priceValue = Number((credits * value).toFixed(4))
    }
  }

  period = PlanPeriod.Monthly
  setPeriod = (value: PlanPeriod): void => {
    this.period = value
  }

  extraCreditPrice = 0
  setExtraCreditPrice = (value: number): void => {
    this.extraCreditPrice = value
  }

  isCustomPrices = false
  setIsCustomPrices = (value: boolean) => {
    this.isCustomPrices = value
  }

  customPricesMap: Map<ChargeObject, number> = new Map()

  getCustomPriceField = (type: ChargeObject, title: string) => {
    return {
      type,
      value: this.customPricesMap.get(type),
      setValue: (value: number) => this.customPricesMap.set(type, value),
      placeholder: configStore.defaultChargeMapPrices.get(type),
      title,
    }
  }

  get customPriceFields() {
    return [
      this.getCustomPriceField(ChargeObject.Sms, `SMS cost, credit`),
      this.getCustomPriceField(ChargeObject.Mms, `MMS cost, credit`),
      this.getCustomPriceField(ChargeObject.Call, `Call cost, credit`),
      this.getCustomPriceField(ChargeObject.Ringless, `Ringless cost, credit`),
    ]
  }
}
