// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.i6o5Qwqb6Ao7hyx594jq{display:grid;grid-template-columns:152px 304px;column-gap:8px}.i6o5Qwqb6Ao7hyx594jq.Vxxla9MJBJ6NIgdMtFWt{align-items:center}`, "",{"version":3,"sources":["webpack://./src/pages/settings/compliance/modals/components/Row/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,iCAAA,CACA,cAAA,CACA,2CACE,kBAAA","sourcesContent":[".wrap{\n  display: grid;\n  grid-template-columns: 152px 304px;\n  column-gap: 8px;\n  &.center{\n    align-items: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `i6o5Qwqb6Ao7hyx594jq`,
	"center": `Vxxla9MJBJ6NIgdMtFWt`
};
export default ___CSS_LOADER_EXPORT___;
