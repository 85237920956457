import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import { RoutesEnum } from 'src/routes/routes'
import mainStore from 'store/mainStore'
import styles from './styles.module.scss'
import { AudioCard } from 'components/CreateElement/AudioCard/AudioCard'
import classNames from 'classnames'
import { ReactComponent as Plus } from 'icons/16px/Plus.svg'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'
import { observer } from 'mobx-react-lite'
import { IAudioItem } from 'src/types/IAudioItem'

export const AddAudioBtn = observer(
  ({
    audioFile,
    onRemove,
    onAddAudio,
    from,
  }: {
    audioFile: IAudioItem | null
    onRemove: () => void
    onAddAudio: () => void
    from: string
    disabledAddAudio?: boolean
  }) => {
    const { pathname } = useLocation()
    const disabledAddAudio = useMemo(
      () => pathname === RoutesEnum.newTrigger && !mainStore.isSelectTriggerApp,
      [mainStore.isSelectTriggerApp, pathname]
    )
    const fromRingless = from === 'ringless'
    return audioFile ? (
      <div
        className={classNames(
          styles.wrapAudioCard,
          fromRingless && styles.fromRingless
        )}
      >
        <AudioCard audio={audioFile} onRemove={onRemove} from={from} />
      </div>
    ) : (
      <button
        onClick={onAddAudio}
        disabled={disabledAddAudio}
        className={classNames(
          styles.plusBtn,
          disabledAddAudio && styles.disabledBtn,
          fromRingless && styles.fromRingless
        )}
      >
        <Plus />
        <span className={'bold'}>
          Add audio
          {disabledAddAudio && (
            <InfoTooltip
              title={'To add an audio you must first select the trigger app'}
              width={197}
            />
          )}
        </span>
      </button>
    )
  }
)
