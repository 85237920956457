import { ReactComponent as Account } from 'icons/defaults/Account.svg'
import { ReactComponent as ActiveCampaign } from 'icons/defaults/ActiveCampaign.svg'
import { ReactComponent as Ai } from 'icons/defaults/Ai.svg'
import { ReactComponent as Alert } from 'icons/defaults/Alert.svg'
import { ReactComponent as AngleDown } from 'icons/defaults/Angle-Down.svg'
import { ReactComponent as AngleLeft } from 'icons/defaults/Angle-Left.svg'
import { ReactComponent as AngleRight } from 'icons/defaults/Angle-Right.svg'
import { ReactComponent as AngleUp } from 'icons/defaults/Angle-Up.svg'
import { ReactComponent as ArrowDown } from 'icons/defaults/Arrow-Down.svg'
import { ReactComponent as ArrowExpand } from 'icons/defaults/arrow-expand.svg'
import { ReactComponent as Audio } from 'icons/defaults/Audio.svg'
import { ReactComponent as AWeber } from 'icons/defaults/AWeber.svg'
import { ReactComponent as BackCircle } from 'icons/defaults/BackCircle.svg'
import { ReactComponent as Billing } from 'icons/defaults/Billing & Usage.svg'
import { ReactComponent as Broadcasts } from 'icons/defaults/Broadcasts.svg'
import { ReactComponent as Calendar } from 'icons/defaults/Calendar.svg'
import { ReactComponent as CampaignLogo } from 'icons/defaults/CampaignLogo.svg'
import { ReactComponent as Campaigns } from 'icons/defaults/Campaigns.svg'
import { ReactComponent as Chat } from 'icons/defaults/Chat.svg'
import { ReactComponent as Check } from 'icons/defaults/Check.svg'
import { ReactComponent as CheckCircle } from 'icons/defaults/CheckCircle.svg'
import { ReactComponent as Clock } from 'icons/defaults/Clock.svg'
import { ReactComponent as Close } from 'icons/defaults/Close.svg'
import { ReactComponent as Code } from 'icons/defaults/Code.svg'
import { ReactComponent as Company } from 'icons/defaults/Company.svg'
import { ReactComponent as CloseCircle } from 'icons/defaults/CloseCircle.svg'
import { ReactComponent as CloseCustom } from 'icons/defaults/CloseCustom.svg'
import { ReactComponent as ContactsFill } from 'icons/defaults/ContactsFill.svg'
import { ReactComponent as Conversion } from 'icons/defaults/Conversion.svg'
import { ReactComponent as DateTime } from 'icons/defaults/DateTime.svg'
import { ReactComponent as Delete } from 'icons/defaults/Delete.svg'
import { ReactComponent as Deliver } from 'icons/defaults/Deliver.svg'
import { ReactComponent as Draft } from 'icons/defaults/Draft.svg'
import { ReactComponent as Drip } from 'icons/defaults/Drip.svg'
import { ReactComponent as Edit } from 'icons/defaults/Edit.svg'
import { ReactComponent as EmailInvite } from 'icons/defaults/Email Invite.svg'
import { ReactComponent as Fallback } from 'icons/defaults/Fallback.svg'
import { ReactComponent as File } from 'icons/defaults/File.svg'
import { ReactComponent as GoHighLevel } from 'icons/defaults/GoHighLevel.svg'
import { ReactComponent as GravityForms } from 'icons/defaults/Gravity Forms.svg'
import { ReactComponent as HubSpotLogo } from 'icons/defaults/HubSpotLogo.svg'
import { ReactComponent as Info1 } from 'icons/defaults/Info-1.svg'
import { ReactComponent as Integrations } from 'icons/defaults/Integrations.svg'
import { ReactComponent as KeapLogo } from 'icons/defaults/KeapLogo.svg'
import { ReactComponent as Leadpages } from 'icons/defaults/Leadpages.svg'
import { ReactComponent as Link } from 'icons/defaults/Link.svg'
import { ReactComponent as Logo } from 'icons/defaults/logo.svg'
import { ReactComponent as Mailchimp } from 'icons/defaults/Mailchimp.svg'
import { ReactComponent as Media } from 'icons/defaults/Media.svg'
import { ReactComponent as Microphone } from 'icons/defaults/Microphone.svg'
import { ReactComponent as Minus } from 'icons/defaults/Minus.svg'
import { ReactComponent as MinusCircle } from 'icons/defaults/MinusCircle.svg'
import { ReactComponent as Missed } from 'icons/defaults/Missed.svg'
import { ReactComponent as Number } from 'icons/defaults/Number.svg'
import { ReactComponent as Pause } from 'icons/defaults/Pause.svg'
import { ReactComponent as Phone } from 'icons/defaults/Phone.svg'
import { ReactComponent as Play } from 'icons/defaults/Play.svg'
import { ReactComponent as Plus } from 'icons/defaults/Plus.svg'
import { ReactComponent as Plus12 } from 'icons/defaults/Plus12.svg'
import { ReactComponent as Power } from 'icons/defaults/Power.svg'
import { ReactComponent as Question } from 'icons/defaults/Question.svg'
import { ReactComponent as Recording } from 'icons/defaults/Recording.svg'
import { ReactComponent as Schedule } from 'icons/defaults/Schedule.svg'
import { ReactComponent as Search } from 'icons/defaults/Search.svg'
import { ReactComponent as Segments } from 'icons/defaults/Segments.svg'
import { ReactComponent as Settings } from 'icons/defaults/Settings.svg'
import { ReactComponent as Shield } from 'icons/defaults/Shield.svg'
import { ReactComponent as Star } from 'icons/defaults/Star.svg'
import { ReactComponent as Status } from 'icons/defaults/Status.svg'
import { ReactComponent as Stripe } from 'icons/defaults/Stripe.svg'
import { ReactComponent as Text } from 'icons/defaults/Text.svg'
import { ReactComponent as TextToSpeech } from 'icons/defaults/Text to speech.svg'
import { ReactComponent as Tick } from 'icons/defaults/Tick.svg'
import { ReactComponent as TollFree } from 'icons/defaults/Toll Free.svg'
import { ReactComponent as Triggers } from 'icons/defaults/Triggers.svg'
import { ReactComponent as Typeform } from 'icons/defaults/Typeform.svg'
import { ReactComponent as Unbounce } from 'icons/defaults/Unbounce.svg'
import { ReactComponent as Undo } from 'icons/defaults/Undo.svg'
import { ReactComponent as User } from 'icons/defaults/User.svg'
import { ReactComponent as UpArrow } from 'icons/defaults/UpArrow.svg'
import { ReactComponent as UserSwittch } from 'icons/defaults/UserSwitch.svg'
import { ReactComponent as Warning } from 'icons/defaults/Warning.svg'
import { ReactComponent as XSmall } from 'icons/defaults/X-Small.svg'
import { ReactComponent as Zapier } from 'icons/defaults/zapier-logo_black.svg'

export const icons = {
  account: <Account />,
  activeCampaign: <ActiveCampaign />,
  ai: <Ai />,
  alert: <Alert />,
  angleDown: <AngleDown />,
  arrowDown: <ArrowDown />,
  arrowExpand: <ArrowExpand />,
  audio: <Audio />,
  angleLeft: <AngleLeft />,
  angleRight: <AngleRight />,
  angleUp: <AngleUp />,
  backCircle: <BackCircle />,
  billing: <Billing />,
  code: <Code />,
  company: <Company />,
  delete: <Delete />,
  deliver: <Deliver />,
  draft: <Draft />,
  question: <Question />,
  broadcasts: <Broadcasts />,
  campaigns: <Campaigns />,
  chat: <Chat />,
  check: <Check />,
  checkCircle: <CheckCircle />,
  clock: <Clock />,
  close: <Close />,
  closeCircle: <CloseCircle />,
  closeCustom: <CloseCustom />,
  triggers: <Triggers />,
  tick: <Tick />,
  tollFree: <TollFree />,
  plus: <Plus />,
  plus12: <Plus12 />,
  power: <Power />,
  text: <Text />,
  number: <Number />,
  dateTime: <DateTime />,
  calendar: <Calendar />,
  campaignLogo: <CampaignLogo />,
  link: <Link />,
  logo: <Logo />,
  missed: <Missed />,
  contactsFill: <ContactsFill />,
  conversion: <Conversion />,
  user: <User />,
  upArrow: <UpArrow />,
  userSwitch: <UserSwittch />,
  file: <File />,
  goHighLevel: <GoHighLevel />,
  info1: <Info1 />,
  integrations: <Integrations />,
  keapLogo: <KeapLogo />,
  textToSpeech: <TextToSpeech />,
  recording: <Recording />,
  segments: <Segments />,
  settings: <Settings />,
  shield: <Shield />,
  star: <Star />,
  status: <Status />,
  play: <Play />,
  pause: <Pause />,
  phone: <Phone />,
  warning: <Warning />,
  xSmall: <XSmall />,
  mailchimp: <Mailchimp />,
  media: <Media />,
  microphone: <Microphone />,
  minus: <Minus />,
  minusCircle: <MinusCircle />,
  typeform: <Typeform />,
  aWeber: <AWeber />,
  drip: <Drip />,
  edit: <Edit />,
  emailInvite: <EmailInvite />,
  fallback: <Fallback />,
  unbounce: <Unbounce />,
  undo: <Undo />,
  gravityForms: <GravityForms />,
  hubSpotLogo: <HubSpotLogo />,
  schedule: <Schedule />,
  search: <Search />,
  stripe: <Stripe />,
  leadpages: <Leadpages />,
  zapier: <Zapier />,
}
