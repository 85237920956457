import { BtnSize, BtnType, Button } from 'components/Button/Button'
import styles from 'src/pages/main/broadcasts/broadcastDetail/header/styles.module.scss'
import { ReactComponent as Eye } from 'icons/16px/Eye.svg'
import { ClassesEnum } from 'styles/classes'
import { Modal } from 'components/Modal/Modal'
import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useWebFormDetailContext } from 'src/pages/main/webForms/detail/webFormDetailPage'
import { DetailsModalContent } from 'components/DetailsModalContent/DetailsModalContent'

export const DetailsBtn = observer(() => {
  const { webForm } = useWebFormDetailContext()
  const [open, setOpen] = useState(false)
  const onClose = () => {
    setOpen(false)
  }
  const onOpen = () => {
    setOpen(true)
  }
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        title={'Details'}
        minWidth={526}
        topRight
      >
        {webForm && <DetailsModalContent element={webForm} />}
      </Modal>
      <Button
        size={BtnSize.medium}
        typeBtn={BtnType.secondaryGray}
        className={styles.btn}
        onClick={onOpen}
      >
        <Eye />
        <span className={ClassesEnum.bold}>Details</span>
      </Button>
    </>
  )
})
