import { SizedBox } from 'components/SizedBox'
import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import { InputText } from 'components/Input/InputText/InputText'
import { ReactNode, useEffect, useState } from 'react'
import { IOption } from 'src/types/IOption'
import { RangeBar } from 'components/RangeBar/RangeBar'
import classNames from 'classnames'

const options: IOption[] = [
  {
    value: '1',
    title: 'Just me',
  },
  {
    value: '2-5',
    title: '2-5',
  },
  {
    value: '6-20',
    title: '6-20',
  },
  {
    value: '21-50',
    title: '21-50',
  },
  {
    value: '51-200',
    title: '51-200',
  },
  {
    value: '200+',
    title: '200+',
  },
]

const title = 'Tell us about your company'

type RegisterOrganizationUiProps = {
  isLargeTitle?: boolean
  loading?: boolean
  onContinue: (companyName: string, employees: string | null) => void
  btnPrimaryText: string
  btnPrimarySize: BtnSize
  bottomContent?: ReactNode
  progress?: number
  initCompanyName?: string
  actionClassName?: string
  additionalContent?: ReactNode
}

export const RegisterOrganization = ({
  isLargeTitle,
  onContinue,
  loading,
  btnPrimaryText,
  btnPrimarySize,
  bottomContent,
  progress = 100,
  initCompanyName = '',
  actionClassName,
  additionalContent,
}: RegisterOrganizationUiProps) => {
  const [companyName, setCompany] = useState(initCompanyName)
  const [employees, setEmployees] = useState(null)
  useEffect(() => {
    if (initCompanyName) {
      setCompany(initCompanyName)
    }
  }, [initCompanyName])
  return (
    <>
      {isLargeTitle ? (
        <h3 className={'bold'}>{title}</h3>
      ) : (
        <>
          <h2>{title}</h2>
          <SizedBox height={8} />
          <p className={'par2 gray1 medium mb20'}>
            This will help customize the app for you
          </p>
          <RangeBar progress={progress} style2 />
        </>
      )}
      <SizedBox height={32} />
      <InputText
        placeholder={'e.g Grower'}
        label={'Company name'}
        value={companyName}
        onChange={(event) => setCompany(event.target.value)}
        onClickRightIcon={() => setCompany('')}
        required
        focusOnMount
      />
      <SizedBox height={32} />

      <div className={styles.deliverText}>
        How many people work at your company?
      </div>
      <SizedBox height={8} />
      <div className={styles.deliverWrap}>
        {options.map((opt) => (
          <InputCheckbox
            key={opt.value}
            label={String(opt.title)}
            checked={employees === opt.value}
            onChecked={() => setEmployees(opt.value)}
            shape={'radio'}
            size={'small'}
          />
        ))}
      </div>
      {additionalContent}
      <div className={classNames('mt40', actionClassName)}>
        <Button
          size={btnPrimarySize}
          typeBtn={BtnType.primary}
          onClick={() => onContinue(companyName.trim(), employees)}
          loading={loading}
          disabled={!companyName.trim()}
        >
          {btnPrimaryText}
        </Button>
        {bottomContent}
      </div>
    </>
  )
}
