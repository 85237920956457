import styles from './styles.module.scss'
import { useLocation } from 'react-router-dom'
import { LinkWrapper } from 'components/Wrappers/LinkWrapper'
import classNames from 'classnames'
import { DashboardNav } from 'components/NavBar/components/DashboardNav'
import { SettingsNav } from 'components/NavBar/components/SettingsNav'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { Logout } from 'components/NavBar/components/Logout'
import { appLinks } from 'src/util/links'
import { AppIcon } from 'components/AppIcon/AppIcon'
import { ColorsNames } from 'styles/variables'
import { Separator } from 'components/Separator/Separator'
import { RoutesEnum } from 'src/routes/routes'
import { INavItem, NavType } from 'components/NavBar/types'
import { NewLabel } from 'src/shared/NewLabel/NewLabel'
import { observer } from 'mobx-react-lite'
import { SwitchOrganization } from 'src/routes/main/newOrganization/components/navbarItem/SwitchOrganization/SwitchOrganization'
import { Typography } from 'shared/Typography'

interface Props {
  disabled?: boolean
  navItem: INavItem
}

export const NavItem = observer(
  ({
    disabled,
    navItem: {
      text,
      LeftIcon,
      // RightIcon,
      type,
      route,
      // rightIconRoute,
      marginBottom,
      separatorMargin,
      noMask,
      iconType,
      separator,
      separatorBottom,
      rightContent,
      isNew,
    },
  }: Props) => {
    const location = useLocation()
    const isActive = route && location.pathname.includes(route)
    let content
    switch (type) {
      case NavType.create: {
        content = (
          <LinkWrapper to={route}>
            <Button size={BtnSize.large} typeBtn={BtnType.primary}>
              {text}
            </Button>
          </LinkWrapper>
        )
        break
      }
      case NavType.dashboard: {
        content = <DashboardNav />
        break
      }
      case NavType.settings: {
        content = <SettingsNav text={text} />
        break
      }
      case NavType.switchOrg: {
        content = <SwitchOrganization />
        break
      }
      case NavType.logout: {
        content = <Logout />
        break
      }
      case NavType.help: {
        content = (
          <a
            target={'_blank'}
            href={appLinks.callLoopSupport}
            rel="noreferrer"
            className={'full-height flex'}
          >
            <div className={classNames(styles.navLink)}>
              <div className={'flex'}>
                <div className={styles.leftIcon}>
                  {LeftIcon && <LeftIcon />}
                </div>
                <Typography>{text}</Typography>
              </div>
            </div>
          </a>
        )
        break
      }
      case NavType.productUpdates: {
        content = (
          <button data-canny-changelog className={'full-height'}>
            <div className={classNames(styles.navLink)}>
              <div className={'flex'}>
                <div className={styles.leftIcon}>
                  {LeftIcon && <LeftIcon />}
                </div>
                <Typography>{text}</Typography>
              </div>
            </div>
          </button>
        )
        break
      }
      default: {
        content = (
          <div
            className={classNames(
              styles.navItemContent,
              styles[type],
              isActive && styles.active
            )}
          >
            <LinkWrapper
              to={route === RoutesEnum.dashboard ? undefined : route}
            >
              <div className={styles.wrapNavItem}>
                <div className={styles.leftIcon}>
                  {LeftIcon && noMask ? (
                    <AppIcon
                      type={iconType}
                      color={isActive ? ColorsNames.blue1 : ColorsNames.gray1}
                    >
                      <LeftIcon />
                    </AppIcon>
                  ) : (
                    LeftIcon && <LeftIcon />
                  )}
                </div>
                <Typography>{text}</Typography>
                {isNew && <NewLabel />}
                {rightContent}
              </div>
            </LinkWrapper>

            {/*<LinkWrapper to={rightIconRoute}>*/}
            {/*  {RightIcon && (*/}
            {/*    <div className={styles.rightIcon}>*/}
            {/*      {RightIcon && <RightIcon />}*/}
            {/*    </div>*/}
            {/*  )}*/}
            {/*</LinkWrapper>*/}
          </div>
        )
      }
    }
    return (
      <>
        {separator && <Separator marginBlock={separatorMargin || 12} />}
        <div
          className={classNames(
            styles.navItem,
            isActive && styles.active,
            disabled && styles.disabled
          )}
          style={{ marginBottom }}
        >
          {content}
        </div>
        {separatorBottom && <Separator marginBlock={separatorMargin || 12} />}
      </>
    )
  }
)
