import { observer } from 'mobx-react-lite'
import { useTrackConversionContext } from 'widgets/TrackConversion/context/context'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { SwitchCard } from 'widgets/TrackConversion/ui/SwitchCard/SwitchCard'
import React from 'react'
import { ConversionSelect } from 'widgets/TrackConversion/ui/ConversionSelect/ConversionSelect'

export const TrackConversionUi = observer(() => {
  const { isEnabled } = useTrackConversionContext()
  return (
    <div className={classNames(styles.wrap, { [styles.active]: isEnabled })}>
      <SwitchCard />
      {isEnabled && <ConversionSelect />}
    </div>
  )
})
