import styles from './styles.module.scss'
import { NavBar } from 'components/NavBar/NavBar'
import { Outlet } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import uiStore from 'store/uiStore'
import { AppModals } from 'src/layout/AppModals'
import { useIsDowngrade } from 'src/pages/settings/billing/hooks/useIsDowngrade'
import { useIsCancelSubscription } from 'src/pages/settings/billing/hooks/useIsCancelSubscription'
import { useLoadingHistory } from 'src/pages/main/contacts/hooks/useLoadingHistory'
import { useSetMember } from 'src/pages/settings/account/hooks/useSetMember'
import { useBeforeUnload } from 'src/hooks/useBeforeUnload'
import { MobileBar } from 'components/NavBar/components/MobileBar/MobileBar'
import { useCanny } from 'src/hooks/useCanny'
import { useSetFullTollFreeProfile } from 'src/pages/settings/compliance/TollFreeVerification/hooks/useSetFullTollFreeProfile'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'
import { useSetIntegrations } from 'src/pages/settings/integrations/hooks/useSetIntegrations'
import integrationsStore from 'store/settings/integrationsStore'
import { useSetTypes } from 'src/pages/settings/company/hooks/useSetTypes'
import { useSetConfig } from 'src/hooks/useSetConfig'
import { MainComponents } from 'src/app/AuthorizedApp/MainComponents'
import { useHubspotRegisterCompany } from 'src/hooks/useHubspotRegisterCompany'
import auth from 'store/auth'
import { ModalContainer } from 'src/widgets/ModalContainer/ModalContainer'
import { Page } from 'shared/Page/Page'

export const AppLayout = observer(() => {
  useBeforeUnload()
  useSetMember(true)
  useIsDowngrade()
  useIsCancelSubscription()
  useLoadingHistory()
  useCanny()
  useSetFullTollFreeProfile(tollFreeVerificationStore.initLoaded)
  useSetIntegrations(integrationsStore.initLoading)
  useSetTypes()
  useSetConfig()
  useHubspotRegisterCompany(auth.completeRegisterTrigger)

  return (
    <div className={styles.wrap}>
      {!uiStore.isMobile && <NavBar />}
      <Page>
        {uiStore.isMobile && <MobileBar />}
        <Outlet />
      </Page>
      <ModalContainer />
      <AppModals />
      <MainComponents />
    </div>
  )
})
