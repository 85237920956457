import { Filters } from './Filters/Filters'
import NewTable from 'components/NewTable/NewTable'
import { useEffect, useState } from 'react'
import { IContactImportState } from 'src/pages/main/contacts/importHistory/ImportHistoryTable/types'
import { useGetImportHistoryQuery } from 'src/generated/graphql'
import { observer } from 'mobx-react-lite'
import importContactStore from 'store/contacts/importContactStore'
import { EmptyStateContacts } from 'src/pages/main/contacts/ContactsTable/EmptyStateContacts'

export const ImportHistoryTable = observer(() => {
  const [fistLoading, setFirstLoading] = useState(true)
  const [refetchLoading, setRefetchLoading] = useState(false)

  const { data, refetch, loading } = useGetImportHistoryQuery({
    variables: {
      page: importContactStore.tableStore.paginationInput,
    },
  })

  useEffect(() => {
    if (data?.getImportHistory) {
      setFirstLoading(false)
      importContactStore.setIContactImportStateResponse(data)
    }
  }, [data])

  useEffect(() => {
    setRefetchLoading(true)
    refetch().finally(() => setRefetchLoading(false))
  }, [importContactStore.tableStore.refreshTrigger])

  const rows = importContactStore.iContactImportState

  return !fistLoading && !rows.length ? (
    <EmptyStateContacts onlyImport />
  ) : (
    <NewTable<IContactImportState>
      withCheckbox
      columns={importContactStore.tableStore.visibleColumns}
      rows={rows}
      leftStickyContent={<Filters />}
      // withSearch
      withCalcMaxHeight
      tableStore={importContactStore.tableStore}
      firstLoading={fistLoading}
      pageLoading={loading || refetchLoading}
    />
  )
})
