import { BusinessProfileContent } from 'src/pages/settings/compliance/BusinessProfile/BusinessProfileContent'
import { CompanyPage } from 'components/Page/CompanyPage/CompanyPage'
import { useSetFullBusinessProfile } from 'src/pages/settings/compliance/BusinessProfile/hooks/useSetFullBusinessProfile'

export const BusinessProfile = () => {
  const { loading } = useSetFullBusinessProfile()
  return (
    <CompanyPage loading={loading}>
      <BusinessProfileContent />
    </CompanyPage>
  )
}
