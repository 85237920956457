import { StatusContent } from 'components/StatusContent/StatusContent'
import {
  BusinessProfileStatus,
  Status,
  TollFreeVerificationStatus,
  useAdminGetFullBusinessProfileLazyQuery,
  useAdminGetFullTollFreeVerificationProfileLazyQuery,
} from 'src/generated/graphql'
import { ColumnType } from 'components/NewTable/types'
import { IOrganizationComplianceResponse } from 'src/types/IOrganizationComplianceResponse'
import { CustomStatuses } from 'components/StatusContent/types'
import dayjs, { DayjsFormats } from 'lib/dayjs'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'
import { observer } from 'mobx-react-lite'
import typesStore from 'store/typesStore'
import businessProfileStore from 'store/settings/company/businessProfileStore'
import { Link } from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'

export const superAdminComplianceColumns: ColumnType<IOrganizationComplianceResponse>[] =
  [
    {
      id: 'organization',
      label: 'Organization',
      format: (value, { companyName, id }) => (
        <Link to={RoutesEnum.adminOrganizations + '/' + id} className={'s1'}>
          {`${companyName} (ID: ${id})`}
        </Link>
      ),
    },
    {
      id: 'status',
      label: 'Subscription status',
      format: (value) => (
        <StatusContent
          status={
            value === Status.Pending ? CustomStatuses.Lead : (value as Status)
          }
          noIcon
        />
      ),
    },
    {
      id: 'signupDate',
      label: 'Signup Date',
      format: (value) => dayjs(value as Date).format(DayjsFormats.fullSlash),
    },
    {
      id: 'businessProfileStatus',
      label: 'Business Profile',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      format: (status: BusinessProfileStatus, { id }) => (
        <BusinessProfileStatusCell status={status} id={id} />
      ),
    },
    {
      id: 'tollFreeStatus',
      label: 'Toll-Free',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      format: (status: TollFreeVerificationStatus, { id }) => (
        <TollFreeStatusCell status={status} id={id} />
      ),
    },
  ]

const BusinessProfileStatusCell = observer(
  ({ status, id }: { status: BusinessProfileStatus; id: number }) => {
    const { setOpenModal } = businessProfileStore
    const [adminGetFullBusinessProfile, { loading }] =
      useAdminGetFullBusinessProfileLazyQuery({
        variables: {
          organizationId: id,
        },
      })
    const onOpenBusinessProfile = async () => {
      if (loading) {
        return
      }
      try {
        const { data } = await adminGetFullBusinessProfile({
          variables: {
            organizationId: id,
          },
        })
        if (data?.adminGetFullBusinessProfile) {
          setOpenModal(true, data?.adminGetFullBusinessProfile, id)
        }
      } catch (e) {
        console.error(e)
      }
      // if (organization.businessProfile) {
      //   businessProfileStore.setOpenModal(true, organization.businessProfile)
      // }
    }

    return (
      <button onClick={onOpenBusinessProfile}>
        <StatusContent
          status={status}
          noIcon
          text={typesStore.businessProfileStatusMapping.get(status)}
        />
      </button>
    )
  }
)

const TollFreeStatusCell = observer(
  ({ status, id }: { status: TollFreeVerificationStatus; id: number }) => {
    const { setOpenModal } = tollFreeVerificationStore

    const [adminGetFullTollFreeVerificationProfile, { loading }] =
      useAdminGetFullTollFreeVerificationProfileLazyQuery()
    const onOpenUseCaseProfile = async () => {
      if (loading) {
        return
      }
      try {
        const { data } = await adminGetFullTollFreeVerificationProfile({
          variables: {
            organizationId: id,
          },
        })
        if (data?.adminGetFullTollFreeVerificationProfile) {
          setOpenModal(true, data?.adminGetFullTollFreeVerificationProfile, id)
        }
      } catch (e) {
        console.error(e)
      }
    }
    return (
      <button onClick={onOpenUseCaseProfile}>
        <StatusContent
          status={status}
          noIcon
          text={typesStore.tfStatusMapping.get(status)}
        />
      </button>
    )
  }
)
