import { makeAutoObservable } from 'mobx'
import { ExpectedMessageType } from 'src/generated/graphql'
import user from 'store/user/user'
import { IListItem } from 'components/SelectList/ListItem/ListItem'
import { SelectListProps } from 'components/SelectList/SelectList'

const ExpectedMessageTypeObj = {
  [ExpectedMessageType.Sms]: 'SMS',
  [ExpectedMessageType.Ringless]: 'Ringless',
  [ExpectedMessageType.Voice]: 'Automated Calls',
}

type IExpectedMessageTypeItem = {
  id: ExpectedMessageType
  title: string
  checked: boolean
}

export class RegisterStore {
  constructor() {
    makeAutoObservable(this)
    const companyDataString = localStorage.getItem('companyData')
    if (companyDataString) {
      const companyData = JSON.parse(companyDataString)
      this.companyName = companyData.companyName
      this.employees = companyData.employees
      this.expectedMessageTypeMap = new Map(companyData.deliveredMap)
    }
    this.loadHubspotForm()
  }

  companyName = ''
  employees: string | null = ''
  expectedMessageTypeMap: Map<ExpectedMessageType, IExpectedMessageTypeItem> =
    new Map(
      Object.entries(ExpectedMessageTypeObj).map(([id, title]) => [
        id,
        { id, title, checked: false },
      ])
    ) as Map<ExpectedMessageType, IExpectedMessageTypeItem>

  get messageTypes(): ExpectedMessageType[] {
    const messageTypes: ExpectedMessageType[] = []
    this.expectedMessageTypeMap.forEach((item, key) => {
      if (item.checked) {
        messageTypes.push(key)
      }
    })
    return messageTypes
  }

  get disabledContinue() {
    if (!this.companyName.trim()) {
      return true
    }
    if (!this.selected_contact_list_size_item) {
      return true
    }
    if (!this.selected_message_frequency_item) {
      return true
    }
    return false
  }

  setCompanyName = (value: string) => {
    this.companyName = value
  }

  saveCompanyData = () => {
    const companyName = this.companyName
    localStorage.setItem(
      'companyData',
      JSON.stringify({
        companyName,
      })
    )

    const form = this.hubspotForm

    if (form) {
      const emailInput = form.querySelector(
        "input[name='email']"
      ) as HTMLInputElement
      if (emailInput) {
        emailInput.value = user.email
      }
      form.submit()
    }
  }

  loadHubspotForm = () => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = '//js.hsforms.net/forms/embed/v2.js'
    script.onload = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      hbspt &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        hbspt.forms.create({
          region: process.env.FE_HUBSPOT_REGION,
          portalId: process.env.FE_HUBSPOT_PORTAL_ID,
          formId: process.env.FE_HUBSPOT_REGISTER_COMPANY_B_FORM_ID,
          onFormReady: this.setHubspotForm,
        })
    }
    document.body.appendChild(script)
  }

  hubspotForm: HTMLFormElement | null = null
  private setHubspotForm = (form: HTMLFormElement) => {
    this.hubspotForm = form

    const contact_list_size = form.querySelector(
      "select[name='contact_list_size']"
    ) as HTMLSelectElement

    if (contact_list_size) {
      this.set_contact_list_size(contact_list_size)
    }

    const message_frequency = form.querySelector(
      "select[name='message_frequency']"
    ) as HTMLSelectElement

    if (message_frequency) {
      this.set_message_frequency(message_frequency)
    }

    const type_of_messages_inputs: NodeListOf<HTMLInputElement> =
      form.querySelectorAll("input[name$='type_of_messages']")
    if (type_of_messages_inputs) {
      this.set_type_of_messages_inputs(type_of_messages_inputs)
    }

    //0-2/type_of_messages
  }

  //contact_list_size

  contact_list_size: HTMLSelectElement | null = null
  selected_contact_list_size_item: IListItem | null = null

  get contact_list_size_options(): IListItem[] {
    if (!this.contact_list_size) {
      return []
    }
    return Array.from(this.contact_list_size.options)
      .filter((option) => option.value)
      .map((option) => ({
        id: option.value,
        option,
        text: option.innerText,
        isActive: this.selected_contact_list_size_item?.id === option.value,
      }))
  }

  private set_contact_list_size = (selectFrequency: HTMLSelectElement) => {
    this.contact_list_size = selectFrequency
  }

  set_selected_contact_list_size: SelectListProps<string>['onSelect'] = (
    id,
    item
  ) => {
    if (item?.option) {
      item.option.selected = true
    }
    this.selected_contact_list_size_item = item
  }

  //message_frequency

  message_frequency: HTMLSelectElement | null = null
  selected_message_frequency_item: IListItem | null = null

  get message_frequency_options(): IListItem[] {
    if (!this.message_frequency) {
      return []
    }
    return Array.from(this.message_frequency.options)
      .filter((option) => option.value)
      .map((option) => ({
        id: option.value,
        option,
        text: option.innerText,
        isActive: this.selected_message_frequency_item?.id === option.value,
      }))
  }

  private set_message_frequency = (selectFrequency: HTMLSelectElement) => {
    this.message_frequency = selectFrequency
  }

  set_selected_message_frequency: SelectListProps<string>['onSelect'] = (
    id,
    item
  ) => {
    if (item?.option) {
      item.option.selected = true
    }
    this.selected_message_frequency_item = item
  }

  //0-2/type_of_messages
  type_of_messages_inputs: NodeListOf<HTMLInputElement> | null = null
  type_of_messages_set: Set<string> = new Set()
  get type_of_messages_inputs_checkbox() {
    if (!this.type_of_messages_inputs) {
      return []
    }
    return Array.from(this.type_of_messages_inputs).map((input) => ({
      label: input.nextSibling?.textContent || input.value,
      checked: this.type_of_messages_set.has(input.value),
      onChecked: () => {
        const checked = this.type_of_messages_set.has(input.value)
        if (checked) {
          this.type_of_messages_set.delete(input.value)
        } else {
          this.type_of_messages_set.add(input.value)
        }

        input.checked = !checked
      },
    }))
  }
  private set_type_of_messages_inputs = (
    type_of_messages_inputs: NodeListOf<HTMLInputElement>
  ) => {
    this.type_of_messages_inputs = type_of_messages_inputs
  }
}
