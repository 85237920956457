import NewTable from 'components/NewTable/NewTable'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Filters } from './Filters/Filters'
import { Status, useGetAllCampaignsQuery } from 'src/generated/graphql'
import campaignsStore from 'store/campaigns/campaignsStore'
import { ICampaign } from 'src/types/ICampaign'
import { EmptyState } from 'components/EmptyState/EmptyState'
import * as React from 'react'
import { useSetData } from 'src/hooks/useSetData'
import { CampaignDetailModal } from 'src/pages/main/campaigns/modals/CampaignDetailModal'
import { getCampaignsActions } from 'src/pages/main/campaigns/functions/getCampaignsActions'
import { EmptyStateSearch } from 'components/EmptyState/EmptyStateSearch'
import { noSortsColumnsCampaigns } from 'components/NewTable/columns/campaigns/campaignColumns'
import { AppElements } from 'src/enums/appElements'

export const CampaignsTable = observer(() => {
  const { tableStore, campaigns, setData } = campaignsStore

  const queryResult = useGetAllCampaignsQuery({
    variables: {
      page: tableStore.paginationInput,
      search: {
        searchPattern: tableStore.search,
        statuses: tableStore.currentStatuses.map((option) => option.value),
      },
    },
  })
  const navigate = useNavigate()
  const onRowClick = (id: string, row: ICampaign) => {
    if (row.status === Status.Draft) {
      navigate(String(id) + '/edit')
    } else {
      navigate(String(id))
    }
  }

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData,
    refreshDeps: [tableStore.refreshTrigger],
  })

  if (
    !loading &&
    !campaigns.length &&
    !tableStore.search &&
    !tableStore.currentStatuses.length
  ) {
    return (
      <EmptyState
        element={AppElements.Campaign}
        description={'Enroll contacts in a sequence of messages'}
      />
    )
  }

  return (
    <>
      <CampaignDetailModal />
      <NewTable<ICampaign>
        withCheckbox
        columns={tableStore.visibleColumns}
        leftStickyContent={<Filters />}
        withSearch
        onRowClick={onRowClick}
        rows={campaigns.map((campaign) => {
          return {
            ...campaign,
            actions: getCampaignsActions(campaign),
          }
        })}
        withCalcMaxHeight
        tableStore={tableStore}
        pageLoading={loading || refetchLoading}
        firstLoading={firstLoading}
        emptyContent={
          campaigns.length ? undefined : (
            <EmptyStateSearch element={AppElements.Campaign} />
          )
        }
        noSortsColumns={noSortsColumnsCampaigns}
      />
    </>
  )
})
