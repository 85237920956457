import classNames from 'classnames'
import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as Refresh } from 'icons/16px/Refresh.svg'
import { ReactComponent as AngleLeft } from 'icons/16pxNoMask/Angle-Left.svg'
import { ReactComponent as AngleRight } from 'icons/16pxNoMask/Angle-Right.svg'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { TTPositionEnum } from 'components/Tooltip/FixedTooltip/FixedTooltip'
import * as React from 'react'
import { useMemo } from 'react'
import { TableStore } from 'components/NewTable/store/TableStore'
import { compactFormatter } from 'src/util/functions'
import { observer } from 'mobx-react-lite'
import { Loader } from 'components/Loader/Loader'
import uiStore from 'store/uiStore'
import { AppIcon, IIconType } from 'components/AppIcon/AppIcon'
import { ColorsNames } from 'styles/variables'
import { SelectList } from 'components/SelectList/SelectList'
import { Icon } from 'components/Icon/Icon'
import { DropdownField } from 'components/DropdownField/DropdownField'

type Props<T> = {
  rows: Array<T>
  pageLoading?: boolean
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  tableStore: TableStore<T>
}

export const AppPagination = observer(
  <T,>({ tableStore, rows, pageLoading }: Props<T>) => {
    const nextPage = () => {
      tableStore.setPage(tableStore.page + 1)
    }
    const previousPage = () => {
      tableStore.setPage(tableStore.page - 1)
    }
    const pages = useMemo(() => {
      const pages = []
      const allPages = Array(
        Math.ceil(
          (tableStore.total || rows.length) / tableStore.selectedRowPerPage
        )
      )
        .fill(0)
        .map((_, i) => i)
      if (allPages.length < 6) {
        return allPages
      } else {
        pages.push(allPages[0])
        if (tableStore.page > 2) {
          pages.push(-1)
        }
        //for end
        if (tableStore.page === allPages.length - 1) {
          pages.push(allPages[tableStore.page - 3])
          pages.push(allPages[tableStore.page - 2])
        }
        if (tableStore.page === allPages.length - 2) {
          pages.push(allPages[tableStore.page - 2])
        }
        if (tableStore.page > 0 && tableStore.page - 1 !== 0) {
          pages.push(allPages[tableStore.page - 1])
        }
        tableStore.page !== 0 &&
          tableStore.page !== allPages.length - 1 &&
          pages.push(allPages[tableStore.page])

        if (
          tableStore.page !== allPages.length - 1 &&
          tableStore.page + 1 !== allPages.length - 1
        ) {
          pages.push(allPages[tableStore.page + 1])
        }
        if (tableStore.page === 0) {
          pages.push(allPages[tableStore.page + 2])
          pages.push(allPages[tableStore.page + 3])
        }

        if (tableStore.page === 1) {
          pages.push(allPages[tableStore.page + 2])
        }

        if (tableStore.page < allPages.length - 3) {
          pages.push(-1)
        }
        pages.push(allPages[allPages.length - 1])
      }
      return pages
    }, [rows, tableStore.selectedRowPerPage, tableStore.page, tableStore.total])
    const canPreviousPage = useMemo(
      () => tableStore.page !== 0,
      [tableStore.page, tableStore.total]
    )
    const canNextPage = useMemo(
      () => tableStore.page !== pages[pages.length - 1],
      [tableStore.selectedRowPerPage, tableStore.page, rows, tableStore.total]
    )

    const moreBtn = (
      <Button
        className={classNames(styles.pageBtn, styles.more)}
        size={BtnSize.mini}
      >
        ...
      </Button>
    )

    return (
      <div className={classNames(styles.pagination)}>
        {!uiStore.isMobile && (
          <>
            <div
              className={classNames(
                styles.wrapRefresh,
                uiStore.isMobile && styles.isMobile
              )}
            >
              {pageLoading ? (
                <div className={styles.loader}>
                  <Loader size={16} />
                </div>
              ) : (
                <Button
                  size={BtnSize.mini}
                  icon
                  onClick={() => tableStore.onRefresh()}
                >
                  <Refresh />
                </Button>
              )}
            </div>

            <div className={styles.nav}>
              <Button
                size={BtnSize.mini}
                icon
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <AppIcon type={IIconType.stroke}>
                  <AngleLeft />
                </AppIcon>
              </Button>
              <div className={styles.flex}>
                {pages.map((p) =>
                  p === -1 ? (
                    moreBtn
                  ) : (
                    <Button
                      key={p}
                      className={classNames(
                        styles.pageBtn,
                        tableStore.page === p && styles.active,
                        styles.fit
                      )}
                      size={BtnSize.mini}
                      onClick={() => tableStore.setPage(p)}
                    >
                      <span className={'bold gray1'}>{p + 1}</span>
                    </Button>
                  )
                )}
              </div>

              <Button
                size={BtnSize.mini}
                icon
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <AppIcon type={IIconType.stroke}>
                  <AngleRight />
                </AppIcon>
              </Button>
            </div>
          </>
        )}
        {uiStore.isMobile && (
          <div className={'row8'}>
            <Button
              btnSmall
              size={BtnSize.small}
              onClick={() => previousPage()}
              color={ColorsNames.gray4}
              disabled={!canPreviousPage}
            >
              <AppIcon type={IIconType.stroke}>
                <AngleLeft />
              </AppIcon>
            </Button>
            <Dropdown
              options={tableStore.allPagesOptions}
              selectedValue={tableStore.page}
              onSelectValue={(val) => tableStore.setPage(val)}
              position={TTPositionEnum.topCenter}
              contentRender={
                <Button
                  size={BtnSize.small}
                  typeBtn={BtnType.secondaryBlue}
                  className={styles.navMobileBtn}
                >
                  {tableStore.page + 1} / {tableStore.allPages}
                </Button>
              }
            />
            <Button
              btnSmall
              onClick={() => nextPage()}
              color={ColorsNames.gray4}
              size={BtnSize.small}
              disabled={!canNextPage}
            >
              <AppIcon type={IIconType.stroke}>
                <AngleRight />
              </AppIcon>
            </Button>
          </div>
        )}
        <div className={styles.showing}>
          {!uiStore.isMobile && 'Showing'}
          <DropdownField
            withPopperOffset
            dropdownContent={
              <SelectList
                options={tableStore.rowPerPageOptions}
                onSelect={(id) =>
                  tableStore.setSelectedRowPerPage(id as number)
                }
              />
            }
          >
            <div className={styles.perPageBtn}>
              <span className={'blue1 bold'}>
                {tableStore.selectedRowPerPage}
              </span>
              <Icon icon={'angleDown'} fontSize={16} />
            </div>
          </DropdownField>
          {/*<Dropdown*/}
          {/*  options={pageOptions}*/}
          {/*  selectedOption={tableStore.selectedRowPerPage}*/}
          {/*  onSelect={handleChangeRowsPerPage}*/}
          {/*  dropDownContentWidth={89}*/}
          {/*  position={TTPositionEnum.topCenter}*/}
          {/*  className={styles.perPageDrop}*/}
          {/*  fromNavigation*/}
          {/*/>*/}
          of {compactFormatter.format(tableStore.total || rows.length)}
        </div>
      </div>
    )
  }
)
