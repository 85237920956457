// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HiHIzbmHvsV60dqGYyGz{display:grid;gap:24px;margin-bottom:40px}.Jg7WDEaMzGWSwQHv1RyR{display:grid;gap:16px}.QjIWrQldzHoJu06MzlEu{display:grid;grid-template-columns:1fr max-content;gap:12px;align-items:center}`, "",{"version":3,"sources":["webpack://./src/pages/settings/integrations/modal/CustomIntegrationModal/CustomFieldsContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CACA,kBAAA,CAEF,sBACE,YAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,qCAAA,CACA,QAAA,CACA,kBAAA","sourcesContent":[".wrap{\n  display: grid;\n  gap: 24px;\n  margin-bottom: 40px;\n}\n.list{\n  display: grid;\n  gap: 16px;\n}\n\n.item{\n  display: grid;\n  grid-template-columns: 1fr max-content;\n  gap: 12px;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `HiHIzbmHvsV60dqGYyGz`,
	"list": `Jg7WDEaMzGWSwQHv1RyR`,
	"item": `QjIWrQldzHoJu06MzlEu`
};
export default ___CSS_LOADER_EXPORT___;
