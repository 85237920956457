// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l0pY0JpwQxsqB8_d1Q7B{padding:0 32px 32px 32px;min-width:512px}@media(max-width: 800px){.l0pY0JpwQxsqB8_d1Q7B{min-width:unset}}@media(max-height: 600px){.l0pY0JpwQxsqB8_d1Q7B{min-width:unset}}.XNTFre29i5k4LzYuAAjZ{display:flex;flex-direction:column;gap:24px;margin-top:32px}`, "",{"version":3,"sources":["webpack://./src/widgets/conversions/NewConversion/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,eAAA,CACA,yBAHF,sBAII,eAAA,CAAA,CAEF,0BANF,sBAOI,eAAA,CAAA,CAIJ,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,eAAA","sourcesContent":[".wrap{\n  padding: 0 32px 32px 32px;\n  min-width: 512px;\n  @media (max-width: 800px) {\n    min-width: unset;\n  }\n  @media (max-height: 600px) {\n    min-width: unset;\n  }\n}\n\n.fields{\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  margin-top: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `l0pY0JpwQxsqB8_d1Q7B`,
	"fields": `XNTFre29i5k4LzYuAAjZ`
};
export default ___CSS_LOADER_EXPORT___;
