import { makeAutoObservable } from 'mobx'
import { SendingSpeedStore } from 'src/widgets/SendingSpeed'
import { WeekDaysSelectorStore } from 'src/widgets/WeekDaysSelector'
import dayjs from 'lib/dayjs'
import { getSendTextByDays } from 'components/Schedule/utils/getSendTextByDays'
import { AppSymbols } from 'src/util/symbols'
import user from 'store/user/user'
import { numberString } from 'src/util/functions'
import {
  AdvancedSchedulingConfigFragment,
  AdvancedSchedulingConfigInput,
} from 'src/generated/graphql'

export class ActiveAdvancedScheduleStore {
  sendingSpeedStore: SendingSpeedStore
  weekDaysSelectorStore: WeekDaysSelectorStore
  constructor(config?: AdvancedSchedulingConfigFragment) {
    this.sendingSpeedStore = new SendingSpeedStore(config?.speedConfig)
    this.weekDaysSelectorStore = new WeekDaysSelectorStore({
      days: config?.allowedDays,
    })
    if (config) {
      this.init(config)
    }
    makeAutoObservable(this)
  }

  get advancedSchedulingConfigInput(): Omit<
    AdvancedSchedulingConfigInput,
    'durationDays' | 'durationMinutes' | 'durationHours'
  > | null {
    return {
      contactsPerDay: this.contactsCount || 0,
      allowedDays: this.weekDaysSelectorStore.selectedDays,
      startTimeHour: dayjs(this.startDate).get('hours') ?? 9,
      startTimeMinute: dayjs(this.startDate).get('minutes') ?? 0,
      speedConfig:
        this.sendingSpeedStore.activeContentStore
          ?.broadcastSendingSpeedConfigInput,
    }
  }

  isEdit = false
  isShowContactsPerDayActiveInputError = false
  setContactsPerDayActiveInput = (value: boolean) => {
    console.log(value, 'value')
    if (!value) {
      this.isShowContactsPerDayActiveInputError = true
    }
  }

  get contactsPerDayActiveInputError() {
    if (!this.contactsCount && this.isShowContactsPerDayActiveInputError) {
      return 'Amount of contacts per day is not set'
    }
  }

  get isFocusOnMount() {
    return !this.contactsCount
  }

  contactsCount: number | null = null
  setContactsCount = (value: number) => {
    this.contactsCount = value
  }

  startDate: Date | null = dayjs().startOf('day').add(9, 'hours').toDate()
  setStartDate = (date: typeof this.startDate) => {
    this.startDate = date
  }

  get sendOnlyStr() {
    return `Send ${getSendTextByDays(this.weekDaysSelectorStore.selectedDays)}`
  }
  get sendAtStr() {
    return `At ${dayjs(this.startDate).format('h:mm a')} ${AppSymbols.point} ${
      user.timezone?.zoneAbbreviation
    }`
  }
  get sendToContactsStr() {
    if (
      this.sendingSpeedStore.active &&
      this.sendingSpeedStore.activeContentStore
    ) {
      return `To ${numberString({
        val: this.sendingSpeedStore.activeContentStore.contactsCount || 0,
      })} contacts every ${
        this.sendingSpeedStore.activeContentStore.unitAmount
      } minutes`
    }
    return `To ${numberString({
      val: this.contactsCount || 0,
    })} contacts per day`
  }

  init = (config: AdvancedSchedulingConfigFragment) => {
    this.isEdit = true
    this.startDate = dayjs()
      .startOf('day')
      .add(config.startTimeHour, 'hours')
      .add(config.startTimeMinute, 'minutes')
      .toDate()

    this.contactsCount = config.contactsPerDay
  }
}
