import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as Contacts } from 'icons/16px/Contacts Add.svg'
import { ReactComponent as Plus } from 'icons/16px/Plus.svg'
import contactStore from 'store/contacts/contactStore'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { DeleteBtn } from 'components/Button/DeleteBtn'
import { ActionsEnum } from 'components/Button/types'
import { MediumBtn } from 'components/Button/MediumBtn'
import { onRenameTableCell } from 'components/NewTable/functions'
import { useNavigate } from 'react-router-dom'
import manualContactStore from 'store/contacts/manualContactStore'
import { useListsFunctions } from 'src/pages/main/contacts/contctLists/hooks/useListsFunctions'
import { CheckLimitContactsWrap } from 'src/pages/main/contacts/components/CheckLimitContactsWrap'
import { ImportBtn } from 'src/pages/main/contacts/components/ImportBtn/ImportBtn'
import { ListExportBtn } from 'src/pages/main/contacts/contctLists/components/ListExportBtn'

export const Filters = observer(() => {
  const {
    onUpdate,
    // onExport,
    onDeleteLists,
    // onValidate,
  } = useListsFunctions()

  const navigate = useNavigate()
  // const handleExport = () => {
  //   onExport(contactStore.tableStoreLists.checkedRowsIds)
  // }
  const handleDelete = () => {
    onDeleteLists(contactStore.tableStoreLists.checkedRowsIds)
  }
  // const handleValidate = () => {
  //   onValidate(contactStore.tableStoreLists.checkedRowsIds)
  // }
  const content = useMemo(() => {
    if (contactStore.tableStoreLists.checkedRows.length === 1) {
      const list = contactStore.tableStoreLists.checkedRows[0]
      if (!list) return <></>
      const onRename = () => {
        onRenameTableCell({
          row: list,
          onSave: async (id, text) => {
            onUpdate(id, text)
          },
        })
      }
      return (
        <>
          {/*<MediumBtn*/}
          {/*  type={ActionsEnum.validatePhone}*/}
          {/*  onClick={handleValidate}*/}
          {/*  // text={'Rename'}*/}
          {/*/>*/}

          <MediumBtn
            type={ActionsEnum.edit}
            onClick={onRename}
            text={'Rename'}
          />
          <ListExportBtn row={list} medium />
          {/*<MediumBtn type={ActionsEnum.export} onClick={handleExport} />*/}
          <DeleteBtn medium onClick={handleDelete} />
        </>
      )
    }
    if (contactStore.tableStoreLists.checkedRowsIds.length > 1) {
      return (
        <>
          {/*<MediumBtn*/}
          {/*  type={ActionsEnum.validatePhone}*/}
          {/*  onClick={handleValidate}*/}
          {/*  // text={'Rename'}*/}
          {/*/>*/}
          {/*<MediumBtn type={ActionsEnum.export} onClick={handleExport} />*/}
          <DeleteBtn medium onClick={handleDelete} />
        </>
      )
    }
    return (
      <>
        <ImportBtn />
        <CheckLimitContactsWrap>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.secondaryGray}
            onClick={() => manualContactStore.setOpenContactModal(true)}
          >
            <Contacts />
            Add manually
          </Button>
        </CheckLimitContactsWrap>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.secondaryGray}
          onClick={() => navigate('new')}
        >
          <Plus />
          New list
        </Button>
      </>
    )
  }, [contactStore.tableStoreLists.checkedRowsIds, contactStore.contactLists])
  return <div className={styles.wrapActions}>{content}</div>
})
