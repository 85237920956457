import { StatusContent } from 'components/StatusContent/StatusContent'
import { AllowImportStatus } from 'src/generated/graphql'
import dayjs, { DayjsFormats } from 'lib/dayjs'
import { numberString } from 'src/util/functions'
import { IContactImportState } from 'src/pages/main/contacts/importHistory/ImportHistoryTable/types'
import { AppSymbols } from 'src/util/symbols'
import { allowImportStatusToText } from 'store/superAdmin/util'
import { RoutesEnum } from 'src/pages/routes'
import { Link } from 'react-router-dom'
import { ColumnType } from 'components/NewTable/types'
import superAdminImportsStore from 'store/superAdmin/superAdminImportsStore'

export const superAdminImportColumns: ColumnType<IContactImportState>[] = [
  {
    id: 'id',
    label: 'Request ID',
  },
  {
    id: 'organizationId',
    label: 'Organization',
    minWidth: 200,
    tooltipTitle: (value, { organization }) => {
      if (organization) {
        const { id, companyName } = organization
        return `${companyName} (ID: ${id})`
      } else return ''
    },
    format: (value, { organization }) => {
      if (organization) {
        const { id, companyName } = organization
        return (
          <Link
            to={RoutesEnum.adminOrganizations + '/' + id}
            className={'s1'}
          >{`${companyName} (ID: ${id})`}</Link>
        )
      } else return <></>
    },
  },
  {
    id: 'memberId',
    label: 'User',
    tooltipTitle: (value, { member }) => {
      if (member) {
        const { id } = member
        const name = member.profile?.name
        return `${name} (ID: ${id})`
      } else return ''
    },
    format: (value, { member }) => {
      if (member) {
        const { id } = member
        const name = member.profile?.name
        return <>{`${name} (ID: ${id})`}</>
      } else return <></>
    },
  },
  {
    id: 'createdAt',
    label: 'Created At',
    format: (value) => dayjs(value as Date).format(DayjsFormats.fullWithAtDash),
  },
  {
    id: 'totalCount',
    label: 'Contacts count',
    format: (value) => numberString({ val: +value }),
  },
  {
    id: 'file',
    label: 'File',
    format: (value, { id, originalFileName }) => (
      <button
        className={'link'}
        onClick={() =>
          superAdminImportsStore.downloadFileImport(id, originalFileName)
        }
      >
        Review
      </button>
    ),
  },
  {
    id: 'approvedBy',
    label: 'Approved by',
    format: (value, { organization }) => (
      <>
        {organization?.importApprover
          ? `${organization?.importApprover?.profile?.name} (ID: ${organization?.importApprover.id})`
          : AppSymbols.dash}
      </>
    ),
  },
  {
    id: 'importApprovedAt',
    label: 'Approved At',
    format: (value, { organization }) => (
      <>
        {organization?.importApprovedAt
          ? dayjs(organization?.importApprovedAt as Date).format(
              DayjsFormats.fullWithAtDash
            )
          : AppSymbols.dash}
      </>
    ),
  },
  {
    id: 'allowImportStatus',
    label: 'Status',
    format: (value) => (
      <StatusContent
        status={value as AllowImportStatus}
        text={allowImportStatusToText[value as AllowImportStatus]}
      />
    ),
  },
]

export const superAdminImportColumnsNoSortsColumns = [
  'approvedBy',
  'allowImportStatus',
]
