import { makeAutoObservable } from 'mobx'
import {
  ConversionFragment,
  ConversionInput,
  GetConversionsQuery,
  PaginationInput,
} from 'src/generated/graphql'
import {
  ConversionResponse,
  IConversion,
} from 'src/pages/settings/conversions/conversions/store/type'
import { conversionResponseToConversion } from 'src/pages/settings/conversions/conversions/store/transform'
import { IListItem } from 'components/SelectList/ListItem/ListItem'

export class TrackConversionStore {
  constructor(response?: ConversionResponse) {
    if (response) {
      this.init(response)
    }
    makeAutoObservable(this)
  }

  total = 0
  pageSize = 20
  pageNumber = 0
  search = ''
  setSearch = (value: string) => {
    this.search = value
  }

  get conversionInput(): ConversionInput | null {
    if (!this.isEnabled || !this.conversion) return null
    return {
      id: this.conversion?.id,
    }
  }

  get page(): PaginationInput {
    return {
      pageSize: this.pageSize,
      pageNumber: this.pageNumber,
    }
  }

  conversion: IConversion | null = null
  setConversion = (response: ConversionFragment) => {
    this.conversion = conversionResponseToConversion(response)
  }

  isEnabled = false
  setEnabled = (value: boolean) => {
    this.isEnabled = value
    if (!value) {
      this.conversion = null
      this.search = ''
      this.pageNumber = 0
    }
  }

  init = (response: ConversionResponse) => {
    this.isEnabled = true
    this.setConversion(response)
  }

  conversionsMap: Map<number, IConversion> = new Map()
  get conversions() {
    return Array.from(this.conversionsMap.values())
  }

  onSelect = (id: any, item: IListItem<IConversion>) => {
    if (item.item) {
      this.conversion = item.item
    }
  }

  setData = (data: GetConversionsQuery) => {
    if (data.getConversions) {
      this.total = data.getConversions.total
      data.getConversions.content?.forEach((response) => {
        if (response) {
          const conversion = conversionResponseToConversion(response)
          this.conversionsMap.set(conversion.id, conversion)
        }
      })
    }
  }

  get isShowLoadingMore() {
    return this.total > this.conversionsMap.size
  }

  handleLoadMore = () => {
    if (!this.isShowLoadingMore) {
      return
    }
    this.pageNumber += 1
  }
}
