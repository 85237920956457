// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CZunYTQHlqmHFa6ShTAf{display:flex;flex-direction:column;flex-grow:1;height:100%;overflow:auto}.CZunYTQHlqmHFa6ShTAf.EnA_CfgXJqpMpSKDx2Xz{background:var(--primary-color-gray-4)}.CZunYTQHlqmHFa6ShTAf.QhXG28qXPQWfj02lJquA{align-items:center}`, "",{"version":3,"sources":["webpack://./src/shared/Page/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,WAAA,CACA,aAAA,CACA,2CACE,sCAAA,CAEF,2CACE,kBAAA","sourcesContent":[".wrap{\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  height: 100%;\n  overflow: auto;\n  &.gray{\n    background: var(--primary-color-gray-4);\n  }\n  &.center{\n    align-items: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `CZunYTQHlqmHFa6ShTAf`,
	"gray": `EnA_CfgXJqpMpSKDx2Xz`,
	"center": `QhXG28qXPQWfj02lJquA`
};
export default ___CSS_LOADER_EXPORT___;
