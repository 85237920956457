import webFormStore from 'store/webForms/webFormStore'
import { useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'
import { useDeleteWebFormsMutation } from 'src/generated/graphql'
import { IWebForm } from 'src/types/IWebForm'

export function useWebFormFunctions(webForm?: IWebForm | null) {
  const navigate = useNavigate()
  const [deleteWebForms] = useDeleteWebFormsMutation()

  const onEdit = () => {
    if (webForm) {
      navigate(`${RoutesEnum.webForms}/${webForm.id}/edit`)
    }
  }

  const onDuplicate = () => {
    if (webForm) {
      navigate(`${RoutesEnum.webForms}/${webForm.id}/duplicate`)
    }
  }
  const onDelete = (ids: string[]) => {
    if (ids.length) {
      deleteWebForms({ variables: { ids: ids } })
        .then(() => {
          navigate(RoutesEnum.webForms)
          webFormStore.delete(ids)
        })
        .catch(console.error)
    }
  }
  const onTestWebForm = () => {
    if (webForm) {
      webFormStore.setTestWebForm(webForm)
    }
  }
  const onGetCodeWebForm = () => {
    if (webForm) {
      webFormStore.setGetCodeWebForm(webForm)
    }
  }

  return {
    onEdit,
    onDuplicate,
    onDelete,
    onTestWebForm,
    onGetCodeWebForm,
  }
}
