// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OH6qVhh2E_vOyd0v6iSf{display:grid;grid-template-columns:1fr 240px}.MiaU66dRoLCKO0Xf9GQN{display:flex;column-gap:12px}.CH97chCDh3aqsNktmhs7{width:fit-content}`, "",{"version":3,"sources":["webpack://./src/pages/main/campaigns/detail/CampaignDetailTable/LeftStickyContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,+BAAA,CAEF,sBACE,YAAA,CACA,eAAA,CAEF,sBACE,iBAAA","sourcesContent":[".row{\n  display: grid;\n  grid-template-columns: 1fr 240px;\n}\n.wrapActions{\n  display: flex;\n  column-gap: 12px;\n}\n.wrapRangePicker{\n  width: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `OH6qVhh2E_vOyd0v6iSf`,
	"wrapActions": `MiaU66dRoLCKO0Xf9GQN`,
	"wrapRangePicker": `CH97chCDh3aqsNktmhs7`
};
export default ___CSS_LOADER_EXPORT___;
