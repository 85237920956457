import { BroadcastsDetailTable } from 'src/pages/main/broadcasts/broadcastDetail/BroadcastDetailTable/BroadcastsDetailTable'
import { HeaderContent } from 'src/pages/main/broadcasts/broadcastDetail/header/HeaderContent'
import { createContext, useContext, useEffect, useState } from 'react'
import { BroadcastDetailStore } from 'store/broadcasts/broadcastDetailStore'
import { useParams } from 'react-router-dom'
import { useGetBroadcastByIdQuery } from 'src/generated/graphql'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import { transformFullBroadcast } from 'src/pages/main/broadcasts/functions/transformBroadcast'
import { observer } from 'mobx-react-lite'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const BroadcastDetailContext = createContext<BroadcastDetailStore>(null)

export function useBroadcastDetailContext() {
  return useContext(BroadcastDetailContext)
}

export const BroadcastDetail = observer(() => {
  const [store] = useState(new BroadcastDetailStore())
  const { id } = useParams()
  const { data, loading } = useGetBroadcastByIdQuery({
    variables: {
      broadcastId: Number(id),
    },
  })

  useEffect(() => {
    if (data?.getBroadcastById) {
      store.setBroadcast(transformFullBroadcast(data?.getBroadcastById))
    }
  }, [data])

  return (
    <BroadcastDetailContext.Provider value={store}>
      {loading && <AbsoluteLoader size={40} />}
      {!loading && store.broadcast && (
        <>
          <HeaderContent />
          <BroadcastsDetailTable />
        </>
      )}
    </BroadcastDetailContext.Provider>
  )
})
