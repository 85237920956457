import { Alert } from 'components/Alert/Alert'
import { observer } from 'mobx-react-lite'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import a2p10DLCStore from 'store/settings/company/a2p10DLCStore'
import {
  TenDlcOptInMethod,
  useInitOptInMethodTenDlcMutation,
} from 'src/generated/graphql'
import React, { useMemo } from 'react'
import { AppIcon, IIconType } from 'components/AppIcon/AppIcon'
import { ColorsNames } from 'styles/variables'
import { ReactComponent as IncomingText } from 'icons/20px/NoMask/Incoming text.svg'
import { ReactComponent as Webforms } from 'icons/20px/NoMask/Webforms.svg'
import { ReactComponent as PaperForm } from 'icons/20px/NoMask/PaperForm.svg'
import { ReactComponent as QRCode } from 'icons/20px/NoMask/QRCode.svg'
import styles from './styles.module.scss'
import { CardButton } from 'components/Button/CardButton/CardButton'
import { BackBtn } from 'src/pages/settings/compliance/registrations/A2P10DLCRegistration/steps/BackBtn'
import typesStore from 'store/typesStore'

const methodIcons = {
  [TenDlcOptInMethod.IncomingText]: (active: boolean) => (
    <AppIcon
      type={IIconType.stroke}
      color={active ? ColorsNames.blue1 : ColorsNames.gray1}
    >
      <IncomingText />
    </AppIcon>
  ),
  [TenDlcOptInMethod.OnlineForm]: (active: boolean) => (
    <AppIcon
      type={IIconType.stroke}
      color={active ? ColorsNames.blue1 : ColorsNames.gray1}
    >
      <Webforms />
    </AppIcon>
  ),
  [TenDlcOptInMethod.PaperForm]: (active: boolean) => (
    <AppIcon
      type={IIconType.stroke}
      color={active ? ColorsNames.blue1 : ColorsNames.gray1}
    >
      <PaperForm />
    </AppIcon>
  ),
  [TenDlcOptInMethod.QrCode]: (active: boolean) => (
    <AppIcon
      type={IIconType.stroke}
      color={active ? ColorsNames.blue1 : ColorsNames.gray1}
    >
      <QRCode />
    </AppIcon>
  ),
}

export const Step3 = observer(() => {
  const [initOptInMethodTenDlc, { loading }] =
    useInitOptInMethodTenDlcMutation()
  const onContinue = async () => {
    try {
      const { data } = await initOptInMethodTenDlc({
        variables: {
          input: a2p10DLCStore.tenDlcOptInMethodRequestInput,
        },
      })
      if (data?.initOptInMethodTenDlc?.profile) {
        a2p10DLCStore.setTenDlcProfile(data.initOptInMethodTenDlc.profile)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const getOption = (method: TenDlcOptInMethod) => ({
    icon: methodIcons[method],
    description: typesStore.a2pOptInMethodsMapping.get(method),
    value: method,
    example: '/#',
  })

  const options = useMemo(() => {
    return Object.values(TenDlcOptInMethod).map(getOption)
  }, [typesStore.a2pOptInMethodsMapping])

  return (
    <div className={'col40'}>
      <Alert
        compact
        text={
          'Please select the options around how your contacts opt-in to receive text messages, therefore giving consent to receive your messages.'
        }
      />
      <div className={styles.wrapOptInMethods}>
        {options.map((method) => {
          const active = a2p10DLCStore.optInMethods.includes(method.value)
          return (
            <CardButton
              key={method.value}
              onClick={() => a2p10DLCStore.setOptInMethods(method.value)}
              description={<>{method.description}</>}
              icon={method.icon(active)}
              active={active}
              neutral
              example={method.example}
              // svgColor={method.svgColor}
            />
          )
        })}
      </div>
      <div className={'row12'}>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          onClick={onContinue}
          loading={loading}
          disabled={a2p10DLCStore.disabledOptInMethods}
        >
          {a2p10DLCStore.continueText}
        </Button>
        <BackBtn />
      </div>
    </div>
  )
})
