import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import accountStore from 'store/settings/accountStore'
import { NewPasswordContent } from 'src/pages/settings/account/routes/PrivacyAccount/password/NewPasswordContent'
import styles from './styles.module.scss'

export const CreateNewPasswordModal = observer(() => {
  const onClose = () => {
    accountStore.setOpenCreatePasswordModal(false)
  }
  return (
    <Modal
      open={accountStore.openCreatePasswordModal}
      onClose={onClose}
      title={'Create new password'}
      minWidth={528}
    >
      <div className={styles.wrap}>
        <NewPasswordContent
          onCancel={onClose}
          fromModal
          verificationCode={accountStore.verificationCode}
        />
      </div>
    </Modal>
  )
})
