import modalStore from 'src/widgets/ModalContainer/store/modalStore'
import { NewConversion } from 'widgets/conversions/NewConversion/NewConversion'
import {
  ConversionResponse,
  IConversion,
} from 'src/pages/settings/conversions/conversions/store/type'

export const openEditConversionModal = (
  conversion: IConversion,
  onSuccess: (response: ConversionResponse) => void
) => {
  const id = 'Edit conversion'
  const onClose = () => {
    modalStore.closeModal(id)
  }

  modalStore.addModal({
    id,
    title: 'Edit conversion',
    minWidth: 576,
    children: (
      <NewConversion
        onClose={onClose}
        onSuccess={onSuccess}
        conversion={conversion}
      />
    ),
  })
}
