import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { TableSearch } from 'components/NewTable/components/TableSearch/TableSearch'
import * as React from 'react'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { ActionsEnum } from 'components/Button/types'
import { MediumBtn } from 'components/Button/MediumBtn'
import { RequiredFields, TableProps } from 'components/NewTable/types'

export const StickyContent = observer(
  <T extends RequiredFields<T>>({
    overrideWrapStickyClass,
    tableStore,
    leftStickyContent,
    withSearch,
    rightStickyContent,
    noStickyHeader,
    setRefSticky,
  }: TableProps<T> & {
    setRefSticky: Dispatch<SetStateAction<HTMLDivElement | null>>
  }) => {
    if (noStickyHeader) {
      return <></>
    }
    const content = useMemo(() => {
      if (!leftStickyContent && withSearch) {
        return <TableSearch<T> tableStore={tableStore} onlySearch />
      }
      if (tableStore.showMobileSearch) {
        return (
          <>
            <TableSearch<T> tableStore={tableStore} />
            <MediumBtn
              type={ActionsEnum.close}
              noText
              onClick={() => tableStore.setShowMobileSearch(false)}
            />
          </>
        )
      } else {
        return (
          <>
            {leftStickyContent ? leftStickyContent : <div />}
            {withSearch || rightStickyContent ? (
              <div>
                {withSearch && <TableSearch<T> tableStore={tableStore} />}
                {rightStickyContent}
              </div>
            ) : (
              <div />
            )}
          </>
        )
      }
    }, [tableStore.showMobileSearch])
    return (
      <div
        className={classNames(
          styles.wrapStickyContent,
          overrideWrapStickyClass
        )}
        ref={setRefSticky}
      >
        {(leftStickyContent ||
          withSearch ||
          rightStickyContent ||
          tableStore.showMobileSearch) &&
          content}
      </div>
    )
  }
)
