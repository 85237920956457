import { observer } from 'mobx-react-lite'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { IOption } from 'src/types/IOption'
import { ReactComponent as StatusIcon } from 'icons/16px/Status/Status.svg'
import {
  BusinessProfileStatus,
  TollFreeVerificationStatus,
} from 'src/generated/graphql'
import { StatusContent } from 'components/StatusContent/StatusContent'
import superAdminComplianceStore from 'store/superAdmin/superAdminComplianceStore'
import typesStore from 'store/typesStore'
import { subscriptionStatusesOptions } from 'src/pages/superAdmin/routes/organizations/OrganizationTable/Filters'

export const Filters = observer(() => {
  const bpOptions: IOption[] = Object.values(BusinessProfileStatus).map(
    (status) => ({
      title: (
        <StatusContent
          status={status}
          text={
            status === BusinessProfileStatus.PendingReview
              ? 'Pending review'
              : typesStore.businessProfileStatusMapping.get(status)
          }
        />
      ),
      value: status,
    })
  )

  const tfOptions: IOption[] = Object.values(TollFreeVerificationStatus).map(
    (status) => ({
      title: (
        <StatusContent
          status={status}
          text={typesStore.tfStatusMapping.get(status)}
        />
      ),
      value: status,
    })
  )
  return (
    <div className={'row12'}>
      <Dropdown
        dropDownContentWidth={220}
        width={260}
        maxWidth={220}
        maxHeightDropdown={250}
        options={bpOptions}
        selectedValues={superAdminComplianceStore.bpStatuses}
        onMultiSelectValues={(values) =>
          superAdminComplianceStore.setBPStatuses(values)
        }
        leftIcon={<StatusIcon />}
        multi
        unit={'status'}
        units={'statuses'}
        labelInput={'Business profile statuses'}
        withSelectALl
      />
      <Dropdown
        withSelectALl
        dropDownContentWidth={260}
        width={260}
        maxWidth={220}
        maxHeightDropdown={260}
        options={tfOptions}
        selectedValues={superAdminComplianceStore.tfStatuses}
        onMultiSelectValues={(values) =>
          superAdminComplianceStore.setTfStatuses(values)
        }
        leftIcon={<StatusIcon />}
        multi
        unit={'status'}
        units={'statuses'}
        labelInput={'Toll-Free profile statuses'}
      />
      <Dropdown
        dropDownContentWidth={250}
        width={210}
        maxHeightDropdown={250}
        options={subscriptionStatusesOptions}
        selectedValues={superAdminComplianceStore.billingStatuses}
        onMultiSelectValues={superAdminComplianceStore.setBillingStatuses}
        leftIcon={<StatusIcon />}
        multi
        unit={'status'}
        units={'statuses'}
        labelInput={'Subscription status'}
      />
    </div>
  )
})
