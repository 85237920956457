import { TriggersTable } from 'src/pages/main/triggers/TriggersTable/TriggersTable'
import { Header } from 'shared/Header/Header'

export const Triggers = () => {
  return (
    <>
      <Header title={'Triggers'} />
      <TriggersTable />
    </>
  )
}
