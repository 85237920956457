import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { ActionsContent } from './ActionContent/ActionsContent'
import { HeadCard } from 'src/pages/main/campaigns/create/createForm/HeadCard/HeadCard'
import { MessageCard } from 'src/pages/main/campaigns/create/createForm/MessageCard/MessageCard'
import { AddMessage } from 'src/pages/main/campaigns/create/createForm/AddMessage/AddMessage'
import createCampaignStore from 'store/campaigns/createCampaignStore'

export const NewCampaign = observer(() => {
  useEffect(() => createCampaignStore.reset, [])
  return (
    <div>
      <HeadCard />
      {createCampaignStore.messages.map((messageStore, index) => (
        <MessageCard
          key={messageStore.id}
          createStore={messageStore}
          index={index}
        />
      ))}
      <AddMessage />
      <ActionsContent />
    </div>
  )
})
