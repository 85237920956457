import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import React from 'react'
import { observer } from 'mobx-react-lite'
import { useCreateWebFromContext } from 'src/pages/main/webForms/create/CreateWebformState'
import webFormStore from 'store/webForms/webFormStore'
import uiStore from 'store/uiStore'
import { useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'
import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { MenuBtn, MenuEnum } from 'components/Button/MenuBtn'
import { ReactComponent as More } from 'icons/16pxNoMask/More.svg'
import { useWebFormFunctions } from 'src/pages/main/webForms/hooks/useWebFormFunctions'
import {
  Status,
  useCreateWebFormMutation,
  useUpdateWebFormMutation,
} from 'src/generated/graphql'
import { acceptedCreate } from 'src/pages/settings/billing/functions/acceptedCreate'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'
import { CloseCreateBtn } from 'components/Page/components/CloseCreateBtn'
import { AppElements } from 'src/enums/appElements'
import { useApolloError } from 'src/hooks/useApolloError'

export const ActionsContent = observer(
  ({ fromModal }: { fromModal?: boolean }) => {
    const createWebFormStore = useCreateWebFromContext()
    const [createWebForm, { loading: loadingCreate, error: errorCreate }] =
      useCreateWebFormMutation()
    const [updateWebForm, { loading: loadingUpdate, error: errorUpdate }] =
      useUpdateWebFormMutation()
    const { setTrigger, trigger, onTriggerClick, showTT, setShowTT } =
      useFixedTooltip()
    const { onTestWebForm, onDelete } = useWebFormFunctions(
      createWebFormStore.webFrom
    )
    const navigate = useNavigate()

    const { ErrorAlert } = useApolloError(errorCreate || errorUpdate)

    const onCreate = () => {
      const acceptedCB = () => {
        createWebForm({
          variables: {
            input: createWebFormStore.webFormInput,
          },
        })
          .then(() => {
            webFormStore.tableStore.onRefresh()
            if (fromModal) {
              webFormStore.webFormModalCB &&
                webFormStore.webFormModalCB(
                  createWebFormStore.dropdownContactListsStore.selectedOptions
                )
              webFormStore.setOpenWebFormModal(false)
            } else {
              uiStore.setNextRoute('')
              navigate(RoutesEnum.webForms)
            }
          })
          .catch((e) => console.error(e))
      }

      if (createWebFormStore.edit) {
        updateWebForm({
          variables: {
            input: {
              ...createWebFormStore.webFormInput,
              status: Status.Active,
            },
          },
        })
          .then(() => {
            webFormStore.tableStore.onRefresh()
            if (fromModal) {
              webFormStore.webFormModalCB &&
                webFormStore.webFormModalCB(
                  createWebFormStore.dropdownContactListsStore.selectedOptions
                )
              webFormStore.setOpenWebFormModal(false)
            } else {
              uiStore.setNextRoute('')
              navigate(RoutesEnum.webForms)
            }
          })
          .catch((e) => console.error(e))
      } else {
        acceptedCreate({
          limitFor: AppElements.WebForm,
          acceptedCB,
        })
      }
    }
    const handleTestWebFrom = () => {
      setShowTT(false)
      onTestWebForm()
    }

    const onCancel = () => {
      if (fromModal) {
        webFormStore.setOpenWebFormModal(false)
      } else {
        if (uiStore.isBeforeUnload) {
          uiStore.setOpenCancelCreateModal(true)
        } else {
          navigate(-1)
        }
      }
    }
    return (
      <div className={styles.wrap}>
        <ErrorAlert />
        <div className={styles.actionsWrap}>
          <div className={styles.left}>
            <Button
              typeBtn={BtnType.primary}
              size={BtnSize.medium}
              onClick={onCreate}
              disabled={createWebFormStore.disabledCreate}
              loading={loadingCreate || loadingUpdate}
            >
              {createWebFormStore.edit
                ? createWebFormStore.webFrom.status === Status.Draft
                  ? 'Create web form'
                  : 'Save'
                : createWebFormStore.createBtnText}
            </Button>
            {<CloseCreateBtn onCancel={onCancel} fromActions />}
          </div>
          {createWebFormStore.edit ? (
            <>
              <FixedTooltip
                visible={showTT}
                trigger={trigger}
                white
                noArrow
                position={TTPositionEnum.topRight}
                addLeftPosition={8}
              >
                <div className={styles.menuWrap}>
                  <MenuBtn
                    type={MenuEnum.testWebForm}
                    onClick={handleTestWebFrom}
                  />
                  <MenuBtn
                    type={MenuEnum.delete}
                    onClick={() => onDelete([createWebFormStore.webFrom.id])}
                  />
                </div>
              </FixedTooltip>
              <Button
                ref={setTrigger}
                typeBtn={BtnType.secondaryGray}
                size={BtnSize.medium}
                onClick={onTriggerClick}
                icon
              >
                <More />
              </Button>
            </>
          ) : (
            <div />
            // <MuiTooltip title={'Test web form'} arrow placement={'top'}>
            //   <Button
            //     typeBtn={BtnType.secondaryGray}
            //     size={BtnSize.medium}
            //     icon
            //     onClick={handleTestWebFrom}
            //   >
            //     <WebForm />
            //   </Button>
            // </MuiTooltip>
          )}
        </div>
      </div>
    )
  }
)
