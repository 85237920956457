import { observer } from 'mobx-react-lite'
import styles from 'src/Modals/CreateTextToSpeechModal/styles.module.scss'
import { InputText } from 'components/Input/InputText/InputText'
import { Textarea } from 'components/Textarea/Textarea'
import { Icon } from 'components/Icon/Icon'
import { ChooseVoice } from 'src/Modals/CreateTextToSpeechModal/components/ChooseVoice/ChooseVoice'
import { Personalize } from 'components/Textarea/Personolize/Personalize'
import classNames from 'classnames'
import { secondToTime } from 'src/util/functions'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { PreviewSpeech } from 'src/Modals/CreateTextToSpeechModal/components/PreviewSpeech/PreviewSpeech'
import React from 'react'
import { useCreateTextToSpeechModalContext } from 'src/Modals/CreateTextToSpeechModal/context/context'
import createTextToSpeechStore from 'src/Modals/CreateTextToSpeechModal/store/createTextToSpeechStore'
import configStore from 'store/configStore'

export const CreateTextToSpeechModalContent = observer(() => {
  const {
    name,
    setName,
    disabledCreate,
    duration,
    handleCreate,
    handleUpdate,
    loadingCreate,
    isError,
    audioFileDuration,
    textareaStore,
    editAudio,
  } = useCreateTextToSpeechModalContext()
  return (
    <div className={'mediumModal'}>
      <div className={styles.wrapInput}>
        <InputText
          withoutBorder
          placeholder={'Audio name'}
          value={name}
          onChange={(event) => setName(event.target.value)}
          withoutClear
          focusOnMount
        />
      </div>
      <div className={styles.wrapTextField}>
        <Textarea textareaStore={textareaStore} noActions noBorder />
        {/*<SimpleTextarea*/}
        {/*  className={styles.textArea}*/}
        {/*  value={text}*/}
        {/*  onChangeValue={setText}*/}
        {/*/>*/}
        <div className={styles.textareaActions}>
          {isError ? (
            <div className={'row8'}>
              <Icon icon={'warning'} color={'--states-color-error-1'} />
              <span className={'s2 error1'}>
                Your message must be under 3 minutes
              </span>
            </div>
          ) : (
            <div className={'row8'}>
              <ChooseVoice />
              {configStore.dynamic_tts && (
                <Personalize
                  onAddPersonalize={textareaStore.onAddPersonalize}
                  isNew
                />
              )}
            </div>
          )}
          {!!duration && (
            <span className={classNames('s2 gray2', { error1: isError })}>
              {(audioFileDuration && secondToTime(audioFileDuration)) ||
                `~${secondToTime(duration)}`}
            </span>
          )}
        </div>
      </div>
      <div className={styles.actions}>
        {editAudio ? (
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            disabled={disabledCreate}
            onClick={handleUpdate}
            loading={loadingCreate}
          >
            Save
          </Button>
        ) : (
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            disabled={disabledCreate}
            onClick={handleCreate}
            loading={loadingCreate}
          >
            Create
          </Button>
        )}

        <Button
          typeBtn={BtnType.secondaryGray}
          size={BtnSize.medium}
          onClick={createTextToSpeechStore.onClose}
        >
          Cancel
        </Button>
        <div />
        <PreviewSpeech />
      </div>
    </div>
  )
})
