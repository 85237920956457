import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import { ImgCard } from 'components/Textarea/AddMediaContent/MyImagesContent/ImgaCard/ImgCard'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import mediaStore from 'store/mediaStore'
import { FC, useEffect, useState } from 'react'
import {
  useGetAssetsQuery,
  useRemoveAssetsMutation,
} from 'src/generated/graphql'

type Props = {
  uploadSelectedIds: string[]
  onDeselectUploadImagesAll: () => void
  onSelectUploadImagesAll: () => void
  onAddImage: () => void
  onSelectUploadImg: (id: string) => void
  isCheckedUploadImg: (id: string) => boolean
  disabledAddImages: boolean
  removeAttachImages: (ids: string[]) => void
}

export const MyImagesContent: FC<Props> = observer(
  ({
    uploadSelectedIds,
    onDeselectUploadImagesAll,
    onSelectUploadImagesAll,
    onAddImage,
    onSelectUploadImg,
    isCheckedUploadImg,
    disabledAddImages,
    removeAttachImages,
  }) => {
    const [ref, setRef] = useState<HTMLDivElement | null>(null)

    const { data, refetch, loading } = useGetAssetsQuery({
      variables: {
        page: mediaStore.tableStore.paginationInput,
      },
    })
    useEffect(() => {
      if (data?.getMyAssets?.content) {
        mediaStore.setAssets(data.getMyAssets.content)
        mediaStore.tableStore.setTotal(data.getMyAssets.total)
        mediaStore.tableStore.setPage(data.getMyAssets.pageNumber)
      }
    }, [data])
    const [removeAssets] = useRemoveAssetsMutation()
    const handleRemoveImages = async () => {
      try {
        await removeAssets({
          variables: {
            assetIds: uploadSelectedIds,
          },
        })
        mediaStore.onDeleteUploadImages(uploadSelectedIds)
        removeAttachImages(uploadSelectedIds)
      } catch (e) {
        console.error(e)
      }
    }
    useEffect(() => {
      function populate() {
        if (ref) {
          const scrollHeight = ref.scrollHeight
          const clientHeight = ref.clientHeight
          const scrollTop = ref.scrollTop
          const isBottom = scrollHeight - clientHeight - scrollTop <= 30
          const total = mediaStore.tableStore.total
          const selectedRowPerPage = mediaStore.tableStore.selectedRowPerPage
          const page = mediaStore.tableStore.page
          const isNextLoading =
            isBottom && total > (page + 1) * selectedRowPerPage && !loading
          if (isNextLoading) {
            refetch({
              page: {
                pageNumber: page + 1,
                pageSize: selectedRowPerPage,
              },
            })
          }
        }
      }
      if (ref) {
        ref.onscroll = populate
      }
    }, [ref])
    return mediaStore.uploadImages.length ? (
      <div>
        <div className={styles.myImagesWrap} ref={setRef}>
          {mediaStore.uploadImages.map((img, index) => (
            <ImgCard
              img={img}
              key={img.id}
              onSelectUploadImg={onSelectUploadImg}
              isSelect={isCheckedUploadImg(img.id)}
              index={index}
            />
          ))}
        </div>
        <div className={styles.actions}>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            onClick={onAddImage}
            disabled={disabledAddImages}
          >
            {uploadSelectedIds.length
              ? `Attach ${uploadSelectedIds.length || ''} Images`
              : 'Confirm'}
          </Button>

          {!!uploadSelectedIds.length &&
          uploadSelectedIds.length === mediaStore.uploadImagesIds.length ? (
            <Button
              typeBtn={BtnType.secondaryGray}
              size={BtnSize.medium}
              onClick={() => onDeselectUploadImagesAll()}
            >
              Deselect all
            </Button>
          ) : (
            <Button
              typeBtn={BtnType.secondaryGray}
              size={BtnSize.medium}
              onClick={() => onSelectUploadImagesAll()}
            >
              Select all
            </Button>
          )}

          <div />
          {!!uploadSelectedIds.length && (
            <Button
              typeBtn={BtnType.secondaryDelete}
              size={BtnSize.medium}
              onClick={handleRemoveImages}
            >
              Delete
            </Button>
          )}
        </div>
      </div>
    ) : (
      <div className={styles.wrapEmptyContent}>
        <p className={'par2'}>You don’t have any images added yet</p>
      </div>
    )
  }
)
