import { observer } from 'mobx-react-lite'
import { Actions } from 'src/pages/settings/conversions/conversions/ui/actions/Actions'
import { Header } from 'shared/Header/Header'
import { useConversionsContext } from 'src/pages/settings/conversions/conversions/context/context'

export const ConversionsHeader = observer(() => {
  const { conversionsTableStore } = useConversionsContext()
  return (
    <Header
      title={'Conversions'}
      rightContent={
        conversionsTableStore.isEmpty === false ? <Actions /> : null
      }
    />
  )
})
