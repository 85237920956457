import styles from './styles.module.scss'
import classNames from 'classnames'
import { CSSProperties } from 'react'
import { Typography } from 'shared/Typography'

type NewLabelProps = {
  marginLeft?: CSSProperties['marginLeft']
  inverted?: boolean
  text?: string
  isPoint?: boolean
}

export const NewLabel = ({
  marginLeft,
  inverted,
  text = 'New',
  isPoint,
}: NewLabelProps) => (
  <Typography
    variant={'s2-medium'}
    className={classNames(styles.wrap, {
      [styles.inverted]: inverted,
      [styles.point]: isPoint,
    })}
    style={{ marginLeft }}
  >
    {!isPoint && text}
  </Typography>
)
