import { observer } from 'mobx-react-lite'
import { Modal } from 'components/Modal/Modal'
import classNames from 'classnames'
import styles from './styles.module.scss'
import businessProfileStore from 'store/settings/company/businessProfileStore'
import { BusinessProfileContent } from 'src/pages/settings/compliance/BusinessProfile/BusinessProfileContent'

export const EditBPModal = observer(() => {
  const { openEditModal, setOpenEditModal } = businessProfileStore
  const onClose = () => {
    setOpenEditModal(false)
  }
  return (
    <Modal open={openEditModal} onClose={onClose} title={'Business profile'}>
      <div className={classNames(styles.wrap, 'pModal')}>
        <BusinessProfileContent isModal />
      </div>
    </Modal>
  )
})
