import * as React from 'react'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import { useContactFunctions } from 'src/pages/main/contacts/hooks/useContactFunctions'
import { IContactActions } from 'src/pages/main/contacts/functions/getContactActions'

export const ContactsArchiveBtn = (props: IContactActions) => {
  const { onArchive } = useContactFunctions(props)
  const handleClick = async () => {
    await onArchive()
    if (props.onCloseTT) {
      props.onCloseTT()
    }
    if (props.onSuccessAction) {
      props.onSuccessAction()
    }
  }
  return (
    <TableBtn {...props} action={ActionsEnum.archive} onAction={handleClick} />
  )
}
