// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Jgb409bP6CvrYLMpe1MN{display:flex;flex-direction:column;gap:16px}.rhU5CbJSDfURh4L3RLIg{display:grid;grid-template-columns:repeat(7, 1fr);gap:4px}.mXnZ8ujuf5REl474MpyK{font-weight:500 !important;font-family:Inter-Medium;font-size:12px !important}`, "",{"version":3,"sources":["webpack://./src/widgets/WeekDaysSelector/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEF,sBACE,YAAA,CACA,oCAAA,CACA,OAAA,CAEF,sBACE,0BAAA,CACA,wBAAA,CACA,yBAAA","sourcesContent":[".wrap{\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n.wrapDays{\n  display: grid;\n  grid-template-columns: repeat(7, 1fr);\n  gap: 4px;\n}\n.dayBtn{\n  font-weight: 500!important;\n  font-family: Inter-Medium;\n  font-size: 12px!important;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Jgb409bP6CvrYLMpe1MN`,
	"wrapDays": `rhU5CbJSDfURh4L3RLIg`,
	"dayBtn": `mXnZ8ujuf5REl474MpyK`
};
export default ___CSS_LOADER_EXPORT___;
