import { observer } from 'mobx-react-lite'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import a2p10DLCStore from 'store/settings/company/a2p10DLCStore'
import React from 'react'
import { BackBtn } from 'src/routes/settings/compliance/registrations/A2P10DLCRegistration/steps/BackBtn'
import styles from './styles.module.scss'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'
import { Currencies, numberString } from 'src/util/functions'
import { Separator } from 'components/Separator/Separator'
import { useCompleteTenDlcMutation } from 'src/generated/graphql'
import billingStore from 'store/settings/billing/billingStore'
import { useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/routes/routes'

export const Step7 = observer(() => {
  const [completeTenDlc, { loading }] = useCompleteTenDlcMutation()
  const navigate = useNavigate()
  const onContinue = async () => {
    try {
      if (billingStore.primaryPaymentMethodId) {
        const { data } = await completeTenDlc({
          variables: {
            paymentMethodId: billingStore.primaryPaymentMethodId,
          },
        })
        if (data?.completeTenDlc?.profile) {
          a2p10DLCStore.setTenDlcProfile(data.completeTenDlc.profile)
          navigate(RoutesEnum.settingsCompliance)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <div className={'col40'}>
      <div className={'col24'}>
        <p className={'bold'}>Order summary</p>
        <div className={styles.summary}>
          <div className={'flex-space-between'}>
            <p className={'par2 gray1 flex'}>
              Brand registration fee (one-time)
              <InfoTooltip
                title={
                  <span className={'white'}>
                    This is a one-time carrier fee <br /> for registering your
                    Brand
                  </span>
                }
              />
            </p>
            <p className={'par2'}>
              {numberString({
                val: a2p10DLCStore.brandRegistrationFees,
                currency: Currencies.usd,
                maximumFractionDigits: 0,
              })}
            </p>
          </div>
          <div className={'flex-space-between'}>
            <p className={'par2 gray1 flex'}>
              Campaign verification fee (one-time)
              <InfoTooltip
                title={
                  <span className={'white'}>
                    This is a one-time carrier <br /> fee for registering your{' '}
                    <br /> brand campaign use case
                  </span>
                }
              />
            </p>
            <p className={'par2'}>
              {numberString({
                val: a2p10DLCStore.campaignVerificationFees,
                currency: Currencies.usd,
                maximumFractionDigits: 0,
              })}
            </p>
          </div>
          <div className={'flex-space-between'}>
            <p className={'par2 gray1 flex'}>
              Campaign use case fee (recurring)
              <InfoTooltip
                title={
                  <span className={'white'}>
                    This is a monthly recurring <br /> carrier fee for
                    registering <br /> your campaign use case
                  </span>
                }
              />
            </p>
            <p className={'par2'}>
              {numberString({
                val: a2p10DLCStore.reoccurringCampaignUseCaseFees,
                currency: Currencies.usd,
                maximumFractionDigits: 0,
              })}{' '}
              / month
            </p>
          </div>
          <Separator
            marginBlock={8}
            color={'--primary-color-gray-2-disabled'}
          />
          <div className={'flex-space-between'}>
            <p className={'par2 flex'}>Total charged today</p>
            <p className={'par2 bold'}>
              {numberString({
                val: a2p10DLCStore.total,
                currency: Currencies.usd,
                maximumFractionDigits: 0,
              })}
            </p>
          </div>
        </div>
        <span className={'s2 gray1'}>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          The fee's above are imposed by the carrier to register your brand and
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          use case. Call Loop passes down these fee's with no markup.
        </span>
      </div>
      <div className={'row12'}>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          onClick={onContinue}
          loading={loading}
        >
          {a2p10DLCStore.continueText}
        </Button>
        <BackBtn />
      </div>
    </div>
  )
})
