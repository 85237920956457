import { makeAutoObservable } from 'mobx'
import { repeatsOptions2map } from 'components/Schedule/store/mockData'
import dayjs from 'lib/dayjs'
import {
  CustomMonthRepeatType,
  DayOfWeek,
  OnMonthDayOrdinal,
  OnMonthDayType,
  RepeatType,
  SendLaterConfig,
  SendLaterConfigInput,
  SendLaterRepeatType,
} from 'src/generated/graphql'
import { AppSymbols } from 'src/util/symbols'

export class RepeatStore {
  constructor(sendLaterConfig?: SendLaterConfig) {
    if (sendLaterConfig) {
      this.sendLaterConfigRepeatType =
        sendLaterConfig?.repeatType || SendLaterRepeatType.Never
      //repeatConfig
      this.repeatConfigEndDate = sendLaterConfig?.repeatConfig?.endDate
        ? dayjs(sendLaterConfig?.repeatConfig?.endDate).toDate()
        : null
      this.customRepeatConfigRepeatType =
        sendLaterConfig?.repeatConfig?.customRepeatConfig?.repeatType ||
        RepeatType.Daily
      this.customRepeatConfigRepeatCount =
        sendLaterConfig?.repeatConfig?.customRepeatConfig?.repeatCount || 1
      //CustomRepeatMonthConfig
      this.customMonthRepeatType =
        sendLaterConfig?.repeatConfig?.customRepeatConfig?.monthConfig
          ?.monthRepeatType || CustomMonthRepeatType.Each
      this.customEachMonthDayConfigDays =
        sendLaterConfig?.repeatConfig?.customRepeatConfig?.monthConfig
          ?.eachMonthDayConfig?.days || []
      this.customOnMonthDayConfigDayOrdinal =
        sendLaterConfig?.repeatConfig?.customRepeatConfig?.monthConfig
          ?.onMonthDayConfig?.dayOrdinal || OnMonthDayOrdinal.First
      this.customOnMonthDayConfigDayType =
        sendLaterConfig?.repeatConfig?.customRepeatConfig?.monthConfig
          ?.onMonthDayConfig?.dayType || OnMonthDayType.Day
      //RepeatWeekConfig
      this.customRepeatWeekConfigDays =
        sendLaterConfig?.repeatConfig?.customRepeatConfig?.weekConfig?.days ||
        []
    }
    makeAutoObservable(this)
  }

  sendLaterConfigRepeatType: SendLaterRepeatType = SendLaterRepeatType.Never
  repeatConfigEndDate: Date | null = null
  customRepeatConfigRepeatCount = 1
  customRepeatConfigRepeatType: RepeatType = RepeatType.Daily
  customMonthRepeatType: CustomMonthRepeatType = CustomMonthRepeatType.Each
  customEachMonthDayConfigDays: Array<number | null> = []
  customOnMonthDayConfigDayOrdinal: OnMonthDayOrdinal = OnMonthDayOrdinal.First
  customOnMonthDayConfigDayType: OnMonthDayType = OnMonthDayType.Sunday
  customRepeatWeekConfigDays: Array<DayOfWeek | null> = [
    DayOfWeek.Monday,
    DayOfWeek.Tuesday,
    DayOfWeek.Wednesday,
    DayOfWeek.Thursday,
    DayOfWeek.Friday,
  ]

  get schedulingConfigInput(): Omit<SendLaterConfigInput, 'sendDate'> {
    return {
      repeatType: this.sendLaterConfigRepeatType,
      repeatConfig:
        this.sendLaterConfigRepeatType === SendLaterRepeatType.Never
          ? undefined
          : {
              endDate: this.repeatConfigEndDate,
              customRepeatConfig:
                this.sendLaterConfigRepeatType === SendLaterRepeatType.Custom
                  ? {
                      repeatType: this.customRepeatConfigRepeatType,
                      monthConfig:
                        this.customRepeatConfigRepeatType === RepeatType.Monthly
                          ? {
                              eachMonthDayConfig:
                                this.customMonthRepeatType ===
                                CustomMonthRepeatType.Each
                                  ? {
                                      days: this.customEachMonthDayConfigDays,
                                    }
                                  : undefined,
                              onMonthDayConfig:
                                this.customMonthRepeatType ===
                                CustomMonthRepeatType.OnThe
                                  ? {
                                      dayOrdinal:
                                        this.customOnMonthDayConfigDayOrdinal,
                                      dayType:
                                        this.customOnMonthDayConfigDayType,
                                    }
                                  : undefined,
                              monthRepeatType: this.customMonthRepeatType,
                            }
                          : undefined,
                      repeatCount: this.customRepeatConfigRepeatCount,
                      weekConfig:
                        this.customRepeatConfigRepeatType === RepeatType.Weekly
                          ? {
                              days: this.customRepeatWeekConfigDays,
                            }
                          : undefined,
                    }
                  : undefined,
            },
    }
  }

  get suffixString() {
    if (this.sendLaterConfigRepeatType === SendLaterRepeatType.Never) {
      return ''
    }
    if (this.sendLaterConfigRepeatType === SendLaterRepeatType.Custom) {
      return `${AppSymbols.point} Custom repeat`
    }
    if (
      this.sendLaterConfigRepeatType === SendLaterRepeatType.EveryDay ||
      this.sendLaterConfigRepeatType === SendLaterRepeatType.EveryWeek ||
      this.sendLaterConfigRepeatType === SendLaterRepeatType.EveryMonth
    ) {
      return `${AppSymbols.point} ${
        repeatsOptions2map.get(this.sendLaterConfigRepeatType)?.title
      }`
    }
  }

  onPlusRepeats = () => {
    this.customRepeatConfigRepeatCount += 1
  }

  onMinusRepeats = () => {
    if (this.customRepeatConfigRepeatCount === 1) {
      return
    }
    this.customRepeatConfigRepeatCount -= 1
  }

  setSendLaterConfigRepeatType = (ending: SendLaterRepeatType) => {
    this.sendLaterConfigRepeatType = ending
  }

  setCustomRepeatConfigRepeatType = (type: RepeatType) => {
    this.customRepeatConfigRepeatType = type
  }

  selectWeeklyDay = (day: DayOfWeek | null) => {
    if (this.customRepeatWeekConfigDays.includes(day)) {
      this.customRepeatWeekConfigDays = this.customRepeatWeekConfigDays.filter(
        (daySelected) => day !== daySelected
      )
    } else {
      this.customRepeatWeekConfigDays.push(day)
    }
  }

  selectMonthlyDay = (day: number) => {
    if (this.customEachMonthDayConfigDays.includes(day)) {
      this.customEachMonthDayConfigDays =
        this.customEachMonthDayConfigDays.filter(
          (daySelected) => day !== daySelected
        )
    } else {
      this.customEachMonthDayConfigDays.push(day)
    }
  }

  setCustomMonthRepeatType = (value: CustomMonthRepeatType) => {
    this.customMonthRepeatType = value
  }

  setCustomOnMonthDayConfigDayOrdinal = (value: OnMonthDayOrdinal) => {
    this.customOnMonthDayConfigDayOrdinal = value
  }

  setCustomOnMonthDayConfigDayType = (value: OnMonthDayType) => {
    this.customOnMonthDayConfigDayType = value
  }

  setRepeatConfigEndDate = (date: Date | null) => {
    this.repeatConfigEndDate = date
  }
}
