import { UserBlock } from 'src/pages/settings/compliance/modals/BusinessProfileModal/UserBlock'
import businessProfileStore from 'store/settings/company/businessProfileStore'
import { observer } from 'mobx-react-lite'
import { Separator } from 'components/Separator/Separator'

export const PeopleToContact = observer(() => {
  return (
    <div className={'col20'}>
      <p className={'par2'}>
        <b>People to contact</b>
      </p>
      <UserBlock {...businessProfileStore.user1} />
      {businessProfileStore.haveSecondAuthUser && (
        <>
          <Separator />
          <UserBlock {...businessProfileStore.user2} />
        </>
      )}
    </div>
  )
})
