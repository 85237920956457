import {
  Direction,
  GetImportHistoryQuery,
  NullHandling,
  Status,
  useGetImportHistoryQuery,
} from 'src/generated/graphql'
import { useEffect, useState } from 'react'
import importContactStore from 'store/contacts/importContactStore'
import alertStore from 'store/alertStore'
import { Link } from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'
import contactStore from 'store/contacts/contactStore'
import { IContactImportState } from 'src/pages/main/contacts/importHistory/ImportHistoryTable/types'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import companyStore from 'store/settings/company/companyStore'

export function useLoadingHistory() {
  const [skip, setSkip] = useState(false)
  const { data, refetch } = useGetImportHistoryQuery({
    variables: {
      page: {
        pageSize: 25,
        pageNumber: 0,
        sort: {
          orders: [
            {
              direction: Direction.Desc,
              property: 'createdAt',
              nullHandlingHint: NullHandling.Native,
              ignoreCase: true,
            },
          ],
        },
      },
    },
    skip,
  })

  const onLoadData = (data: GetImportHistoryQuery, fromRefetch?: boolean) => {
    const filteredData: IContactImportState[] = []

    data?.getImportHistory?.content?.forEach((state) => {
      if (state) {
        filteredData.push({ ...state, id: state.id })
      }
    })
    if (data.getMyOrganization) {
      companyStore.setAllowImport(data.getMyOrganization?.allowImport)
    }
    importContactStore.setLoadingIContactImportState(filteredData)
    const loadingData: IContactImportState[] = filteredData.filter(
      (state) => state.simplifiedStatus === Status.Processing
    )
    if (loadingData.length && fromRefetch) {
      setTimeout(() => {
        importContactStore.setLoadingTriggerImportState()
      }, 5000)
    }
    if (loadingData.length) {
      alertStore.setImportContactAlert(
        {
          title: companyStore.allowImport ? (
            `Your file ${loadingData[0].originalFileName} has been uploaded. We'll notify you when the import finishes.`
          ) : (
            <>
              Your file has been uploaded and is <b>pending review</b> by our
              customer support team. Typically within an hour.
            </>
          ),
          type: AlertTypeEnum.blue3,
          rightContent: (
            <Link to={RoutesEnum.contactsHistory}>
              <span className={'bold white'}>See details</span>
            </Link>
          ),
        },
        loadingData[0].id
      )
    } else if (alertStore.loadingContactImportIds.length) {
      const lastLoaded = importContactStore.iContactImportStateMap.get(
        alertStore.loadingContactImportIds[
          alertStore.loadingContactImportIds.length - 1
        ]
      )
      if (!lastLoaded) {
        return
      }
      if (lastLoaded.status === Status.Completed) {
        alertStore.setImportContactAlert({
          title: `The ${lastLoaded.originalFileName} has been successfully imported`,
          rightContent: (
            <Link
              to={RoutesEnum.contactsHistory}
              onClick={() =>
                alertStore.setImportContactAlert(null, lastLoaded.id)
              }
            >
              <span className={'bold white'}>See the results</span>
            </Link>
          ),
          type: AlertTypeEnum.success,
        })
        contactStore.tableStoreContacts.onRefresh()
        contactStore.tableStoreLists.onRefresh()
      } else if (lastLoaded.status === Status.Failed) {
        alertStore.setImportContactAlert({
          title: `The ${lastLoaded.originalFileName} failed to import`,
          rightContent: (
            <Link
              to={RoutesEnum.contactsHistory + '/' + lastLoaded.id}
              onClick={() =>
                alertStore.setImportContactAlert(null, lastLoaded.id)
              }
            >
              <span className={'bold white'}>See details</span>
            </Link>
          ),
          type: AlertTypeEnum.error,
        })
      }
    }
  }
  useEffect(() => {
    if (data?.getImportHistory?.content) {
      setSkip(true)
      onLoadData(data)
    }
  }, [data])
  useEffect(() => {
    refetch()
      .then(({ data }) => {
        onLoadData(data, true)
      })
      .catch((e) => console.error(e))
  }, [importContactStore.loadingTriggerImportState])
}
