// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vS1xo1Yfjxz7podWJEQu{width:100%;display:flex;align-items:center;gap:48px}.vS1xo1Yfjxz7podWJEQu .zydvdRMX7yhX5M8doIzw{display:flex;flex-direction:column;gap:8px}`, "",{"version":3,"sources":["webpack://./src/widgets/TrackConversion/ui/SwitchCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,kBAAA,CACA,QAAA,CACA,4CACE,YAAA,CACA,qBAAA,CACA,OAAA","sourcesContent":[".switchCard{\n  width: 100%;\n  display: flex;\n  align-items: center;\n  gap: 48px;\n  .left{\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switchCard": `vS1xo1Yfjxz7podWJEQu`,
	"left": `zydvdRMX7yhX5M8doIzw`
};
export default ___CSS_LOADER_EXPORT___;
