import styles from './styles.module.scss'
import { ColorsNames } from 'styles/variables'
import { ReactComponent as Phone } from 'icons/16pxNoMask/Phone.svg'
import { ITrigger } from 'src/types/ITrigger'
import { TestItBtn } from 'components/TestItBtn/TestItBtn'
import { ReactComponent as App } from 'icons/16pxNoMask/App.svg'
import { AppRender } from 'src/routes/main/triggers/TriggersTable/AppRender'
import { ReactComponent as Deliver } from 'icons/16pxNoMask/Deliver.svg'
import { ReactComponent as Transfer } from 'icons/16pxNoMask/Transfer.svg'
import { ReactComponent as ArrowRight } from 'icons/16pxNoMask/Arrow-Right.svg'
import { TypeContent } from 'components/TypeContent/TypeContent'
import { ReactComponent as StatusIcon } from 'icons/16pxNoMask/Status/Status.svg'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { ContentType } from 'src/generated/graphql'
import { DetailTypeContent } from 'components/DetailsModalContent/DetailTypeContent'
import {
  transformRinglessMessageToInput,
  transformSMSToInput,
  transformVoiceMessageToInput,
} from 'src/util/transform'

export const DetailTriggerContent = ({ trigger }: { trigger: ITrigger }) => {
  return (
    <>
      <div className={styles.row}>
        <h3>{trigger.name}</h3>
        <TestItBtn
          testMessageInput={
            trigger.type === ContentType.Sms && trigger.smsMessage
              ? {
                  smsMessage: transformSMSToInput(trigger.smsMessage),
                  toNumber: '',
                  fromNumberId: trigger.sendFromPhoneNumber?.id,
                }
              : undefined
          }
          testCallMessageRequestInput={
            trigger.type === ContentType.Voice && trigger.voiceMessage
              ? {
                  voiceMessage: transformVoiceMessageToInput(
                    trigger.voiceMessage
                  ),
                  toNumber: '',
                  fromNumberId: trigger.sendFromPhoneNumber?.id,
                }
              : undefined
          }
          testRinglessMessageRequestInput={
            trigger.type === ContentType.Ringless && trigger.ringlessMessage
              ? {
                  ...transformRinglessMessageToInput(trigger.ringlessMessage),
                  toNumber: '',
                  fromNumberId: trigger.sendFromPhoneNumber?.id,
                }
              : undefined
          }
          fromAudio={trigger.type !== ContentType.Sms}
        />
      </div>
      <div className={styles.grid}>
        <div className={styles.rowTable}>
          <div className={styles.left}>
            <StatusIcon />
            <span className={ColorsNames.gray1}>Status</span>
          </div>
          {trigger?.status && <StatusContent status={trigger?.status} />}
        </div>
        <div className={styles.rowTable}>
          <div className={styles.left}>
            <App />
            <span className={ColorsNames.gray1}>App</span>
          </div>
          <AppRender
            title={trigger.appOption.title}
            leftIcon={trigger.appOption.leftIcon}
            iconClass={trigger.appOption.iconClass}
          />
        </div>
        <div className={styles.rowTable}>
          <div className={styles.left}>
            <Phone />
            <span className={ColorsNames.gray1}>Send from</span>
          </div>
          <span>{trigger.sendFromPhoneNumber?.friendlyName}</span>
        </div>
        <div className={styles.rowTable}>
          <div className={styles.left}>
            <Deliver />
            <span className={ColorsNames.gray1}>Message type</span>
          </div>
          {trigger.type && <TypeContent type={trigger.type} />}
        </div>
        {trigger.voiceMessage?.liveAnswer?.transferCallEnabled && (
          <div className={styles.rowTable}>
            <div className={styles.left}>
              <Transfer />
              <span className={ColorsNames.gray1}>Transfer call</span>
            </div>
            <div className={'row8'}>
              <span>{trigger.voiceMessage?.liveAnswer?.transferCallDigit}</span>
              <ArrowRight />
              <span>
                {trigger.voiceMessage?.liveAnswer?.transferCallNumber}
              </span>
            </div>
          </div>
        )}
      </div>
      <DetailTypeContent
        smsMessage={trigger.smsMessage}
        ringlessMessage={trigger.ringlessMessage}
        voiceMessage={trigger.voiceMessage}
      />
    </>
  )
}
