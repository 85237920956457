import styles from './styles.module.scss'
import vCardStore from 'store/settings/vCardStore'
import { observer } from 'mobx-react-lite'
import { EmptyContent } from 'src/pages/settings/account/routes/MyProfile/VirtualContactCard/EmptyContent'
import { FullCardContent } from 'src/pages/settings/account/routes/MyProfile/VirtualContactCard/FullCardContent/FullCardContent'
import { useGetMyContactCardsQuery } from 'src/generated/graphql'
import { useEffect } from 'react'

export const VirtualContactCard = observer(() => {
  const { data, loading } = useGetMyContactCardsQuery()
  useEffect(() => {
    if (data) {
      vCardStore.setMyContactsCardData(data)
    }
  }, [data])
  if (loading) {
    return <></>
  }
  return (
    <div className={styles.wrap}>
      {vCardStore.isCreate ? (
        <div className={'col16'}>
          {vCardStore.vCards.map((card) => (
            <FullCardContent vCard={card} key={card.id} />
          ))}
        </div>
      ) : (
        <EmptyContent />
      )}
    </div>
  )
})
